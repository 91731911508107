import React from "react";
import EmailSettings from "../pages/emailSettings";
import Settings from "../pages/settings";
import SidebarSettings from "../pages/sidebarSettings";
import Page from "@jumbo/shared/Page";

const settingsRoutes = [
    {
        path: "/settings",
        element: <Page component={Settings} />,
    },
    {
        path: "/settings/sidebar",
        element: <Page component={SidebarSettings} />,
    },
    {
        path: "/settings/email",
        element: <Page component={EmailSettings} />,
    },
];

export default settingsRoutes;
