import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import HTMLTypography from "../../shared/widgets/HTMLTypography";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SURVEY_IS_SUBMITTED,
    SURVEY_IS_NOT_SUBMITTED,
    SURVEY_IS_NOT_ARCHIVED,
    SURVEY_KEYS,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import { getClientSurvey } from "../../redux/actions/surveys";
import TakeSurveyForm from "./TakeSurveyForm";

const genericPageName = "Take Survey";

const TakeSurvey = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isTakeSurveyMounted = useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();

    const surveyId = params?.surveyId || "";

    const [isLoading, setIsLoading] = useState(false);
    const [surveyIdIsValid, setSurveyIdIsValid] = useState(false);
    const [surveyIsValid, setSurveyIsValid] = useState(false);
    const [fetchSurvey, setFetchSurvey] = useState(false);
    const [surveyFetched, setSurveyFetched] = useState(false);
    const [currentSurvey, setCurrentSurvey] = useState(null);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    useEffect(() => {
        return () => {
            _isTakeSurveyMounted.current = false;
        };
    }, []);

    /* Check survey ID is valid */
    useEffect(() => {
        if (isValidUserType) {
            setSurveyIdIsValid(!!surveyId);
        }
    }, [isValidUserType, surveyId]);

    /* Start loading */
    useEffect(() => {
        if (surveyIdIsValid) {
            setIsLoading(true);
        }
    }, [surveyIdIsValid]);

    /* Fetch survey */
    useEffect(() => {
        if (isLoading) {
            setFetchSurvey(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchSurvey) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getClientSurvey(payload, (fetchedSurvey) => {
                            if (isActive) {
                                setFetchSurvey(false);
                                setSurveyFetched(true);
                                setCurrentSurvey(fetchedSurvey || null);
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.SURVEY_ID]: surveyId,
            };

            const promise = dispatch(fetchData({ surveyData: surveyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchSurvey(false);
                    setSurveyFetched(false);
                    setCurrentSurvey(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchSurvey, surveyId]);

    /* Check if fetched survey is valid or not */
    useEffect(() => {
        if (
            surveyFetched &&
            currentSurvey &&
            currentSurvey?.[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] === surveyId
        ) {
            setSurveyIsValid(true);
        } else {
            setSurveyIsValid(false);
        }
    }, [currentSurvey, surveyFetched, surveyId]);

    const surveyIsNotArchived = useMemo(() => {
        if (surveyIsValid) {
            return (
                currentSurvey?.[SURVEY_KEYS.IS_ARCHIVED] &&
                currentSurvey[SURVEY_KEYS.IS_ARCHIVED] ===
                    SURVEY_IS_NOT_ARCHIVED
            );
        } else {
            return false;
        }
    }, [currentSurvey, surveyIsValid]);

    const surveyIsSubmitted = useMemo(() => {
        if (surveyIsValid) {
            return (
                currentSurvey?.[SURVEY_KEYS.IS_SUBMITTED] &&
                currentSurvey[SURVEY_KEYS.IS_SUBMITTED] === SURVEY_IS_SUBMITTED
            );
        } else {
            return false;
        }
    }, [currentSurvey, surveyIsValid]);

    const surveyIsNotSubmitted = useMemo(() => {
        if (surveyIsValid) {
            return (
                currentSurvey?.[SURVEY_KEYS.IS_SUBMITTED] &&
                currentSurvey[SURVEY_KEYS.IS_SUBMITTED] ===
                    SURVEY_IS_NOT_SUBMITTED
            );
        } else {
            return false;
        }
    }, [currentSurvey, surveyIsValid]);

    const handleSubmitSurvey = () => navigate("/surveys");

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchSurvey && !surveyFetched;
    const loadError =
        !isValidUserType ||
        !surveyIdIsValid ||
        fetchError ||
        !surveyIsValid ||
        !surveyIsNotArchived;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle
                        component="h1"
                        title={
                            surveyIsValid
                                ? currentSurvey[SURVEY_KEYS.NAME]
                                : "Invalid Survey!"
                        }
                        type="v2"
                    />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: surveyIsValid
                                ? currentSurvey[SURVEY_KEYS.NAME]
                                : genericPageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !surveyIdIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid survey!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && surveyIdIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the survey!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType &&
                        surveyIdIsValid &&
                        !fetchError &&
                        !surveyIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Invalid survey details!
                                </Alert>
                            </Grid>
                        )}
                    {isValidUserType &&
                        surveyIdIsValid &&
                        !fetchError &&
                        surveyIsValid &&
                        !surveyIsNotArchived && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Survey has been archived!
                                </Alert>
                            </Grid>
                        )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <HTMLTypography
                            component="span"
                            variant="h5"
                            content={currentSurvey[SURVEY_KEYS.DESCRIPTION]}
                        />
                    </Grid>
                    {surveyIsSubmitted && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Survey has been submitted!
                            </Alert>
                        </Grid>
                    )}
                    {surveyIsNotSubmitted && (
                        <Grid item xs={12}>
                            <TakeSurveyForm
                                canSubmitSurvey={true}
                                currentSurvey={currentSurvey}
                                onSubmitSuccess={handleSubmitSurvey}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default TakeSurvey;
