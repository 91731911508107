import {
    GET_ACCOUNT_ASSETS,
    GET_ACCOUNT_ASSET_COUNT,
    SET_ACCOUNT_ASSET_DETAILS,
} from "../../utils/constants/actionTypes";
import { ACCOUNT_ASSET_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    accountAssets: [],
    currentAccountAsset: null,
    accountAssetCounts: { [ACCOUNT_ASSET_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ACCOUNT_ASSETS: {
            return {
                ...state,
                accountAssets: action.payload,
            };
        }
        case GET_ACCOUNT_ASSET_COUNT: {
            return {
                ...state,
                accountAssetCounts: action.payload,
            };
        }
        case SET_ACCOUNT_ASSET_DETAILS: {
            return {
                ...state,
                currentAccountAsset: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
