import React, { useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import NameLink from "../../shared/widgets/NameLink";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    CLIENT_USER_KEYS,
    FM_USER_KEYS,
    TICKET_KEYS,
} from "../../utils/constants/appData";

const LabelComponent = ({ children, labelSx = {}, ...restProps }) => {
    return (
        <Typography
            component="p"
            variant="h5"
            sx={(theme) => ({
                ...labelSx,
                fontWeight: theme.typography.fontWeightBold,
            })}
            {...restProps}
        >
            {children}
        </Typography>
    );
};

const ValueComponent = ({ children, valueSx = {}, ...restProps }) => {
    return (
        <Typography
            component="p"
            sx={{
                ...valueSx,
                wordBreak: "break-word",
            }}
            {...restProps}
        >
            {children}
        </Typography>
    );
};

const TicketSidebar = ({
    canFetchClient,
    canViewCompany,
    canViewFmUsers,
    canViewFmUserDetails,
    currentTicket,
    ticketClientUser,
    ticketFmUsers,
}) => {
    const scrollbarRef = useRef();

    useEffect(() => {
        if (scrollbarRef) scrollbarRef.current?.scrollToBottom();
    }, [scrollbarRef]);

    const ticketCompanyName = currentTicket[TICKET_KEYS.COMPANY];
    const ticketCompanyInitials = currentTicket[TICKET_KEYS.COMPANY_INITIALS];
    const ticketCompanyPhotoLink =
        currentTicket[TICKET_KEYS.COMPANY_PHOTO] || "";

    let ticketClientUserPhotoLink = "",
        ticketClientUserName = "",
        ticketClientUserEmail = "";

    if (canFetchClient) {
        ticketClientUserName = ticketClientUser[CLIENT_USER_KEYS.NAME];
        ticketClientUserEmail = ticketClientUser[CLIENT_USER_KEYS.EMAIL];

        const fetchedPhoto = ticketClientUser[CLIENT_USER_KEYS.PROFILE_PIC];
        if (fetchedPhoto) {
            ticketClientUserPhotoLink = `${
                ticketClientUser[CLIENT_USER_KEYS.PHOTO_BASE_URL]
            }/${fetchedPhoto[CLIENT_USER_KEYS.PHOTO_FILE_PATH]}`;
        }
    }

    const ticketClientUserInitials = ticketClientUserPhotoLink ? (
        <React.Fragment>&nbsp;</React.Fragment>
    ) : (
        getInitialsFromName(ticketClientUserName)
    );

    return (
        <Stack
            direction="column"
            spacing={3}
            sx={(theme) => ({
                padding: theme.spacing(2),
                border: `2px solid ${theme.palette.secondary.main}`,
                borderRadius: "8px",
            })}
        >
            <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-start", md: "center" }}
                spacing={1}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    spacing={{ xs: 0.5, md: 1.25 }}
                >
                    <ValueComponent>Requested:</ValueComponent>
                    <ValueComponent
                        valueSx={{
                            fontWeight: (theme) =>
                                theme.typography.fontWeightBold,
                        }}
                    >
                        {currentTicket[TICKET_KEYS.CREATED_AT]}
                    </ValueComponent>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    spacing={{ xs: 0.5, md: 1.25 }}
                >
                    <ValueComponent>Updated:</ValueComponent>
                    <ValueComponent
                        valueSx={{
                            fontWeight: (theme) =>
                                theme.typography.fontWeightBold,
                        }}
                    >
                        {currentTicket[TICKET_KEYS.UPDATED_AT] ||
                            currentTicket[TICKET_KEYS.CREATED_AT]}
                    </ValueComponent>
                </Stack>
            </Stack>
            {canViewCompany && (
                <Stack
                    direction="column"
                    flexWrap="wrap"
                    spacing={{ md: 1.25, xs: 0.5 }}
                >
                    <LabelComponent
                        labelSx={(theme) => ({
                            mb: theme.spacing(1),
                        })}
                    >
                        Company
                    </LabelComponent>
                    <Stack
                        direction="row"
                        alignItems="center"
                        flexWrap="wrap"
                        spacing={{ md: 1.25, xs: 0.5 }}
                    >
                        <Avatar
                            src={ticketCompanyPhotoLink}
                            sizes={"small"}
                            alt={ticketCompanyName}
                            sx={{
                                bgcolor: "#487AA3",
                            }}
                        >
                            {ticketCompanyInitials}
                        </Avatar>
                        <Stack direction="column">
                            <NameLink
                                component={RouterLink}
                                to={`/company/${
                                    currentTicket[TICKET_KEYS.COMPANY_ID]
                                }/dashboard/`}
                                sx={{
                                    fontWeight: (theme) =>
                                        theme.typography.fontWeightBold,
                                }}
                            >
                                {ticketCompanyName}
                            </NameLink>
                        </Stack>
                    </Stack>
                </Stack>
            )}
            {canFetchClient && (
                <React.Fragment>
                    <LabelComponent
                        labelSx={(theme) => ({
                            mb: theme.spacing(1),
                        })}
                    >
                        Client User
                    </LabelComponent>
                    <Stack
                        direction="row"
                        alignItems="center"
                        flexWrap="wrap"
                        spacing={{ md: 1.25, xs: 0.5 }}
                    >
                        <Avatar
                            src={ticketClientUserPhotoLink}
                            sizes={"small"}
                            alt={ticketClientUserName}
                            sx={{
                                bgcolor: "#487AA3",
                            }}
                        >
                            {ticketClientUserInitials}
                        </Avatar>
                        <Stack direction="column">
                            <NameLink
                                component={RouterLink}
                                to={`/clientUser/${
                                    ticketClientUser[CLIENT_USER_KEYS.ID]
                                }/overview/`}
                                sx={{
                                    fontWeight: (theme) =>
                                        theme.typography.fontWeightBold,
                                }}
                            >
                                {ticketClientUserName}
                            </NameLink>
                            {ticketClientUserEmail && (
                                <NameLink
                                    href={`mailto:${ticketClientUserEmail}`}
                                    variant="body2"
                                >
                                    {ticketClientUserEmail}
                                </NameLink>
                            )}
                        </Stack>
                    </Stack>
                </React.Fragment>
            )}
            {canViewFmUsers && (
                <Box
                    sx={(theme) => ({
                        height: "240px",
                        maxHeight: "240px",
                        overflow: "hidden",
                        [theme.breakpoints.down("md")]: {
                            height: "160px",
                            maxHeight: "160px",
                        },
                    })}
                >
                    <JumboScrollbar
                        ref={scrollbarRef}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                        autoHeightMin={30}
                        style={{ height: "100%" }}
                    >
                        <LabelComponent>Assigned Users</LabelComponent>
                        <List>
                            {ticketFmUsers.map((ticketFmUser) => {
                                const ticketFmUserId =
                                    ticketFmUser[FM_USER_KEYS.ID];

                                const ticketFmUserName =
                                    ticketFmUser[FM_USER_KEYS.NAME];

                                const ticketFmUserEmail =
                                    ticketFmUser[FM_USER_KEYS.EMAIL];

                                const ticketFmUserFetchedPhoto =
                                    ticketFmUser[FM_USER_KEYS.PROFILE_PIC];
                                let ticketFmUserPhotoLink = "";
                                if (ticketFmUserFetchedPhoto) {
                                    ticketFmUserPhotoLink = `${
                                        ticketFmUser[
                                            FM_USER_KEYS.PHOTO_BASE_URL
                                        ]
                                    }/${
                                        ticketFmUserFetchedPhoto[
                                            FM_USER_KEYS.PHOTO_FILE_PATH
                                        ]
                                    }`;
                                }
                                const ticketFmUserInitials =
                                    ticketFmUserPhotoLink ? (
                                        <React.Fragment>&nbsp;</React.Fragment>
                                    ) : (
                                        getInitialsFromName(ticketFmUserName)
                                    );

                                return ticketFmUser ? (
                                    <ListItem
                                        key={`assigned-client-${ticketFmUserId}`}
                                        disableGutters
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                src={ticketFmUserPhotoLink}
                                                sizes={"small"}
                                                alt={ticketFmUserName}
                                                sx={{
                                                    bgcolor: "#487AA3",
                                                }}
                                            >
                                                {ticketFmUserInitials}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                canViewFmUserDetails ? (
                                                    <NameLink
                                                        component={RouterLink}
                                                        to={`/fmUser/${ticketFmUserId}/`}
                                                        sx={{
                                                            fontWeight: (
                                                                theme
                                                            ) =>
                                                                theme.typography
                                                                    .fontWeightBold,
                                                        }}
                                                    >
                                                        {ticketFmUserName}
                                                    </NameLink>
                                                ) : (
                                                    ticketFmUserName
                                                )
                                            }
                                            secondary={
                                                canViewFmUserDetails &&
                                                ticketFmUserEmail ? (
                                                    <NameLink
                                                        href={`mailto:${ticketFmUserEmail}`}
                                                        variant="body2"
                                                    >
                                                        {ticketFmUserEmail}
                                                    </NameLink>
                                                ) : (
                                                    ""
                                                )
                                            }
                                        />
                                    </ListItem>
                                ) : null;
                            })}
                        </List>
                    </JumboScrollbar>
                </Box>
            )}
        </Stack>
    );
};

TicketSidebar.propTypes = {
    canFetchClient: PropTypes.bool,
    canViewCompany: PropTypes.bool,
    canViewFmUsers: PropTypes.bool,
    canViewFmUserDetails: PropTypes.bool,
    currentTicket: PropTypes.object,
    ticketClientUser: PropTypes.object,
    ticketFmUsers: PropTypes.array,
};

TicketSidebar.defaultProps = {
    canFetchClient: false,
    canViewCompany: false,
    canViewFmUsers: false,
    canViewFmUserDetails: false,
    ticketClientUser: null,
    ticketFmUsers: [],
};

export default TicketSidebar;
