import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { capitalizeFLetter } from "@jumbo/utils";

const FmSingleAutocomplete = ({
    id,
    idKey,
    label,
    labelKey,
    options,
    value,
    onChange,
    ...restProps
}) => {
    return (
        <Autocomplete
            filterSelectedOptions
            id={id}
            value={value}
            options={options}
            getOptionLabel={(option) => {
                let optionLabel = "";
                if (option) {
                    if (typeof option === "string") {
                        optionLabel = option;
                    } else if (
                        typeof option === "object" &&
                        option?.[labelKey]
                    ) {
                        optionLabel = option[labelKey];
                    }
                }

                if (optionLabel) {
                    const optionLabelParts = optionLabel
                        .split("_")
                        .map((optionLabelPart) =>
                            capitalizeFLetter(optionLabelPart)
                        );
                    return optionLabelParts.join(" ");
                } else {
                    return "";
                }
            }}
            isOptionEqualToValue={(option, value) => {
                let isEqual = false;
                if (typeof option === "string") {
                    isEqual = option === value;
                } else if (typeof option === "object") {
                    isEqual = option[idKey] === value[idKey];
                }
                return isEqual;
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" />
            )}
            onChange={(event, value, reason) => {
                if (["selectOption", "removeOption"].includes(reason)) {
                    onChange(value || null);
                } else if (reason === "clear") {
                    onChange(null);
                }
            }}
            sx={(theme) => ({
                "& .MuiAutocomplete-inputRoot": {
                    height: 44,
                    padding: theme.spacing(0, 1, 0, 2),
                    borderRadius: 24,
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.085)",
                    "&:hover,:active,:not( :hover )": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.divider,
                        },
                    },
                },
                "& .MuiFormLabel-root.MuiInputLabel-root": {
                    lineHeight: "1em",
                    color: "#B8B8B8",
                },
            })}
            {...restProps}
        />
    );
};

FmSingleAutocomplete.propTypes = {
    id: PropTypes.string,
    idKey: PropTypes.string,
    label: PropTypes.string,
    labelKey: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
};

FmSingleAutocomplete.defaultProps = {
    idKey: "",
    labelKey: "",
    options: [],
    // onChange: () => {},
};

export default FmSingleAutocomplete;
