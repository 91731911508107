import React from "react";
import ClientOverview from "../pages/clientOverview";
import CompanyClientUsers from "../pages/companyClientUsers";
import Page from "@jumbo/shared/Page";

const clientUsersRoutes = [
    {
        path: "/company/:companyId/clientUsers",
        element: <Page component={CompanyClientUsers} />,
    },
    {
        path: "/clientUser/:clientUserId/overview/",
        element: <Page component={ClientOverview} />,
    },
];

export default clientUsersRoutes;