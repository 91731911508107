import React, {
    lazy,
    Suspense,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { CKEditor } from "ckeditor4-react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { addSurvey, updateSurvey } from "../../redux/actions/surveys";
import FmButton from "../../shared/widgets/FmButton";
import HeadingStack from "../../shared/widgets/HeadingStack";
import {
    CKEDITOR_TOOLBAR,
    SURVEY_KEYS,
    SURVEY_QUESTION_KEYS,
    SURVEY_QUESTION_IS_DELETED,
    SURVEY_QUESTION_IS_NOT_DELETED,
} from "../../utils/constants/appData";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";
import {
    checkSurveyNameValid,
    checkSurveyDescriptionValid,
    checkSurveyQuestionsValid,
} from "../../utils/validationHelpers";
import SurveyFormQuestion from "./SurveyFormQuestion";
const AddEditQuestionDialog = lazy(() => import("./AddEditQuestionDialog"));

const SurveyForm = ({ currentSurvey, onAdd }) => {
    const _isSurveyFormMounted = useRef(true);
    const descriptionEditor = useRef(null);
    const dispatch = useDispatch();
    const { authToken } = useJumboAuth();

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [initDescription, setInitDescription] = useState(false);
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [questions, setQuestions] = useState([]);
    const [activeQuestions, setActiveQuestions] = useState([]);
    const [activQuestionsError, setActiveQuestionsError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentQuestionIdx, setCurrentQuestionIdx] = useState(-1);
    const [openAddEditQuestionDialog, setOpenAddEditQuestionDialog] =
        useState(false);

    const questionTitles = useMemo(() => {
        return activeQuestions && activeQuestions.length > 0
            ? activeQuestions.map(
                  (question) => question[SURVEY_QUESTION_KEYS.TITLE]
              )
            : [];
    }, [activeQuestions]);

    useEffect(() => {
        return () => {
            _isSurveyFormMounted.current = false;
            descriptionEditor.current = null;
        };
    }, []);

    useEffect(() => {
        if (
            currentSurvey &&
            currentSurvey?.[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID]
        ) {
            setName(currentSurvey[SURVEY_KEYS.NAME]);
            setQuestions(currentSurvey[SURVEY_KEYS.QUESTIONS]);
        } else {
            setName("");
            setQuestions([]);
        }
    }, [currentSurvey]);

    useEffect(() => {
        if (initDescription) {
            setInitDescription(false);
            if (
                currentSurvey &&
                currentSurvey?.[SURVEY_KEYS.ID] &&
                currentSurvey[SURVEY_KEYS.ID]
            ) {
                const surveyDescription =
                    currentSurvey[SURVEY_KEYS.DESCRIPTION];
                setDescription(surveyDescription);
                if (descriptionEditor.current)
                    descriptionEditor.current.setData(surveyDescription);
            } else {
                setDescription("");
                if (descriptionEditor.current)
                    descriptionEditor.current.setData("");
            }
        }
    }, [currentSurvey, initDescription]);

    useEffect(() => {
        if (questions.length > 0) {
            setActiveQuestions(
                questions.filter(
                    (question) =>
                        question[SURVEY_QUESTION_KEYS.IS_DELETED] ===
                        SURVEY_QUESTION_IS_NOT_DELETED
                )
            );
        } else {
            setActiveQuestions([]);
        }
    }, [questions]);

    useEffect(() => {
        const questionsValidationResult =
            checkSurveyQuestionsValid(activeQuestions);
        if (questionsValidationResult.status) {
            if (questionsValidationResult.status === "true") {
                setActiveQuestionsError("");
            }
        }
    }, [activeQuestions]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case SURVEY_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case SURVEY_KEYS.DESCRIPTION:
                        setDescriptionError(msg);
                        break;

                    case SURVEY_KEYS.QUESTIONS:
                        setActiveQuestionsError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const nameBlurHandler = (event) => {
        const validationResult = checkSurveyNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const descriptionChangeHandler = (event) => {
        setDescription(event.editor.getData());
        setDescriptionError("");
    };

    const descriptionBlurHandler = (event) => {
        const validationResult = checkSurveyDescriptionValid(
            event.editor.getData()
        );
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDescriptionError(validationResult.msg);
            }
        } else {
            setDescriptionError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkSurveyNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        const descriptionValidationResult =
            checkSurveyDescriptionValid(description);
        if (descriptionValidationResult.status) {
            if (descriptionValidationResult.status === "false") {
                formIsValid = false;
                setDescriptionError(descriptionValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDescriptionError(VALIDATION_FAILED);
        }

        const questionsValidationResult =
            checkSurveyQuestionsValid(activeQuestions);
        if (questionsValidationResult.status) {
            if (questionsValidationResult.status === "false") {
                formIsValid = false;
                setActiveQuestionsError(questionsValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setActiveQuestionsError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.NAME]: name,
                [SURVEY_KEYS.DESCRIPTION]: description,
                [SURVEY_KEYS.QUESTIONS]: questions,
            };

            if (currentSurvey) {
                surveyData[SURVEY_KEYS.SURVEY_ID] =
                    currentSurvey[SURVEY_KEYS.ID];

                /* console.log({ surveyData }); */

                dispatch(
                    updateSurvey(
                        { surveyData: surveyData },
                        () => {},
                        (messages) => {
                            if (_isSurveyFormMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                /* console.log({ surveyData }); */

                dispatch(
                    addSurvey(
                        { surveyData: surveyData },
                        () => {
                            setTimeout(() => {
                                onAdd();
                            }, 1000);
                        },
                        (messages) => {
                            if (_isSurveyFormMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    const handleAddButtonClick = () => {
        setCurrentQuestion(null);
        setCurrentQuestionIdx(-1);
        setOpenAddEditQuestionDialog(true);
    };

    const handleAddQuestion = (questionObj) => {
        setQuestions((prevState) => [...prevState, questionObj]);
    };

    const handleUpdateQuestion = (questionObj, index) => {
        /* console.log("handleUpdateQuestion: ", { questionObj, index }); */
        setQuestions((prevState) =>
            prevState.map((question, idx) => {
                if (questionObj[SURVEY_QUESTION_KEYS.ID] === "0") {
                    return idx === index ? questionObj : question;
                } else {
                    return question[SURVEY_QUESTION_KEYS.ID] ===
                        questionObj[SURVEY_QUESTION_KEYS.ID]
                        ? questionObj
                        : question;
                }
            })
        );
    };

    const handleCloseAddEditQuestionDialog = () => {
        setOpenAddEditQuestionDialog(false);
        setCurrentQuestion(null);
        setCurrentQuestionIdx(-1);
    };

    const handleEditQuestion = (editedQuestion, editedQuestionIndex) => {
        /* console.log("handleEditQuestion: ", {
            editedQuestion,
            editedQuestionIndex,
        }); */
        setCurrentQuestion(editedQuestion);
        setCurrentQuestionIdx(editedQuestionIndex);
        setOpenAddEditQuestionDialog(true);
    };

    const handleDeleteQuestion = (deletedQuestion, deletedQuestionIndex) => {
        /* console.log("handleDeleteQuestion: ", {
            deletedQuestion,
            deletedQuestionIndex,
        }); */
        if (deletedQuestion[SURVEY_QUESTION_KEYS.ID] === "0") {
            setQuestions((prevState) =>
                prevState.filter((question, idx) => {
                    const titlesMatch =
                        question[SURVEY_QUESTION_KEYS.TITLE] ===
                        deletedQuestion[SURVEY_QUESTION_KEYS.TITLE];
                    const indexMatch = idx === deletedQuestionIndex;

                    return !(indexMatch && titlesMatch);
                })
            );
        } else {
            setQuestions((prevState) =>
                prevState.map((question) => {
                    if (
                        question[SURVEY_QUESTION_KEYS.ID] ===
                        deletedQuestion[SURVEY_QUESTION_KEYS.ID]
                    ) {
                        return {
                            ...question,
                            [SURVEY_QUESTION_KEYS.IS_DELETED]:
                                SURVEY_QUESTION_IS_DELETED,
                        };
                    } else {
                        return question;
                    }
                })
            );
        }
    };

    /* const handleDeleteQuestionAlt = (deletedQuestion, deletedQuestionIndex) => {
        console.log("handleDeleteQuestionAlt: ", {
            deletedQuestion,
            deletedQuestionIndex,
        });
        setQuestions((prevState) =>
            prevState.filter((question, idx) => {
                if (deletedQuestion[SURVEY_QUESTION_KEYS.ID] === "0") {
                    const titlesMatch =
                        question[SURVEY_QUESTION_KEYS.TITLE] ===
                        deletedQuestion[SURVEY_QUESTION_KEYS.TITLE];
                    const indexMatch = idx === deletedQuestionIndex;

                    return !(indexMatch && titlesMatch);
                } else {
                    const idMatch =
                        question[SURVEY_QUESTION_KEYS.ID] ===
                        deletedQuestion[SURVEY_QUESTION_KEYS.ID];
                    return !idMatch;
                }
            })
        );
    }; */

    /* console.log({ currentSurvey }); */

    return (
        <React.Fragment>
            <Paper sx={{ p: 2 }}>
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        {miscellaneousErrors &&
                            miscellaneousErrors.length > 0 && (
                                <Grid item xs={12}>
                                    {miscellaneousErrors.map(
                                        (miscellaneousError, idx) => (
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                color="error"
                                                gutterBottom
                                                key={`misc-error-${idx}`}
                                            >
                                                {miscellaneousError}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                id="name"
                                name="name"
                                label="Name"
                                value={name}
                                margin="dense"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameError("");
                                }}
                                onBlur={nameBlurHandler}
                                error={nameError !== ""}
                                helperText={nameError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="description">
                                Description
                            </InputLabel>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <CKEditor
                                    id="description"
                                    initData={description}
                                    config={{
                                        contentsCss: [
                                            `${ASSET_MUSEO_SANS}/styles.css`,
                                            `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                        ],
                                        toolbar: CKEDITOR_TOOLBAR,
                                        removePlugins: "image",
                                    }}
                                    onBlur={descriptionBlurHandler}
                                    onChange={descriptionChangeHandler}
                                    onInstanceReady={({ editor }) => {
                                        descriptionEditor.current = editor;
                                        setInitDescription(true);
                                    }}
                                />
                                {descriptionError && (
                                    <FormHelperText
                                        error
                                        id="description-error"
                                    >
                                        {descriptionError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <HeadingStack>
                                <Stack
                                    direction="column"
                                    sx={(theme) => ({
                                        [theme.breakpoints.down("md")]: {
                                            mt: theme.spacing(4),
                                        },
                                    })}
                                >
                                    <Typography component="h4" variant="h4">
                                        Questions
                                    </Typography>
                                    {activQuestionsError && (
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            {activQuestionsError && (
                                                <FormHelperText
                                                    error
                                                    id="questions-error"
                                                    sx={{
                                                        m: 0,
                                                    }}
                                                >
                                                    {activQuestionsError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                </Stack>
                                <FmButton
                                    startIcon="add"
                                    type="button"
                                    label="Add a Question"
                                    onClick={handleAddButtonClick}
                                    sx={{
                                        p: (theme) => theme.spacing(0.75, 1.5),
                                        fontSize: (theme) =>
                                            theme.typography.h5.fontSize,
                                        fontWeight: (theme) =>
                                            theme.typography.h5.fontWeight,
                                        textTransform: "none",
                                    }}
                                />
                            </HeadingStack>
                        </Grid>
                        {activeQuestions && activeQuestions.length === 0 && (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    No questions added!
                                </Alert>
                            </Grid>
                        )}
                        {activeQuestions && activeQuestions.length > 0 && (
                            <Grid item xs={12} sx={{ pt: 0 }}>
                                <List disablePadding>
                                    {/* Note: Using questions instead of activeQuestions in loop to
                                            retain proper indexing */}
                                    {questions.map((question, questionIdx) => {
                                        return (
                                            <SurveyFormQuestion
                                                key={`question-${questionIdx}`}
                                                numQuestions={questions.length}
                                                question={question}
                                                questionIdx={questionIdx}
                                                onDelete={handleDeleteQuestion}
                                                onEdit={handleEditQuestion}
                                            />
                                        );
                                    })}
                                </List>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {currentSurvey
                                    ? "Update Survey"
                                    : "Create Survey"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            {openAddEditQuestionDialog && (
                <Suspense fallback={null}>
                    <AddEditQuestionDialog
                        addedTitles={questionTitles}
                        currentQuestion={currentQuestion}
                        currentQuestionIdx={currentQuestionIdx}
                        open={openAddEditQuestionDialog}
                        onClose={handleCloseAddEditQuestionDialog}
                        onAddQuestion={handleAddQuestion}
                        onUpdateQuestion={handleUpdateQuestion}
                    />
                </Suspense>
            )}
        </React.Fragment>
    );
};

SurveyForm.propTypes = {
    currentSurvey: PropTypes.object,
    onAdd: PropTypes.func,
};

SurveyForm.defaultProps = {
    currentSurvey: null,
    onAdd: () => {},
};

export default SurveyForm;
