import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    checkMessageValid,
    checkSubjectValid,
} from "../../utils/validationHelpers";
import {
    AUTH_USER_KEYS,
    CLIENT_USER_KEYS,
    COMPANY_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { sendClientUserMsg } from "../../redux/actions/clientUsers";

const SendOfficeContactMessageDialog = ({
    currentCompany,
    currentOfficeContact,
    open,
    onClose,
}) => {
    const _isSendOfficeContactMessageDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [companyValid, setCompanyValid] = useState(false);
    const [officeContactValid, setOfficeContactValid] = useState(false);
    const [subject, setSubject] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isSendOfficeContactMessageDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setCompanyValid(false);
            setOfficeContactValid(false);
            setSubject("");
            setSubjectError("");
            setMessage("");
            setMessageError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (
            currentCompany &&
            currentCompany?.[COMPANY_KEYS.ID] &&
            currentCompany[COMPANY_KEYS.ID]
        ) {
            setCompanyValid(true);
        }
    }, [currentCompany]);

    useEffect(() => {
        if (
            currentOfficeContact &&
            currentOfficeContact?.[CLIENT_USER_KEYS.ID] &&
            currentOfficeContact[CLIENT_USER_KEYS.ID]
        ) {
            setOfficeContactValid(true);
        }
    }, [currentOfficeContact]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case CLIENT_USER_KEYS.SUBJECT:
                        setSubjectError(msg);
                        break;

                    case CLIENT_USER_KEYS.MESSAGE:
                        setMessageError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const subjectBlurHandler = (event) => {
        const validationResult = checkSubjectValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSubjectError(validationResult.msg);
            }
        } else {
            setSubjectError(VALIDATION_FAILED);
        }
    };

    const messageBlurHandler = (event) => {
        const validationResult = checkMessageValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setMessageError(validationResult.msg);
            }
        } else {
            setMessageError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const subjectValidationResult = checkSubjectValid(subject);
        if (subjectValidationResult.status) {
            if (subjectValidationResult.status === "false") {
                formIsValid = false;
                setSubjectError(subjectValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSubjectError(VALIDATION_FAILED);
        }

        const messageValidationResult = checkMessageValid(message);
        if (messageValidationResult.status) {
            if (messageValidationResult.status === "false") {
                formIsValid = false;
                setMessageError(messageValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setMessageError(VALIDATION_FAILED);
        }

        if (isValidUserType && formIsValid) {
            const officeContactData = {
                authcode: authToken,
                [CLIENT_USER_KEYS.COMPANY_ID]: currentCompany[COMPANY_KEYS.ID],
                [CLIENT_USER_KEYS.CLIENT_ID]:
                    currentOfficeContact[CLIENT_USER_KEYS.ID],
                [CLIENT_USER_KEYS.SUBJECT]: subject,
                [CLIENT_USER_KEYS.MESSAGE]: message,
            };

            dispatch(
                sendClientUserMsg(
                    {
                        clientUserData: officeContactData,
                        authUserType: authUserType,
                    },
                    () => {
                        if (_isSendOfficeContactMessageDialogMounted.current)
                            onClose();
                    },
                    (messages) => {
                        if (_isSendOfficeContactMessageDialogMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    officeContactValid
                        ? `Send Message to ${
                              currentOfficeContact[CLIENT_USER_KEYS.NAME]
                          }`
                        : "Invalid Office Contact!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {isValidUserType && companyValid && officeContactValid && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="subject"
                                    name="subject"
                                    label="Subject"
                                    value={subject}
                                    margin="dense"
                                    onChange={(e) => {
                                        setSubject(e.target.value);
                                        setSubjectError("");
                                    }}
                                    onBlur={subjectBlurHandler}
                                    error={subjectError !== ""}
                                    helperText={subjectError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    id="message"
                                    name="message"
                                    label="Message"
                                    value={message}
                                    margin="dense"
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                        setMessageError("");
                                    }}
                                    onBlur={messageBlurHandler}
                                    error={messageError !== ""}
                                    helperText={messageError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Send Message
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!isValidUserType && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {isValidUserType && !companyValid && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Company is not valid!
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {isValidUserType && !officeContactValid && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Office Contact is not valid!
                            </Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

SendOfficeContactMessageDialog.propTypes = {
    currentCompany: PropTypes.object,
    currentOfficeContact: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

SendOfficeContactMessageDialog.defaultProps = {
    currentOfficeContact: null,
    open: false,
    // onClose: () => {},
};

export default SendOfficeContactMessageDialog;
