import {
    ADD_CLIENT_USER,
    DELETE_CLIENT_USER,
    EDIT_CLIENT_USER,
    GET_CLIENT_USER_COUNT,
    GET_CLIENT_USERS,
    SET_CLIENT_USER_DETAILS,
} from "../../utils/constants/actionTypes";
import { CLIENT_USER_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    clientUsers: [],
    currentClientUser: null,
    clientUserCounts: { [CLIENT_USER_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENT_USERS: {
            return {
                ...state,
                clientUsers: action.payload,
            };
        }
        case GET_CLIENT_USER_COUNT: {
            return {
                ...state,
                clientUserCounts: action.payload,
            };
        }
        case SET_CLIENT_USER_DETAILS: {
            return {
                ...state,
                currentClientUser: action.payload,
            };
        }
        case ADD_CLIENT_USER: {
            return {
                ...state,
                clientUsers: [action.payload, ...state.clientUsers],
            };
        }
        case EDIT_CLIENT_USER: {
            return {
                ...state,
                clientUsers: state.clientUsers.map((clientUser) =>
                    clientUser.id === action.payload.id
                        ? action.payload
                        : clientUser
                ),
            };
        }
        case DELETE_CLIENT_USER: {
            return {
                ...state,
                clientUsers: state.clientUsers.filter(
                    (clientUser) => clientUser.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
