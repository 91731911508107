import ListItem from "@mui/material/ListItem";
import styled from "@mui/material/styles/styled";

const StyledSidebarItem = styled(ListItem)(({ theme }) => {
    const mainColor = theme.palette.primary.main;
    const breakpoints = theme.breakpoints;

    const styles = {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1, 2),
        border: `1px solid ${mainColor}`,
        boxShadow: theme.shadows[1],
        color: mainColor,
        "& .MuiListItemText-root .MuiListItemText-primary": {
            fontSize: theme.typography.h4.fontSize,
        },
        "&:hover": {
            cursor: "pointer",
            boxShadow: theme.shadows[4],
            color: mainColor,
        },
        "& .MuiListItemText-root": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& .MuiListItemText-primary": {
                marginRight: theme.spacing(0.5),
            },
            [breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "start",
            },
        },
    };

    return styles;
});

export default StyledSidebarItem;
