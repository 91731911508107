import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";

const HeadingStack = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        alignItems: "start",
        justifyContent: "start",
    },
}));

export default HeadingStack;
