import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UploadIcon from "@mui/icons-material/Upload";

const FmButton = ({
    className,
    endIcon,
    label,
    size,
    smSx,
    startIcon,
    sx,
    type,
    variant,
    ...restProps
}) => {
    const theme = useTheme();

    const btnMainColor =
        theme.palette?.miscellaneous?.button?.main || "#112434";
    const btnRedColor = theme.palette.secondary.main;
    const btnContrastColor =
        theme.palette?.miscellaneous?.button?.contrastText || "#FFF";

    const defaultSx = {
        padding: theme.spacing(1, 2),
        border: `2px solid ${btnRedColor}`,
        backgroundColor: "transparent",
        color: btnMainColor,
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.h4.fontWeight,
        "&:hover": {
            border: `2px solid ${btnRedColor}`,
            backgroundColor: btnRedColor,
            color: btnContrastColor,
        },
    };

    let startIconNode = null;
    switch (startIcon) {
        case "add":
            startIconNode = <AddIcon />;
            break;

        case "description":
            startIconNode = <DescriptionIcon />;
            break;

        case "downloadFile":
            startIconNode = <FileDownloadOutlinedIcon />;
            break;

        case "edit":
            startIconNode = <EditIcon />;
            break;

        case "groupAdd":
            startIconNode = <GroupAddIcon />;
            break;

        case "upload":
            startIconNode = <UploadIcon />;
            break;

        default:
            break;
    }

    let endIconNode = null;
    switch (endIcon) {
        case "openInNew":
            endIconNode = <OpenInNewIcon />;
            break;

        default:
            break;
    }

    return (
        <Button
            type={type}
            variant={variant}
            size={size}
            endIcon={endIconNode}
            startIcon={startIconNode}
            className={className ? `fmButton ${className}` : `fmButton`}
            sx={{
                ...defaultSx,
                ...sx,
                [theme.breakpoints.down("md")]: {
                    ...smSx,
                },
            }}
            {...restProps}
        >
            {label}
        </Button>
    );
};

FmButton.propTypes = {
    className: PropTypes.string,
    endIcon: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    smSx: PropTypes.object,
    startIcon: PropTypes.string,
    sx: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func,
};

FmButton.defaultProps = {
    className: "",
    endIcon: "",
    label: "",
    size: "medium",
    smSx: {},
    sx: {},
    type: "button",
    variant: "outlined",
    onClick: () => {},
};

export default FmButton;
