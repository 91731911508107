import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import EmptyValue from "../../shared/widgets/EmptyValue";
import HeadingStack from "../../shared/widgets/HeadingStack";
import HTMLTypography from "../../shared/widgets/HTMLTypography";
import MultilineTypography from "../../shared/widgets/MultilineTypography";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    getSurveyAllQuestionTypes,
    getSurveyOptionsQuestionTypes,
} from "../../utils/appHelpers";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SURVEY_IS_SUBMITTED,
    SURVEY_KEYS,
    SURVEY_QUESTION_KEYS,
    SURVEY_QUESTION_OPTION_KEYS,
    SURVEY_QUESTION_OPTION_IS_ANSWER,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import { getClientSurvey } from "../../redux/actions/surveys";

const allQuestionTypesData = getSurveyAllQuestionTypes();
const allQuestionTypes = allQuestionTypesData.map(
    (tempQuesType) => tempQuesType.value
);

const optionsQuestionTypesData = getSurveyOptionsQuestionTypes();

const genericPageName = "Survey Responses";

const SurveyResponses = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isSurveyResponsesMounted = useRef(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();

    const surveyId = params.surveyId;

    const [surveyIsValid, setSurveyIsValid] = useState(false);
    const [fetchingSurvey, setFetchingSurvey] = useState(false);
    const [surveyFetched, setSurveyFetched] = useState(false);
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const [questions, setQuestions] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    useEffect(() => {
        return () => {
            _isSurveyResponsesMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isValidUserType && surveyId) {
            setFetchingSurvey(true);
        }
    }, [isValidUserType, surveyId]);

    useEffect(() => {
        let isActive = true;

        if (isValidUserType && surveyId && fetchingSurvey) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getClientSurvey(payload, (fetchedSurvey) => {
                            if (isActive) {
                                setFetchingSurvey(false);
                                setSurveyFetched(true);
                                setCurrentSurvey(fetchedSurvey);
                            }
                        })
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.SURVEY_ID]: surveyId,
            };

            const promise = dispatch(fetchData({ surveyData: surveyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchingSurvey(false);
                    setSurveyFetched(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        fetchingSurvey,
        isValidUserType,
        surveyFetched,
        surveyId,
    ]);

    useEffect(() => {
        if (
            currentSurvey &&
            currentSurvey?.[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] === surveyId
        ) {
            const currentSurveyQuestions =
                currentSurvey?.[SURVEY_KEYS.QUESTIONS] || [];

            setQuestions(currentSurveyQuestions);
            setSurveyIsValid(true);
        } else {
            setQuestions([]);
            setSurveyIsValid(false);
        }
    }, [currentSurvey, surveyId]);

    if (questions.length === 0)
        return (
            <Alert variant="outlined" severity="error">
                No questions found for this survey!
            </Alert>
        );

    if (surveyIsValid) {
        let optionsNonZero = true;
        let questionTypesValid = true;
        for (let i = 0; i < questions.length; i++) {
            const questionType =
                questions[i]?.[SURVEY_QUESTION_KEYS.TYPE] || "";
            if (questionType) {
                if (!allQuestionTypes.includes(questionType)) {
                    questionTypesValid = false;
                    break;
                }
            } else {
                questionTypesValid = false;
                break;
            }

            if (optionsQuestionTypesData.includes(questionType)) {
                if (questions[i]?.[SURVEY_QUESTION_KEYS.OPTIONS]) {
                    const questionOptions =
                        questions[i][SURVEY_QUESTION_KEYS.OPTIONS];
                    if (questionOptions && questionOptions.length > 0) {
                        continue;
                    } else {
                        optionsNonZero = false;
                        break;
                    }
                } else {
                    optionsNonZero = false;
                    break;
                }
            }
        }

        if (!questionTypesValid || !optionsNonZero)
            return (
                <Alert variant="outlined" severity="error">
                    There was a problem in loading the questions!
                </Alert>
            );
    }

    return (
        <Grid container spacing={3.5}>
            {!fetchingSurvey && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <HeadingStack>
                            <PageTitle
                                component="h1"
                                title={
                                    surveyIsValid
                                        ? currentSurvey[SURVEY_KEYS.NAME]
                                        : "Invalid Survey!"
                                }
                                type="v2"
                            />
                            <Breadcrumb
                                pageNames={{
                                    genericName: genericPageName,
                                    specificName: surveyIsValid
                                        ? currentSurvey[SURVEY_KEYS.NAME]
                                        : genericPageName,
                                }}
                            />
                        </HeadingStack>
                    </Grid>

                    {isValidUserType && surveyIsValid && (
                        <Grid item xs={12}>
                            <HTMLTypography
                                component="span"
                                variant="h5"
                                content={currentSurvey[SURVEY_KEYS.DESCRIPTION]}
                            />
                        </Grid>
                    )}
                    {isValidUserType && surveyIsValid && (
                        <React.Fragment>
                            {currentSurvey?.[SURVEY_KEYS.IS_SUBMITTED] &&
                                currentSurvey[SURVEY_KEYS.IS_SUBMITTED] !==
                                    SURVEY_IS_SUBMITTED && (
                                    <Grid item xs={12}>
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                        >
                                            Survey is not submitted yet!
                                        </Alert>
                                    </Grid>
                                )}

                            {currentSurvey?.[SURVEY_KEYS.IS_SUBMITTED] &&
                                currentSurvey[SURVEY_KEYS.IS_SUBMITTED] ===
                                    SURVEY_IS_SUBMITTED && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            {questions.map(
                                                (questionObj, idx) => {
                                                    const questionId =
                                                        questionObj[
                                                            SURVEY_QUESTION_KEYS
                                                                .ID
                                                        ];
                                                    const questionType =
                                                        questionObj[
                                                            SURVEY_QUESTION_KEYS
                                                                .TYPE
                                                        ];
                                                    const options =
                                                        questionObj[
                                                            SURVEY_QUESTION_KEYS
                                                                .OPTIONS
                                                        ];
                                                    const textResponse =
                                                        questionObj?.[
                                                            SURVEY_QUESTION_KEYS
                                                                .TEXT_RESPONSE
                                                        ] || "";

                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            key={`question-${questionId}`}
                                                        >
                                                            <Card>
                                                                <CardHeader
                                                                    title={`${
                                                                        idx + 1
                                                                    }. ${
                                                                        questionObj[
                                                                            SURVEY_QUESTION_KEYS
                                                                                .TITLE
                                                                        ]
                                                                    }`}
                                                                    sx={{
                                                                        pb: 0,
                                                                    }}
                                                                />
                                                                <CardContent>
                                                                    {optionsQuestionTypesData.includes(
                                                                        questionType
                                                                    ) ? (
                                                                        <List>
                                                                            {options.map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    const optionId =
                                                                                        option[
                                                                                            SURVEY_QUESTION_OPTION_KEYS
                                                                                                .ID
                                                                                        ];
                                                                                    const isAnswer =
                                                                                        option[
                                                                                            SURVEY_QUESTION_OPTION_KEYS
                                                                                                .IS_ANSWER
                                                                                        ] ===
                                                                                        SURVEY_QUESTION_OPTION_IS_ANSWER;

                                                                                    const optionKey = `question-${questionId}-option-${optionId}`;
                                                                                    const labelId = `label-${optionKey}`;

                                                                                    return (
                                                                                        <ListItem
                                                                                            key={
                                                                                                optionKey
                                                                                            }
                                                                                            disablePadding
                                                                                        >
                                                                                            <ListItemText
                                                                                                id={
                                                                                                    labelId
                                                                                                }
                                                                                                sx={(
                                                                                                    theme
                                                                                                ) => ({
                                                                                                    "& .MuiTypography-root":
                                                                                                        {
                                                                                                            color: isAnswer
                                                                                                                ? theme
                                                                                                                      .palette
                                                                                                                      .success
                                                                                                                      .main
                                                                                                                : theme
                                                                                                                      .palette
                                                                                                                      .primary
                                                                                                                      .main,
                                                                                                            fontWeight:
                                                                                                                isAnswer
                                                                                                                    ? theme
                                                                                                                          .typography
                                                                                                                          .fontWeightBold
                                                                                                                    : theme
                                                                                                                          .typography
                                                                                                                          .fontWeightRegular,
                                                                                                        },
                                                                                                })}
                                                                                            >
                                                                                                {
                                                                                                    option[
                                                                                                        SURVEY_QUESTION_OPTION_KEYS
                                                                                                            .TITLE
                                                                                                    ]
                                                                                                }
                                                                                            </ListItemText>
                                                                                        </ListItem>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </List>
                                                                    ) : textResponse ? (
                                                                        <MultilineTypography>
                                                                            {
                                                                                textResponse
                                                                            }
                                                                        </MultilineTypography>
                                                                    ) : (
                                                                        <EmptyValue />
                                                                    )}
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                        </React.Fragment>
                    )}
                    {isValidUserType && !surveyIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid survey!
                            </Alert>
                        </Grid>
                    )}
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default SurveyResponses;
