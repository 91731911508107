import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { CKEditor } from "ckeditor4-react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getAllCompanies } from "../../redux/actions/companies";
import { addSpecial, updateSpecial } from "../../redux/actions/specials";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import DndWrapper from "../../shared/widgets/DndWrapper";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { formatBytes } from "../../utils/appHelpers";
import { getMonthsList } from "../../utils/dateHelpers";
import {
    AUTH_USER_KEYS,
    CKEDITOR_TOOLBAR,
    COMPANY_KEYS,
    SPECIAL_KEYS,
    SPECIAL_DOCUMENT_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
    VALID_COMMON_FILE_MAX_SIZE_MB,
    VALID_COMMON_FILES_COUNT,
    VALID_SPECIAL_FILE_EXTENSIONS,
    VALID_SPECIAL_FILE_MIME_TYPES,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";
import {
    checkAssignedCompanyValid,
    checkSpecialDescriptionValid,
    /* checkSpecialDocumentsValid, */
    checkSpecialMonthValid,
    checkTitleValid,
} from "../../utils/validationHelpers";

const monthsList = getMonthsList();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddEditSpecialDialog = ({
    currentSpecial,
    open,
    setGlobalState,
    onClose,
    onInsert,
    onUpdate,
}) => {
    const _isAddEditSpecialDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: VALID_SPECIAL_FILE_MIME_TYPES.join(","),
        maxSize: VALID_COMMON_FILE_MAX_SIZE_MB * 1024 * 1024,
        maxFiles: VALID_COMMON_FILES_COUNT,
        onDrop: (acceptedFiles, fileRejections) => {
            setDocumentsError("");
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "too-many-files") {
                        setDocumentsError(
                            `Error: Maximum number of allowed files is ${VALID_COMMON_FILES_COUNT}`
                        );
                    }

                    if (err.code === "file-too-large") {
                        setDocumentsError(
                            `Error: Maximum allowed file size is ${VALID_COMMON_FILE_MAX_SIZE_MB} MB`
                        );
                    }

                    if (err.code === "file-invalid-type") {
                        setDocumentsError(
                            `Error: File type must be ${VALID_SPECIAL_FILE_EXTENSIONS.join(
                                ", "
                            )}`
                        );
                    }
                });
            });
        },
    });
    const { authToken, authUser } = useJumboAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [allCompanies, setAllCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const [companyError, setCompanyError] = useState("");
    const [month, setMonth] = useState("");
    const [monthError, setMonthError] = useState("");
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [documents, setDocuments] = useState([]);
    const [documentsError, setDocumentsError] = useState("");
    const [deletedDocuments, setDeletedDocuments] = useState([]);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    const canAssignCompany = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isAddEditSpecialDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAllCompanies([]);
            setCompany(null);
            setCompanyError("");
            setMonth("");
            setMonthError("");
            setTitle("");
            setTitleError("");
            setDescription("");
            setDescriptionError("");
            setDocuments([]);
            setDocumentsError("");
            setDeletedDocuments([]);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch all companies */
    useEffect(() => {
        let isActive = true;

        if (isLoading) {
            if (canAssignCompany) {
                const fetchData = (payload) => {
                    return (dispatch, getState) => {
                        return dispatch(
                            getAllCompanies(payload, (fetchedCompanies) => {
                                if (isActive) {
                                    setAllCompanies(
                                        fetchedCompanies?.companies || []
                                    );
                                    setIsLoading(false);
                                }
                            })
                        );
                    };
                };

                const companyData = { authcode: authToken };
                const promise = dispatch(
                    fetchData({ companyData: companyData, fetchStart: true })
                );
                promise.catch((error) => {
                    if (isActive) {
                        setAllCompanies([]);
                        setIsLoading(false);
                    }
                });
            } else {
                setIsLoading(false);
            }
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, canAssignCompany, isLoading]);

    useEffect(() => {
        if (currentSpecial) {
            setMonth(currentSpecial[SPECIAL_KEYS.MONTH]);
            setTitle(currentSpecial[SPECIAL_KEYS.TITLE]);
            setDescription(currentSpecial[SPECIAL_KEYS.DESCRIPTION]);
            setDocuments(currentSpecial[SPECIAL_KEYS.UPLOADED_DOCUMENTS] || []);
            setDeletedDocuments([]);
        }
    }, [currentSpecial]);

    /* useEffect(() => {
        const acceptedFilesValidationResult =
            checkSpecialDocumentsValid(acceptedFiles);
        const documentsValidationResult = checkSpecialDocumentsValid(documents);
        if (
            (acceptedFilesValidationResult.status &&
                acceptedFilesValidationResult.status === "true") ||
            (documentsValidationResult.status &&
                documentsValidationResult.status === "true")
        ) {
            setDocumentsError("");
        }
    }, [acceptedFiles, documents]); */

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case SPECIAL_KEYS.COMPANY_ID:
                        setCompanyError(msg);
                        break;

                    case SPECIAL_KEYS.MONTH:
                        setMonthError(msg);
                        break;

                    case SPECIAL_KEYS.TITLE:
                        setTitleError(msg);
                        break;

                    case SPECIAL_KEYS.DESCRIPTION:
                        setDescriptionError(msg);
                        break;

                    case SPECIAL_KEYS.DOCUMENTS:
                        setDocumentsError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const companyChangeHandler = (event, newValue) => {
        setCompanyError("");

        const assignedCompanyValidationResult = checkAssignedCompanyValid(
            newValue,
            allCompanies
        );
        if (assignedCompanyValidationResult.status) {
            if (assignedCompanyValidationResult.status === "false") {
                setCompany(null);
                setCompanyError(assignedCompanyValidationResult.msg);
            } else {
                setCompany(newValue);
            }
        } else {
            setCompany(null);
            setCompanyError(VALIDATION_FAILED);
        }
    };

    const handleDeleteDocument = (deletedDocument) => {
        setDeletedDocuments((prevState) => [
            ...prevState,
            deletedDocument[SPECIAL_DOCUMENT_KEYS.ID],
        ]);
        setDocuments((prevState) =>
            prevState.filter((tempDocument) => {
                return (
                    tempDocument[SPECIAL_DOCUMENT_KEYS.ID] !==
                    deletedDocument[SPECIAL_DOCUMENT_KEYS.ID]
                );
            })
        );
    };

    const monthChangeHandler = (event) => {
        setMonthError("");
        const monthValidationResult = checkSpecialMonthValid(
            event.target.value,
            monthsList
        );
        if (monthValidationResult.status) {
            if (monthValidationResult.status === "false") {
                setMonth("");
                setMonthError(monthValidationResult.msg);
            } else {
                setMonth(event.target.value);
            }
        } else {
            setMonth("");
            setMonthError(VALIDATION_FAILED);
        }
    };

    const titleBlurHandler = (event) => {
        const validationResult = checkTitleValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setTitleError(validationResult.msg);
            }
        } else {
            setTitleError(VALIDATION_FAILED);
        }
    };

    const descriptionChangeHandler = (event) => {
        setDescription(event.editor.getData());
        setDescriptionError("");
    };

    const descriptionBlurHandler = (event) => {
        const validationResult = checkSpecialDescriptionValid(
            event.editor.getData()
        );
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDescriptionError(validationResult.msg);
            }
        } else {
            setDescriptionError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        if (canAssignCompany && !currentSpecial) {
            const companyValidationResult = checkAssignedCompanyValid(
                company,
                allCompanies
            );
            if (companyValidationResult.status) {
                if (companyValidationResult.status === "false") {
                    formIsValid = false;
                    setCompanyError(companyValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setCompanyError(VALIDATION_FAILED);
            }
        }

        const monthValidationResult = checkSpecialMonthValid(month, monthsList);
        if (monthValidationResult.status) {
            if (monthValidationResult.status === "false") {
                formIsValid = false;
                setMonthError(monthValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setMonthError(VALIDATION_FAILED);
        }

        const titleValidationResult = checkTitleValid(title);
        if (titleValidationResult.status) {
            if (titleValidationResult.status === "false") {
                formIsValid = false;
                setTitleError(titleValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setTitleError(VALIDATION_FAILED);
        }

        const descriptionValidationResult =
            checkSpecialDescriptionValid(description);
        if (descriptionValidationResult.status) {
            if (descriptionValidationResult.status === "false") {
                formIsValid = false;
                setDescriptionError(descriptionValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDescriptionError(VALIDATION_FAILED);
        }

        /* let documentsIsValid = false;
        if (currentSpecial) {
            if (!deletedDocuments || deletedDocuments.length === 0) {
                // Means existing documents have not been changed
                documentsIsValid = false;
            } else {
                const documentsValidationResult =
                    checkSpecialDocumentsValid(documents);
                if (documentsValidationResult.status) {
                    if (documentsValidationResult.status === "false") {
                        documentsIsValid = false;
                    } else {
                        documentsIsValid = true;
                    }
                } else {
                    documentsIsValid = false;
                }
            }
        } else {
            documentsIsValid = true;
        }

        const acceptedFilesValidationResult =
            checkSpecialDocumentsValid(acceptedFiles);
        if (acceptedFilesValidationResult.status) {
            if (acceptedFilesValidationResult.status === "false") {
                if (!documentsIsValid) {
                    formIsValid = false;
                    setDocumentsError(acceptedFilesValidationResult.msg);
                }
            }
        } else {
            if (!documentsIsValid) {
                formIsValid = false;
                setDocumentsError(VALIDATION_FAILED);
            }
        } */

        if (formIsValid) {
            const specialData = new FormData();
            specialData.append("authcode", authToken);
            if (canAssignCompany && !currentSpecial) {
                specialData.append(
                    [SPECIAL_KEYS.COMPANY_ID],
                    company?.[COMPANY_KEYS.ID] || ""
                );
            }
            specialData.append([SPECIAL_KEYS.MONTH], month);
            specialData.append([SPECIAL_KEYS.TITLE], title);
            specialData.append([SPECIAL_KEYS.DESCRIPTION], description);
            specialData.append(
                [SPECIAL_KEYS.DELETED_DOCUMENTS],
                deletedDocuments.length > 0 ? deletedDocuments.join(",") : ""
            );

            if (acceptedFiles.length > 0) {
                for (let i = 0; i < acceptedFiles.length; i++) {
                    specialData.append(
                        [SPECIAL_KEYS.DOCUMENTS],
                        acceptedFiles[i]
                    );
                }
            } else {
                specialData.append([SPECIAL_KEYS.DOCUMENTS], null);
            }

            if (currentSpecial) {
                specialData.append(
                    [SPECIAL_KEYS.SPECIAL_ID],
                    currentSpecial[SPECIAL_KEYS.ID]
                );

                dispatch(
                    updateSpecial(
                        {
                            specialData: specialData,
                            setGlobalState: setGlobalState,
                        },
                        (updatedSpecial) => {
                            if (_isAddEditSpecialDialogMounted.current) {
                                onUpdate(updatedSpecial);
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditSpecialDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                dispatch(
                    addSpecial(
                        {
                            specialData: specialData,
                            setGlobalState: setGlobalState,
                        },
                        () => {
                            if (_isAddEditSpecialDialogMounted.current) {
                                onInsert();
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditSpecialDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    const loadError = !isValidUserType;

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentSpecial ? "Edit Special" : "Add Special"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {!isLoading && loadError && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!isLoading && !loadError && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            {canAssignCompany && !currentSpecial && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            filterSelectedOptions
                                            id="company_id"
                                            options={allCompanies}
                                            value={company}
                                            getOptionLabel={(option) => {
                                                return option &&
                                                    option[COMPANY_KEYS.ID]
                                                    ? option[COMPANY_KEYS.NAME]
                                                    : "";
                                            }}
                                            isOptionEqualToValue={(
                                                option,
                                                newValue
                                            ) => {
                                                return (
                                                    option[COMPANY_KEYS.ID] ===
                                                    newValue[COMPANY_KEYS.ID]
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Select Company"
                                                    error={companyError !== ""}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li
                                                    {...props}
                                                    key={
                                                        option[COMPANY_KEYS.ID]
                                                    }
                                                >
                                                    {option[COMPANY_KEYS.NAME]}
                                                </li>
                                            )}
                                            onChange={companyChangeHandler}
                                        />
                                        {companyError && (
                                            <FormHelperText
                                                error
                                                id="company_id-error"
                                            >
                                                {companyError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel
                                        id="special-month-select-label"
                                        error={monthError !== ""}
                                    >
                                        Month
                                    </InputLabel>
                                    <Select
                                        labelId="special-month-select-label"
                                        id="special-month-select"
                                        value={month}
                                        label="Month"
                                        margin="dense"
                                        onChange={monthChangeHandler}
                                        input={
                                            <OutlinedInput
                                                fullWidth
                                                label="Month"
                                                error={monthError !== ""}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {monthsList.map((monthObj) => (
                                            <MenuItem
                                                key={`${monthObj.value}-month`}
                                                value={monthObj.value}
                                            >
                                                {monthObj.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {monthError && (
                                        <FormHelperText
                                            error
                                            id="special-month-select-error"
                                        >
                                            {monthError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="title"
                                    name="title"
                                    label="Title"
                                    value={title}
                                    margin="dense"
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setTitleError("");
                                    }}
                                    onBlur={titleBlurHandler}
                                    error={titleError !== ""}
                                    helperText={titleError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <CKEditor
                                        id="description"
                                        initData={description}
                                        config={{
                                            contentsCss: [
                                                `${ASSET_MUSEO_SANS}/styles.css`,
                                                `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                            ],
                                            toolbar: CKEDITOR_TOOLBAR,
                                            removePlugins: "image",
                                        }}
                                        onBlur={descriptionBlurHandler}
                                        onChange={descriptionChangeHandler}
                                        /* onKey={descriptionChangeHandler} */
                                    />
                                    {descriptionError && (
                                        <FormHelperText
                                            error
                                            id="description-error"
                                        >
                                            {descriptionError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Div sx={{ flex: 1 }}>
                                    {documents.length > 0 && (
                                        <React.Fragment>
                                            <Typography variant="p">
                                                Uploaded Files
                                            </Typography>
                                            <List
                                                disablePadding
                                                sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                {documents.map((document) => (
                                                    <ListItem
                                                        key={
                                                            document[
                                                                SPECIAL_DOCUMENT_KEYS
                                                                    .ID
                                                            ]
                                                        }
                                                        component={Link}
                                                        href={
                                                            document[
                                                                SPECIAL_DOCUMENT_KEYS
                                                                    .LINK
                                                            ]
                                                        }
                                                        target="_blank"
                                                        rel="noopener"
                                                        underline="hover"
                                                        secondaryAction={
                                                            <TableActionIcon
                                                                iconComponent="delete"
                                                                label="delete document"
                                                                tooltip="Delete Document"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleDeleteDocument(
                                                                        document
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        sx={{
                                                            width: "auto",
                                                            mr: 1,
                                                            flex: "1 1 100%",
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={`${
                                                                document[
                                                                    SPECIAL_DOCUMENT_KEYS
                                                                        .NAME
                                                                ]
                                                            } - ${formatBytes(
                                                                document[
                                                                    SPECIAL_DOCUMENT_KEYS
                                                                        .SIZE
                                                                ]
                                                            )}`}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                    )}
                                    <DndWrapper>
                                        <div
                                            {...getRootProps({
                                                className:
                                                    "dropzoneCustomInputBtn",
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography variant={"body1"}>
                                                Drag 'n' drop some files here,
                                                or click to select files
                                            </Typography>
                                        </div>
                                    </DndWrapper>
                                    <Typography variant="p">
                                        Selected Files
                                    </Typography>
                                    {acceptedFiles.length > 0 ? (
                                        <List
                                            disablePadding
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {acceptedFiles.map((file) => (
                                                <ListItem
                                                    key={file.path}
                                                    sx={{
                                                        width: "auto",
                                                        mr: 1,
                                                        flex: "1 1 100%",
                                                    }}
                                                >
                                                    {`${
                                                        file.path
                                                    } - ${formatBytes(
                                                        file.size
                                                    )}`}
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        !documentsError && (
                                            <Alert severity="info">
                                                No files selected!
                                            </Alert>
                                        )
                                    )}
                                    {documentsError && (
                                        <Alert severity="error">
                                            {documentsError}
                                        </Alert>
                                    )}
                                </Div>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {currentSpecial
                                        ? "Update Special"
                                        : "Add Special"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditSpecialDialog.propTypes = {
    currentSpecial: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    onClose: PropTypes.func,
    onInsert: PropTypes.func,
    onUpdate: PropTypes.func,
};

AddEditSpecialDialog.defaultProps = {
    currentSpecial: null,
    open: false,
    setGlobalState: false,
    // onClose: () => {},
    onInsert: () => {},
    onUpdate: () => {},
};

export default AddEditSpecialDialog;
