import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { ALERT_KEYS } from "../../utils/constants/appData";

const AlertsList = ({ alerts, onMarkRead }) => {
    return (
        <Grid container spacing={4}>
            {alerts.map((alert) => {
                const alertId = alert[ALERT_KEYS.ID];
                return (
                    <Grid item key={`alert-${alertId}`} xs={12} sx={{ p: 0 }}>
                        <Card
                            sx={(theme) => ({
                                padding: theme.spacing(1),
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 12px 15px #0000000D",
                                borderRadius: "10px",
                                opacity: 1,
                            })}
                        >
                            <CardContent sx={{ p: 1 }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        noWrap
                                        variant={"h5"}
                                        align="left"
                                        sx={{ wordWrap: "break-word" }}
                                    >
                                        {alert?.[ALERT_KEYS.TITLE] || ""}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        variant={"p"}
                                        align="left"
                                        sx={{ wordWrap: "break-word" }}
                                    >
                                        {alert[ALERT_KEYS.SENT_DATE]}
                                    </Typography>
                                </Stack>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: alert[ALERT_KEYS.MESSAGE],
                                    }}
                                ></Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Tooltip title="Mark Read">
                                    <IconButton
                                        type="button"
                                        onClick={() => onMarkRead(alert)}
                                        sx={{ color: "#888888" }}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </Tooltip> */}
                                <Button
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    onClick={() => onMarkRead(alert)}
                                    startIcon={<CheckIcon />}
                                >
                                    Mark as read
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

AlertsList.propTypes = {
    alerts: PropTypes.array,
    onMarkRead: PropTypes.func,
};

AlertsList.defaultProps = {
    alerts: [],
    onMarkRead: () => {},
};

export default AlertsList;
