import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { getFilteredCompaniesLocalState } from "../../redux/actions/companies";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    COMPANY_ACTIVE_STATUS,
    COMPANY_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import ReportsCompaniesTable from "./ReportsCompaniesTable";

const pageName = "Reports Companies";

const INIT_COMPANIES_STATE = {
    companiesFetched: true,
    fetchCompanies: false,
    firstLoad: true,
    order: SORT_ORDER_ASC,
    page: 0,
    rowsPerPage: 10,
    sort: COMPANY_KEYS.NAME,
    sorted: false,
};

const companiesReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, companiesFetched: false, fetchCompanies: true };
        } else if (action.type === "RESET") {
            return {
                ...state,
                companiesFetched: false,
                fetchCompanies: false,
                firstLoad: false,
                page: 0,
                sorted: false,
            };
        } else if (action.type === "SET_LIMIT_AND_FETCH") {
            return {
                ...state,
                companiesFetched: false,
                fetchCompanies: true,
                page: 0,
                rowsPerPage: action.rowsPerPage,
            };
        } else if (action.type === "SET_PAGE_AND_FETCH") {
            return {
                ...state,
                companiesFetched: false,
                fetchCompanies: true,
                page: action.page,
            };
        } else if (action.type === "SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                companiesFetched: false,
                fetchCompanies: true,
                page: 0,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                sort: updatedSort,
                sorted: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                companiesFetched: true,
                fetchCompanies: false,
                firstLoad: false,
            };
        }
    }

    return INIT_COMPANIES_STATE;
};

const ReportsCompanies = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isReportsCompaniesMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [companyCounts, setCompanyCounts] = useState(null);

    const [companiesState, dispatchCompaniesAction] = useReducer(
        companiesReducer,
        INIT_COMPANIES_STATE
    );

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isReportsCompaniesMounted.current = false;
        };
    }, []);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch companies list */
    useEffect(() => {
        if (isLoading) {
            dispatchCompaniesAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (companiesState.fetchCompanies) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredCompaniesLocalState(
                            payload,
                            (fetchedCompaniesData) => {
                                if (isActive) {
                                    dispatchCompaniesAction({
                                        type: "STOP_FETCH",
                                    });
                                    setCompanies(
                                        fetchedCompaniesData?.companies || []
                                    );
                                    setCompanyCounts(
                                        fetchedCompaniesData?.totals || null
                                    );
                                    setIsLoading(false);
                                }
                            }
                        )
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                page: companiesState.page + 1,
                rows_per_page: companiesState.rowsPerPage,
                sort: companiesState.sort,
                order: companiesState.order,
                [COMPANY_KEYS.STATUS]: COMPANY_ACTIVE_STATUS,
            };

            const payload = { companyData: companyData };
            if (!companiesState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    dispatchCompaniesAction({
                        type: "RESET",
                    });
                    setCompanies([]);
                    setCompanyCounts(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, companiesState]);

    const handlePageChange = (event, newPage) => {
        dispatchCompaniesAction({ type: "SET_PAGE_AND_FETCH", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchCompaniesAction({
            type: "SET_LIMIT_AND_FETCH",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchCompaniesAction({
            type: "SORT_AND_FETCH",
            sort: property,
        });
    };

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError =
        !companiesState.fetchCompanies && !companiesState.companiesFetched;
    const loadError = !isValidUserType || fetchError;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle component="h1" title={pageName} type="v2" />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the companies!
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <Grid item xs={12}>
                    <ReportsCompaniesTable
                        companies={companies}
                        companyCounts={companyCounts}
                        companiesFetched={companiesState.companiesFetched}
                        order={companiesState.order}
                        page={companiesState.page}
                        rowsPerPage={companiesState.rowsPerPage}
                        sort={companiesState.sort}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        onSort={handleSort}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ReportsCompanies;
