import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import LinkButton from "../../shared/widgets/LinkButton";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { getAssetFileNameFromLink } from "../../utils/accountAssetHelpers";
import {
    ACCOUNT_ASSET_KEYS,
    COMPANY_KEYS,
    FOLDER_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const PendingAssetsTable = ({
    accountAssets,
    accountAssetCounts,
    accountAssetsFetched,
    canApproveRequest,
    canRejectRequest,
    canResendRequest,
    columnsToShow,
    fetchAccountAssets,
    headCells,
    order,
    page,
    rowsPerPage,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onApprove,
    onDownload,
    onReject,
    onResend,
    onSort,
    onViewChat,
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const accountAssetCountNonZero =
        accountAssets &&
        accountAssets.length > 0 &&
        accountAssetCounts &&
        accountAssetCounts[ACCOUNT_ASSET_KEYS.TOTAL] &&
        !isNaN(parseInt(accountAssetCounts[ACCOUNT_ASSET_KEYS.TOTAL])) &&
        parseInt(accountAssetCounts[ACCOUNT_ASSET_KEYS.TOTAL]) > 0;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Pending Assets Table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            <TableCell
                                key="actions"
                                sx={{ width: "15%", textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {accountAssetCountNonZero ? (
                            accountAssets.map((accountAsset) => {
                                const companyId =
                                    accountAsset[ACCOUNT_ASSET_KEYS.COMPANY_ID];

                                const company =
                                    accountAsset?.[
                                        ACCOUNT_ASSET_KEYS.COMPANY
                                    ] || null;

                                let folderId = "";
                                let folderName = "";

                                const folder =
                                    accountAsset?.[ACCOUNT_ASSET_KEYS.FOLDER] ||
                                    null;
                                if (folder) {
                                    folderId = folder?.[FOLDER_KEYS.ID] || "";
                                    folderName =
                                        folder?.[FOLDER_KEYS.NAME] || "NA";
                                }

                                const assetName =
                                    accountAsset[
                                        ACCOUNT_ASSET_KEYS.DOCUMENT_NAME
                                    ];

                                const assetLink =
                                    accountAsset[
                                        ACCOUNT_ASSET_KEYS.DOCUMENT_LINK
                                    ];

                                const assetNameFromLink =
                                    getAssetFileNameFromLink(assetLink);

                                const assetMimeType =
                                    accountAsset[ACCOUNT_ASSET_KEYS.MIME_TYPE];
                                const assetExtension =
                                    accountAsset[ACCOUNT_ASSET_KEYS.EXTENSION];

                                return (
                                    <TableRow
                                        key={`${
                                            accountAsset[ACCOUNT_ASSET_KEYS.ID]
                                        }-${companyId}`}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        {columnsToShow.includes(
                                            ACCOUNT_ASSET_KEYS.COMPANY_NAME
                                        ) && (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <NameLink
                                                    component={RouterLink}
                                                    to={`/company/${companyId}/dashboard/`}
                                                >
                                                    {company?.[
                                                        COMPANY_KEYS.NAME
                                                    ] || ""}
                                                </NameLink>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            {folderId ? (
                                                <NameLink
                                                    component={RouterLink}
                                                    to={`/accountAssets/folder/${folderId}/${companyId}/`}
                                                >
                                                    {folderName}
                                                </NameLink>
                                            ) : (
                                                { folderName }
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {[
                                                "csv",
                                                "doc",
                                                "docx",
                                                "xlsx",
                                            ].includes(assetExtension) ? (
                                                <LinkButton
                                                    component="button"
                                                    onClick={() => {
                                                        onDownload(
                                                            assetLink,
                                                            assetNameFromLink,
                                                            assetMimeType
                                                        );
                                                    }}
                                                >
                                                    {assetName}
                                                </LinkButton>
                                            ) : (
                                                <NameLink
                                                    download={assetNameFromLink}
                                                    href={assetLink}
                                                    target="_blank"
                                                >
                                                    {assetName}
                                                </NameLink>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                accountAsset[
                                                    ACCOUNT_ASSET_KEYS
                                                        .CREATED_AT
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <TableActionIcon
                                                    iconComponent="forum"
                                                    label="view discussion"
                                                    tooltip="View Discussion"
                                                    onClick={() => {
                                                        onViewChat(
                                                            accountAsset
                                                        );
                                                    }}
                                                />
                                                {canResendRequest && (
                                                    <TableActionIcon
                                                        iconComponent="send"
                                                        label="resend for approval"
                                                        tooltip="Resend for Approval"
                                                        onClick={() =>
                                                            onResend(
                                                                accountAsset
                                                            )
                                                        }
                                                    />
                                                )}
                                                {canApproveRequest && (
                                                    <TableActionIcon
                                                        iconComponent="done"
                                                        label="approve"
                                                        tooltip="Approve"
                                                        onClick={() =>
                                                            onApprove(
                                                                accountAsset
                                                            )
                                                        }
                                                    />
                                                )}
                                                {canRejectRequest && (
                                                    <TableActionIcon
                                                        iconComponent="close"
                                                        label="reject"
                                                        tooltip="Reject"
                                                        onClick={() =>
                                                            onReject(
                                                                accountAsset
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    {fetchAccountAssets ? (
                                        <Alert severity="info">
                                            Loading assets...
                                        </Alert>
                                    ) : (
                                        <Alert severity="error">
                                            {accountAssetsFetched
                                                ? "There are no records found."
                                                : "There was a problem in fetching the assets!"}
                                        </Alert>
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    accountAssetCountNonZero
                        ? parseInt(accountAssetCounts[ACCOUNT_ASSET_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={accountAssetCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

PendingAssetsTable.propTypes = {
    accountAssets: PropTypes.array,
    accountAssetCounts: PropTypes.object,
    accountAssetsFetched: PropTypes.bool,
    canApproveRequest: PropTypes.bool,
    canRejectRequest: PropTypes.bool,
    canResendRequest: PropTypes.bool,
    columnsToShow: PropTypes.array,
    fetchAccountAssets: PropTypes.bool,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onApprove: PropTypes.func,
    onDownload: PropTypes.func,
    onReject: PropTypes.func,
    onResend: PropTypes.func,
    onSort: PropTypes.func,
    onViewChat: PropTypes.func,
};

PendingAssetsTable.defaultProps = {
    accountAssets: [],
    accountAssetCounts: null,
    accountAssetsFetched: false,
    canApproveRequest: false,
    canRejectRequest: false,
    canResendRequest: false,
    columnsToShow: [],
    fetchAccountAssets: false,
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    onApprove: () => {},
    // onDownload: () => {},
    onReject: () => {},
    onResend: () => {},
    // onSort: () => {},
    // onViewChat: () => {},
};

export default PendingAssetsTable;
