import React from "react";

import EmailIcon from "@mui/icons-material/Email";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";

const settingsList = [
    {
        uri: "/settings/sidebar",
        label: "Sidebar",
        type: "nav-item",
        icon: <ViewSidebarIcon />,
    },
    {
        uri: "/settings/email",
        label: "SMTP Email",
        type: "nav-item",
        icon: <EmailIcon />,
    },
];

export default settingsList;
