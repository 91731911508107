import {
    ADD_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT,
    EDIT_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_COUNT,
    GET_ANNOUNCEMENTS,
    SET_ANNOUNCEMENT_DETAILS,
} from "../../utils/constants/actionTypes";
import { ANNOUNCEMENT_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    announcements: [],
    currentAnnouncement: null,
    announcementCounts: { [ANNOUNCEMENT_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ANNOUNCEMENTS: {
            return {
                ...state,
                announcements: action.payload,
            };
        }
        case GET_ANNOUNCEMENT_COUNT: {
            return {
                ...state,
                announcementCounts: action.payload,
            };
        }
        case SET_ANNOUNCEMENT_DETAILS: {
            return {
                ...state,
                currentAnnouncement: action.payload,
            };
        }
        case ADD_ANNOUNCEMENT: {
            return {
                ...state,
                announcements: [action.payload, ...state.announcements],
            };
        }
        case EDIT_ANNOUNCEMENT: {
            return {
                ...state,
                announcements: state.announcements.map((announcement) =>
                    announcement.id === action.payload.id
                        ? action.payload
                        : announcement
                ),
            };
        }
        case DELETE_ANNOUNCEMENT: {
            return {
                ...state,
                announcements: state.announcements.filter(
                    (announcement) => announcement.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
