import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    deleteAssignedService,
    getFilteredAssignedServices,
    setCurrentAssignedService,
} from "../../redux/actions/assignedServices";
import { getCompany } from "../../redux/actions/companies";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    ASSIGNED_SERVICE_KEYS,
    AUTH_USER_KEYS,
    APP_NAME,
    COMPANY_KEYS,
    EMPTY_PARAM_VALUE,
    SERVICE_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import { assignedServicesAllHeadCells } from "../../utils/constants/tableColumns";
import AssignedServicesTable from "./AssignedServicesTable";
import ServiceDescriptionDialog from "../services/ServiceDescriptionDialog";
import UpdateRenewalDateDialog from "./UpdateRenewalDateDialog";
import UploadWorkOrderDocumentDialog from "./UploadWorkOrderDocumentDialog";
import ViewWorkOrderDocumentDialog from "./ViewWorkOrderDocumentDialog";
const AssignServiceDialog = lazy(() => import("./AssignServiceDialog"));
const EditAssignedServiceDialog = lazy(() =>
    import("./EditAssignedServiceDialog")
);

const ASSIGNED_SERVICES_LIMIT = 10;

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, deleted: true };
        }
    }

    return { inserted: false, deleted: false };
};

const INIT_ASSIGNED_SERVICE_STATE = {
    companyId: "",
    fetchPage: false,
    order: SORT_ORDER_DESC,
    page: 0,
    rowsPerPage: ASSIGNED_SERVICES_LIMIT,
    sort: ASSIGNED_SERVICE_KEYS.CREATED_AT,
};

const filterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "RESET_FILTER") {
            return { ...state, page: 0, fetchPage: false };
        } else if (action.type === "SET_COMPANY_ID") {
            return { ...state, companyId: action.companyId };
        } else if (action.type === "SET_LIMIT_AND_FETCH") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE_AND_FETCH") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                sort: updatedSort,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                fetchPage: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchPage: false };
        }
    }

    return INIT_ASSIGNED_SERVICE_STATE;
};

const genericPageName = "Assigned Services";

const AssignedServices = ({ companyParam = "" }) => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isMountedAssignedServices = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { assignedServices, assignedServiceCounts, currentAssignedService } =
        useSelector(({ assignedServicesReducer }) => assignedServicesReducer);

    const [inputValid, setInputValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [headCells, setHeadCells] = useState([]);
    const [pageName, setPageName] = useState(genericPageName);
    const [companyIsValid, setCompanyIsValid] = useState(false);
    const [companyFetched, setCompanyFetched] = useState(false);
    const [company, setCompany] = useState(null);
    const [assignedServicesFetched, setAssignedServicesFetched] =
        useState(true);
    const [openAssignServiceDialog, setOpenAssignServiceDialog] =
        useState(false);
    const [openEditAssignedServiceDialog, setOpenEditAssignedServiceDialog] =
        useState(false);
    const [openServiceDescriptionDialog, setOpenServiceDescriptionDialog] =
        useState(false);
    const [openUpdateRenewalDateDialog, setOpenUpdateRenewalDateDialog] =
        useState(false);
    const [openUploadWODocumentDialog, setOpenUploadWODocumentDialog] =
        useState(false);
    const [openViewWODocumentDialog, setOpenViewWODocumentDialog] =
        useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
    });

    const [filterState, dispatchFilterAction] = useReducer(
        filterReducer,
        INIT_ASSIGNED_SERVICE_STATE
    );

    const { companyId, fetchPage } = filterState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canAssignService = isAdminUser || isFmUser;
    const canDeleteService = isAdminUser || isFmUser;
    const canEditService = isAdminUser || isFmUser;
    const canEditRenewalDate = isAdminUser || isFmUser;
    const canUploadWODocument = isAdminUser || isFmUser;
    const canViewDescription = isClientUser;
    const canViewWODocument = isAdminUser || isClientUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedAssignedServices.current = false;
            dispatch(setCurrentAssignedService(null));
        };
    }, [dispatch]);

    /* Set the table columns to show */
    useEffect(() => {
        if (isAdminUser || isFmUser || isClientUser) {
            setHeadCells(
                assignedServicesAllHeadCells.map((headCell) => {
                    if (
                        [ASSIGNED_SERVICE_KEYS.COMPANY_NAME].includes(
                            headCell.id
                        )
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isClientUser, isFmUser]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Set url parameters as filters for fetching assigned services */
    useEffect(() => {
        if (isValidUserType) {
            dispatchFilterAction({
                type: "SET_COMPANY_ID",
                companyId: companyParam || EMPTY_PARAM_VALUE,
            });
        }
    }, [companyParam, isValidUserType]);

    /* Start loading the page content if input is okay */
    useEffect(() => {
        if (!inputValid) {
            if (companyId) {
                setInputValid(true);
            }
        }
    }, [companyId, inputValid]);

    useEffect(() => {
        if (inputValid) {
            setIsLoading(true);
        }
    }, [inputValid]);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (isLoading) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompanyFetched(true);
                                setCompany(fetchedCompany || null);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: companyId,
            };

            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setCompanyFetched(false);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, companyId, isLoading]);

    /* Check if fetched company is valid */
    useEffect(() => {
        if (companyFetched && company) {
            if (company?.[COMPANY_KEYS.ID] && company[COMPANY_KEYS.ID]) {
                setCompanyIsValid(true);
                setPageName(
                    company?.[COMPANY_KEYS.NAME]
                        ? `${company[COMPANY_KEYS.NAME]} - Services`
                        : ""
                );
            }
        }
    }, [company, companyFetched]);

    /* Fetch all services */
    useEffect(() => {
        if (companyIsValid) {
            dispatchFilterAction({
                type: "FETCH_PAGE",
            });
        }
    }, [companyIsValid]);

    /* Fetch company's assigned services */
    useEffect(() => {
        if (fetchPage) {
            setAssignedServicesFetched(false);
        }
    }, [fetchPage]);

    useEffect(() => {
        let isActive = true;

        if (!assignedServicesFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredAssignedServices(payload, () => {
                            if (isActive) {
                                setAssignedServicesFetched(true);
                                dispatchFilterAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const assignedServiceData = {
                authcode: authToken,
                page: filterState.page + 1,
                rows_per_page: filterState.rowsPerPage,
                order: filterState.order,
            };

            if (filterState.sort === ASSIGNED_SERVICE_KEYS.COMPANY_NAME) {
                assignedServiceData.sort = "company_name";
            } else {
                assignedServiceData.sort = filterState.sort;
            }

            if (filterState.companyId !== EMPTY_PARAM_VALUE) {
                assignedServiceData[ASSIGNED_SERVICE_KEYS.COMPANY_ID] =
                    filterState.companyId;
            }

            const promise = dispatch(
                fetchData({
                    assignedServiceData: assignedServiceData,
                    fromAction: actionState,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setAssignedServicesFetched(true);
                    dispatchFilterAction({ type: "RESET_FILTER" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        actionState,
        assignedServicesFetched,
        authToken,
        authUserType,
        filterState,
    ]);

    /**
     * Fetch the list again if a service is
     * assigned or deleted
     */
    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            dispatchFilterAction({ type: "FETCH_PAGE" });
        }
    }, [actionState]);

    const handlePageChange = (event, newPage) => {
        dispatchFilterAction({ type: "SET_PAGE_AND_FETCH", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchFilterAction({
            type: "SET_LIMIT_AND_FETCH",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchFilterAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleOpenAssignServiceDialog = () => {
        if (canAssignService) {
            setOpenAssignServiceDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAssignServiceDialog = () => {
        setOpenAssignServiceDialog(false);
        dispatch(setCurrentAssignedService(null));
    };

    const handleEditAssignedService = (service) => {
        if (canEditService) {
            dispatch(setCurrentAssignedService(service));
            setOpenEditAssignedServiceDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseEditAssignedServiceDialog = () => {
        setOpenEditAssignedServiceDialog(false);
        dispatch(setCurrentAssignedService(null));
    };

    const handleOpenUpdateRenewalDateDialog = (service) => {
        if (canEditRenewalDate) {
            dispatch(setCurrentAssignedService(service));
            setOpenUpdateRenewalDateDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseUpdateRenewalDateDialog = () => {
        setOpenUpdateRenewalDateDialog(false);
        dispatch(setCurrentAssignedService(null));
    };

    const handleOpenUploadWODocumentDialog = (service) => {
        if (canUploadWODocument) {
            dispatch(setCurrentAssignedService(service));
            setOpenUploadWODocumentDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseUploadWODocumentDialog = () => {
        setOpenUploadWODocumentDialog(false);
        dispatch(setCurrentAssignedService(null));
    };

    const handleOpenViewWODocumentDialog = (service) => {
        if (canViewWODocument) {
            dispatch(setCurrentAssignedService(service));
            setOpenViewWODocumentDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseViewWODocumentDialog = () => {
        setOpenViewWODocumentDialog(false);
        dispatch(setCurrentAssignedService(null));
    };

    const handleOpenServiceDescriptionDialog = (service) => {
        if (canViewDescription) {
            dispatch(setCurrentAssignedService(service));
            setOpenServiceDescriptionDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseServiceDescriptionDialog = () => {
        setOpenServiceDescriptionDialog(false);
        dispatch(setCurrentAssignedService(null));
    };

    const handleAssignedServiceDelete = React.useCallback(
        (assignedService) => {
            if (assignedService && assignedService[ASSIGNED_SERVICE_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Delete assigned service: ${
                        assignedService[ASSIGNED_SERVICE_KEYS.NAME]
                    }?`,
                    onYes: () => {
                        hideDialog();
                        const assignedServiceData = {
                            authcode: authToken,
                            [ASSIGNED_SERVICE_KEYS.ASSIGNED_SERVICE_ID]:
                                assignedService[ASSIGNED_SERVICE_KEYS.ID],
                        };
                        dispatch(
                            deleteAssignedService(
                                { assignedServiceData: assignedServiceData },
                                () => {
                                    if (_isMountedAssignedServices.current)
                                        dispatchAction({ type: "DELETE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid assigned service",
                });
            }
        },
        [dispatch, Swal, authToken, hideDialog, showDialog]
    );

    const errorOnLoading = !isValidUserType || !inputValid || !companyIsValid;

    return (
        <React.Fragment>
            {!isLoading && errorOnLoading && (
                <Grid container spacing={2}>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !inputValid && (
                        <Grid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                One or more input is not valid!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && inputValid && !companyIsValid && (
                        <Grid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                Invalid company!
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            )}
            {!isLoading && !errorOnLoading && (
                <React.Fragment>
                    <Grid container spacing={3.5}>
                        {pageName && (
                            <Grid item xs={12}>
                                <HeadingStack sx={{ alignItems: "baseline" }}>
                                    <PageTitle
                                        type="v2"
                                        component="h1"
                                        title={pageName}
                                    />
                                    <Breadcrumb
                                        pageNames={{
                                            genericName: genericPageName,
                                            specificName: pageName,
                                        }}
                                    />
                                </HeadingStack>
                            </Grid>
                        )}
                        {canAssignService && (
                            <Grid item xs={12}>
                                <HeadingStack sx={{ justifyContent: "end" }}>
                                    <FmButton
                                        startIcon="add"
                                        type="button"
                                        label="Assign Service"
                                        onClick={handleOpenAssignServiceDialog}
                                    />
                                </HeadingStack>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <AssignedServicesTable
                                assignedServices={assignedServices}
                                assignedServiceCounts={assignedServiceCounts}
                                assignedServicesFetched={
                                    assignedServicesFetched
                                }
                                canDeleteService={canDeleteService}
                                canEditService={canEditService}
                                canEditRenewalDate={canEditRenewalDate}
                                canUploadWODocument={canUploadWODocument}
                                canViewDescription={canViewDescription}
                                canViewWODocument={canViewWODocument}
                                columnsToShow={columnsToShow}
                                headCells={headCells}
                                order={filterState.order}
                                page={filterState.page}
                                rowsPerPage={filterState.rowsPerPage}
                                showPagination={true}
                                sort={filterState.sort}
                                handlePageChange={handlePageChange}
                                handleRowsPerPageChange={
                                    handleRowsPerPageChange
                                }
                                onDelete={handleAssignedServiceDelete}
                                onEdit={handleEditAssignedService}
                                onEditRenewalDate={
                                    handleOpenUpdateRenewalDateDialog
                                }
                                onSort={handleSort}
                                onUploadWODocument={
                                    handleOpenUploadWODocumentDialog
                                }
                                onViewDescription={
                                    handleOpenServiceDescriptionDialog
                                }
                                onViewWODocument={
                                    handleOpenViewWODocumentDialog
                                }
                            />
                        </Grid>
                    </Grid>
                    {canAssignService && openAssignServiceDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AssignServiceDialog
                                currentCompany={company}
                                open={openAssignServiceDialog}
                                onClose={handleCloseAssignServiceDialog}
                                onDispatchAction={dispatchAction}
                            />
                        </Suspense>
                    )}
                    {canAssignService && openEditAssignedServiceDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <EditAssignedServiceDialog
                                currentAssignedService={currentAssignedService}
                                currentCompany={company}
                                open={openEditAssignedServiceDialog}
                                setGlobalState={true}
                                onClose={handleCloseEditAssignedServiceDialog}
                            />
                        </Suspense>
                    )}
                    {canEditRenewalDate && openUpdateRenewalDateDialog && (
                        <UpdateRenewalDateDialog
                            currentAssignedService={currentAssignedService}
                            open={openUpdateRenewalDateDialog}
                            setGlobalState={true}
                            onClose={handleCloseUpdateRenewalDateDialog}
                        />
                    )}
                    {canUploadWODocument && openUploadWODocumentDialog && (
                        <UploadWorkOrderDocumentDialog
                            currentAssignedService={currentAssignedService}
                            open={openUploadWODocumentDialog}
                            setGlobalState={true}
                            onClose={handleCloseUploadWODocumentDialog}
                        />
                    )}
                    {canViewWODocument && openViewWODocumentDialog && (
                        <ViewWorkOrderDocumentDialog
                            currentAssignedService={currentAssignedService}
                            open={openViewWODocumentDialog}
                            setGlobalState={true}
                            onClose={handleCloseViewWODocumentDialog}
                        />
                    )}
                    {canViewDescription && openServiceDescriptionDialog && (
                        <ServiceDescriptionDialog
                            currentService={currentAssignedService}
                            serviceKeys={SERVICE_KEYS}
                            open={openServiceDescriptionDialog}
                            onClose={handleCloseServiceDescriptionDialog}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default AssignedServices;
