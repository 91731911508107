import React from "react";
import PropTypes from "prop-types";
import {
    Search,
    SearchIconWrapper,
    StyledInputBase,
} from "../../shared/SearchGlobal/style";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";

const FmSearch = ({
    ariaLabel,
    id,
    filterSearch,
    placeholder,
    searchTerm,
    sx,
    onSearchChange,
}) => {
    return (
        <Search
            sx={{
                "& .MuiInputBase-root": {
                    border: 1,
                    borderColor: "divider",
                    backgroundColor: (theme) => theme.palette.background.paper,
                },
                ...sx,
            }}
        >
            <SearchIconWrapper>
                {filterSearch && <CircularProgress size={24} />}
                {!filterSearch && <SearchIcon />}
            </SearchIconWrapper>

            <StyledInputBase
                type="text"
                id={id}
                placeholder={placeholder || "Search..."}
                inputProps={{ "aria-label": ariaLabel || "search" }}
                value={searchTerm}
                onChange={(e) => onSearchChange(e)}
            />
        </Search>
    );
};

FmSearch.propTypes = {
    ariaLabel: PropTypes.string,
    id: PropTypes.string,
    filterSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    searchTerm: PropTypes.string,
    sx: PropTypes.object,
    onSearchChange: PropTypes.func,
};

FmSearch.defaultProps = {
    ariaLabel: "",
    filterSearch: false,
    placeholder: "",
    searchTerm: "",
    sx: {},
};

export default FmSearch;
