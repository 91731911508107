import React, { Suspense, lazy } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";

const FirmMediaLogo = lazy(() => import("../SVGImages/FirmMediaLogo"));
const FirmMediaLogoLight = lazy(() =>
    import("../SVGImages/FirmMediaLogoLight")
);

const SVGLogo = ({ mode, sx, svgSx }) => {
    let logoComponent = null;
    if (mode !== "light") {
        logoComponent = (
            <Suspense fallback={null}>
                <FirmMediaLogoLight sx={{ ...svgSx }} />
            </Suspense>
        );
    } else {
        logoComponent = (
            <Suspense fallback={null}>
                <FirmMediaLogo sx={{ ...svgSx }} />
            </Suspense>
        );
    }

    return (
        <Div sx={{ display: "inline-flex", ...sx }}>
            <Link component={RouterLink} to="/">
                {logoComponent}
            </Link>
        </Div>
    );
};

SVGLogo.propTypes = {
    mode: PropTypes.string,
    sx: PropTypes.object,
    svgSx: PropTypes.object,
};

SVGLogo.defaultProps = {
    mode: "light",
    sx: {},
    svgSx: {},
};

export default SVGLogo;
