import Link from "@mui/material/Link";
import { styled } from "@mui/system";

const NameLink = styled(Link)(({ theme }) => ({
    fontSize: theme.typography?.miscellaneous?.button?.medium,
    fontWeight: 500,
    color: theme.palette.miscellaneous.button.main,
    letterSpacing: "0px",
    textDecoration: "underline",
    "&:hover": {
        color: theme.palette.secondary.main,
        textDecoration: "underline",
    },
}));

export default NameLink;
