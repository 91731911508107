import React, { useEffect, useRef } from "react";
import { CKEditor } from "ckeditor4-react";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
/* import Typography from "@mui/material/Typography"; */
import PropTypes from "prop-types";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";

const ServiceDescriptionDialog = ({
    open,
    onClose,
    currentService,
    serviceKeys,
}) => {
    const _isServiceDescriptionDialogMounted = useRef(true);

    useEffect(() => {
        return () => {
            _isServiceDescriptionDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
        }
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    currentService && serviceKeys
                        ? `${currentService[serviceKeys.NAME]}`
                        : "Invalid Service!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    {currentService && serviceKeys && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                "& .cke_chrome": {
                                    border: "none",
                                },
                                "& .cke_inner.cke_reset": {
                                    border: "none",
                                },
                                "& .cke_top.cke_reset_all": {
                                    display: "none",
                                },
                                "& .cke_bottom.cke_reset_all": {
                                    display: "none",
                                },
                            }}
                        >
                            <CKEditor
                                id="description"
                                name="description"
                                initData={
                                    currentService[serviceKeys.DESCRIPTION]
                                }
                                config={{
                                    contentsCss: [
                                        `${ASSET_MUSEO_SANS}/styles.css`,
                                        `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                    ],
                                }}
                                readOnly
                            />
                        </Grid>
                    )}
                    {(!currentService || !serviceKeys) && (
                        <Grid item xs={12}>
                            <Alert severity="error">Invalid service!</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

ServiceDescriptionDialog.propTypes = {
    currentService: PropTypes.object,
    serviceKeys: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

ServiceDescriptionDialog.defaultProps = {
    open: false,
    // onClose: () => {},
};

export default ServiceDescriptionDialog;
