import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { useParams, Link as RouterLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    deleteAssignedService,
    getFilteredAssignedServicesLocalState,
} from "../../redux/actions/assignedServices";
import { getClientUserLocalState } from "../../redux/actions/clientUsers";
import { getCompany } from "../../redux/actions/companies";
import { getAllIndustries } from "../../redux/actions/industries";
import { getFilteredMeetingsLocalState } from "../../redux/actions/meetings";
import { getFilteredTicketsLocalState } from "../../redux/actions/tickets";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import {
    AUTH_USER_KEYS,
    APP_NAME,
    ASSIGNED_SERVICE_KEYS,
    CLIENT_USER_ACTIVE_STATUS,
    CLIENT_USER_KEYS,
    COMPANY_KEYS,
    EMPTY_PARAM_VALUE,
    MEETING_KEYS,
    SERVICE_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    REFRESH_ACTION_INSERT,
    TICKET_CLOSE_STATUS_TXT,
    TICKET_OPEN_STATUS,
    TICKET_KEYS,
    TICKETS_PAGE_LINK,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import {
    allMeetingsHeadCells,
    assignedServicesAllHeadCells,
} from "../../utils/constants/tableColumns";
/* Page Components */
import AssignedServicesTable from "../assignedServices/AssignedServicesTable";
import ClientDetails from "./ClientDetails";
import MeetingsTable from "../meetings/MeetingsTable";
import TicketsTableLoadMore from "../tickets/TicketsTableLoadMore";
/* Modal Components */
import AddEditCompanyClientDialog from "../companyClientUsers/AddEditCompanyClientDialog";
import ServiceDescriptionDialog from "../services/ServiceDescriptionDialog";
import UpdateRenewalDateDialog from "../assignedServices/UpdateRenewalDateDialog";
import UploadWorkOrderDocumentDialog from "../assignedServices/UploadWorkOrderDocumentDialog";
import ViewWorkOrderDocumentDialog from "../assignedServices/ViewWorkOrderDocumentDialog";
const AddMeetingDialog = lazy(() => import("../meetings/AddMeetingDialog"));
const AddTicketDialog = lazy(() => import("../tickets/AddTicketDialog"));
const AssignServiceDialog = lazy(() =>
    import("../assignedServices/AssignServiceDialog")
);

const assignedServiceActionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "DELETE") {
            return {
                deleted: true,
                inserted: false,
                updated: false,
                updatedService: null,
            };
        } else if (action.type === "INSERT") {
            return {
                deleted: false,
                inserted: true,
                updated: false,
                updatedService: null,
            };
        } else if (action.type === "UPDATE") {
            return {
                deleted: false,
                inserted: false,
                updated: true,
                updatedService: action.updatedService,
            };
        }
    }

    return {
        deleted: false,
        inserted: false,
        updated: false,
        updatedService: null,
    };
};

const ASSIGNED_SERVICES_LIMIT = 5;

const INIT_SERVICE_STATE = {
    clientUserId: "",
    companyId: "",
    fetchPage: false,
    inputValid: false,
    order: SORT_ORDER_DESC,
    page: 0,
    rowsPerPage: ASSIGNED_SERVICES_LIMIT,
    sort: ASSIGNED_SERVICE_KEYS.CREATED_AT,
};

const assignedServiceFilterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "RESET_FILTER") {
            return { ...state, page: 0, fetchPage: false };
        } else if (action.type === "SET_CLIENT_DATA") {
            const clientUserId = action.clientUserId;
            const companyId = action.companyId;
            return {
                ...state,
                clientUserId: clientUserId,
                companyId: companyId,
                inputValid: !!clientUserId && !!companyId,
            };
        } else if (action.type === "SET_LIMIT_AND_FETCH") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE_AND_FETCH") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                sort: updatedSort,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                fetchPage: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchPage: false };
        }
    }

    return INIT_SERVICE_STATE;
};

const MEETINGS_LIMIT = 5;

const meetingActionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true };
        }
    }

    return { inserted: false };
};

const meetingFilterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "RESET_FILTER") {
            return { ...state, page: 0, fetchPage: false };
        } else if (action.type === "SET_IDS") {
            const clientId = action.clientId;
            const companyId = action.companyId;
            return {
                ...state,
                clientId: clientId,
                companyId: companyId,
                inputValid: !!clientId && !!companyId,
            };
        } else if (action.type === "SET_LIMIT_AND_FETCH") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE_AND_FETCH") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                sort: updatedSort,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                fetchPage: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchPage: false };
        }
    }

    return {
        clientId: "",
        companyId: "",
        fetchPage: false,
        inputValid: false,
        order: SORT_ORDER_DESC,
        page: 0,
        rowsPerPage: MEETINGS_LIMIT,
        sort: MEETING_KEYS.MEETING_DATE,
        status: "",
    };
};

const ticketActionsReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true };
        }
    }
    return { inserted: false };
};

const TICKETS_LIMIT = 5;

const TICKETS_INIT_STATE = {
    fetchTickets: false,
    firstLoad: true,
    loadMore: false,
    order: SORT_ORDER_DESC,
    page: 0,
    refresh: false,
    refreshAction: "",
    rowsPerPage: TICKETS_LIMIT,
    sort: TICKET_KEYS.CREATED_AT,
    sorted: false,
    status: TICKET_OPEN_STATUS,
    tickets: [],
    ticketsFetched: true,
    totals: 0,
};

const ticketReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                fetchTickets: true,
                loadMore: false,
                ticketsFetched: false,
            };
        } else if (action.type === "REFRESH") {
            return {
                ...state,
                loadMore: false,
                refresh: true,
                refreshAction: action?.refreshAction || "",
                ticketsFetched: false,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                firstLoad: false,
                loadMore: false,
                page: 0,
                refresh: false,
                refreshAction: "",
                rowsPerPage: TICKETS_LIMIT,
                sorted: false,
                status: TICKET_OPEN_STATUS,
                tickets: [],
                ticketsFetched: true,
                totals: 0,
            };
        } else if (action.type === "SET_TICKETS") {
            const isRefreshed = state.refresh;
            const isSorted = state.sorted;
            const currentTickets = state.tickets || [];

            const updatedTickets =
                isRefreshed || isSorted ? [] : [...currentTickets];
            let numUpdatedTickets = updatedTickets.length;

            let newPage =
                numUpdatedTickets > 0
                    ? Math.ceil(updatedTickets / TICKETS_LIMIT)
                    : 0;
            newPage = newPage > 0 ? newPage - 1 : newPage;

            const currentRowsPerPage = state.rowsPerPage;

            const fetchedTotals = action.totals;
            const numTotalTickets = fetchedTotals[TICKET_KEYS.TOTAL];

            const fetchedTickets = action.tickets;
            const numFetchedTickets = fetchedTickets.length;
            if (fetchedTickets.length > 0) {
                let numAddedTickets = 0;

                for (let i = 0; i < numFetchedTickets; i++) {
                    const fetchedTicket = fetchedTickets[i];
                    if (
                        fetchedTicket &&
                        fetchedTicket?.[TICKET_KEYS.ID] &&
                        fetchedTicket[TICKET_KEYS.ID]
                    ) {
                        const foundTicket = updatedTickets.find(
                            (ticketObj) =>
                                ticketObj[TICKET_KEYS.ID] ===
                                fetchedTicket[TICKET_KEYS.ID]
                        );
                        if (!foundTicket) {
                            updatedTickets.push(fetchedTicket);
                            numAddedTickets += 1;
                        }
                    }
                }

                numUpdatedTickets = updatedTickets.length;

                newPage =
                    numUpdatedTickets > 0
                        ? Math.ceil(numUpdatedTickets / TICKETS_LIMIT)
                        : 0;
                newPage = newPage > 0 ? newPage - 1 : newPage;

                if (numUpdatedTickets === numTotalTickets) {
                    return {
                        ...state,
                        page: newPage,
                        refresh: false,
                        refreshAction: "",
                        sorted: false,
                        tickets: updatedTickets,
                        totals: numTotalTickets,
                    };
                } else {
                    const maxLimit = isRefreshed
                        ? (newPage + 1) * currentRowsPerPage
                        : currentRowsPerPage;
                    if (numAddedTickets === maxLimit) {
                        return {
                            ...state,
                            loadMore: true,
                            page: newPage + 1,
                            refresh: false,
                            refreshAction: "",
                            sorted: false,
                            tickets: updatedTickets,
                            totals: numTotalTickets,
                        };
                    } else {
                        return {
                            ...state,
                            page: newPage,
                            refresh: false,
                            refreshAction: "",
                            sorted: false,
                            tickets: updatedTickets,
                            totals: numTotalTickets,
                        };
                    }
                }
            } else {
                return {
                    ...state,
                    page: newPage,
                    refresh: false,
                    refreshAction: "",
                    sorted: false,
                    tickets: updatedTickets,
                    totals: numTotalTickets,
                };
            }
        } else if (action.type === "SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                fetchTickets: true,
                loadMore: false,
                page: 0,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                sort: updatedSort,
                sorted: true,
                ticketsFetched: false,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                firstLoad: false,
                ticketsFetched: true,
            };
        }
    }

    return TICKETS_INIT_STATE;
};

const genericPageName = "Client Overview";

const ClientOverview = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isMountedClientOverview = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const clientIdParam = params?.clientUserId || "";

    const [fetchClient, setFetchClient] = useState(false);
    const [clientFetched, setClientFetched] = useState(false);
    const [clientUser, setClientUser] = useState(null);
    const [clientUserId, setClientUserId] = useState("");
    /* Company State */
    const [companyId, setCompanyId] = useState("");
    const [fetchCompany, setFetchCompany] = useState(false);
    const [company, setCompany] = useState(null);
    /* All Industries state */
    const [fetchIndustries, setFetchIndustries] = useState(false);
    const [allIndustries, setAllIndustries] = useState([]);
    /* Assigned Services state */
    const [fetchAssignedServices, setFetchAssignedServices] = useState(false);
    const [assignedServicesFetched, setAssignedServicesFetched] =
        useState(true);
    const [assignedServices, setAssignedServices] = useState([]);
    const [assignedServiceCounts, setAssignedServiceCount] = useState(null);
    const [assignedServicesHeadCells, setAssignedServicesHeadCells] = useState(
        []
    );
    const [assignedServicesColsToShow, setAssignedServicesColsToShow] =
        useState([]);
    const [currentAssignedService, setCurrentAssignedService] = useState(null);
    /* Meetings state */
    const [fetchMeetings, setFetchMeetings] = useState(false);
    const [meetingsFetched, setMeetingsFetched] = useState(true);
    const [meetings, setMeetings] = useState([]);
    const [meetingsCount, setMeetingsCount] = useState(null);
    const [meetingsHeadCells, setMeetingsHeadCells] = useState([]);
    const [meetingsColsToShow, setMeetingsColsToShow] = useState([]);
    /* Modal States */
    const [openAddEditClientDialog, setOpenAddEditClientDialog] =
        useState(false);
    const [openAddMeetingDialog, setOpenAddMeetingDialog] = useState(false);
    const [openAssignServiceDialog, setOpenAssignServiceDialog] =
        useState(false);
    const [openAddTicketDialog, setOpenAddTicketDialog] = useState(false);
    const [openServiceDescriptionDialog, setOpenServiceDescriptionDialog] =
        useState(false);
    const [openUpdateRenewalDateDialog, setOpenUpdateRenewalDateDialog] =
        useState(false);
    const [openUploadWODocumentDialog, setOpenUploadWODocumentDialog] =
        useState(false);
    const [openViewWODocumentDialog, setOpenViewWODocumentDialog] =
        useState(false);
    /* Miscellaneous States */
    const [isLoading, setIsLoading] = useState(false);

    /* Assigned Service Reducer */
    const [assignedServiceActionState, dispatchAssignedServiceAction] =
        useReducer(assignedServiceActionReducer, {
            deleted: false,
            inserted: false,
            updated: false,
            updatedService: null,
        });

    const [assignedServiceFilterState, dispatchAssignedServiceFilterAction] =
        useReducer(assignedServiceFilterReducer, INIT_SERVICE_STATE);

    const {
        fetchPage: assignedServiceFetchPage,
        inputValid: assignedServiceInputValid,
    } = assignedServiceFilterState;

    /* Meeting Reducer */
    const [meetingActionState, dispatchMeetingAction] = useReducer(
        meetingActionReducer,
        { inserted: false }
    );

    const [meetingFilterState, dispatchMeetingFilterAction] = useReducer(
        meetingFilterReducer,
        {
            clientId: "",
            companyId: "",
            fetchPage: false,
            inputValid: false,
            order: SORT_ORDER_DESC,
            page: 0,
            rowsPerPage: MEETINGS_LIMIT,
            sort: MEETING_KEYS.MEETING_DATE,
            status: "",
        }
    );

    const { fetchPage: meetingFetchPage, inputValid: meetingInputValid } =
        meetingFilterState;

    /* Ticket Reducer */
    const [ticketActionsState, dispatchTicketActionsAction] = useReducer(
        ticketActionsReducer,
        { inserted: false }
    );

    const [ticketState, dispatchTicketAction] = useReducer(
        ticketReducer,
        TICKETS_INIT_STATE
    );

    const {
        fetchTickets,
        loadMore: loadMoreTickets,
        tickets,
        ticketsFetched,
    } = ticketState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    const canAddEditClient = isAdminUser || isFmUser;
    const canAddMeeting = isAdminUser || isFmUser;
    const canDeleteAssignedService = /* isAdminUser || isFmUser */ false;
    const canEditRenewalDate = /* isAdminUser || isFmUser */ false;
    const canUploadWODocument = isAdminUser || isFmUser;
    const canViewDescription = isAdminUser || isFmUser;
    const canViewWODocument = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedClientOverview.current = false;
        };
    }, []);

    /* Setup assigned services table columns */
    useEffect(() => {
        if (
            assignedServicesAllHeadCells &&
            assignedServicesAllHeadCells.length > 0
        ) {
            setAssignedServicesHeadCells(
                assignedServicesAllHeadCells.map((headCell) => {
                    if (
                        [ASSIGNED_SERVICE_KEYS.COMPANY_NAME].includes(
                            headCell.id
                        )
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        }
    }, []);

    useEffect(() => {
        if (assignedServicesHeadCells && assignedServicesHeadCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < assignedServicesHeadCells.length; i++) {
                if (assignedServicesHeadCells[i].show) {
                    visibleColumnIds.push(assignedServicesHeadCells[i].id);
                }
            }
            setAssignedServicesColsToShow([...visibleColumnIds]);
        } else {
            setAssignedServicesColsToShow([]);
        }
    }, [assignedServicesHeadCells]);

    /* Setup meetings table columns */
    useEffect(() => {
        if (allMeetingsHeadCells && allMeetingsHeadCells.length > 0) {
            setMeetingsHeadCells(
                allMeetingsHeadCells.map((headCell) => {
                    if ([MEETING_KEYS.CLIENT_NAME].includes(headCell.id)) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        }
    }, []);

    useEffect(() => {
        if (meetingsHeadCells && meetingsHeadCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < meetingsHeadCells.length; i++) {
                if (meetingsHeadCells[i].show) {
                    visibleColumnIds.push(meetingsHeadCells[i].id);
                }
            }
            setMeetingsColsToShow([...visibleColumnIds]);
        } else {
            setMeetingsColsToShow([]);
        }
    }, [meetingsHeadCells]);

    /* Start Loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch client user's details */
    useEffect(() => {
        if (isLoading) {
            setFetchClient(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchClient) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getClientUserLocalState(
                            payload,
                            (fetchedClientUser) => {
                                if (isActive) {
                                    setFetchClient(false);
                                    setClientFetched(true);
                                    if (
                                        fetchedClientUser &&
                                        fetchedClientUser?.[
                                            CLIENT_USER_KEYS.ID
                                        ] &&
                                        fetchedClientUser[
                                            CLIENT_USER_KEYS.ID
                                        ] &&
                                        fetchedClientUser[
                                            CLIENT_USER_KEYS.ID
                                        ] === clientIdParam
                                    ) {
                                        setClientUser(fetchedClientUser);
                                        setClientUserId(
                                            fetchedClientUser[
                                                CLIENT_USER_KEYS.ID
                                            ]
                                        );
                                        setCompanyId(
                                            fetchedClientUser[
                                                CLIENT_USER_KEYS.COMPANY_ID
                                            ]
                                        );
                                        setFetchCompany(true);
                                    } else {
                                        setClientUser(null);
                                        setClientUserId("");
                                        setCompanyId("");
                                        setIsLoading(false);
                                    }
                                }
                            }
                        )
                    );
                };
            };

            const clientUserData = {
                authcode: authToken,
                [CLIENT_USER_KEYS.USER_ID]: clientIdParam,
            };

            const promise = dispatch(
                fetchData({
                    clientUserData: clientUserData,
                    authUserType: authUserType,
                })
            );
            promise.catch((error) => {
                if (isActive) {
                    setFetchClient(false);
                    setClientFetched(false);
                    setClientUser(null);
                    setClientUserId("");
                    setCompanyId("");
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserType, clientIdParam, fetchClient]);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (companyId && fetchCompany) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompany(fetchedCompany || null);
                                setFetchIndustries(true);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: companyId,
            };

            const promise = dispatch(fetchData({ companyData: companyData }));
            promise.catch((error) => {
                if (isActive) {
                    setCompany(null);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, companyId, fetchCompany]);

    /* Fetch all industries */
    useEffect(() => {
        let isActive = true;

        if (fetchIndustries) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllIndustries(payload, (fetchedIndustries) => {
                            if (isActive) {
                                setFetchIndustries(false);
                                setAllIndustries([...fetchedIndustries]);
                                setFetchAssignedServices(true);
                            }
                        })
                    );
                };
            };

            const industryData = { authcode: authToken };
            const promise = dispatch(fetchData({ industryData: industryData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchIndustries(false);
                    setAllIndustries([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchIndustries]);

    /* Set client & company ID as filter for fetching assigned services */
    useEffect(() => {
        if (fetchAssignedServices) {
            dispatchAssignedServiceFilterAction({
                type: "SET_CLIENT_DATA",
                companyId: companyId || EMPTY_PARAM_VALUE,
                clientUserId: clientUserId || EMPTY_PARAM_VALUE,
            });
        }
    }, [clientUserId, companyId, fetchAssignedServices]);

    /* Fetch Assigned Services if input is okay */
    useEffect(() => {
        if (assignedServiceInputValid) {
            dispatchAssignedServiceFilterAction({ type: "FETCH_PAGE" });
        }
    }, [assignedServiceInputValid]);

    useEffect(() => {
        if (assignedServiceFetchPage) {
            setAssignedServicesFetched(false);
        }
    }, [assignedServiceFetchPage]);

    useEffect(() => {
        let isActive = true;

        if (!assignedServicesFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredAssignedServicesLocalState(
                            payload,
                            (fetchedAssignedServices) => {
                                if (isActive) {
                                    setAssignedServicesFetched(true);
                                    dispatchAssignedServiceFilterAction({
                                        type: "STOP_FETCH",
                                    });
                                    setAssignedServices(
                                        fetchedAssignedServices?.assignedServices ||
                                            []
                                    );
                                    setAssignedServiceCount(
                                        fetchedAssignedServices?.totals || null
                                    );
                                    if (!fetchMeetings) {
                                        setFetchMeetings(true);
                                    }
                                    dispatchAssignedServiceAction();
                                }
                            }
                        )
                    );
                };
            };

            const assignedServiceData = {
                authcode: authToken,
                page: assignedServiceFilterState.page + 1,
                rows_per_page: assignedServiceFilterState.rowsPerPage,
                order: assignedServiceFilterState.order,
            };

            if (
                assignedServiceFilterState.sort ===
                ASSIGNED_SERVICE_KEYS.COMPANY_NAME
            ) {
                assignedServiceData.sort = "company_name";
            } else {
                assignedServiceData.sort = assignedServiceFilterState.sort;
            }

            if (assignedServiceFilterState.companyId !== EMPTY_PARAM_VALUE) {
                assignedServiceData[ASSIGNED_SERVICE_KEYS.COMPANY_ID] =
                    assignedServiceFilterState.companyId;
            }

            const promise = dispatch(
                fetchData({
                    assignedServiceData: assignedServiceData,
                    fromAction: assignedServiceActionState,
                    fetchStart: !isLoading,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setAssignedServicesFetched(true);
                    dispatchAssignedServiceFilterAction({
                        type: "RESET_FILTER",
                    });
                    setIsLoading(false);
                    setAssignedServices([]);
                    setAssignedServiceCount(null);
                    dispatchAssignedServiceAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        assignedServiceActionState,
        assignedServicesFetched,
        assignedServiceFilterState,
        authToken,
        authUserType,
        fetchMeetings,
        isLoading,
    ]);

    /* Set company ID as filter for fetching meetings */
    useEffect(() => {
        if (fetchMeetings) {
            dispatchMeetingFilterAction({
                type: "SET_IDS",
                clientId: clientUserId || EMPTY_PARAM_VALUE,
                companyId: companyId || EMPTY_PARAM_VALUE,
            });
        }
    }, [clientUserId, companyId, fetchMeetings]);

    /* Fetch meetings if input is okay */
    useEffect(() => {
        if (meetingInputValid) {
            dispatchMeetingFilterAction({ type: "FETCH_PAGE" });
        }
    }, [meetingInputValid]);

    useEffect(() => {
        if (meetingFetchPage) {
            setMeetingsFetched(false);
        }
    }, [meetingFetchPage]);

    useEffect(() => {
        let isActive = true;

        if (!meetingsFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredMeetingsLocalState(
                            payload,
                            (fetchedData) => {
                                if (isActive) {
                                    setMeetingsFetched(true);
                                    dispatchMeetingFilterAction({
                                        type: "STOP_FETCH",
                                    });
                                    setMeetings(fetchedData?.meetings || []);
                                    setMeetingsCount(
                                        fetchedData?.totals || null
                                    );
                                    if (!fetchTickets) {
                                        dispatchTicketAction({
                                            type: "FETCH",
                                        });
                                    }
                                    dispatchMeetingAction();
                                }
                            }
                        )
                    );
                };
            };

            const meetingData = {
                authcode: authToken,
                page: meetingFilterState.page + 1,
                rows_per_page: meetingFilterState.rowsPerPage,
                sort: meetingFilterState.sort,
                order: meetingFilterState.order,
                status: meetingFilterState.status,
            };

            if (meetingFilterState.clientId !== EMPTY_PARAM_VALUE) {
                meetingData[MEETING_KEYS.CLIENT_ID] =
                    meetingFilterState.clientId;
            }

            if (meetingFilterState.companyId !== EMPTY_PARAM_VALUE) {
                meetingData[MEETING_KEYS.COMPANY_ID] =
                    meetingFilterState.companyId;
            }

            const promise = dispatch(
                fetchData({
                    authUserType: authUserType,
                    fetchStart: !isLoading,
                    fromAction: meetingActionState,
                    meetingData: meetingData,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setMeetingsFetched(true);
                    dispatchMeetingFilterAction({
                        type: "RESET_FILTER",
                    });
                    setIsLoading(false);
                    setMeetings([]);
                    setMeetingsCount(null);
                    dispatchMeetingAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        authUserType,
        fetchTickets,
        isLoading,
        meetingActionState,
        meetingsFetched,
        meetingFilterState,
    ]);

    /* Fetch tickets */
    useEffect(() => {
        let isActive = true;

        if (!ticketsFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredTicketsLocalState(payload, (fetchedData) => {
                            if (isActive) {
                                dispatchTicketAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchTicketAction({
                                    type: "SET_TICKETS",
                                    tickets: fetchedData?.tickets || [],
                                    totals: fetchedData?.totals || null,
                                });
                                dispatchTicketActionsAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const ticketData = {
                authcode: authToken,
                [TICKET_KEYS.CLIENT_ID]: clientUserId,
                [TICKET_KEYS.COMPANY_ID]: companyId,
                [TICKET_KEYS.STATUS]: ticketState.status,
                order: ticketState.order,
                sort: ticketState.sort,
            };

            let currentPage = ticketState.page + 1;
            if (ticketState.refresh) {
                const numTotals = ticketState.tickets.length;
                if (ticketState.refreshAction === REFRESH_ACTION_INSERT) {
                    currentPage = Math.ceil((numTotals + 1) / TICKETS_LIMIT);
                }
                ticketData.page = 1;
                ticketData.rows_per_page =
                    currentPage * ticketState.rowsPerPage;
            } else {
                ticketData.page = currentPage;
                ticketData.rows_per_page = ticketState.rowsPerPage;
            }

            const promise = dispatch(
                fetchData({
                    authUserType: authUserType,
                    fetchStart: !ticketState.firstLoad,
                    fromAction: ticketActionsState,
                    ticketData: ticketData,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    dispatchTicketAction({ type: "RESET" });
                    setIsLoading(false);
                    dispatchTicketActionsAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        authUserType,
        clientUserId,
        companyId,
        ticketsFetched,
        ticketActionsState,
        ticketState,
    ]);

    /**
     * Fetch assigned services list again if a service is assigned
     * Updated assigned services list if an assigned service is updated
     */
    useEffect(() => {
        if (
            assignedServiceActionState.inserted ||
            assignedServiceActionState.deleted
        ) {
            dispatchAssignedServiceFilterAction({ type: "FETCH_PAGE" });
        } else if (assignedServiceActionState.updated) {
            const updatedAssignedService =
                assignedServiceActionState.updatedService;
            setAssignedServices((prevState) => {
                return prevState.map((assignedService) =>
                    assignedService[ASSIGNED_SERVICE_KEYS.ID] ===
                    updatedAssignedService[ASSIGNED_SERVICE_KEYS.ID]
                        ? updatedAssignedService
                        : assignedService
                );
            });
            dispatchAssignedServiceAction();
        }
    }, [assignedServiceActionState]);

    /**
     * Fetch the meetings list again if a meeting is added.
     */
    useEffect(() => {
        if (meetingActionState.inserted) {
            dispatchMeetingFilterAction({ type: "FETCH_PAGE" });
        }
    }, [meetingActionState]);

    /* Fetch tickets list again if a ticket is added */
    useEffect(() => {
        if (ticketActionsState.inserted) {
            dispatchTicketAction({
                type: "REFRESH",
                refreshAction: REFRESH_ACTION_INSERT,
            });
        }
    }, [ticketActionsState]);

    /* Client methods */
    const handleClientEdit = () => {
        setOpenAddEditClientDialog(true);
    };

    const handleCloseAddEditClientDialog = () => {
        setOpenAddEditClientDialog(false);
    };

    const handleClientUpdate = (updatedClient) => setClientUser(updatedClient);

    /* Assigned Service methods */
    const handleAssignedServicePageChange = (event, newPage) => {
        dispatchAssignedServiceFilterAction({
            type: "SET_PAGE_AND_FETCH",
            page: newPage,
        });
    };

    const handleAssignedServiceRowsPerPageChange = (event) => {
        dispatchAssignedServiceFilterAction({
            type: "SET_LIMIT_AND_FETCH",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleAssignedServiceSort = (property) => {
        dispatchAssignedServiceFilterAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleOpenAssignServiceDialog = () => {
        setOpenAssignServiceDialog(true);
    };

    const handleCloseAssignServiceDialog = () => {
        setOpenAssignServiceDialog(false);
        setCurrentAssignedService(null);
    };

    const handleOpenUpdateRenewalDateDialog = (service) => {
        if (canEditRenewalDate) {
            setCurrentAssignedService(service);
            setOpenUpdateRenewalDateDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseUpdateRenewalDateDialog = () => {
        setOpenUpdateRenewalDateDialog(false);
        setCurrentAssignedService(null);
    };

    const handleOpenUploadWODocumentDialog = (service) => {
        if (canUploadWODocument) {
            setCurrentAssignedService(service);
            setOpenUploadWODocumentDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseUploadWODocumentDialog = () => {
        setOpenUploadWODocumentDialog(false);
        setCurrentAssignedService(null);
    };

    const handleOpenViewWODocumentDialog = (service) => {
        if (canViewWODocument) {
            setCurrentAssignedService(service);
            setOpenViewWODocumentDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseViewWODocumentDialog = () => {
        setOpenViewWODocumentDialog(false);
        setCurrentAssignedService(null);
    };

    const handleOpenServiceDescriptionDialog = (service) => {
        if (canViewDescription) {
            setCurrentAssignedService(service);
            setOpenServiceDescriptionDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseServiceDescriptionDialog = () => {
        setOpenServiceDescriptionDialog(false);
        setCurrentAssignedService(null);
    };

    const handleAssignedServiceDelete = React.useCallback(
        (assignedService) => {
            if (assignedService && assignedService[ASSIGNED_SERVICE_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Delete assigned service: ${
                        assignedService[ASSIGNED_SERVICE_KEYS.NAME]
                    }?`,
                    onYes: () => {
                        hideDialog();
                        const assignedServiceData = {
                            authcode: authToken,
                            [ASSIGNED_SERVICE_KEYS.ASSIGNED_SERVICE_ID]:
                                assignedService[ASSIGNED_SERVICE_KEYS.ID],
                        };
                        dispatch(
                            deleteAssignedService(
                                { assignedServiceData: assignedServiceData },
                                () => {
                                    if (_isMountedClientOverview.current)
                                        dispatchAssignedServiceAction({
                                            type: "DELETE",
                                        });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid assigned service",
                });
            }
        },
        [dispatch, Swal, authToken, hideDialog, showDialog]
    );

    /* Meeting methods */
    const handleMeetingPageChange = (event, newPage) => {
        dispatchMeetingFilterAction({
            type: "SET_PAGE_AND_FETCH",
            page: newPage,
        });
    };

    const handleMeetingRowsPerPageChange = (event) => {
        dispatchMeetingFilterAction({
            type: "SET_LIMIT_AND_FETCH",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleMeetingSort = (property) => {
        dispatchMeetingFilterAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleOpenAddMeetingDialog = () => {
        if (canAddMeeting) {
            setOpenAddMeetingDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAddMeetingDialog = () => {
        setOpenAddMeetingDialog(false);
    };

    const handleInsertMeeting = () => dispatchMeetingAction({ type: "INSERT" });

    /* Tickets methods */
    const handleLoadMoreTickets = () => dispatchTicketAction({ type: "FETCH" });

    const handleTicketSort = (property) => {
        dispatchTicketAction({
            type: "SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleAddTicket = (fmUser) => {
        setOpenAddTicketDialog(true);
    };

    const handleCloseAddTicketDialog = () => {
        setOpenAddTicketDialog(false);
    };

    const fetchedClientValid = clientFetched && clientUserId;
    const fetchedClientActive =
        fetchedClientValid &&
        clientUser?.[CLIENT_USER_KEYS.STATUS] &&
        clientUser[CLIENT_USER_KEYS.STATUS] === CLIENT_USER_ACTIVE_STATUS;
    const loadError =
        !isValidUserType || !fetchedClientValid || !fetchedClientActive;

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !fetchedClientValid && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    Invalid client!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            fetchedClientValid &&
                            !fetchedClientActive && (
                                <Grid item xs={12}>
                                    <Alert severity="error" variant="outlined">
                                        Client not found!
                                    </Alert>
                                </Grid>
                            )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        {clientUser && (
                            <Grid item xs={12}>
                                <ClientDetails
                                    allIndustries={allIndustries}
                                    canAddEditClient={canAddEditClient}
                                    clientUser={clientUser}
                                    genericPageName={genericPageName}
                                    onEdit={handleClientEdit}
                                />
                            </Grid>
                        )}
                        {fetchAssignedServices && (
                            <Grid item xs={12}>
                                <AssignedServicesTable
                                    assignedServices={assignedServices}
                                    assignedServiceCounts={
                                        assignedServiceCounts
                                    }
                                    assignedServicesFetched={
                                        assignedServicesFetched
                                    }
                                    canDeleteService={canDeleteAssignedService}
                                    canEditRenewalDate={canEditRenewalDate}
                                    canUploadWODocument={canUploadWODocument}
                                    canViewDescription={canViewDescription}
                                    canViewWODocument={canViewWODocument}
                                    columnsToShow={assignedServicesColsToShow}
                                    headCells={assignedServicesHeadCells}
                                    order={assignedServiceFilterState.order}
                                    page={assignedServiceFilterState.page}
                                    rowsPerPage={
                                        assignedServiceFilterState.rowsPerPage
                                    }
                                    showPagination={true}
                                    sort={assignedServiceFilterState.sort}
                                    handlePageChange={
                                        handleAssignedServicePageChange
                                    }
                                    handleRowsPerPageChange={
                                        handleAssignedServiceRowsPerPageChange
                                    }
                                    onDelete={handleAssignedServiceDelete}
                                    onEditRenewalDate={
                                        handleOpenUpdateRenewalDateDialog
                                    }
                                    onSort={handleAssignedServiceSort}
                                    onUploadWODocument={
                                        handleOpenUploadWODocumentDialog
                                    }
                                    onViewDescription={
                                        handleOpenServiceDescriptionDialog
                                    }
                                    onViewWODocument={
                                        handleOpenViewWODocumentDialog
                                    }
                                />
                                <Stack
                                    direction={{
                                        xs: "row-reverse",
                                    }}
                                    alignItems="center"
                                    flexWrap="wrap"
                                    spacing={1}
                                    sx={{ mt: 2 }}
                                >
                                    <FmButton
                                        label="Assign Service"
                                        startIcon="add"
                                        onClick={handleOpenAssignServiceDialog}
                                    />
                                </Stack>
                            </Grid>
                        )}
                        {fetchMeetings && (
                            <Grid item xs={12}>
                                <MeetingsTable
                                    authUser={authUser}
                                    columnsToShow={meetingsColsToShow}
                                    headCells={meetingsHeadCells}
                                    meetings={meetings}
                                    meetingCounts={meetingsCount}
                                    meetingsFetched={meetingsFetched}
                                    order={meetingFilterState.order}
                                    page={meetingFilterState.page}
                                    rowsPerPage={meetingFilterState.rowsPerPage}
                                    sort={meetingFilterState.sort}
                                    showLoader={true}
                                    handlePageChange={handleMeetingPageChange}
                                    handleRowsPerPageChange={
                                        handleMeetingRowsPerPageChange
                                    }
                                    onSort={handleMeetingSort}
                                />
                                <Stack
                                    direction={{
                                        xs: "row-reverse",
                                    }}
                                    alignItems="center"
                                    flexWrap="wrap"
                                    spacing={1}
                                    sx={{ mt: 2 }}
                                >
                                    {canAddMeeting && (
                                        <FmButton
                                            label="Add Meeting"
                                            startIcon="add"
                                            onClick={handleOpenAddMeetingDialog}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                        )}
                        {fetchTickets && (
                            <Grid item xs={12}>
                                <TicketsTableLoadMore
                                    loadMore={loadMoreTickets}
                                    order={ticketState.order}
                                    sort={ticketState.sort}
                                    tickets={tickets}
                                    ticketsFetched={ticketsFetched}
                                    onLoadMore={handleLoadMoreTickets}
                                    onSort={handleTicketSort}
                                />
                                <Stack
                                    direction={{ xs: "row-reverse" }}
                                    alignItems="center"
                                    flexWrap="wrap"
                                    spacing={1}
                                    sx={{ mt: 2 }}
                                >
                                    <FmButton
                                        label="New Message"
                                        startIcon="add"
                                        onClick={handleAddTicket}
                                    />
                                    <FmButton
                                        label="View Closed Tickets"
                                        component={RouterLink}
                                        to={`${TICKETS_PAGE_LINK}?client=${clientUserId}&status=${TICKET_CLOSE_STATUS_TXT}`}
                                    />
                                </Stack>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && (
                <React.Fragment>
                    {canAddEditClient && openAddEditClientDialog && (
                        <AddEditCompanyClientDialog
                            currentClientUser={clientUser}
                            currentCompany={company}
                            open={openAddEditClientDialog}
                            onClose={handleCloseAddEditClientDialog}
                            onUpdate={handleClientUpdate}
                        />
                    )}
                    {fetchMeetings && canAddMeeting && openAddMeetingDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddMeetingDialog
                                currentClientUserId={clientUserId}
                                currentCompanyId={companyId}
                                open={openAddMeetingDialog}
                                onClose={handleCloseAddMeetingDialog}
                                onInsert={handleInsertMeeting}
                            />
                        </Suspense>
                    )}
                    {fetchAssignedServices && openAssignServiceDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AssignServiceDialog
                                currentCompany={company}
                                open={openAssignServiceDialog}
                                onClose={handleCloseAssignServiceDialog}
                                onDispatchAction={dispatchAssignedServiceAction}
                            />
                        </Suspense>
                    )}
                    {fetchTickets && openAddTicketDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddTicketDialog
                                currentClientUser={clientUser}
                                currentCompany={company}
                                fetchOnInsert={true}
                                open={openAddTicketDialog}
                                onClose={handleCloseAddTicketDialog}
                                onDispatchAction={dispatchTicketActionsAction}
                            />
                        </Suspense>
                    )}
                    {fetchAssignedServices &&
                        canEditRenewalDate &&
                        openUpdateRenewalDateDialog && (
                            <UpdateRenewalDateDialog
                                currentAssignedService={currentAssignedService}
                                open={openUpdateRenewalDateDialog}
                                setLocalState={true}
                                onClose={handleCloseUpdateRenewalDateDialog}
                                onDispatchAction={dispatchAssignedServiceAction}
                            />
                        )}
                    {fetchAssignedServices &&
                        canUploadWODocument &&
                        openUploadWODocumentDialog && (
                            <UploadWorkOrderDocumentDialog
                                currentAssignedService={currentAssignedService}
                                open={openUploadWODocumentDialog}
                                setLocalState={true}
                                onClose={handleCloseUploadWODocumentDialog}
                                onDispatchAction={dispatchAssignedServiceAction}
                            />
                        )}
                    {fetchAssignedServices &&
                        canViewWODocument &&
                        openViewWODocumentDialog && (
                            <ViewWorkOrderDocumentDialog
                                currentAssignedService={currentAssignedService}
                                open={openViewWODocumentDialog}
                                setLocalState={true}
                                onClose={handleCloseViewWODocumentDialog}
                                onDispatchAction={dispatchAssignedServiceAction}
                            />
                        )}
                    {fetchAssignedServices &&
                        canViewDescription &&
                        openServiceDescriptionDialog && (
                            <ServiceDescriptionDialog
                                currentService={currentAssignedService}
                                serviceKeys={SERVICE_KEYS}
                                open={openServiceDescriptionDialog}
                                onClose={handleCloseServiceDescriptionDialog}
                            />
                        )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ClientOverview;
