import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { testEmailSettings } from "../../redux/actions/emailSettings";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";
import {
    AUTH_USER_KEYS,
    CKEDITOR_TOOLBAR,
    EMAIL_SETTINGS_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import {
    checkEmailValid,
    checkEmailBodyValid,
    checkSubjectValid,
} from "../../utils/validationHelpers";

const TestEmailSettingsDialog = ({ open, onClose }) => {
    const _isTestEmailSettingsDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [subject, setSubject] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [body, setBody] = useState("");
    const [bodyError, setBodyError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isValidUserType = isAdminUser;

    useEffect(() => {
        return () => {
            _isTestEmailSettingsDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setSubject("");
            setSubjectError("");
            setEmail("");
            setEmailError("");
            setBody("");
            setBodyError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case EMAIL_SETTINGS_KEYS.TEST_SUBJECT:
                        setSubjectError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.TEST_TO:
                        setEmailError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.TEST_BODY:
                        setBodyError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const subjectBlurHandler = (event) => {
        const validationResult = checkSubjectValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSubjectError(validationResult.msg);
            }
        } else {
            setSubjectError(VALIDATION_FAILED);
        }
    };

    const emailBlurHandler = (event) => {
        const validationResult = checkEmailValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setEmailError(validationResult.msg);
            }
        } else {
            setEmailError(VALIDATION_FAILED);
        }
    };

    const bodyChangeHandler = (event) => {
        setBody(event.editor.getData());
        setBodyError("");
    };

    const bodyBlurHandler = (event) => {
        const validationResult = checkEmailBodyValid(event.editor.getData());
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setBodyError(validationResult.msg);
            }
        } else {
            setBodyError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const subjectValidationResult = checkSubjectValid(subject);
        if (subjectValidationResult.status) {
            if (subjectValidationResult.status === "false") {
                formIsValid = false;
                setSubjectError(subjectValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSubjectError(VALIDATION_FAILED);
        }

        const emailValidationResult = checkEmailValid(email);
        if (emailValidationResult.status) {
            if (emailValidationResult.status === "false") {
                formIsValid = false;
                setEmailError(emailValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setEmailError(VALIDATION_FAILED);
        }

        const bodyValidationResult = checkEmailBodyValid(body);
        if (bodyValidationResult.status) {
            if (bodyValidationResult.status === "false") {
                formIsValid = false;
                setBodyError(bodyValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setBodyError(VALIDATION_FAILED);
        }

        if (isValidUserType && formIsValid) {
            const emailSettingsData = {
                authcode: authToken,
                [EMAIL_SETTINGS_KEYS.TEST_BODY]: body,
                [EMAIL_SETTINGS_KEYS.TEST_SUBJECT]: subject,
                [EMAIL_SETTINGS_KEYS.TEST_TO]: email,
            };

            dispatch(
                testEmailSettings(
                    { emailSettingsData: emailSettingsData },
                    () => {
                        if (_isTestEmailSettingsDialogMounted.current) {
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isTestEmailSettingsDialogMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title="Test Email Settings"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {isValidUserType && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    label="To"
                                    value={email}
                                    margin="dense"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError("");
                                    }}
                                    onBlur={emailBlurHandler}
                                    error={emailError !== ""}
                                    helperText={emailError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="subject"
                                    name="subject"
                                    label="Subject"
                                    value={subject}
                                    margin="dense"
                                    onChange={(e) => {
                                        setSubject(e.target.value);
                                        setSubjectError("");
                                    }}
                                    onBlur={subjectBlurHandler}
                                    error={subjectError !== ""}
                                    helperText={subjectError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <CKEditor
                                        id="body"
                                        initData={body}
                                        config={{
                                            contentsCss: [
                                                `${ASSET_MUSEO_SANS}/styles.css`,
                                                `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                            ],
                                            toolbar: CKEDITOR_TOOLBAR,
                                            removePlugins: "image",
                                        }}
                                        onBlur={bodyBlurHandler}
                                        onChange={bodyChangeHandler}
                                    />
                                    {bodyError && (
                                        <FormHelperText error id="body-error">
                                            {bodyError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Send Mail
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!isValidUserType && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

TestEmailSettingsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

TestEmailSettingsDialog.defaultProps = {
    open: false,
    // onClose: () => {},
};

export default TestEmailSettingsDialog;
