import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { capitalizeFLetter } from "@jumbo/utils";
import EmptyValue from "../../shared/widgets/EmptyValue";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import TableColorCard from "../../shared/widgets/TableColorCard";
import { getAssignedServiceStatusList } from "../../utils/appHelpers";
import {
    ASSIGNED_SERVICE_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const ASSIGNED_SERVICE_STATUS_LIST = getAssignedServiceStatusList();

const AssignedServicesTable = ({
    ariaLabel,
    assignedServices,
    assignedServiceCounts,
    assignedServicesFetched,
    canDeleteService,
    canEditService,
    canEditRenewalDate,
    canUploadWODocument,
    canViewDescription,
    canViewWODocument,
    cardColor,
    columnsToShow,
    headCells,
    order,
    page,
    rowsPerPage,
    showColorCard,
    showPagination,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onDelete,
    onEdit,
    onEditRenewalDate,
    onSort,
    onUploadWODocument,
    onViewDescription,
    onViewWODocument,
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const assignedServicesTotalCount =
        assignedServiceCounts &&
        assignedServiceCounts[ASSIGNED_SERVICE_KEYS.TOTAL] &&
        !isNaN(parseInt(assignedServiceCounts[ASSIGNED_SERVICE_KEYS.TOTAL])) &&
        parseInt(assignedServiceCounts[ASSIGNED_SERVICE_KEYS.TOTAL]) > 0
            ? parseInt(assignedServiceCounts[ASSIGNED_SERVICE_KEYS.TOTAL])
            : 0;

    const fetchedAssignedServicesCount =
        assignedServices && assignedServices.length > 0
            ? assignedServices.length
            : 0;

    const assignedServiceCountNonZero =
        fetchedAssignedServicesCount > 0 && assignedServicesTotalCount > 0;

    const showActions =
        canDeleteService ||
        canEditService ||
        canEditRenewalDate ||
        canUploadWODocument ||
        canViewDescription ||
        canViewWODocument;

    const table = (
        <Table aria-label={ariaLabel}>
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => {
                        const sortDirection =
                            sort === headCell.id
                                ? order === SORT_ORDER_ASC
                                    ? SORT_ORDER_DESC
                                    : SORT_ORDER_ASC
                                : SORT_ORDER_ASC;

                        return headCell.show ? (
                            <TableCell key={headCell.id}>
                                {headCell.sortColumn ? (
                                    <TableSortLabel
                                        active={sort === headCell.id}
                                        direction={sortDirection}
                                        onClick={() => onSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {sort === headCell.id ? (
                                            <Box
                                                component="span"
                                                sx={visuallyHidden}
                                            >
                                                {order === SORT_ORDER_DESC
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ) : null;
                    })}
                    {showActions && (
                        <TableCell
                            key="actions"
                            sx={{ width: "15%", textAlign: "center" }}
                        >
                            Actions
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody
                sx={{
                    p: (theme) => theme.spacing(1),
                }}
            >
                {assignedServiceCountNonZero ? (
                    assignedServices.map((assignedService) => {
                        const assignedServiceStatusObj =
                            ASSIGNED_SERVICE_STATUS_LIST.find(
                                (statusObj) =>
                                    statusObj.value ===
                                    assignedService[
                                        ASSIGNED_SERVICE_KEYS.STATUS
                                    ]
                            );
                        const assignedServiceStatusTxt =
                            assignedServiceStatusObj?.label || "NA";
                        const assignedServiceStatusColor =
                            assignedServiceStatusObj?.color || "primary";

                        return (
                            <TableRow
                                key={assignedService[ASSIGNED_SERVICE_KEYS.ID]}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {columnsToShow.includes(
                                    ASSIGNED_SERVICE_KEYS.NAME
                                ) && (
                                    <TableCell component="th" scope="row">
                                        <Typography
                                            component="span"
                                            fontWeight="bolder"
                                        >
                                            {
                                                assignedService[
                                                    ASSIGNED_SERVICE_KEYS.NAME
                                                ]
                                            }
                                        </Typography>
                                    </TableCell>
                                )}
                                {columnsToShow.includes(
                                    ASSIGNED_SERVICE_KEYS.COMPANY_NAME
                                ) && (
                                    <TableCell>
                                        <NameLink
                                            component={RouterLink}
                                            to={`/company/${
                                                assignedService[
                                                    ASSIGNED_SERVICE_KEYS
                                                        .COMPANY_ID
                                                ]
                                            }/dashboard/`}
                                        >
                                            {
                                                assignedService[
                                                    ASSIGNED_SERVICE_KEYS
                                                        .COMPANY_NAME
                                                ]
                                            }
                                        </NameLink>
                                    </TableCell>
                                )}
                                {columnsToShow.includes(
                                    ASSIGNED_SERVICE_KEYS.CREATED_AT
                                ) && (
                                    <TableCell>
                                        {
                                            assignedService[
                                                ASSIGNED_SERVICE_KEYS.CREATED_AT
                                            ]
                                        }
                                    </TableCell>
                                )}
                                {columnsToShow.includes(
                                    ASSIGNED_SERVICE_KEYS.RENEWAL_DATE
                                ) && (
                                    <TableCell>
                                        {assignedService[
                                            ASSIGNED_SERVICE_KEYS.RENEWAL_DATE
                                        ] || <EmptyValue />}
                                    </TableCell>
                                )}
                                {columnsToShow.includes(
                                    ASSIGNED_SERVICE_KEYS.STATUS
                                ) && (
                                    <TableCell>
                                        <Typography
                                            variant="span"
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette[
                                                        assignedServiceStatusColor
                                                    ].main,
                                            }}
                                        >
                                            {capitalizeFLetter(
                                                assignedServiceStatusTxt
                                            )}
                                        </Typography>
                                    </TableCell>
                                )}
                                {showActions && (
                                    <TableCell>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {canViewDescription && (
                                                <TableActionIcon
                                                    iconComponent="description"
                                                    label="view service description"
                                                    tooltip="View Service Description"
                                                    onClick={() =>
                                                        onViewDescription(
                                                            assignedService
                                                        )
                                                    }
                                                />
                                            )}
                                            {canEditService && (
                                                <TableActionIcon
                                                    iconComponent="edit"
                                                    label="edit service"
                                                    tooltip="Edit Service"
                                                    onClick={() =>
                                                        onEdit(assignedService)
                                                    }
                                                />
                                            )}
                                            {canEditRenewalDate && (
                                                <TableActionIcon
                                                    iconComponent="update"
                                                    label="update renewal date"
                                                    tooltip="Update Renewal Date"
                                                    onClick={() =>
                                                        onEditRenewalDate(
                                                            assignedService
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUploadWODocument && (
                                                <TableActionIcon
                                                    iconComponent="upload"
                                                    label="upload work order documents"
                                                    tooltip="Upload Work Order Documents"
                                                    onClick={() =>
                                                        onUploadWODocument(
                                                            assignedService
                                                        )
                                                    }
                                                />
                                            )}
                                            {canViewWODocument && (
                                                <TableActionIcon
                                                    iconComponent="filePresent"
                                                    label="view work order documents"
                                                    tooltip="View Work Order Documents"
                                                    onClick={() =>
                                                        onViewWODocument(
                                                            assignedService
                                                        )
                                                    }
                                                />
                                            )}
                                            {canDeleteService && (
                                                <TableActionIcon
                                                    iconComponent="delete"
                                                    label="delete assigned service"
                                                    tooltip="Delete Assigned Service"
                                                    onClick={() =>
                                                        onDelete(
                                                            assignedService
                                                        )
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={colspan}>
                            <Alert
                                severity={
                                    assignedServicesFetched ? "error" : "info"
                                }
                            >
                                {assignedServicesFetched
                                    ? "There are no records found."
                                    : "Loading services..."}
                            </Alert>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    return (
        <React.Fragment>
            {showColorCard ? (
                <TableColorCard
                    cardColor={cardColor}
                    title={
                        assignedServiceCounts?.[ASSIGNED_SERVICE_KEYS.TOTAL]
                            ? `${
                                  assignedServiceCounts[
                                      ASSIGNED_SERVICE_KEYS.TOTAL
                                  ]
                              }`
                            : "NA"
                    }
                    subtitle={`${
                        assignedServiceCounts?.[ASSIGNED_SERVICE_KEYS.TOTAL] &&
                        assignedServiceCounts[ASSIGNED_SERVICE_KEYS.TOTAL] === 1
                            ? "Service"
                            : "Services"
                    }`}
                    sx={{ cardSx: { width: "100%" } }}
                >
                    {table}
                </TableColorCard>
            ) : (
                <TableContainer
                    component={Paper}
                    elevation={4}
                    sx={{ borderRadius: "10px" }}
                >
                    {table}
                </TableContainer>
            )}
            {showPagination && (
                <TablePagination
                    rowsPerPageOptions={PAGINATION_PAGES}
                    component="div"
                    count={
                        assignedServiceCountNonZero
                            ? parseInt(
                                  assignedServiceCounts[
                                      ASSIGNED_SERVICE_KEYS.TOTAL
                                  ]
                              )
                            : 0
                    }
                    labelRowsPerPage=""
                    rowsPerPage={rowsPerPage}
                    page={assignedServiceCountNonZero ? page : 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            )}
        </React.Fragment>
    );
};

AssignedServicesTable.propTypes = {
    ariaLabel: PropTypes.string,
    assignedServices: PropTypes.array,
    assignedServiceCounts: PropTypes.object,
    assignedServicesFetched: PropTypes.bool,
    canDeleteService: PropTypes.bool,
    canEditService: PropTypes.bool,
    canEditRenewalDate: PropTypes.bool,
    canUploadWODocument: PropTypes.bool,
    canViewDescription: PropTypes.bool,
    canViewWODocument: PropTypes.bool,
    cardColor: PropTypes.string,
    columnsToShow: PropTypes.array,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    showColorCard: PropTypes.bool,
    showPagination: PropTypes.bool,
    sort: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onEditRenewalDate: PropTypes.func,
    onSort: PropTypes.func,
    onUploadWODocument: PropTypes.func,
    onViewDescription: PropTypes.func,
    onViewWODocument: PropTypes.func,
};

AssignedServicesTable.defaultProps = {
    ariaLabel: "Assigned Services Table",
    assignedServices: [],
    assignedServiceCounts: null,
    assignedServicesFetched: false,
    canDeleteService: false,
    canEditService: false,
    canEditRenewalDate: false,
    canUploadWODocument: false,
    canViewDescription: false,
    canViewWODocument: false,
    cardColor: "",
    columnsToShow: [],
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    showColorCard: false,
    showPagination: false,
    sort: "",
    handlePageChange: () => {},
    handleRowsPerPageChange: () => {},
    onDelete: () => {},
    onEdit: () => {},
    onEditRenewalDate: () => {},
    // onSort: () => {},
    onUploadWODocument: () => {},
    onViewDescription: () => {},
    onViewWODocument: () => {},
};

export default AssignedServicesTable;
