import basicAuthAxios from "../../services/auth/basicAuth";

import { fetchError, fetchStart, fetchSuccess } from "./common";

import {
    ADD_MEETING,
    DELETE_MEETING,
    EDIT_MEETING,
    GET_MEETING_COUNT,
    GET_MEETINGS,
    SET_MEETING_DETAILS,
} from "../../utils/constants/actionTypes";

import {
    ADD_MEETING_LINK,
    CANCEL_MEETING_LINK,
    /* DELETE_MEETING_LINK, */
    /* GET_MEETING_LINK, */
    GET_MEETINGS_LINK,
    REQUEST_NEW_MEETING_TIME,
    UPDATE_MEETING_LINK,
    UPDATE_MEETING_STATUS_LINK,
    UPDATE_TIME_REQUEST_STATUS_LINK,
} from "../../utils/constants/apiLinks";

import {
    MEETING_KEYS,
    MEETING_APPROVED_STATUS,
    MEETING_REJECTED_STATUS,
    NEW_MEETING_TIME_APPROVE_STATUS,
    NEW_MEETING_TIME_REJECT_STATUS,
} from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addMeeting = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(ADD_MEETING_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meeting) {
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: ADD_MEETING,
                                    payload: data.meeting,
                                });
                            }

                            if (callbackFun) callbackFun(data.meeting);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredMeetings = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_MEETINGS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meetings) {
                            const meetings = data.meetings;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [MEETING_KEYS.TOTAL]: count[
                                        MEETING_KEYS.TOTAL
                                    ]
                                        ? count[MEETING_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [MEETING_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Meeting was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected meeting was deleted successfully";
                                } else if (fromAction.cancelled) {
                                    successMsg =
                                        "Selected meeting was cancelled successfully";
                                } else if (fromAction.statusUpdated) {
                                    successMsg = `Meeting ${fromAction.updatedStatus} successfully`;
                                } else if (
                                    fromAction.timeRequestStatusUpdated
                                ) {
                                    successMsg = `Meeting time request ${fromAction.updatedTimeRequestStatus} successfully`;
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({ type: GET_MEETINGS, payload: meetings });
                            dispatch({
                                type: GET_MEETING_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(meetings);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_MEETINGS, payload: [] });
                dispatch({
                    type: GET_MEETING_COUNT,
                    payload: {
                        [MEETING_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

export const getFilteredMeetingsLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_MEETINGS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meetings) {
                            const meetings = data.meetings;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [MEETING_KEYS.TOTAL]: count[
                                        MEETING_KEYS.TOTAL
                                    ]
                                        ? count[MEETING_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [MEETING_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request?.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Meeting was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected meeting was deleted successfully";
                                } else if (fromAction.cancelled) {
                                    successMsg =
                                        "Selected meeting was cancelled successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            if (callbackFun)
                                callbackFun({
                                    meetings: meetings,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

export const setCurrentMeeting = (meeting) => {
    return (dispatch) => {
        dispatch({ type: SET_MEETING_DETAILS, payload: meeting });
    };
};

export const updateMeeting = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_MEETING_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meeting) {
                            const updatedMeeting = data.meeting;
                            if (request?.showAlert && request.showAlert) {
                                dispatch(
                                    fetchSuccess(
                                        "Selected meeting was updated successfully."
                                    )
                                );
                            }
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_MEETING,
                                    payload: updatedMeeting,
                                });
                            }
                            if (callbackFun) callbackFun(updatedMeeting);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateMeetingStatus = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_MEETING_STATUS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meeting) {
                            const updatedMeeting = data.meeting;
                            let successMsg = "";
                            if (request?.showAlert && request.showAlert) {
                                successMsg = "Status was updated successfully.";
                                if (
                                    updatedMeeting[MEETING_KEYS.STATUS] ===
                                    MEETING_APPROVED_STATUS
                                ) {
                                    successMsg = "Meeting has been approved.";
                                } else if (
                                    updatedMeeting[MEETING_KEYS.STATUS] ===
                                    MEETING_REJECTED_STATUS
                                ) {
                                    successMsg = "Meeting has been rejected.";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_MEETING,
                                    payload: updatedMeeting,
                                });
                            }
                            if (callbackFun) callbackFun(updatedMeeting);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateNewTimeRequest = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_TIME_REQUEST_STATUS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meeting) {
                            const updatedMeeting = data.meeting;

                            let successMsg = "";
                            if (request?.showAlert && request.showAlert) {
                                if (
                                    updatedMeeting[
                                        MEETING_KEYS.REQUEST_STATUS
                                    ] === NEW_MEETING_TIME_APPROVE_STATUS
                                ) {
                                    successMsg = "Request has been approved.";
                                } else if (
                                    updatedMeeting[
                                        MEETING_KEYS.REQUEST_STATUS
                                    ] === NEW_MEETING_TIME_REJECT_STATUS
                                ) {
                                    successMsg = "Request has been rejected.";
                                } else {
                                    successMsg =
                                        "Status was updated successfully.";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));

                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_MEETING,
                                    payload: updatedMeeting,
                                });
                            }

                            if (callbackFun) callbackFun(updatedMeeting);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const cancelMeeting = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(CANCEL_MEETING_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected meeting was deleted successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_MEETING,
                            payload:
                                request.meetingData[MEETING_KEYS.MEETING_ID],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

/* export const deleteMeeting = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_MEETING_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        // dispatch(
                        //     fetchSuccess(
                        //         "Selected meeting was deleted successfully."
                        //     )
                        // );
                        dispatch({
                            type: DELETE_MEETING,
                            payload:
                                request.meetingData[MEETING_KEYS.MEETING_ID],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
}; */

export const requestNewMeetingTime = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.meetingData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(REQUEST_NEW_MEETING_TIME, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.meeting) {
                            dispatch(
                                fetchSuccess(
                                    "Request has been submitted successfully."
                                )
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_MEETING,
                                    payload: data.meeting,
                                });
                            }
                            if (callbackFun) callbackFun(data.meeting);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};
