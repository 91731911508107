/* import moment from "moment"; */
import moment from "moment-timezone";

export const getCurrentDateTimeFormatted = (
    format = "YYYY-MM-DD HH:mm:ss",
    timezone = ""
) => {
    if (timezone) {
        return moment().tz(timezone).format(format);
    } else {
        return moment().format(format);
    }
};

export const getDateinDesiredFormat = (date, format) => {
    return moment(date).format(format);
};

export const getMomentDateObj = (dateString) => {
    const dateObj = new Date(dateString);
    return moment(dateObj);
};

export const getMomentUTCDateObj = (dateString) => {
    const dateObj = new Date(dateString);
    return moment.utc(dateObj);
};

export const getMonthsList = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        months.push({
            value: `${i + 1}`,
            label: moment().month(i).format("MMMM"),
        });
    }
    return months;
};

export const getTimeList12HrFormat = (
    startHr = 0,
    endHr = 24,
    endMin = 59,
    interval = 15,
    prefix = "",
    suffix = ""
) => {
    endHr = endHr < 0 ? 0 : endHr;
    endHr = endHr > 24 ? 24 : endHr;

    const items = [];
    let hr = startHr;
    while (hr <= endHr) {
        for (let min = 0; min < 60; min += interval) {
            if (hr === endHr && min > endMin) break;

            let item = null;
            if (min === 0) {
                item = {
                    label: `${prefix}${moment({ hour: hr }).format(
                        "hh:mm A"
                    )}${suffix}`,
                    value: moment({ hour: hr }).format("hh:mm A"),
                    standard: moment({ hour: hr }).format("HH:mm:ss"),
                };
            } else {
                item = {
                    label: `${prefix}${moment({
                        hour: hr,
                        minute: min,
                    }).format("hh:mm A")}${suffix}`,
                    value: moment({ hour: hr, minute: min }).format("hh:mm A"),
                    standard: moment({ hour: hr, minute: min }).format(
                        "HH:mm:ss"
                    ),
                };
            }
            if (item) items.push(item);
        }
        hr += 1;
    }
    return items;
};

export const isStartBeforeEnd = (start, end) => {
    return moment(start).isBefore(end);
};

export const isPastFromToday = (date) => {
    return !moment(date).isSameOrAfter(moment(), "day");
};
