import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const PageLoader = ({ open }) => {
    return (
        <Backdrop
            open={open}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        >
            <CircularProgress />
        </Backdrop>
    );
};

PageLoader.propTypes = {
    open: PropTypes.bool,
};

PageLoader.defaultProps = {
    open: false,
};

export default PageLoader;
