import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { SURVEY_ANSWER_KEYS } from "../../utils/constants/appData";

const TakeSurveyTextarea = ({
    answerObj,
    errorMsg,
    questionId,
    onBlur,
    onChange,
}) => {
    if (!answerObj || !questionId) return null;

    const answer = answerObj[SURVEY_ANSWER_KEYS.ANSWER];

    const handleBlur = (event) => onBlur(questionId, event.target.value);

    const handleChange = (event) => onChange(questionId, event.target.value);

    return (
        <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            name={`question-${questionId}-answer`}
            label=""
            value={answer}
            margin="dense"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errorMsg !== ""}
            helperText={errorMsg}
        />
    );
};

TakeSurveyTextarea.propTypes = {
    answerObj: PropTypes.object,
    errorMsg: PropTypes.string,
    questionId: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

TakeSurveyTextarea.defaultProps = {
    answerObj: null,
    errorMsg: "",
    questionId: "",
    // onBlur: () => {},
    // onChange: () => {},
};

export default TakeSurveyTextarea;
