export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";

// FM Users
export const ADD_FM_USER = "ADD_FM_USER";
export const DELETE_FM_USER = "DELETE_FM_USER";
export const EDIT_FM_USER = "EDIT_FM_USER";
export const GET_FM_USER_COUNT = "GET_FM_USER_COUNT";
export const GET_FM_USERS = "GET_FM_USERS";
export const SET_FM_USER_DETAILS = "SET_FM_USER_DETAILS";

// Clients
export const ADD_CLIENT_USER = "ADD_CLIENT_USER";
export const DELETE_CLIENT_USER = "DELETE_CLIENT_USER";
export const EDIT_CLIENT_USER = "EDIT_CLIENT_USER";
export const GET_CLIENT_USER_COUNT = "GET_CLIENT_USER_COUNT";
export const GET_CLIENT_USERS = "GET_CLIENT_USERS";
export const SET_CLIENT_USER_DETAILS = "SET_CLIENT_USER_DETAILS";

// Clients FM Users
export const GET_CLIENT_FM_USERS = "GET_CLIENT_FM_USERS";
export const GET_CLIENT_FM_USER_COUNT = "GET_CLIENT_FM_USER_COUNT";
export const SET_CLIENT_FM_USER_DETAILS = "SET_CLIENT_FM_USER_DETAILS";

// Office Contacts
export const ADD_OFFICE_CONTACT = "ADD_OFFICE_CONTACT";
export const DELETE_OFFICE_CONTACT = "DELETE_OFFICE_CONTACT";
export const EDIT_OFFICE_CONTACT = "EDIT_OFFICE_CONTACT";
export const GET_OFFICE_CONTACT_COUNT = "GET_OFFICE_CONTACT_COUNT";
export const GET_OFFICE_CONTACTS = "GET_OFFICE_CONTACTS";
export const SET_OFFICE_CONTACT_DETAILS = "SET_OFFICE_CONTACT_DETAILS";

// Folder
export const ADD_FOLDER = "ADD_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const EDIT_FOLDER = "EDIT_FOLDER";
export const GET_FOLDER_COUNT = "GET_FOLDER_COUNT";
export const GET_FOLDERS = "GET_FOLDERS";
export const SET_FOLDER_DETAILS = "SET_FOLDER_DETAILS";

// Account Assets
export const GET_ACCOUNT_ASSET_COUNT = "GET_ACCOUNT_ASSET_COUNT";
export const GET_ACCOUNT_ASSETS = "GET_ACCOUNT_ASSETS";
export const SET_ACCOUNT_ASSET_DETAILS = "SET_ACCOUNT_ASSET_DETAILS";

// Tickets
export const ADD_TICKET = "ADD_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
export const EDIT_TICKET = "EDIT_TICKET";
export const GET_TICKET_COUNT = "GET_TICKET_COUNT";
export const GET_TICKETS = "GET_TICKETS";
export const SET_TICKET_DETAILS = "SET_TICKET_DETAILS";

// Meetings
export const ADD_MEETING = "ADD_MEETING";
export const DELETE_MEETING = "DELETE_MEETING";
export const EDIT_MEETING = "EDIT_MEETING";
export const GET_MEETING_COUNT = "GET_MEETING_COUNT";
export const GET_MEETINGS = "GET_MEETINGS";
export const SET_MEETING_DETAILS = "SET_MEETING_DETAILS";

// Services
export const ADD_SERVICE = "ADD_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const GET_SERVICE_COUNT = "GET_SERVICE_COUNT";
export const GET_SERVICES = "GET_SERVICES";
export const SET_SERVICE_DETAILS = "SET_SERVICE_DETAILS";

// Assigned Services
export const DELETE_ASSIGNED_SERVICE = "DELETE_ASSIGNED_SERVICE";
export const EDIT_ASSIGNED_SERVICE = "EDIT_ASSIGNED_SERVICE";
export const GET_ASSIGNED_SERVICE_COUNT = "GET_ASSIGNED_SERVICE_COUNT";
export const GET_ASSIGNED_SERVICES = "GET_ASSIGNED_SERVICES";
export const SET_ASSIGNED_SERVICE_DETAILS = "SET_ASSIGNED_SERVICE_DETAILS";

// Invoices
export const GET_INVOICE_COUNT = "GET_INVOICE_COUNT";
export const GET_INVOICES = "GET_INVOICES";
export const SET_INVOICE_DETAILS = "SET_INVOICE_DETAILS";

// Case Studies
export const ADD_CASE_STUDY = "ADD_CASE_STUDY";
export const DELETE_CASE_STUDY = "DELETE_CASE_STUDY";
export const EDIT_CASE_STUDY = "EDIT_CASE_STUDY";
export const GET_CASE_STUDY_COUNT = "GET_CASE_STUDY_COUNT";
export const GET_CASE_STUDIES = "GET_CASE_STUDIES";
export const SET_CASE_STUDY_DETAILS = "SET_CASE_STUDY_DETAILS";

// Alerts
export const GET_ALERT_COUNT = "GET_ALERT_COUNT";
export const GET_ALERTS = "GET_ALERTS";
export const SET_ALERT_DETAILS = "SET_ALERT_DETAILS";

// Statements
export const GET_STATEMENT_COUNT = "GET_STATEMENT_COUNT";
export const GET_STATEMENTS = "GET_STATEMENTS";
export const SET_STATEMENT_DETAILS = "SET_STATEMENT_DETAILS";

// Specials
export const ADD_SPECIAL = "ADD_SPECIAL";
export const DELETE_SPECIAL = "DELETE_SPECIAL";
export const EDIT_SPECIAL = "EDIT_SPECIAL";
export const GET_SPECIAL_COUNT = "GET_SPECIAL_COUNT";
export const GET_SPECIALS = "GET_SPECIALS";
export const SET_SPECIAL_DETAILS = "SET_SPECIAL_DETAILS";

// Surveys
export const ADD_SURVEY = "ADD_SURVEY";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const EDIT_SURVEY = "EDIT_SURVEY";
export const GET_SURVEY_COUNT = "GET_SURVEY_COUNT";
export const GET_SURVEYS = "GET_SURVEYS";
export const SET_SURVEY_DETAILS = "SET_SURVEY_DETAILS";

// Industries
export const GET_INDUSTRY_COUNT = "GET_INDUSTRY_COUNT";
export const GET_INDUSTRIES = "GET_INDUSTRIES";

// Asset Types
export const GET_ASSET_TYPE_COUNT = "GET_ASSET_TYPE_COUNT";
export const GET_ASSET_TYPES = "GET_ASSET_TYPES";

// Announcements
export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const EDIT_ANNOUNCEMENT = "EDIT_ANNOUNCEMENT";
export const GET_ANNOUNCEMENT_COUNT = "GET_ANNOUNCEMENT_COUNT";
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const SET_ANNOUNCEMENT_DETAILS = "SET_ANNOUNCEMENT_DETAILS";

// Companies
export const ADD_COMPANY = "ADD_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const GET_COMPANY_COUNT = "GET_COMPANY_COUNT";
export const GET_COMPANIES = "GET_COMPANIES";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";

// Reports
export const ADD_REPORT = "ADD_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const EDIT_REPORT = "EDIT_REPORT";
export const GET_REPORT_COUNT = "GET_REPORT_COUNT";
export const GET_REPORTS = "GET_REPORTS";
export const SET_REPORT_DETAILS = "SET_REPORT_DETAILS";
