import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import MultilineTypography from "../../shared/widgets/MultilineTypography";
/* import { getAssetFileNameFromLink } from "../../utils/accountAssetHelpers"; */
import { TICKET_KEYS } from "../../utils/constants/appData";

const TicketDescriptionDialog = ({ currentTicket, open, onClose }) => {
    const _isTicketDescriptionDialogMounted = useRef(true);

    const [ticketIsValid, setTicketIsValid] = useState(false);

    useEffect(() => {
        return () => {
            _isTicketDescriptionDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setTicketIsValid(false);
        }
    }, [open]);

    useEffect(() => {
        if (
            currentTicket &&
            currentTicket?.[TICKET_KEYS.ID] &&
            currentTicket[TICKET_KEYS.ID]
        ) {
            setTicketIsValid(true);
        }
    }, [currentTicket]);

    let ticketDocumentsHtml = null;
    if (ticketIsValid) {
        const baseUrl = currentTicket?.[TICKET_KEYS.BASE_URL] || "";
        const ticketDocuments =
            currentTicket?.[TICKET_KEYS.DOCUMENTS_LIST] || null;
        if (ticketDocuments && ticketDocuments.length > 0) {
            ticketDocumentsHtml = (
                <List disablePadding>
                    {ticketDocuments.map((ticketDocument, idx) => {
                        const documentLink = `${baseUrl}/${
                            ticketDocument[TICKET_KEYS.FILE_PATH]
                        }`;
                        /* const documentName =
                            getAssetFileNameFromLink(documentLink); */
                        const documentName =
                            ticketDocument[TICKET_KEYS.ORIGINAL_FILE_NAME];

                        return (
                            <ListItem
                                disableGutters
                                disablePadding
                                key={`ticket-${
                                    currentTicket[TICKET_KEYS.ID]
                                }-document-${idx}`}
                                component={Link}
                                href={documentLink}
                                target="_blank"
                                rel="noopener"
                                underline="hover"
                            >
                                <ListItemButton disableGutters>
                                    <ListItemIcon>
                                        <FilePresentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={documentName} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            );
        }
    }

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    ticketIsValid
                        ? `${currentTicket[TICKET_KEYS.SUBJECT]}`
                        : "Invalid Ticket!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    {ticketIsValid && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <MultilineTypography variant="body1">
                                    {currentTicket[TICKET_KEYS.DESCRIPTION]}
                                </MultilineTypography>
                            </Grid>
                            <Grid item xs={12}>
                                {ticketDocumentsHtml}
                            </Grid>
                        </React.Fragment>
                    )}
                    {!ticketIsValid && (
                        <Grid item xs={12}>
                            <Alert severity="error">Invalid ticket!</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

TicketDescriptionDialog.propTypes = {
    currentTicket: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

TicketDescriptionDialog.defaultProps = {
    currentTicket: null,
    open: false,
    // onClose: () => {},
};

export default TicketDescriptionDialog;
