import {
    ACCOUNT_ASSET_PENDING_STATUS_ID,
    ACCOUNT_ASSET_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "./constants/appData";

export const getAssetFileNameFromLink = (url = "") => {
    if (url) {
        return url.substring(url.lastIndexOf("/") + 1);
    } else {
        return "";
    }
};

export const getAssetFileExtFromLink = (url = "") => {
    if (url) {
        return url.split(".").pop();
    } else {
        return "";
    }
};

/* export const isAccountAssetDeletable = (
    authUserId,
    authUserType,
    accountAsset
) => {
    // ADMIN: Any asset
    // FM USER: Any asset of their company.
    // (API will have to make sure that FM user can only view assets of their assigned companies.)
    // CLIENT: Only approved assets of their company
    // (API will have to make sure that Client can only view assets of their company.)
    
    const assetStatus = accountAsset?.[ACCOUNT_ASSET_KEYS.STATUS] || "";
    const assetApproved =
        assetStatus === ACCOUNT_ASSET_APPROVED_STATUS_ID;

    const isAdminOrFmUser = [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID].includes(
        authUserType
    );
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;

    let canDeleteAsset = false;
    if (authUserId && authUserType) {
        if (isAdminOrFmUser) {
            canDeleteAsset = true;
        } else if (isClientUser) {
            canDeleteAsset = assetApproved;
        }
    }

    return canDeleteAsset;
}; */

export const isAccountAssetDeletable = (
    authUserId,
    authUserType,
    accountAsset
) => {
    /* Only assets with status "pending" can be deleted */
    const assetStatus = accountAsset?.[ACCOUNT_ASSET_KEYS.STATUS] || "";
    let canDeleteAsset = false;
    if (authUserId && authUserType) {
        canDeleteAsset = assetStatus === ACCOUNT_ASSET_PENDING_STATUS_ID;
    }
    return canDeleteAsset;
};

/* export const isAccountAssetEditable = (
    authUserId,
    authUserType,
    accountAsset
) => {
    // ADMIN: Can edit PENDING / APPROVED assets
    // FM USER: Can edit PENDING / APPROVED assets
    // CLIENT: Can edit APPROVED assets
    const assetStatus = accountAsset?.[ACCOUNT_ASSET_KEYS.STATUS] || "";
    const assetApproved = assetStatus === ACCOUNT_ASSET_APPROVED_STATUS_ID;
    const assetPending = assetStatus === ACCOUNT_ASSET_PENDING_STATUS_ID;

    const isAdminOrFmUser = [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID].includes(
        authUserType
    );
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;

    let canEditAsset = false;
    if (authUserId && authUserType) {
        if (assetPending) {
            if (isAdminOrFmUser) {
                canEditAsset = true;
            }
        } else if (assetApproved) {
            if (isAdminOrFmUser || isClientUser) {
                canEditAsset = true;
            }
        }
    }

    return canEditAsset;
}; */

export const isAccountAssetEditable = (
    authUserId,
    authUserType,
    accountAsset
) => {
    /**
     * ADMIN: Can edit PENDING assets
     * FM USER: Can edit PENDING assets
     * CLIENT: Cannot edit any assets (because client can only see approved assets)
     */
    const assetStatus = accountAsset?.[ACCOUNT_ASSET_KEYS.STATUS] || "";
    const assetPending = assetStatus === ACCOUNT_ASSET_PENDING_STATUS_ID;

    const isAdminOrFmUser = [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID].includes(
        authUserType
    );

    let canEditAsset = false;
    if (authUserId && authUserType) {
        if (assetPending) {
            if (isAdminOrFmUser) {
                canEditAsset = true;
            }
        }
    }

    return canEditAsset;
};

export const isAccountAssetFileEditable = (
    authUserId,
    authUserType,
    accountAsset
) => {
    /**
     * ADMIN:
     *  - PENDING assets: Can edit file + Info
     * FM USER:
     *  - PENDING assets: Can edit file + Info
     * CLIENT:
     *  - PENDING assets: Cannot edit anything
     */
    const assetStatus = accountAsset?.[ACCOUNT_ASSET_KEYS.STATUS] || "";
    const assetPending = assetStatus === ACCOUNT_ASSET_PENDING_STATUS_ID;

    const isAdminOrFmUser = [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID].includes(
        authUserType
    );

    let canEditAssetFile = false;
    if (authUserId && authUserType) {
        if (assetPending) {
            if (isAdminOrFmUser) {
                canEditAssetFile = true;
            }
        }
    }

    return canEditAssetFile;
};

/* Reference: https://github.com/kennethjiang/js-file-download */
export const downloadAccountAsset = (data, filename, mime, bom) => {
    var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
};
