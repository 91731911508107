import basicAuthAxios from "../../services/auth/basicAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import { SET_CLIENT_FM_USER_DETAILS } from "../../utils/constants/actionTypes";
import {
    /* GET_ALL_CLIENT_FM_USERS_LINK, */
    GET_CLIENT_FM_USERS_LINK,
} from "../../utils/constants/apiLinks";
import { FM_USER_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const getFilteredClientFmUsers = (request = {}, callbackFun) => {
    const authUserType = request?.authUserType || "";
    let apiLink = "";
    switch (authUserType) {
        default:
            apiLink = GET_CLIENT_FM_USERS_LINK;
            break;
    }
    /* console.log("getFilteredClientFmUsers: " + apiLink); */
    return (dispatch) => {
        const body = request.clientFmUserData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.users) {
                            const fmUsers = data.users;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [FM_USER_KEYS.TOTAL]: count[
                                        FM_USER_KEYS.TOTAL
                                    ]
                                        ? count[FM_USER_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [FM_USER_KEYS.TOTAL]: "0",
                                };
                            }
                            dispatch(fetchSuccess());
                            /* dispatch({
                                type: GET_CLIENT_FM_USERS,
                                payload: fmUsers,
                            });
                            dispatch({
                                type: GET_CLIENT_FM_USER_COUNT,
                                payload: totals,
                            }); */
                            if (callbackFun)
                                callbackFun({
                                    fmUsers: fmUsers,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                /* dispatch({ type: GET_CLIENT_FM_USERS, payload: [] });
                dispatch({
                    type: GET_CLIENT_FM_USER_COUNT,
                    payload: {
                        [FM_USER_KEYS.TOTAL]: "0",
                    },
                }); */

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

/* export const getAllClientFmUsers = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            default:
                apiLink = GET_ALL_CLIENT_FM_USERS_LINK;
                break;
        }
        // console.log("getAllClientFmUsers: " + apiLink);
        const body = request.clientFmUserData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.users) {
                            const clientFmUsers = data.users;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(clientFmUsers);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
}; */

export const setCurrentClienFmtUser = (clientFmUser) => {
    return (dispatch) => {
        dispatch({ type: SET_CLIENT_FM_USER_DETAILS, payload: clientFmUser });
    };
};
