import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TableActionIcon from "../TableActionIcon";
import { formatBytes } from "../../../utils/appHelpers";

const DnDAcceptedFiles = ({ files, onRemove }) => {
    if (files && files.length > 0) {
        return (
            <List
                disablePadding
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {files.map((file, fileIdx) => {
                    const filePath = file.path;

                    return (
                        <ListItem
                            key={`${fileIdx}${filePath}`}
                            secondaryAction={
                                <TableActionIcon
                                    edge="end"
                                    iconComponent="delete"
                                    label="remove file"
                                    tooltip="Remove File"
                                    onClick={() => onRemove(fileIdx)}
                                />
                            }
                            sx={{
                                width: "auto",
                                mr: 1,
                                flex: "1 1 100%",
                            }}
                        >
                            {`${filePath} - ${formatBytes(file.size)}`}
                        </ListItem>
                    );
                })}
            </List>
        );
    } else {
        return null;
    }
};

DnDAcceptedFiles.propTypes = {
    files: PropTypes.array,
    onRemove: PropTypes.func,
};

DnDAcceptedFiles.defaultProps = {
    files: [],
};

export default DnDAcceptedFiles;
