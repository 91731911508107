import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    ACTIVE_FM_USER,
    APP_NAME,
    AUTH_USER_KEYS,
    FM_USER_KEYS,
    INACTIVE_FM_USER,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import {
    deleteFmUser,
    getFilteredFmUsers,
    setCurrentFmUser,
    updateFmUserStatus,
} from "../../redux/actions/fmUsers";
import { getAllCompanies } from "../../redux/actions/companies";
import AddEditUserDialog from "./AddEditUserDialog";
import FMUsersTable from "./FMUsersTable";
/* import ViewAssignedClientsDialog from "./ViewAssignedClientsDialog"; */
import ViewAssginedCompaniesDialog from "./ViewAssginedCompaniesDialog";

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, deleted: true };
        }
    }

    return { inserted: false, deleted: false };
};

const genericPageName = "Users List";

const FMUsers = () => {
    const _isFMUsersMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { currentFmUser, fmUsers, fmUserCounts } = useSelector(
        ({ fmUsersReducer }) => fmUsersReducer
    );

    const [pageName, setPageName] = useState(genericPageName);
    const [allCompanies, setAllCompanies] = useState([]);
    const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
    const [fetchingOnLoad, setFetchingOnLoad] = useState(true);
    const [fmUsersFetched, setFmUsersFetched] = useState(true);
    const [fetchCriteria, setFetchCriteria] = useState(null);
    const [fmUsersFilterChanged, setFmUsersFilterChanged] = useState(true);
    const [openAddEditUserDialog, setOpenAddEditUserDialog] = useState(false);
    /* const [openViewAssignedClientsDialog, setOpenViewAssignedClientsDialog] =
        useState(false); */
    const [
        openViewAssginedCompaniesDialog,
        setOpenViewAssginedCompaniesDialog,
    ] = useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
    });

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const authUserGlobalCompany =
        authUser &&
        authUser?.[AUTH_USER_KEYS.GLOBAL_COMPANY] &&
        authUser[AUTH_USER_KEYS.GLOBAL_COMPANY]
            ? authUser[AUTH_USER_KEYS.GLOBAL_COMPANY]
            : "";

    useEffect(() => {
        return () => {
            _isFMUsersMounted.current = false;
            dispatch(setCurrentFmUser(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (authUserGlobalCompany) {
            setPageName(`${authUserGlobalCompany} ${genericPageName}`);
        }
    }, [authUserGlobalCompany]);

    useEffect(() => {
        if (pageName) {
            document.title = `${APP_NAME} - ${pageName}`;
        } else {
            document.title = `${APP_NAME} - ${genericPageName}`;
        }
    }, [pageName]);

    useEffect(() => {
        setFetchCriteria({
            page: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
        });

        if (!fmUsersFilterChanged) {
            setFmUsersFetched(false);
        } else {
            setFmUsersFilterChanged(false);
        }
    }, [fmUsersFilterChanged, pagination]);

    useEffect(() => {
        let isActive = true;

        if (authUserType) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllCompanies(payload, (fetchedCompanies) => {
                            if (isActive) {
                                setAllCompanies(
                                    fetchedCompanies?.companies || []
                                );
                            }
                        })
                    );
                };
            };

            const companyData = { authcode: authToken };
            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setAllCompanies([]);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserType]);

    useEffect(() => {
        let isActive = true;

        if (!fmUsersFetched && fetchCriteria) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredFmUsers(payload, () => {
                            if (isActive) {
                                setFmUsersFetched(true);
                                setFetchingOnLoad(false);
                                dispatchAction();
                            }
                        })
                    );
                };
            };

            const fmUserData = {
                authcode: authToken,
                page: fetchCriteria.page + 1,
                rows_per_page: fetchCriteria.rowsPerPage,
            };

            const promise = dispatch(
                fetchData({ fmUserData: fmUserData, fromAction: actionState })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setFmUsersFetched(true);
                    setFetchingOnLoad(false);
                    dispatchAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, actionState, fmUsersFetched, fetchCriteria]);

    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            /**
             * Fetch the list again if a user is added
             * or deleted
             */
            setFmUsersFetched(false);
        }
    }, [actionState]);

    const handleOpenAddUserDialog = () => {
        setOpenAddEditUserDialog(true);
    };

    const handleCloseAddEditUserDialog = () => {
        setOpenAddEditUserDialog(false);
        dispatch(setCurrentFmUser(null));
    };

    const handleFmUserInsert = () => dispatchAction({ type: "INSERT" });

    const handlePageChange = (event, newPage) => {
        setPagination((prevState) => {
            return { ...prevState, page: newPage };
        });
    };

    const handleRowsPerPageChange = (event) => {
        setPagination({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleFmUserEdit = (fmUser) => {
        dispatch(setCurrentFmUser(fmUser));
        setOpenAddEditUserDialog(true);
    };

    const handleFmUserDelete = React.useCallback(
        (fmUser) => {
            if (fmUser && fmUser[FM_USER_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Delete user: ${fmUser[FM_USER_KEYS.NAME]}?`,
                    onYes: () => {
                        hideDialog();
                        const fmUserData = {
                            authcode: authToken,
                            [FM_USER_KEYS.USER_ID]: fmUser[FM_USER_KEYS.ID],
                        };
                        dispatch(
                            deleteFmUser(
                                { fmUserData: fmUserData },
                                () => {
                                    if (_isFMUsersMounted.current)
                                        dispatchAction({ type: "DELETE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid user",
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken]
    );

    const handleFmUserStatusUpdate = (fmUser) => {
        if (
            !fmUser ||
            !fmUser[FM_USER_KEYS.ID] ||
            !fmUser[FM_USER_KEYS.STATUS] ||
            ![ACTIVE_FM_USER, INACTIVE_FM_USER].includes(
                fmUser[FM_USER_KEYS.STATUS]
            )
        ) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid user",
            });
            return false;
        }

        const updatedStatus =
            fmUser[FM_USER_KEYS.STATUS] === INACTIVE_FM_USER
                ? ACTIVE_FM_USER
                : INACTIVE_FM_USER;

        const fmUserData = {
            authcode: authToken,
            [FM_USER_KEYS.USER_ID]: fmUser[FM_USER_KEYS.ID],
            [FM_USER_KEYS.STATUS]: updatedStatus,
        };

        dispatch(
            updateFmUserStatus({ fmUserData: fmUserData }, null, (messages) => {
                const msgArray = messages ? Object.values(messages) : [];
                if (_isFMUsersMounted.current && msgArray.length > 0) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        html: msgArray.join("<br>"),
                    });
                }
            })
        );
    };

    /* const handleViewClientsHandler = (fmUser) => {
        dispatch(setCurrentFmUser(fmUser));
        setOpenViewAssignedClientsDialog(true);
    }; */

    /* const handleCloseViewAssignedClientsDialog = () => {
        setOpenViewAssignedClientsDialog(false);
        dispatch(setCurrentFmUser(null));
    }; */

    const handleViewAssignedCompanies = (fmUser) => {
        dispatch(setCurrentFmUser(fmUser));
        setOpenViewAssginedCompaniesDialog(true);
    };

    const handleCloseViewAssginedCompaniesDialog = () => {
        setOpenViewAssginedCompaniesDialog(false);
        dispatch(setCurrentFmUser(null));
    };

    return (
        <React.Fragment>
            {!fetchingOnLoad && (
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <HeadingStack>
                            <PageTitle
                                component="h1"
                                title={pageName}
                                type="v2"
                            />
                            <Breadcrumb
                                pageNames={{
                                    genericName: genericPageName,
                                    specificName: pageName,
                                }}
                            />
                        </HeadingStack>
                    </Grid>
                    <Grid item xs={12}>
                        <HeadingStack sx={{ justifyContent: "end" }}>
                            <FmButton
                                startIcon="add"
                                type="button"
                                label="Add User"
                                onClick={handleOpenAddUserDialog}
                            />
                        </HeadingStack>
                    </Grid>
                    <Grid item xs={12}>
                        <FMUsersTable
                            fmUsers={fmUsers}
                            fmUserCounts={fmUserCounts}
                            fmUsersFetched={fmUsersFetched}
                            page={pagination.page}
                            rowsPerPage={pagination.rowsPerPage}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            onEdit={handleFmUserEdit}
                            onDelete={handleFmUserDelete}
                            onUpdateStatus={handleFmUserStatusUpdate}
                            onViewAssignedCompanies={
                                handleViewAssignedCompanies
                            }
                        />
                    </Grid>
                </Grid>
            )}
            {!fetchingOnLoad && openAddEditUserDialog && (
                <AddEditUserDialog
                    allCompanies={allCompanies}
                    currentFmUser={currentFmUser}
                    open={openAddEditUserDialog}
                    setGlobalState={true}
                    onClose={handleCloseAddEditUserDialog}
                    onInsert={handleFmUserInsert}
                />
            )}
            {/* !fetchingOnLoad && openViewAssignedClientsDialog && (
                <ViewAssignedClientsDialog
                    allClientUsers={allClientUsers}
                    open={openViewAssignedClientsDialog}
                    onClose={handleCloseViewAssignedClientsDialog}
                />
            ) */}
            {!fetchingOnLoad && openViewAssginedCompaniesDialog && (
                <ViewAssginedCompaniesDialog
                    allCompanies={allCompanies}
                    currentFmUser={currentFmUser}
                    open={openViewAssginedCompaniesDialog}
                    onClose={handleCloseViewAssginedCompaniesDialog}
                />
            )}
            {!authUserType && (
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default FMUsers;
