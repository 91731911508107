import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getCompany } from "../../redux/actions/companies";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    API_COMMON_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";

const pageName = "Lead Sheets";

const LeadSheets = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isLeadSheetsMounted = useRef(true);
    const buttonRef = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [fetchingOnLoad, setFetchingOnLoad] = useState(true);
    const [company, setCompany] = useState(null);
    const [companyIsValid, setCompanyIsValid] = useState(false);
    const [companyFetched, setCompanyFetched] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [sheetLink, setSheetLink] = useState("");

    const authUserCompanyId =
        authUser &&
        authUser?.[AUTH_USER_KEYS.COMPANY_ID] &&
        authUser[AUTH_USER_KEYS.COMPANY_ID]
            ? authUser[AUTH_USER_KEYS.COMPANY_ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    useEffect(() => {
        return () => {
            _isLeadSheetsMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isValidUserType) {
            setFetchingOnLoad(true);
        }
    }, [isValidUserType]);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (fetchingOnLoad) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompanyFetched(true);
                                setCompany(fetchedCompany || null);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: authUserCompanyId,
            };

            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setErrorMsg(error?.message || API_COMMON_ERROR);
                    setCompanyFetched(false);
                    setFetchingOnLoad(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserCompanyId, fetchingOnLoad]);

    /* Check if fetched company is valid */
    useEffect(() => {
        if (companyFetched) {
            if (
                company &&
                company?.[COMPANY_KEYS.ID] &&
                company[COMPANY_KEYS.ID]
            ) {
                setCompanyIsValid(true);
                setSheetLink(
                    company?.[COMPANY_KEYS.SHEET_LINK]
                        ? company[COMPANY_KEYS.SHEET_LINK]
                        : ""
                );
            } else {
                setFetchingOnLoad(false);
            }
        }
    }, [company, companyFetched]);

    useEffect(() => {
        if (sheetLink && buttonRef.current) {
            setTimeout(() => {
                buttonRef.current.click();
            }, 500);
        }
    }, [sheetLink]);

    const loadError =
        !isValidUserType || errorMsg || !companyIsValid || !sheetLink;

    if (fetchingOnLoad) {
        return <FmCircularProgress showBackDrop />;
    }

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle component="h2" title={pageName} type="v2" />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert
                                variant="outlined"
                                severity="error"
                                sx={{ mt: 4 }}
                            >
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && errorMsg && (
                        <Grid item xs={12}>
                            <Alert
                                variant="outlined"
                                severity="error"
                                sx={{ mt: 4 }}
                            >
                                {errorMsg}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !errorMsg && !companyIsValid && (
                        <Grid item xs={12}>
                            <Alert
                                variant="outlined"
                                severity="error"
                                sx={{ mt: 4 }}
                            >
                                Invalid company!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType &&
                        !errorMsg &&
                        companyIsValid &&
                        !sheetLink && (
                            <Grid item xs={12}>
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    sx={{ mt: 4 }}
                                >
                                    Link not found!
                                </Alert>
                            </Grid>
                        )}
                </React.Fragment>
            )}
            {!loadError && (
                <Grid item xs={12}>
                    <Card sx={{ px: 2.5, py: 5, borderRadius: "5px" }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                    <Button
                                        ref={buttonRef}
                                        component={Link}
                                        href={sheetLink}
                                        target="_blank"
                                        type="button"
                                        variant="contained"
                                        endIcon={<OpenInNewIcon />}
                                    >
                                        Click to view sheet
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
};

export default LeadSheets;
