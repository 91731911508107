import basicAuthAxios from "./auth/basicAuth";

const authServices = {};

authServices.getCurrentUser = async (token) => {
    const userData = { authcode: token };
    try {
        const response = await basicAuthAxios.post("/login/detail", userData);
        const responseData = response.data;
        if (responseData.status && responseData.status === "true") {
            return responseData.data.user;
        } else {
            throw Error(
                responseData.msg ||
                    "There was a problem in fetching the user data"
            );
        }
    } catch (e) {
        let errorMsg =
            e.message || "There was a problem in fetching the user data";

        if (e.response && e.response.data) {
            const errResponseData = e.response.data;
            if (errResponseData.msg) {
                errorMsg = errResponseData.msg;
            } else if (errResponseData.messages) {
                const errorMessages = errResponseData.messages;
                if (errorMessages && errorMessages.msg) {
                    errorMsg = errorMessages.msg;
                }
            }
        }

        throw Error(errorMsg);
    }
};

/* loginCreds must be {email: "abc@example.com", password: "ABC123DEF"} */
authServices.signIn = async (loginCreds) => {
    try {
        const response = await basicAuthAxios.post("/login", loginCreds);
        const responseData = response.data;
        if (responseData.status && responseData.status === "true") {
            return responseData.data;
        } else {
            return {
                hasError: true,
                error: responseData.msg || "There was a problem in sign in",
            };
        }
    } catch (e) {
        let errorMsg = e.message || "There was a problem in sign in";

        if (e.response && e.response.data) {
            const errResponseData = e.response.data;
            if (errResponseData.msg) {
                errorMsg = errResponseData.msg;
            } else if (errResponseData.messages) {
                const errorMessages = errResponseData.messages;
                if (errorMessages && errorMessages.msg) {
                    errorMsg = errorMessages.msg;
                }
            }
        }

        return {
            hasError: true,
            error: errorMsg,
        };
    }
};

/* creds must be {email: "abc@example.com"} */
authServices.forgotPassword = async (creds) => {
    try {
        const response = await basicAuthAxios.post("/forgotpassword", creds);
        const responseData = response.data;
        if (responseData.status && responseData.status === "true") {
            return {
                hasError: false,
                success: responseData.msg || "",
            };
        } else {
            return {
                hasError: true,
                error:
                    responseData.msg ||
                    "There was a problem in sending request",
            };
        }
    } catch (e) {
        let errorMsg = e.message || "There was a problem in sending request";

        if (e.response && e.response.data) {
            const errResponseData = e.response.data;
            if (errResponseData.msg) {
                errorMsg = errResponseData.msg;
            } else if (errResponseData.messages) {
                const errorMessages = errResponseData.messages;
                if (errorMessages && errorMessages.msg) {
                    errorMsg = errorMessages.msg;
                }
            }
        }

        return {
            hasError: true,
            error: errorMsg,
        };
    }
};

/* data must be {password: "ABC123DEF", confirmPassword: "ABC123DEF"} */
authServices.resetPassword = async (data) => {
    try {
        const response = await basicAuthAxios.post("/resetforgotpassword", data);
        const responseData = response.data;
        if (responseData.status && responseData.status === "true") {
            return {
                hasError: false,
                success: responseData.msg || "",
            };
        } else {
            return {
                hasError: true,
                error:
                    responseData.msg ||
                    "There was a problem in sending request",
            };
        }
    } catch (e) {
        let errorMsg = e.message || "There was a problem in sending request";

        if (e.response && e.response.data) {
            const errResponseData = e.response.data;
            if (errResponseData.msg) {
                errorMsg = errResponseData.msg;
            } else if (errResponseData.messages) {
                const errorMessages = errResponseData.messages;
                if (errorMessages && errorMessages.msg) {
                    errorMsg = errorMessages.msg;
                }
            }
        }

        return {
            hasError: true,
            error: errorMsg,
        };
    }
};

export default authServices;
