import React from "react";
import AddSurvey from "../pages/surveys/AddSurvey";
import EditSurvey from "../pages/surveys/EditSurvey";
import Survey from "../pages/survey/Survey";
import SurveyResult from "../pages/surveys/SurveyResult";
import TextQuestionResponses from "../pages/surveys/TextQuestionResponses";
import Page from "@jumbo/shared/Page";

const surveyRoutes = [
    {
        path: "/survey/add",
        element: <Page component={AddSurvey} />,
    },
    {
        path: "/survey/:surveyId/",
        element: <Page component={Survey} />,
    },
    {
        path: "/survey/:surveyId/edit",
        element: <Page component={EditSurvey} />,
    },
    {
        path: "/survey/result/:surveyId/",
        element: <Page component={SurveyResult} />,
    },
    {
        path: "/survey/responses/:surveyId/:questionId",
        element: <Page component={TextQuestionResponses} />,
    },
];

export default surveyRoutes;
