import basicAuthAxios from "../../services/auth/basicAuth";
import basicFileAuthAxios from "../../services/auth/basicFileAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    /* ADD_REPORT, */
    DELETE_REPORT,
    EDIT_REPORT,
    GET_REPORTS,
    GET_REPORT_COUNT,
    SET_REPORT_DETAILS,
} from "../../utils/constants/actionTypes";
import {
    ADD_REPORT_LINK,
    DELETE_REPORT_LINK,
    GET_REPORT_LINK,
    GET_REPORTS_LINK,
    UPDATE_REPORT_LINK,
} from "../../utils/constants/apiLinks";
import { REPORT_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addReport = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.reportData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(ADD_REPORT_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.reports) {
                            /* dispatch(
                                fetchSuccess("Report was added successfully.")
                            ); */
                            /* dispatch({
                                type: ADD_REPORT,
                                payload: data.report,
                            }); */
                            if (callbackFun) callbackFun(data.reports);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredReports = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.reportData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_REPORTS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.reports) {
                            const reports = data.reports;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [REPORT_KEYS.TOTAL]: count[
                                        REPORT_KEYS.TOTAL
                                    ]
                                        ? count[REPORT_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [REPORT_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request?.fromAction && request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction?.inserted) {
                                    successMsg =
                                        "Report was uploaded successfully";
                                } else if (fromAction?.deleted) {
                                    successMsg =
                                        "Selected report was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: GET_REPORTS,
                                payload: reports,
                            });
                            dispatch({
                                type: GET_REPORT_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(reports);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_REPORTS, payload: [] });
                dispatch({
                    type: GET_REPORT_COUNT,
                    payload: {
                        [REPORT_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getReportLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.reportData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_REPORT_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.reports) {
                            const report = data.reports;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(report);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const setCurrentReport = (report) => {
    return (dispatch) => {
        dispatch({ type: SET_REPORT_DETAILS, payload: report });
    };
};

export const updateReport = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.reportData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(UPDATE_REPORT_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.reports) {
                            dispatch(
                                fetchSuccess(
                                    "Selected report was updated successfully."
                                )
                            );
                            dispatch({
                                type: EDIT_REPORT,
                                payload: data.reports,
                            });
                            if (callbackFun) callbackFun(data.reports);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteReport = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.reportData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_REPORT_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected report was deleted successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_REPORT,
                            payload: request.reportData[REPORT_KEYS.REPORT_ID],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};
