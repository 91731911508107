import {
    GET_ASSET_TYPE_COUNT,
    GET_ASSET_TYPES,
} from "../../utils/constants/actionTypes";
import { ASSET_TYPE_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    assetTypes: [],
    /* currentAssetType: null, */
    assetTypeCounts: { [ASSET_TYPE_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ASSET_TYPES: {
            return {
                ...state,
                assetTypes: action.payload,
            };
        }
        case GET_ASSET_TYPE_COUNT: {
            return {
                ...state,
                assetTypeCounts: action.payload,
            };
        }
        /* case SET_ASSET_TYPE_DETAILS: {
            return {
                ...state,
                currentAssetType: action.payload,
            };
        } */
        /* case ADD_ASSET_TYPE: {
            return {
                ...state,
                assetTypes: [action.payload, ...state.assetTypes],
            };
        } */
        /* case EDIT_ASSET_TYPE: {
            return {
                ...state,
                assetTypes: state.assetTypes.map((assetType) =>
                    assetType.id === action.payload.id
                        ? action.payload
                        : assetType
                ),
            };
        } */
        /* case DELETE_ASSET_TYPE: {
            return {
                ...state,
                assetTypes: state.assetTypes.filter(
                    (assetType) => assetType.id !== action.payload
                ),
            };
        } */
        default:
            return state;
    }
};

export default reducerFunc;
