import React from "react";
import SurveyResponses from "../pages/surveys/SurveyResponses";
import TakeSurvey from "../pages/takeSurvey/TakeSurvey";
import Page from "@jumbo/shared/Page";

const clientSurveyRoutes = [
    {
        path: "/survey/response/:surveyId/",
        element: <Page component={SurveyResponses} />,
    },
    {
        path: "/survey/take/:surveyId/",
        element: <Page component={TakeSurvey} />,
    },
];

export default clientSurveyRoutes;
