import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FmSearch from "../../shared/FmSearch";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    deleteAccountAsset,
    downloadAssetBlob,
    getFilteredAccountAssets,
} from "../../redux/actions/accountAssets";
import { getAllAssetTypesLocalState } from "../../redux/actions/assetTypes";
import { downloadAccountAsset } from "../../utils/accountAssetHelpers";
import {
    ACCOUNT_ASSET_KEYS,
    ACCOUNT_ASSET_TYPE_LOGO,
    APP_NAME,
    ASSET_TYPE_KEYS,
    AUTH_USER_KEYS,
    EMPTY_PARAM_VALUE,
    FOLDER_KEYS,
    REFRESH_ACTION_DELETE,
    REFRESH_ACTION_INSERT,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import { useDebounce } from "../../utils/commonHelper";
import AddEditFileDialog from "./AddEditFileDialog";
import AssetTypeTabPanel, { a11yProps } from "./AssetTypeTabPanel";
const AccountAssetsVirtualList = lazy(() =>
    import("./AccountAssetsVirtualList")
);

const genericPageName = "Folder Assets";

const ACCOUNT_ASSETS_LIMIT = 16;

const INIT_ASSETS_STATE = {
    assets: [],
    assetsFetched: false,
    assetType: ACCOUNT_ASSET_TYPE_LOGO,
    assetTypeValid: false,
    assetTypes: [],
    assetTypesFetched: false,
    assetTypeNames: [],
    companyId: "",
    currentAsset: null,
    fetchAssets: false,
    filtered: false,
    firstLoad: true,
    folder: null,
    folderId: "",
    folderIsValid: false,
    inputIsValid: false,
    loadMore: false,
    page: 0,
    refresh: false,
    refreshAction: "",
    rowsPerPage: ACCOUNT_ASSETS_LIMIT,
    search: "",
    searching: false,
    totals: 0,
};

const assetsReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                assetsFetched: false,
                fetchAssets: true,
            };
        } else if (action.type === "REFRESH") {
            return {
                ...state,
                assetsFetched: false,
                currentAsset: null,
                fetchAssets: true,
                refresh: true,
                refreshAction: action?.refreshAction || "",
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                assets: [],
                assetsFetched: false,
                fetchAssets: false,
                filtered: false,
                firstLoad: false,
                folder: null,
                folderIsValid: false,
                loadMore: false,
                page: 0,
                refresh: false,
                refreshAction: "",
                searching: false,
                totals: 0,
            };
        } else if (action.type === "SEARCH_AND_FETCH") {
            if (state.firstLoad) {
                return { ...state };
            } else {
                return {
                    ...state,
                    assetsFetched: false,
                    fetchAssets: true,
                    page: 0,
                    search: action.search,
                    searching: true,
                };
            }
        } else if (action.type === "SET_ASSET_TYPE") {
            const currentCompanyId = state.companyId;
            const currentFolderId = state.folderId;

            const currentAssetTypeNames = state.assetTypeNames;
            const updatedAssetType = action.assetType;
            const updatedAssetTypeValid =
                !!updatedAssetType &&
                currentAssetTypeNames.includes(updatedAssetType);

            return {
                ...state,
                assetType: updatedAssetType,
                assetTypeValid: updatedAssetTypeValid,
                inputIsValid:
                    !!currentCompanyId &&
                    !!currentFolderId &&
                    updatedAssetTypeValid,
            };
        } else if (action.type === "SET_ASSET_TYPE_AND_FETCH") {
            const currentCompanyId = state.companyId;
            const currentFolderId = state.folderId;

            const currentAssetTypeNames = state.assetTypeNames;
            const updatedAssetType = action.assetType;
            const updatedAssetTypeValid =
                !!updatedAssetType &&
                currentAssetTypeNames.includes(updatedAssetType);

            const updatedInputIsValid =
                !!currentCompanyId &&
                !!currentFolderId &&
                updatedAssetTypeValid;

            if (updatedInputIsValid) {
                return {
                    ...state,
                    assetsFetched: false,
                    assetType: updatedAssetType,
                    assetTypeValid: updatedAssetTypeValid,
                    fetchAssets: true,
                    filtered: true,
                    inputIsValid: true,
                    page: 0,
                };
            } else {
                return {
                    ...state,
                    assets: [],
                    assetType: updatedAssetType,
                    assetTypeValid: updatedAssetTypeValid,
                    inputIsValid: false,
                    folder: null,
                    folderIsValid: false,
                    totals: 0,
                };
            }
        } else if (action.type === "SET_ASSET_TYPES") {
            const fetchedAssetTypes = action.assetTypes;
            if (fetchedAssetTypes && fetchedAssetTypes.length > 0) {
                const fetchedAssetTypeNames = fetchedAssetTypes.map(
                    (fetchedAssetTypeObj) =>
                        fetchedAssetTypeObj[ASSET_TYPE_KEYS.NAME]
                );
                return {
                    ...state,
                    assetTypes: fetchedAssetTypes,
                    assetTypesFetched: true,
                    assetTypeNames: fetchedAssetTypeNames,
                };
            } else {
                return {
                    ...state,
                    assetTypes: [],
                    assetTypesFetched: false,
                    assetTypeNames: [],
                };
            }
        } else if (action.type === "SET_COMPANY_ID") {
            const currentAssetType = state.assetType;
            const currentFolderId = state.folderId;
            const updatedCompanyId = action.companyId;
            return {
                ...state,
                companyId: updatedCompanyId,
                inputIsValid:
                    !!currentAssetType &&
                    !!currentFolderId &&
                    !!updatedCompanyId,
            };
        } else if (action.type === "SET_CURRENT_ASSET") {
            return {
                ...state,
                currentAsset: action.asset,
            };
        } else if (action.type === "SET_DATA") {
            const fetchedFolder = action.folder;
            if (
                fetchedFolder &&
                fetchedFolder?.[FOLDER_KEYS.ID] &&
                fetchedFolder[FOLDER_KEYS.ID]
            ) {
                const updatedFolder = {
                    [FOLDER_KEYS.ID]: fetchedFolder[FOLDER_KEYS.ID],
                    [FOLDER_KEYS.COMPANY_ID]:
                        fetchedFolder[FOLDER_KEYS.COMPANY_ID],
                    [FOLDER_KEYS.NAME]: fetchedFolder[FOLDER_KEYS.NAME],
                    [FOLDER_KEYS.INDUSTRY]: fetchedFolder[FOLDER_KEYS.INDUSTRY],
                    [FOLDER_KEYS.CREATED_AT]:
                        fetchedFolder[FOLDER_KEYS.CREATED_AT],
                    [FOLDER_KEYS.UPDATED_AT]:
                        fetchedFolder[FOLDER_KEYS.UPDATED_AT],
                };

                const isFiltered = state.filtered;
                const isRefreshed = state.refresh;
                const isSearching = state.searching;
                const currentAssets = state.assets || [];

                const updatedAssets =
                    isFiltered || isRefreshed || isSearching
                        ? []
                        : [...currentAssets];
                let numUpdatedAssets = updatedAssets.length;

                let newPage =
                    numUpdatedAssets > 0
                        ? Math.ceil(numUpdatedAssets / ACCOUNT_ASSETS_LIMIT)
                        : 0;
                newPage = newPage > 0 ? newPage - 1 : newPage;

                const currentRowsPerPage = state.rowsPerPage;

                const numTotalAssets = action.totals;
                const fetchedAssets = action.assets;
                const numFetchedAssets = fetchedAssets.length;
                if (numFetchedAssets > 0) {
                    let numAddedAssets = 0;

                    for (let i = 0; i < numFetchedAssets; i++) {
                        const fetchedAsset = fetchedAssets[i];
                        if (
                            fetchedAsset &&
                            fetchedAsset?.[ACCOUNT_ASSET_KEYS.ID] &&
                            fetchedAsset[ACCOUNT_ASSET_KEYS.ID]
                        ) {
                            const foundAsset = updatedAssets.find(
                                (assetObj) =>
                                    assetObj[ACCOUNT_ASSET_KEYS.ID] ===
                                    fetchedAsset[ACCOUNT_ASSET_KEYS.ID]
                            );
                            if (!foundAsset) {
                                updatedAssets.push(fetchedAsset);
                                numAddedAssets += 1;
                            }
                        }
                    }

                    numUpdatedAssets = updatedAssets.length;

                    newPage =
                        numUpdatedAssets > 0
                            ? Math.ceil(numUpdatedAssets / ACCOUNT_ASSETS_LIMIT)
                            : 0;
                    newPage = newPage > 0 ? newPage - 1 : newPage;

                    if (numUpdatedAssets === numTotalAssets) {
                        return {
                            ...state,
                            assets: updatedAssets,
                            assetsFetched: true,
                            filtered: false,
                            folder: updatedFolder,
                            folderIsValid: true,
                            loadMore: false,
                            page: newPage,
                            refresh: false,
                            refreshAction: "",
                            searching: false,
                            totals: numTotalAssets,
                        };
                    } else {
                        const maxLimit = isRefreshed
                            ? (newPage + 1) * currentRowsPerPage
                            : currentRowsPerPage;
                        if (numAddedAssets === maxLimit) {
                            return {
                                ...state,
                                assets: updatedAssets,
                                assetsFetched: true,
                                filtered: false,
                                folder: updatedFolder,
                                folderIsValid: true,
                                loadMore: true,
                                page: newPage + 1,
                                refresh: false,
                                refreshAction: "",
                                searching: false,
                                totals: numTotalAssets,
                            };
                        } else {
                            return {
                                ...state,
                                assets: updatedAssets,
                                assetsFetched: true,
                                filtered: false,
                                folder: updatedFolder,
                                folderIsValid: true,
                                loadMore: false,
                                page: newPage,
                                refresh: false,
                                refreshAction: "",
                                searching: false,
                                totals: numTotalAssets,
                            };
                        }
                    }
                } else {
                    return {
                        ...state,
                        assets: updatedAssets,
                        assetsFetched: true,
                        filtered: false,
                        folder: updatedFolder,
                        folderIsValid: true,
                        loadMore: false,
                        page: newPage,
                        refresh: false,
                        refreshAction: "",
                        searching: false,
                        totals: numTotalAssets,
                    };
                }
            } else {
                return {
                    ...state,
                    assets: [],
                    assetsFetched: true,
                    filtered: false,
                    folder: null,
                    folderIsValid: false,
                    loadMore: false,
                    page: 0,
                    refresh: false,
                    refreshAction: "",
                    searching: false,
                    totals: 0,
                };
            }
        } else if (action.type === "SET_FOLDER_ID") {
            const currentAssetType = state.assetType;
            const currentCompanyId = state.companyId;
            const updatedFolderId = action.folderId;
            return {
                ...state,
                folder: null,
                folderId: updatedFolderId,
                folderIsValid: false,
                inputIsValid:
                    !!currentAssetType &&
                    !!currentCompanyId &&
                    !!updatedFolderId,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchAssets: false,
                firstLoad: false,
            };
        } else if (action.type === "UPDATE_ASSET") {
            const updatedAsset = action.updatedAsset;
            if (updatedAsset && updatedAsset?.[ACCOUNT_ASSET_KEYS.ID]) {
                const currentAssets = state.assets;
                const updatedAssets = currentAssets.map((currentAsset) => {
                    if (
                        currentAsset[ACCOUNT_ASSET_KEYS.ID] ===
                        updatedAsset[ACCOUNT_ASSET_KEYS.ID]
                    ) {
                        return updatedAsset;
                    } else {
                        return currentAsset;
                    }
                });
                return {
                    ...state,
                    assets: updatedAssets,
                    currentAsset: null,
                };
            } else {
                return { ...state, currentAsset: null };
            }
        }
    }
    return INIT_ASSETS_STATE;
};

const FolderAssets = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isFolderAssetsMounted = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const assetTypeParam = params?.assetType || "";
    const companyIdParam = params?.companyId || "";
    const folderIdParam = params?.folderId || "";

    const [isLoading, setIsLoading] = useState(false);
    const [pageName, setPageName] = useState(genericPageName);
    const [alertData, setAlertData] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [fetchAssetTypes, setFetchAssetTypes] = useState(false);
    const [openAddFileDialog, setOpenAddFileDialog] = useState(false);

    const [assetsState, dispatchAssetsAction] = useReducer(
        assetsReducer,
        INIT_ASSETS_STATE
    );

    const {
        assetTypeNames,
        assetTypeValid,
        assetTypesFetched,
        firstLoad,
        folder,
        folderIsValid,
        inputIsValid,
        loadMore,
    } = assetsState;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const authUserId =
        authUser && authUser?.[AUTH_USER_KEYS.ID] && authUser[AUTH_USER_KEYS.ID]
            ? authUser[AUTH_USER_KEYS.ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    useEffect(() => {
        return () => {
            _isFolderAssetsMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (alertData) {
            Swal.fire({
                icon: alertData.icon,
                title: alertData.title,
                text: alertData.text,
            });
            setAlertData(null);
        }
    }, [Swal, alertData]);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch asset types */
    useEffect(() => {
        if (isLoading) {
            setFetchAssetTypes(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchAssetTypes) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllAssetTypesLocalState(
                            payload,
                            (fetchedAssetTypes) => {
                                if (isActive) {
                                    setFetchAssetTypes(false);
                                    dispatchAssetsAction({
                                        type: "SET_ASSET_TYPES",
                                        assetTypes: fetchedAssetTypes,
                                    });
                                }
                            }
                        )
                    );
                };
            };

            const promise = dispatch(
                fetchData({
                    assetTypeData: {
                        authcode: authToken,
                    },
                })
            );
            promise.catch((error) => {
                if (isActive) {
                    setFetchAssetTypes(false);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchAssetTypes]);

    /* Set query parameters if fetched asset types are valid */
    useEffect(() => {
        if (assetTypesFetched) {
            dispatchAssetsAction({
                type: "SET_ASSET_TYPE",
                assetType: assetTypeParam || ACCOUNT_ASSET_TYPE_LOGO,
            });
        }
    }, [assetTypeParam, assetTypesFetched]);

    useEffect(() => {
        if (assetTypeValid && firstLoad) {
            dispatchAssetsAction({
                type: "SET_COMPANY_ID",
                companyId: companyIdParam || EMPTY_PARAM_VALUE,
            });
        }
    }, [assetTypeValid, companyIdParam, firstLoad]);

    useEffect(() => {
        if (assetTypeValid && firstLoad) {
            dispatchAssetsAction({
                type: "SET_FOLDER_ID",
                folderId: folderIdParam || EMPTY_PARAM_VALUE,
            });
        }
    }, [assetTypeValid, firstLoad, folderIdParam]);

    /* Fetch assets */
    useEffect(() => {
        if (inputIsValid) {
            dispatchAssetsAction({ type: "FETCH" });
        }
    }, [inputIsValid]);

    useEffect(() => {
        let isActive = true;

        if (assetsState.fetchAssets) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredAccountAssets(payload, (fetchedFolder) => {
                            if (isActive) {
                                dispatchAssetsAction({
                                    type: "STOP_FETCH",
                                });

                                if (
                                    fetchedFolder &&
                                    fetchedFolder?.[FOLDER_KEYS.ID] &&
                                    fetchedFolder[FOLDER_KEYS.ID]
                                ) {
                                    dispatchAssetsAction({
                                        type: "SET_DATA",
                                        folder: fetchedFolder,
                                        assets:
                                            fetchedFolder?.[
                                                FOLDER_KEYS.ACCOUNT_ASSETS
                                            ] || [],
                                        totals:
                                            fetchedFolder?.account_assets_total ||
                                            null,
                                    });
                                } else {
                                    dispatchAssetsAction({
                                        type: "SET_DATA",
                                        folder: null,
                                        assets: [],
                                        totals: null,
                                    });
                                }

                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const accountAssetData = {
                authcode: authToken,
                order: SORT_ORDER_DESC,
                search: assetsState.search,
                sort: ACCOUNT_ASSET_KEYS.CREATED_AT,
            };

            if (assetsState.assetType !== EMPTY_PARAM_VALUE) {
                accountAssetData[ACCOUNT_ASSET_KEYS.DOCUMENT_TYPE] =
                    assetsState.assetType;
            }

            if (assetsState.companyId !== EMPTY_PARAM_VALUE) {
                accountAssetData[ACCOUNT_ASSET_KEYS.COMPANY_ID] =
                    assetsState.companyId;
            }

            if (assetsState.folderId !== EMPTY_PARAM_VALUE) {
                accountAssetData[ACCOUNT_ASSET_KEYS.FOLDER_ID] =
                    assetsState.folderId;
            }

            let currentPage = assetsState.page + 1;
            if (assetsState.refresh) {
                const numTotals = assetsState.assets.length;
                if (assetsState.refreshAction === REFRESH_ACTION_INSERT) {
                    currentPage = Math.ceil(
                        (numTotals + 1) / ACCOUNT_ASSETS_LIMIT
                    );
                } else if (
                    assetsState.refreshAction === REFRESH_ACTION_DELETE
                ) {
                    currentPage = Math.ceil(
                        (numTotals - 1) / ACCOUNT_ASSETS_LIMIT
                    );
                }
                accountAssetData.page = 1;
                accountAssetData.rows_per_page =
                    currentPage * assetsState.rowsPerPage;
            } else {
                accountAssetData.page = currentPage;
                accountAssetData.rows_per_page = assetsState.rowsPerPage;
            }

            const payload = { accountAssetData: accountAssetData };
            if (!assetsState.firstLoad && !assetsState.searching) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchAssetsAction({ type: "RESET" });
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, assetsState, authToken]);

    /* Set page name */
    useEffect(() => {
        if (folderIsValid) {
            setPageName(folder[FOLDER_KEYS.NAME] || genericPageName);
        }
    }, [folder, folderIsValid]);

    /* Search assets */
    useEffect(() => {
        dispatchAssetsAction({
            type: "SEARCH_AND_FETCH",
            search: debouncedSearchTerm,
        });
    }, [debouncedSearchTerm, dispatchAssetsAction]);

    const handleOpenAddFileDialog = () => {
        setOpenAddFileDialog(true);
    };

    const handleCloseAddFileDialog = () => {
        setOpenAddFileDialog(false);
    };

    const handleLoadMore = () => dispatchAssetsAction({ type: "FETCH" });

    const searchTermChangeHandler = (event) =>
        setSearchTerm(event.target.value);

    const assetTypeFilterChangeHandler = (event, newValue) => {
        dispatchAssetsAction({
            type: "SET_ASSET_TYPE_AND_FETCH",
            assetType: newValue,
        });
    };

    const handleAddFile = () => {
        dispatchAssetsAction({
            type: "REFRESH",
            refreshAction: REFRESH_ACTION_INSERT,
        });
    };

    const handleEditFile = (accountAsset) => {
        dispatchAssetsAction({
            type: "SET_CURRENT_ASSET",
            asset: accountAsset,
        });
        setOpenAddFileDialog(true);
    };

    const handleUpdateFile = (updatedAccountAsset) => {
        dispatchAssetsAction({
            type: "UPDATE_ASSET",
            updatedAsset: updatedAccountAsset,
        });
    };

    const handleDeleteFile = React.useCallback(
        (accountAsset) => {
            if (folderIsValid) {
                if (accountAsset && accountAsset[ACCOUNT_ASSET_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete account asset: ${
                            accountAsset[ACCOUNT_ASSET_KEYS.DOCUMENT_NAME]
                        }?`,
                        onYes: () => {
                            hideDialog();
                            const accountAssetData = {
                                authcode: authToken,
                                [ACCOUNT_ASSET_KEYS.ACCOUNT_ASSET_ID]:
                                    accountAsset[ACCOUNT_ASSET_KEYS.ID],
                                [ACCOUNT_ASSET_KEYS.COMPANY_ID]:
                                    folder[FOLDER_KEYS.COMPANY_ID],
                                [ACCOUNT_ASSET_KEYS.FOLDER_ID]:
                                    folder[FOLDER_KEYS.ID],
                            };
                            dispatch(
                                deleteAccountAsset(
                                    { accountAssetData: accountAssetData },
                                    (deletedAccountAssetId) => {
                                        if (_isFolderAssetsMounted.current) {
                                            dispatchAssetsAction({
                                                type: "REFRESH",
                                                refreshAction:
                                                    REFRESH_ACTION_DELETE,
                                            });
                                        }
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    setAlertData({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid account asset",
                    });
                }
            } else {
                setAlertData({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid folder",
                });
            }
        },
        [dispatch, hideDialog, showDialog, authToken, folder, folderIsValid]
    );

    const handleDownloadAsset = React.useCallback(
        (url, filename, fileMimeType) => {
            if (url && filename && fileMimeType) {
                dispatch(
                    downloadAssetBlob(
                        { url: url },
                        (fileData) => {
                            if (_isFolderAssetsMounted.current) {
                                downloadAccountAsset(
                                    fileData,
                                    filename,
                                    fileMimeType
                                );
                            }
                        },
                        () => {}
                    )
                );
            } else {
                setAlertData({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid file",
                });
            }
        },
        [dispatch]
    );

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const loadError = !isValidUserType || !folderIsValid || !inputIsValid;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: genericPageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !assetTypeValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Invalid asset type!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && assetTypeValid && !inputIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    One or more parameters are not valid!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && inputIsValid && !folderIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Folder data is not valid!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <HeadingStack sx={{ justifyContent: "end" }}>
                                <FmButton
                                    label="Upload Asset"
                                    size="medium"
                                    startIcon="add"
                                    onClick={handleOpenAddFileDialog}
                                />
                            </HeadingStack>
                        </Grid>
                        <Grid item xs={12}>
                            <FmSearch
                                ariaLabel="Search assets"
                                id="account_assets_search"
                                placeholder="Search Assets"
                                searchTerm={searchTerm}
                                filterSearch={assetsState.searching}
                                onSearchChange={searchTermChangeHandler}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                }}
                            >
                                <Tabs
                                    variant="fullWidth"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    aria-label="asset type tabs"
                                    value={assetsState.assetType}
                                    onChange={assetTypeFilterChangeHandler}
                                >
                                    {assetTypeNames.map((assetTypeName) => {
                                        return (
                                            <Tab
                                                key={`asset-type-tab-${assetTypeName}`}
                                                label={assetTypeName}
                                                value={assetTypeName}
                                                sx={{
                                                    textTransform: "capitalize",
                                                }}
                                                {...a11yProps(assetTypeName)}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </Box>
                            {assetTypeNames.map(
                                (assetTypeName, assetTypeIdx) => {
                                    return (
                                        <AssetTypeTabPanel
                                            key={`asset-type-tabpanel-${assetTypeName}`}
                                            value={assetsState.assetType}
                                            index={assetTypeIdx}
                                            name={assetTypeName}
                                        >
                                            <Suspense fallback={null}>
                                                <AccountAssetsVirtualList
                                                    accountAssets={
                                                        assetsState.assets
                                                    }
                                                    authUserId={authUserId}
                                                    authUserType={authUserType}
                                                    loading={
                                                        assetsState.fetchAssets
                                                    }
                                                    loadMore={loadMore}
                                                    onDelete={handleDeleteFile}
                                                    onDownload={
                                                        handleDownloadAsset
                                                    }
                                                    onEdit={handleEditFile}
                                                    onLoadMore={handleLoadMore}
                                                />
                                            </Suspense>
                                        </AssetTypeTabPanel>
                                    );
                                }
                            )}
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && (
                <React.Fragment>
                    {openAddFileDialog && (
                        <AddEditFileDialog
                            currentAccountAsset={assetsState.currentAsset}
                            folder={folder}
                            open={openAddFileDialog}
                            onClose={handleCloseAddFileDialog}
                            onAddFile={handleAddFile}
                            onUpdateFile={handleUpdateFile}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default FolderAssets;
