import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
    SURVEY_ANSWER_KEYS,
    SURVEY_QUESTION_OPTION_KEYS,
} from "../../utils/constants/appData";

const TakeSurveyRadio = ({
    answerObj,
    errorMsg,
    options,
    questionId,
    onSelect,
}) => {
    if (!answerObj || !questionId || !options) return null;

    const answers = answerObj[SURVEY_ANSWER_KEYS.ANSWER];
    const answer = answers.length === 1 ? answers[0] : "";

    const handleChange = (event) => onSelect(questionId, event.target.value);

    return (
        <FormControl>
            <RadioGroup
                name={`question-${questionId}-options`}
                value={answer}
                onChange={handleChange}
            >
                {options.map((option) => {
                    const optionId = option[SURVEY_QUESTION_OPTION_KEYS.ID];

                    return (
                        <FormControlLabel
                            key={`question-${questionId}-option-${optionId}`}
                            control={
                                <Radio
                                    sx={(theme) => ({
                                        mr: theme.spacing(4),
                                        color: theme.palette.primary.main,
                                    })}
                                />
                            }
                            value={optionId}
                            label={option[SURVEY_QUESTION_OPTION_KEYS.TITLE]}
                            sx={(theme) => ({ p: theme.spacing(0.5, 2) })}
                        />
                    );
                })}
            </RadioGroup>
            {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
        </FormControl>
    );
};

TakeSurveyRadio.propTypes = {
    answerObj: PropTypes.object,
    errorMsg: PropTypes.string,
    options: PropTypes.array,
    questionId: PropTypes.string,
    onSelect: PropTypes.func,
};

TakeSurveyRadio.defaultProps = {
    answerObj: null,
    errorMsg: "",
    options: [],
    questionId: "",
    // onSelect: () => {},
};

export default TakeSurveyRadio;
