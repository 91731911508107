import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { PAGINATION_PAGES, SERVICE_KEYS } from "../../utils/constants/appData";

const allHeadCells = [
    {
        id: SERVICE_KEYS.NAME,
        label: "Name",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: SERVICE_KEYS.PRICE,
        label: "Price",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: SERVICE_KEYS.CREATED_AT,
        label: "Date Created",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
];

const ServicesTable = ({
    canDelete,
    canEdit,
    services,
    serviceCounts,
    servicesFetched,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    onEdit,
    onDelete,
}) => {
    let colspan = allHeadCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const serviceCountNonZero =
        services &&
        services.length > 0 &&
        serviceCounts &&
        serviceCounts[SERVICE_KEYS.TOTAL] &&
        !isNaN(parseInt(serviceCounts[SERVICE_KEYS.TOTAL])) &&
        parseInt(serviceCounts[SERVICE_KEYS.TOTAL]) > 0;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Services Table">
                    <TableHead>
                        <TableRow>
                            {allHeadCells.map((headCell) =>
                                headCell.show ? (
                                    <TableCell key={headCell.id}>
                                        {headCell.label}
                                    </TableCell>
                                ) : null
                            )}
                            <TableCell
                                key="actions"
                                sx={{ width: "15%", textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {serviceCountNonZero ? (
                            services.map((service) => {
                                return (
                                    <TableRow
                                        key={service[SERVICE_KEYS.ID]}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {service[SERVICE_KEYS.NAME]}
                                        </TableCell>
                                        <TableCell>
                                            {service[SERVICE_KEYS.PRICE]
                                                ? `$${
                                                      service[
                                                          SERVICE_KEYS.PRICE
                                                      ]
                                                  } per ${
                                                      service[
                                                          SERVICE_KEYS
                                                              .PRICE_INTERVAL
                                                      ]
                                                  }`
                                                : "NA"}
                                        </TableCell>
                                        <TableCell>
                                            {service[SERVICE_KEYS.CREATED_AT]}
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {canEdit && (
                                                    <TableActionIcon
                                                        iconComponent="edit"
                                                        label="edit service"
                                                        tooltip="Edit Service"
                                                        onClick={() =>
                                                            onEdit(service)
                                                        }
                                                    />
                                                )}
                                                {canDelete && (
                                                    <TableActionIcon
                                                        iconComponent="delete"
                                                        label="delete service"
                                                        tooltip="Delete Service"
                                                        onClick={() =>
                                                            onDelete(service)
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            servicesFetched ? "error" : "info"
                                        }
                                    >
                                        {servicesFetched
                                            ? "There are no records found."
                                            : "Loading services..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    serviceCountNonZero
                        ? parseInt(serviceCounts[SERVICE_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={serviceCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

ServicesTable.propTypes = {
    canDelete: PropTypes.bool,
    canEdit: PropTypes.bool,
    authUserType: PropTypes.string,
    services: PropTypes.array,
    serviceCounts: PropTypes.object,
    servicesFetched: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onReopen: PropTypes.func,
};

ServicesTable.defaultProps = {
    canDelete: false,
    canEdit: false,
    services: [],
    serviceCounts: null,
    servicesFetched: false,
    page: 0,
    rowsPerPage: 0,
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    onEdit: () => {},
    onDelete: () => {},
};

export default ServicesTable;
