import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import TableActionIcon from "../TableActionIcon";
import { formatBytes } from "../../../utils/appHelpers";
import {
    VALID_COMMON_FILE_MAX_SIZE_MB,
    VALID_COMMON_FILES_COUNT,
} from "../../../utils/constants/appData";

const DnDRejectedFiles = ({ files, validFileExtensions, onRemove }) => {
    const { theme: jumboTheme } = useJumboTheme();

    if (files && files.length > 0) {
        return (
            <List
                disablePadding
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {files.map(({ file, errors }, fileIdx) => {
                    const filePath = file.path;

                    return (
                        <ListItem
                            key={`error-${fileIdx}${filePath}`}
                            secondaryAction={
                                <TableActionIcon
                                    edge="end"
                                    iconComponent="close"
                                    label="close error message"
                                    tooltip="Close Error Message"
                                    onClick={() => onRemove(fileIdx)}
                                />
                            }
                            sx={{
                                width: "auto",
                                mr: 1,
                                flex: "1 1 100%",
                            }}
                        >
                            <ListItemText
                                disableTypography
                                primary={`${filePath} - ${formatBytes(
                                    file.size
                                )}`}
                                secondary={
                                    <ul>
                                        {errors.map((error) => {
                                            const errorCode = error.code;

                                            let errorMsg = "";
                                            switch (errorCode) {
                                                case "too-many-files":
                                                    errorMsg = `Maximum number of allowed files is ${VALID_COMMON_FILES_COUNT}`;
                                                    break;

                                                case "file-too-large":
                                                    errorMsg = `Maximum allowed file size is ${VALID_COMMON_FILE_MAX_SIZE_MB} MB`;
                                                    break;

                                                case "file-invalid-type":
                                                    errorMsg =
                                                        validFileExtensions &&
                                                        validFileExtensions.length >
                                                            0
                                                            ? `File type must be ${validFileExtensions.join(
                                                                  ", "
                                                              )}`
                                                            : error.message;
                                                    break;

                                                default:
                                                    errorMsg = error.message;
                                                    break;
                                            }

                                            return (
                                                <li
                                                    key={`${fileIdx}-${errorCode}`}
                                                    style={{
                                                        color: jumboTheme
                                                            .palette.error.main,
                                                    }}
                                                >
                                                    {errorMsg}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
        );
    } else {
        return null;
    }
};

DnDRejectedFiles.propTypes = {
    files: PropTypes.array,
    validFileExtensions: PropTypes.array,
    onRemove: PropTypes.func,
};

DnDRejectedFiles.defaultProps = {
    files: [],
    validFileExtensions: [],
};

export default DnDRejectedFiles;
