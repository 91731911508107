import VerticalDefault from "../../layouts/vertical-default/VerticalDefault";
import SoloPage from "../../layouts/solo-page/SoloPage";
import FullPage from "../../layouts/full-page/FullPage";

const LAYOUT_NAMES = {
    VERTICAL_DEFAULT: "vertical-default",
    SOLO_PAGE: "solo-page",
    FULL_PAGE: "full-page",
};

const LAYOUTS = [
    {
        name: LAYOUT_NAMES.VERTICAL_DEFAULT,
        label: "Vertical Default",
        component: VerticalDefault,
    },
    {
        name: LAYOUT_NAMES.SOLO_PAGE,
        label: "Solo Page",
        component: SoloPage,
    },
    {
        name: LAYOUT_NAMES.FULL_PAGE,
        label: "Full Page",
        component: FullPage,
    },
];

export { LAYOUTS, LAYOUT_NAMES };
