import {
    GET_CLIENT_FM_USERS,
    GET_CLIENT_FM_USER_COUNT,
    SET_CLIENT_FM_USER_DETAILS,
} from "../../utils/constants/actionTypes";
import { FM_USER_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    clientFmUsers: [],
    currentClientFmUser: null,
    clientFmUserCounts: { [FM_USER_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENT_FM_USERS: {
            return {
                ...state,
                clientFmUsers: action.payload,
            };
        }
        case GET_CLIENT_FM_USER_COUNT: {
            return {
                ...state,
                clientFmUserCounts: action.payload,
            };
        }
        case SET_CLIENT_FM_USER_DETAILS: {
            return {
                ...state,
                currentClientFmUser: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
