import axios from "axios";

const basicAuthAxios = axios.create({
    baseURL: "https://fmportal.firm-media.com/",
    headers: {
        "Content-Type": "application/json",
    },
});

basicAuthAxios.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response && err.response.data.type === "token-invalid") {
            //todo logout the user
        }
        return Promise.reject(err);
    }
);

/* export const setAuthToken = (token) => {
    if (token) {
        basicAuthAxios.defaults.headers.common["Authorization"] =
            "Bearer " + token;
        sessionStorage.setItem("token", token);
    } else {
        delete basicAuthAxios.defaults.headers.common["Authorization"];
        sessionStorage.removeItem("token");
    }
}; */

/* export const getAuthToken = () => {
    return sessionStorage.getItem("token");
}; */

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default basicAuthAxios;
