import {
    ASSIGNED_SERVICE_KEYS,
    FOLDER_KEYS,
    MEETING_KEYS,
    SURVEY_KEYS,
    TICKET_KEYS,
} from "./appData";

export const allFoldersHeadCells = [
    {
        id: FOLDER_KEYS.NAME,
        label: "Folder",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "50%",
    },
    {
        id: FOLDER_KEYS.INDUSTRY,
        label: "Industry",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "25%",
    },
    {
        id: FOLDER_KEYS.UPDATED_AT,
        label: "Last Modified",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "20%",
    },
];

export const allMeetingsHeadCells = [
    {
        id: MEETING_KEYS.NAME,
        label: "Meeting Name",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "20%",
    },
    {
        id: MEETING_KEYS.STATUS,
        label: "Status",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "10%",
    },
    {
        id: MEETING_KEYS.FM_USER_NAME,
        label: "User",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
    {
        id: MEETING_KEYS.CLIENT_NAME,
        label: "Client",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
    {
        id: MEETING_KEYS.MEETING_DATE,
        label: "Meeting Date",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "17.5%",
    },
];

export const allSurveyHeadCells = [
    {
        id: SURVEY_KEYS.NAME,
        label: "Name",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "70%",
    },
    {
        id: SURVEY_KEYS.CREATED_AT,
        label: "Created Date",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
];

export const allTicketsHeadCells = [
    {
        id: TICKET_KEYS.ID,
        label: "Ticket #",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "11.5%",
    },
    {
        id: TICKET_KEYS.SUBJECT,
        label: "Subject",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "40%",
    },
    {
        id: TICKET_KEYS.PRIORITY,
        label: "Priority",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "9%",
    },
    {
        id: TICKET_KEYS.FM_USERS,
        label: "Assigned AE",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "11.5%",
    },
    {
        id: TICKET_KEYS.CREATED_AT,
        label: "Requested",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "9%",
    },
    {
        id: TICKET_KEYS.UPDATED_AT,
        label: "Updated",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "9%",
    },
];

export const assignedServicesAllHeadCells = [
    {
        id: ASSIGNED_SERVICE_KEYS.NAME,
        label: "Services Purchased",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ASSIGNED_SERVICE_KEYS.COMPANY_NAME,
        label: "Company",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ASSIGNED_SERVICE_KEYS.CREATED_AT,
        label: "Date of Purchase",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ASSIGNED_SERVICE_KEYS.RENEWAL_DATE,
        label: "Renewal Date",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ASSIGNED_SERVICE_KEYS.STATUS,
        label: "Status",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
];
