import { alpha } from "@mui/material";

export const mainTheme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },

    palette: {
        primary: {
            main: "#4B4B4B",
            light: "#818E94",
            dark: "#2D2F39",
            contrastText: "#FFF",
        },
        secondary: {
            main: "#CA3D27",
            light: "#DA543F",
            dark: "#962D1D",
            contrastText: "#FFF",
        },
        error: {
            main: "#E73145",
            light: "#FF6A70",
            dark: "#AD001E",
            contrastText: "#FFF",
        },
        warning: {
            main: "#F39711",
            light: "#FFC84C",
            dark: "#BB6900",
            contrastText: "#FFF",
        },
        info: {
            main: "#2EB5C9",
            light: "#6FE7FC",
            dark: "#008598",
            contrastText: "#FFF",
        },
        success: {
            main: "#3BD2A2",
            light: "#78FFD3",
            dark: "#00A073",
            contrastText: "#FFF",
        },
        text: {
            primary: "#4B4B4B",
            secondary: "#E44A77",
            disabled: "#A2B2C3",
        },
        divider: "#DEE2E6",
        background: {
            paper: "#FFFFFF",
            default: "#F5F7FA",
        },
        action: {
            active: "#4B4B4B",
            hover: "#F5F7FA",
        },
        miscellaneous: {
            red: "#CA3D27",
            button: {
                main: "#112434",
                contrastText: "#FFF",
            },
        },
    },
    shadows: [
        "none",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
        "0 5px 10px rgba(0, 0, 0, 0.085)",
        "0 5px 10px rgba(0, 0, 0, 0.175)",
    ],
    typography: {
        fontFamily: "MuseoSans, Arial",
        fontSize: 14,
        h1: {
            fontSize: "1.5rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#37373C",
            margin: "0 0 .5rem",
        },
        h2: {
            fontSize: "1.4rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#37373C",
            margin: "0 0 .5rem",
        },
        h3: {
            fontSize: "1.25rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#37373C",
            margin: "0 0 .5rem",
        },
        h4: {
            fontSize: "1.1rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#37373C",
            margin: "0 0 .5rem",
        },
        h5: {
            fontSize: "1rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#37373C",
            margin: "0 0 .5rem",
        },
        h6: {
            fontSize: ".875rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#37373C",
            margin: "0 0 .5rem",
        },
        body1: {
            fontSize: ".875rem",
        },
        miscellaneous: {
            button: {
                medium: { fontSize: ".875rem" },
                small: { fontSize: ".825rem" },
            },
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: alpha("#000", 0.1),
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: "auto",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: `0 0.5rem 1.25rem ${alpha("#7352C7", 0.175)}`,
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 24,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: "18px 24px",
                },
                title: {
                    fontSize: "1.1rem",
                    marginBottom: 0,
                },
                subheader: {
                    margin: "4px 0 0",
                },
                action: {
                    margin: 0,
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "8px 24px",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                sizeSmall: {
                    height: 22,
                    fontSize: 12,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    const className = ownerState?.className || "";
                    const color = ownerState?.color || "";
                    const variant = ownerState.variant;
                    const breakpoints = theme.breakpoints;

                    const btnMdFontSize =
                        theme.typography?.miscellaneous?.button?.medium
                            ?.fontSize || "1rem";
                    const btnSmFontSize =
                        theme.typography?.miscellaneous?.button?.small
                            ?.fontSize || ".5rem";

                    let btnRedColor = theme.palette.secondary.main;
                    let btnMainColor =
                        theme.palette?.miscellaneous?.button?.main || "#112434";
                    let btnContrastColor =
                        theme.palette?.miscellaneous?.button?.contrastText ||
                        "#FFF";
                    switch (color) {
                        case "error":
                            btnMainColor = theme.palette.error.main;
                            btnContrastColor = theme.palette.error.contrastText;
                            break;

                        default:
                            break;
                    }

                    const updatedStyles = {
                        border: `2px solid ${btnMainColor}`,
                        borderRadius: "34px",
                        opacity: 1,
                        textAlign: "center",
                        fontWeight: 400,
                        fontSize: btnMdFontSize,
                        letterSpacing: 0,
                        textDecoration: "none",
                        textTransform: "capitalize",
                    };

                    if (ownerState.size === "small") {
                        updatedStyles.padding = theme.spacing(0.75, 1.5);
                        updatedStyles.fontSize = btnSmFontSize;
                    } else {
                        if (className.includes("officeContactBtn")) {
                            updatedStyles.padding = theme.spacing(1, 2);
                        } else if (className.includes("fmButton")) {
                            updatedStyles.padding = theme.spacing(1.25, 2.5);
                        } else {
                            updatedStyles.padding = theme.spacing(1.5, 3);
                        }
                        updatedStyles[breakpoints.down("sm")] = {
                            padding: theme.spacing(1, 2),
                            fontSize: btnSmFontSize,
                        };
                    }

                    if (variant === "contained") {
                        if (ownerState.color === "primary") {
                            updatedStyles.boxShadow = `0px 5px 5px ${btnMainColor}47`;
                            updatedStyles.background = `${btnMainColor} 0% 0% no-repeat padding-box`;
                            updatedStyles.color = btnContrastColor;
                            updatedStyles["&:hover"] = {
                                border: `2px solid ${btnMainColor}`,
                                backgroundColor: btnContrastColor,
                                fontWeight: 600,
                                color: btnMainColor,
                                textDecoration: "underline",
                            };
                        }
                    } else if (variant === "outlined") {
                        updatedStyles.fontWeight = 600;
                        updatedStyles.color = btnMainColor;
                        updatedStyles["&:hover"] = {
                            border: `2px solid ${btnMainColor}`,
                            backgroundColor: btnMainColor,
                            color: btnContrastColor,
                            textDecoration: "underline",
                        };
                    } else if (variant === "text") {
                        updatedStyles.border = "none";
                        updatedStyles.backgroundColor = "transparent";
                        updatedStyles.color = btnMainColor;
                        updatedStyles.fontWeight = 600;
                        updatedStyles.textDecoration = "underline";
                        updatedStyles["&:hover"] = {
                            border: "none",
                            borderRadius: "5%",
                            backgroundColor: alpha(btnMainColor, 0.04),
                            color: btnRedColor,
                        };
                    }

                    return updatedStyles;
                },
            },
        },
        MuiLoadingButton: {
            styleOverrides: {
                loadingIndicator: ({ ownerState, theme }) => {
                    const className = ownerState?.className || "";

                    const btnMainColor =
                        theme.palette?.miscellaneous?.button?.primary ||
                        "#112434";

                    const updatedStyles = {};

                    if (ownerState.variant === "contained") {
                        if (className === "authBtn") {
                            updatedStyles.color = btnMainColor;
                        }
                    }

                    return updatedStyles;
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    const updatedStyles = {};

                    updatedStyles["&:hover"] = {
                        color: theme.palette.secondary.main,
                        "& .MuiTableSortLabel-icon": {
                            color: theme.palette.secondary.main,
                        },
                    };

                    updatedStyles["&.Mui-active"] = {
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        "& .MuiTableSortLabel-icon": {
                            fontWeight: 600,
                            color: theme.palette.secondary.main,
                        },
                        "&:hover": {
                            fontWeight: 600,
                            color: theme.palette.secondary.main,
                            "& .MuiTableSortLabel-icon": {
                                fontWeight: 600,
                                color: theme.palette.secondary.light,
                            },
                        },
                    };

                    return updatedStyles;
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 8,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    let btnMainColor =
                        theme.palette?.miscellaneous?.button?.main || "#112434";

                    const updatedStyles = { padding: "16px 24px" };

                    updatedStyles[
                        "& .MuiButtonBase-root.MuiButton-containedPrimary, .MuiButtonBase-root.MuiButton-outlinedPrimary"
                    ] = {
                        padding: theme.spacing(1, 2),
                    };

                    updatedStyles[
                        "& .MuiButtonBase-root.MuiButton-textPrimary"
                    ] = {
                        color: btnMainColor,
                        padding: theme.spacing(1, 2),
                        borderRadius: "34px",
                        textDecoration: "none",
                        "&:hover": {
                            color: btnMainColor,
                            textDecoration: "none",
                        },
                    };

                    return updatedStyles;
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                avatar: {
                    backgroundColor: "#757575",
                    fontSize: 16,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: "#4B4B4B",
                    background: "#F4F7FC 0% 0% no-repeat padding-box",
                    borderColor: "#243463",
                    borderRadius: "2px",
                    opacity: 1,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#4B4B4B",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#707070",
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    const updatedStyles = {};

                    updatedStyles["&.MuiRadio-colorPrimary"] = {
                        color: theme.palette.primary.main,
                        "&:hover": {
                            color: theme.palette.secondary.main,
                        },
                    };

                    return updatedStyles;
                },
            },
        },
    },
    jumboComponents: {
        JumboSearch: {
            background: "#F5F5F5",
        },
    },
};
