import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    SIDEBAR_ITEM_ACTIVE,
    SIDEBAR_ITEM_KEYS,
    SIDEBAR_ITEM_DEFAULT,
} from "../../utils/constants/appData";
import muiIconsList from "../../utils/constants/muiIconsList";
import StyledSidebarItem from "./StyledSidebarItem";

const SidebarItem = ({ item, onDeleteItem, onEditItem }) => {
    const itemId =
        item && item?.[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
            ? item[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
            : "";

    if (!itemId) return null;

    const iconObj = muiIconsList.find(
        (tempIconObj) => tempIconObj.label === item[SIDEBAR_ITEM_KEYS.ICON]
    );

    const IconComponent = iconObj && iconObj?.icon ? iconObj.icon : null;

    const itemIsDefault =
        item && item?.[SIDEBAR_ITEM_KEYS.IS_DEFAULT]
            ? item[SIDEBAR_ITEM_KEYS.IS_DEFAULT] === SIDEBAR_ITEM_DEFAULT
            : true;

    const itemIsActive =
        item && item?.[SIDEBAR_ITEM_KEYS.IS_ACTIVE]
            ? item[SIDEBAR_ITEM_KEYS.IS_ACTIVE] === SIDEBAR_ITEM_ACTIVE
            : false;

    return (
        <StyledSidebarItem
            secondaryAction={
                <Stack direction="row" alignItems="center" spacing={1}>
                    <TableActionIcon
                        iconComponent="edit"
                        label="edit item"
                        tooltip="Edit Item"
                        onClick={() => onEditItem(item)}
                    />
                    {!itemIsDefault && (
                        <TableActionIcon
                            iconComponent="delete"
                            label="delete item"
                            tooltip="Delete Item"
                            onClick={() => onDeleteItem(item)}
                        />
                    )}
                </Stack>
            }
        >
            {IconComponent && (
                <ListItemIcon>
                    <IconComponent />
                </ListItemIcon>
            )}
            <ListItemText
                primary={item[SIDEBAR_ITEM_KEYS.LABEL]}
                secondary={
                    itemIsActive ? (
                        <Chip
                            component="span"
                            label="Visible"
                            size="small"
                            color="success"
                        />
                    ) : (
                        <Chip
                            component="span"
                            label="Hidden"
                            size="small"
                            color="default"
                        />
                    )
                }
            />
        </StyledSidebarItem>
    );
};

SidebarItem.propTypes = {
    item: PropTypes.object,
    onDeleteItem: PropTypes.func,
    onEditItem: PropTypes.func,
};

SidebarItem.defaultProps = {
    item: null,
};

export default SidebarItem;
