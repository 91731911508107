import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    SURVEY_KEYS,
    SURVEY_ANSWER_KEYS,
    SURVEY_QUESTION_KEYS,
    SURVEY_QUESTION_TYPE_MULTI_SELECT,
    SURVEY_QUESTION_TYPE_SINGLE_SELECT,
    SURVEY_QUESTION_TYPE_TEXT,
} from "../../utils/constants/appData";
import {
    getSurveyAllQuestionTypes,
    getSurveyOptionsQuestionTypes,
} from "../../utils/appHelpers";
import { SURVEY_ANSWER_REQUIRED } from "../../utils/constants/errorMessages";
import { submitSurvey } from "../../redux/actions/surveys";
import TakeSurveyCheckbox from "./TakeSurveyCheckbox";
import TakeSurveyRadio from "./TakeSurveyRadio";
import TakeSurveyTextarea from "./TakeSurveyTextarea";

const allQuestionTypesData = getSurveyAllQuestionTypes();
const allQuestionTypes = allQuestionTypesData.map(
    (tempQuesType) => tempQuesType.value
);

const optionsQuestionTypesData = getSurveyOptionsQuestionTypes();

const TakeSurveyForm = ({
    canSubmitSurvey,
    currentSurvey,
    onSubmitSuccess,
}) => {
    const _isTakeSurveyFormMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken } = useJumboAuth();

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [questionErrors, setQuestionErrors] = useState([]);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        if (
            currentSurvey &&
            currentSurvey?.[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID]
        ) {
            const currentSurveyQuestions =
                currentSurvey?.[SURVEY_KEYS.QUESTIONS] || [];

            setQuestions(currentSurveyQuestions);

            if (currentSurveyQuestions && currentSurveyQuestions.length > 0) {
                setQuestionErrors(
                    currentSurveyQuestions.map((currentSurveyQuestion) => {
                        return {
                            question_id:
                                currentSurveyQuestion[SURVEY_QUESTION_KEYS.ID],
                            error_msg: "",
                        };
                    })
                );
                setAnswers(
                    currentSurveyQuestions.map((currentSurveyQuestion) => {
                        const currentSurveyQuestionType =
                            currentSurveyQuestion[SURVEY_QUESTION_KEYS.TYPE];

                        return {
                            [SURVEY_ANSWER_KEYS.QUESTION_ID]:
                                currentSurveyQuestion[SURVEY_QUESTION_KEYS.ID],
                            [SURVEY_ANSWER_KEYS.ANSWER]:
                                optionsQuestionTypesData.includes(
                                    currentSurveyQuestionType
                                )
                                    ? []
                                    : "",
                            [SURVEY_ANSWER_KEYS.ANSWER_REQUIRED]:
                                optionsQuestionTypesData.includes(
                                    currentSurveyQuestionType
                                )
                                    ? true
                                    : false,
                        };
                    })
                );
            } else {
                setAnswers([]);
                setQuestionErrors([]);
            }
        } else {
            setQuestions([]);
            setAnswers([]);
            setQuestionErrors([]);
        }
    }, [currentSurvey]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    if (questions.length === 0) {
        return (
            <Alert variant="outlined" severity="error">
                No questions found for this survey!
            </Alert>
        );
    }

    let optionsNonZero = true;
    let questionTypesValid = true;

    for (let i = 0; i < questions.length; i++) {
        const questionType = questions[i]?.[SURVEY_QUESTION_KEYS.TYPE] || "";
        if (questionType) {
            if (!allQuestionTypes.includes(questionType)) {
                questionTypesValid = false;
                break;
            }
        } else {
            questionTypesValid = false;
            break;
        }

        if (optionsQuestionTypesData.includes(questionType)) {
            if (questions[i]?.[SURVEY_QUESTION_KEYS.OPTIONS]) {
                const questionOptions =
                    questions[i][SURVEY_QUESTION_KEYS.OPTIONS];
                if (questionOptions && questionOptions.length > 0) {
                    continue;
                } else {
                    optionsNonZero = false;
                    break;
                }
            } else {
                optionsNonZero = false;
                break;
            }
        }
    }

    if (!questionTypesValid || !optionsNonZero) {
        return (
            <Alert variant="outlined" severity="error">
                There was a problem in loading the questions!
            </Alert>
        );
    }

    const handleMultiSelect = (questionId, optionId) => {
        /* console.log("handleMultiSelect", { questionId, optionId }); */
        if (!questionId || !optionId) return false;

        setAnswers((prevState) =>
            prevState.map((answerObj) => {
                if (answerObj[SURVEY_ANSWER_KEYS.QUESTION_ID] === questionId) {
                    const checked = answerObj[SURVEY_ANSWER_KEYS.ANSWER];
                    const currentIndex = checked.indexOf(optionId);
                    const newChecked = [...checked];

                    if (currentIndex === -1) {
                        newChecked.push(optionId);
                    } else {
                        newChecked.splice(currentIndex, 1);
                    }

                    return {
                        ...answerObj,
                        [SURVEY_ANSWER_KEYS.ANSWER_REQUIRED]: true,
                        [SURVEY_ANSWER_KEYS.ANSWER]: newChecked,
                    };
                } else {
                    return answerObj;
                }
            })
        );
    };

    const handleSingleSelect = (questionId, optionId) => {
        /* console.log("handleSingleSelect", { questionId, optionId }); */
        if (!questionId || !optionId) return false;

        setAnswers((prevState) =>
            prevState.map((answerObj) => {
                if (answerObj[SURVEY_ANSWER_KEYS.QUESTION_ID] === questionId) {
                    return {
                        ...answerObj,
                        [SURVEY_ANSWER_KEYS.ANSWER]: [optionId],
                    };
                } else {
                    return answerObj;
                }
            })
        );
    };

    const handleTextChange = (questionId, answer) => {
        if (!questionId) return false;

        setAnswers((prevState) =>
            prevState.map((answerObj) => {
                if (answerObj[SURVEY_ANSWER_KEYS.QUESTION_ID] === questionId) {
                    return {
                        ...answerObj,
                        [SURVEY_ANSWER_KEYS.ANSWER]: answer || "",
                    };
                } else {
                    return answerObj;
                }
            })
        );
    };

    const handleTextBlur = (questionId, answer) => {
        if (!questionId) return false;

        setAnswers((prevState) =>
            prevState.map((answerObj) => {
                if (answerObj[SURVEY_ANSWER_KEYS.QUESTION_ID] === questionId) {
                    return {
                        ...answerObj,
                        [SURVEY_ANSWER_KEYS.ANSWER]: answer || "",
                    };
                } else {
                    return answerObj;
                }
            })
        );
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!canSubmitSurvey) {
            return false;
        }

        setMiscellaneousErrors([]);

        let formIsValid = true;

        for (let i = 0; i < answers.length; i++) {
            let errorMsg = "";

            if (
                answers[i][SURVEY_ANSWER_KEYS.ANSWER_REQUIRED] &&
                answers[i][SURVEY_ANSWER_KEYS.ANSWER].length === 0
            ) {
                formIsValid = false;
                errorMsg = SURVEY_ANSWER_REQUIRED;
            }

            setQuestionErrors((prevState) =>
                prevState.map((errorObj) => {
                    if (
                        errorObj.question_id ===
                        answers[i][SURVEY_ANSWER_KEYS.QUESTION_ID]
                    ) {
                        return {
                            ...errorObj,
                            error_msg: errorMsg,
                        };
                    } else {
                        return errorObj;
                    }
                })
            );
        }

        if (formIsValid) {
            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.SURVEY_ID]: currentSurvey[SURVEY_KEYS.ID],
                [SURVEY_KEYS.ANSWERS]: answers,
            };

            /* console.log({ surveyData }); */
            dispatch(
                submitSurvey(
                    { surveyData: surveyData },
                    (submittedSurvey) => {
                        if (_isTakeSurveyFormMounted.current) {
                            setTimeout(() => {
                                onSubmitSuccess();
                            }, 1000);
                        }
                    },
                    (messages) => {
                        if (_isTakeSurveyFormMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
                {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        sx={(theme) => ({
                            p: theme.spacing(1, 4),
                        })}
                    >
                        {miscellaneousErrors.map((miscellaneousError, idx) => (
                            <Typography
                                variant="caption"
                                display="block"
                                color="error"
                                gutterBottom
                                key={`misc-error-${idx}`}
                            >
                                {miscellaneousError}
                            </Typography>
                        ))}
                    </Grid>
                )}
                {questions.map((questionObj, idx) => {
                    const questionId = questionObj[SURVEY_QUESTION_KEYS.ID];
                    const type = questionObj[SURVEY_QUESTION_KEYS.TYPE];
                    const options = questionObj[SURVEY_QUESTION_KEYS.OPTIONS];
                    const answerObj = answers.find(
                        (answer) =>
                            answer[SURVEY_ANSWER_KEYS.QUESTION_ID] ===
                            questionId
                    );
                    const errorObj = questionErrors.find(
                        (questionError) =>
                            questionError.question_id === questionId
                    );
                    const errorMsg = errorObj.error_msg;

                    return (
                        <Grid item xs={12} key={`question-${questionId}`}>
                            <Card>
                                <CardHeader
                                    title={`${idx + 1}. ${
                                        questionObj[SURVEY_QUESTION_KEYS.TITLE]
                                    }`}
                                    sx={{ pb: 0 }}
                                />
                                <CardContent>
                                    {type ===
                                        SURVEY_QUESTION_TYPE_MULTI_SELECT && (
                                        <TakeSurveyCheckbox
                                            answerObj={answerObj}
                                            errorMsg={errorMsg}
                                            options={options}
                                            questionId={questionId}
                                            onSelect={handleMultiSelect}
                                        />
                                    )}
                                    {type ===
                                        SURVEY_QUESTION_TYPE_SINGLE_SELECT && (
                                        <TakeSurveyRadio
                                            answerObj={answerObj}
                                            errorMsg={errorMsg}
                                            options={options}
                                            questionId={questionId}
                                            onSelect={handleSingleSelect}
                                        />
                                    )}
                                    {type === SURVEY_QUESTION_TYPE_TEXT && (
                                        <TakeSurveyTextarea
                                            answerObj={answerObj}
                                            errorMsg={errorMsg}
                                            questionId={questionId}
                                            onBlur={handleTextBlur}
                                            onChange={handleTextChange}
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
                {canSubmitSurvey && (
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                        >
                            Submit
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

TakeSurveyForm.propTypes = {
    canSubmitSurvey: PropTypes.bool,
    currentSurvey: PropTypes.object,
    onSubmitSuccess: PropTypes.func,
};

TakeSurveyForm.defaultProps = {
    canSubmitSurvey: false,
    currentSurvey: null,
    onSubmitSuccess: () => {},
};

export default TakeSurveyForm;
