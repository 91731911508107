import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import HeadingStack from "../../shared/widgets/HeadingStack";
import NameLink from "../../shared/widgets/NameLink";
import PageTitle from "../../shared/widgets/PageTitle";
import { CLIENT_USER_KEYS, INDUSTRY_KEYS } from "../../utils/constants/appData";

const ClientDetails = ({
    allIndustries,
    canAddEditClient,
    clientUser,
    genericPageName,
    onEdit,
}) => {
    const [clientIndustry, setClientIndustry] = useState("");
    useEffect(() => {
        if (allIndustries && clientUser[CLIENT_USER_KEYS.INDUSTRY]) {
            const clientIndustryObj = allIndustries.find(
                (tempIndustryObj) =>
                    tempIndustryObj[INDUSTRY_KEYS.NAME] ===
                    clientUser[CLIENT_USER_KEYS.INDUSTRY]
            );
            setClientIndustry(
                clientIndustryObj ? clientIndustryObj[INDUSTRY_KEYS.NAME] : ""
            );
        }
    }, [allIndustries, clientUser]);

    const clientCompany = clientUser[CLIENT_USER_KEYS.COMPANY] || "";
    const clientCompanyId = clientUser[CLIENT_USER_KEYS.COMPANY_ID] || "";
    const clientName = clientUser[CLIENT_USER_KEYS.NAME] || "";
    const clientWebsite = clientUser[CLIENT_USER_KEYS.WEBSITE] || "";

    const pageName = clientName || genericPageName;

    return (
        <React.Fragment>
            {pageName && (
                <HeadingStack sx={{ alignItems: "baseline" }}>
                    <PageTitle type="v2" component="h1" title={pageName} />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            )}
            <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "start", md: "space-between" }}
                alignItems={{ xs: "start", md: "center" }}
            >
                <Stack direction="column">
                    {clientCompany && (
                        <NameLink
                            component={RouterLink}
                            to={`/company/${clientCompanyId}/dashboard/`}
                            variant="h5"
                            sx={{ fontWeight: "bold" }}
                        >
                            {clientCompany}
                        </NameLink>
                    )}
                    {clientIndustry && (
                        <Typography
                            variant="h5"
                            component="p"
                            sx={{ color: "#757575" }}
                        >
                            {clientIndustry}
                        </Typography>
                    )}
                    {clientWebsite && (
                        <NameLink
                            href={clientWebsite}
                            target="_blank"
                            variant="h5"
                            sx={{ fontWeight: "bold" }}
                        >
                            {clientWebsite}
                        </NameLink>
                    )}
                </Stack>
                {canAddEditClient && (
                    <FmButton
                        label="Edit Project"
                        startIcon="edit"
                        onClick={onEdit}
                    />
                )}
            </Stack>
        </React.Fragment>
    );
};

ClientDetails.propTypes = {
    allIndustries: PropTypes.array,
    canAddEditClient: PropTypes.bool,
    clientUser: PropTypes.object,
    genericPageName: PropTypes.string,
    onEdit: PropTypes.func,
};

ClientDetails.defaultProps = {
    allIndustries: [],
    canAddEditClient: false,
    clientUser: null,
    genericPageName: "",
};

export default ClientDetails;
