import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { capitalizeFLetter } from "@jumbo/utils";
import { updateTicketPriority } from "../../redux/actions/tickets";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import { getTicketPriorityList } from "../../utils/appHelpers";
import { AUTH_USER_KEYS, TICKET_KEYS } from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkTicketPriorityValid } from "../../utils/validationHelpers";

const priorityList = getTicketPriorityList();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditTicketPriorityDialog = ({
    currentTicket,
    open,
    setGlobalState,
    showAlert,
    onClose,
    onPriorityUpdate,
}) => {
    const _isMountedEditTicketPriority = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [priority, setPriority] = useState("");
    const [priorityError, setPriorityError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    useEffect(() => {
        return () => {
            _isMountedEditTicketPriority.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setPriority("");
            setPriorityError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (currentTicket) {
            setPriority(currentTicket[TICKET_KEYS.PRIORITY]);
        }
    }, [currentTicket]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case TICKET_KEYS.PRIORITY:
                        setPriorityError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const priorityChangeHandler = (event) => {
        setPriorityError("");
        const priorityValidationResult = checkTicketPriorityValid(
            event.target.value,
            priorityList
        );
        if (priorityValidationResult.status) {
            if (priorityValidationResult.status === "false") {
                setPriority("");
                setPriorityError(priorityValidationResult.msg);
            } else {
                setPriority(event.target.value);
            }
        } else {
            setPriority("");
            setPriorityError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        if (!currentTicket || !currentTicket[TICKET_KEYS.ID] || !authUserType) {
            formIsValid = false;
            setPriorityError("Invalid ticket");
        } else {
            const priorityValidationResult = checkTicketPriorityValid(
                priority,
                priorityList
            );
            if (priorityValidationResult.status) {
                if (priorityValidationResult.status === "false") {
                    formIsValid = false;
                    setPriorityError(priorityValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setPriorityError(VALIDATION_FAILED);
            }
        }

        if (formIsValid) {
            const ticketData = {
                authcode: authToken,
                [TICKET_KEYS.TICKET_ID]: currentTicket[TICKET_KEYS.ID],
                [TICKET_KEYS.PRIORITY]: priority,
            };

            dispatch(
                updateTicketPriority(
                    {
                        ticketData: ticketData,
                        setGlobalState: setGlobalState,
                        showAlert: showAlert,
                    },
                    () => {
                        if (_isMountedEditTicketPriority.current) {
                            onPriorityUpdate();
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isMountedEditTicketPriority.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitleClose
                title="Edit Ticket Priority"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {authUserType && currentTicket && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel
                                        id="ticket-priority-select-label"
                                        error={priorityError !== ""}
                                    >
                                        Priority
                                    </InputLabel>
                                    <Select
                                        labelId="ticket-priority-select-label"
                                        id="ticket-priority-select"
                                        value={priority}
                                        label="Priority"
                                        margin="dense"
                                        onChange={priorityChangeHandler}
                                        input={
                                            <OutlinedInput
                                                fullWidth
                                                label="Priority"
                                                error={priorityError !== ""}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {priorityList.map((priorityVal) => (
                                            <MenuItem
                                                key={`${priorityVal}-priority`}
                                                value={priorityVal}
                                            >
                                                {capitalizeFLetter(priorityVal)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {priorityError && (
                                        <FormHelperText
                                            error
                                            id="ticket-priority-select-error"
                                        >
                                            {priorityError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update Priority
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!authUserType && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {!currentTicket && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">Invalid ticket</Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

EditTicketPriorityDialog.propTypes = {
    currentTicket: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    showAlert: PropTypes.bool,
    onClose: PropTypes.func,
    onPriorityUpdate: PropTypes.func,
};

EditTicketPriorityDialog.defaultProps = {
    currentTicket: null,
    open: false,
    setGlobalState: false,
    showAlert: false,
    // onClose: () => {},
    onPriorityUpdate: () => {},
};

export default EditTicketPriorityDialog;
