import React, { useMemo, useState } from "react";
import {
    DndContext,
    DragOverlay,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import { SIDEBAR_ITEM_KEYS } from "../../utils/constants/appData";
import SidebarItem from "./SidebarItem";
import SortableSidebarItem from "./SortableSidebarItem";

const SidebarItems = ({ items, onDeleteItem, onEditItem, onOrderUpdate }) => {
    const [activeId, setActiveId] = useState(null);

    const itemIds = useMemo(
        () => items.map((item) => item[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]),
        [items]
    );

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    );

    return (
        <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={itemIds}
                strategy={verticalListSortingStrategy}
            >
                <List>
                    {items.map((item, idx) => (
                        <SortableSidebarItem
                            key={`sidebar-item-${idx}-${
                                item[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
                            }`}
                            item={item}
                            onDeleteItem={onDeleteItem}
                            onEditItem={onEditItem}
                        />
                    ))}
                </List>
            </SortableContext>
            <DragOverlay>
                {activeId ? <SidebarItem id={activeId} /> : null}
            </DragOverlay>
        </DndContext>
    );

    function handleDragStart(event) {
        const { active } = event;
        setActiveId(active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            onOrderUpdate(active.id, over.id);
        }
        setActiveId(null);
    }
};

SidebarItems.propTypes = {
    items: PropTypes.array,
    onDeleteItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onOrderUpdate: PropTypes.func,
};

SidebarItems.defaultProps = {
    items: [],
};

export default SidebarItems;
