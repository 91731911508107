import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    archiveSurvey,
    deleteSurvey,
    getFilteredSurveys,
} from "../../redux/actions/surveys";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { getSurveyTypes } from "../../utils/appHelpers";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    SURVEY_KEYS,
    SURVEY_ARCHIVED_YES,
    SURVEY_ARCHIVED_NO,
    SURVEY_IS_SUBMITTED,
    SURVEY_STATUS_ACTIVE,
    SURVEY_STATUS_ARCHIVED,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { allSurveyHeadCells } from "../../utils/constants/tableColumns";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import SurveysTable from "./SurveysTable";
import SurveysTabPanel, { a11yProps } from "./SurveysTabPanel";
const SendSurveyDialog = lazy(() => import("./SendSurveyDialog"));

const pageName = "Surveys";

const SURVEY_TYPES = getSurveyTypes();

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (["ARCHIVE", "DELETE"].includes(action.type)) {
            return { deleted: true };
        } else if (action.type === "CANCEL") {
            return { deleted: false };
        }
    }

    return { deleted: false };
};

const SURVEYS_LIMIT = 10;

const INIT_SURVEYS_STATE = {
    fetchSurveys: false,
    firstLoad: true,
    order: SORT_ORDER_DESC,
    page: 0,
    rowsPerPage: SURVEYS_LIMIT,
    sort: SURVEY_KEYS.NAME,
    surveysFetched: false,
    type: SURVEY_STATUS_ACTIVE,
};

const surveysLocalReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, fetchSurveys: true, surveysFetched: false };
        } else if (action.type === "RESET") {
            return {
                ...state,
                fetchSurveys: false,
                firstLoad: false,
                page: 0,
                surveysFetched: false,
            };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                fetchSurveys: true,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                surveysFetched: false,
            };
        } else if (action.type === "SET_PAGE") {
            return {
                ...state,
                fetchSurveys: true,
                page: action.page,
                surveysFetched: false,
            };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                fetchSurveys: true,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                sort: updatedSort,
                surveysFetched: false,
            };
        } else if (action.type === "SET_TYPE_AND_FETCH") {
            return {
                ...state,
                fetchSurveys: true,
                type: action.updatedType,
                page: 0,
                surveysFetched: false,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchSurveys: false,
                firstLoad: false,
                surveysFetched: true,
            };
        }
    }

    return INIT_SURVEYS_STATE;
};

const Surveys = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isSurveysMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { surveys, surveyCounts } = useSelector(
        ({ surveysReducer }) => surveysReducer
    );

    const [isLoading, setIsLoading] = useState(false);
    /* const [allFmUsers, setAllFmUsers] = useState([]); */
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [headCells, setHeadCells] = useState([]);
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const [openSendSurveyDialog, setOpenSendSurveyDialog] = useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        deleted: false,
    });

    const [surveysState, dispatchSurveysAction] = useReducer(
        surveysLocalReducer,
        INIT_SURVEYS_STATE
    );

    const { fetchSurveys, surveysFetched, type } = surveysState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canAddEditSurvey = isAdminUser || isFmUser;
    const canArchiveSurvey = isAdminUser || isFmUser;
    const canDeleteSurvey = isAdminUser || isFmUser;
    const canSendSurvey = isAdminUser || isFmUser;
    const canTakeSurvey = isClientUser;
    const canViewSurvey = isAdminUser || isFmUser;
    const canViewSurveyResult = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isSurveysMounted.current = false;
        };
    }, []);

    /* Set the table columns */
    useEffect(() => {
        if (isAdminUser || isClientUser || isFmUser) {
            setHeadCells(
                allSurveyHeadCells.map((headCell) => {
                    if (
                        [SURVEY_KEYS.NAME, SURVEY_KEYS.CREATED_AT].includes(
                            headCell.id
                        )
                    ) {
                        return { ...headCell, show: true, sortColumn: true };
                    } else {
                        return { ...headCell, show: false };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isClientUser, isFmUser]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch surveys */
    useEffect(() => {
        if (isLoading) {
            dispatchSurveysAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (surveysState.fetchSurveys) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredSurveys(payload, () => {
                            if (isActive) {
                                dispatchSurveysAction({ type: "STOP_FETCH" });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                order: surveysState.order,
                page: surveysState.page + 1,
                rows_per_page: surveysState.rowsPerPage,
                sort: surveysState.sort,
                [SURVEY_KEYS.ARCHIVED]:
                    surveysState.type === SURVEY_STATUS_ARCHIVED
                        ? SURVEY_ARCHIVED_YES
                        : SURVEY_ARCHIVED_NO,
            };

            const payload = {
                surveyData: surveyData,
                authUserType: authUserType,
                fromAction: actionState,
            };

            if (!surveysState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    dispatchSurveysAction({ type: "RESET" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserType, actionState, surveysState]);

    /* Fetch the list again if a survey is deleted */
    useEffect(() => {
        if (actionState.deleted) {
            dispatchSurveysAction({ type: "FETCH" });
        }
    }, [actionState]);

    const handleTypeChange = (event, newValue) => {
        if (SURVEY_TYPES.includes(newValue))
            dispatchSurveysAction({
                type: "SET_TYPE_AND_FETCH",
                updatedType: newValue,
            });
    };

    const handlePageChange = (event, newPage) => {
        dispatchSurveysAction({ type: "SET_PAGE", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchSurveysAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchSurveysAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    /* Send survey to companies */
    const handleSendSurvey = (survey) => {
        if (canSendSurvey) {
            setCurrentSurvey(survey);
            setOpenSendSurveyDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseSendSurveyDialog = () => {
        setOpenSendSurveyDialog(false);
        setCurrentSurvey(null);
    };

    const handleDeleteSurvey = React.useCallback(
        (survey) => {
            if (canDeleteSurvey) {
                if (survey && survey[SURVEY_KEYS.ID]) {
                    if (
                        survey?.[SURVEY_KEYS.IS_SUBMITTED] &&
                        survey[SURVEY_KEYS.IS_SUBMITTED] === SURVEY_IS_SUBMITTED
                    ) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "The survey cannot be deleted if submitted by a client",
                        });
                    } else {
                        showDialog({
                            variant: "confirm",
                            title: `Delete survey: ${
                                survey[SURVEY_KEYS.NAME]
                            }?`,
                            onYes: () => {
                                hideDialog();
                                const surveyData = {
                                    authcode: authToken,
                                    [SURVEY_KEYS.SURVEY_ID]:
                                        survey[SURVEY_KEYS.ID],
                                };
                                dispatch(
                                    deleteSurvey(
                                        { surveyData: surveyData },
                                        () => {
                                            if (_isSurveysMounted.current)
                                                dispatchAction({
                                                    type: "DELETE",
                                                });
                                        },
                                        () => {}
                                    )
                                );
                            },
                            onNo: hideDialog,
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid survey",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, canDeleteSurvey]
    );

    const handleArchiveSurvey = React.useCallback(
        (survey) => {
            if (canArchiveSurvey) {
                if (survey && survey[SURVEY_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Archive survey: ${survey[SURVEY_KEYS.NAME]}?`,
                        onYes: () => {
                            hideDialog();
                            const surveyData = {
                                authcode: authToken,
                                [SURVEY_KEYS.SURVEY_ID]: survey[SURVEY_KEYS.ID],
                            };
                            dispatch(
                                archiveSurvey(
                                    { surveyData: surveyData },
                                    () => {
                                        dispatchAction({ type: "ARCHIVE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid survey",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, canArchiveSurvey]
    );

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchSurveys && !surveysFetched;
    const loadError = !isValidUserType || fetchError;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                {pageName && (
                    <Grid item xs={12}>
                        <HeadingStack>
                            <PageTitle
                                component="h1"
                                title={pageName}
                                type="v2"
                            />
                            <Breadcrumb
                                pageNames={{
                                    genericName: pageName,
                                    specificName: pageName,
                                }}
                            />
                        </HeadingStack>
                    </Grid>
                )}
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && fetchError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    There was a problem in fetching the surveys!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        {canAddEditSurvey && (
                            <Grid item xs={12}>
                                <HeadingStack sx={{ justifyContent: "end" }}>
                                    <FmButton
                                        startIcon="add"
                                        type="button"
                                        component={RouterLink}
                                        label="Add Survey"
                                        to={`/survey/add`}
                                    />
                                </HeadingStack>
                            </Grid>
                        )}
                        {(isAdminUser || isFmUser) && type && (
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    <AppBar position="static" color="inherit">
                                        <Tabs
                                            variant="fullWidth"
                                            textColor="inherit"
                                            indicatorColor="secondary"
                                            value={type}
                                            aria-label="surveys tabs"
                                            onChange={handleTypeChange}
                                        >
                                            {SURVEY_TYPES.map((surveyType) => {
                                                return (
                                                    <Tab
                                                        key={`survey-tab-${surveyType}`}
                                                        label={surveyType}
                                                        value={surveyType}
                                                        sx={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                        {...a11yProps(
                                                            surveyType
                                                        )}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                    </AppBar>
                                    {SURVEY_TYPES.map(
                                        (surveyType, surveyTypeIdx) => {
                                            return (
                                                <SurveysTabPanel
                                                    key={`survey-tabpanel-${surveyType}`}
                                                    value={type}
                                                    index={surveyTypeIdx}
                                                    type={surveyType}
                                                >
                                                    {headCells.length > 0 ? (
                                                        <SurveysTable
                                                            canArchiveSurvey={
                                                                canArchiveSurvey
                                                            }
                                                            canAddEditSurvey={
                                                                canAddEditSurvey
                                                            }
                                                            canDeleteSurvey={
                                                                canDeleteSurvey
                                                            }
                                                            canSendSurvey={
                                                                canSendSurvey
                                                            }
                                                            canViewSurvey={
                                                                canViewSurvey
                                                            }
                                                            canViewSurveyResult={
                                                                canViewSurveyResult
                                                            }
                                                            columnsToShow={
                                                                columnsToShow
                                                            }
                                                            surveys={surveys}
                                                            surveyCounts={
                                                                surveyCounts
                                                            }
                                                            surveysFetched={
                                                                surveysFetched
                                                            }
                                                            headCells={
                                                                headCells
                                                            }
                                                            order={
                                                                surveysState.order
                                                            }
                                                            page={
                                                                surveysState.page
                                                            }
                                                            rowsPerPage={
                                                                surveysState.rowsPerPage
                                                            }
                                                            sort={
                                                                surveysState.sort
                                                            }
                                                            type={surveyType}
                                                            handlePageChange={
                                                                handlePageChange
                                                            }
                                                            handleRowsPerPageChange={
                                                                handleRowsPerPageChange
                                                            }
                                                            onArchive={
                                                                handleArchiveSurvey
                                                            }
                                                            onDelete={
                                                                handleDeleteSurvey
                                                            }
                                                            onSend={
                                                                handleSendSurvey
                                                            }
                                                            onSort={handleSort}
                                                        />
                                                    ) : (
                                                        <Alert
                                                            variant="outlined"
                                                            severity="error"
                                                        >
                                                            There was a problem
                                                            in rendering the
                                                            table!
                                                        </Alert>
                                                    )}
                                                </SurveysTabPanel>
                                            );
                                        }
                                    )}
                                </Box>
                            </Grid>
                        )}
                        {isClientUser && (
                            <Grid item xs={12}>
                                {headCells.length > 0 ? (
                                    <SurveysTable
                                        canTakeSurvey={canTakeSurvey}
                                        columnsToShow={columnsToShow}
                                        surveys={surveys}
                                        surveyCounts={surveyCounts}
                                        surveysFetched={surveysFetched}
                                        headCells={headCells}
                                        order={surveysState.order}
                                        page={surveysState.page}
                                        rowsPerPage={surveysState.rowsPerPage}
                                        sort={surveysState.sort}
                                        type={SURVEY_STATUS_ACTIVE}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={
                                            handleRowsPerPageChange
                                        }
                                        onSort={handleSort}
                                    />
                                ) : (
                                    <Alert variant="outlined" severity="error">
                                        There was a problem in rendering the
                                        table!
                                    </Alert>
                                )}
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && canSendSurvey && openSendSurveyDialog && (
                <Suspense fallback={null}>
                    <SendSurveyDialog
                        currentSurvey={currentSurvey}
                        open={openSendSurveyDialog}
                        onClose={handleCloseSendSurveyDialog}
                    />
                </Suspense>
            )}
        </React.Fragment>
    );
};

export default Surveys;
