import { useEffect, useState } from "react";

const useOutsideClick = (ref) => {
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        /**
         * OnMouseDown, we check if the clicked place is in the selected element
         * and update the isClicked value and return.
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsClicked(true);
            } else {
                setIsClicked(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return isClicked;
};

export default useOutsideClick;
