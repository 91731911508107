import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import FormattedPhoneNo from "../../shared/widgets/FormattedPhoneNo";
import PhoneNoExtension from "../../shared/widgets/PhoneNoExtension";
import NameLink from "../../shared/widgets/NameLink";
import {
    checkCompanyLogoValid,
    checkEmailValid,
    checkIndustryValid,
    checkNameValid,
    checkPhoneNoValid,
    checkPhoneNoExtValid,
    checkSheetLinkValid,
    checkWebsiteValid,
} from "../../utils/validationHelpers";
import {
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    INDUSTRY_KEYS,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { addCompany, updateCompany } from "../../redux/actions/companies";

const AddEditCompanyDialog = ({
    allIndustries,
    currentCompany,
    open,
    setGlobalState,
    onClose,
    onInsert,
    onUpdate,
}) => {
    const _isAddEditCompanyDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [industriesList, setIndustriesList] = useState([]);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [industry, setIndustry] = useState(null);
    const [industryError, setIndustryError] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneNoError, setPhoneNoError] = useState("");
    const [phoneNoExt, setPhoneNoExt] = useState("");
    const [phoneNoExtError, setPhoneNoExtError] = useState("");
    const [logo, setLogo] = useState(null);
    const [logoError, setLogoError] = useState("");
    const [logoLink, setLogoLink] = useState("");
    const [website, setWebsite] = useState("");
    const [websiteError, setWebsiteError] = useState("");
    const [sheetLink, setSheetLink] = useState("");
    const [sheetLinkError, setSheetLinkError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    useEffect(() => {
        return () => {
            _isAddEditCompanyDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setIndustriesList([]);
            setName("");
            setNameError("");
            setEmail("");
            setEmailError("");
            setIndustry(null);
            setIndustryError("");
            setPhoneNo("");
            setPhoneNoError("");
            setPhoneNoExt("");
            setPhoneNoExtError("");
            setLogo(null);
            setLogoError("");
            setLogoLink("");
            setWebsite("");
            setWebsiteError("");
            setSheetLink("");
            setSheetLinkError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (allIndustries) {
            setIndustriesList(
                allIndustries.map(
                    (tempIndustry) => tempIndustry[INDUSTRY_KEYS.NAME]
                )
            );
        }
    }, [allIndustries]);

    useEffect(() => {
        if (currentCompany) {
            setName(currentCompany[COMPANY_KEYS.NAME]);
            setEmail(currentCompany[COMPANY_KEYS.EMAIL] || "");
            setPhoneNo(currentCompany[COMPANY_KEYS.PHONE_NO] || "");
            setPhoneNoExt(currentCompany[COMPANY_KEYS.PHONE_NO_EXT] || "");
            setWebsite(currentCompany[COMPANY_KEYS.WEBSITE] || "");
            setSheetLink(currentCompany?.[COMPANY_KEYS.SHEET_LINK] || "");

            if (currentCompany[COMPANY_KEYS.INDUSTRY]) {
                if (allIndustries) {
                    const companyIndustryObj = allIndustries.find(
                        (tempIndustryObj) =>
                            tempIndustryObj[INDUSTRY_KEYS.NAME] ===
                            currentCompany[COMPANY_KEYS.INDUSTRY]
                    );
                    setIndustry(
                        companyIndustryObj
                            ? companyIndustryObj[INDUSTRY_KEYS.NAME]
                            : null
                    );
                }
            } else {
                setIndustry(null);
            }

            setLogo(null);
            const fetchedLogo = currentCompany[COMPANY_KEYS.LOGO_PIC];
            if (fetchedLogo) {
                setLogoLink(
                    `${currentCompany[COMPANY_KEYS.LOGO_BASE_URL]}/${
                        fetchedLogo[COMPANY_KEYS.LOGO_FILE_PATH]
                    }`
                );
            }
        }
    }, [allIndustries, currentCompany]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case COMPANY_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case COMPANY_KEYS.EMAIL:
                        setEmailError(msg);
                        break;

                    case COMPANY_KEYS.INDUSTRY:
                        setIndustryError(msg);
                        break;

                    case COMPANY_KEYS.PHONE_NO:
                        setPhoneNoError(msg);
                        break;

                    case COMPANY_KEYS.PHONE_NO_EXT:
                        setPhoneNoExtError(msg);
                        break;

                    case COMPANY_KEYS.LOGO_PIC:
                        setLogoError(msg);
                        break;

                    case COMPANY_KEYS.WEBSITE:
                        setWebsiteError(msg);
                        break;

                    case COMPANY_KEYS.SHEET_LINK:
                        setSheetLinkError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const nameBlurHandler = (event) => {
        const validationResult = checkNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const emailBlurHandler = (event) => {
        const validationResult = checkEmailValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setEmailError(validationResult.msg);
            }
        } else {
            setEmailError(VALIDATION_FAILED);
        }
    };

    const industryChangeHandler = (event, newValue) => {
        setIndustryError("");

        const industryValidationResult = checkIndustryValid(
            newValue,
            industriesList
        );
        if (industryValidationResult.status) {
            if (industryValidationResult.status === "false") {
                setIndustry(null);
                setIndustryError(industryValidationResult.msg);
            } else {
                setIndustry(newValue);
            }
        } else {
            setIndustry(null);
            setIndustryError(VALIDATION_FAILED);
        }
    };

    const phoneNoChangeHandler = (event) => {
        const updatedPhoneNo = event.target.value;

        setPhoneNo(updatedPhoneNo);
        setPhoneNoError("");

        const validationResult = checkPhoneNoValid(updatedPhoneNo);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setPhoneNoError(validationResult.msg);
            }
        } else {
            setPhoneNoError(VALIDATION_FAILED);
        }
    };

    const phoneNoExtChangeHandler = (event) => {
        const updatedPhoneNoExt = event.target.value;

        setPhoneNoExt(updatedPhoneNoExt);
        setPhoneNoExtError("");

        const validationResult = checkPhoneNoExtValid(updatedPhoneNoExt);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setPhoneNoExtError(validationResult.msg);
            }
        } else {
            setPhoneNoExtError(VALIDATION_FAILED);
        }
    };

    const websiteBlurHandler = (event) => {
        const enteredWebsite = event.target.value;
        if (enteredWebsite) {
            const validationResult = checkWebsiteValid(enteredWebsite);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    setWebsiteError(validationResult.msg);
                }
            } else {
                setWebsiteError(VALIDATION_FAILED);
            }
        }
    };

    const sheetLinkBlurHandler = (event) => {
        const enteredSheetLink = event.target.value;
        if (enteredSheetLink) {
            const validationResult = checkSheetLinkValid(enteredSheetLink);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    setSheetLinkError(validationResult.msg);
                }
            } else {
                setSheetLinkError(VALIDATION_FAILED);
            }
        }
    };

    const logoChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (files) {
            const validationResult = checkCompanyLogoValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setLogoError(validationResult.msg);
                }
            } else {
                isValid = false;
                setLogoError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setLogo(files?.[0] || null);
            setLogoError("");
        } else {
            setLogo(null);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        const emailValidationResult = checkEmailValid(email);
        if (emailValidationResult.status) {
            if (emailValidationResult.status === "false") {
                formIsValid = false;
                setEmailError(emailValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setEmailError(VALIDATION_FAILED);
        }

        const industryValidationResult = checkIndustryValid(
            industry,
            industriesList
        );
        if (industryValidationResult.status) {
            if (industryValidationResult.status === "false") {
                formIsValid = false;
                setIndustryError(industryValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setIndustryError(VALIDATION_FAILED);
        }

        const phoneNoValidationResult = checkPhoneNoValid(phoneNo);
        if (phoneNoValidationResult.status) {
            if (phoneNoValidationResult.status === "false") {
                formIsValid = false;
                setPhoneNoError(phoneNoValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPhoneNoError(VALIDATION_FAILED);
        }

        const phoneNoExtValidationResult = checkPhoneNoExtValid(phoneNoExt);
        if (phoneNoExtValidationResult.status) {
            if (phoneNoExtValidationResult.status === "false") {
                formIsValid = false;
                setPhoneNoExtError(phoneNoExtValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPhoneNoExtError(VALIDATION_FAILED);
        }

        if (website) {
            const websiteValidationResult = checkWebsiteValid(website);
            if (websiteValidationResult.status) {
                if (websiteValidationResult.status === "false") {
                    formIsValid = false;
                    setWebsiteError(websiteValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setWebsiteError(VALIDATION_FAILED);
            }
        }

        if (sheetLink) {
            const sheetLinkValidationResult = checkSheetLinkValid(sheetLink);
            if (sheetLinkValidationResult.status) {
                if (sheetLinkValidationResult.status === "false") {
                    formIsValid = false;
                    setSheetLinkError(sheetLinkValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setSheetLinkError(VALIDATION_FAILED);
            }
        }

        if (logo) {
            const logoValidationResult = checkCompanyLogoValid([logo]);
            if (logoValidationResult.status) {
                if (logoValidationResult.status === "false") {
                    formIsValid = false;
                    setLogoError(logoValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setLogoError(VALIDATION_FAILED);
            }
        }

        if (authUserType && formIsValid) {
            const companyData = new FormData();
            companyData.append("authcode", authToken);
            companyData.append([COMPANY_KEYS.NAME], name);
            companyData.append([COMPANY_KEYS.EMAIL], email);
            companyData.append([COMPANY_KEYS.INDUSTRY], industry);
            companyData.append([COMPANY_KEYS.PHONE_NO], phoneNo);
            companyData.append([COMPANY_KEYS.PHONE_NO_EXT], phoneNoExt);
            companyData.append([COMPANY_KEYS.WEBSITE], website || "");
            companyData.append([COMPANY_KEYS.SHEET_LINK], sheetLink || "");
            if (currentCompany) {
                companyData.append(
                    [COMPANY_KEYS.COMPANY_ID],
                    currentCompany[COMPANY_KEYS.ID]
                );
            }
            if (logo) companyData.append([COMPANY_KEYS.LOGO_PIC], logo);

            if (currentCompany) {
                dispatch(
                    updateCompany(
                        {
                            companyData: companyData,
                            setGlobalState: setGlobalState,
                        },
                        (updatedCompany) => {
                            if (_isAddEditCompanyDialogMounted.current) {
                                onUpdate(updatedCompany);
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditCompanyDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                dispatch(
                    addCompany(
                        {
                            companyData: companyData,
                            setGlobalState: setGlobalState,
                        },
                        () => {
                            if (_isAddEditCompanyDialogMounted.current) {
                                onInsert();
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditCompanyDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentCompany ? "Edit Company" : "Add Company"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {authUserType && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={name}
                                    margin="dense"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameError("");
                                    }}
                                    onBlur={nameBlurHandler}
                                    error={nameError !== ""}
                                    helperText={nameError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={email}
                                    margin="dense"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError("");
                                    }}
                                    onBlur={emailBlurHandler}
                                    error={emailError !== ""}
                                    helperText={emailError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        filterSelectedOptions
                                        id="industry"
                                        options={industriesList}
                                        value={industry}
                                        getOptionLabel={(option) => {
                                            return option ? option : "";
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Select Industry"
                                                error={industryError !== ""}
                                            />
                                        )}
                                        onChange={industryChangeHandler}
                                    />
                                    {industryError && (
                                        <FormHelperText
                                            error
                                            id="industry-error"
                                        >
                                            {industryError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="website"
                                    name="website"
                                    label="Website"
                                    margin="dense"
                                    value={website}
                                    onChange={(e) => {
                                        setWebsite(e.target.value);
                                        setWebsiteError("");
                                    }}
                                    onBlur={websiteBlurHandler}
                                    error={websiteError !== ""}
                                    helperText={websiteError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="phone_no"
                                    name="phone_no"
                                    label="Office Phone Number"
                                    margin="dense"
                                    value={phoneNo}
                                    InputProps={{
                                        inputComponent: FormattedPhoneNo,
                                        inputProps: {
                                            allowEmptyFormatting: false,
                                            displayType: "input",
                                        },
                                    }}
                                    onChange={phoneNoChangeHandler}
                                    error={phoneNoError !== ""}
                                    helperText={phoneNoError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="phone_no_ext"
                                    name="phone_no_ext"
                                    label="Extension"
                                    margin="dense"
                                    value={phoneNoExt}
                                    InputProps={{
                                        inputComponent: PhoneNoExtension,
                                        inputProps: {
                                            displayType: "input",
                                            allowLeadingZeros: true,
                                        },
                                    }}
                                    onChange={phoneNoExtChangeHandler}
                                    error={phoneNoExtError !== ""}
                                    helperText={phoneNoExtError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="sheet_link"
                                    name="sheet_link"
                                    label="Sheet Link"
                                    margin="dense"
                                    value={sheetLink}
                                    onChange={(e) => {
                                        setSheetLink(e.target.value);
                                        setSheetLinkError("");
                                    }}
                                    onBlur={sheetLinkBlurHandler}
                                    error={sheetLinkError !== ""}
                                    helperText={sheetLinkError}
                                />
                                {sheetLink && (
                                    <Box textAlign="center">
                                        <NameLink href={sheetLink} target="_blank">
                                            View Sheet
                                        </NameLink>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <label
                                    htmlFor="logo"
                                    style={{ display: "block", marginTop: 8 }}
                                >
                                    <input
                                        id="logo"
                                        name="logo"
                                        type="file"
                                        onChange={logoChangeHandler}
                                        style={{ display: "none" }}
                                    />

                                    <AttachFileButton
                                        fullWidth
                                        type="button"
                                        variant="outlined"
                                        component="span"
                                        startIcon={<AttachFileIcon />}
                                        sx={(theme) => {
                                            const logoErrorSx = logoError
                                                ? {
                                                      color: theme.palette.error
                                                          .main,
                                                      border: `1px solid ${theme.palette.error.main}`,
                                                  }
                                                : "";
                                            return {
                                                ...logoErrorSx,
                                            };
                                        }}
                                    >
                                        {logo ? logo.name : "Logo"}
                                    </AttachFileButton>
                                </label>
                                {logoError && (
                                    <FormHelperText error id="logo-error">
                                        {logoError}
                                    </FormHelperText>
                                )}
                                {logoLink && (
                                    <NameLink
                                        noWrap
                                        align="center"
                                        href={logoLink}
                                        target="_blank"
                                        sx={(theme) => ({
                                            display: "block",
                                            wordWrap: "break-word",
                                            ...theme.typography.h5,
                                        })}
                                    >
                                        View Logo
                                    </NameLink>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {currentCompany
                                        ? "Update Company"
                                        : "Create Company"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!authUserType && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditCompanyDialog.propTypes = {
    allIndustries: PropTypes.array,
    currentCompany: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    onClose: PropTypes.func,
    onInsert: PropTypes.func,
    onUpdate: PropTypes.func,
};

AddEditCompanyDialog.defaultProps = {
    open: false,
    setGlobalState: false,
    // onClose: () => {},
    onInsert: () => {},
    onUpdate: () => {},
};

export default AddEditCompanyDialog;
