import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import TableColorCard from "../../shared/widgets/TableColorCard";
import {
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    SPECIAL_KEYS,
} from "../../utils/constants/appData";

const SpecialsTable = ({
    ariaLabel,
    canAddEditSpecials,
    canDeleteSpecials,
    canUploadFlyers,
    canViewDescription,
    canViewSpecials,
    cardColor,
    columnsToShow,
    headCells,
    order,
    page,
    showColorCard,
    showEmptyRow,
    sort,
    specials,
    specialCounts,
    specialsFetched,
    tableActions,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    onDelete,
    onEdit,
    onSort,
    onUploadFlyers,
    onViewDescription,
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const specialsTotalCount =
        specialCounts &&
        specialCounts[SPECIAL_KEYS.TOTAL] &&
        !isNaN(parseInt(specialCounts[SPECIAL_KEYS.TOTAL])) &&
        parseInt(specialCounts[SPECIAL_KEYS.TOTAL]) > 0
            ? parseInt(specialCounts[SPECIAL_KEYS.TOTAL])
            : 0;

    const fetchedSpecialsCount =
        specials && specials.length > 0 ? specials.length : 0;

    const specialCountNonZero =
        specials && specials.length > 0 && specialsTotalCount > 0;

    const paddingSx = {};
    if (showEmptyRow) {
        paddingSx.border = "none";
        if (fetchedSpecialsCount) {
            if (fetchedSpecialsCount < rowsPerPage) {
                paddingSx.pt = (theme) => theme.spacing(3.5);
                paddingSx.pb = (theme) => theme.spacing(3.5);
            }
        } else {
            paddingSx.pt = (theme) => theme.spacing(4);
            paddingSx.pb = (theme) => theme.spacing(4);
        }
    }

    const showActions =
        canAddEditSpecials ||
        canDeleteSpecials ||
        canUploadFlyers ||
        canViewDescription ||
        canViewSpecials;

    const table = (
        <Table aria-label={ariaLabel}>
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => {
                        const headCellSx = headCell?.width
                            ? { width: headCell.width }
                            : {};

                        const sortDirection =
                            sort === headCell.id
                                ? order === SORT_ORDER_ASC
                                    ? SORT_ORDER_DESC
                                    : SORT_ORDER_ASC
                                : SORT_ORDER_ASC;

                        return headCell.show ? (
                            <TableCell key={headCell.id} sx={headCellSx}>
                                {headCell.sortColumn ? (
                                    <TableSortLabel
                                        active={sort === headCell.id}
                                        direction={sortDirection}
                                        onClick={() => onSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {sort === headCell.id ? (
                                            <Box
                                                component="span"
                                                sx={visuallyHidden}
                                            >
                                                {order === SORT_ORDER_DESC
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ) : null;
                    })}
                    {showActions && (
                        <TableCell
                            key="actions"
                            sx={{ width: "15%", textAlign: "center" }}
                        >
                            Actions
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody
                sx={{
                    p: (theme) => theme.spacing(1),
                }}
            >
                {specialCountNonZero ? (
                    specials.map((special) => {
                        const specialId = special[SPECIAL_KEYS.ID];

                        return (
                            <TableRow
                                key={specialId}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {columnsToShow.includes(SPECIAL_KEYS.TITLE) && (
                                    <TableCell component="th" scope="row">
                                        {canViewSpecials ? (
                                            <Link
                                                noWrap
                                                color="text.primary"
                                                component={RouterLink}
                                                to={`/special/${specialId}/`}
                                            >
                                                {special[SPECIAL_KEYS.TITLE]}
                                            </Link>
                                        ) : (
                                            special[SPECIAL_KEYS.TITLE]
                                        )}
                                    </TableCell>
                                )}
                                {columnsToShow.includes(
                                    SPECIAL_KEYS.COMPANY_NAME
                                ) && (
                                    <TableCell>
                                        <NameLink
                                            component={RouterLink}
                                            to={`/company/${
                                                special[SPECIAL_KEYS.COMPANY_ID]
                                            }/dashboard/`}
                                        >
                                            {special[SPECIAL_KEYS.COMPANY_NAME]}
                                        </NameLink>
                                    </TableCell>
                                )}
                                {columnsToShow.includes(
                                    SPECIAL_KEYS.CREATED_AT
                                ) && (
                                    <TableCell>
                                        {special[SPECIAL_KEYS.CREATED_AT]}
                                    </TableCell>
                                )}
                                {showActions && (
                                    <TableCell>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {canViewDescription && (
                                                <TableActionIcon
                                                    iconComponent="description"
                                                    label="view description"
                                                    tooltip="View Description"
                                                    onClick={() =>
                                                        onViewDescription(
                                                            special
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUploadFlyers && (
                                                <TableActionIcon
                                                    iconComponent="upload"
                                                    label="upload flyers"
                                                    tooltip="Upload Flyers"
                                                    onClick={() =>
                                                        onUploadFlyers(special)
                                                    }
                                                />
                                            )}
                                            {canAddEditSpecials && (
                                                <TableActionIcon
                                                    iconComponent="edit"
                                                    label="edit special"
                                                    tooltip="Edit special"
                                                    onClick={() =>
                                                        onEdit(special)
                                                    }
                                                />
                                            )}
                                            {canDeleteSpecials && (
                                                <TableActionIcon
                                                    iconComponent="delete"
                                                    label="delete special"
                                                    tooltip="Delete special"
                                                    onClick={() =>
                                                        onDelete(special)
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={colspan} sx={{ ...paddingSx }}>
                            <Alert
                                severity={specialsFetched ? "error" : "info"}
                            >
                                {specialsFetched
                                    ? "There are no records found."
                                    : "Loading specials..."}
                            </Alert>
                        </TableCell>
                    </TableRow>
                )}
                {showEmptyRow &&
                    specialCountNonZero &&
                    fetchedSpecialsCount < rowsPerPage && (
                        <TableRow>
                            <TableCell
                                colSpan={colspan}
                                sx={{ ...paddingSx }}
                            ></TableCell>
                        </TableRow>
                    )}
            </TableBody>
        </Table>
    );

    return showColorCard ? (
        <TableColorCard
            actions={tableActions}
            cardColor={cardColor}
            title={
                specialCounts?.[SPECIAL_KEYS.TOTAL]
                    ? `${specialCounts[SPECIAL_KEYS.TOTAL]}`
                    : "NA"
            }
            subtitle={`${
                specialsTotalCount && specialsTotalCount === 1
                    ? "Special"
                    : "Specials"
            }`}
            sx={{ cardSx: { height: "100%", width: "100%" } }}
        >
            <TableContainer>{table}</TableContainer>
        </TableColorCard>
    ) : (
        <React.Fragment>
            <TableContainer component={Paper}>{table}</TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    specialCountNonZero
                        ? parseInt(specialCounts[SPECIAL_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={specialCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

SpecialsTable.propTypes = {
    ariaLabel: PropTypes.string,
    canAddEditSpecials: PropTypes.bool,
    canDeleteSpecials: PropTypes.bool,
    canUploadFlyers: PropTypes.bool,
    canViewDescription: PropTypes.bool,
    canViewSpecials: PropTypes.bool,
    cardColor: PropTypes.string,
    columnsToShow: PropTypes.array,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    showColorCard: PropTypes.bool,
    showEmptyRow: PropTypes.bool,
    sort: PropTypes.string,
    specials: PropTypes.array,
    specialCounts: PropTypes.object,
    specialsFetched: PropTypes.bool,
    tableActions: PropTypes.array,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onSort: PropTypes.func,
    onUploadFlyers: PropTypes.func,
    onViewDescription: PropTypes.func,
};

SpecialsTable.defaultProps = {
    ariaLabel: "Specials Table",
    canAddEditSpecials: false,
    canDeleteSpecials: false,
    canUploadFlyers: false,
    canViewDescription: false,
    canViewSpecials: false,
    cardColor: "",
    columnsToShow: [],
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    showColorCard: false,
    showEmptyRow: false,
    sort: "",
    specials: [],
    specialCounts: null,
    specialsFetched: false,
    tableActions: [],
    handlePageChange: () => {},
    handleRowsPerPageChange: () => {},
    onDelete: () => {},
    onEdit: () => {},
    onSort: () => {},
    // onUploadFlyers: () => {},
    onViewDescription: () => {},
};

export default SpecialsTable;
