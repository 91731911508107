import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import NameLink from "../../shared/widgets/NameLink";
import {
    checkNameValid,
    checkReportDocumentValid,
} from "../../utils/validationHelpers";
import { COMPANY_KEYS, REPORT_KEYS } from "../../utils/constants/appData";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";
import { addReport, updateReport } from "../../redux/actions/reports";

const AddEditReportDialog = ({
    currentCompany,
    currentReport,
    open,
    onClose,
    onDispatchAction,
}) => {
    const _isAddEditReportDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken } = useJumboAuth();

    const [companyIsValid, setCompanyIsValid] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [fileLink, setFileLink] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        return () => {
            _isAddEditReportDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setCompanyIsValid(false);
            setName("");
            setNameError("");
            setFile(null);
            setFileError("");
            setFileLink("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (
            currentCompany &&
            currentCompany?.[COMPANY_KEYS.ID] &&
            currentCompany[COMPANY_KEYS.ID]
        ) {
            setCompanyIsValid(true);
        }
    }, [currentCompany]);

    useEffect(() => {
        if (currentReport) {
            setName(currentReport[REPORT_KEYS.NAME]);
            setFile(null);
            const fetchedFile = currentReport[REPORT_KEYS.FILE_DATA];
            if (fetchedFile) {
                setFileLink(
                    `${currentReport[REPORT_KEYS.FILE_BASE_URL]}/${
                        fetchedFile[REPORT_KEYS.FILE_PATH]
                    }`
                );
            }
        }
    }, [currentReport]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case REPORT_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case REPORT_KEYS.FILE:
                        setFileError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const nameBlurHandler = (event) => {
        const validationResult = checkNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const fileChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (files && files.length > 0) {
            const validationResult = checkReportDocumentValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setFileError(validationResult.msg);
                }
            } else {
                isValid = false;
                setFileError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setFile(files[0]);
            setFileError("");
        } else {
            setFile(null);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        if (!currentReport) {
            const fileValidationResult = checkReportDocumentValid([file]);
            if (fileValidationResult.status) {
                if (fileValidationResult.status === "false") {
                    formIsValid = false;
                    setFileError(fileValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setFileError(VALIDATION_FAILED);
            }
        }

        if (companyIsValid && formIsValid) {
            const reportData = new FormData();
            reportData.append("authcode", authToken);
            reportData.append([REPORT_KEYS.NAME], name);
            reportData.append(
                [REPORT_KEYS.COMPANY_ID],
                currentCompany?.[COMPANY_KEYS.ID] || ""
            );

            if (currentReport) {
                reportData.append(
                    [REPORT_KEYS.REPORT_ID],
                    currentReport[REPORT_KEYS.ID]
                );

                dispatch(
                    updateReport(
                        { reportData: reportData },
                        () => {
                            if (_isAddEditReportDialogMounted.current)
                                onClose();
                        },
                        (messages) => {
                            if (_isAddEditReportDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                reportData.append([REPORT_KEYS.FILE], file);

                dispatch(
                    addReport(
                        { reportData: reportData },
                        () => {
                            if (_isAddEditReportDialogMounted.current) {
                                onDispatchAction({ type: "INSERT" });
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditReportDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentReport ? "Edit Report" : "Add Report"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {companyIsValid && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={name}
                                    margin="dense"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameError("");
                                    }}
                                    onBlur={nameBlurHandler}
                                    error={nameError !== ""}
                                    helperText={nameError}
                                />
                            </Grid>
                            {!currentReport && (
                                <Grid item xs={12}>
                                    <label
                                        htmlFor="file"
                                        style={{
                                            display: "block",
                                            marginTop: 8,
                                        }}
                                    >
                                        <input
                                            id="file"
                                            name="file"
                                            type="file"
                                            onChange={fileChangeHandler}
                                            style={{ display: "none" }}
                                        />

                                        <AttachFileButton
                                            fullWidth
                                            type="button"
                                            variant="outlined"
                                            component="span"
                                            startIcon={<AttachFileIcon />}
                                            sx={(theme) => {
                                                const fileErrorSx = fileError
                                                    ? {
                                                          color: theme.palette
                                                              .error.main,
                                                          border: `1px solid ${theme.palette.error.main}`,
                                                      }
                                                    : "";
                                                return {
                                                    ...fileErrorSx,
                                                };
                                            }}
                                        >
                                            {file ? file.name : "File"}
                                        </AttachFileButton>
                                    </label>
                                    {fileError && (
                                        <FormHelperText error id="file-error">
                                            {fileError}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            )}
                            {fileLink && (
                                <Grid item xs={12}>
                                    <NameLink
                                        noWrap
                                        align="center"
                                        href={fileLink}
                                        target="_blank"
                                        sx={(theme) => ({
                                            display: "block",
                                            wordWrap: "break-word",
                                            ...theme.typography.h5,
                                        })}
                                    >
                                        View File
                                    </NameLink>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save Report
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!companyIsValid && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Company is not valid!
                            </Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditReportDialog.propTypes = {
    currentCompany: PropTypes.object,
    currentReport: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

AddEditReportDialog.defaultProps = {
    currentCompany: null,
    currentReport: null,
    open: false,
    // onClose: () => {},
    // onDispatchAction: () => {},
};

export default AddEditReportDialog;
