import {
    ADD_TICKET,
    DELETE_TICKET,
    EDIT_TICKET,
    GET_TICKET_COUNT,
    GET_TICKETS,
    SET_TICKET_DETAILS,
} from "../../utils/constants/actionTypes";
import { TICKET_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    tickets: [],
    currentTicket: null,
    ticketCounts: { [TICKET_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TICKETS: {
            return {
                ...state,
                tickets: action.payload,
            };
        }
        case GET_TICKET_COUNT: {
            return {
                ...state,
                ticketCounts: action.payload,
            };
        }
        case SET_TICKET_DETAILS: {
            return {
                ...state,
                currentTicket: action.payload,
            };
        }
        case ADD_TICKET: {
            return {
                ...state,
                tickets: [action.payload, ...state.tickets],
            };
        }
        case EDIT_TICKET: {
            return {
                ...state,
                tickets: state.tickets.map((ticket) =>
                    ticket.id === action.payload.id ? action.payload : ticket
                ),
            };
        }
        case DELETE_TICKET: {
            return {
                ...state,
                tickets: state.tickets.filter(
                    (ticket) => ticket.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
