import {
    ADD_REPORT,
    DELETE_REPORT,
    EDIT_REPORT,
    GET_REPORT_COUNT,
    GET_REPORTS,
    SET_REPORT_DETAILS,
} from "../../utils/constants/actionTypes";
import { REPORT_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    reports: [],
    currentReport: null,
    reportCounts: { [REPORT_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REPORTS: {
            return {
                ...state,
                reports: action.payload,
            };
        }
        case GET_REPORT_COUNT: {
            return {
                ...state,
                reportCounts: action.payload,
            };
        }
        case SET_REPORT_DETAILS: {
            return {
                ...state,
                currentReport: action.payload,
            };
        }
        case ADD_REPORT: {
            return {
                ...state,
                reports: [action.payload, ...state.reports],
            };
        }
        case EDIT_REPORT: {
            return {
                ...state,
                reports: state.reports.map((report) =>
                    report.id === action.payload.id ? action.payload : report
                ),
            };
        }
        case DELETE_REPORT: {
            return {
                ...state,
                reports: state.reports.filter(
                    (report) => report.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
