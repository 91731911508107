import React from "react";
import Page from "@jumbo/shared/Page";
import Error404 from "../pages/miscellaneous/Error404";

const extraRoutes = [
    {
        path: "*",
        element: <Page component={Error404} />,
    },
];

export default extraRoutes;
