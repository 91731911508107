import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import NameLink from "../../shared/widgets/NameLink";
import { getInitialsFromName } from "../../utils/appHelpers";
import { COMPANY_KEYS, FM_USER_KEYS } from "../../utils/constants/appData";

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    padding: theme.spacing(1, 0),
    fontSize: theme.typography.h5.fontSize,
}));

const CompaniesCard = ({ allCompanies, fmUser }) => {
    const [fmUserCompanies, setFmUserCompanies] = useState([]);

    useEffect(() => {
        if (allCompanies && allCompanies.length > 0 && fmUser) {
            const assignedCompanies = fmUser[FM_USER_KEYS.ASSIGNED_COMPANIES];
            const assignedCompaniesArray = assignedCompanies
                ? assignedCompanies.split(",")
                : [];
            if (assignedCompaniesArray && assignedCompaniesArray.length > 0) {
                setFmUserCompanies(
                    allCompanies.filter((companyObj) =>
                        assignedCompaniesArray.includes(
                            companyObj[COMPANY_KEYS.ID]
                        )
                    )
                );
            }
        }
    }, [allCompanies, fmUser]);

    return (
        <Card
            sx={(theme) => ({
                padding: theme.spacing(1, 1),
                boxShadow: theme.shadows[4],
            })}
        >
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography
                            component="h3"
                            variant="h3"
                            align="center"
                            sx={{
                                textTransform: "uppercase",
                                fontWeight: (theme) =>
                                    theme.typography.fontWeightBold,
                            }}
                        >
                            Companies
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {fmUserCompanies && fmUserCompanies.length > 0 ? (
                                fmUserCompanies.map(
                                    (fmUserCompany, fmUserCompanyIdx) => {
                                        const fmUserCompanyName =
                                            fmUserCompany[COMPANY_KEYS.NAME];
                                        const fmUserCompanyEmail =
                                            fmUserCompany[COMPANY_KEYS.EMAIL];

                                        const fmUserCompanyPhoto =
                                            fmUserCompany[
                                                COMPANY_KEYS.LOGO_PIC
                                            ];
                                        let fmUserPhotoLink = "";
                                        if (fmUserCompanyPhoto) {
                                            fmUserPhotoLink = `${
                                                fmUserCompany[
                                                    COMPANY_KEYS.LOGO_BASE_URL
                                                ]
                                            }/${
                                                fmUserCompanyPhoto[
                                                    COMPANY_KEYS.LOGO_FILE_PATH
                                                ]
                                            }`;
                                        }
                                        const fmUserCompanyInitials =
                                            fmUserPhotoLink ? (
                                                <React.Fragment>
                                                    &nbsp;
                                                </React.Fragment>
                                            ) : (
                                                getInitialsFromName(
                                                    fmUserCompanyName
                                                )
                                            );

                                        return (
                                            <React.Fragment
                                                key={`fm-user-company-${fmUserCompanyIdx}`}
                                            >
                                                {fmUserCompanyIdx === 0 && (
                                                    <Divider component="li" />
                                                )}
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            src={
                                                                fmUserPhotoLink
                                                            }
                                                            sizes={"small"}
                                                            alt={
                                                                fmUserCompanyName
                                                            }
                                                            sx={{
                                                                bgcolor:
                                                                    "#487AA3",
                                                            }}
                                                        >
                                                            {
                                                                fmUserCompanyInitials
                                                            }
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <StyledListItemText
                                                        primary={
                                                            <NameLink
                                                                key={`fm-company-${fmUserCompanyIdx}`}
                                                                variant="h5"
                                                                component={
                                                                    RouterLink
                                                                }
                                                                to={`/company/${
                                                                    fmUserCompany[
                                                                        COMPANY_KEYS
                                                                            .ID
                                                                    ]
                                                                }/dashboard/`}
                                                            >
                                                                {
                                                                    fmUserCompanyName
                                                                }
                                                            </NameLink>
                                                        }
                                                        secondary={
                                                            <NameLink
                                                                variant="h6"
                                                                href={`mailto:${fmUserCompanyEmail}`}
                                                            >
                                                                {
                                                                    fmUserCompanyEmail
                                                                }
                                                            </NameLink>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider component="li" />
                                            </React.Fragment>
                                        );
                                    }
                                )
                            ) : (
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Alert severity="info">
                                                No companies found!
                                            </Alert>
                                        }
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

CompaniesCard.propTypes = {
    allCompanies: PropTypes.array,
    fmUser: PropTypes.object,
};

CompaniesCard.defaultProps = {
    allCompanies: [],
    fmUser: null,
};

export default CompaniesCard;
