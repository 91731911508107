import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import EmptyValue from "../../shared/widgets/EmptyValue";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    CLIENT_USER_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const StyledOCButton = styled(Button)(({ theme }) => ({
    width: "25%",
    fontSize: theme.typography.body1.fontSize,
}));

const headCells = [
    {
        id: CLIENT_USER_KEYS.NAME,
        label: "Office Contacts",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "40%",
    },
    {
        id: CLIENT_USER_KEYS.ROLE,
        label: "Position",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "15%",
    },
];

const OfficeContactsTable = ({
    canArchiveOC,
    canDeleteOC,
    canEditOC,
    canSendMessageOC,
    officeContacts,
    officeContactCounts,
    officeContactsFetched,
    order,
    page,
    rowsPerPage,
    sort,
    showPagination,
    handlePageChange,
    handleRowsPerPageChange,
    onArchive,
    onDelete,
    onEdit,
    onSendMessage,
    onSort,
}) => {
    const isResponsiveMode = useMediaQuery("(max-width:1200px)");

    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const officeContactsTotalCount =
        officeContactCounts &&
        officeContactCounts[CLIENT_USER_KEYS.TOTAL] &&
        !isNaN(parseInt(officeContactCounts[CLIENT_USER_KEYS.TOTAL])) &&
        parseInt(officeContactCounts[CLIENT_USER_KEYS.TOTAL]) > 0
            ? parseInt(officeContactCounts[CLIENT_USER_KEYS.TOTAL])
            : 0;

    const fetchedOfficeContactsCount =
        officeContacts && officeContacts.length > 0 ? officeContacts.length : 0;

    const officeContactCountNonZero =
        fetchedOfficeContactsCount > 0 && officeContactsTotalCount > 0;

    return (
        <React.Fragment>
            <TableContainer
                component={Paper}
                elevation={4}
                sx={{ borderRadius: "10px" }}
            >
                <Table aria-label="Office Contacts Table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const sx = {};
                                if (headCell?.width) {
                                    sx.width = headCell.width;
                                }

                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id} sx={{ ...sx }}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            <TableCell key="actions" sx={{ width: "45%" }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {officeContactCountNonZero ? (
                            officeContacts.map((officeContact) => {
                                const officeContactId =
                                    officeContact[CLIENT_USER_KEYS.ID];

                                const officeContactName =
                                    officeContact[CLIENT_USER_KEYS.NAME];

                                const officeContactRole =
                                    officeContact?.[CLIENT_USER_KEYS.ROLE] ||
                                    "";

                                const fetchedPhoto =
                                    officeContact[CLIENT_USER_KEYS.PROFILE_PIC];
                                let officeContactPhotoLink = "";
                                if (fetchedPhoto) {
                                    officeContactPhotoLink = `${
                                        officeContact[
                                            CLIENT_USER_KEYS.PHOTO_BASE_URL
                                        ]
                                    }/${
                                        fetchedPhoto[
                                            CLIENT_USER_KEYS.PHOTO_FILE_PATH
                                        ]
                                    }`;
                                }
                                const officeContactInitials =
                                    officeContactPhotoLink ? (
                                        <React.Fragment>&nbsp;</React.Fragment>
                                    ) : (
                                        getInitialsFromName(officeContactName)
                                    );

                                return (
                                    <TableRow
                                        key={officeContactId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1.25}
                                            >
                                                <Avatar
                                                    src={officeContactPhotoLink}
                                                    sizes={"small"}
                                                    alt={officeContactName}
                                                    sx={{
                                                        bgcolor: "#487AA3",
                                                    }}
                                                >
                                                    {officeContactInitials}
                                                </Avatar>
                                                <Typography
                                                    component="span"
                                                    fontWeight="bolder"
                                                >
                                                    <NameLink
                                                        component={RouterLink}
                                                        to={`/clientUser/${officeContactId}/overview/`}
                                                    >
                                                        {officeContactName}
                                                    </NameLink>
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {officeContactRole ? (
                                                officeContactRole
                                            ) : (
                                                <EmptyValue />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {isResponsiveMode ? (
                                                <Stack
                                                    direction="row"
                                                    flexWrap="nowrap"
                                                    alignItems="center"
                                                    justifyContent="start"
                                                >
                                                    {canSendMessageOC && (
                                                        <TableActionIcon
                                                            iconComponent="send"
                                                            label="message office contact"
                                                            tooltip="Message Office Contact"
                                                            onClick={() =>
                                                                onSendMessage(
                                                                    officeContact
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {canEditOC && (
                                                        <TableActionIcon
                                                            iconComponent="edit"
                                                            label="edit office contact"
                                                            tooltip="Edit Office Contact"
                                                            onClick={() =>
                                                                onEdit(
                                                                    officeContact
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {canArchiveOC && (
                                                        <TableActionIcon
                                                            iconComponent="archive"
                                                            label="archive office contact"
                                                            tooltip="Archive Office Contact"
                                                            onClick={() =>
                                                                onArchive(
                                                                    officeContact
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {canDeleteOC && (
                                                        <TableActionIcon
                                                            iconComponent="delete"
                                                            label="delete office contact"
                                                            tooltip="Delete Office Contact"
                                                            onClick={() =>
                                                                onDelete(
                                                                    officeContact
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Stack>
                                            ) : (
                                                <Stack
                                                    direction={{
                                                        xs: "column",
                                                        md: "row",
                                                    }}
                                                    flexWrap="wrap"
                                                    alignItems="center"
                                                    justifyContent="start"
                                                    spacing={1}
                                                >
                                                    {canSendMessageOC && (
                                                        <StyledOCButton
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            className="officeContactBtn"
                                                            onClick={() =>
                                                                onSendMessage(
                                                                    officeContact
                                                                )
                                                            }
                                                        >
                                                            Message
                                                        </StyledOCButton>
                                                    )}
                                                    {canEditOC && (
                                                        <StyledOCButton
                                                            type="button"
                                                            variant="outlined"
                                                            color="primary"
                                                            className="officeContactBtn"
                                                            onClick={() =>
                                                                onEdit(
                                                                    officeContact
                                                                )
                                                            }
                                                        >
                                                            Edit User
                                                        </StyledOCButton>
                                                    )}
                                                    {canArchiveOC && (
                                                        <StyledOCButton
                                                            type="button"
                                                            variant="outlined"
                                                            color="error"
                                                            className="officeContactBtn"
                                                            onClick={() =>
                                                                onArchive(
                                                                    officeContact
                                                                )
                                                            }
                                                        >
                                                            Archive User
                                                        </StyledOCButton>
                                                    )}
                                                    {canDeleteOC && (
                                                        <StyledOCButton
                                                            type="button"
                                                            variant="outlined"
                                                            color="error"
                                                            className="officeContactBtn"
                                                            onClick={() =>
                                                                onDelete(
                                                                    officeContact
                                                                )
                                                            }
                                                        >
                                                            Delete User
                                                        </StyledOCButton>
                                                    )}
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            officeContactsFetched
                                                ? "error"
                                                : "info"
                                        }
                                    >
                                        {officeContactsFetched
                                            ? "There are no records found."
                                            : "Loading office contacts..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {showPagination && (
                <TablePagination
                    rowsPerPageOptions={PAGINATION_PAGES}
                    component="div"
                    count={
                        officeContactCountNonZero
                            ? parseInt(
                                  officeContactCounts[CLIENT_USER_KEYS.TOTAL]
                              )
                            : 0
                    }
                    labelRowsPerPage=""
                    rowsPerPage={rowsPerPage}
                    page={officeContactCountNonZero ? page : 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            )}
        </React.Fragment>
    );
};

OfficeContactsTable.propTypes = {
    canArchiveOC: PropTypes.bool,
    canDeleteOC: PropTypes.bool,
    canEditOC: PropTypes.bool,
    canSendMessageOC: PropTypes.bool,
    officeContacts: PropTypes.array,
    officeContactCounts: PropTypes.object,
    officeContactsFetched: PropTypes.bool,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    showPagination: PropTypes.bool,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onSendMessage: PropTypes.func,
    onSort: PropTypes.func,
};

OfficeContactsTable.defaultProps = {
    canArchiveOC: false,
    canDeleteOC: false,
    canEditOC: false,
    canSendMessageOC: false,
    officeContacts: [],
    officeContactCounts: null,
    officeContactsFetched: false,
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    showPagination: false,
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onArchive: () => {}
    // onDelete: () => {}
    // onEdit: () => {}
    // onSendMessage: () => {}
    // onSort: () => {},
};

export default OfficeContactsTable;
