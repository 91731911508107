import React from "react";
/* import { useTranslation } from "react-i18next"; */
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import WidgetsIcon from "@mui/icons-material/Widgets";
import styled from "@mui/material/styles/styled";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import {
    SIDEBAR_ITEM_ACTIVE,
    SIDEBAR_ITEM_KEYS,
    SIDEBAR_ITEM_NEW_TAB_YES,
    SIDEBAR_ITEM_URL_EXTERNAL,
} from "../../../../utils/constants/appData";
import muiIconsList from "../../../../utils/constants/muiIconsList";

const StyledLink = styled(Link)(({ theme }) => ({
    flex: 1,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    color: "inherit",
}));

const menuBefore = {
    left: 0,
    top: 0,
    content: `''`,
    position: "absolute",
    display: "inline-block",
    width: "4px",
    height: "100%",
    backgroundColor: "transparent",
};

const CustomNavItem = ({ item, isNested /* , translate */ }) => {
    const location = useLocation();
    const { sidebarOptions } = useJumboLayoutSidebar();
    /* const { t } = useTranslation(); */

    const isMiniAndClosed = React.useMemo(() => {
        return (
            sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open
        );
    }, [sidebarOptions.view, sidebarOptions.open]);

    /* const itemLabel = item[SIDEBAR_ITEM_KEYS.LABEL]; */
    const label =
        /* React.useMemo(() => {
        return translate ? t(itemLabel) : itemLabel;
    }, [itemLabel, translate, t]) */ item[SIDEBAR_ITEM_KEYS.LABEL];

    if (!item) return null;

    const itemActive = item[SIDEBAR_ITEM_KEYS.IS_ACTIVE];
    if (itemActive !== SIDEBAR_ITEM_ACTIVE) return null;

    let IconComponent = WidgetsIcon;
    const itemIcon = item[SIDEBAR_ITEM_KEYS.ICON];
    if (itemIcon) {
        const iconObj = muiIconsList.find(
            (tempMenuObj) => tempMenuObj.label === itemIcon
        );
        if (iconObj && iconObj?.icon) {
            IconComponent = iconObj.icon;
        }
    }

    const itemUrl = item[SIDEBAR_ITEM_KEYS.URL];
    const isUrlExternal =
        item[SIDEBAR_ITEM_KEYS.IS_URL_EXTERNAL] === SIDEBAR_ITEM_URL_EXTERNAL;
    const openInNewTab =
        item[SIDEBAR_ITEM_KEYS.OPENS_IN_NEW_TAB] === SIDEBAR_ITEM_NEW_TAB_YES;

    const linkContent = (
        <React.Fragment>
            <ListItemIcon
                sx={{
                    minWidth: isMiniAndClosed ? 20 : 32,
                    ...(location.pathname === itemUrl
                        ? {
                              color: "inherit",
                          }
                        : {
                              color: (theme) => theme.palette.text.secondary,
                          }),
                }}
            >
                {isNested ? (
                    <CircleIcon sx={{ fontSize: 6, ml: 1 }} />
                ) : (
                    <IconComponent sx={{ fontSize: 28 }} />
                )}
            </ListItemIcon>
            {!isMiniAndClosed && (
                <ListItemText
                    primary={label}
                    sx={{
                        m: 0,
                        ml: 1.5,
                        "& .MuiTypography-root": {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: 14,
                        },
                    }}
                />
            )}
        </React.Fragment>
    );

    let linkEl = null;
    if (isUrlExternal) {
        linkEl = (
            <StyledLink
                underline={"none"}
                href={itemUrl}
                target={openInNewTab ? "_blank" : "_self"}
                sx={{
                    p: (theme) => (!isMiniAndClosed ? theme.spacing(1, 3) : 0),
                    ...(isMiniAndClosed ? { justifyContent: "center" } : {}),
                }}
            >
                {linkContent}
            </StyledLink>
        );
    } else {
        linkEl = (
            <StyledLink
                underline={"none"}
                component={RouterLink}
                to={itemUrl}
                target={openInNewTab ? "_blank" : "_self"}
                sx={{
                    p: (theme) => (!isMiniAndClosed ? theme.spacing(1, 3) : 0),
                    ...(isMiniAndClosed ? { justifyContent: "center" } : {}),
                }}
            >
                {linkContent}
            </StyledLink>
        );
    }

    return (
        <ListItemButton
            component={"li"}
            sx={{
                overflow: "hidden",
                borderRadius: "0",
                margin: isMiniAndClosed ? "0 auto" : "0",
                color: (theme) => theme.palette.text.primary,
                ...(isMiniAndClosed
                    ? {
                          width: 40,
                          height: 40,
                          justifyContent: "center",
                          p: 0,
                          py: 4,
                      }
                    : { p: 1.45 }),
                ...(!isMiniAndClosed ? { "&::before": menuBefore } : {}),
                "&:hover": {
                    color: (theme) => theme.palette.nav.action.hover,
                    backgroundColor: (theme) =>
                        theme.palette.nav.background.hover,
                    ...(!isMiniAndClosed
                        ? {
                              "&::before": {
                                  ...menuBefore,
                                  backgroundColor: (theme) =>
                                      theme.palette.nav.tick.hover,
                              },
                          }
                        : {}),
                },
                ...(location.pathname === itemUrl
                    ? {
                          color: (theme) => theme.palette.nav.action.active,
                          backgroundColor: (theme) =>
                              theme.palette.nav.background.active,
                          ...(!isMiniAndClosed
                              ? {
                                    "&::before": {
                                        ...menuBefore,
                                        backgroundColor: (theme) =>
                                            theme.palette.nav.tick.active,
                                    },
                                }
                              : {}),
                      }
                    : {}),
            }}
        >
            {isMiniAndClosed ? (
                <Tooltip title={label}>{linkEl}</Tooltip>
            ) : (
                linkEl
            )}
        </ListItemButton>
    );
};

export default CustomNavItem;
