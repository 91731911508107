import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    addSettingItem,
    updateSettingItem,
} from "../../redux/actions/sidebarSettings";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import { getUserTypesList } from "../../utils/appHelpers";
import {
    checkAssignedIconValid,
    checkAssignedSidebarUrlTypeValid,
    checkLabelValid,
    checkSidebarIsActiveValid,
    checkSidebarOpensInNewTabValid,
    checkSidebarUrlValid,
} from "../../utils/validationHelpers";
import {
    SIDEBAR_ITEM_ACTIVE,
    SIDEBAR_ITEM_NOT_ACTIVE,
    SIDEBAR_ITEM_DEFAULT,
    SIDEBAR_ITEM_KEYS,
    SIDEBAR_ITEM_NEW_TAB_NO,
    SIDEBAR_ITEM_NEW_TAB_YES,
    SIDEBAR_ITEM_URL_EXTERNAL,
    SIDEBAR_ITEM_URL_INTERNAL,
} from "../../utils/constants/appData";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";
import muiIconsList from "../../utils/constants/muiIconsList";

const USER_TYPES = getUserTypesList();

const AddEditItemDialog = ({
    currentItem,
    currentUserTypeId,
    fetchOnInsert,
    open,
    onClose,
    onDispatchAction,
}) => {
    const _isAddEditItemDialogMounted = useRef(true);
    const isInitialRender = useRef(true);
    const dispatch = useDispatch();
    const { authToken } = useJumboAuth();

    const [currentUserTypeValid, setCurrentUserTypeValid] = useState(false);
    const [currentItemUserTypeLabel, setCurrentUserTypeLabel] = useState("");
    const [label, setLabel] = useState("");
    const [labelError, setLabelError] = useState("");
    const [icon, setIcon] = useState(null);
    const [iconError, setIconError] = useState("");
    const [url, setUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const [isActive, setIsActive] = useState(SIDEBAR_ITEM_ACTIVE);
    const [isActiveError, setIsActiveError] = useState("");
    const [isUrlExternal, setIsUrlExternal] = useState(
        SIDEBAR_ITEM_URL_INTERNAL
    );
    const [isUrlExternalError, setIsUrlExternalError] = useState("");
    const [opensInNewTab, setOpensInNewTab] = useState(SIDEBAR_ITEM_NEW_TAB_NO);
    const [opensInNewTabError, setOpensInNewTabError] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        return () => {
            _isAddEditItemDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setCurrentUserTypeValid(false);
            setCurrentUserTypeLabel("");
            setLabel("");
            setLabelError("");
            setIcon(null);
            setIconError("");
            setUrl("");
            setUrlError("");
            setIsActive(SIDEBAR_ITEM_ACTIVE);
            setIsActiveError("");
            setIsUrlExternal(SIDEBAR_ITEM_URL_INTERNAL);
            setIsUrlExternalError("");
            setOpensInNewTab(SIDEBAR_ITEM_NEW_TAB_NO);
            setOpensInNewTabError("");
            setIsDefault(false);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (currentUserTypeId) {
            const userTypeObj = USER_TYPES.find(
                (tempUserTypeObj) => tempUserTypeObj.value === currentUserTypeId
            );
            if (userTypeObj && userTypeObj?.value) {
                if (currentItem) {
                    const currentItemUserTypeId =
                        currentItem[SIDEBAR_ITEM_KEYS.USER_TYPE_ID];
                    if (
                        currentItemUserTypeId &&
                        currentItemUserTypeId === currentUserTypeId
                    ) {
                        setCurrentUserTypeValid(true);
                    } else {
                        setCurrentUserTypeValid(false);
                    }
                } else {
                    setCurrentUserTypeValid(true);
                }
            }
        }
    }, [currentItem, currentUserTypeId]);

    useEffect(() => {
        if (currentUserTypeValid) {
            const userTypeObj = USER_TYPES.find(
                (tempUserTypeObj) => tempUserTypeObj.value === currentUserTypeId
            );
            setCurrentUserTypeLabel(userTypeObj.label);
        }
    }, [currentUserTypeId, currentUserTypeValid]);

    useEffect(() => {
        if (currentItem && currentUserTypeValid) {
            setLabel(currentItem[SIDEBAR_ITEM_KEYS.LABEL]);
            setUrl(currentItem[SIDEBAR_ITEM_KEYS.URL]);
            setIsActive(currentItem[SIDEBAR_ITEM_KEYS.IS_ACTIVE]);
            setIsUrlExternal(currentItem[SIDEBAR_ITEM_KEYS.IS_URL_EXTERNAL]);
            setOpensInNewTab(currentItem[SIDEBAR_ITEM_KEYS.OPENS_IN_NEW_TAB]);
            setIsDefault(
                currentItem[SIDEBAR_ITEM_KEYS.IS_DEFAULT] ===
                    SIDEBAR_ITEM_DEFAULT
            );

            const itemIconLabel = currentItem[SIDEBAR_ITEM_KEYS.ICON];
            if (itemIconLabel) {
                const iconObj = muiIconsList.find(
                    (tempIconObj) => tempIconObj.label === itemIconLabel
                );
                setIcon(iconObj || null);
            } else {
                setIcon(null);
            }
        }
    }, [currentItem, currentUserTypeValid]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case SIDEBAR_ITEM_KEYS.LABEL:
                        setLabelError(msg);
                        break;

                    case SIDEBAR_ITEM_KEYS.ICON:
                        setIconError(msg);
                        break;

                    case SIDEBAR_ITEM_KEYS.URL:
                        setUrlError(msg);
                        break;

                    case SIDEBAR_ITEM_KEYS.IS_ACTIVE:
                        setIsActiveError(msg);
                        break;

                    case SIDEBAR_ITEM_KEYS.IS_URL_EXTERNAL:
                        setIsUrlExternalError(msg);
                        break;

                    case SIDEBAR_ITEM_KEYS.OPENS_IN_NEW_TAB:
                        setOpensInNewTab(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        if (!isUrlExternalError) {
            setUrlError("");
            const urlValidationResult = checkSidebarUrlValid(
                url,
                isUrlExternal === SIDEBAR_ITEM_URL_EXTERNAL
            );
            if (urlValidationResult.status) {
                if (urlValidationResult.status === "false") {
                    setUrlError(urlValidationResult.msg);
                }
            } else {
                setUrlError(VALIDATION_FAILED);
            }
        }
    }, [isUrlExternal, isUrlExternalError, url]);

    const labelBlurHandler = (event) => {
        const validationResult = checkLabelValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setLabelError(validationResult.msg);
            }
        } else {
            setLabelError(VALIDATION_FAILED);
        }
    };

    const iconChangeHandler = (event, newValue) => {
        setIconError("");

        const iconValidationResult = checkAssignedIconValid(
            newValue,
            muiIconsList
        );
        if (iconValidationResult.status) {
            if (iconValidationResult.status === "false") {
                setIcon(null);
                setIconError(iconValidationResult.msg);
            } else {
                setIcon(newValue);
            }
        } else {
            setIcon(null);
            setIconError(VALIDATION_FAILED);
        }
    };

    const isActiveChangeHandler = (event) => {
        setIsActiveError("");
        setIsActive(event.target.value);
    };

    const isUrlExternalChangeHandler = (event) => {
        setIsUrlExternalError("");
        setIsUrlExternal(event.target.value);
    };

    const opensInNewTabChangeHandler = (event) => {
        setOpensInNewTabError("");
        setOpensInNewTab(event.target.value);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const labelValidationResult = checkLabelValid(label);
        if (labelValidationResult.status) {
            if (labelValidationResult.status === "false") {
                formIsValid = false;
                setLabelError(labelValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setLabelError(VALIDATION_FAILED);
        }

        const iconValidationResult = checkAssignedIconValid(icon, muiIconsList);
        if (iconValidationResult.status) {
            if (iconValidationResult.status === "false") {
                formIsValid = false;
                setIconError(iconValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setIconError(VALIDATION_FAILED);
        }

        const isActiveValidationResult = checkSidebarIsActiveValid(isActive, [
            SIDEBAR_ITEM_ACTIVE,
            SIDEBAR_ITEM_NOT_ACTIVE,
        ]);
        if (isActiveValidationResult.status) {
            if (isActiveValidationResult.status === "false") {
                formIsValid = false;
                setIsActiveError(isActiveValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setIsActiveError(VALIDATION_FAILED);
        }

        let typeIsValid = true;
        const typeValidationResult = checkAssignedSidebarUrlTypeValid(
            isUrlExternal,
            [SIDEBAR_ITEM_URL_EXTERNAL, SIDEBAR_ITEM_URL_INTERNAL]
        );
        if (typeValidationResult.status) {
            if (typeValidationResult.status === "false") {
                typeIsValid = false;
                setIsUrlExternalError(typeValidationResult.msg);
            }
        } else {
            typeIsValid = false;
            setIsUrlExternalError(VALIDATION_FAILED);
        }

        if (typeIsValid) {
            const urlValidationResult = checkSidebarUrlValid(
                url,
                isUrlExternal === SIDEBAR_ITEM_URL_EXTERNAL
            );
            if (urlValidationResult.status) {
                if (urlValidationResult.status === "false") {
                    formIsValid = false;
                    setUrlError(urlValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setUrlError(VALIDATION_FAILED);
            }
        } else {
            formIsValid = false;
        }

        const opensInNewTabValidationResult = checkSidebarOpensInNewTabValid(
            opensInNewTab,
            [SIDEBAR_ITEM_NEW_TAB_YES, SIDEBAR_ITEM_NEW_TAB_NO]
        );
        if (opensInNewTabValidationResult.status) {
            if (opensInNewTabValidationResult.status === "false") {
                formIsValid = false;
                setOpensInNewTabError(opensInNewTabValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setOpensInNewTabError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const itemData = {
                authcode: authToken,
                [SIDEBAR_ITEM_KEYS.USER_TYPE_ID]: currentUserTypeId,
                [SIDEBAR_ITEM_KEYS.LABEL]: label,
                [SIDEBAR_ITEM_KEYS.ICON]: icon?.label || "",
                [SIDEBAR_ITEM_KEYS.IS_ACTIVE]: isActive,
            };

            if (!isDefault) {
                itemData[SIDEBAR_ITEM_KEYS.URL] = url;
                itemData[SIDEBAR_ITEM_KEYS.IS_URL_EXTERNAL] = isUrlExternal;
                itemData[SIDEBAR_ITEM_KEYS.OPENS_IN_NEW_TAB] = opensInNewTab;
            }

            if (currentItem) {
                itemData[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID] =
                    currentItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID];

                dispatch(
                    updateSettingItem(
                        { itemData: itemData },
                        (updatedItem) => {
                            if (_isAddEditItemDialogMounted.current) {
                                onDispatchAction({
                                    type: "UPDATE",
                                    updatedItem: updatedItem,
                                });
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditItemDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                dispatch(
                    addSettingItem(
                        { itemData: itemData },
                        () => {
                            if (_isAddEditItemDialogMounted.current) {
                                if (fetchOnInsert)
                                    onDispatchAction({ type: "INSERT" });
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditItemDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={`${currentItem ? "Edit Item" : "Add Item"} ${
                    currentUserTypeValid
                        ? `for ${currentItemUserTypeLabel}`
                        : ""
                }`}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {currentUserTypeValid && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="label"
                                    name="label"
                                    label="Label"
                                    value={label}
                                    margin="dense"
                                    onChange={(e) => {
                                        setLabel(e.target.value);
                                        setLabelError("");
                                    }}
                                    onBlur={labelBlurHandler}
                                    error={labelError !== ""}
                                    helperText={labelError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        filterSelectedOptions
                                        id="icon"
                                        options={muiIconsList}
                                        value={icon}
                                        isOptionEqualToValue={(
                                            option,
                                            newValue
                                        ) => {
                                            return (
                                                option.label === newValue.label
                                            );
                                        }}
                                        renderInput={(params) => {
                                            const iconLabel =
                                                params?.inputProps &&
                                                params?.inputProps?.value
                                                    ? params.inputProps.value
                                                    : "";

                                            const iconObj = muiIconsList.find(
                                                (tempIconObj) =>
                                                    tempIconObj.label ===
                                                    iconLabel
                                            );

                                            const IconComponent =
                                                iconObj && iconObj?.icon
                                                    ? iconObj.icon
                                                    : null;

                                            return (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Icon"
                                                    error={iconError !== ""}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment:
                                                            IconComponent ? (
                                                                <InputAdornment position="start">
                                                                    <IconComponent />
                                                                </InputAdornment>
                                                            ) : null,
                                                    }}
                                                />
                                            );
                                        }}
                                        renderOption={(props, option) => {
                                            const IconComponent =
                                                option && option?.icon
                                                    ? option.icon
                                                    : null;
                                            if (!IconComponent) return null;

                                            return (
                                                <li
                                                    {...props}
                                                    key={option.label}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <IconComponent />
                                                        <Typography
                                                            component="span"
                                                            sx={{ ml: 1 }}
                                                        >
                                                            {option.label}
                                                        </Typography>
                                                    </Box>
                                                </li>
                                            );
                                        }}
                                        onChange={iconChangeHandler}
                                    />
                                    {iconError && (
                                        <FormHelperText error id="icon-error">
                                            {iconError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel id="is-active-group-label">
                                        Is this item visible to{" "}
                                        {currentItemUserTypeLabel}?
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="is-active-group-label"
                                        name="is-active-groups"
                                        value={isActive}
                                        onChange={isActiveChangeHandler}
                                    >
                                        <FormControlLabel
                                            value={SIDEBAR_ITEM_ACTIVE}
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value={SIDEBAR_ITEM_NOT_ACTIVE}
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                    {isActiveError && (
                                        <FormHelperText
                                            error
                                            id="is-active-group-error"
                                        >
                                            {isActiveError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {!isDefault && (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            id="url"
                                            name="url"
                                            label="URL"
                                            value={url}
                                            margin="dense"
                                            onChange={(e) => {
                                                setUrl(e.target.value);
                                                setUrlError("");
                                            }}
                                            error={urlError !== ""}
                                            helperText={urlError}
                                        />
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            <FormLabel id="is-url-external-group-label">
                                                <Typography component="p">
                                                    Does the URL link to a page
                                                    outside of the app?
                                                </Typography>
                                                <Alert severity="info">
                                                    Note: Internal links must
                                                    begin with a forward slash (
                                                    <code>/</code>). Example:{" "}
                                                    <code>/companies</code>
                                                </Alert>
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="is-url-external-group-label"
                                                name="is-url-external-groups"
                                                value={isUrlExternal}
                                                onChange={
                                                    isUrlExternalChangeHandler
                                                }
                                            >
                                                <FormControlLabel
                                                    value={
                                                        SIDEBAR_ITEM_URL_EXTERNAL
                                                    }
                                                    control={<Radio />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value={
                                                        SIDEBAR_ITEM_URL_INTERNAL
                                                    }
                                                    control={<Radio />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                            {isUrlExternalError && (
                                                <FormHelperText
                                                    error
                                                    id="is-url-external-group-error"
                                                >
                                                    {isUrlExternalError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel id="opens-in-new-tab-group-label">
                                                Does the URL open in a new tab?
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="opens-in-new-tab-group-label"
                                                name="opens-in-new-tab-groups"
                                                value={opensInNewTab}
                                                onChange={
                                                    opensInNewTabChangeHandler
                                                }
                                            >
                                                <FormControlLabel
                                                    value={
                                                        SIDEBAR_ITEM_NEW_TAB_YES
                                                    }
                                                    control={<Radio />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value={
                                                        SIDEBAR_ITEM_NEW_TAB_NO
                                                    }
                                                    control={<Radio />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                            {opensInNewTabError && (
                                                <FormHelperText
                                                    error
                                                    id="opens-in-new-tab-error"
                                                >
                                                    {opensInNewTabError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {currentItem
                                        ? "Update Item"
                                        : "Create Item"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!currentUserTypeValid && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid user type!
                            </Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditItemDialog.propTypes = {
    currentItem: PropTypes.object,
    currentUserTypeId: PropTypes.string,
    fetchOnInsert: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

AddEditItemDialog.defaultProps = {
    currentItem: null,
    currentUserTypeId: "",
    fetchOnInsert: false,
    open: false,
    // onClose: () => {},
    onDispatchAction: () => {},
};

export default AddEditItemDialog;
