import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { capitalizeFLetter } from "@jumbo/utils";
import {
    addAccountAsset,
    updateAccountAsset,
} from "../../redux/actions/accountAssets";
import { getAllAssetTypesLocalState } from "../../redux/actions/assetTypes";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import NameLink from "../../shared/widgets/NameLink";
import {
    getAssetFileNameFromLink,
    isAccountAssetFileEditable,
} from "../../utils/accountAssetHelpers";
import {
    ACCOUNT_ASSET_KEYS,
    ACCOUNT_ASSET_TYPE_LOGO,
    ASSET_TYPE_KEYS,
    AUTH_USER_KEYS,
    FOLDER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import {
    checkAccountAssetTypeValid,
    checkAccountAssetValid,
    checkDocumentNameValid,
} from "../../utils/validationHelpers";

const AddEditFileDialog = ({
    currentAccountAsset,
    folder,
    open,
    onClose,
    onAddFile,
    onUpdateFile,
}) => {
    const _isAddEditFileDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [folderIsValid, setFolderIsValid] = useState(false);
    const [assetTypeNames, setAssetTypeNames] = useState([]);
    const [documentName, setDocumentName] = useState("");
    const [documentNameError, setDocumentNameError] = useState("");
    const [documentType, setDocumentType] = useState(ACCOUNT_ASSET_TYPE_LOGO);
    const [documentTypeError, setDocumentTypeError] = useState("");
    const [document, setDocument] = useState(null);
    const [documentError, setDocumentError] = useState("");
    const [documentLink, setDocumentLink] = useState("");
    const [canUploadDocument, setCanUploadDocument] = useState(false);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserId =
        authUser && authUser?.[AUTH_USER_KEYS.ID] && authUser[AUTH_USER_KEYS.ID]
            ? authUser[AUTH_USER_KEYS.ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    useEffect(() => {
        return () => {
            _isAddEditFileDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setIsLoading(false);
            setFolderIsValid(false);
            setAssetTypeNames([]);
            setDocumentName("");
            setDocumentNameError("");
            setDocumentType("");
            setDocumentTypeError("");
            setDocument(null);
            setDocumentError("");
            setDocumentLink("");
            setCanUploadDocument(false);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    /* Check folder is valid */
    useEffect(() => {
        if (isValidUserType) {
            if (folder && folder?.[FOLDER_KEYS.ID] && folder[FOLDER_KEYS.ID]) {
                setFolderIsValid(true);
            }
        }
    }, [folder, isValidUserType]);

    /* Start loading if folder is valid */
    useEffect(() => {
        if (folderIsValid) {
            setIsLoading(true);
        }
    }, [folderIsValid]);

    /* Fetch all asset types */
    useEffect(() => {
        let isActive = true;

        if (isLoading) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllAssetTypesLocalState(
                            payload,
                            (fetchedAssetTypes) => {
                                if (isActive) {
                                    if (
                                        fetchedAssetTypes &&
                                        fetchedAssetTypes.length > 0
                                    ) {
                                        setAssetTypeNames(
                                            fetchedAssetTypes.map(
                                                (fetchedAssetTypeObj) =>
                                                    fetchedAssetTypeObj[
                                                        ASSET_TYPE_KEYS.NAME
                                                    ]
                                            )
                                        );
                                    } else {
                                        setAssetTypeNames([]);
                                    }
                                    setIsLoading(false);
                                }
                            }
                        )
                    );
                };
            };

            const promise = dispatch(
                fetchData({
                    assetTypeData: {
                        authcode: authToken,
                    },
                    fetchStart: true,
                })
            );
            promise.catch((error) => {
                if (isActive) {
                    setAssetTypeNames([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, isLoading]);

    /* Set current asset data */
    useEffect(() => {
        if (currentAccountAsset) {
            setDocumentName(
                currentAccountAsset[ACCOUNT_ASSET_KEYS.DOCUMENT_NAME]
            );
            setDocumentType(
                currentAccountAsset?.[ACCOUNT_ASSET_KEYS.DOCUMENT_TYPE] || ""
            );
            setDocument(null);
            setDocumentLink(
                currentAccountAsset[ACCOUNT_ASSET_KEYS.DOCUMENT_LINK]
            );
            setCanUploadDocument(
                isAccountAssetFileEditable(
                    authUserId,
                    authUserType,
                    currentAccountAsset
                )
            );
        } else {
            setCanUploadDocument(true);
        }
    }, [authUserId, authUserType, currentAccountAsset]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case ACCOUNT_ASSET_KEYS.DOCUMENT_NAME:
                        setDocumentNameError(msg);
                        break;

                    case ACCOUNT_ASSET_KEYS.DOCUMENT_TYPE:
                        setDocumentNameError(msg);
                        break;

                    case ACCOUNT_ASSET_KEYS.DOCUMENT:
                        setDocumentError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const documentNameBlurHandler = (event) => {
        const validationResult = checkDocumentNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDocumentNameError(validationResult.msg);
            }
        } else {
            setDocumentNameError(VALIDATION_FAILED);
        }
    };

    const documentTypeChangeHandler = (event) => {
        const selectedDocumentType = event.target.value;
        if (selectedDocumentType && selectedDocumentType.length > 0) {
            setDocumentType(selectedDocumentType);
        } else {
            setDocumentType("");
        }
        setDocumentTypeError("");

        const documentTypeValidationResult = checkAccountAssetTypeValid(
            selectedDocumentType,
            assetTypeNames
        );
        if (documentTypeValidationResult.status) {
            if (documentTypeValidationResult.status === "false") {
                setDocumentTypeError(documentTypeValidationResult.msg);
            }
        } else {
            setDocumentTypeError(VALIDATION_FAILED);
        }
    };

    const documentChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (
            !currentAccountAsset ||
            (currentAccountAsset && !documentLink) ||
            (currentAccountAsset && files && files.length > 0)
        ) {
            const validationResult = checkAccountAssetValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setDocumentError(validationResult.msg);
                }
            } else {
                isValid = false;
                setDocumentError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setDocument(files[0]);
            setDocumentError("");
        } else {
            setDocument(null);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const documentNameValidationResult =
            checkDocumentNameValid(documentName);
        if (documentNameValidationResult.status) {
            if (documentNameValidationResult.status === "false") {
                formIsValid = false;
                setDocumentNameError(documentNameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDocumentNameError(VALIDATION_FAILED);
        }

        const documentTypeValidationResult = checkAccountAssetTypeValid(
            documentType,
            assetTypeNames
        );
        if (documentTypeValidationResult.status) {
            if (documentTypeValidationResult.status === "false") {
                formIsValid = false;
                setDocumentTypeError(documentTypeValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDocumentTypeError(VALIDATION_FAILED);
        }

        if (
            !currentAccountAsset ||
            (currentAccountAsset && !documentLink) ||
            (currentAccountAsset && document)
        ) {
            const documentValidationResult = checkAccountAssetValid([document]);
            if (documentValidationResult.status) {
                if (documentValidationResult.status === "false") {
                    formIsValid = false;
                    setDocumentError(documentValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setDocumentError(VALIDATION_FAILED);
            }
        }

        if (formIsValid) {
            const accountAssetData = new FormData();
            accountAssetData.append("authcode", authToken);
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.COMPANY_ID],
                folder[FOLDER_KEYS.COMPANY_ID]
            );
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.FOLDER_ID],
                folder[FOLDER_KEYS.ID]
            );
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.DOCUMENT_NAME],
                documentName
            );

            if (currentAccountAsset) {
                accountAssetData.append(
                    [ACCOUNT_ASSET_KEYS.ACCOUNT_ASSET_ID],
                    currentAccountAsset[ACCOUNT_ASSET_KEYS.ID]
                );

                if (canUploadDocument && document)
                    accountAssetData.append(
                        [ACCOUNT_ASSET_KEYS.DOCUMENT],
                        document
                    );

                dispatch(
                    updateAccountAsset(
                        { accountAssetData: accountAssetData },
                        (updatedAccountAsset) => {
                            if (_isAddEditFileDialogMounted.current) {
                                onUpdateFile(updatedAccountAsset);
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditFileDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                accountAssetData.append(
                    [ACCOUNT_ASSET_KEYS.DOCUMENT_TYPE],
                    documentType
                );

                if (canUploadDocument)
                    accountAssetData.append(
                        [ACCOUNT_ASSET_KEYS.DOCUMENT],
                        document
                    );

                dispatch(
                    addAccountAsset(
                        { accountAssetData: accountAssetData },
                        () => {
                            if (_isAddEditFileDialogMounted.current) {
                                onAddFile();
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditFileDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    const assetTypesValid = useMemo(() => {
        return assetTypeNames && assetTypeNames.length > 0;
    }, [assetTypeNames]);

    const loadError = !isValidUserType || !folderIsValid || !assetTypesValid;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentAccountAsset ? "Edit Asset" : "Upload Asset"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {!isLoading && loadError && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !folderIsValid && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    Folder is not valid!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            folderIsValid &&
                            !assetTypesValid && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        There was a problem in fetching the
                                        asset types!
                                    </Alert>
                                </Grid>
                            )}
                    </Grid>
                )}
                {!isLoading && !loadError && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="document_name"
                                    name="document_name"
                                    label="Document Name"
                                    value={documentName}
                                    margin="dense"
                                    onChange={(e) => {
                                        setDocumentName(e.target.value);
                                        setDocumentNameError("");
                                    }}
                                    onBlur={documentNameBlurHandler}
                                    error={documentNameError !== ""}
                                    helperText={documentNameError}
                                />
                            </Grid>
                            {!currentAccountAsset && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="document-type-label"
                                            error={documentTypeError !== ""}
                                        >
                                            Document Type
                                        </InputLabel>
                                        <Select
                                            labelId="document-type-label"
                                            id="document-type"
                                            value={documentType}
                                            label="Document Type"
                                            input={
                                                <OutlinedInput
                                                    fullWidth
                                                    label="Document Type"
                                                    error={
                                                        documentTypeError !== ""
                                                    }
                                                />
                                            }
                                            onChange={documentTypeChangeHandler}
                                        >
                                            {assetTypeNames.map(
                                                (assetTypeName) => {
                                                    const assetTypeNameParts =
                                                        assetTypeName
                                                            .split("_")
                                                            .map(
                                                                (
                                                                    assetTypeNamePart
                                                                ) =>
                                                                    capitalizeFLetter(
                                                                        assetTypeNamePart
                                                                    )
                                                            );
                                                    return (
                                                        <MenuItem
                                                            key={assetTypeName}
                                                            value={
                                                                assetTypeName
                                                            }
                                                        >
                                                            {assetTypeNameParts.join(
                                                                " "
                                                            )}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        {documentTypeError && (
                                            <FormHelperText
                                                error
                                                id="document-type-error"
                                            >
                                                {documentTypeError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            {canUploadDocument && (
                                <Grid item xs={12}>
                                    <label
                                        htmlFor="document"
                                        style={{
                                            display: "block",
                                            marginTop: 8,
                                        }}
                                    >
                                        <input
                                            id="document"
                                            name="document"
                                            type="file"
                                            onChange={documentChangeHandler}
                                            style={{ display: "none" }}
                                        />

                                        <AttachFileButton
                                            fullWidth
                                            type="button"
                                            variant="outlined"
                                            component="span"
                                            startIcon={<AttachFileIcon />}
                                            sx={(theme) => {
                                                const documentErrorSx =
                                                    documentError
                                                        ? {
                                                              color: theme
                                                                  .palette.error
                                                                  .main,
                                                              border: `1px solid ${theme.palette.error.main}`,
                                                          }
                                                        : "";
                                                return {
                                                    ...documentErrorSx,
                                                };
                                            }}
                                        >
                                            {document
                                                ? document.name
                                                : "Choose Document"}
                                        </AttachFileButton>
                                    </label>
                                    {documentError && (
                                        <FormHelperText
                                            error
                                            id="document-error"
                                        >
                                            {documentError}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            )}
                            {documentLink && (
                                <Grid item xs={12}>
                                    <NameLink
                                        download={getAssetFileNameFromLink(
                                            documentLink
                                        )}
                                        noWrap
                                        align="center"
                                        href={documentLink}
                                        target="_blank"
                                        sx={(theme) => ({
                                            display: "block",
                                            wordWrap: "break-word",
                                            ...theme.typography.h5,
                                        })}
                                    >
                                        View Asset
                                    </NameLink>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {currentAccountAsset ? "Update" : "Upload"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditFileDialog.propTypes = {
    currentAccountAsset: PropTypes.object,
    folder: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onAddFile: PropTypes.func,
    onUpdateFile: PropTypes.func,
};

AddEditFileDialog.defaultProps = {
    open: false,
    // onClose: () => {},
    // onAddFile: () => {},
    // onUpdateFile: () => {},
};

export default AddEditFileDialog;
