import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getFmUserLocal } from "../../redux/actions/fmUsers";
import { getAllCompanies } from "../../redux/actions/companies";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    AUTH_USER_KEYS,
    APP_NAME,
    FM_USER_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import AddEditUserDialog from "../fmUsers/AddEditUserDialog";
import BioCard from "../fmUser/BioCard";
import CompaniesCard from "../fmUser/CompaniesCard";

const genericPageName = "User";

const FmUser = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isMountedFmUser = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const fmUserIdParam = params?.fmUserId || "";

    const [fetchFmUser, setFetchFmUser] = useState(false);
    const [fmUserFetched, setFmUserFetched] = useState(false);
    const [fmUser, setFmUser] = useState(null);
    const [fmUserId, setFmUserId] = useState("");
    /* All Companies state */
    const [fetchCompanies, setFetchCompanies] = useState(false);
    const [allCompanies, setAllCompanies] = useState([]);
    /* Miscellaneous States */
    const [pageName, setPageName] = useState(genericPageName);
    const [isLoading, setIsLoading] = useState(false);
    const [openAddEditUserDialog, setOpenAddEditUserDialog] = useState(false);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isValidUserType = isAdminUser;

    useEffect(() => {
        return () => {
            _isMountedFmUser.current = false;
        };
    }, []);

    /* Start Loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch fm user details */
    useEffect(() => {
        if (isLoading) {
            setFetchFmUser(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchFmUser) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFmUserLocal(payload, (fetchedFmUser) => {
                            if (isActive) {
                                setFetchFmUser(false);
                                setFmUserFetched(true);
                                if (
                                    fetchedFmUser &&
                                    fetchedFmUser?.[FM_USER_KEYS.ID] &&
                                    fetchedFmUser[FM_USER_KEYS.ID] &&
                                    fetchedFmUser[FM_USER_KEYS.ID] ===
                                        fmUserIdParam
                                ) {
                                    setFmUser(fetchedFmUser);
                                    setFmUserId(fetchedFmUser[FM_USER_KEYS.ID]);
                                    setFetchCompanies(true);
                                } else {
                                    setFmUser(null);
                                    setFmUserId("");
                                    setIsLoading(false);
                                }
                            }
                        })
                    );
                };
            };

            const fmUserData = {
                authcode: authToken,
                [FM_USER_KEYS.USER_ID]: fmUserIdParam,
            };

            const promise = dispatch(fetchData({ fmUserData: fmUserData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchFmUser(false);
                    setFmUserFetched(false);
                    setFmUser(null);
                    setFmUserId("");
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fmUserIdParam, fetchFmUser]);

    /* Fetch all companies */
    useEffect(() => {
        let isActive = true;

        if (fetchCompanies) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllCompanies(payload, (fetchedCompanies) => {
                            if (isActive) {
                                setFetchCompanies(false);
                                setAllCompanies(
                                    fetchedCompanies?.companies || []
                                );
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const companyData = { authcode: authToken };
            const promise = dispatch(fetchData({ companyData: companyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchCompanies(false);
                    setAllCompanies([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchCompanies]);

    /* Set page name */
    useEffect(() => {
        if (!fetchFmUser && fmUser && fmUserFetched) {
            setPageName(fmUser[FM_USER_KEYS.NAME] || genericPageName);
        } else {
            setPageName(genericPageName);
        }
    }, [fetchFmUser, fmUser, fmUserFetched]);

    const handleFmUserEdit = () => {
        setOpenAddEditUserDialog(true);
    };

    const handleCloseAddEditUserDialog = () => {
        setOpenAddEditUserDialog(false);
    };

    const handleFmUserUpdate = (updatedFmUser) => setFmUser(updatedFmUser);

    const fetchedFmUserValid = fmUserFetched && fmUserId;
    const loadError = !isValidUserType || !fetchedFmUserValid;

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: genericPageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !fetchedFmUserValid && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    Invalid User!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <HeadingStack sx={{ justifyContent: "end" }}>
                                <FmButton
                                    startIcon="edit"
                                    type="button"
                                    label="Edit User"
                                    onClick={handleFmUserEdit}
                                />
                            </HeadingStack>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <BioCard fmUser={fmUser} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <CompaniesCard
                                allCompanies={allCompanies}
                                fmUser={fmUser}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && openAddEditUserDialog && (
                <AddEditUserDialog
                    allCompanies={allCompanies}
                    currentFmUser={fmUser}
                    open={openAddEditUserDialog}
                    onClose={handleCloseAddEditUserDialog}
                    onUpdate={handleFmUserUpdate}
                />
            )}
        </React.Fragment>
    );
};

export default FmUser;
