import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { arrayMove } from "@dnd-kit/sortable";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    deleteSettingItem,
    getFilteredSidebarLocal,
    updateSettingItemsOrder,
} from "../../redux/actions/sidebarSettings";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { getUserTypesList } from "../../utils/appHelpers";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SIDEBAR_ITEM_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import AddEditItemDialog from "./AddEditItemDialog";
import SidebarItems from "./SidebarItems";
import SidebarItemsTabPanel, { a11yProps } from "./SidebarItemsTabPanel";

const USER_TYPES = getUserTypesList();

const pageName = "Sidebar Settings";

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return {
                inserted: true,
                deleted: false,
                updated: false,
                updatedItem: null,
            };
        } else if (action.type === "DELETE") {
            return {
                inserted: false,
                deleted: true,
                updated: false,
                updatedItem: null,
            };
        } else if (action.type === "UPDATE") {
            return {
                inserted: false,
                deleted: false,
                updated: true,
                updatedItem: action.updatedItem,
            };
        }
    }

    return {
        inserted: false,
        deleted: false,
        updated: false,
        updatedItem: null,
    };
};

const itemsReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_ITEMS") {
            return {
                ...state,
                fetchItems: true,
                firstLoad: false,
                updateOrder: false,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                items: [],
                itemsOrder: [],
                fetchItems: false,
                firstLoad: false,
                updateOrder: false,
            };
        } else if (action.type === "SET_FETCHED_ITEMS") {
            const currentItems = action.items;

            const currentItemsOrder = currentItems.map(
                (currentItem, currentItemIdx) => {
                    return {
                        [SIDEBAR_ITEM_KEYS.ID]:
                            currentItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID],
                        [SIDEBAR_ITEM_KEYS.POSITION]: currentItemIdx,
                    };
                }
            );

            return {
                ...state,
                items: currentItems,
                itemsOrder: currentItemsOrder,
            };
        } else if (action.type === "STOP_FETCH_ITEMS") {
            return {
                ...state,
                fetchItems: false,
                firstLoad: false,
                updateOrder: false,
            };
        } else if (action.type === "UPDATE_ITEM") {
            const updatedItem = action.updatedItem;
            if (
                !(
                    updatedItem &&
                    updatedItem?.[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
                )
            ) {
                return { ...state };
            }

            const currentItems = state.items;
            const updatedItems = currentItems.map((currentItem) =>
                currentItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID] ===
                updatedItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
                    ? updatedItem
                    : currentItem
            );

            const updatedItemsOrder = updatedItems.map(
                (currentItem, currentItemIdx) => {
                    return {
                        [SIDEBAR_ITEM_KEYS.ID]:
                            currentItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID],
                        [SIDEBAR_ITEM_KEYS.POSITION]: currentItemIdx,
                    };
                }
            );

            return {
                ...state,
                items: updatedItems,
                itemsOrder: updatedItemsOrder,
            };
        } else if (action.type === "UPDATE_ORDER") {
            const currentItems = state.items;
            const tempItemIds = currentItems.map(
                (tempItem) => tempItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
            );

            const oldIndex = tempItemIds.indexOf(action.activeId);
            const newIndex = tempItemIds.indexOf(action.overId);

            const updatedItems = arrayMove(currentItems, oldIndex, newIndex);

            const updatedItemsOrder = updatedItems.map(
                (currentItem, currentItemIdx) => {
                    return {
                        [SIDEBAR_ITEM_KEYS.ID]:
                            currentItem[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID],
                        [SIDEBAR_ITEM_KEYS.POSITION]: currentItemIdx,
                    };
                }
            );

            return {
                ...state,
                items: updatedItems,
                itemsOrder: updatedItemsOrder,
                updateOrder: true,
            };
        }
    }

    return {
        items: [],
        itemsOrder: [],
        fetchItems: false,
        firstLoad: true,
        updateOrder: false,
    };
};

const SidebarSettings = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isSidebarSettingsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const [fetchingOnLoad, setFetchingOnLoad] = useState(false);
    const [currentUserTypeId, setCurrentUserTypeId] = useState("");
    const [currentItem, setCurrentItem] = useState(null);
    const [openAddEditItemDialog, setOpenAddEditItemDialog] = useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
        updated: false,
        updatedItem: null,
    });

    const [itemsState, dispatchItems] = useReducer(itemsReducer, {
        items: [],
        itemsOrder: [],
        fetchItems: false,
        firstLoad: true,
        updateOrder: false,
    });

    const { items, itemsOrder, fetchItems, firstLoad, updateOrder } =
        itemsState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isValidUserType = isAdminUser;

    useEffect(() => {
        return () => {
            _isSidebarSettingsMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isValidUserType) {
            setCurrentUserTypeId(authUserType);
        }
    }, [authUserType, isValidUserType]);

    useEffect(() => {
        if (currentUserTypeId) {
            if (firstLoad) {
                setFetchingOnLoad(true);
            } else {
                dispatchItems({ type: "FETCH_ITEMS" });
            }
        }
    }, [currentUserTypeId, firstLoad]);

    useEffect(() => {
        if (fetchingOnLoad) {
            dispatchItems({ type: "FETCH_ITEMS" });
        }
    }, [fetchingOnLoad]);

    /* Fetch sidebar items */
    useEffect(() => {
        let isActive = true;

        if (fetchItems) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredSidebarLocal(payload, (fetchedSidebar) => {
                            if (isActive) {
                                dispatchItems({ type: "STOP_FETCH_ITEMS" });
                                setFetchingOnLoad(false);
                                dispatchItems({
                                    type: "SET_FETCHED_ITEMS",
                                    items: fetchedSidebar?.items || [],
                                });
                                dispatchAction();
                            }
                        })
                    );
                };
            };

            const sidebarData = {
                authcode: authToken,
                [SIDEBAR_ITEM_KEYS.USER_TYPE_ID]: currentUserTypeId,
            };

            const promise = dispatch(
                fetchData({ sidebarData: sidebarData, fromAction: actionState })
            );
            promise.catch((error) => {
                if (isActive) {
                    dispatchItems({ type: "RESET" });
                    setFetchingOnLoad(false);
                    dispatchAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, actionState, authToken, currentUserTypeId, fetchItems]);

    /* Fetch list again if an item is added or deleted */
    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            dispatchItems({
                type: "FETCH_ITEMS",
            });
        } else if (actionState.updated) {
            dispatchItems({
                type: "UPDATE_ITEM",
                updatedItem: actionState.updatedItem,
            });
            dispatchAction();
        }
    }, [actionState]);

    /* Update order */
    useEffect(() => {
        let isActive = true;

        if (updateOrder) {
            const updateData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        updateSettingItemsOrder(payload, () => {
                            if (isActive) {
                                dispatchItems({
                                    type: "FETCH_ITEMS",
                                });
                            }
                        })
                    );
                };
            };

            const itemsData = {
                authcode: authToken,
                [SIDEBAR_ITEM_KEYS.USER_TYPE_ID]: currentUserTypeId,
                [SIDEBAR_ITEM_KEYS.ORDER]: itemsOrder,
            };

            const promise = dispatch(updateData({ itemsData: itemsData }));
            promise.catch((error) => {
                if (isActive) {
                    dispatchItems({ type: "RESET" });
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, currentUserTypeId, itemsOrder, updateOrder]);

    const handleUserTypeChange = (event, newValue) =>
        setCurrentUserTypeId(newValue);

    const handleAddItem = () => setOpenAddEditItemDialog(true);

    const handleEditItem = (item) => {
        setCurrentItem(item);
        setOpenAddEditItemDialog(true);
    };

    const handleCloseAddEditItemDialog = () => {
        setOpenAddEditItemDialog(false);
        setCurrentItem(null);
    };

    const handledeleteItem = React.useCallback(
        (item) => {
            if (
                isValidUserType &&
                item &&
                item[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]
            ) {
                showDialog({
                    variant: "confirm",
                    title: `Delete item: ${item[SIDEBAR_ITEM_KEYS.LABEL]}?`,
                    onYes: () => {
                        hideDialog();
                        const itemData = {
                            authcode: authToken,
                            [SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID]:
                                item[SIDEBAR_ITEM_KEYS.SIDEBAR_ITEM_ID],
                            [SIDEBAR_ITEM_KEYS.USER_TYPE_ID]: currentUserTypeId,
                        };
                        dispatch(
                            deleteSettingItem(
                                { itemData: itemData },
                                () => {
                                    if (_isSidebarSettingsMounted.current)
                                        dispatchAction({ type: "DELETE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid item",
                });
            }
        },
        [
            dispatch,
            Swal,
            hideDialog,
            showDialog,
            authToken,
            currentUserTypeId,
            isValidUserType,
        ]
    );

    const handleOrderUpdate = (activeId, overId) => {
        dispatchItems({
            type: "UPDATE_ORDER",
            activeId: activeId,
            overId: overId,
        });
    };

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle type="v2" component="h1" title={pageName} />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {!fetchingOnLoad && isValidUserType && currentUserTypeId && (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                            }}
                        >
                            <AppBar position="static" color="inherit">
                                <Tabs
                                    variant="fullWidth"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    value={currentUserTypeId}
                                    aria-label="sidebar items tabs"
                                    onChange={handleUserTypeChange}
                                >
                                    {USER_TYPES.map((userTypeObj) => {
                                        const typeValue = userTypeObj.value;
                                        return (
                                            <Tab
                                                key={`sidebar-item-tab-${typeValue}`}
                                                label={userTypeObj.label}
                                                value={typeValue}
                                                sx={{
                                                    textTransform: "capitalize",
                                                }}
                                                {...a11yProps(typeValue)}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </AppBar>
                            {USER_TYPES.map((userTypeObj, userTypeIdx) => {
                                const typeValue = userTypeObj.value;

                                return (
                                    <SidebarItemsTabPanel
                                        key={`sidebar-item-tabpanel-${typeValue}`}
                                        value={currentUserTypeId}
                                        index={userTypeIdx}
                                        userType={typeValue}
                                    >
                                        {currentUserTypeId === typeValue &&
                                        items &&
                                        items.length > 0 ? (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <HeadingStack>
                                                        <PageTitle
                                                            type="v2"
                                                            component="p"
                                                            title={
                                                                userTypeObj.label
                                                            }
                                                        />
                                                        <FmButton
                                                            label="Add Item"
                                                            startIcon="add"
                                                            onClick={
                                                                handleAddItem
                                                            }
                                                        />
                                                    </HeadingStack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SidebarItems
                                                        items={items}
                                                        onDeleteItem={
                                                            handledeleteItem
                                                        }
                                                        onEditItem={
                                                            handleEditItem
                                                        }
                                                        onOrderUpdate={
                                                            handleOrderUpdate
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Alert
                                                variant="outlined"
                                                severity="error"
                                            >
                                                There was a problem in rendering
                                                the items!
                                            </Alert>
                                        )}
                                    </SidebarItemsTabPanel>
                                );
                            })}
                        </Box>
                    </Grid>
                )}
                {!fetchingOnLoad && !isValidUserType && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                )}
            </Grid>
            {!fetchingOnLoad && isValidUserType && openAddEditItemDialog && (
                <AddEditItemDialog
                    currentItem={currentItem}
                    currentUserTypeId={currentUserTypeId}
                    fetchOnInsert={true}
                    open={openAddEditItemDialog}
                    onClose={handleCloseAddEditItemDialog}
                    onDispatchAction={dispatchAction}
                />
            )}
        </React.Fragment>
    );
};

export default SidebarSettings;
