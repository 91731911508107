import React from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import EditAdminProfile from "./EditAdminProfile";
import EditClientUserProfile from "./EditClientUserProfile";
import EditFmUserProfile from "./EditFmUserProfile";

const EditProfile = () => {
    const { authUser } = useJumboAuth();

    let editProfileComponent = null;

    if (authUser && authUser?.[AUTH_USER_KEYS.TYPE]) {
        if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_ADMIN_ID) {
            editProfileComponent = <EditAdminProfile />;
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_AE_ID) {
            editProfileComponent = <EditFmUserProfile />;
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_CLIENT_ID) {
            editProfileComponent = <EditClientUserProfile />;
        }
    }

    return editProfileComponent;
};

export default EditProfile;
