import React, {
    lazy,
    Suspense,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    cancelMeeting,
    /* deleteMeeting, */
    getFilteredMeetings,
    setCurrentMeeting,
    updateMeetingStatus,
} from "../../redux/actions/meetings";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { allMeetingsHeadCells } from "../../utils/constants/tableColumns";
import {
    getMeetingStatusColor,
    getMeetingStatusList,
    getMeetingTimeRequestStatusList,
} from "../../utils/appHelpers";
import { isMeetingActionAllowed } from "../../utils/meetingsHelper";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    EMPTY_PARAM_VALUE,
    MEETING_KEYS,
    MEETING_ALL_STATUS,
    MEETING_APPROVED_STATUS,
    MEETING_REJECTED_STATUS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import MeetingDescriptionDialog from "./MeetingDescriptionDialog";
import MeetingsTable from "./MeetingsTable";
import MeetingsTabPanel, { a11yProps } from "./MeetingsTabPanel";
const AddMeetingDialog = lazy(() => import("./AddMeetingDialog"));
const EditMeetingDialog = lazy(() => import("./EditMeetingDialog"));
const HandleNewTimeRequestDialog = lazy(() =>
    import("./HandleNewTimeRequestDialog")
);
const RequestNewTimeDialog = lazy(() => import("./RequestNewTimeDialog"));
const SentTimeRequestDialog = lazy(() => import("./SentTimeRequestDialog"));

const pageName = "Meetings";

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return {
                inserted: true,
                deleted: false,
                cancelled: false,
                statusUpdated: false,
                updatedStatus: "",
                timeRequestStatusUpdated: false,
                updatedTimeRequestStatus: "",
            };
        } else if (action.type === "CANCEL") {
            return {
                inserted: false,
                deleted: false,
                cancelled: true,
                statusUpdated: false,
                updatedStatus: "",
                timeRequestStatusUpdated: false,
                updatedTimeRequestStatus: "",
            };
        } else if (action.type === "DELETE") {
            return {
                inserted: false,
                deleted: true,
                cancelled: false,
                statusUpdated: false,
                updatedStatus: "",
                timeRequestStatusUpdated: false,
                updatedTimeRequestStatus: "",
            };
        } else if (action.type === "STATUS_UPDATE") {
            return {
                inserted: false,
                deleted: false,
                cancelled: false,
                statusUpdated: true,
                updatedStatus: action.updatedStatus,
                timeRequestStatusUpdated: false,
                updatedTimeRequestStatus: "",
            };
        } else if (action.type === "TIME_REQUEST_STATUS_UPDATE") {
            return {
                inserted: false,
                deleted: false,
                cancelled: false,
                statusUpdated: false,
                updatedStatus: "",
                timeRequestStatusUpdated: true,
                updatedTimeRequestStatus: action.updatedTimeRequestStatus,
            };
        }
    }

    return {
        inserted: false,
        deleted: false,
        cancelled: false,
        statusUpdated: false,
        updatedStatus: "",
        timeRequestStatusUpdated: false,
        updatedTimeRequestStatus: "",
    };
};

const MEETINGS_LIMIT = 10;

const INIT_MEETINGS_STATE = {
    clientId: "",
    companyId: "",
    fetchMeetings: false,
    firstLoad: true,
    inputIsValid: false,
    meetingsFetched: false,
    order: SORT_ORDER_DESC,
    page: 0,
    rowsPerPage: MEETINGS_LIMIT,
    sort: MEETING_KEYS.MEETING_DATE,
    status: MEETING_ALL_STATUS,
    statusChanged: false,
    upcoming: "",
};

const meetingsLocalReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, fetchMeetings: true, meetingsFetched: false };
        } else if (action.type === "RESET") {
            return {
                ...state,
                fetchMeetings: false,
                firstLoad: false,
                meetingsFetched: false,
                page: 0,
                statusChanged: false,
            };
        } else if (action.type === "SET_CLIENT_ID") {
            const updatedClientId = action.clientId;

            const currentCompanyId = state.companyId;
            const currentUpcoming = state.upcoming;

            const updatedInputIsValid =
                currentCompanyId && currentUpcoming && updatedClientId;

            return {
                ...state,
                clientId: updatedClientId,
                inputIsValid: updatedInputIsValid,
            };
        } else if (action.type === "SET_COMPANY_ID") {
            const updatedCompanyId = action.companyId;

            const currentClientId = state.clientId;
            const currentUpcoming = state.upcoming;

            const updatedInputIsValid =
                currentClientId && currentUpcoming && updatedCompanyId;

            return {
                ...state,
                companyId: action.companyId,
                inputIsValid: updatedInputIsValid,
            };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchMeetings: true,
                meetingsFetched: false,
            };
        } else if (action.type === "SET_PAGE") {
            return {
                ...state,
                page: action.page,
                fetchMeetings: true,
                meetingsFetched: false,
            };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                sort: updatedSort,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                fetchMeetings: true,
                meetingsFetched: false,
            };
        } else if (action.type === "SET_STATUS_AND_FETCH") {
            return {
                ...state,
                fetchMeetings: true,
                meetingsFetched: false,
                page: 0,
                status: action.status,
                statusChanged: true,
            };
        } else if (action.type === "SET_UPCOMING") {
            const updatedUpcoming = action.upcoming;

            const currentClientId = state.clientId;
            const currentCompanyId = state.companyId;

            const updatedInputIsValid =
                currentClientId && currentCompanyId && updatedUpcoming;

            return {
                ...state,
                upcoming: action.upcoming,
                inputIsValid: updatedInputIsValid,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchMeetings: false,
                firstLoad: false,
                meetingsFetched: true,
                statusChanged: false,
            };
        }
    }

    return INIT_MEETINGS_STATE;
};

const statusList = getMeetingStatusList();
const meetingTimeRequestStatusList = getMeetingTimeRequestStatusList();

const Meetings = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isMeetingsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { currentMeeting, meetings, meetingCounts } = useSelector(
        ({ meetingsReducer }) => meetingsReducer
    );

    const [params] = useSearchParams();
    const clientParam = params.get("client") || "";
    const companyParam = params.get("company") || "";
    const upcomingParam = params.get("upcoming") || "";

    const [isLoading, setIsLoading] = useState(false);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [headCells, setHeadCells] = useState([]);
    const [openAddMeetingDialog, setOpenAddMeetingDialog] = useState(false);
    const [openEditMeetingDialog, setOpenEditMeetingDialog] = useState(false);
    const [openHandleNewTimeRequestDialog, setOpenHandleNewTimeRequestDialog] =
        useState(false);
    const [openMeetingDescriptionDialog, setOpenMeetingDescriptionDialog] =
        useState(false);
    const [openRequestNewTimeDialog, setOpenRequestNewTimeDialog] =
        useState(false);
    const [openSentTimeRequestDialog, setOpenSentTimeRequestDialog] =
        useState(false);

    const [meetingsState, dispatchMeetingsAction] = useReducer(
        meetingsLocalReducer,
        INIT_MEETINGS_STATE
    );

    const { fetchMeetings, inputIsValid, meetingsFetched, status } =
        meetingsState;

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
        cancelled: false,
        statusUpdated: false,
        updatedStatus: "",
        timeRequestStatusUpdated: false,
        updatedTimeRequestStatus: "",
    });

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canAddMeeting = isAdminUser || isClientUser || isFmUser;
    const canApproveMeeting = isAdminUser || isClientUser || isFmUser;
    const canCancelMeeting = isAdminUser || isClientUser || isFmUser;
    /* const canDeleteMeeting = isAdminUser || isClientUser || isFmUser; */
    const canEditMeeting = isAdminUser || isClientUser || isFmUser;
    const canHandleNewTimeRequest = isAdminUser || isClientUser || isFmUser;
    const canReadDescription = isAdminUser || isClientUser || isFmUser;
    const canRejectMeeting = isAdminUser || isClientUser || isFmUser;
    const canRequestNewTime = isAdminUser || isClientUser || isFmUser;
    const canViewSentTimeRequest = isAdminUser || isClientUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMeetingsMounted.current = false;
            dispatch(setCurrentMeeting(null));
        };
    }, [dispatch]);

    /* Set the table columns */
    useEffect(() => {
        const showAllStatus = status === MEETING_ALL_STATUS;
        if (isAdminUser) {
            setHeadCells(
                allMeetingsHeadCells.map((headCell) => {
                    if (
                        !showAllStatus &&
                        [MEETING_KEYS.STATUS].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        } else if (isClientUser) {
            setHeadCells(
                allMeetingsHeadCells.map((headCell) => {
                    if (
                        !showAllStatus &&
                        [MEETING_KEYS.STATUS].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else if (
                        [MEETING_KEYS.CLIENT_NAME].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        } else if (isFmUser) {
            setHeadCells(
                allMeetingsHeadCells.map((headCell) => {
                    if (
                        !showAllStatus &&
                        [MEETING_KEYS.STATUS].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else if (
                        [MEETING_KEYS.FM_USER_NAME].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isClientUser, isFmUser, status]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Set url parameters as filters for fetching meetings */
    useEffect(() => {
        if (isAdminUser || isFmUser) {
            dispatchMeetingsAction({
                type: "SET_CLIENT_ID",
                clientId: clientParam || EMPTY_PARAM_VALUE,
            });
        } else {
            dispatchMeetingsAction({
                type: "SET_CLIENT_ID",
                clientId: EMPTY_PARAM_VALUE,
            });
        }
    }, [clientParam, isAdminUser, isFmUser]);

    useEffect(() => {
        if (isAdminUser || isFmUser) {
            dispatchMeetingsAction({
                type: "SET_COMPANY_ID",
                companyId: companyParam || EMPTY_PARAM_VALUE,
            });
        } else {
            dispatchMeetingsAction({
                type: "SET_COMPANY_ID",
                companyId: EMPTY_PARAM_VALUE,
            });
        }
    }, [companyParam, isAdminUser, isFmUser]);

    useEffect(() => {
        if (isValidUserType) {
            dispatchMeetingsAction({
                type: "SET_UPCOMING",
                upcoming: upcomingParam || EMPTY_PARAM_VALUE,
            });
        }
    }, [isValidUserType, upcomingParam]);

    /* Start loading the page content if input is okay */
    useEffect(() => {
        if (inputIsValid) {
            setIsLoading(true);
        }
    }, [inputIsValid]);

    /* Fetch filtered meetings */
    useEffect(() => {
        if (isLoading) {
            dispatchMeetingsAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (meetingsState.fetchMeetings) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredMeetings(payload, () => {
                            if (isActive) {
                                dispatchMeetingsAction({ type: "STOP_FETCH" });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const meetingData = {
                authcode: authToken,
                page: meetingsState.page + 1,
                rows_per_page: meetingsState.rowsPerPage,
                sort: meetingsState.sort,
                order: meetingsState.order,
            };

            if (meetingsState.clientId !== EMPTY_PARAM_VALUE) {
                meetingData[MEETING_KEYS.CLIENT_ID] = meetingsState.clientId;
            }

            if (meetingsState.companyId !== EMPTY_PARAM_VALUE) {
                meetingData[MEETING_KEYS.COMPANY_ID] = meetingsState.companyId;
            }

            if (meetingsState.upcoming !== EMPTY_PARAM_VALUE) {
                meetingData.upcoming = meetingsState.upcoming;
            }

            if (meetingsState.status === MEETING_ALL_STATUS) {
                meetingData.status = "";
            } else {
                meetingData.status = meetingsState.status;
            }

            const payload = {
                meetingData: meetingData,
                fromAction: actionState,
            };
            if (!meetingsState.statusChanged) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchMeetingsAction({ type: "RESET" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, actionState, meetingsState]);

    /**
     * Fetch the meetings list again if a meeting
     * is added, cancelled, deleted or status updated
     */
    useEffect(() => {
        if (
            actionState.inserted ||
            actionState.cancelled /* ||
            actionState.deleted */ ||
            actionState.statusUpdated ||
            actionState.timeRequestStatusUpdated
        ) {
            dispatchMeetingsAction({ type: "FETCH" });
        }
    }, [actionState]);

    const handleStatusChange = (event, newValue) =>
        dispatchMeetingsAction({
            type: "SET_STATUS_AND_FETCH",
            status: newValue,
        });

    const handlePageChange = (event, newPage) =>
        dispatchMeetingsAction({ type: "SET_PAGE", page: newPage });

    const handleRowsPerPageChange = (event) => {
        dispatchMeetingsAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchMeetingsAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    /* Add meeting */
    const handleOpenAddMeetingDialog = () => {
        if (canAddMeeting) {
            setOpenAddMeetingDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAddMeetingDialog = () => {
        setOpenAddMeetingDialog(false);
        dispatch(setCurrentMeeting(null));
    };

    const handleInsertMeeting = () => dispatchAction({ type: "INSERT" });

    /* Edit Meeting */
    const handleEditMeeting = (meeting) => {
        const canUserEditMeeting =
            canEditMeeting && isMeetingActionAllowed("edit", authUser, meeting);
        if (canUserEditMeeting) {
            dispatch(setCurrentMeeting(meeting));
            setOpenEditMeetingDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseEditMeetingDialog = () => {
        setOpenEditMeetingDialog(false);
        dispatch(setCurrentMeeting(null));
    };

    /* Read description */
    const handleOpenMeetingDescriptionDialog = (meeting) => {
        dispatch(setCurrentMeeting(meeting));
        setOpenMeetingDescriptionDialog(true);
    };

    const handleCloseMeetingDescriptionDialog = () => {
        setOpenMeetingDescriptionDialog(false);
        dispatch(setCurrentMeeting(null));
    };

    /* Cancel meeting */
    const handleCancelMeeting = React.useCallback(
        (meeting) => {
            const canUserCancelMeeting =
                canCancelMeeting &&
                isMeetingActionAllowed("cancel", authUser, meeting);
            if (canUserCancelMeeting) {
                if (meeting && meeting[MEETING_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Cancel meeting: ${meeting[MEETING_KEYS.NAME]}?`,
                        onYes: () => {
                            hideDialog();
                            const meetingData = {
                                authcode: authToken,
                                [MEETING_KEYS.MEETING_ID]:
                                    meeting[MEETING_KEYS.ID],
                            };
                            dispatch(
                                cancelMeeting(
                                    { meetingData: meetingData },
                                    () => {
                                        if (_isMeetingsMounted.current)
                                            dispatchAction({ type: "CANCEL" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid meeting",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [
            dispatch,
            Swal,
            authUser,
            authToken,
            canCancelMeeting,
            hideDialog,
            showDialog,
        ]
    );

    /* Update meeting status */
    const handleUpdateMeetingStatus = React.useCallback(
        (meeting, updatedStatus) => {
            let statusTxt = "";
            let canUserUpdateStatus = false;
            if (updatedStatus === MEETING_APPROVED_STATUS) {
                statusTxt = "Approve";
                canUserUpdateStatus =
                    canApproveMeeting &&
                    isMeetingActionAllowed("approve", authUser, meeting);
            } else if (updatedStatus === MEETING_REJECTED_STATUS) {
                statusTxt = "Reject";
                canUserUpdateStatus =
                    canRejectMeeting &&
                    isMeetingActionAllowed("reject", authUser, meeting);
            }
            if (canUserUpdateStatus) {
                if (meeting && meeting[MEETING_KEYS.ID]) {
                    const foundStatusObj = statusList.find(
                        (tempStatusObj) => tempStatusObj.value === updatedStatus
                    );
                    const updatedStatusTxt =
                        foundStatusObj && foundStatusObj?.label
                            ? foundStatusObj.label
                            : "NA";

                    showDialog({
                        variant: "confirm",
                        title: `${statusTxt} meeting: ${
                            meeting[MEETING_KEYS.NAME]
                        }?`,
                        onYes: () => {
                            hideDialog();
                            const meetingData = {
                                authcode: authToken,
                                [MEETING_KEYS.MEETING_ID]:
                                    meeting[MEETING_KEYS.ID],
                                [MEETING_KEYS.STATUS]: updatedStatus,
                            };
                            dispatch(
                                updateMeetingStatus(
                                    { meetingData: meetingData },
                                    () => {
                                        if (_isMeetingsMounted.current)
                                            dispatchAction({
                                                type: "STATUS_UPDATE",
                                                updatedStatus: updatedStatusTxt,
                                            });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid meeting",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [
            dispatch,
            Swal,
            authUser,
            authToken,
            canApproveMeeting,
            canRejectMeeting,
            hideDialog,
            showDialog,
        ]
    );

    /* Request new time */
    const handleRequestNewTime = (meeting) => {
        const canUserRequestNewTime =
            canRequestNewTime &&
            isMeetingActionAllowed("requestNewTime", authUser, meeting);
        if (canUserRequestNewTime) {
            dispatch(setCurrentMeeting(meeting));
            setOpenRequestNewTimeDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseRequestNewTimeDialog = () => {
        setOpenRequestNewTimeDialog(false);
        dispatch(setCurrentMeeting(null));
    };

    /* View Sent Time Request */
    const handleOpenSentTimeRequestDialog = (meeting) => {
        dispatch(setCurrentMeeting(meeting));
        setOpenSentTimeRequestDialog(true);
    };

    const handleCloseSentTimeRequestDialog = () => {
        setOpenSentTimeRequestDialog(false);
        dispatch(setCurrentMeeting(null));
    };

    /* Handle new time request */
    const handleHandleNewTimeRequest = (meeting) => {
        const canUserHandleNewTimeRequest =
            canHandleNewTimeRequest &&
            isMeetingActionAllowed("handleNewTimeRequest", authUser, meeting);
        if (canUserHandleNewTimeRequest) {
            dispatch(setCurrentMeeting(meeting));
            setOpenHandleNewTimeRequestDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseHandleNewTimeRequestDialog = () => {
        setOpenHandleNewTimeRequestDialog(false);
        dispatch(setCurrentMeeting(null));
    };

    const handleOnUpdateTimeRequest = (updatedMeeting) => {
        const newTimeRequestStatus =
            updatedMeeting[MEETING_KEYS.REQUEST_STATUS];
        const foundStatusObj = meetingTimeRequestStatusList.find(
            (tempStatusObj) => tempStatusObj.value === newTimeRequestStatus
        );
        dispatchAction({
            type: "TIME_REQUEST_STATUS_UPDATE",
            updatedTimeRequestStatus:
                foundStatusObj && foundStatusObj?.label
                    ? foundStatusObj.label
                    : "NA",
        });
    };

    /* const handleDeleteMeeting = React.useCallback(
        (meeting) => {
            if (canDeleteMeeting) {
                if (meeting && meeting[MEETING_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete meeting: ${meeting[MEETING_KEYS.NAME]}?`,
                        onYes: () => {
                            hideDialog();
                            const meetingData = {
                                authcode: authToken,
                                [MEETING_KEYS.MEETING_ID]:
                                    meeting[MEETING_KEYS.ID],
                            };
                            dispatch(
                                deleteMeeting(
                                    { meetingData: meetingData },
                                    () => {
                                        if (_isMeetingsMounted.current)
                                            dispatchAction({ type: "DELETE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid meeting",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, canDeleteMeeting]
    ); */

    const statusColorObj = useMemo(() => {
        if (status) {
            return getMeetingStatusColor(status);
        } else {
            return null;
        }
    }, [status]);
    const statusColor = statusColorObj ? statusColorObj.bgColor : "";

    const fetchError = !fetchMeetings && !meetingsFetched;
    const loadError = !isValidUserType || !inputIsValid || fetchError;

    return (
        <React.Fragment>
            {!isLoading && loadError && (
                <Grid container spacing={3.5}>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !inputIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                One or more input is not valid!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && inputIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the meetings!
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            )}
            {!isLoading && !loadError && (
                <React.Fragment>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12}>
                            <HeadingStack>
                                <PageTitle
                                    component="h1"
                                    title={pageName}
                                    type="v2"
                                />
                                <Breadcrumb
                                    pageNames={{
                                        genericName: pageName,
                                        specificName: pageName,
                                    }}
                                />
                            </HeadingStack>
                        </Grid>
                        {canAddMeeting && (
                            <Grid item xs={12}>
                                <HeadingStack sx={{ justifyContent: "end" }}>
                                    <FmButton
                                        startIcon="add"
                                        type="button"
                                        label="Add Meeting"
                                        onClick={handleOpenAddMeetingDialog}
                                    />
                                </HeadingStack>
                            </Grid>
                        )}
                        {status && (
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    <AppBar position="static" color="inherit">
                                        <Tabs
                                            variant="fullWidth"
                                            textColor="inherit"
                                            indicatorColor="secondary"
                                            value={status}
                                            aria-label="meetings tabs"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor:
                                                        statusColor,
                                                },
                                            }}
                                            onChange={handleStatusChange}
                                        >
                                            {statusList.map((statusObj) => {
                                                const statusValue =
                                                    statusObj.value;
                                                return (
                                                    <Tab
                                                        key={`meeting-tab-${statusValue}`}
                                                        label={`Status: ${statusObj.label}`}
                                                        value={statusValue}
                                                        sx={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                        {...a11yProps(
                                                            statusValue
                                                        )}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                    </AppBar>
                                    {statusList.map((statusObj, statusIdx) => {
                                        const statusValue = statusObj.value;
                                        return (
                                            <MeetingsTabPanel
                                                key={`meeting-tabpanel-${statusValue}`}
                                                value={status}
                                                index={statusIdx}
                                                status={statusValue}
                                            >
                                                {headCells.length > 0 ? (
                                                    <MeetingsTable
                                                        authUser={authUser}
                                                        canApproveMeeting={
                                                            canApproveMeeting
                                                        }
                                                        canCancelMeeting={
                                                            canCancelMeeting
                                                        }
                                                        /* canDeleteMeeting={canDeleteMeeting} */
                                                        canEditMeeting={
                                                            canEditMeeting
                                                        }
                                                        canHandleNewTimeRequest={
                                                            canHandleNewTimeRequest
                                                        }
                                                        canReadDescription={
                                                            canReadDescription
                                                        }
                                                        canRejectMeeting={
                                                            canRejectMeeting
                                                        }
                                                        canRequestNewTime={
                                                            canRequestNewTime
                                                        }
                                                        canViewSentTimeRequest={
                                                            canViewSentTimeRequest
                                                        }
                                                        columnsToShow={
                                                            columnsToShow
                                                        }
                                                        meetings={meetings}
                                                        meetingCounts={
                                                            meetingCounts
                                                        }
                                                        meetingsFetched={
                                                            meetingsFetched
                                                        }
                                                        headCells={headCells}
                                                        order={
                                                            meetingsState.order
                                                        }
                                                        page={
                                                            meetingsState.page
                                                        }
                                                        rowsPerPage={
                                                            meetingsState.rowsPerPage
                                                        }
                                                        showLoader={true}
                                                        sort={
                                                            meetingsState.sort
                                                        }
                                                        handlePageChange={
                                                            handlePageChange
                                                        }
                                                        handleRowsPerPageChange={
                                                            handleRowsPerPageChange
                                                        }
                                                        onCancel={
                                                            handleCancelMeeting
                                                        }
                                                        /* onDelete={handleDeleteMeeting} */
                                                        onEdit={
                                                            handleEditMeeting
                                                        }
                                                        onHandleNewTimeRequest={
                                                            handleHandleNewTimeRequest
                                                        }
                                                        onReadDescription={
                                                            handleOpenMeetingDescriptionDialog
                                                        }
                                                        onRequestNewTime={
                                                            handleRequestNewTime
                                                        }
                                                        onSort={handleSort}
                                                        onUpdateStatus={
                                                            handleUpdateMeetingStatus
                                                        }
                                                        onViewSentTimeRequest={
                                                            handleOpenSentTimeRequestDialog
                                                        }
                                                    />
                                                ) : (
                                                    <Alert
                                                        variant="outlined"
                                                        severity="error"
                                                    >
                                                        There was a problem in
                                                        rendering the table!
                                                    </Alert>
                                                )}
                                            </MeetingsTabPanel>
                                        );
                                    })}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    {canAddMeeting && openAddMeetingDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddMeetingDialog
                                open={openAddMeetingDialog}
                                setGlobalState={true}
                                onClose={handleCloseAddMeetingDialog}
                                onInsert={handleInsertMeeting}
                            />
                        </Suspense>
                    )}
                    {canEditMeeting && openEditMeetingDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <EditMeetingDialog
                                currentMeeting={currentMeeting}
                                open={openEditMeetingDialog}
                                setGlobalState={true}
                                showAlert={true}
                                onClose={handleCloseEditMeetingDialog}
                            />
                        </Suspense>
                    )}
                    {canReadDescription && openMeetingDescriptionDialog && (
                        <MeetingDescriptionDialog
                            currentMeeting={currentMeeting}
                            meetingKeys={MEETING_KEYS}
                            open={openMeetingDescriptionDialog}
                            onClose={handleCloseMeetingDescriptionDialog}
                        />
                    )}
                    {canRequestNewTime && openRequestNewTimeDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <RequestNewTimeDialog
                                currentMeeting={currentMeeting}
                                open={openRequestNewTimeDialog}
                                setGlobalState={true}
                                onClose={handleCloseRequestNewTimeDialog}
                            />
                        </Suspense>
                    )}
                    {canViewSentTimeRequest && openSentTimeRequestDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <SentTimeRequestDialog
                                currentMeeting={currentMeeting}
                                open={openSentTimeRequestDialog}
                                onClose={handleCloseSentTimeRequestDialog}
                            />
                        </Suspense>
                    )}
                    {canHandleNewTimeRequest &&
                        openHandleNewTimeRequestDialog && (
                            <Suspense fallback={<FmCircularProgress />}>
                                <HandleNewTimeRequestDialog
                                    currentMeeting={currentMeeting}
                                    open={openHandleNewTimeRequestDialog}
                                    setGlobalState={true}
                                    onClose={
                                        handleCloseHandleNewTimeRequestDialog
                                    }
                                    onUpdate={handleOnUpdateTimeRequest}
                                />
                            </Suspense>
                        )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Meetings;
