import React, { lazy } from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import AppSuspense from "../../shared/widgets/AppSuspense";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
/* import AdminHome from "./AdminHome";
import ClientUserHome from "./clientUserHome";
import FmUserHome from "./fmUserHome"; */

const AdminHome = lazy(() => import("./AdminHome"));
const ClientUserHome = lazy(() => import("./clientUserHome"));
const FmUserHome = lazy(() => import("./fmUserHome"));

const Home = () => {
    const { authUser } = useJumboAuth();

    if (authUser && authUser?.[AUTH_USER_KEYS.TYPE]) {
        if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_ADMIN_ID) {
            return (
                <AppSuspense>
                    <AdminHome />
                </AppSuspense>
            );
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_AE_ID) {
            return (
                <AppSuspense>
                    <FmUserHome />
                </AppSuspense>
            );
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_CLIENT_ID) {
            return (
                <AppSuspense>
                    <ClientUserHome />
                </AppSuspense>
            );
        }
    }

    return null;
};

export default Home;
