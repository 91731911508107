import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
    SURVEY_ANSWER_KEYS,
    SURVEY_QUESTION_OPTION_KEYS,
} from "../../utils/constants/appData";

const TakeSurveyCheckbox = ({
    answerObj,
    errorMsg,
    options,
    questionId,
    onSelect,
}) => {
    if (!answerObj || !questionId || !options) return null;

    const answers = answerObj[SURVEY_ANSWER_KEYS.ANSWER];

    return (
        <List>
            {options.map((option) => {
                const optionId = option[SURVEY_QUESTION_OPTION_KEYS.ID];

                const optionKey = `question-${questionId}-option-${optionId}`;
                const labelId = `label-${optionKey}`;

                return (
                    <ListItem key={optionKey} disablePadding>
                        <ListItemButton
                            role={undefined}
                            onClick={() => onSelect(questionId, optionId)}
                            dense
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={answers.indexOf(optionId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId}>
                                {option[SURVEY_QUESTION_OPTION_KEYS.TITLE]}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                );
            })}
            {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
        </List>
    );
};

TakeSurveyCheckbox.propTypes = {
    answerObj: PropTypes.object,
    errorMsg: PropTypes.string,
    options: PropTypes.array,
    questionId: PropTypes.string,
    onSelect: PropTypes.func,
};

TakeSurveyCheckbox.defaultProps = {
    answerObj: null,
    errorMsg: "",
    options: [],
    questionId: "",
    // onSelect: () => {},
};

export default TakeSurveyCheckbox;
