import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";

const FmCircularProgress = ({ label, showBackDrop }) => {
    const labelComponent = label ? (
        <Typography variant={"h6"} mt={2}>
            {label}
        </Typography>
    ) : null;

    if (showBackDrop) {
        return (
            <Backdrop
                open={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 9999 }}
            >
                <CircularProgress />
                {labelComponent}
            </Backdrop>
        );
    } else {
        return (
            <Div
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: (theme) => theme.spacing(3),
                    m: "auto",
                    height: "100%",
                }}
            >
                <CircularProgress />
                {labelComponent}
            </Div>
        );
    }
};

FmCircularProgress.propTypes = {
    label: PropTypes.string,
    showBackDrop: PropTypes.bool,
};

FmCircularProgress.defaultProps = {
    label: "",
    showBackDrop: false,
};

export default FmCircularProgress;
