import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const HTMLTypography = ({ content, ...restProps }) => {
    if (content) {
        return (
            <Typography
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
                {...restProps}
            ></Typography>
        );
    } else {
        return null;
    }
};

HTMLTypography.propTypes = {
    content: PropTypes.string,
};

HTMLTypography.defaultProps = {
    content: "",
};

export default HTMLTypography;
