import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import MultilineTypography from "../../shared/widgets/MultilineTypography";

const MeetingDescriptionDialog = ({
    currentMeeting,
    meetingKeys,
    open,
    onClose,
}) => {
    const _isMeetingDescriptionDialogMounted = useRef(true);

    useEffect(() => {
        return () => {
            _isMeetingDescriptionDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
        }
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    currentMeeting && meetingKeys
                        ? `${currentMeeting[meetingKeys.NAME]}`
                        : "Invalid Meeting!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent sx={{ minHeight: "25vh" }}>
                <Grid container spacing={2}>
                    {currentMeeting && meetingKeys && (
                        <Grid item xs={12}>
                            <MultilineTypography variant="body1">
                                {currentMeeting[meetingKeys.DESCRIPTION]}
                            </MultilineTypography>
                        </Grid>
                    )}
                    {(!currentMeeting || !meetingKeys) && (
                        <Grid item xs={12}>
                            <Alert severity="error">Invalid meeting!</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

MeetingDescriptionDialog.propTypes = {
    currentMeeting: PropTypes.object,
    meetingKeys: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

MeetingDescriptionDialog.defaultProps = {
    open: false,
    // onClose: () => {},
};

export default MeetingDescriptionDialog;
