import { LAYOUT_CONTAINER_STYLES } from "@jumbo/utils/constants/layout";
import { mainTheme } from "../themes/main/theme5";
import { headerTheme } from "../themes/header/theme5";
import { sidebarTheme } from "../themes/sidebar/theme2";
import { footerTheme } from "../themes/footer/default";
import LAYOUT_NAMES from "../layouts/layouts";
import { createJumboTheme } from "@jumbo/utils";
import authServices from "../services/auth-services";
import basicAuthAxios from "../services/auth/basicAuth";

const config = {
    defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,

    authSetting: {
        axiosObject: basicAuthAxios,
        fallbackPath: "/login",
        getAuthUserService: authServices.getCurrentUser,
        redirectNotAuthenticatedPath: "/",
    },

    theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),
};

export { config };
