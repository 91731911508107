import basicAuthAxios from "../../services/auth/basicAuth";
import basicFileAuthAxios from "../../services/auth/basicFileAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    DELETE_ASSIGNED_SERVICE,
    EDIT_ASSIGNED_SERVICE,
    GET_ASSIGNED_SERVICES,
    GET_ASSIGNED_SERVICE_COUNT,
    SET_ASSIGNED_SERVICE_DETAILS,
} from "../../utils/constants/actionTypes";
import {
    ASSIGN_SERVICE_LINK,
    DELETE_ASSIGNED_SERVICE_LINK,
    DELETE_WORK_ORDER_DOCUMENTS_LINK,
    GET_ASSIGNED_SERVICES_LINK,
    UPDATE_ASSIGNED_SERVICE_LINK,
    UPDATE_SERVICE_RENEWAL_DATE_LINK,
    UPLOAD_WORK_ORDER_DOCUMENT_LINK,
} from "../../utils/constants/apiLinks";
import { ASSIGNED_SERVICE_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const assignService = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(ASSIGN_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            if (callbackFun)
                                callbackFun(data.assigned_services);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredAssignedServices = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_ASSIGNED_SERVICES_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            const serviceRequests = data.assigned_services;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [ASSIGNED_SERVICE_KEYS.TOTAL]: count[
                                        ASSIGNED_SERVICE_KEYS.TOTAL
                                    ]
                                        ? count[ASSIGNED_SERVICE_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [ASSIGNED_SERVICE_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Service was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected service was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: GET_ASSIGNED_SERVICES,
                                payload: serviceRequests,
                            });
                            dispatch({
                                type: GET_ASSIGNED_SERVICE_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(serviceRequests);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_ASSIGNED_SERVICES, payload: [] });
                dispatch({
                    type: GET_ASSIGNED_SERVICE_COUNT,
                    payload: {
                        [ASSIGNED_SERVICE_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getFilteredAssignedServicesLocalState = (
    request = {},
    callbackFun
) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_ASSIGNED_SERVICES_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            const assignedServices = data.assigned_services;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [ASSIGNED_SERVICE_KEYS.TOTAL]: count[
                                        ASSIGNED_SERVICE_KEYS.TOTAL
                                    ]
                                        ? count[ASSIGNED_SERVICE_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [ASSIGNED_SERVICE_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Service was assigned successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected service was removed successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));

                            if (callbackFun)
                                callbackFun({
                                    assignedServices: assignedServices,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const setCurrentAssignedService = (assignedService) => {
    return (dispatch) => {
        dispatch({
            type: SET_ASSIGNED_SERVICE_DETAILS,
            payload: assignedService,
        });
    };
};

export const updateAssignedService = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_ASSIGNED_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            const updatedAssignedService =
                                data.assigned_services;
                            dispatch(
                                fetchSuccess("Service was updated successfully")
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_ASSIGNED_SERVICE,
                                    payload: updatedAssignedService,
                                });
                            }
                            if (callbackFun)
                                callbackFun(updatedAssignedService);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateAssignedServiceRenewalDate = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_SERVICE_RENEWAL_DATE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            const updatedAssignedService =
                                data.assigned_services;
                            dispatch(
                                fetchSuccess(
                                    "Renewal Date was updated successfully"
                                )
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_ASSIGNED_SERVICE,
                                    payload: updatedAssignedService,
                                });
                            }
                            if (callbackFun)
                                callbackFun(updatedAssignedService);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const uploadWorkOrderDocument = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(UPLOAD_WORK_ORDER_DOCUMENT_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            const updatedAssignedService =
                                data.assigned_services;
                            dispatch(
                                fetchSuccess(
                                    "Documents were saved successfully."
                                )
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_ASSIGNED_SERVICE,
                                    payload: updatedAssignedService,
                                });
                            }
                            if (callbackFun)
                                callbackFun(updatedAssignedService);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteWorkOrderDocuments = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_WORK_ORDER_DOCUMENTS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.assigned_services) {
                            const updatedAssignedService =
                                data.assigned_services;
                            let successMsg =
                                "Selected documents were deleted successfully.";
                            dispatch(fetchSuccess(successMsg));
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_ASSIGNED_SERVICE,
                                    payload: updatedAssignedService,
                                });
                            }
                            if (callbackFun)
                                callbackFun(updatedAssignedService);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData?.msg || "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteAssignedService = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.assignedServiceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_ASSIGNED_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected service was deleted successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_ASSIGNED_SERVICE,
                            payload:
                                request.assignedServiceData[
                                    ASSIGNED_SERVICE_KEYS.ASSIGNED_SERVICE_ID
                                ],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};
