import React from "react";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";

const PhoneNoExtension = React.forwardRef(function FormattedPhoneNoExt(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            valueIsNumericString
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                        formattedValue: values.formattedValue,
                    },
                });
            }}
        />
    );
});

PhoneNoExtension.propTypes = {
    onChange: PropTypes.func,
};

PhoneNoExtension.defaultProps = {
    onChange: () => {},
};

export default PhoneNoExtension;
