import basicAuthAxios from "../../services/auth/basicAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    ADD_SIDEBAR_SETTING_ITEM_LINK,
    DELETE_SIDEBAR_SETTING_ITEM_LINK,
    GET_SIDEBAR_SETTINGS_LINK,
    UPDATE_SIDEBAR_SETTING_ITEM_LINK,
    UPDATE_SIDEBAR_SETTING_ITEMS_ORDER_LINK,
} from "../../utils/constants/apiLinks";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const getFilteredSidebarLocal = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.sidebarData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_SIDEBAR_SETTINGS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.menu) {
                            const menu = data.menu;
                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg = "Item was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected item was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            if (callbackFun) callbackFun({ items: menu });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const addSettingItem = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.itemData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(ADD_SIDEBAR_SETTING_ITEM_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data/* .item */) {
                            /* dispatch(
                                fetchSuccess("Item was added successfully.")
                            ); */
                            if (callbackFun) callbackFun(data/* .item */);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteSettingItem = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.itemData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_SIDEBAR_SETTING_ITEM_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected item was deleted successfully."
                            )
                        ); */
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

export const updateSettingItem = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.itemData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_SIDEBAR_SETTING_ITEM_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data/* .item */) {
                            dispatch(
                                fetchSuccess(
                                    "Selected item was updated successfully."
                                )
                            );
                            if (callbackFun) callbackFun(data/* .item */);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateSettingItemsOrder = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.itemsData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(UPDATE_SIDEBAR_SETTING_ITEMS_ORDER_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        dispatch(fetchSuccess(""));
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};
