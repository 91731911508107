import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
/* import Snackbar from "@mui/material/Snackbar"; */
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { capitalizeFLetter } from "@jumbo/utils";
/* import useClipboardCopy from "../../hooks/useClipboardCopy"; */
import { getTimeZonesLocal } from "../../redux/actions/timeZones";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import TableColorCard from "../../shared/widgets/TableColorCard";
import {
    getMeetingStatusList,
    getMeetingStatusColor,
} from "../../utils/appHelpers";
import { isMeetingActionAllowed } from "../../utils/meetingsHelper";
import {
    MEETING_KEYS,
    MEETING_APPROVED_STATUS,
    MEETING_REJECTED_STATUS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    TIME_ZONE_KEYS,
} from "../../utils/constants/appData";

const meetingStatusList = getMeetingStatusList();

const MeetingsTable = ({
    ariaLabel,
    canApproveMeeting,
    canCancelMeeting,
    canEditMeeting,
    canHandleNewTimeRequest,
    canReadDescription,
    canRejectMeeting,
    canRequestNewTime,
    canViewSentTimeRequest,
    cardColor,
    columnsToShow,
    meetings,
    meetingCounts,
    meetingsFetched,
    headCells,
    order,
    page,
    rowsPerPage,
    showColorCard,
    showEmptyRow,
    showLoader,
    sort,
    tableActions,
    handlePageChange,
    handleRowsPerPageChange,
    onCancel,
    onEdit,
    onHandleNewTimeRequest,
    onReadDescription,
    onRequestNewTime,
    onSort,
    onUpdateStatus,
    onViewSentTimeRequest,
}) => {
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [timeZonesLoading, setTimeZonesLoading] = useState(true);
    const [timeZonesLoaded, setTimeZonesLoaded] = useState(false);
    const [allTimeZones, setAllTimeZones] = useState([]);
    /* const [copyText, setCopyText] = useState(""); */

    /* const isClipboardCopied = useClipboardCopy(copyText); */

    /* Fetch time zones list */
    useEffect(() => {
        let isActive = true;

        const fetchData = (payload) => {
            return (dispatch, getState) => {
                return dispatch(
                    getTimeZonesLocal(payload, (fetchedData) => {
                        if (isActive) {
                            const fetchedTimeZones =
                                fetchedData?.timeZones || [];
                            if (
                                fetchedTimeZones &&
                                fetchedTimeZones.length > 0
                            ) {
                                setAllTimeZones(fetchedTimeZones);
                            } else {
                                setAllTimeZones([]);
                            }
                            setTimeZonesLoading(false);
                            setTimeZonesLoaded(true);
                        }
                    })
                );
            };
        };

        const timeZoneData = { authcode: authToken };
        const promise = dispatch(fetchData({ timeZoneData: timeZoneData }));
        promise.catch((error) => {
            if (isActive) {
                setAllTimeZones([]);
                setTimeZonesLoading(false);
                setTimeZonesLoaded(false);
            }
        });

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken]);

    /* useEffect(() => {
        if (copyText) {
            setCopyText("");
        }
    }, [copyText]); */

    if (timeZonesLoading) {
        return showLoader ? <FmCircularProgress /> : null;
    } else if (!timeZonesLoading && !timeZonesLoaded) {
        return (
            <Alert severity="error">
                There was a problem in fetching the time zones!
            </Alert>
        );
    }

    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const meetingsTotalCount =
        meetingCounts &&
        meetingCounts[MEETING_KEYS.TOTAL] &&
        !isNaN(parseInt(meetingCounts[MEETING_KEYS.TOTAL])) &&
        parseInt(meetingCounts[MEETING_KEYS.TOTAL]) > 0
            ? parseInt(meetingCounts[MEETING_KEYS.TOTAL])
            : 0;

    const fetchedMeetingsCount =
        meetings && meetings.length > 0 ? meetings.length : 0;

    const meetingCountNonZero =
        fetchedMeetingsCount > 0 && meetingsTotalCount > 0;

    const paddingSx = {};
    if (showEmptyRow) {
        paddingSx.border = "none";
        if (fetchedMeetingsCount) {
            if (fetchedMeetingsCount < rowsPerPage) {
                paddingSx.pt = (theme) => theme.spacing(3.1);
                paddingSx.pb = (theme) => theme.spacing(3.1);
            }
        } else {
            paddingSx.pt = (theme) => theme.spacing(3.3);
            paddingSx.pb = (theme) => theme.spacing(3.3);
        }
    }

    const showActions =
        canApproveMeeting ||
        canCancelMeeting ||
        canEditMeeting ||
        canHandleNewTimeRequest ||
        canReadDescription ||
        canRejectMeeting ||
        canRequestNewTime;

    const numTotalCols = headCells.length + 1;
    const numVisibleCols = columnsToShow.length + (showActions ? 1 : 0);

    const table = (
        <Table aria-label={ariaLabel}>
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => {
                        const sx = {};

                        if (headCell?.width) {
                            const headCellWidth = Number(
                                headCell.width.match(/\d+(\.\d+)?/)[0]
                            );
                            const updHeadCellWidth =
                                (numTotalCols / numVisibleCols) * headCellWidth;
                            sx.width = `${updHeadCellWidth.toFixed(2)}%`;
                        }

                        const sortDirection =
                            sort === headCell.id
                                ? order === SORT_ORDER_ASC
                                    ? SORT_ORDER_DESC
                                    : SORT_ORDER_ASC
                                : SORT_ORDER_ASC;

                        return headCell.show ? (
                            <TableCell key={headCell.id} sx={{ ...sx }}>
                                {headCell.sortColumn ? (
                                    <TableSortLabel
                                        active={sort === headCell.id}
                                        direction={sortDirection}
                                        onClick={() => onSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {sort === headCell.id ? (
                                            <Box
                                                component="span"
                                                sx={visuallyHidden}
                                            >
                                                {order === SORT_ORDER_DESC
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ) : null;
                    })}
                    {showActions && (
                        <TableCell
                            key="actions"
                            sx={{ width: "22.5%", textAlign: "center" }}
                        >
                            Actions
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody
                sx={{
                    p: (theme) => theme.spacing(1),
                }}
            >
                {meetingCountNonZero ? (
                    meetings.map((meeting) => {
                        const name = meeting[MEETING_KEYS.NAME];
                        const link = meeting[MEETING_KEYS.MEETING_LINK] || "";

                        const timeZone = meeting[MEETING_KEYS.TIME_ZONE] || "";
                        let timeZoneName = "";
                        if (timeZone) {
                            const foundTimeZoneObj = allTimeZones.find(
                                (timeZoneObj) =>
                                    timeZoneObj[TIME_ZONE_KEYS.NAME] ===
                                    timeZone
                            );
                            if (
                                foundTimeZoneObj &&
                                foundTimeZoneObj?.[TIME_ZONE_KEYS.NAME]
                            ) {
                                timeZoneName = `(${
                                    foundTimeZoneObj[TIME_ZONE_KEYS.NAME]
                                })`;
                            }
                        }

                        const status = meeting?.[MEETING_KEYS.STATUS] || "";

                        const statusColorObj = getMeetingStatusColor(status);
                        const statusBgColor = statusColorObj
                            ? statusColorObj.bgColor
                            : "";
                        const statusTextColor = statusColorObj
                            ? statusColorObj.textColor
                            : "";

                        let statusTxt = "";
                        const statusObj = meetingStatusList.find(
                            (tempStatusObj) => tempStatusObj.value === status
                        );
                        statusTxt = statusObj?.label || "";

                        const canUserApproveMeeting =
                            canApproveMeeting &&
                            isMeetingActionAllowed(
                                "approve",
                                authUser,
                                meeting
                            );

                        const canUserCancelMeeting =
                            canCancelMeeting &&
                            isMeetingActionAllowed("cancel", authUser, meeting);

                        const canUserEditMeeting =
                            canEditMeeting &&
                            isMeetingActionAllowed("edit", authUser, meeting);

                        const canUserHandleNewTimeRequest =
                            canHandleNewTimeRequest &&
                            isMeetingActionAllowed(
                                "handleNewTimeRequest",
                                authUser,
                                meeting
                            );

                        const canUserRejectMeeting =
                            canRejectMeeting &&
                            isMeetingActionAllowed("reject", authUser, meeting);

                        const canUserRequestNewTime =
                            canRequestNewTime &&
                            isMeetingActionAllowed(
                                "requestNewTime",
                                authUser,
                                meeting
                            );

                        const canUserViewSentTimeRequest =
                            canViewSentTimeRequest &&
                            isMeetingActionAllowed(
                                "viewSentTimeRequest",
                                authUser,
                                meeting
                            );

                        return (
                            <TableRow
                                key={meeting[MEETING_KEYS.ID]}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {columnsToShow.map((columnId, columnIdx) => {
                                    const foundHeadCell = headCells.find(
                                        (headCell) => headCell.id === columnId
                                    );

                                    const sx = {};
                                    if (foundHeadCell?.pxWidth) {
                                        sx.width = foundHeadCell.pxWidth;
                                    }

                                    let cellContents = null;
                                    if (columnId === MEETING_KEYS.NAME) {
                                        cellContents = link ? (
                                            <Stack
                                                direction="row"
                                                flexWrap="wrap"
                                                alignItems="center"
                                            >
                                                <NameLink
                                                    href={link}
                                                    target="_blank"
                                                    sx={{
                                                        mr: (theme) =>
                                                            theme.spacing(0.5),
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {name}
                                                </NameLink>
                                                {/* <TableActionIcon
                                                    iconComponent="contentCopy"
                                                    label="copy link to clipboard"
                                                    tooltip="Copy Link to Clipboard"
                                                    size="small"
                                                    btnSx={{ p: 0 }}
                                                    iconSx={{
                                                        fontSize: "1rem",
                                                    }}
                                                    onClick={() =>
                                                        setCopyText(link)
                                                    }
                                                /> */}
                                            </Stack>
                                        ) : (
                                            name
                                        );
                                    } else if (
                                        columnId === MEETING_KEYS.FM_USER_NAME
                                    ) {
                                        cellContents = (
                                            <NameLink
                                                component={RouterLink}
                                                to={`/fmUser/${
                                                    meeting[
                                                        MEETING_KEYS.FM_USER_ID
                                                    ]
                                                }/`}
                                            >
                                                {
                                                    meeting[
                                                        MEETING_KEYS
                                                            .FM_USER_NAME
                                                    ]
                                                }
                                            </NameLink>
                                        );
                                    } else if (
                                        columnId === MEETING_KEYS.CLIENT_NAME
                                    ) {
                                        cellContents = (
                                            <NameLink
                                                component={RouterLink}
                                                to={`/clientUser/${
                                                    meeting[
                                                        MEETING_KEYS.CLIENT_ID
                                                    ]
                                                }/overview/`}
                                            >
                                                {
                                                    meeting[
                                                        MEETING_KEYS.CLIENT_NAME
                                                    ]
                                                }
                                            </NameLink>
                                        );
                                    } else if (
                                        columnId === MEETING_KEYS.STATUS
                                    ) {
                                        cellContents = (
                                            <Chip
                                                label={capitalizeFLetter(
                                                    statusTxt
                                                )}
                                                sx={{
                                                    backgroundColor:
                                                        statusBgColor ||
                                                        "inherit",
                                                    color:
                                                        statusTextColor ||
                                                        "inherit",
                                                    textTransform: "uppercase",
                                                }}
                                            />
                                        );
                                    } else if (
                                        columnId === MEETING_KEYS.MEETING_DATE
                                    ) {
                                        cellContents = `${
                                            meeting[MEETING_KEYS.MEETING_DATE]
                                        } ${
                                            meeting[MEETING_KEYS.MEETING_TIME]
                                        } ${timeZoneName}`;
                                    }

                                    const tableCellKey = `cell-${columnId}-${columnIdx}`;
                                    const tableCellSx = {};
                                    if (
                                        showColorCard &&
                                        columnId === MEETING_KEYS.STATUS
                                    ) {
                                        tableCellSx.p = (theme) =>
                                            theme.spacing(1);
                                    }

                                    if (columnIdx === 0) {
                                        return (
                                            <TableCell
                                                key={tableCellKey}
                                                component="th"
                                                scope="row"
                                                sx={tableCellSx}
                                            >
                                                {cellContents}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell
                                                key={tableCellKey}
                                                sx={tableCellSx}
                                            >
                                                {cellContents}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {showActions && (
                                    <TableCell>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                            spacing={1}
                                        >
                                            {canReadDescription && (
                                                <TableActionIcon
                                                    iconComponent="description"
                                                    label="read meeting description"
                                                    tooltip="Read Meeting Description"
                                                    onClick={() =>
                                                        onReadDescription(
                                                            meeting
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUserEditMeeting && (
                                                <TableActionIcon
                                                    iconComponent="edit"
                                                    label="edit meeting"
                                                    tooltip="Edit Meeting"
                                                    onClick={() =>
                                                        onEdit(meeting)
                                                    }
                                                />
                                            )}
                                            {canUserApproveMeeting && (
                                                <TableActionIcon
                                                    iconComponent="thumbUp"
                                                    label="approve meeting"
                                                    tooltip="Approve Meeting"
                                                    onClick={() =>
                                                        onUpdateStatus(
                                                            meeting,
                                                            MEETING_APPROVED_STATUS
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUserRequestNewTime && (
                                                <TableActionIcon
                                                    iconComponent="accessTime"
                                                    label="request new time"
                                                    tooltip="Request New Time"
                                                    onClick={() =>
                                                        onRequestNewTime(
                                                            meeting
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUserRejectMeeting && (
                                                <TableActionIcon
                                                    iconComponent="thumbDown"
                                                    label="reject meeting"
                                                    tooltip="Reject Meeting"
                                                    onClick={() =>
                                                        onUpdateStatus(
                                                            meeting,
                                                            MEETING_REJECTED_STATUS
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUserViewSentTimeRequest && (
                                                <TableActionIcon
                                                    iconComponent="alarmOn"
                                                    tooltip="View Sent Time Request"
                                                    label="view sent time request"
                                                    onClick={() =>
                                                        onViewSentTimeRequest(
                                                            meeting
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUserHandleNewTimeRequest && (
                                                <TableActionIcon
                                                    iconComponent="alarm"
                                                    tooltip="New Time Request"
                                                    label="new time request"
                                                    onClick={() =>
                                                        onHandleNewTimeRequest(
                                                            meeting
                                                        )
                                                    }
                                                />
                                            )}
                                            {canUserCancelMeeting && (
                                                <TableActionIcon
                                                    iconComponent="cancel"
                                                    label="cancel meeting"
                                                    tooltip="Cancel Meeting"
                                                    onClick={() =>
                                                        onCancel(meeting)
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={colspan} sx={{ ...paddingSx }}>
                            <Alert
                                severity={meetingsFetched ? "error" : "info"}
                            >
                                {meetingsFetched
                                    ? "There are no records found."
                                    : "Loading meetings..."}
                            </Alert>
                        </TableCell>
                    </TableRow>
                )}
                {showEmptyRow &&
                    meetingCountNonZero &&
                    fetchedMeetingsCount < rowsPerPage && (
                        <TableRow>
                            <TableCell
                                colSpan={colspan}
                                sx={{ ...paddingSx }}
                            ></TableCell>
                        </TableRow>
                    )}
            </TableBody>
        </Table>
    );

    /* const snackBar = (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isClipboardCopied}
            autoHideDuration={6000}
        >
            <Alert severity="info" sx={{ width: "100%" }}>
                Link copied to clipboard!
            </Alert>
        </Snackbar>
    ); */
    const snackBar = null;

    return showColorCard ? (
        <React.Fragment>
            <TableColorCard
                actions={tableActions}
                cardColor={cardColor}
                title={
                    meetingCounts?.[MEETING_KEYS.TOTAL]
                        ? `${meetingCounts[MEETING_KEYS.TOTAL]}`
                        : "NA"
                }
                subtitle={`Upcoming ${
                    meetingCounts?.[MEETING_KEYS.TOTAL] &&
                    meetingCounts[MEETING_KEYS.TOTAL] === 1
                        ? "Meeting"
                        : "Meetings"
                }`}
                sx={{ cardSx: { height: "100%", width: "100%" } }}
            >
                <TableContainer>{table}</TableContainer>
            </TableColorCard>
            {snackBar}
        </React.Fragment>
    ) : (
        <React.Fragment>
            <TableContainer component={Paper}>{table}</TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    meetingCountNonZero
                        ? parseInt(meetingCounts[MEETING_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={meetingCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
            {snackBar}
        </React.Fragment>
    );
};

MeetingsTable.propTypes = {
    ariaLabel: PropTypes.string,
    canApproveMeeting: PropTypes.bool,
    canCancelMeeting: PropTypes.bool,
    canEditMeeting: PropTypes.bool,
    canHandleNewTimeRequest: PropTypes.bool,
    canReadDescription: PropTypes.bool,
    canRejectMeeting: PropTypes.bool,
    canRequestNewTime: PropTypes.bool,
    canViewSentTimeRequest: PropTypes.bool,
    cardColor: PropTypes.string,
    columnsToShow: PropTypes.array,
    meetings: PropTypes.array,
    meetingCounts: PropTypes.object,
    meetingsFetched: PropTypes.bool,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    showColorCard: PropTypes.bool,
    showEmptyRow: PropTypes.bool,
    showLoader: PropTypes.bool,
    sort: PropTypes.string,
    tableActions: PropTypes.array,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    onHandleNewTimeRequest: PropTypes.func,
    onReadDescription: PropTypes.func,
    onRequestNewTime: PropTypes.func,
    onSort: PropTypes.func,
    onUpdateStatus: PropTypes.func,
    onViewSentTimeRequest: PropTypes.func,
};

MeetingsTable.defaultProps = {
    ariaLabel: "Meetings Table",
    canApproveMeeting: false,
    canCancelMeeting: false,
    canEditMeeting: false,
    canHandleNewTimeRequest: false,
    canReadDescription: false,
    canRejectMeeting: false,
    canRequestNewTime: false,
    canViewSentTimeRequest: false,
    cardColor: "",
    columnsToShow: [],
    meetings: [],
    meetingCounts: null,
    meetingsFetched: false,
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    showColorCard: false,
    showEmptyRow: false,
    showLoader: false,
    sort: "",
    tableActions: [],
    handlePageChange: () => {},
    handleRowsPerPageChange: () => {},
    onCancel: () => {},
    onEdit: () => {},
    onHandleNewTimeRequest: () => {},
    onReadDescription: () => {},
    onRequestNewTime: () => {},
    onSort: () => {},
    onUpdateStatus: () => {},
    onViewSentTimeRequest: () => {},
};

export default MeetingsTable;
