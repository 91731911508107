import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { downloadAssetBlob } from "../../redux/actions/accountAssets";
import CustomAlert from "../../shared/widgets/CustomAlert";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import { downloadAccountAsset } from "../../utils/accountAssetHelpers";
import ChatConversation from "./ChatConversation";
import SendMessage from "./SendMessage";

const ChatSection = ({
    canSendMessage,
    chatBox,
    chatFetched,
    errorMessages,
    fetchingChat,
    firstLoad,
    message,
    messages,
    messageFiles,
    messageFilesErrors,
    messageFilesErrorMsg,
    messageKeys,
    sendingMsg,
    sendRestrictionMsg,
    showHeading,
    textAreaConfig,
    onRemoveMessageFile,
    onRemoveMessageFileError,
    onSendMessage,
    onSetMessage,
    onSetMessageFiles,
}) => {
    const _isChatSectionMounted = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            _isChatSectionMounted.current = false;
        };
    }, []);

    const handleDownloadFile = React.useCallback(
        (url, filename, fileMimeType) => {
            if (url && filename && fileMimeType) {
                dispatch(
                    downloadAssetBlob(
                        { url: url },
                        (fileData) => {
                            if (_isChatSectionMounted.current) {
                                downloadAccountAsset(
                                    fileData,
                                    filename,
                                    fileMimeType
                                );
                            }
                        },
                        () => {}
                    )
                );
            }
        },
        [dispatch]
    );

    if (!messageKeys) {
        return null;
    }

    if (fetchingChat && firstLoad) {
        return <FmCircularProgress />;
    }

    const fetchError = !fetchingChat && !chatFetched;

    return (
        <Grid container spacing={2}>
            {showHeading && (
                <Grid item xs={12}>
                    <Typography
                        component="h2"
                        variant="h4"
                        sx={{
                            fontWeight: (theme) =>
                                theme.typography.fontWeightBold,
                        }}
                    >
                        Messages
                    </Typography>
                </Grid>
            )}
            {fetchError && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        There was a problem in fetching the messages!
                    </Alert>
                </Grid>
            )}
            {!fetchError && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <ChatConversation
                            chatBox={chatBox}
                            messages={messages}
                            messageKeys={messageKeys}
                            onDownload={handleDownloadFile}
                        />
                    </Grid>
                    {errorMessages && errorMessages.length > 0 && (
                        <Grid item xs={12}>
                            {errorMessages.map((errorMessage, idx) => (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    color="error"
                                    gutterBottom
                                    key={`misc-error-${idx}`}
                                >
                                    {errorMessage}
                                </Typography>
                            ))}
                        </Grid>
                    )}
                    {canSendMessage ? (
                        <Grid item xs={12}>
                            <SendMessage
                                message={message}
                                messageFiles={messageFiles}
                                messageFilesErrors={messageFilesErrors}
                                messageFilesErrorMsg={messageFilesErrorMsg}
                                sendingMsg={sendingMsg}
                                textAreaConfig={textAreaConfig}
                                onRemoveMessageFile={onRemoveMessageFile}
                                onRemoveMessageFileError={
                                    onRemoveMessageFileError
                                }
                                onSendMessage={onSendMessage}
                                onSetMessage={onSetMessage}
                                onSetMessageFiles={onSetMessageFiles}
                            />
                        </Grid>
                    ) : sendRestrictionMsg ? (
                        <Grid item xs={12}>
                            <CustomAlert
                                colorType="dark"
                                customSx={{
                                    border: "2px solid",
                                }}
                                severity="info"
                                variant="outlined"
                            >
                                {sendRestrictionMsg}
                            </CustomAlert>
                        </Grid>
                    ) : null}
                </React.Fragment>
            )}
        </Grid>
    );
};

ChatSection.propTypes = {
    canSendMessage: PropTypes.bool,
    chatBox: PropTypes.object,
    chatFetched: PropTypes.bool,
    errorMessages: PropTypes.array,
    fetchingChat: PropTypes.bool,
    firstLoad: PropTypes.bool,
    message: PropTypes.string,
    messages: PropTypes.array,
    messageFiles: PropTypes.array,
    messageFilesErrors: PropTypes.array,
    messageFilesErrorMsg: PropTypes.string,
    messageKeys: PropTypes.object,
    sendingMsg: PropTypes.bool,
    sendRestrictionMsg: PropTypes.string,
    showHeading: PropTypes.bool,
    textAreaConfig: PropTypes.object,
    onRemoveMessageFile: PropTypes.func,
    onRemoveMessageFileError: PropTypes.func,
    onSendMessage: PropTypes.func,
    onSetMessage: PropTypes.func,
    onSetMessageFiles: PropTypes.func,
};

ChatSection.defaultProps = {
    canSendMessage: false,
    chatBox: {},
    chatFetched: false,
    errorMessages: [],
    fetchingChat: false,
    firstLoad: false,
    message: "",
    messages: [],
    messageFiles: [],
    messageFilesErrors: [],
    messageFilesErrorMsg: "",
    messageKeys: null,
    sendingMsg: false,
    sendRestrictionMsg: "",
    showHeading: false,
    textAreaConfig: {},
    // onClose: () => {},
};

export default ChatSection;
