import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { updateAssignedServiceRenewalDate } from "../../redux/actions/assignedServices";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    getDateinDesiredFormat,
    getMomentDateObj,
} from "../../utils/dateHelpers";
import { checkDateValid } from "../../utils/validationHelpers";
import {
    ASSIGNED_SERVICE_KEYS,
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";

const UpdateRenewalDateDialog = ({
    currentAssignedService,
    open,
    setGlobalState,
    setLocalState,
    onClose,
    onDispatchAction,
}) => {
    const _isMountedUpdateRenewalDate = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [assignedServiceValid, setAssignedServiceValid] = useState(false);
    const [date, setDate] = useState(null);
    const [dateError, setDateError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedUpdateRenewalDate.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAssignedServiceValid(false);
            setDate(null);
            setDateError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (isValidUserType) {
            if (
                currentAssignedService &&
                currentAssignedService?.[ASSIGNED_SERVICE_KEYS.ID] &&
                currentAssignedService[ASSIGNED_SERVICE_KEYS.ID]
            ) {
                setAssignedServiceValid(true);
            }
        }
    }, [currentAssignedService, isValidUserType]);

    useEffect(() => {
        if (assignedServiceValid) {
            const dateTime = `${
                currentAssignedService[ASSIGNED_SERVICE_KEYS.RENEWAL_DATE]
            } 12:00 AM`;

            const dateTimeObj = getMomentDateObj(dateTime);
            setDate(dateTimeObj);
        }
    }, [assignedServiceValid, currentAssignedService]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case ASSIGNED_SERVICE_KEYS.RENEWAL_DATE:
                        setDateError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const dateChangeHandler = (newValue) => {
        setDate(newValue);
        setDateError("");

        const validationResult = checkDateValid(newValue);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDateError(validationResult.msg);
            } else {
                setDateError("");
            }
        } else {
            setDateError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const dateValidationResult = checkDateValid(date);
        if (dateValidationResult.status) {
            if (dateValidationResult.status === "false") {
                formIsValid = false;
                setDateError(dateValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDateError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const assignedServiceData = {
                authcode: authToken,
                [ASSIGNED_SERVICE_KEYS.ASSIGNED_SERVICE_ID]:
                    currentAssignedService[ASSIGNED_SERVICE_KEYS.ID],
                [ASSIGNED_SERVICE_KEYS.RENEWAL_DATE]: getDateinDesiredFormat(
                    date,
                    "MM/DD/YYYY"
                ),
            };

            dispatch(
                updateAssignedServiceRenewalDate(
                    {
                        assignedServiceData: assignedServiceData,
                        setGlobalState: setGlobalState,
                    },
                    (updatedAssignedService) => {
                        if (_isMountedUpdateRenewalDate.current) {
                            if (setLocalState) {
                                onDispatchAction({
                                    type: "UPDATE",
                                    updatedService: updatedAssignedService,
                                });
                            }
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isMountedUpdateRenewalDate.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const errorOnLoading = !isValidUserType || !assignedServiceValid;

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    assignedServiceValid
                        ? "Update Service Renewal Time"
                        : "Invalid service!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {errorOnLoading && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !assignedServiceValid && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    Invalid service!
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!errorOnLoading && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DatePicker
                                        label="Date"
                                        inputFormat="MM/DD/YYYY"
                                        minDate={new Date()}
                                        value={date}
                                        onChange={dateChangeHandler}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id="date"
                                                name="date"
                                                margin="dense"
                                                error={dateError !== ""}
                                                helperText={dateError}
                                                /* onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} */
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

UpdateRenewalDateDialog.propTypes = {
    currentAssignedService: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    setLocalState: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

UpdateRenewalDateDialog.defaultProps = {
    currentAssignedService: null,
    open: false,
    setGlobalState: false,
    setLocalState: false,
    // onClose: () => {},
    onDispatchAction: () => {},
};

export default UpdateRenewalDateDialog;
