import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CKEditor } from "ckeditor4-react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import NameLink from "../../shared/widgets/NameLink";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    CASE_STUDY_KEYS,
    CKEDITOR_TOOLBAR,
} from "../../utils/constants/appData";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";
import {
    checkCaseStudyDescriptionValid,
    checkCaseStudyDocumentValid,
    /* checkCaseStudyProcedureValid, */
} from "../../utils/validationHelpers";
import { addCaseStudy, updateCaseStudy } from "../../redux/actions/caseStudies";

const AddEditCaseStudyDialog = ({
    currentCaseStudy,
    fetchOnInsert,
    open,
    onClose,
    onDispatchAction,
}) => {
    const _isAddEditCaseStudyDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken } = useJumboAuth();

    const [before, setBefore] = useState(null);
    const [beforeError, setBeforeError] = useState("");
    const [beforeLink, setBeforeLink] = useState("");
    const [after, setAfter] = useState(null);
    const [afterError, setAfterError] = useState("");
    const [afterLink, setAfterLink] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    /* const [procedure, setProcedure] = useState("");
    const [procedureError, setProcedureError] = useState(""); */
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        return () => {
            _isAddEditCaseStudyDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setBefore(null);
            setBeforeError("");
            setBeforeLink("");
            setAfter(null);
            setAfterError("");
            setAfterLink("");
            setDescription("");
            setDescriptionError("");
            /* setProcedure("");
            setProcedureError(""); */
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (currentCaseStudy) {
            setBefore(null);
            setBeforeLink(currentCaseStudy[CASE_STUDY_KEYS.BEFORE_URL]);
            setAfter(null);
            setAfterLink(currentCaseStudy[CASE_STUDY_KEYS.AFTER_URL]);
            setDescription(currentCaseStudy[CASE_STUDY_KEYS.DESCRIPTION] || "");
            /* setProcedure(currentCaseStudy[CASE_STUDY_KEYS.PROCEDURE]); */
        }
    }, [currentCaseStudy]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case CASE_STUDY_KEYS.BEFORE:
                        setBeforeError(msg);
                        break;

                    case CASE_STUDY_KEYS.AFTER:
                        setAfterError(msg);
                        break;

                    case CASE_STUDY_KEYS.DESCRIPTION:
                        setDescriptionError(msg);
                        break;

                    /* case CASE_STUDY_KEYS.PROCEDURE:
                        setProcedureError(msg);
                        break; */

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const beforeChangeHandler = (event) => {
        const files = event.target.files;
        let isValid = true;
        const validationResult = checkCaseStudyDocumentValid(files);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                isValid = false;
                setBeforeError(validationResult.msg);
            }
        } else {
            isValid = false;
            setBeforeError(VALIDATION_FAILED);
        }

        if (isValid) {
            setBefore(files[0]);
            setBeforeError("");
        } else {
            setBefore(null);
        }
    };

    const afterChangeHandler = (event) => {
        const files = event.target.files;
        let isValid = true;
        const validationResult = checkCaseStudyDocumentValid(files);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                isValid = false;
                setAfterError(validationResult.msg);
            }
        } else {
            isValid = false;
            setAfterError(VALIDATION_FAILED);
        }

        if (isValid) {
            setAfter(files[0]);
            setAfterError("");
        } else {
            setAfter(null);
        }
    };

    const descriptionChangeHandler = (event) => {
        setDescription(event.editor.getData() || "");
        setDescriptionError("");
    };

    const descriptionBlurHandler = (event) => {
        const validationResult = checkCaseStudyDescriptionValid(
            event.editor.getData()
        );
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDescriptionError(validationResult.msg);
            }
        } else {
            setDescriptionError(VALIDATION_FAILED);
        }
    };

    /* const procedureChangeHandler = (event) => {
        setProcedure(event.editor.getData());
        setProcedureError("");
    };

    const procedureBlurHandler = (event) => {
        const validationResult = checkCaseStudyProcedureValid(
            event.editor.getData()
        );
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setProcedureError(validationResult.msg);
            }
        } else {
            setProcedureError(VALIDATION_FAILED);
        }
    }; */

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        if (!currentCaseStudy || (currentCaseStudy && before)) {
            const beforeValidationResult = checkCaseStudyDocumentValid([
                before,
            ]);
            if (beforeValidationResult.status) {
                if (beforeValidationResult.status === "false") {
                    formIsValid = false;
                    setBeforeError(beforeValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setBeforeError(VALIDATION_FAILED);
            }
        }

        if (!currentCaseStudy || (currentCaseStudy && after)) {
            const afterValidationResult = checkCaseStudyDocumentValid([after]);
            if (afterValidationResult.status) {
                if (afterValidationResult.status === "false") {
                    formIsValid = false;
                    setAfterError(afterValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setAfterError(VALIDATION_FAILED);
            }
        }

        const descriptionValidationResult =
            checkCaseStudyDescriptionValid(description);
        if (descriptionValidationResult.status) {
            if (descriptionValidationResult.status === "false") {
                formIsValid = false;
                setDescriptionError(descriptionValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDescriptionError(VALIDATION_FAILED);
        }

        /* const procedureValidationResult =
            checkCaseStudyProcedureValid(procedure);
        if (procedureValidationResult.status) {
            if (procedureValidationResult.status === "false") {
                formIsValid = false;
                setProcedureError(procedureValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setProcedureError(VALIDATION_FAILED);
        } */

        if (formIsValid) {
            const caseStudyData = new FormData();
            caseStudyData.append("authcode", authToken);
            caseStudyData.append(
                [CASE_STUDY_KEYS.DESCRIPTION],
                description || ""
            );
            /* caseStudyData.append([CASE_STUDY_KEYS.PROCEDURE], procedure); */

            if (currentCaseStudy) {
                if (before)
                    caseStudyData.append([CASE_STUDY_KEYS.BEFORE], before);
                if (after) caseStudyData.append([CASE_STUDY_KEYS.AFTER], after);

                caseStudyData.append(
                    [CASE_STUDY_KEYS.CASE_STUDY_ID],
                    currentCaseStudy[CASE_STUDY_KEYS.ID]
                );

                dispatch(
                    updateCaseStudy(
                        { caseStudyData: caseStudyData },
                        (updatedCaseStudy) => {
                            if (_isAddEditCaseStudyDialogMounted.current) {
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditCaseStudyDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                caseStudyData.append([CASE_STUDY_KEYS.BEFORE], before);
                caseStudyData.append([CASE_STUDY_KEYS.AFTER], after);

                dispatch(
                    addCaseStudy(
                        {
                            caseStudyData: caseStudyData,
                            fetchOnInsert: fetchOnInsert,
                        },
                        () => {
                            if (_isAddEditCaseStudyDialogMounted.current) {
                                if (fetchOnInsert)
                                    onDispatchAction({ type: "INSERT" });
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditCaseStudyDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentCaseStudy ? "Edit Case Study" : "Add Case Study"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        {miscellaneousErrors &&
                            miscellaneousErrors.length > 0 && (
                                <Grid item xs={12}>
                                    {miscellaneousErrors.map(
                                        (miscellaneousError, idx) => (
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                color="error"
                                                gutterBottom
                                                key={`misc-error-${idx}`}
                                            >
                                                {miscellaneousError}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <label
                                htmlFor="before"
                                style={{ display: "block", marginTop: 8 }}
                            >
                                <input
                                    id="before"
                                    name="before"
                                    type="file"
                                    onChange={beforeChangeHandler}
                                    style={{ display: "none" }}
                                />

                                <AttachFileButton
                                    fullWidth
                                    type="button"
                                    variant="outlined"
                                    component="span"
                                    startIcon={<AttachFileIcon />}
                                    sx={(theme) => {
                                        const beforeErrorSx = beforeError
                                            ? {
                                                  color: theme.palette.error
                                                      .main,
                                                  border: `1px solid ${theme.palette.error.main}`,
                                              }
                                            : "";
                                        return {
                                            ...beforeErrorSx,
                                        };
                                    }}
                                >
                                    {before ? before.name : "Before"}
                                </AttachFileButton>
                            </label>
                            {beforeError && (
                                <FormHelperText error id="before-error">
                                    {beforeError}
                                </FormHelperText>
                            )}
                        </Grid>
                        {beforeLink && (
                            <Grid item xs={12}>
                                <NameLink
                                    noWrap
                                    align="center"
                                    href={beforeLink}
                                    target="_blank"
                                    sx={(theme) => ({
                                        display: "block",
                                        wordWrap: "break-word",
                                        ...theme.typography.h5,
                                    })}
                                >
                                    View Before
                                </NameLink>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <label
                                htmlFor="after"
                                style={{ display: "block", marginTop: 8 }}
                            >
                                <input
                                    id="after"
                                    name="after"
                                    type="file"
                                    onChange={afterChangeHandler}
                                    style={{ display: "none" }}
                                />

                                <AttachFileButton
                                    fullWidth
                                    type="button"
                                    variant="outlined"
                                    component="span"
                                    startIcon={<AttachFileIcon />}
                                    sx={(theme) => {
                                        const afterErrorSx = afterError
                                            ? {
                                                  color: theme.palette.error
                                                      .main,
                                                  border: `1px solid ${theme.palette.error.main}`,
                                              }
                                            : "";
                                        return {
                                            ...afterErrorSx,
                                        };
                                    }}
                                >
                                    {after ? after.name : "After"}
                                </AttachFileButton>
                            </label>
                            {afterError && (
                                <FormHelperText error id="after-error">
                                    {afterError}
                                </FormHelperText>
                            )}
                        </Grid>
                        {afterLink && (
                            <Grid item xs={12}>
                                <NameLink
                                    noWrap
                                    align="center"
                                    href={afterLink}
                                    target="_blank"
                                    sx={(theme) => ({
                                        display: "block",
                                        wordWrap: "break-word",
                                        ...theme.typography.h5,
                                    })}
                                >
                                    View After
                                </NameLink>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                component="label"
                                variant="body1"
                                htmlFor="description"
                            >
                                Description
                            </Typography>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <CKEditor
                                    id="description"
                                    initData={description}
                                    config={{
                                        contentsCss: [
                                            `${ASSET_MUSEO_SANS}/styles.css`,
                                            `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                        ],
                                        toolbar: CKEDITOR_TOOLBAR,
                                        removePlugins: "image",
                                    }}
                                    onBlur={descriptionBlurHandler}
                                    onChange={descriptionChangeHandler}
                                />
                                {descriptionError && (
                                    <FormHelperText
                                        error
                                        id="description-error"
                                    >
                                        {descriptionError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography
                                component="label"
                                variant="body1"
                                htmlFor="procedure"
                            >
                                Procedure
                            </Typography>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <CKEditor
                                    id="procedure"
                                    initData={procedure}
                                    config={{
                                        contentsCss: [
                                            `${ASSET_MUSEO_SANS}/styles.css`,
                                            `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                        ],
                                        toolbar: CKEDITOR_TOOLBAR,
                                        removePlugins: "image",
                                    }}
                                    onBlur={procedureBlurHandler}
                                    onChange={procedureChangeHandler}
                                />
                                {procedureError && (
                                    <FormHelperText error id="procedure-error">
                                        {procedureError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {currentCaseStudy
                                    ? "Update Case Study"
                                    : "Add Case Study"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

AddEditCaseStudyDialog.propTypes = {
    currentCaseStudy: PropTypes.object,
    fetchOnInsert: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

AddEditCaseStudyDialog.defaultProps = {
    currentCaseStudy: null,
    fetchOnInsert: false,
    open: false,
    // onClose: () => {},
    // onDispatchAction: () => {},
};

export default AddEditCaseStudyDialog;
