import React, { useMemo, useRef } from "react";
import { CKEditor } from "ckeditor4-react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChatAcceptedFiles from "../../shared/widgets/ChatAcceptedFiles";
import ChatRejectedFiles from "../../shared/widgets/ChatRejectedFiles";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    CKEDITOR_TOOLBAR,
    VALID_FILE_AUDIO_MIME_TYPES,
    VALID_FILE_DOC_MIME_TYPES,
    VALID_FILE_GRAPHICS_MIME_TYPES,
    VALID_FILE_IMG_MIME_TYPES,
    VALID_FILE_SHEET_MIME_TYPES,
    VALID_FILE_VIDEO_MIME_TYPES,
} from "../../utils/constants/appData";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";

const SendMessage = ({
    message,
    messageFiles,
    messageFilesErrors,
    messageFilesErrorMsg,
    sendingMsg,
    textAreaConfig,
    onRemoveMessageFile,
    onRemoveMessageFileError,
    onSendMessage,
    onSetMessage,
    onSetMessageFiles,
}) => {
    const hiddenFileInput = useRef(null);

    const handleFileClick = (event) => {
        hiddenFileInput.current.click();
    };

    const acceptedFilesHTML = useMemo(() => {
        if (messageFiles && messageFiles.length > 0) {
            return (
                <React.Fragment>
                    <Typography
                        component="p"
                        variant="h6"
                        sx={(theme) => ({
                            m: 0,
                            p: theme.spacing(1, 2),
                            fontWeight: theme.typography.fontWeightBold,
                        })}
                    >
                        Accepted Files:
                    </Typography>
                    <ChatAcceptedFiles
                        files={messageFiles}
                        onRemove={onRemoveMessageFile}
                    />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }, [messageFiles, onRemoveMessageFile]);

    const rejectedFilesHTML = useMemo(() => {
        if (messageFilesErrors && messageFilesErrors.length > 0) {
            return (
                <React.Fragment>
                    <Typography
                        component="p"
                        variant="h6"
                        sx={(theme) => ({
                            m: 0,
                            p: theme.spacing(1, 2),
                            fontWeight: theme.typography.fontWeightBold,
                        })}
                    >
                        {messageFilesErrorMsg || "Rejected Files:"}
                    </Typography>
                    <ChatRejectedFiles
                        errors={messageFilesErrors}
                        onRemove={onRemoveMessageFileError}
                    />
                </React.Fragment>
            );
        } else if (messageFilesErrorMsg) {
            return (
                <Typography
                    component="p"
                    variant="h6"
                    sx={(theme) => ({
                        m: 0,
                        p: theme.spacing(1, 2),
                        color: theme.palette.error.main,
                    })}
                >
                    {messageFilesErrorMsg}
                </Typography>
            );
        } else {
            return null;
        }
    }, [messageFilesErrors, messageFilesErrorMsg, onRemoveMessageFileError]);

    const validMimeTypes = [
        ...VALID_FILE_AUDIO_MIME_TYPES,
        ...VALID_FILE_DOC_MIME_TYPES,
        ...VALID_FILE_GRAPHICS_MIME_TYPES,
        ...VALID_FILE_IMG_MIME_TYPES,
        ...VALID_FILE_SHEET_MIME_TYPES,
        ...VALID_FILE_VIDEO_MIME_TYPES,
    ];

    return (
        <Stack
            direction="column"
            flexWrap="nowrap"
            sx={{
                border: "2px solid rgba(0, 0, 0, 0.23)",
                backgroundColor: "transparent",
                "& .cke_chrome": {
                    border: "none",
                },
                "& .cke_inner.cke_reset": {
                    border: "none",
                },
                "& .cke_top.cke_reset_all": {
                    borderBottom: "2px solid rgba(0, 0, 0, 0.23)",
                },
                "& .cke_bottom.cke_reset_all": {
                    display: "none",
                },
            }}
        >
            <CKEditor
                id="message"
                initData={message}
                config={{
                    contentsCss: [
                        `${ASSET_MUSEO_SANS}/styles.css`,
                        `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                    ],
                    toolbar: CKEDITOR_TOOLBAR,
                    removePlugins: "image",
                    ...textAreaConfig,
                }}
                onChange={(e) => onSetMessage(e.editor.getData())}
            />
            <Divider
                sx={{
                    borderBottom: "2px solid rgba(0, 0, 0, 0.23)",
                }}
            />
            <input
                multiple
                type="file"
                ref={hiddenFileInput}
                accept={validMimeTypes.join(",")}
                onChange={(e) => onSetMessageFiles(e)}
                style={{ display: "none" }}
            />
            {acceptedFilesHTML}
            {rejectedFilesHTML}
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent={sendingMsg ? "flex-end" : "space-between"}
            >
                {sendingMsg ? (
                    <CircularProgress
                        size={40}
                        sx={{ p: (theme) => theme.spacing(1) }}
                    />
                ) : (
                    <React.Fragment>
                        <TableActionIcon
                            iconComponent="attachFile"
                            label="upload file(s)"
                            tooltip="Upload File(s)"
                            onClick={handleFileClick}
                        />
                        <TableActionIcon
                            iconComponent="send"
                            label="send message"
                            tooltip="Send Message"
                            onClick={onSendMessage}
                        />
                    </React.Fragment>
                )}
            </Stack>
        </Stack>
    );
};

SendMessage.propTypes = {
    message: PropTypes.string,
    messageFiles: PropTypes.array,
    messageFilesErrors: PropTypes.array,
    messageFilesErrorMsg: PropTypes.string,
    sendingMsg: PropTypes.bool,
    textAreaConfig: PropTypes.object,
    onRemoveMessageFile: PropTypes.func,
    onRemoveMessageFileError: PropTypes.func,
    onSendMessage: PropTypes.func,
    onSetMessage: PropTypes.func,
    onSetMessageFiles: PropTypes.func,
};

SendMessage.defaultProps = {
    message: "",
    messageFiles: [],
    messageFilesErrors: [],
    messageFilesErrorMsg: "",
    sendingMsg: false,
    textAreaConfig: {},
    // onRemoveMessageFile: () => {},
    // onRemoveMessageFileError: () => {},
    // onSendMessage: () => {},
    // onSetMessage: () => {},
    // onSetMessageFiles: () => {},
};

export default SendMessage;
