import React from "react";
import PropTypes from "prop-types";

import Div from "@jumbo/shared/Div";
import useJumboLayoutRoot from "@jumbo/hooks/useJumboLayoutRoot";
import useJumboLayoutContent from "@jumbo/hooks/useJumboLayoutContent";

import { CssBaseline } from "@mui/material";

const FullPageLayout = (props) => {
    const { rootOptions } = useJumboLayoutRoot();
    const { contentOptions } = useJumboLayoutContent();

    return (
        <Div
            sx={{
                display: "flex",
                flex: 1,
                minWidth: 0,
                minHeight: "100%",
                flexDirection: "column",
                ...rootOptions?.sx,
            }}
            className="CmtLayout-root"
        >
            <CssBaseline />
            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    minWidth: 0,
                    position: "relative",
                }}
                className="CmtLayout-wrapper"
            >
                <Div
                    sx={{
                        display: "flex",
                        minWidth: 0,
                        flex: 1,
                        flexDirection: "column",
                        minHeight: "100%",
                        marginLeft: 0,
                        transition: (theme) =>
                            theme.transitions.create(["margin-left"]),
                    }}
                    className="CmtLayout-main"
                >
                    <Div
                        sx={{
                            display: "flex",
                            minWidth: 0,
                            flex: 1,
                            flexDirection: "column",
                            py: 4,
                            px: { lg: 6, xs: 4 },
                            ...(contentOptions?.sx ?? {}),
                        }}
                        className="CmtLayout-content"
                    >
                        {props.children}
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

FullPageLayout.propTypes = {
    children: PropTypes.node,
};

export default FullPageLayout;
