import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

const NoMessagesAlert = ({ showBorder }) => {
    return (
        <Stack
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={(theme) => ({
                height: "100%",
                p: theme.spacing(3, 1),
                border: showBorder
                    ? `1px solid ${theme.palette.primary.light}`
                    : "none",
                borderRadius: showBorder ? "8px" : 0,
            })}
        >
            <ChatBubbleOutlineIcon />
            <Typography component="p" variant="body1" sx={{ m: 0 }}>
                There are no messages in this conversation!
            </Typography>
        </Stack>
    );
};

NoMessagesAlert.propTypes = {
    showBorder: PropTypes.bool,
};

NoMessagesAlert.defaultProps = {
    showBorder: false,
};

export default NoMessagesAlert;
