import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { getSurveyAllQuestionTypes } from "../../utils/appHelpers";
import {
    SURVEY_QUESTION_KEYS,
    SURVEY_QUESTION_IS_DELETED,
    SURVEY_QUESTION_OPTION_KEYS,
    SURVEY_QUESTION_OPTION_IS_NOT_DELETED,
} from "../../utils/constants/appData";

const allQuestionTypesData = getSurveyAllQuestionTypes();

const SurveyFormQuestion = ({
    numQuestions,
    question,
    questionIdx,
    onDelete,
    onEdit,
}) => {
    const questionDataValid = useMemo(() => {
        const questionIsValid =
            question &&
            question?.[SURVEY_QUESTION_KEYS.ID] &&
            question[SURVEY_QUESTION_KEYS.ID];
        if (
            numQuestions > 0 &&
            questionIsValid &&
            questionIdx >= 0 &&
            question[SURVEY_QUESTION_KEYS.IS_DELETED] !==
                SURVEY_QUESTION_IS_DELETED
        ) {
            return true;
        } else {
            return false;
        }
    }, [numQuestions, question, questionIdx]);

    if (!questionDataValid) {
        return null;
    }

    const type = question[SURVEY_QUESTION_KEYS.TYPE];
    const typeLabel = allQuestionTypesData.find(
        (typeObj) => typeObj.value === type
    ).label;

    const options = question[SURVEY_QUESTION_KEYS.OPTIONS];

    return (
        <ListItem
            disablePadding
            divider={questionIdx < numQuestions - 1}
            sx={{ py: (theme) => theme.spacing(1.5) }}
        >
            <ListItemText
                disableTypography
                primary={
                    <Stack direction="column" spacing={1}>
                        <Chip
                            label={typeLabel}
                            variant="outlined"
                            size="small"
                            sx={(theme) => ({
                                alignSelf: "start",
                                borderColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.main,
                            })}
                        />
                        <Stack
                            direction={{ xs: "column-reverse", md: "row" }}
                            alignItems="flex-start"
                            justifyContent={{
                                xs: "flex-start",
                                md: "space-between",
                            }}
                            spacing={1}
                        >
                            <Typography
                                component="p"
                                variant="h5"
                                sx={(theme) => ({
                                    m: 0,
                                    fontWeight: theme.typography.fontWeightBold,
                                })}
                            >
                                {question[SURVEY_QUESTION_KEYS.TITLE]}
                            </Typography>
                            <Stack direction="row" spacing={1}>
                                <TableActionIcon
                                    iconComponent="edit"
                                    label="edit question"
                                    tooltip="Edit Question"
                                    onClick={() =>
                                        onEdit(question, questionIdx)
                                    }
                                    btnSx={{ p: 0 }}
                                />
                                <TableActionIcon
                                    iconComponent="delete"
                                    label="delete question"
                                    tooltip="Delete Question"
                                    onClick={() =>
                                        onDelete(question, questionIdx)
                                    }
                                    btnSx={{ p: 0 }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                }
                secondary={
                    options && options.length > 0 ? (
                        <ul style={{ marginTop: "8px" }}>
                            {options.map((option, optionIdx) => {
                                if (
                                    option[
                                        SURVEY_QUESTION_OPTION_KEYS.IS_DELETED
                                    ] === SURVEY_QUESTION_OPTION_IS_NOT_DELETED
                                ) {
                                    return (
                                        <li
                                            key={`questions-${questionIdx}-${optionIdx}`}
                                        >
                                            {
                                                option[
                                                    SURVEY_QUESTION_OPTION_KEYS
                                                        .TITLE
                                                ]
                                            }
                                        </li>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </ul>
                    ) : null
                }
            />
        </ListItem>
    );
};

SurveyFormQuestion.propTypes = {
    numQuestions: PropTypes.number,
    question: PropTypes.object,
    questionIdx: PropTypes.number,
    open: PropTypes.bool,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

SurveyFormQuestion.defaultProps = {
    numQuestions: 0,
    question: null,
    questionIdx: -1,
    open: false,
    // onDelete: () => {},
    // onEdit: () => {},
};

export default SurveyFormQuestion;
