import React from "react";
import Page from "@jumbo/shared/Page";

import AdminFmAssignedServices from "../pages/assignedServices/AdminFmAssignedServices";
import ClientAssignedServices from "../pages/assignedServices/ClientAssignedServices";
import ClientFmUsers from "../pages/clientFmUsers";
import CompanyReports from "../pages/reports/CompanyReports";
import Home from "../pages/home";
import Logout from "../pages/logout";
import Services from "../pages/services";

import accountAssetsRoutes from "./accountAssetsRoutes";
import alertsRoutes from "./alertsRoutes";
import announcementsRoutes from "./announcementsRoutes";
import authRoutes from "./authRoutes";
/* import billingRoutes from "./billingRoutes"; */
import caseStudiesRoutes from "./caseStudiesRoutes";
import clientSurveyRoutes from "./clientSurveyRoutes";
import clientUsersRoutes from "./clientUsersRoutes";
import companiesRoutes from "./companiesRoutes";
import meetingsRoutes from "./meetingsRoutes";
import extraRoutes from "./extraRoutes";
import fmUsersRoutes from "./fmUsersRoutes";
import leadSheetsRoutes from "./leadSheetsRoutes";
import profileRoutes from "./profileRoutes";
import reportsRoutes from "./reportsRoutes";
import settingsRoutes from "./settingsRoutes";
import specialsRoutes from "./specialsRoutes";
import surveyRoutes from "./surveyRoutes";
import surveysRoutes from "./surveysRoutes";
import ticketsRoutes from "./ticketsRoutes";

import Admin from "./middleware/Admin";
/* import AdminClientUser from "./middleware/AdminClientUser"; */
import AdminFmUser from "./middleware/AdminFmUser";
import Auth from "./middleware/Auth";
/* import ClientFmUser from "./middleware/ClientFmUser"; */
import ClientUser from "./middleware/ClientUser";
/* import FmUser from "./middleware/FmUser"; */
import NotAuth from "./middleware/NotAuth";
import { config } from "../config/main";
import { SERVICES_PAGE_LINK } from "../utils/constants/appData";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    /* For ADMIN */
    {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
            {
                element: Admin,
                fallbackPath: config.authSetting.fallbackPath ?? "/",
            },
        ],
        routes: [...announcementsRoutes, ...fmUsersRoutes, ...settingsRoutes],
    },
    /* For FM USERS */
    /* {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
            {
                element: FmUser,
                fallbackPath: config.authSetting.fallbackPath ?? "/",
            },
        ],
        routes: [],
    }, */
    /* For CLIENT USERS */
    {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
            {
                element: ClientUser,
                fallbackPath: config.authSetting.fallbackPath ?? "/",
            },
        ],
        routes: [
            {
                path: "/fmUser",
                element: <Page component={ClientFmUsers} />,
            },
            {
                path: `${SERVICES_PAGE_LINK}/assigned`,
                element: <Page component={ClientAssignedServices} />,
            },
            ...caseStudiesRoutes,
            ...clientSurveyRoutes,
            /* ...billingRoutes, */
            ...leadSheetsRoutes,
        ],
    },
    /* For ADMIN + FM USERS */
    {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
            {
                element: AdminFmUser,
                fallbackPath: config.authSetting.fallbackPath ?? "/",
            },
        ],
        routes: [
            {
                path: "/reports/:companyId/",
                element: <Page component={CompanyReports} />,
            },
            {
                path: `${SERVICES_PAGE_LINK}/assigned/:companyId/`,
                element: <Page component={AdminFmAssignedServices} />,
            },
            ...clientUsersRoutes,
            ...companiesRoutes,
            ...surveyRoutes,
        ],
    },
    /* For ADMIN + CLIENT USERS */
    /* {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
            {
                element: AdminClientUser,
                fallbackPath: config.authSetting.fallbackPath ?? "/",
            },
        ],
        routes: [],
    }, */
    /* For FM USERS + CLIENT USERS */
    /* {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
            {
                element: ClientFmUser,
                fallbackPath: config.authSetting.fallbackPath ?? "/",
            },
        ],
        routes: [],
    }, */
    /* For ALL authenticated users */
    {
        middleware: [
            {
                element: Auth,
                fallbackPath: config.authSetting.fallbackPath ?? "/login",
            },
        ],
        routes: [
            {
                path: "/",
                element: <Page component={Home} />,
            },
            {
                path: "/logout",
                element: <Page component={Logout} />,
            },
            {
                path: SERVICES_PAGE_LINK,
                element: <Page component={Services} />,
            },
            ...accountAssetsRoutes,
            ...alertsRoutes,
            ...meetingsRoutes,
            ...extraRoutes,
            ...profileRoutes,
            ...reportsRoutes,
            ...specialsRoutes,
            ...surveysRoutes,
            ...ticketsRoutes,
        ],
    },
];

/**
 * routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        middleware: [
            {
                element: NotAuth,
                fallbackPath:
                    config.authSetting.redirectNotAuthenticatedPath ?? "/",
            },
        ],
        routes: [...authRoutes],
    },
];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {
    routes as default,
    routesForPublic,
    routesForNotAuthenticatedOnly,
    routesForAuthenticatedOnly,
};
