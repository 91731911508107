import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { fetchError, fetchSuccess } from "../../../redux/actions/common";
import { verifyCode } from "../../../redux/actions/auth";
import authServices from "../../../services/auth-services";
import ContentLoader from "../../../shared/ContentLoader";
import LockIcon from "../../../shared/SVGImages/LockIcon";
import SVGLogo from "../../../shared/SVGLogo";
import AuthLink from "../../../shared/widgets/AuthLink";
import FmCircularProgress from "../../../shared/widgets/FmCircularProgress";
import PageTitle from "../../../shared/widgets/PageTitle";
import { getAssetPath } from "../../../utils/appHelpers";
import { APP_NAME } from "../../../utils/constants/appData";
import { ASSET_IMAGES } from "../../../utils/constants/paths";

const validationSchema = yup.object({
    password: yup
        .string("Enter your password")
        .required("Password is required"),
    confirmPassword: yup
        .string("Confirm your password")
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const pageName = "Reset Password";

const ResetPassword = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isResetPasswordMounted = useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const isResponsiveMode = useMediaQuery("(max-width:575px)");

    const code = params?.code || "";

    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState("");
    const [authError, setAuthError] = useState("");
    const [authSuccess, setAuthSuccess] = useState("");
    const [user, setUser] = useState(null);

    useEffect(() => {
        return () => {
            _isResetPasswordMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (authError) {
            dispatch(fetchError(authError));
            setAuthError("");
        }
    }, [dispatch, authError]);

    useEffect(() => {
        if (authSuccess) {
            dispatch(fetchSuccess(authSuccess));
            setAuthSuccess("");
        }
    }, [dispatch, authSuccess]);

    useEffect(() => {
        let isActive = true;

        if (isLoading) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        verifyCode(payload, (data) => {
                            if (isActive) {
                                setIsLoading(false);
                                if (data) {
                                    setUser(data);
                                } else {
                                    setLoadError(
                                        "There was a problem in verifying the request"
                                    );
                                }
                            }
                        })
                    );
                };
            };

            const authData = { code: code };
            const promise = dispatch(fetchData({ authData: authData }));
            promise.catch((error) => {
                if (isActive) {
                    setIsLoading(false);
                    setUser(null);
                    setLoadError(error.message);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, code, isLoading]);

    const onResetPassword = (
        userId,
        userEmail,
        password,
        confirmPassword,
        callBackFunc
    ) => {
        authServices
            .resetPassword({
                user_id: userId,
                email: userEmail,
                password: password,
                confirm_password: confirmPassword,
            })
            .then((data) => {
                if (data.hasError) {
                    callBackFunc();
                    setAuthError(data.error);
                } else {
                    callBackFunc();
                    if (data.success) {
                        setAuthSuccess(data.success);
                    } else {
                        setAuthError(
                            "There was a problem in getting the message"
                        );
                    }
                    setTimeout(() => {
                        navigate("/login");
                    }, 1000);
                }
            });
    };

    const loginComponent = (
        <AuthLink
            component={RouterLink}
            to="/login"
            sx={{
                textTransform: "none",
            }}
        >
            Back to login
        </AuthLink>
    );

    return (
        <Paper
            sx={{
                width: "100%",
                height: "100%",
                m: 0,
                p: 0,
            }}
        >
            <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid item lg={6} md={12} sx={{ height: "100%" }}>
                    {isLoading && (
                        <FmCircularProgress label="Checking request" />
                    )}
                    {!isLoading && loadError && (
                        <Grid
                            container
                            rowSpacing={1}
                            sx={(theme) => ({
                                p: 12,
                                [theme.breakpoints.down("lg")]: {
                                    px: 8,
                                },
                                [theme.breakpoints.down("md")]: {
                                    px: 4,
                                },
                                [theme.breakpoints.down("sm")]: {
                                    px: 2,
                                },
                            })}
                        >
                            <Grid item xs={12} sx={{ mb: 1.5 }}>
                                <SVGLogo
                                    svgSx={{
                                        height: "auto",
                                        fontSize: isResponsiveMode
                                            ? "16rem"
                                            : "24rem",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <PageTitle
                                    component="h1"
                                    title={pageName}
                                    type="v2"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <Alert variant="outlined" severity="error">
                                    {loadError}
                                </Alert>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                {loginComponent}
                            </Grid>
                        </Grid>
                    )}
                    {!isLoading && !loadError && (
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                password: "",
                                confirmPassword: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting }) => {
                                setSubmitting(true);
                                onResetPassword(
                                    user.id,
                                    user.email,
                                    data.password,
                                    data.confirmPassword,
                                    () => {
                                        setSubmitting(false);
                                    }
                                );
                                /* setSubmitting(false); */
                            }}
                        >
                            {({ isSubmitting }) => {
                                return (
                                    <Form
                                        style={{ textAlign: "left" }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Grid
                                            container
                                            rowSpacing={1}
                                            sx={(theme) => ({
                                                p: 12,
                                                [theme.breakpoints.down("lg")]:
                                                    {
                                                        px: 8,
                                                    },
                                                [theme.breakpoints.down("md")]:
                                                    {
                                                        px: 4,
                                                    },
                                                [theme.breakpoints.down("sm")]:
                                                    {
                                                        px: 2,
                                                    },
                                            })}
                                        >
                                            <Grid item xs={12} sx={{ mb: 1.5 }}>
                                                <SVGLogo
                                                    svgSx={{
                                                        height: "auto",
                                                        fontSize:
                                                            isResponsiveMode
                                                                ? "16rem"
                                                                : "24rem",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{ mb: 3 }}>
                                                <PageTitle
                                                    component="h1"
                                                    title={pageName}
                                                    type="v2"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{ mb: 3 }}>
                                                <JumboTextField
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="password"
                                                    label={null}
                                                    placeholder="Password"
                                                    InputProps={{
                                                        notched: false,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon
                                                                    sx={{
                                                                        color: "#487AA3",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                        sx: {
                                                            background:
                                                                "#F4F7FC 0% 0% no-repeat padding-box",
                                                            borderColor:
                                                                "#243463",
                                                            borderRadius: "2px",
                                                            opacity: 1,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{ mb: 3 }}>
                                                <JumboTextField
                                                    type="password"
                                                    fullWidth
                                                    variant="outlined"
                                                    name="confirmPassword"
                                                    label={null}
                                                    placeholder="Confirm Password"
                                                    InputProps={{
                                                        notched: false,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon
                                                                    sx={{
                                                                        color: "#487AA3",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                        sx: {
                                                            background:
                                                                "#F4F7FC 0% 0% no-repeat padding-box",
                                                            borderColor:
                                                                "#243463",
                                                            borderRadius: "2px",
                                                            opacity: 1,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: false,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{ mb: 3 }}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    sx={(theme) => ({
                                                        [theme.breakpoints.down(
                                                            "md"
                                                        )]: {
                                                            flexWrap: "wrap",
                                                        },
                                                    })}
                                                >
                                                    <Div
                                                        sx={(theme) => ({
                                                            flex: "1 1 45%",
                                                            [theme.breakpoints.down(
                                                                "md"
                                                            )]: {
                                                                flex: "1 1 100%",
                                                                mb: 3,
                                                            },
                                                        })}
                                                    >
                                                        <LoadingButton
                                                            fullWidth
                                                            type="submit"
                                                            variant="contained"
                                                            className="authBtn"
                                                            loading={
                                                                isSubmitting
                                                            }
                                                        >
                                                            Reset Password
                                                        </LoadingButton>
                                                    </Div>
                                                    <Div
                                                        sx={(theme) => ({
                                                            flex: "1 1 55%",
                                                            textAlign: "right",
                                                            [theme.breakpoints.down(
                                                                "md"
                                                            )]: {
                                                                flex: "1 1 100%",
                                                                textAlign:
                                                                    "center",
                                                            },
                                                        })}
                                                    >
                                                        {loginComponent}
                                                    </Div>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </Grid>
                <Box
                    component={Grid}
                    item
                    lg={6}
                    md={0}
                    sx={{
                        height: "100%",
                        position: "relative",
                        background: `#0267a0 url(${getAssetPath(
                            `${ASSET_IMAGES}/auth/auth-bg-pattern-2.png`,
                            "640x428"
                        )}) no-repeat center`,
                        backgroundSize: "cover",
                    }}
                ></Box>
            </Grid>
            <ContentLoader />
        </Paper>
    );
};

export default ResetPassword;
