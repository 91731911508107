import basicAuthAxios from "../../services/auth/basicAuth";
import { CHECK_PWD_RESET_CODE_LINK } from "../../utils/constants/apiLinks";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const verifyCode = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.authData;
        return basicAuthAxios
            .post(CHECK_PWD_RESET_CODE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data?.id) {
                            if (callbackFun) callbackFun(responseData.data);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                throw new Error(errorMsg);
            });
    };
};
