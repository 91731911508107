import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import PageLoader from "../PageLoader";
import { fetchError } from "../../redux/actions/common";

function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
}

const ContentLoader = () => {
    const { error, loading, message, showLoader } = useSelector(
        ({ common }) => common
    );
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchError(""));
        }, 3000);
    }, [dispatch, error, message]);

    return (
        <React.Fragment>
            <PageLoader open={loading && showLoader} />
            {
                <Snackbar
                    open={Boolean(error)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    TransitionComponent={SlideTransition}
                >
                    <Alert variant="filled" severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            }
            {
                <Snackbar
                    open={Boolean(message)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    TransitionComponent={SlideTransition}
                >
                    <Alert
                        variant="filled"
                        severity="success"
                        sx={{
                            color: (theme) =>
                                theme.palette.primary.contrastText,
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            }
        </React.Fragment>
    );
};

export default ContentLoader;
