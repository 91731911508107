import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const EditIcon = (props) => {
    const { sx, ...restProps } = props;
    return (
        <SvgIcon
            viewBox="0 0 20.359 20.359"
            sx={{
                ...sx,
            }}
            {...restProps}
        >
            <g
                id="Icon_feather-edit"
                data-name="Icon feather-edit"
                transform="translate(-2 -1.641)"
            >
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M11.132,6H4.807A1.807,1.807,0,0,0,3,7.807V20.458a1.807,1.807,0,0,0,1.807,1.807H17.458a1.807,1.807,0,0,0,1.807-1.807V14.132"
                    transform="translate(0 -1.265)"
                    fill="none"
                    stroke="#888"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M21.488,3.379A1.917,1.917,0,0,1,24.2,6.09l-8.584,8.584-3.614.9.9-3.614Z"
                    transform="translate(-3.578 0)"
                    fill="none"
                    stroke="#888"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </SvgIcon>
    );
};

export default EditIcon;
