import React from "react";

import AnnouncementIcon from "@mui/icons-material/Announcement";
import BusinessIcon from "@mui/icons-material/Business";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EventIcon from "@mui/icons-material/Event";
import FolderIcon from "@mui/icons-material/Folder";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LogoutIcon from "@mui/icons-material/Logout";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonIcon from "@mui/icons-material/Person";
import PollIcon from "@mui/icons-material/Poll";
import SsidChartIcon from "@mui/icons-material/SsidChart";

import {
    MEETINGS_PAGE_LINK,
    SERVICES_PAGE_LINK,
    SPECIALS_PAGE_LINK,
    TICKETS_PAGE_LINK,
} from "../../../../utils/constants/appData";

const muiMenuIconSize = 28;

const adminMenus = [
    {
        uri: "/companies",
        label: "Companies",
        type: "nav-item",
        icon: <BusinessIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/fmUsers",
        label: "FM Users",
        type: "nav-item",
        icon: <PersonIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    /* {
        uri: "#",
        label: "Add User",
        type: "nav-item",
        icon: <UserPlusIcon sx={{ fontSize: muiMenuIconSize }} />,
    }, */
    {
        uri: "/accountAssets",
        label: "Account Assets",
        type: "nav-item",
        icon: <FolderIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/accountAssets/trash",
        label: "Assets Trash",
        type: "nav-item",
        icon: <FolderDeleteIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/accountAssets/pendingApprovals",
        label: "Pending Approvals",
        type: "nav-item",
        icon: <PendingActionsIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/reports",
        label: "Reports",
        type: "nav-item",
        icon: <SsidChartIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: SPECIALS_PAGE_LINK,
        label: "Specials",
        type: "nav-item",
        icon: <ImportContactsIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/alerts",
        label: "Alerts",
        type: "nav-item",
        icon: <NotificationsActiveIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: TICKETS_PAGE_LINK,
        label: "Support Tickets",
        type: "nav-item",
        icon: <ConfirmationNumberIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: MEETINGS_PAGE_LINK,
        label: "Meetings",
        type: "nav-item",
        icon: <EventIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: SERVICES_PAGE_LINK,
        label: "Services",
        type: "nav-item",
        icon: <MiscellaneousServicesIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/surveys",
        label: "Surveys",
        type: "nav-item",
        icon: <PollIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/announcements",
        label: "Announcements",
        type: "nav-item",
        icon: <AnnouncementIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/logout",
        label: "Logout",
        type: "nav-item",
        icon: <LogoutIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
];

export default adminMenus;
