import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/system";
import { useBreadcrumb } from "../../../providers/BreadcrumbProvider/BreadcrumbProvider";
import NameLink from "../NameLink";

const StyledBreadcrumb = styled(Breadcrumbs)(() => ({
    "& .MuiBreadcrumbs-ol": {
        flexWrap: "wrap",
        justifyContent: "end",
    },
    "& .MuiBreadcrumbs-li:last-child .MuiLink-root": {
        fontWeight: "bold",
    },
}));

const Breadcrumb = ({ pageNames }) => {
    const location = useLocation();
    const { breadcrumb, addItem } = useBreadcrumb();

    const [setBreadcrumb, setSetBreadcrumb] = useState(false);

    useEffect(() => {
        if (pageNames) {
            setSetBreadcrumb(true);
        } else {
            setSetBreadcrumb(false);
        }
    }, [pageNames]);

    useEffect(() => {
        if (location && setBreadcrumb) {
            addItem({
                genericName: pageNames.genericName,
                specificName: pageNames.specificName,
                url: location.pathname,
            });
        }
    }, [addItem, location, pageNames, setBreadcrumb]);

    if (setBreadcrumb && breadcrumb) {
        return (
            <StyledBreadcrumb aria-label="breadcrumb">
                {breadcrumb.map((breadcrumbItem, breadcrumbIdx) => (
                    <NameLink
                        key={`breadcrumb-${breadcrumbIdx}`}
                        component={RouterLink}
                        to={breadcrumbItem.url}
                        color="text.primary"
                        sx={{ textDecoration: "none" }}
                    >
                        {breadcrumbItem.specificName}
                    </NameLink>
                ))}
            </StyledBreadcrumb>
        );
    } else {
        return null;
    }
};

Breadcrumb.propTypes = {
    pageNames: PropTypes.object,
};

Breadcrumb.defaultProps = {
    pageNames: null,
};

export default Breadcrumb;
