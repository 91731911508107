import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { getCompany } from "../../redux/actions/companies";
import { getFilteredReports } from "../../redux/actions/reports";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    REPORT_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import CompanyReportsTable from "./CompanyReportsTable";

const genericPageName = "Reports";

const filterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "SET_LIMIT_AND_FETCH") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE_AND_FETCH") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            return {
                ...state,
                sort: action.sort,
                order: action.order,
                fetchPage: true,
            };
        } else if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "STOP_FETCH_PAGE") {
            return { ...state, fetchPage: false };
        }
    }

    return {
        page: 0,
        rowsPerPage: 10,
        sort: REPORT_KEYS.NAME,
        order: SORT_ORDER_ASC,
        fetchPage: false,
    };
};

const ClientReports = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isClientReportsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const { reports, reportCounts } = useSelector(
        ({ reportsReducer }) => reportsReducer
    );

    const [pageName, setPageName] = useState("");
    const [fetchingOnLoad, setFetchingOnLoad] = useState(true);
    const [company, setCompany] = useState(null);
    const [companyIsValid, setCompanyIsValid] = useState(false);
    const [companyFetched, setCompanyFetched] = useState(false);
    const [reportsFetched, setReportsFetched] = useState(true);

    const [filterState, dispatchFilter] = useReducer(filterReducer, {
        page: 0,
        rowsPerPage: 10,
        sort: REPORT_KEYS.NAME,
        order: SORT_ORDER_ASC,
        fetchPage: false,
    });

    const { fetchPage } = filterState;

    const authUserCompanyId =
        authUser &&
        authUser?.[AUTH_USER_KEYS.COMPANY_ID] &&
        authUser[AUTH_USER_KEYS.COMPANY_ID]
            ? authUser[AUTH_USER_KEYS.COMPANY_ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    useEffect(() => {
        return () => {
            _isClientReportsMounted.current = false;
        };
    }, []);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (isValidUserType) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompanyFetched(true);
                                setCompany(fetchedCompany || null);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: authUserCompanyId,
            };

            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setCompanyFetched(false);
                    setFetchingOnLoad(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserCompanyId, isValidUserType]);

    /* Check if fetched company is valid */
    useEffect(() => {
        if (companyFetched) {
            if (
                company &&
                company?.[COMPANY_KEYS.ID] &&
                company[COMPANY_KEYS.ID]
            ) {
                setCompanyIsValid(true);
                setPageName(
                    company?.[COMPANY_KEYS.NAME]
                        ? `${company[COMPANY_KEYS.NAME]} Reports`
                        : genericPageName
                );
            } else {
                setFetchingOnLoad(false);
            }
        }
    }, [company, companyFetched]);

    /* Set reports filter */
    useEffect(() => {
        if (companyIsValid) {
            dispatchFilter({ type: "FETCH_PAGE" });
        }
    }, [companyIsValid]);

    useEffect(() => {
        if (fetchPage) {
            setReportsFetched(false);
        }
    }, [fetchPage]);

    /* Fetch reports list */
    useEffect(() => {
        let isActive = true;

        if (!reportsFetched && filterState) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredReports(payload, () => {
                            if (isActive) {
                                setReportsFetched(true);
                                setFetchingOnLoad(false);
                                dispatchFilter({
                                    type: "STOP_FETCH_PAGE",
                                });
                            }
                        })
                    );
                };
            };

            const reportData = {
                authcode: authToken,
                page: filterState.page + 1,
                rows_per_page: filterState.rowsPerPage,
                sort: filterState.sort,
                order: filterState.order,
                [REPORT_KEYS.COMPANY_ID]: authUserCompanyId,
            };

            const promise = dispatch(fetchData({ reportData: reportData }));
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setReportsFetched(true);
                    setFetchingOnLoad(false);
                    dispatchFilter();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserCompanyId, filterState, reportsFetched]);

    const handlePageChange = (event, newPage) => {
        dispatchFilter({ type: "SET_PAGE_AND_FETCH", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchFilter({
            type: "SET_LIMIT_AND_FETCH",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        const isAsc =
            filterState.sort === property &&
            filterState.order === SORT_ORDER_ASC;

        dispatchFilter({
            type: "SET_SORT_AND_FETCH",
            sort: property,
            order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
        });
    };

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: genericPageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {!fetchingOnLoad && isValidUserType && (
                    <Grid item xs={12}>
                        <CompanyReportsTable
                            reports={reports}
                            reportCounts={reportCounts}
                            reportsFetched={reportsFetched}
                            order={filterState.order}
                            page={filterState.page}
                            rowsPerPage={filterState.rowsPerPage}
                            sort={filterState.sort}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            onSort={handleSort}
                        />
                    </Grid>
                )}
                {!fetchingOnLoad && !isValidUserType && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default ClientReports;
