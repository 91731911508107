import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import FormattedPhoneNoTxt from "../../shared/widgets/FormattedPhoneNoTxt";
import NameLink from "../../shared/widgets/NameLink";
import { getInitialsFromName } from "../../utils/appHelpers";
import { FM_USER_KEYS } from "../../utils/constants/appData";
import { cleanPhoneNo } from "../../utils/dataHelpers";

const StyledLabelTblCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(2, 1),
    verticalAlign: "top",
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
}));

const StyledValueTblCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(2, 1),
    fontSize: theme.typography.h5.fontSize,
}));

const BioCard = ({ fmUser }) => {
    const scrollbarRef = React.useRef();

    useEffect(() => {
        if (scrollbarRef) scrollbarRef.current?.scrollToBottom();
    }, [scrollbarRef]);

    const fmUserName = fmUser[FM_USER_KEYS.NAME];
    const fmUserEmail = fmUser[FM_USER_KEYS.EMAIL] || "";
    const fmUserPhoneNo = fmUser[FM_USER_KEYS.PHONE_NO] || "";
    const fmUserBioLink = fmUser[FM_USER_KEYS.BIO_LINK] || "";
    const fmUserBioDescription = fmUser[FM_USER_KEYS.BIO_DESCRIPTION] || "";

    const fetchedPhoto = fmUser?.[FM_USER_KEYS.PROFILE_PIC];
    let photoLink = "";
    if (fetchedPhoto) {
        photoLink = `${fmUser[FM_USER_KEYS.PHOTO_BASE_URL]}/${
            fetchedPhoto[FM_USER_KEYS.PHOTO_FILE_PATH]
        }`;
    }
    const fmUserInitials = photoLink ? (
        <React.Fragment>&nbsp;</React.Fragment>
    ) : (
        getInitialsFromName(fmUserName)
    );

    return (
        <Card
            sx={(theme) => ({
                padding: theme.spacing(1, 1),
                boxShadow: theme.shadows[4],
            })}
        >
            <CardContent>
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <Avatar
                            sx={{
                                width: 200,
                                height: 200,
                                mb: 2,
                                ml: "auto",
                                mr: "auto",
                                bgcolor: "#112434",
                                opacity: 1,
                                fontSize: "3.5rem",
                            }}
                            alt={fmUserName}
                            src={photoLink}
                        >
                            {fmUserInitials}
                        </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table aria-label="FM User Profile Details Table">
                                <TableBody>
                                    <TableRow>
                                        <StyledLabelTblCell
                                            component="th"
                                            scope="row"
                                            sx={{ width: "30%" }}
                                        >
                                            Name
                                        </StyledLabelTblCell>
                                        <StyledValueTblCell
                                            sx={{ width: "70%" }}
                                        >
                                            {fmUserName}
                                        </StyledValueTblCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledLabelTblCell
                                            component="th"
                                            scope="row"
                                        >
                                            Role
                                        </StyledLabelTblCell>
                                        <StyledValueTblCell>
                                            {fmUser[FM_USER_KEYS.ROLE] || "NA"}
                                        </StyledValueTblCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledLabelTblCell
                                            component="th"
                                            scope="row"
                                        >
                                            Email
                                        </StyledLabelTblCell>
                                        <StyledValueTblCell>
                                            {fmUserEmail ? (
                                                <NameLink
                                                    variant="h5"
                                                    href={`mailto:${fmUserEmail}`}
                                                >
                                                    {fmUserEmail}
                                                </NameLink>
                                            ) : (
                                                "NA"
                                            )}
                                        </StyledValueTblCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledLabelTblCell
                                            component="th"
                                            scope="row"
                                        >
                                            Phone
                                        </StyledLabelTblCell>
                                        <StyledValueTblCell>
                                            {fmUserPhoneNo ? (
                                                <FormattedPhoneNoTxt
                                                    value={cleanPhoneNo(
                                                        fmUserPhoneNo
                                                    )}
                                                />
                                            ) : (
                                                "NA"
                                            )}
                                        </StyledValueTblCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledLabelTblCell
                                            component="th"
                                            scope="row"
                                        >
                                            Bio Link
                                        </StyledLabelTblCell>
                                        <StyledValueTblCell>
                                            {fmUserBioLink ? (
                                                <NameLink
                                                    variant="h5"
                                                    href={fmUserBioLink}
                                                >
                                                    {fmUserBioLink}
                                                </NameLink>
                                            ) : (
                                                "NA"
                                            )}
                                        </StyledValueTblCell>
                                    </TableRow>

                                    <TableRow>
                                        <StyledLabelTblCell
                                            component="th"
                                            scope="row"
                                        >
                                            Bio Description
                                        </StyledLabelTblCell>
                                        <StyledValueTblCell>
                                            {fmUserBioDescription ? (
                                                <Box
                                                    sx={(theme) => ({
                                                        p: 0,
                                                        m: 0,
                                                        width: "100%",
                                                        height: "160px",
                                                        overflowY: "hidden",
                                                    })}
                                                >
                                                    <JumboScrollbar
                                                        ref={scrollbarRef}
                                                        autoHide
                                                        autoHideDuration={200}
                                                        autoHideTimeout={500}
                                                        autoHeightMin={30}
                                                        style={{
                                                            height: "100%",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            color="text.primary"
                                                            sx={{
                                                                wordWrap:
                                                                    "break-word",
                                                            }}
                                                        >
                                                            {
                                                                fmUserBioDescription
                                                            }
                                                        </Typography>
                                                    </JumboScrollbar>
                                                </Box>
                                            ) : (
                                                "NA"
                                            )}
                                        </StyledValueTblCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BioCard.propTypes = {
    fmUser: PropTypes.object,
};

BioCard.defaultProps = {
    fmUser: null,
};

export default BioCard;
