import {
    ADD_OFFICE_CONTACT,
    DELETE_OFFICE_CONTACT,
    EDIT_OFFICE_CONTACT,
    GET_OFFICE_CONTACT_COUNT,
    GET_OFFICE_CONTACTS,
    SET_OFFICE_CONTACT_DETAILS,
} from "../../utils/constants/actionTypes";
import { OFFICE_CONTACT_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    officeContacts: [],
    currentOfficeContact: null,
    officeContactCounts: { [OFFICE_CONTACT_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OFFICE_CONTACTS: {
            return {
                ...state,
                officeContacts: action.payload,
            };
        }
        case GET_OFFICE_CONTACT_COUNT: {
            return {
                ...state,
                officeContactCounts: action.payload,
            };
        }
        case SET_OFFICE_CONTACT_DETAILS: {
            return {
                ...state,
                currentOfficeContact: action.payload,
            };
        }
        case ADD_OFFICE_CONTACT: {
            return {
                ...state,
                officeContacts: [action.payload, ...state.officeContacts],
            };
        }
        case EDIT_OFFICE_CONTACT: {
            return {
                ...state,
                officeContacts: state.officeContacts.map((officeContact) =>
                    officeContact.id === action.payload.id
                        ? action.payload
                        : officeContact
                ),
            };
        }
        case DELETE_OFFICE_CONTACT: {
            return {
                ...state,
                officeContacts: state.officeContacts.filter(
                    (officeContact) => officeContact.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
