import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import PageLoader from "../../../shared/PageLoader";

const Auth = ({ fallbackPath }) => {
    const { pathname } = useLocation();
    const { authToken, isLoading } = useJumboAuth();

    if (isLoading) return <PageLoader open={true} />;

    /**
     * Do not use authUser in "if" condition
     * because it is stored in a state and
     * when the url is refreshed, the initial 
     * value of the state is "null".
     * 
     * This will cause the condition to fail and 
     * as a result the user will be first taken
     * to the login page and then back to the
     * home page.
     */
    if (authToken) {
        return <Outlet />;
    }

    if (/^\/survey\/take\/[^\/\\]+\/$/gm.test(pathname)) {
        const surveyId = pathname.split("/")[3];
        localStorage.removeItem("loginSurveyId");
        localStorage.setItem("loginSurveyId", surveyId);
    }

    return <Navigate to={fallbackPath} />;
};

export default Auth;
