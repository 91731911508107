import React from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import ClientServices from "./ClientServices";
import ManageServices from "./ManageServices";

const Services = () => {
    const { authUser } = useJumboAuth();

    let servicesComponent = null;

    if (authUser && authUser?.[AUTH_USER_KEYS.TYPE]) {
        if (
            [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID].includes(
                authUser[AUTH_USER_KEYS.TYPE]
            )
        ) {
            servicesComponent = <ManageServices />;
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_CLIENT_ID) {
            servicesComponent = <ClientServices />;
        }
    }

    return servicesComponent;
};

export default Services;
