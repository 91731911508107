import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import PageLoader from "../../../shared/PageLoader";

const NotAuth = ({ fallbackPath }) => {
    const { authToken, isLoading } = useJumboAuth();

    if (isLoading) return <PageLoader open={true} />;

    if (!authToken) {
        return <Outlet />;
    }

    return <Navigate to={fallbackPath} />;
};

export default NotAuth;
