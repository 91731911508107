import {
    GET_INVOICE_COUNT,
    GET_INVOICES,
    SET_INVOICE_DETAILS,
} from "../../utils/constants/actionTypes";
import { INVOICE_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    invoices: [],
    currentInvoice: null,
    invoiceCounts: { [INVOICE_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INVOICES: {
            return {
                ...state,
                invoices: action.payload,
            };
        }
        case GET_INVOICE_COUNT: {
            return {
                ...state,
                invoiceCounts: action.payload,
            };
        }
        case SET_INVOICE_DETAILS: {
            return {
                ...state,
                currentInvoice: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
