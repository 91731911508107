import React from "react";
import Ticket from "../pages/ticket";
import Tickets from "../pages/tickets";
import Page from "@jumbo/shared/Page";
import { TICKETS_PAGE_LINK } from "../utils/constants/appData";

const ticketsRoutes = [
    {
        path: TICKETS_PAGE_LINK,
        element: <Page component={Tickets} />,
    },
    {
        path: "/supportTicket/:supportTicketId/",
        element: <Page component={Ticket} />,
    },
];

export default ticketsRoutes;
