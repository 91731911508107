import basicAuthAxios from "../../services/auth/basicAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    GET_ADMIN_PROFILE_LINK,
    UPDATE_ADMIN_PROFILE_LINK,
} from "../../utils/constants/apiLinks";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const getAdminProfile = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.adminData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_ADMIN_PROFILE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.user) {
                            const admin = data.user;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(admin);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const updateAdminProfile = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.adminData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_ADMIN_PROFILE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* const data = responseData.data;
                        if (data.user) { */
                        dispatch(
                            fetchSuccess("Profile was updated successfully.")
                        );
                        if (callbackFun) callbackFun(/* data.user */);
                        /* } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        } */
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};
