import React from "react";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { alpha } from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ForumIcon from "@mui/icons-material/Forum";
import GridOnIcon from "@mui/icons-material/GridOn";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MenuIcon from "@mui/icons-material/Menu";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import NoteIcon from "@mui/icons-material/Note";
import PeopleIcon from "@mui/icons-material/People";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import QuizIcon from "@mui/icons-material/Quiz";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import UpdateIcon from "@mui/icons-material/Update";
import UploadIcon from "@mui/icons-material/Upload";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WebStoriesIcon from "@mui/icons-material/WebStories";

import EditIcon from "../../SVGImages/EditIcon";

const components = {
    accessTime: AccessTimeIcon,
    alarm: AlarmIcon,
    alarmOn: AlarmOnIcon,
    appRegistration: AppRegistrationIcon,
    archive: ArchiveIcon,
    assignmentTurnedIn: AssignmentTurnedInIcon,
    attachFile: AttachFileIcon,
    barChart: BarChartIcon,
    cancel: CancelIcon,
    close: CloseIcon,
    contentCopy: ContentCopyIcon,
    dashboard: DashboardIcon,
    delete: DeleteIcon,
    deleteOutlined: DeleteOutlineOutlinedIcon,
    description: DescriptionIcon,
    done: DoneIcon,
    calendarMonth: CalendarMonthIcon,
    edit: EditIcon,
    fileDownloadOutlined: FileDownloadOutlinedIcon,
    filePresent: FilePresentIcon,
    forum: ForumIcon,
    gridOn: GridOnIcon,
    groupAdd: GroupAddIcon,
    info: InfoIcon,
    locationOn: LocationOnIcon,
    lockOpen: LockOpenIcon,
    menu: MenuIcon,
    miscellaneousServices: MiscellaneousServicesIcon,
    note: NoteIcon,
    people: PeopleIcon,
    priorityHigh: PriorityHighIcon,
    quiz: QuizIcon,
    receipt: ReceiptIcon,
    restoreFromTrash: RestoreFromTrashIcon,
    saveAlt: SaveAltIcon,
    sell: SellIcon,
    send: SendIcon,
    thumbDown: ThumbDownIcon,
    thumbUp: ThumbUpIcon,
    toggleOff: ToggleOffIcon,
    toggleOn: ToggleOnIcon,
    unarchive: UnarchiveIcon,
    update: UpdateIcon,
    upload: UploadIcon,
    viewKanban: ViewKanbanIcon,
    viewSidebar: ViewSidebarIcon,
    visibility: VisibilityIcon,
    visibilityOff: VisibilityOffIcon,
    webStories: WebStoriesIcon,
};

const TableActionIcon = ({
    btnSx,
    iconComponent,
    iconSx,
    label,
    tooltip,
    componentType,
    ...restProps
}) => {
    const IconComponent =
        iconComponent && components[iconComponent]
            ? components[iconComponent]
            : null;
    if (!IconComponent) {
        return null;
    }

    let svgSx = {};
    if (
        [
            "accessTime",
            "alarm",
            "alarmOn",
            "appRegistration",
            "archive",
            "assignmentTurnedIn",
            "attachFile",
            "barChart",
            "calendarMonth",
            "cancel",
            "close",
            "contentCopy",
            "dashboard",
            "delete",
            "deleteOutlined",
            "description",
            "done",
            "fileDownloadOutlined",
            "filePresent",
            "forum",
            "gridOn",
            "groupAdd",
            "info",
            "locationOn",
            "menu",
            "miscellaneousServices",
            "note",
            "people",
            "priorityHigh",
            "quiz",
            "receipt",
            "restoreFromTrash",
            "saveAlt",
            "sell",
            "send",
            "thumbDown",
            "thumbUp",
            "toggleOff",
            "toggleOn",
            "unarchive",
            "update",
            "upload",
            "userTie",
            "viewKanban",
            "viewSidebar",
            "visibility",
            "visibilityOff",
            "webStories",
        ].includes(iconComponent)
    ) {
        svgSx = {
            fill: "#888",
            "&:hover": {
                "&& path": {
                    fill: "#CA3E27",
                },
            },
            ...iconSx,
        };
    } else {
        svgSx = {
            "&:hover": {
                "&& path": {
                    stroke: "#CA3E27",
                },
            },
            ...iconSx,
        };
    }

    let component = null;
    if (componentType === "link") {
        component = (
            <Link
                sx={(theme) => ({
                    display: "inline-flex",
                    p: theme.spacing(1),
                    "&:hover": {
                        borderRadius: "50%",
                        backgroundColor: alpha("#757575", 0.04),
                    },
                    ...btnSx,
                })}
                {...restProps}
            >
                <IconComponent sx={svgSx} />
            </Link>
        );
    } else {
        component = (
            <IconButton
                aria-label={label}
                sx={{
                    ...btnSx,
                }}
                {...restProps}
            >
                <IconComponent sx={svgSx} />
            </IconButton>
        );
    }

    if (tooltip && component) {
        return <Tooltip title={tooltip}>{component}</Tooltip>;
    }

    return component;
};

TableActionIcon.propTypes = {
    btnSx: PropTypes.object,
    iconComponent: PropTypes.string,
    iconSx: PropTypes.object,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    componentType: PropTypes.string,
};

TableActionIcon.defaultProps = {
    btnSx: {},
    iconComponent: "",
    iconSx: {},
    label: "",
    tooltip: "",
    componentType: "icon-button",
};

export default TableActionIcon;
