import {
    GET_STATEMENT_COUNT,
    GET_STATEMENTS,
    SET_STATEMENT_DETAILS,
} from "../../utils/constants/actionTypes";
import { STATEMENT_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    statements: [],
    currentStatement: null,
    statementCounts: { [STATEMENT_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STATEMENTS: {
            return {
                ...state,
                statements: action.payload,
            };
        }
        case GET_STATEMENT_COUNT: {
            return {
                ...state,
                statementCounts: action.payload,
            };
        }
        case SET_STATEMENT_DETAILS: {
            return {
                ...state,
                currentStatement: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
