import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    SURVEY_KEYS,
    SURVEY_IS_SUBMITTED,
    SURVEY_STATUS_ACTIVE,
} from "../../utils/constants/appData";

const SurveysTable = ({
    canArchiveSurvey,
    canAddEditSurvey,
    canDeleteSurvey,
    canSendSurvey,
    canTakeSurvey,
    canViewSurvey,
    canViewSurveyResult,
    columnsToShow,
    headCells,
    order,
    page,
    rowsPerPage,
    sort,
    surveys,
    surveyCounts,
    surveysFetched,
    type,
    handlePageChange,
    handleRowsPerPageChange,
    onArchive,
    onDelete,
    onSend,
    onSort,
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const surveysTotalCount =
        surveyCounts &&
        surveyCounts[SURVEY_KEYS.TOTAL] &&
        !isNaN(parseInt(surveyCounts[SURVEY_KEYS.TOTAL])) &&
        parseInt(surveyCounts[SURVEY_KEYS.TOTAL]) > 0
            ? parseInt(surveyCounts[SURVEY_KEYS.TOTAL])
            : 0;

    const fetchedSurveysCount =
        surveys && surveys.length > 0 ? surveys.length : 0;

    const surveyCountNonZero = fetchedSurveysCount > 0 && surveysTotalCount > 0;

    const numTotalCols = headCells.length;
    const numVisibleCols = columnsToShow.length;

    const surveyIsActive = type === SURVEY_STATUS_ACTIVE;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Surveys Table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const sx = {};

                                if (headCell?.width) {
                                    const headCellWidth = Number(
                                        headCell.width.match(/\d+(\.\d+)?/)[0]
                                    );
                                    const updHeadCellWidth =
                                        (numTotalCols / numVisibleCols) *
                                        headCellWidth;
                                    sx.width = `${updHeadCellWidth.toFixed(
                                        2
                                    )}%`;
                                }

                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id} sx={{ ...sx }}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            <TableCell
                                key="actions"
                                sx={{ width: "15%", textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {surveyCountNonZero ? (
                            surveys.map((survey) => {
                                const surveyId = survey[SURVEY_KEYS.ID];
                                const surveyName = survey[SURVEY_KEYS.NAME];
                                const isSurveySubmitted =
                                    survey[SURVEY_KEYS.IS_SUBMITTED] ===
                                    SURVEY_IS_SUBMITTED;

                                return (
                                    <TableRow
                                        key={surveyId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        {columnsToShow.includes(
                                            SURVEY_KEYS.NAME
                                        ) &&
                                            canTakeSurvey && (
                                                <TableCell>
                                                    {isSurveySubmitted ? (
                                                        <NameLink
                                                            component={
                                                                RouterLink
                                                            }
                                                            to={`/survey/response/${surveyId}/`}
                                                        >
                                                            {surveyName}
                                                        </NameLink>
                                                    ) : (
                                                        <NameLink
                                                            component={
                                                                RouterLink
                                                            }
                                                            to={`/survey/take/${surveyId}/`}
                                                        >
                                                            {surveyName}
                                                        </NameLink>
                                                    )}
                                                </TableCell>
                                            )}
                                        {columnsToShow.includes(
                                            SURVEY_KEYS.NAME
                                        ) &&
                                            canViewSurvey && (
                                                <TableCell>
                                                    <NameLink
                                                        component={RouterLink}
                                                        to={`/survey/${surveyId}/`}
                                                    >
                                                        {surveyName}
                                                    </NameLink>
                                                </TableCell>
                                            )}
                                        {columnsToShow.includes(
                                            SURVEY_KEYS.CREATED_AT
                                        ) && (
                                            <TableCell>
                                                {survey[SURVEY_KEYS.CREATED_AT]}
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={1}
                                            >
                                                {surveyIsActive &&
                                                    canTakeSurvey &&
                                                    isSurveySubmitted && (
                                                        <React.Fragment>
                                                            <TableActionIcon
                                                                iconComponent="assignmentTurnedIn"
                                                                label="view your responses"
                                                                tooltip="View Your Responses"
                                                                component={
                                                                    RouterLink
                                                                }
                                                                to={`/survey/response/${surveyId}/`}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                {surveyIsActive &&
                                                    canTakeSurvey &&
                                                    !isSurveySubmitted && (
                                                        <React.Fragment>
                                                            <TableActionIcon
                                                                iconComponent="quiz"
                                                                label="take survey"
                                                                tooltip="Take Survey"
                                                                component={
                                                                    RouterLink
                                                                }
                                                                to={`/survey/take/${surveyId}/`}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                {surveyIsActive &&
                                                    canAddEditSurvey &&
                                                    !isSurveySubmitted && (
                                                        <TableActionIcon
                                                            iconComponent="edit"
                                                            label="edit survey"
                                                            tooltip="Edit Survey"
                                                            component={
                                                                RouterLink
                                                            }
                                                            to={`/survey/${surveyId}/edit`}
                                                        />
                                                    )}
                                                {surveyIsActive &&
                                                    canSendSurvey && (
                                                        <React.Fragment>
                                                            <TableActionIcon
                                                                iconComponent="send"
                                                                label="send survey"
                                                                tooltip="Send Survey"
                                                                onClick={() =>
                                                                    onSend(
                                                                        survey
                                                                    )
                                                                }
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                {canViewSurveyResult && (
                                                    <React.Fragment>
                                                        <TableActionIcon
                                                            iconComponent="barChart"
                                                            label="view survey result"
                                                            tooltip="View Survey Result"
                                                            component={
                                                                RouterLink
                                                            }
                                                            to={`/survey/result/${surveyId}/`}
                                                        />
                                                    </React.Fragment>
                                                )}
                                                {surveyIsActive &&
                                                    canDeleteSurvey &&
                                                    !isSurveySubmitted && (
                                                        <React.Fragment>
                                                            <TableActionIcon
                                                                iconComponent="delete"
                                                                label="delete survey"
                                                                tooltip="Delete Survey"
                                                                onClick={() =>
                                                                    onDelete(
                                                                        survey
                                                                    )
                                                                }
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                {surveyIsActive &&
                                                    canArchiveSurvey && (
                                                        <React.Fragment>
                                                            <TableActionIcon
                                                                iconComponent="archive"
                                                                label="archive survey"
                                                                tooltip="Archive Survey"
                                                                onClick={() =>
                                                                    onArchive(
                                                                        survey
                                                                    )
                                                                }
                                                            />
                                                        </React.Fragment>
                                                    )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            surveysFetched ? "error" : "info"
                                        }
                                    >
                                        {surveysFetched
                                            ? "There are no records found."
                                            : "Loading surveys..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    surveyCountNonZero
                        ? parseInt(surveyCounts[SURVEY_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={surveyCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

SurveysTable.propTypes = {
    canArchiveSurvey: PropTypes.bool,
    canAddEditSurvey: PropTypes.bool,
    canDeleteSurvey: PropTypes.bool,
    canSendSurvey: PropTypes.bool,
    canTakeSurvey: PropTypes.bool,
    canViewSurvey: PropTypes.bool,
    canViewSurveyResult: PropTypes.bool,
    columnsToShow: PropTypes.array,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    surveys: PropTypes.array,
    surveyCounts: PropTypes.object,
    surveysFetched: PropTypes.bool,
    type: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    onSend: PropTypes.func,
    onSort: PropTypes.func,
};

SurveysTable.defaultProps = {
    canArchiveSurvey: false,
    canAddEditSurvey: false,
    canDeleteSurvey: false,
    canSendSurvey: false,
    canTakeSurvey: false,
    canViewSurvey: false,
    canViewSurveyResult: false,
    columnsToShow: [],
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    surveys: [],
    surveyCounts: null,
    surveysFetched: false,
    type: "",
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onArchive: () => {},
    // onDelete: () => {},
    // onSend: () => {},
    // onSort: () => {},
};

export default SurveysTable;
