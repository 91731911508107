import React from "react";

/* import AddCardIcon from "@mui/icons-material/AddCard"; */
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EventIcon from "@mui/icons-material/Event";
import FolderIcon from "@mui/icons-material/Folder";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import GroupsIcon from "@mui/icons-material/Groups";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PollIcon from "@mui/icons-material/Poll";
/* import RequestQuoteIcon from "@mui/icons-material/RequestQuote"; */
import SsidChartIcon from "@mui/icons-material/SsidChart";

import {
    CASE_STUDIES_PAGE_LINK,
    MEETINGS_PAGE_LINK,
    SERVICES_PAGE_LINK,
    SPECIALS_PAGE_LINK,
    TICKETS_PAGE_LINK,
} from "../../../../utils/constants/appData";

const muiMenuIconSize = 28;

const clientUserMenus = [
    {
        uri: "/fmUser",
        label: "Firm Media Team",
        type: "nav-item",
        icon: <GroupsIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/accountAssets",
        label: "Account Assets",
        type: "nav-item",
        icon: <FolderIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/accountAssets/trash",
        label: "Assets Trash",
        type: "nav-item",
        icon: <FolderDeleteIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/accountAssets/pendingApprovals",
        label: "Pending Approvals",
        type: "nav-item",
        icon: <PendingActionsIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/reports",
        label: "Reports",
        type: "nav-item",
        icon: <SsidChartIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/leadSheets",
        label: "Lead Sheets",
        type: "nav-item",
        icon: <ConfirmationNumberIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: CASE_STUDIES_PAGE_LINK,
        label: "Case Studies",
        type: "nav-item",
        icon: <MenuBookIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: SPECIALS_PAGE_LINK,
        label: "Specials",
        type: "nav-item",
        icon: <ImportContactsIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/alerts",
        label: "Alerts",
        type: "nav-item",
        icon: <NotificationsActiveIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: TICKETS_PAGE_LINK,
        label: "Support Tickets",
        type: "nav-item",
        icon: <ConfirmationNumberIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: MEETINGS_PAGE_LINK,
        label: "Meetings",
        type: "nav-item",
        icon: <EventIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: "/surveys",
        label: "Surveys",
        type: "nav-item",
        icon: <PollIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: SERVICES_PAGE_LINK,
        label: "Services",
        type: "nav-item",
        icon: <MiscellaneousServicesIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    {
        uri: `${SERVICES_PAGE_LINK}/assigned`,
        label: "My Services",
        type: "nav-item",
        icon: <ManageAccountsIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
    /* {
        label: "Billing",
        type: "section",
        children: [
            {
                uri: "/invoices",
                label: "Invoices",
                type: "nav-item",
                icon: <RequestQuoteIcon sx={{ fontSize: muiMenuIconSize }} />,
            },
            {
                uri: "/addCard",
                label: "Add Card",
                type: "nav-item",
                icon: <AddCardIcon sx={{ fontSize: muiMenuIconSize }} />,
            },
        ],
    }, */
    {
        uri: "/logout",
        label: "Logout",
        type: "nav-item",
        icon: <LogoutIcon sx={{ fontSize: muiMenuIconSize }} />,
    },
];

export default clientUserMenus;
