import {
    ADD_MEETING,
    DELETE_MEETING,
    EDIT_MEETING,
    GET_MEETING_COUNT,
    GET_MEETINGS,
    SET_MEETING_DETAILS,
} from "../../utils/constants/actionTypes";
import { MEETING_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    meetings: [],
    currentMeeting: null,
    meetingCounts: { [MEETING_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MEETINGS: {
            return {
                ...state,
                meetings: action.payload,
            };
        }
        case GET_MEETING_COUNT: {
            return {
                ...state,
                meetingCounts: action.payload,
            };
        }
        case SET_MEETING_DETAILS: {
            return {
                ...state,
                currentMeeting: action.payload,
            };
        }
        case ADD_MEETING: {
            return {
                ...state,
                meetings: [action.payload, ...state.meetings],
            };
        }
        case EDIT_MEETING: {
            return {
                ...state,
                meetings: state.meetings.map((meeting) =>
                    meeting.id === action.payload.id ? action.payload : meeting
                ),
            };
        }
        case DELETE_MEETING: {
            return {
                ...state,
                meetings: state.meetings.filter(
                    (meeting) => meeting.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
