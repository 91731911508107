import Button from "@mui/material/Button";
import { alpha } from "@mui/material";
import { styled } from "@mui/system";

const AuthLink = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5),
    fontSize: theme.typography?.miscellaneous?.button?.medium,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    letterSpacing: "0px",
    textDecoration: "none",
    "&:hover": {
        borderRadius: "5%",
        backgroundColor: alpha(theme.palette.miscellaneous.button.main, 0.04),
        textDecoration: "underline",
    },
}));

export default AuthLink;
