import React from "react";
/* import JumboLayout from "@jumbo/components/JumboLayout"; */
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";
import FullPageLayout from "../../shared/JumboCustomizer/components/FullPageLayout";

const FullPage = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout();

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig);
    }, []);

    /**
     * The FullPageLayout component does not include the
     * header, footer and sidebar components.
     * So the settings in layoutConfig should not work for 
     * the FullPage layout.
     */
    return <FullPageLayout>{children}</FullPageLayout>;
};

export default FullPage;
