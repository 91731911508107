import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { fetchError, fetchSuccess } from "../../../redux/actions/common";
import authServices from "../../../services/auth-services";
import ContentLoader from "../../../shared/ContentLoader";
import SVGLogo from "../../../shared/SVGLogo";
import AuthLink from "../../../shared/widgets/AuthLink";
import PageTitle from "../../../shared/widgets/PageTitle";
import { getAssetPath } from "../../../utils/appHelpers";
import { APP_NAME } from "../../../utils/constants/appData";
import { ASSET_IMAGES } from "../../../utils/constants/paths";

const validationSchema = yup.object({
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
});

const pageName = "Forgot Password";

const ForgotPassword = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const dispatch = useDispatch();
    const isResponsiveMode = useMediaQuery("(max-width:575px)");

    const [authError, setAuthError] = useState("");
    const [authSuccess, setAuthSuccess] = useState("");

    useEffect(() => {
        if (authError) {
            dispatch(fetchError(authError));
            setAuthError("");
        }
    }, [dispatch, authError]);

    useEffect(() => {
        if (authSuccess) {
            dispatch(fetchSuccess(authSuccess));
            setAuthSuccess("");
        }
    }, [dispatch, authSuccess]);

    const onForgotPassword = (email, callBackFunc) => {
        authServices.forgotPassword({ email }).then((data) => {
            if (data.hasError) {
                callBackFunc();
                setAuthError(data.error);
            } else {
                callBackFunc();
                if (data.success) {
                    setAuthSuccess(data.success);
                } else {
                    setAuthError("There was a problem in getting the message");
                }
            }
        });
    };

    return (
        <Paper
            sx={{
                width: "100%",
                height: "100%",
                m: 0,
                p: 0,
            }}
        >
            <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid item lg={6} md={12} sx={{ height: "100%" }}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{ email: "" }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            onForgotPassword(data.email, () => {
                                setSubmitting(false);
                            });
                            /* setSubmitting(false); */
                        }}
                    >
                        {({ isSubmitting }) => {
                            return (
                                <Form
                                    style={{ textAlign: "left" }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid
                                        container
                                        rowSpacing={1}
                                        sx={(theme) => ({
                                            p: 12,
                                            [theme.breakpoints.down("lg")]: {
                                                px: 8,
                                            },
                                            [theme.breakpoints.down("md")]: {
                                                px: 4,
                                            },
                                            [theme.breakpoints.down("sm")]: {
                                                px: 2,
                                            },
                                        })}
                                    >
                                        <Grid item xs={12} sx={{ mb: 1.5 }}>
                                            <SVGLogo
                                                svgSx={{
                                                    height: "auto",
                                                    fontSize: isResponsiveMode
                                                        ? "16rem"
                                                        : "24rem",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 3 }}>
                                            <PageTitle
                                                component="h1"
                                                title={pageName}
                                                type="v2"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 3 }}>
                                            <JumboTextField
                                                fullWidth
                                                variant="outlined"
                                                name="email"
                                                label={null}
                                                placeholder="Email"
                                                InputProps={{
                                                    notched: false,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PersonRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        "1.75rem",
                                                                    color: "#487AA3",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        background:
                                                            "#F4F7FC 0% 0% no-repeat padding-box",
                                                        borderColor: "#243463",
                                                        borderRadius: "2px",
                                                        opacity: 1,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 3 }}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                sx={(theme) => ({
                                                    [theme.breakpoints.down(
                                                        "md"
                                                    )]: {
                                                        flexWrap: "wrap",
                                                    },
                                                })}
                                            >
                                                <Div
                                                    sx={(theme) => ({
                                                        flex: "1 1 45%",
                                                        [theme.breakpoints.down(
                                                            "md"
                                                        )]: {
                                                            flex: "1 1 100%",
                                                            mb: 3,
                                                        },
                                                    })}
                                                >
                                                    <LoadingButton
                                                        fullWidth
                                                        type="submit"
                                                        variant="contained"
                                                        className="authBtn"
                                                        loading={isSubmitting}
                                                    >
                                                        Send Password Link
                                                    </LoadingButton>
                                                </Div>
                                                <Div
                                                    sx={(theme) => ({
                                                        flex: "1 1 55%",
                                                        textAlign: "right",
                                                        [theme.breakpoints.down(
                                                            "md"
                                                        )]: {
                                                            flex: "1 1 100%",
                                                            textAlign: "center",
                                                        },
                                                    })}
                                                >
                                                    <AuthLink
                                                        component={RouterLink}
                                                        to="/login"
                                                        sx={{
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        Back to login
                                                    </AuthLink>
                                                </Div>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
                <Box
                    component={Grid}
                    item
                    lg={6}
                    md={0}
                    sx={{
                        height: "100%",
                        position: "relative",
                        background: `#0267a0 url(${getAssetPath(
                            `${ASSET_IMAGES}/auth/auth-bg-pattern-2.png`,
                            "640x428"
                        )}) no-repeat center`,
                        backgroundSize: "cover",
                    }}
                ></Box>
            </Grid>
            <ContentLoader />
        </Paper>
    );
};

export default ForgotPassword;
