import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import PageLoader from "../../../shared/PageLoader";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../../utils/constants/appData";

const Admin = ({ fallbackPath }) => {
    const { authToken, authUser, isLoading } = useJumboAuth();

    if (isLoading) return <PageLoader open={true} />;

    /* Wait for the auth user's data to be fetched via api */
    if (authToken && !authUser) return <PageLoader open={true} />;

    if (authUser) {
        if (
            authUser?.[AUTH_USER_KEYS.TYPE] &&
            authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_ADMIN_ID
        ) {
            return <Outlet />;
        }
    }

    return <Navigate to={fallbackPath} />;
};

export default Admin;
