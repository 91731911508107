import React from "react";
import CaseStudies from "../pages/caseStudies";
import CaseStudy from "../pages/caseStudy";
import Page from "@jumbo/shared/Page";
import { CASE_STUDIES_PAGE_LINK } from "../utils/constants/appData";

const caseStudiesRoutes = [
    {
        path: CASE_STUDIES_PAGE_LINK,
        element: <Page component={CaseStudies} />,
    },
    {
        path: "/caseStudy/:caseStudyId/",
        element: <Page component={CaseStudy} />,
    },
];

export default caseStudiesRoutes;
