import React from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import ClientReports from "./ClientReports";
import ReportsCompanies from "./ReportsCompanies";

const Reports = () => {
    const { authUser } = useJumboAuth();

    let reportsComponent = null;

    if (authUser && authUser?.[AUTH_USER_KEYS.TYPE]) {
        if (
            [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID].includes(
                authUser[AUTH_USER_KEYS.TYPE]
            )
        ) {
            reportsComponent = <ReportsCompanies />;
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_CLIENT_ID) {
            reportsComponent = <ClientReports />;
        }
    }

    return reportsComponent;
};

export default Reports;
