import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomAlert from "../../shared/widgets/CustomAlert";
import FolderCard from "./FolderCard";

const FoldersGridLoadMore = ({
    canDeleteFolder,
    canDownloadFolder,
    canEditFolder,
    canRestoreFolder,
    canTrashFolder,
    canViewFolder,
    folders,
    foldersFetched,
    loadMore,
    onDelete,
    onDownload,
    onEdit,
    onLoadMore,
    onRestore,
    onTrash,
}) => {
    const fetchedFoldersCount =
        folders && folders.length > 0 ? folders.length : 0;
    const folderCountNonZero = fetchedFoldersCount > 0;

    return (
        <Grid container spacing={2}>
            {folderCountNonZero ? (
                folders.map((folder, idx) => (
                    <Grid item md={3} xs={12} key={`folder-${idx}`}>
                        <FolderCard
                            canDeleteFolder={canDeleteFolder}
                            canDownloadFolder={canDownloadFolder}
                            canEditFolder={canEditFolder}
                            canRestoreFolder={canRestoreFolder}
                            canTrashFolder={canTrashFolder}
                            canViewFolder={canViewFolder}
                            folder={folder}
                            onDelete={onDelete}
                            onDownload={onDownload}
                            onEdit={onEdit}
                            onRestore={onRestore}
                            onTrash={onTrash}
                        />
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <CustomAlert
                        colorType={foldersFetched ? "" : "dark"}
                        customSx={
                            foldersFetched
                                ? null
                                : {
                                      border: "2px solid",
                                  }
                        }
                        severity={foldersFetched ? "error" : "info"}
                        variant="outlined"
                    >
                        {foldersFetched
                            ? "There are no records found."
                            : "Loading folders..."}
                    </CustomAlert>
                </Grid>
            )}
            {loadMore && (
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <LoadingButton
                        loading={!foldersFetched}
                        type="button"
                        variant="outlined"
                        size="small"
                        loadingPosition="start"
                        className="btnHomeLoadMore"
                        startIcon={<RefreshIcon />}
                        onClick={() => onLoadMore()}
                    >
                        Load More Folders
                    </LoadingButton>
                </Grid>
            )}
        </Grid>
    );
};

FoldersGridLoadMore.propTypes = {
    canDeleteFolder: PropTypes.bool,
    canDownloadFolder: PropTypes.bool,
    canEditFolder: PropTypes.bool,
    canRestoreFolder: PropTypes.bool,
    canTrashFolder: PropTypes.bool,
    canViewFolder: PropTypes.bool,
    folders: PropTypes.array,
    foldersFetched: PropTypes.bool,
    loadMore: PropTypes.bool,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    onEdit: PropTypes.func,
    onLoadMore: PropTypes.func,
    onRestore: PropTypes.func,
    onTrash: PropTypes.func,
};

FoldersGridLoadMore.defaultProps = {
    canDeleteFolder: false,
    canDownloadFolder: false,
    canEditFolder: false,
    canRestoreFolder: false,
    canTrashFolder: false,
    canViewFolder: false,
    folders: [],
    foldersFetched: false,
    loadMore: false,
    onDelete: () => {},
    onDownload: () => {},
    onEdit: () => {},
    onLoadMore: () => {},
    onRestore: () => {},
    onTrash: () => {},
};

export default FoldersGridLoadMore;
