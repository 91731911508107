import basicAuthAxios from "../../services/auth/basicAuth";
import basicFileAuthAxios from "../../services/auth/basicFileAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    GET_ACCOUNT_ASSET_COUNT,
    GET_ACCOUNT_ASSETS,
    SET_ACCOUNT_ASSET_DETAILS,
} from "../../utils/constants/actionTypes";
import {
    ADD_ACCOUNT_ASSET_LINK,
    DELETE_ACCOUNT_ASSET_LINK,
    GET_ACCOUNT_ASSET_CHAT_LINK,
    GET_FOLDER_ACCOUNT_ASSETS_LINK,
    GET_PENDING_ASSETS_LINK,
    SEND_ACCOUNT_ASSET_MESSAGE_LINK,
    RESEND_ACCOUNT_ASSET_APPROVAL_LINK,
    UPDATE_ACCOUNT_ASSET_LINK,
    UPDATE_ACCOUNT_ASSET_STATUS_LINK,
} from "../../utils/constants/apiLinks";
import { ACCOUNT_ASSET_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addAccountAsset = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.accountAssetData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(ADD_ACCOUNT_ASSET_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.account_assets) {
                            const accountAssets = data.account_assets;
                            dispatch(
                                fetchSuccess(
                                    "Account Asset was uploaded successfully."
                                )
                            );
                            if (callbackFun) callbackFun(accountAssets);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredAccountAssets = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.accountAssetData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_FOLDER_ACCOUNT_ASSETS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (typeof data.folder !== typeof undefined) {
                            const folder = data.folder;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(folder);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

export const getFilteredPendingAssets = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.accountAssetsData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_PENDING_ASSETS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        const accountAssets = data?.account_asset || null;
                        if (accountAssets) {
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [ACCOUNT_ASSET_KEYS.TOTAL]: count[
                                        ACCOUNT_ASSET_KEYS.TOTAL
                                    ]
                                        ? count[ACCOUNT_ASSET_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [ACCOUNT_ASSET_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request?.fromAction && request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.approved) {
                                    successMsg =
                                        "Selected request was approved.";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected request was removed successfully.";
                                } else if (fromAction.inserted) {
                                    successMsg =
                                        "Account Asset was uploaded successfully.";
                                } else if (fromAction.rejected) {
                                    successMsg =
                                        "Selected request was rejected.";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: GET_ACCOUNT_ASSETS,
                                payload: accountAssets,
                            });
                            dispatch({
                                type: GET_ACCOUNT_ASSET_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(accountAssets);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_ACCOUNT_ASSETS, payload: [] });
                dispatch({
                    type: GET_ACCOUNT_ASSET_COUNT,
                    payload: {
                        [ACCOUNT_ASSET_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

export const setCurrentAccountAsset = (accountAsset) => {
    return (dispatch) => {
        dispatch({
            type: SET_ACCOUNT_ASSET_DETAILS,
            payload: accountAsset,
        });
    };
};

export const resendAssetApprovalRequest = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.accountAssetData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(RESEND_ACCOUNT_ASSET_APPROVAL_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.account_assets) {
                            const accountAsset = data.account_assets;
                            dispatch(
                                fetchSuccess("Request was sent successfully")
                            );
                            if (callbackFun) callbackFun(accountAsset);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateAccountAsset = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.accountAssetData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(UPDATE_ACCOUNT_ASSET_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.account_assets) {
                            const accountAsset = data.account_assets;
                            dispatch(
                                fetchSuccess(
                                    "Selected account asset was updated successfully."
                                )
                            );
                            if (callbackFun) callbackFun(accountAsset);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateAccountAssetStatus = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.accountAssetData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_ACCOUNT_ASSET_STATUS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.account_assets) {
                            const request = data.account_assets;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(request);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteAccountAsset = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.accountAssetData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_ACCOUNT_ASSET_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        dispatch(
                            fetchSuccess(
                                "Selected account asset was deleted successfully."
                            )
                        );
                        if (callbackFun)
                            callbackFun(
                                request.accountAssetData[
                                    ACCOUNT_ASSET_KEYS.ACCOUNT_ASSET_ID
                                ]
                            );
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

export const getAssetClientChat = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            default:
                apiLink = GET_ACCOUNT_ASSET_CHAT_LINK;
                break;
        }
        /* console.log("getAssetClientChat: " + apiLink); */
        const body = request.accountAssetData;
        dispatch(fetchStart(false));
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.message) {
                            const chat = data.message;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(chat);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const sendAssetClientMessage = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            default:
                apiLink = SEND_ACCOUNT_ASSET_MESSAGE_LINK;
                break;
        }
        /* console.log("sendAssetClientMessage: " + apiLink); */
        const body = request.accountAssetData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        basicFileAuthAxios
            .post(apiLink, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.message) {
                            if (data.message) {
                                dispatch(fetchSuccess());
                                if (callbackFun) callbackFun(data.message);
                            } else {
                                isError = true;
                                errorMsg = "Unable to get response";
                            }
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = [];

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const downloadAssetBlob = (request = {}, callbackFun) => {
    return (dispatch) => {
        dispatch(fetchStart(false));
        return basicAuthAxios
            .get(request.url, {
                responseType: "blob",
            })
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    dispatch(fetchSuccess());
                    if (callbackFun) callbackFun(response.data);
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};
