import Link from "@mui/material/Link";
import { styled } from "@mui/system";

const HoveredLink = styled(Link)(({ theme }) => {
    const btnMainColor =
        theme.palette?.miscellaneous?.button?.primary || "#112434";

    return {
        display: "inline-block",
        position: "relative",
        color: btnMainColor,
        fontWeight: "bolder",
        textDecoration: "none",
        "&:after": {
            content: '""',
            position: "absolute",
            width: "100%",
            transform: "scaleX(0)",
            height: "2px",
            bottom: 0,
            left: 0,
            backgroundColor: btnMainColor,
            transformOrigin: "bottom right",
            transition: "transform 0.25s ease-out",
        },
        "&:hover:after": {
            transform: "scaleX(1)",
            transformOrigin: "bottom left",
        },
    };
});

export default HoveredLink;
