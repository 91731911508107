import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const a11yProps = (type) => {
    return {
        id: `surveys-tab-${type}`,
        "aria-controls": `surveys-tabpanel-${type}`,
    };
};

const SurveysTabPanel = (props) => {
    const { children, value, type, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== type}
            id={`surveys-tabpanel-${type}`}
            aria-labelledby={`surveys-tab-${type}`}
            {...other}
        >
            {value === type && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

SurveysTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

export default SurveysTabPanel;
