import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import accountAssets from "./accountAssets";
import announcements from "./announcements";
import assetTypes from "./assetTypes";
import assignedServices from "./assignedServices";
import caseStudies from "./caseStudies";
import clientFmUsers from "./clientFmUsers";
import clientUsers from "./clientUsers";
import common from "./common";
import companies from "./companies";
import contactsApp from "./contactsApp";
import fmUsers from "./fmUsers";
import folders from "./folders";
import industries from "./industries";
import invoices from "./invoices";
import meetings from "./meetings";
import officeContacts from "./officeContacts";
import reports from "./reports";
import services from "./services";
import specials from "./specials";
import statements from "./statements";
import surveys from "./surveys";
import tickets from "./tickets";

const exportReducers = (history) => {
    return combineReducers({
        router: connectRouter(history),
        accountAssetsReducer: accountAssets,
        announcementsReducer: announcements,
        assetTypesReducer: assetTypes,
        assignedServicesReducer: assignedServices,
        caseStudiesReducer: caseStudies,
        clientFmUsersReducer: clientFmUsers,
        clientUsersReducer: clientUsers,
        common: common,
        companiesReducer: companies,
        contactsApp: contactsApp,
        fmUsersReducer: fmUsers,
        foldersReducer: folders,
        industriesReducer: industries,
        invoicesReducer: invoices,
        meetingsReducer: meetings,
        officeContactsReducer: officeContacts,
        reportsReducer: reports,
        servicesReducer: services,
        specialsReducer: specials,
        statementsReducer: statements,
        surveysReducer: surveys,
        ticketsReducer: tickets,
    });
};

export default exportReducers;
