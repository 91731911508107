import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import NameLink from "../../shared/widgets/NameLink";
import {
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    TICKET_KEYS,
} from "../../utils/constants/appData";

const headCells = [
    {
        id: TICKET_KEYS.COMPANY,
        label: "Current Communication",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "20%",
    },
    {
        id: TICKET_KEYS.ID,
        label: "Ticket #",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
    {
        id: TICKET_KEYS.CREATED_AT,
        label: "Date",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
    {
        id: TICKET_KEYS.SUBJECT,
        label: "Subject",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "20%",
    },
    {
        id: TICKET_KEYS.DESCRIPTION,
        label: "Description",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "30%",
    },
];

const TicketsTableLoadMore = ({
    ariaLabel,
    canReadDescription,
    loadMore,
    order,
    sort,
    tickets,
    ticketsFetched,
    onLoadMore,
    onSort,
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const fetchedTicketsCount =
        tickets && tickets.length > 0 ? tickets.length : 0;

    const ticketCountNonZero = fetchedTicketsCount > 0;

    return (
        <TableContainer component={Paper}>
            <Table aria-label={ariaLabel}>
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => {
                            const width = headCell?.width || "";
                            const sx = {};
                            if (width) sx.width = width;

                            const sortDirection =
                                sort === headCell.id
                                    ? order === SORT_ORDER_ASC
                                        ? SORT_ORDER_DESC
                                        : SORT_ORDER_ASC
                                    : SORT_ORDER_ASC;

                            return headCell.show ? (
                                <TableCell key={headCell.id} sx={{ ...sx }}>
                                    {headCell.sortColumn ? (
                                        <TableSortLabel
                                            active={sort === headCell.id}
                                            direction={sortDirection}
                                            onClick={() => onSort(headCell.id)}
                                        >
                                            {headCell.label}
                                            {sort === headCell.id ? (
                                                <Box
                                                    component="span"
                                                    sx={visuallyHidden}
                                                >
                                                    {order === SORT_ORDER_DESC
                                                        ? "sorted descending"
                                                        : "sorted ascending"}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        headCell.label
                                    )}
                                </TableCell>
                            ) : null;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        p: (theme) => theme.spacing(1),
                    }}
                >
                    {ticketCountNonZero ? (
                        tickets.map((ticket) => {
                            const ticketId = ticket[TICKET_KEYS.ID];

                            const companyName = ticket[TICKET_KEYS.COMPANY];
                            const companyInitials =
                                ticket[TICKET_KEYS.COMPANY_INITIALS];
                            const companyPhotoLink =
                                ticket[TICKET_KEYS.COMPANY_PHOTO] || "";

                            return (
                                <TableRow
                                    key={ticketId}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1.25}
                                        >
                                            <Avatar
                                                src={companyPhotoLink}
                                                sizes={"small"}
                                                alt={companyName}
                                                sx={{
                                                    bgcolor: "#487AA3",
                                                }}
                                            >
                                                {companyInitials}
                                            </Avatar>
                                            <NameLink
                                                component={RouterLink}
                                                to={`/company/${
                                                    ticket[
                                                        TICKET_KEYS.COMPANY_ID
                                                    ]
                                                }/dashboard/`}
                                            >
                                                {companyName}
                                            </NameLink>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <NameLink
                                            component={RouterLink}
                                            to={`/supportTicket/${ticketId}/`}
                                            sx={(theme) => ({
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            })}
                                        >
                                            {ticket[TICKET_KEYS.TICKETID]}
                                        </NameLink>
                                    </TableCell>
                                    <TableCell>
                                        {ticket[TICKET_KEYS.CREATED_AT]}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "216px",
                                            }}
                                        >
                                            {ticket[TICKET_KEYS.SUBJECT]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {canReadDescription ? (
                                            <Typography
                                                sx={{
                                                    p: 0,
                                                    m: 0,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    width: "320px",
                                                }}
                                            >
                                                {
                                                    ticket[
                                                        TICKET_KEYS.DESCRIPTION
                                                    ]
                                                }
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={colspan}>
                                <Alert
                                    severity={ticketsFetched ? "error" : "info"}
                                >
                                    {ticketsFetched
                                        ? "There are no records found."
                                        : "Loading tickets..."}
                                </Alert>
                            </TableCell>
                        </TableRow>
                    )}
                    {loadMore && (
                        <TableRow>
                            <TableCell
                                colSpan={colspan}
                                sx={{ textAlign: "center" }}
                            >
                                {ticketsFetched ? (
                                    <Button
                                        type="button"
                                        variant="text"
                                        size="small"
                                        color="primary"
                                        onClick={() => onLoadMore()}
                                        sx={{ textDecoration: "none" }}
                                    >
                                        Load More Messages
                                    </Button>
                                ) : (
                                    <CircularProgress size={24} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TicketsTableLoadMore.propTypes = {
    ariaLabel: PropTypes.string,
    canReadDescription: PropTypes.bool,
    loadMore: PropTypes.bool,
    order: PropTypes.string,
    sort: PropTypes.string,
    tickets: PropTypes.array,
    ticketsFetched: PropTypes.bool,
    onLoadMore: PropTypes.func,
    onSort: PropTypes.func,
};

TicketsTableLoadMore.defaultProps = {
    ariaLabel: "Tickets Table",
    canReadDescription: true,
    loadMore: false,
    order: "",
    sort: "",
    tickets: [],
    ticketsFetched: false,
};

export default TicketsTableLoadMore;
