import React from "react";
import Companies from "../pages/companies";
import CompanyDashboard from "../pages/companyDashboard";
/* import TestCompanies from "../pages/companies/TestCompanies"; */
import Page from "@jumbo/shared/Page";

const companiesRoutes = [
    {
        path: "/companies",
        element: <Page component={Companies} />,
    },
    /* {
        path: "/testCompanies",
        element: <Page component={TestCompanies} />,
    }, */
    {
        path: "/company/:companyId/dashboard",
        element: <Page component={CompanyDashboard} />,
    },
];

export default companiesRoutes;
