import React from "react";
import { PatternFormat } from "react-number-format";
import PropTypes from "prop-types";

const FormattedPhoneNo = React.forwardRef(function FormattedPhoneNo(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            format="(###) ###-####"
            mask="_"
            valueIsNumericString
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                        formattedValue: values.formattedValue,
                    },
                });
            }}
        />
    );
});

FormattedPhoneNo.propTypes = {
    onChange: PropTypes.func,
};

FormattedPhoneNo.defaultProps = {
    onChange: () => {},
};

export default FormattedPhoneNo;
