import React from "react";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    AUTH_USER_KEYS,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import AssignedServices from "./AssignedServices";

const ClientAssignedServices = () => {
    const { authUser } = useJumboAuth();

    const authUserCompanyId =
        authUser &&
        authUser?.[AUTH_USER_KEYS.COMPANY_ID] &&
        authUser[AUTH_USER_KEYS.COMPANY_ID]
            ? authUser[AUTH_USER_KEYS.COMPANY_ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    if (isValidUserType) {
        return <AssignedServices companyParam={authUserCompanyId} />;
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="error" variant="outlined">
                        {AUTHORIZATION_ERROR}
                    </Alert>
                </Grid>
            </Grid>
        );
    }
};

export default ClientAssignedServices;
