import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import FmButton from "../../shared/widgets/FmButton";

const SpecialActions = ({
    canAddEditSpecials,
    canUploadFlyers,
    canViewDescription,
    onEdit,
    onUploadFlyers,
    onViewDescription,
}) => {
    return (
        <Stack
            spacing={1}
            direction={{
                xs: "column",
                md: "row",
            }}
            flexWrap="wrap"
            alignItems={{ xs: "start", md: "center" }}
            justifyContent={{ xs: "start", md: "end" }}
        >
            {canViewDescription && (
                <FmButton
                    label="View Description"
                    onClick={onViewDescription}
                />
            )}
            {canAddEditSpecials && (
                <FmButton
                    startIcon="edit"
                    type="button"
                    label="Edit Special"
                    onClick={onEdit}
                />
            )}
            {canUploadFlyers && (
                <FmButton
                    startIcon="upload"
                    type="button"
                    label="Upload Flyers"
                    onClick={onUploadFlyers}
                />
            )}
        </Stack>
    );
};

SpecialActions.propTypes = {
    canAddEditSpecials: PropTypes.bool,
    canUploadFlyers: PropTypes.bool,
    canViewDescription: PropTypes.bool,
    onEdit: PropTypes.func,
    onUploadFlyers: PropTypes.func,
    onViewDescription: PropTypes.func,
};

SpecialActions.defaultProps = {
    canAddEditSpecials: false,
    canUploadFlyers: false,
    canViewDescription: false,
    onEdit: () => {},
    onUploadFlyers: () => {},
    onViewDescription: () => {},
};

export default SpecialActions;
