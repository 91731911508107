import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import EmptyValue from "../../shared/widgets/EmptyValue";
import FormattedPhoneNoTxt from "../../shared/widgets/FormattedPhoneNoTxt";
import LinkButton from "../../shared/widgets/LinkButton";
import NameLink from "../../shared/widgets/NameLink";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    COMPANY_ACTIVE_STATUS,
    COMPANY_KEYS,
    FM_USER_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";
import { cleanPhoneNo } from "../../utils/dataHelpers";
import CompaniesActionsList from "./CompaniesActionsList";

const StylesTblCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const CompaniesTable = ({
    canAddEditCompany,
    canAddTicket,
    canEditRenewalDate,
    canManageLocations,
    canManageNotes,
    canManageSidebar,
    canViewClientUsers,
    canViewDashboard,
    canViewGoogleSheet,
    companies,
    companyCounts,
    companiesFetched,
    columnsToShow,
    headCells,
    order,
    page,
    rowsPerPage,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onAddTicket,
    onArchive,
    onDelete,
    onEdit,
    onEditRenewalDate,
    onManageLocations,
    onManageNotes,
    onManageSidebar,
    onRestore,
    onSort,
}) => {
    const navigate = useNavigate();

    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const companyCountNonZero =
        companies &&
        companies.length > 0 &&
        companyCounts &&
        companyCounts[COMPANY_KEYS.TOTAL] &&
        !isNaN(parseInt(companyCounts[COMPANY_KEYS.TOTAL])) &&
        parseInt(companyCounts[COMPANY_KEYS.TOTAL]) > 0;

    const numTotalCols = headCells.length;
    const numVisibleCols = columnsToShow.length;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Companies Table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const sx = {};

                                if (headCell?.width) {
                                    const headCellWidth = Number(
                                        headCell.width.match(/\d+(\.\d+)?/)[0]
                                    );
                                    const updHeadCellWidth =
                                        (numTotalCols / numVisibleCols) *
                                        headCellWidth;
                                    sx.width = `${updHeadCellWidth.toFixed(
                                        2
                                    )}%`;
                                }

                                if (headCell?.textAlign)
                                    sx.textAlign = headCell.textAlign;

                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <StylesTblCell
                                        key={headCell.id}
                                        sx={{ ...sx }}
                                    >
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </StylesTblCell>
                                ) : null;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {companyCountNonZero ? (
                            companies.map((company) => {
                                const companyId = company[COMPANY_KEYS.ID];
                                const companyName = company[COMPANY_KEYS.NAME];
                                const companyEmail =
                                    company[COMPANY_KEYS.EMAIL] || "";

                                const companyPhoneNo =
                                    company[COMPANY_KEYS.PHONE_NO] || "";
                                const companyPhoneNoExt =
                                    company[COMPANY_KEYS.PHONE_NO_EXT] || "";
                                const completePhoneNo =
                                    companyPhoneNo && companyPhoneNoExt
                                        ? `${companyPhoneNo}${companyPhoneNoExt}`
                                        : companyPhoneNo;

                                const companyWebsite =
                                    company[COMPANY_KEYS.WEBSITE] || "";

                                const fetchedPhoto =
                                    company[COMPANY_KEYS.LOGO_PIC];
                                let photoLink = "";
                                if (fetchedPhoto) {
                                    photoLink = `${
                                        company[COMPANY_KEYS.LOGO_BASE_URL]
                                    }/${
                                        fetchedPhoto[
                                            COMPANY_KEYS.LOGO_FILE_PATH
                                        ]
                                    }`;
                                }
                                const companyInitials = photoLink ? (
                                    <React.Fragment>&nbsp;</React.Fragment>
                                ) : (
                                    getInitialsFromName(companyName)
                                );

                                const assignedAEs =
                                    company[COMPANY_KEYS.ASSIGNED_AE] || [];
                                const status =
                                    company[COMPANY_KEYS.STATUS] || "";
                                const isActive =
                                    status === COMPANY_ACTIVE_STATUS;

                                return (
                                    <TableRow
                                        key={companyId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <StylesTblCell
                                            component="th"
                                            scope="row"
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1.25}
                                            >
                                                <Avatar
                                                    src={photoLink}
                                                    sizes={"small"}
                                                    alt={companyName}
                                                    sx={{
                                                        bgcolor: "#487AA3",
                                                    }}
                                                >
                                                    {companyInitials}
                                                </Avatar>
                                                {isActive &&
                                                canViewDashboard ? (
                                                    <NameLink
                                                        component={RouterLink}
                                                        to={`/company/${companyId}/dashboard/`}
                                                    >
                                                        {companyName}
                                                    </NameLink>
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {companyName}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </StylesTblCell>
                                        <StylesTblCell>
                                            <CompaniesActionsList
                                                canAddEditCompany={
                                                    canAddEditCompany
                                                }
                                                canEditRenewalDate={
                                                    canEditRenewalDate
                                                }
                                                canManageLocations={
                                                    canManageLocations
                                                }
                                                canManageNotes={canManageNotes}
                                                canManageSidebar={
                                                    canManageSidebar
                                                }
                                                canViewClientUsers={
                                                    canViewClientUsers
                                                }
                                                canViewGoogleSheet={
                                                    canViewGoogleSheet
                                                }
                                                company={company}
                                                onArchive={onArchive}
                                                onDelete={onDelete}
                                                onEdit={onEdit}
                                                onEditRenewalDate={
                                                    onEditRenewalDate
                                                }
                                                onManageLocations={
                                                    onManageLocations
                                                }
                                                onManageNotes={onManageNotes}
                                                onManageSidebar={
                                                    onManageSidebar
                                                }
                                                onRestore={onRestore}
                                            />
                                        </StylesTblCell>
                                        {columnsToShow.includes(
                                            COMPANY_KEYS.EMAIL
                                        ) && (
                                            <StylesTblCell>
                                                {isActive ? (
                                                    canAddTicket &&
                                                    companyEmail ? (
                                                        <LinkButton
                                                            component="button"
                                                            onClick={() =>
                                                                onAddTicket(
                                                                    company
                                                                )
                                                            }
                                                            sx={{
                                                                p: 0,
                                                                textAlign:
                                                                    "left",
                                                                wordBreak:
                                                                    "break-word",
                                                            }}
                                                        >
                                                            {companyEmail}
                                                        </LinkButton>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {companyEmail}
                                                    </Typography>
                                                )}
                                            </StylesTblCell>
                                        )}
                                        {columnsToShow.includes(
                                            COMPANY_KEYS.PHONE_NO
                                        ) && (
                                            <StylesTblCell>
                                                {
                                                    <FormattedPhoneNoTxt
                                                        extension={cleanPhoneNo(
                                                            companyPhoneNoExt
                                                        )}
                                                        value={cleanPhoneNo(
                                                            completePhoneNo
                                                        )}
                                                    />
                                                }
                                            </StylesTblCell>
                                        )}
                                        {columnsToShow.includes(
                                            COMPANY_KEYS.WEBSITE
                                        ) && (
                                            <StylesTblCell>
                                                {isActive ? (
                                                    companyWebsite ? (
                                                        <NameLink
                                                            href={
                                                                companyWebsite
                                                            }
                                                            target="_blank"
                                                            sx={{
                                                                wordBreak:
                                                                    "break-word",
                                                            }}
                                                        >
                                                            {companyWebsite}
                                                        </NameLink>
                                                    ) : (
                                                        <EmptyValue />
                                                    )
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {companyWebsite}
                                                    </Typography>
                                                )}
                                            </StylesTblCell>
                                        )}
                                        {columnsToShow.includes(
                                            COMPANY_KEYS.INDUSTRY
                                        ) && (
                                            <StylesTblCell>
                                                {company[
                                                    COMPANY_KEYS.INDUSTRY
                                                ] || ""}
                                            </StylesTblCell>
                                        )}
                                        {columnsToShow.includes(
                                            COMPANY_KEYS.RENEWAL_DATE
                                        ) && (
                                            <StylesTblCell>
                                                {company?.[
                                                    COMPANY_KEYS.RENEWAL_DATE
                                                ] || <EmptyValue />}
                                            </StylesTblCell>
                                        )}
                                        {columnsToShow.includes(
                                            COMPANY_KEYS.ASSIGNED_AE
                                        ) && (
                                            <StylesTblCell>
                                                {assignedAEs.length > 0 ? (
                                                    <AvatarGroup
                                                        max={12}
                                                        sx={{
                                                            maxWidth: "100%",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        {assignedAEs.map(
                                                            (
                                                                assignedAE,
                                                                assignedAEIdx
                                                            ) => {
                                                                const assignedAEId =
                                                                    assignedAE[
                                                                        FM_USER_KEYS
                                                                            .ID
                                                                    ];
                                                                const assignedAEName =
                                                                    assignedAE[
                                                                        FM_USER_KEYS
                                                                            .NAME
                                                                    ];
                                                                const assignedAEInitials =
                                                                    assignedAE[
                                                                        FM_USER_KEYS
                                                                            .PHOTO_TEXT
                                                                    ];
                                                                const assignedAEPhoto =
                                                                    assignedAE[
                                                                        FM_USER_KEYS
                                                                            .PROFILE_PIC
                                                                    ] || "";

                                                                return (
                                                                    <Avatar
                                                                        key={`assigned-ae-${assignedAEIdx}-${assignedAEId}`}
                                                                        alt={
                                                                            assignedAEName
                                                                        }
                                                                        src={
                                                                            assignedAEPhoto
                                                                        }
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/fmUser/${assignedAEId}/`
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        {assignedAEPhoto ||
                                                                            assignedAEInitials}
                                                                    </Avatar>
                                                                );
                                                            }
                                                        )}
                                                    </AvatarGroup>
                                                ) : (
                                                    <EmptyValue align="center" />
                                                )}
                                            </StylesTblCell>
                                        )}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <StylesTblCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            companiesFetched ? "error" : "info"
                                        }
                                    >
                                        {companiesFetched
                                            ? "There are no records found."
                                            : "Loading companies..."}
                                    </Alert>
                                </StylesTblCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    companyCountNonZero
                        ? parseInt(companyCounts[COMPANY_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={companyCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

CompaniesTable.propTypes = {
    canAddEditCompany: PropTypes.bool,
    canAddTicket: PropTypes.bool,
    canEditRenewalDate: PropTypes.bool,
    canManageLocations: PropTypes.bool,
    canManageNotes: PropTypes.bool,
    canManageSidebar: PropTypes.bool,
    canViewClientUsers: PropTypes.bool,
    canViewDashboard: PropTypes.bool,
    canViewGoogleSheet: PropTypes.bool,
    companies: PropTypes.array,
    companyCounts: PropTypes.object,
    companiesFetched: PropTypes.bool,
    columnsToShow: PropTypes.array,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onAddTicket: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onEditRenewalDate: PropTypes.func,
    onManageLocations: PropTypes.func,
    onManageNotes: PropTypes.func,
    onManageSidebar: PropTypes.func,
    onRestore: PropTypes.func,
    onSort: PropTypes.func,
};

CompaniesTable.defaultProps = {
    canAddEditCompany: false,
    canAddTicket: false,
    canEditRenewalDate: false,
    canManageLocations: false,
    canManageNotes: false,
    canManageSidebar: false,
    canViewClientUsers: false,
    canViewDashboard: false,
    canViewGoogleSheet: false,
    companies: [],
    companyCounts: null,
    companiesFetched: false,
    columnsToShow: [],
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onAddTicket: () => {},
    // onArchive: () => {},
    // onDelete: () => {},
    // onEdit: () => {},
    // onEditRenewalDate: () => {},
    // onManageLocations: () => {},
    // onManageNotes: () => {},
    onManageSidebar: () => {},
    // onRestore: () => {},
    // onSort: () => {},
};

export default CompaniesTable;
