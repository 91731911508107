import {
    GET_INDUSTRY_COUNT,
    GET_INDUSTRIES,
} from "../../utils/constants/actionTypes";
import { INDUSTRY_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    industries: [],
    /* currentIndustry: null, */
    industryCounts: { [INDUSTRY_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INDUSTRIES: {
            return {
                ...state,
                industries: action.payload,
            };
        }
        case GET_INDUSTRY_COUNT: {
            return {
                ...state,
                industryCounts: action.payload,
            };
        }
        /* case SET_INDUSTRY_DETAILS: {
            return {
                ...state,
                currentIndustry: action.payload,
            };
        } */
        /* case ADD_INDUSTRY: {
            return {
                ...state,
                industries: [action.payload, ...state.industries],
            };
        } */
        /* case EDIT_INDUSTRY: {
            return {
                ...state,
                industries: state.industries.map((industry) =>
                    industry.id === action.payload.id
                        ? action.payload
                        : industry
                ),
            };
        } */
        /* case DELETE_INDUSTRY: {
            return {
                ...state,
                industries: state.industries.filter(
                    (industry) => industry.id !== action.payload
                ),
            };
        } */
        default:
            return state;
    }
};

export default reducerFunc;
