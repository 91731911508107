import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import ClientFmUserCard from "./ClientFmUserCard";
import { getFilteredClientFmUsers } from "../../redux/actions/clientFmUsers";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    FM_USER_KEYS,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    API_COMMON_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import {
    STATUS_ERROR,
    STATUS_IN_PROGRESS,
    STATUS_SUCCESS,
} from "../../utils/constants/miscellaneous";

const fetchStateReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "SET_ERROR_STATUS") {
            return { status: STATUS_ERROR, msg: action.msg };
        } else if (action.type === "SET_SUCCESS_STATUS") {
            return { status: STATUS_SUCCESS, msg: "" };
        } else if (action.type === "SET_IN_PROGRESS_STATUS") {
            return { status: STATUS_IN_PROGRESS, msg: "" };
        }
    }
    return { status: "", msg: "" };
};

const CLIENT_FM_USERS_LIMIT = 16;

const fmUsersReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                fetchFmUsers: true,
                fmUsersFetched: false,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                firstLoad: false,
                loadMore: false,
                page: 0,
                rowsPerPage: CLIENT_FM_USERS_LIMIT,
                fmUsers: [],
                fmUsersFetched: true,
            };
        } else if (action.type === "SET_FM_USERS") {
            const currentFmUsers = state.fmUsers || [];
            const updatedFmUsers = [...currentFmUsers];

            const fetchedTotals = action.totals;
            const numTotalFmUsers = fetchedTotals[FM_USER_KEYS.TOTAL];

            const fetchedFmUsers = action.fmUsers;
            const numFetchedFmUsers = fetchedFmUsers.length;
            if (fetchedFmUsers.length > 0) {
                let numAddedFmUsers = 0;

                for (let i = 0; i < numFetchedFmUsers; i++) {
                    const fetchedFmUser = fetchedFmUsers[i];
                    if (
                        fetchedFmUser &&
                        fetchedFmUser?.[FM_USER_KEYS.ID] &&
                        fetchedFmUser[FM_USER_KEYS.ID]
                    ) {
                        const foundFmUser = updatedFmUsers.find(
                            (fmUserObj) =>
                                fmUserObj[FM_USER_KEYS.ID] ===
                                fetchedFmUser[FM_USER_KEYS.ID]
                        );
                        if (!foundFmUser) {
                            updatedFmUsers.push(fetchedFmUser);
                            numAddedFmUsers += 1;
                        }
                    }
                }

                const numUpdatedFmUsers = updatedFmUsers.length;
                if (numUpdatedFmUsers === numTotalFmUsers) {
                    return {
                        ...state,
                        fmUsers: updatedFmUsers,
                        loadMore: false,
                    };
                } else {
                    const maxLimit = state.rowsPerPage;
                    if (numAddedFmUsers === maxLimit) {
                        return {
                            ...state,
                            page: state.page + 1,
                            loadMore: true,
                            fmUsers: updatedFmUsers,
                        };
                    } else {
                        return {
                            ...state,
                            fmUsers: updatedFmUsers,
                            loadMore: false,
                        };
                    }
                }
            } else {
                return { ...state, fmUsers: updatedFmUsers, loadMore: false };
            }
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                firstLoad: false,
                fmUsersFetched: true,
            };
        }
    }

    return {
        fetchFmUsers: false,
        firstLoad: true,
        fmUsers: [],
        fmUsersFetched: false,
        loadMore: false,
        page: 0,
        rowsPerPage: CLIENT_FM_USERS_LIMIT,
    };
};

const pageName = "Your Firm Media Team Contacts";

const ClientFmUsers = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isClientFmUsersMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [isLoading, seIsLoading] = useState(false);

    const [fetchState, dispatchFetchState] = useReducer(fetchStateReducer, {
        status: "",
        msg: "",
    });
    const { status: fetchStatus } = fetchState;

    const [fmUsersState, dispatchFmUsersAction] = useReducer(fmUsersReducer, {
        fetchFmUsers: false,
        firstLoad: true,
        fmUsers: [],
        fmUsersFetched: false,
        loadMore: false,
        page: 0,
        rowsPerPage: CLIENT_FM_USERS_LIMIT,
    });
    const {
        fetchFmUsers,
        fmUsers: clientFmUsers,
        fmUsersFetched,
        firstLoad,
        loadMore,
        page,
        rowsPerPage,
    } = fmUsersState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    const authUserGlobalCompany =
        authUser &&
        authUser?.[AUTH_USER_KEYS.GLOBAL_COMPANY] &&
        authUser[AUTH_USER_KEYS.GLOBAL_COMPANY]
            ? authUser[AUTH_USER_KEYS.GLOBAL_COMPANY]
            : "";

    useEffect(() => {
        return () => {
            _isClientFmUsersMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isValidUserType) {
            seIsLoading(true);
        }
    }, [isValidUserType]);

    useEffect(() => {
        if (isLoading) {
            dispatchFmUsersAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        if (!fmUsersFetched) {
            dispatchFetchState({ type: "SET_IN_PROGRESS_STATUS" });
        }
    }, [fmUsersFetched, dispatchFetchState]);

    useEffect(() => {
        let isActive = true;

        if (fetchStatus === STATUS_IN_PROGRESS) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredClientFmUsers(payload, (fetchedData) => {
                            if (isActive) {
                                dispatchFetchState({
                                    type: "SET_SUCCESS_STATUS",
                                });
                                dispatchFmUsersAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchFmUsersAction({
                                    type: "SET_FM_USERS",
                                    fmUsers: fetchedData?.fmUsers || [],
                                    totals: fetchedData?.totals || null,
                                });
                                seIsLoading(false);
                            }
                        })
                    );
                };
            };

            const clientFmUserData = {
                authcode: authToken,
                page: page + 1,
                rows_per_page: rowsPerPage,
            };

            const payload = {
                clientFmUserData: clientFmUserData,
                authUserType: authUserType,
            };
            if (!firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchFetchState({
                        type: "SET_ERROR_STATUS",
                        msg: error?.message || API_COMMON_ERROR,
                    });
                    dispatchFmUsersAction({ type: "RESET" });
                    seIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        dispatchFetchState,
        dispatchFmUsersAction,
        authToken,
        authUserType,
        fetchStatus,
        firstLoad,
        isValidUserType,
        page,
        rowsPerPage,
    ]);

    const handleLoadMore = () => dispatchFmUsersAction({ type: "FETCH" });

    const handleRetry = () => dispatchFmUsersAction({ type: "FETCH" });

    const clientFmUsersValid =
        fetchFmUsers && clientFmUsers && clientFmUsers.length > 0;

    const fetchError =
        fetchStatus === STATUS_ERROR ||
        (fetchStatus === STATUS_SUCCESS && !clientFmUsersValid);

    const loadError = !isValidUserType || fetchError || !clientFmUsersValid;

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle component="h2" title={pageName} type="v2" />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            <Grid item xs={12}>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    flexWrap="wrap"
                    alignItems={{ xs: "start", md: "baseline" }}
                    justifyContent={{ xs: "start", md: "end" }}
                    spacing={1}
                >
                    {clientFmUsersValid && loadMore && (
                        <LoadingButton
                            loading={!fmUsersFetched}
                            type="button"
                            variant="outlined"
                            size="small"
                            loadingPosition="start"
                            className="btnHomeLoadMore"
                            startIcon={<RefreshIcon />}
                            onClick={handleLoadMore}
                        >
                            Load More {authUserGlobalCompany} Users
                        </LoadingButton>
                    )}
                    {!clientFmUsersValid && (
                        <LoadingButton
                            loading={!fmUsersFetched}
                            type="button"
                            variant="outlined"
                            size="small"
                            loadingPosition="start"
                            className="btnHomeRetry"
                            startIcon={<RefreshIcon />}
                            onClick={handleRetry}
                        >
                            Retry Loading {authUserGlobalCompany} Users
                        </LoadingButton>
                    )}
                </Stack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!clientFmUsersValid && (
                        <Grid item xs={12}>
                            <Alert
                                variant="outlined"
                                severity="error"
                                sx={{ mt: 4 }}
                            >
                                There was a problem in fetching the users!
                            </Alert>
                        </Grid>
                    )}
                    {fetchError && (
                        <Grid item xs={12}>
                            <Alert
                                variant="outlined"
                                severity="error"
                                sx={{ mt: 4 }}
                            >
                                {fetchState.msg || "No users found!"}
                            </Alert>
                        </Grid>
                    )}
                    {!authUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <Grid item xs={12}>
                    {fetchFmUsers && (
                        <Grid container spacing={2}>
                            {clientFmUsers.map((clientFmUser) => {
                                const clientFmUserId =
                                    clientFmUser[FM_USER_KEYS.ID];
                                /* const cardActions = [
                                {
                                    type: "button",
                                    buttonType: "button",
                                    variant: "outlined",
                                    label: "View Account Asset",
                                    href: clientFmUser[
                                        FM_USER_KEYS.DOCUMENT_LINK
                                    ],
                                    sx: { ...sx },
                                },
                            ]; */
                                return (
                                    <Grid
                                        item
                                        key={`client-fm-user-${clientFmUserId}`}
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        sx={{ p: 0 }}
                                    >
                                        <ClientFmUserCard
                                            clientFmUser={clientFmUser}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default ClientFmUsers;
