import React from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomAlert from "../../shared/widgets/CustomAlert";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    SPECIAL_KEYS,
    SPECIAL_FLYER_KEYS,
    FLYER_APPROVED_STATUS,
    FLYER_PENDING_STATUS,
} from "../../utils/constants/appData";

const FlyerHeader = ({ canApprove, currentSpecial, onApprove }) => {
    const flyers = currentSpecial?.[SPECIAL_KEYS.UPLOADED_FLYERS] || [];
    const numFlyers = flyers.length;

    return (
        <Stack direction="column" spacing={2}>
            <Typography
                component="h2"
                variant="h4"
                sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
            >
                Flyers
            </Typography>
            {flyers && flyers.length > 0 ? (
                <List
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.background.paper,
                    }}
                >
                    {flyers.map((flyer, idx) => {
                        const lastFlyer = idx === numFlyers - 1;
                        const isFlyerApproved =
                            flyer[SPECIAL_FLYER_KEYS.STATUS] ===
                            FLYER_APPROVED_STATUS;
                        const isFlyerPending =
                            flyer[SPECIAL_FLYER_KEYS.STATUS] ===
                            FLYER_PENDING_STATUS;

                        return (
                            <React.Fragment
                                key={`ticket-${
                                    flyer[SPECIAL_FLYER_KEYS.ID]
                                }-document-${idx}`}
                            >
                                <ListItem>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Stack
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                                alignItems={{
                                                    xs: "flex-start",
                                                }}
                                                justifyContent={{
                                                    xs: "flex-start",
                                                    md: "space-between",
                                                }}
                                                spacing={0.5}
                                            >
                                                <Link
                                                    href={
                                                        flyer[
                                                            SPECIAL_FLYER_KEYS
                                                                .LINK
                                                        ]
                                                    }
                                                    target="_blank"
                                                    rel="noopener"
                                                    underline="hover"
                                                    color="text.primary"
                                                    sx={{
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                    {
                                                        flyer[
                                                            SPECIAL_FLYER_KEYS
                                                                .NAME
                                                        ]
                                                    }
                                                </Link>
                                                {isFlyerPending ? (
                                                    canApprove ? (
                                                        <TableActionIcon
                                                            iconComponent="done"
                                                            label="approve flyer"
                                                            tooltip="Approve Flyer"
                                                            onClick={() =>
                                                                onApprove(flyer)
                                                            }
                                                        />
                                                    ) : (
                                                        <Typography
                                                            sx={(theme) => ({
                                                                fontWeight:
                                                                    "bold",
                                                                color: theme
                                                                    .palette
                                                                    .warning
                                                                    .main,
                                                            })}
                                                        >
                                                            Approval Pending
                                                        </Typography>
                                                    )
                                                ) : isFlyerApproved ? (
                                                    <Typography
                                                        sx={(theme) => ({
                                                            fontWeight: "bold",
                                                            color: theme.palette
                                                                .success.main,
                                                        })}
                                                    >
                                                        Approved
                                                    </Typography>
                                                ) : null}
                                            </Stack>
                                        }
                                        secondary={
                                            <Stack
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                                alignItems={{
                                                    xs: "flex-start",
                                                }}
                                                spacing={0.5}
                                            >
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.disabled"
                                                >
                                                    Uploaded By:{" "}
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette
                                                                .secondary.main,
                                                    }}
                                                >
                                                    {
                                                        flyer[
                                                            SPECIAL_FLYER_KEYS
                                                                .USER_NAME
                                                        ]
                                                    }
                                                </Typography>
                                            </Stack>
                                        }
                                    />
                                </ListItem>
                                {!lastFlyer && <Divider />}
                            </React.Fragment>
                        );
                    })}
                </List>
            ) : (
                <CustomAlert
                    colorType="dark"
                    customSx={{
                        border: "2px solid",
                    }}
                    severity="info"
                    variant="outlined"
                >
                    No flyers uploaded!
                </CustomAlert>
            )}
        </Stack>
    );
};

FlyerHeader.propTypes = {
    canApprove: PropTypes.bool,
    onApprove: PropTypes.func,
};

FlyerHeader.defaultProps = {
    canApprove: false,
    // onApprove: () => {},
};

export default FlyerHeader;
