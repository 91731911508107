import React, { useEffect, useMemo, useRef, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import {
    AUTH_USER_KEYS,
    SPECIAL_DOCUMENT_KEYS,
    SPECIAL_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { formatBytes } from "../../utils/appHelpers";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";

const SpecialDescriptionDialog = ({ currentSpecial, open, onClose }) => {
    const _isSpecialDescriptionDialogMounted = useRef(true);
    const { authUser } = useJumboAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [specialIsValid, setSpecialIsValid] = useState(false);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    useEffect(() => {
        return () => {
            _isSpecialDescriptionDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setIsLoading(false);
            setSpecialIsValid(false);
        }
    }, [open]);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    useEffect(() => {
        if (isLoading) {
            if (
                currentSpecial &&
                currentSpecial?.[SPECIAL_KEYS.ID] &&
                currentSpecial[SPECIAL_KEYS.ID]
            ) {
                setSpecialIsValid(true);
            } else {
                setSpecialIsValid(false);
            }
        }
    }, [currentSpecial, isLoading]);

    let specialDocumentsHtml = useMemo(() => {
        if (specialIsValid) {
            const specialDocuments =
                currentSpecial?.[SPECIAL_KEYS.UPLOADED_DOCUMENTS] || [];
            if (specialDocuments && specialDocuments.length > 0) {
                return (
                    <List disablePadding>
                        {specialDocuments.map((specialDocument, idx) => {
                            return (
                                <ListItem
                                    disableGutters
                                    key={`special-${
                                        specialDocument[
                                            SPECIAL_DOCUMENT_KEYS.ID
                                        ]
                                    }-document-${idx}`}
                                    component={Link}
                                    href={
                                        specialDocument[
                                            SPECIAL_DOCUMENT_KEYS.LINK
                                        ]
                                    }
                                    target="_blank"
                                    rel="noopener"
                                    underline="hover"
                                >
                                    <ListItemButton disableGutters>
                                        <ListItemIcon>
                                            <FilePresentIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${
                                                specialDocument[
                                                    SPECIAL_DOCUMENT_KEYS.NAME
                                                ]
                                            } - ${formatBytes(
                                                specialDocument[
                                                    SPECIAL_DOCUMENT_KEYS.SIZE
                                                ]
                                            )}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }, [currentSpecial, specialIsValid]);

    const loadError = !isValidUserType || !specialIsValid;

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    currentSpecial
                        ? `${currentSpecial[SPECIAL_KEYS.TITLE]}`
                        : "Invalid Special!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    {isLoading && (
                        <Grid item xs={12}>
                            <FmCircularProgress showBackDrop />
                        </Grid>
                    )}
                    {!isLoading && loadError && (
                        <React.Fragment>
                            {!isValidUserType && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        {AUTHORIZATION_ERROR}
                                    </Alert>
                                </Grid>
                            )}
                            {isValidUserType && !specialIsValid && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        Invalid special!
                                    </Alert>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                    {!loadError && (
                        <React.Fragment>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    "& .cke_chrome": {
                                        border: "none",
                                    },
                                    "& .cke_inner.cke_reset": {
                                        border: "none",
                                    },
                                    "& .cke_top.cke_reset_all": {
                                        display: "none",
                                    },
                                    "& .cke_bottom.cke_reset_all": {
                                        display: "none",
                                    },
                                    borderBottom: (theme) =>
                                        specialDocumentsHtml
                                            ? `1px solid ${theme.palette.divider}`
                                            : "none",
                                }}
                            >
                                <CKEditor
                                    id="description"
                                    name="description"
                                    initData={
                                        currentSpecial[SPECIAL_KEYS.DESCRIPTION]
                                    }
                                    config={{
                                        contentsCss: [
                                            `${ASSET_MUSEO_SANS}/styles.css`,
                                            `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                        ],
                                    }}
                                    readOnly
                                    onInstanceReady={() => {
                                        setIsLoading(false);
                                    }}
                                />
                            </Grid>
                            {!isLoading && specialDocumentsHtml && (
                                <Grid item xs={12}>
                                    <Typography variant="h4" component="h3">
                                        Uploaded Documents
                                    </Typography>
                                    {specialDocumentsHtml}
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

SpecialDescriptionDialog.propTypes = {
    currentSpecial: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

SpecialDescriptionDialog.defaultProps = {
    currentSpecial: null,
    open: false,
    // onClose: () => {},
};

export default SpecialDescriptionDialog;
