import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import EmptyValue from "../../shared/widgets/EmptyValue";
import FormattedPhoneNoTxt from "../../shared/widgets/FormattedPhoneNoTxt";
import LinkButton from "../../shared/widgets/LinkButton";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    CLIENT_USER_ACTIVE_STATUS,
    CLIENT_USER_ARCHIVE_STATUS,
    /* CLIENT_USER_INACTIVE_STATUS, */
    CLIENT_USER_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";
import { cleanPhoneNo } from "../../utils/dataHelpers";

const CompanyClientUsersTable = ({
    canAddEditClient,
    canArchiveClient,
    canCreateTicket,
    canDeleteClient,
    canRestoreClient,
    /* canUpdateClient, */
    canViewOverview,
    clientUsers,
    clientUserCounts,
    clientUsersFetched,
    columnsToShow,
    headCells,
    order,
    page,
    rowsPerPage,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onArchive,
    onCreateTicket,
    onEdit,
    onDelete,
    onRestore,
    onSort,
    /* onUpdateStatus, */
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const clientUserCountNonZero =
        clientUsers &&
        clientUsers.length > 0 &&
        clientUserCounts &&
        clientUserCounts[CLIENT_USER_KEYS.TOTAL] &&
        !isNaN(parseInt(clientUserCounts[CLIENT_USER_KEYS.TOTAL])) &&
        parseInt(clientUserCounts[CLIENT_USER_KEYS.TOTAL]) > 0;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Client Users Table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            <TableCell
                                key="actions"
                                sx={{ textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {clientUserCountNonZero ? (
                            clientUsers.map((clientUser) => {
                                const clientUserId =
                                    clientUser[CLIENT_USER_KEYS.ID];
                                const clientUserName =
                                    clientUser[CLIENT_USER_KEYS.NAME];
                                const clientUserEmail =
                                    clientUser[CLIENT_USER_KEYS.EMAIL];
                                const clientUserPhoneNo =
                                    clientUser[CLIENT_USER_KEYS.PHONE_NO];

                                const clientUserStatus =
                                    clientUser[CLIENT_USER_KEYS.STATUS];
                                const isActiveClientUser =
                                    clientUserStatus ===
                                    CLIENT_USER_ACTIVE_STATUS;
                                const isArchivedClientUser =
                                    clientUserStatus ===
                                    CLIENT_USER_ARCHIVE_STATUS;
                                /* const isInactiveClientUser =
                                    clientUserStatus ===
                                    CLIENT_USER_INACTIVE_STATUS; */

                                const fetchedPhoto =
                                    clientUser[CLIENT_USER_KEYS.PROFILE_PIC];
                                let photoLink = "";
                                if (fetchedPhoto) {
                                    photoLink = `${
                                        clientUser[
                                            CLIENT_USER_KEYS.PHOTO_BASE_URL
                                        ]
                                    }/${
                                        fetchedPhoto[
                                            CLIENT_USER_KEYS.PHOTO_FILE_PATH
                                        ]
                                    }`;
                                }
                                const clientUserInitials = photoLink ? (
                                    <React.Fragment>&nbsp;</React.Fragment>
                                ) : (
                                    getInitialsFromName(clientUserName)
                                );

                                return (
                                    <TableRow
                                        key={clientUserId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        {columnsToShow.includes(
                                            CLIENT_USER_KEYS.NAME
                                        ) && (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1.25}
                                                >
                                                    <Avatar
                                                        src={photoLink}
                                                        sizes={"small"}
                                                        alt={clientUserName}
                                                        sx={{
                                                            bgcolor: "#487AA3",
                                                        }}
                                                    >
                                                        {clientUserInitials}
                                                    </Avatar>

                                                    {isActiveClientUser &&
                                                    canViewOverview ? (
                                                        <NameLink
                                                            component={
                                                                RouterLink
                                                            }
                                                            to={`/clientUser/${clientUserId}/overview/`}
                                                        >
                                                            {clientUserName}
                                                        </NameLink>
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                wordBreak:
                                                                    "break-word",
                                                            }}
                                                        >
                                                            {clientUserName}
                                                        </Typography>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                        )}
                                        {columnsToShow.includes(
                                            CLIENT_USER_KEYS.EMAIL
                                        ) && (
                                            <TableCell>
                                                {isActiveClientUser &&
                                                canCreateTicket ? (
                                                    <LinkButton
                                                        component="button"
                                                        onClick={() =>
                                                            onCreateTicket(
                                                                clientUser
                                                            )
                                                        }
                                                        sx={{
                                                            p: 0,
                                                            textAlign: "left",
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {clientUserEmail}
                                                    </LinkButton>
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            wordBreak:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {clientUserEmail}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        )}
                                        {columnsToShow.includes(
                                            CLIENT_USER_KEYS.PHONE_NO
                                        ) && (
                                            <TableCell>
                                                {clientUserPhoneNo ? (
                                                    <FormattedPhoneNoTxt
                                                        value={cleanPhoneNo(
                                                            clientUserPhoneNo
                                                        )}
                                                    />
                                                ) : (
                                                    <EmptyValue />
                                                )}
                                            </TableCell>
                                        )}
                                        {columnsToShow.includes(
                                            CLIENT_USER_KEYS.CREATED_AT
                                        ) && (
                                            <TableCell>
                                                {
                                                    clientUser[
                                                        CLIENT_USER_KEYS
                                                            .CREATED_AT
                                                    ]
                                                }
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {isActiveClientUser &&
                                                    canAddEditClient && (
                                                        <TableActionIcon
                                                            iconComponent="edit"
                                                            label="edit client user"
                                                            tooltip="Edit Client User"
                                                            onClick={() =>
                                                                onEdit(
                                                                    clientUser
                                                                )
                                                            }
                                                        />
                                                    )}
                                                {/* (isActiveClientUser ||
                                                    isInactiveClientUser) &&
                                                    canUpdateClient && (
                                                        <TableActionIcon
                                                            iconComponent={
                                                                isActiveClientUser
                                                                    ? "visibility"
                                                                    : "visibilityOff"
                                                            }
                                                            label={
                                                                isActiveClientUser
                                                                    ? "disable client user"
                                                                    : "enable client user"
                                                            }
                                                            tooltip={
                                                                isActiveClientUser
                                                                    ? "Disable Client User"
                                                                    : "Enable Client User"
                                                            }
                                                            onClick={() =>
                                                                onUpdateStatus(
                                                                    clientUser
                                                                )
                                                            }
                                                        />
                                                    ) */}
                                                {isActiveClientUser &&
                                                    canArchiveClient && (
                                                        <TableActionIcon
                                                            iconComponent="archive"
                                                            label="archive client user"
                                                            tooltip="Archive Client User"
                                                            onClick={() =>
                                                                onArchive(
                                                                    clientUser
                                                                )
                                                            }
                                                        />
                                                    )}
                                                {isArchivedClientUser &&
                                                    canRestoreClient && (
                                                        <TableActionIcon
                                                            iconComponent="unarchive"
                                                            label="restore client user"
                                                            tooltip="Restore Client User"
                                                            onClick={() =>
                                                                onRestore(
                                                                    clientUser
                                                                )
                                                            }
                                                        />
                                                    )}
                                                {isArchivedClientUser &&
                                                    canDeleteClient && (
                                                        <TableActionIcon
                                                            iconComponent="delete"
                                                            label="delete client user"
                                                            tooltip="Delete Client User"
                                                            onClick={() =>
                                                                onDelete(
                                                                    clientUser
                                                                )
                                                            }
                                                        />
                                                    )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            clientUsersFetched
                                                ? "error"
                                                : "info"
                                        }
                                    >
                                        {clientUsersFetched
                                            ? "There are no records found."
                                            : "Loading clients..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    clientUserCountNonZero
                        ? parseInt(clientUserCounts[CLIENT_USER_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={clientUserCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

CompanyClientUsersTable.propTypes = {
    canAddEditClient: PropTypes.bool,
    canArchiveClient: PropTypes.bool,
    canCreateTicket: PropTypes.bool,
    canDeleteClient: PropTypes.bool,
    canRestoreClient: PropTypes.bool,
    /* canUpdateClient: PropTypes.bool, */
    canViewOverview: PropTypes.bool,
    clientUsers: PropTypes.array,
    clientUserCounts: PropTypes.object,
    clientUsersFetched: PropTypes.bool,
    columnsToShow: PropTypes.array,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onArchive: PropTypes.func,
    onCreateTicket: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onRestore: PropTypes.func,
    onSort: PropTypes.func,
    /* onUpdateStatus: PropTypes.func, */
};

CompanyClientUsersTable.defaultProps = {
    canAddEditClient: false,
    canArchiveClient: false,
    canCreateTicket: false,
    canDeleteClient: false,
    canRestoreClient: false,
    /* canUpdateClient: false, */
    canViewOverview: false,
    clientUsers: [],
    clientUserCounts: null,
    clientUsersFetched: false,
    columnsToShow: [],
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onArchive: () => {},
    onCreateTicket: () => {},
    // onEdit: () => {},
    // onDelete: () => {},
    // onRestore: () => {},
    onSort: () => {},
    // onUpdateStatus: () => {},
};

export default CompanyClientUsersTable;
