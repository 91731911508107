import { USE_IMAGE_PLACEHOLDERS } from "./constants/paths";
import {
    /* ACCOUNT_ASSET_TYPE_CASE_STUDY, */
    ACCOUNT_ASSET_TYPE_DOCUMENT,
    ACCOUNT_ASSET_TYPE_LOGO,
    ASSIGNED_SERVICE_APPROVED,
    ASSIGNED_SERVICE_APPROVED_TXT,
    ASSIGNED_SERVICE_CANCELLED,
    ASSIGNED_SERVICE_CANCELLED_TXT,
    ASSIGNED_SERVICE_COMPLETED,
    ASSIGNED_SERVICE_COMPLETED_TXT,
    ASSIGNED_SERVICE_DELETED,
    ASSIGNED_SERVICE_DELETED_TXT,
    ASSIGNED_SERVICE_REJECTED,
    ASSIGNED_SERVICE_REJECTED_TXT,
    CLIENT_USER_ACTIVE_STATUS,
    CLIENT_USER_ACTIVE_STATUS_TXT,
    CLIENT_USER_ARCHIVE_STATUS,
    CLIENT_USER_ARCHIVE_STATUS_TXT,
    COMPANY_ACTIVE_STATUS,
    COMPANY_ACTIVE_STATUS_TXT,
    COMPANY_ARCHIVE_STATUS,
    COMPANY_ARCHIVE_STATUS_TXT,
    MEETING_ALL_STATUS,
    MEETING_ALL_STATUS_TXT,
    MEETING_APPROVED_STATUS,
    MEETING_APPROVED_STATUS_TXT,
    MEETING_CANCELLED_STATUS,
    MEETING_CANCELLED_STATUS_TXT,
    MEETING_PENDING_STATUS,
    MEETING_PENDING_STATUS_TXT,
    MEETING_REJECTED_STATUS,
    MEETING_REJECTED_STATUS_TXT,
    NEW_MEETING_TIME_APPROVE_STATUS,
    NEW_MEETING_TIME_APPROVE_STATUS_TXT,
    NEW_MEETING_TIME_PENDING_STATUS,
    NEW_MEETING_TIME_PENDING_STATUS_TXT,
    NEW_MEETING_TIME_REJECT_STATUS,
    NEW_MEETING_TIME_REJECT_STATUS_TXT,
    SERVICE_PRICE_INTERVAL_MONTH,
    SERVICE_PRICE_INTERVAL_YEAR,
    SURVEY_QUESTION_TYPE_MULTI_SELECT,
    SURVEY_QUESTION_TYPE_SINGLE_SELECT,
    SURVEY_QUESTION_TYPE_TEXT,
    SURVEY_STATUS_ACTIVE,
    SURVEY_STATUS_ARCHIVED,
    TICKET_LOW_PRIORITY,
    TICKET_NORMAL_PRIORITY,
    TICKET_URGENT_PRIORITY,
    TICKET_CLOSE_STATUS,
    TICKET_OPEN_STATUS,
    /* TICKET_PENDING_STATUS, */
    TICKET_CLOSE_STATUS_TXT,
    TICKET_OPEN_STATUS_TXT,
    /* TICKET_PENDING_STATUS_TXT, */
    TIME_REQUEST_RECEIVER_CLIENT,
    TIME_REQUEST_RECEIVER_FM,
    USER_TYPE_ADMIN,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT,
    USER_TYPE_CLIENT_ID,
} from "./constants/appData";
import { amber, blue, green, grey, orange, red } from "@mui/material/colors";

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getAccountAssetTypeList = () => {
    return [
        /* ACCOUNT_ASSET_TYPE_CASE_STUDY, */
        ACCOUNT_ASSET_TYPE_DOCUMENT,
        ACCOUNT_ASSET_TYPE_LOGO,
    ];
};

export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const getAppUserTypeName = (typeId = "") => {
    let userTypeName = "";
    switch (typeId) {
        case "1":
            userTypeName = USER_TYPE_ADMIN;
            break;

        case "2":
            userTypeName = USER_TYPE_AE;
            break;

        case "3":
            userTypeName = USER_TYPE_CLIENT;
            break;

        default:
            break;
    }
    return userTypeName;
};

export const getAssignedServiceStatusList = () => {
    return [
        {
            value: ASSIGNED_SERVICE_APPROVED,
            label: ASSIGNED_SERVICE_APPROVED_TXT,
            color: "error",
        },
        {
            value: ASSIGNED_SERVICE_CANCELLED,
            label: ASSIGNED_SERVICE_CANCELLED_TXT,
            color: "primary",
        },
        {
            value: ASSIGNED_SERVICE_COMPLETED,
            label: ASSIGNED_SERVICE_COMPLETED_TXT,
            color: "success",
        },
        {
            value: ASSIGNED_SERVICE_DELETED,
            label: ASSIGNED_SERVICE_DELETED_TXT,
            color: "info",
        },
        {
            value: ASSIGNED_SERVICE_REJECTED,
            label: ASSIGNED_SERVICE_REJECTED_TXT,
            color: "info",
        },
    ];
};

export const getClientUserStatusList = () => {
    return [
        {
            label: CLIENT_USER_ACTIVE_STATUS_TXT,
            value: CLIENT_USER_ACTIVE_STATUS,
        },
        {
            label: CLIENT_USER_ARCHIVE_STATUS_TXT,
            value: CLIENT_USER_ARCHIVE_STATUS,
        },
    ];
};

export const getCompanyStatusList = () => {
    return [
        { label: COMPANY_ACTIVE_STATUS_TXT, value: COMPANY_ACTIVE_STATUS },
        { label: COMPANY_ARCHIVE_STATUS_TXT, value: COMPANY_ARCHIVE_STATUS },
    ];
};

const getFirstAlphaNumChar = (str = "") => {
    if (str) {
        return (str.match(/[a-zA-Z0-9]/) || []).pop();
    } else {
        return "";
    }
};

export const getInitialsFromName = (name = "") => {
    let initials = "";
    if (name) {
        const parts = name.trim().split(" ");
        if (parts.length > 1) {
            const part1 = parts[0];
            /* const part2 = parts[parts.length - 1]; */
            const part2 = parts[1];
            if (part1 && part2) {
                initials = `${getFirstAlphaNumChar(
                    part1
                ).toUpperCase()}${getFirstAlphaNumChar(part2).toUpperCase()}`;
            } else if (part1) {
                initials = getFirstAlphaNumChar(part1).toUpperCase();
            } else if (part2) {
                initials = getFirstAlphaNumChar(part2).toUpperCase();
            }
        } else {
            const part1 = parts[0];
            if (part1) {
                initials = getFirstAlphaNumChar(part1).toUpperCase();
            }
        }
    }
    return initials;
};

export const getMeetingStatusColor = (meetingStatus = "") => {
    let bgColor = "",
        textColor = "";

    switch (meetingStatus) {
        case MEETING_ALL_STATUS:
            bgColor = blue[500];
            textColor = "#fff";
            break;
        case MEETING_APPROVED_STATUS:
            bgColor = green[500];
            textColor = "#fff";
            break;
        case MEETING_CANCELLED_STATUS:
            bgColor = grey[500];
            textColor = "#fff";
            break;
        case MEETING_PENDING_STATUS:
            bgColor = amber[500];
            textColor = "#fff";
            break;
        case MEETING_REJECTED_STATUS:
            bgColor = red[500];
            textColor = "#fff";
            break;
        default:
            break;
    }
    return { bgColor, textColor };
};

export const getMeetingStatusList = () => {
    return [
        {
            value: MEETING_ALL_STATUS,
            label: MEETING_ALL_STATUS_TXT,
        },
        {
            value: MEETING_PENDING_STATUS,
            label: MEETING_PENDING_STATUS_TXT,
        },
        {
            value: MEETING_APPROVED_STATUS,
            label: MEETING_APPROVED_STATUS_TXT,
        },
        {
            value: MEETING_CANCELLED_STATUS,
            label: MEETING_CANCELLED_STATUS_TXT,
        },
        {
            value: MEETING_REJECTED_STATUS,
            label: MEETING_REJECTED_STATUS_TXT,
        },
    ];
};

export const getMeetingTimeRequestReceiverTypes = () => {
    return [TIME_REQUEST_RECEIVER_CLIENT, TIME_REQUEST_RECEIVER_FM];
};

export const getMeetingTimeRequestStatusList = () => {
    return [
        {
            value: NEW_MEETING_TIME_APPROVE_STATUS,
            label: NEW_MEETING_TIME_APPROVE_STATUS_TXT,
        },
        {
            value: NEW_MEETING_TIME_PENDING_STATUS,
            label: NEW_MEETING_TIME_PENDING_STATUS_TXT,
        },
        {
            value: NEW_MEETING_TIME_REJECT_STATUS,
            label: NEW_MEETING_TIME_REJECT_STATUS_TXT,
        },
    ];
};

export const getMimeTypeLabels = (mimeType, extension) => {
    let label = "";
    switch (mimeType) {
        case "audio/mpeg":
            label = "MPEG audio";
            break;

        case "audio/wav":
            label = "WAV audio";
            break;

        case "audio/webm":
            label = "WEBM audio";
            break;

        case "application/msword":
            label = "DOC document";
            break;

        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            label = "DOCX document";
            break;

        case "application/pdf":
            label = "PDF document";
            break;

        case "application/postscript":
            label = "AI file";
            break;

        case "image/vnd.adobe.photoshop":
            label = "PSD file";
            break;

        case "application/x-sketch":
            label = "Sketch file";
            break;

        case "application/vnd.adobe.xd":
            label = "XD file";
            break;

        case "image/png":
            label = "PNG image";
            break;

        case "image/jpeg":
            label = `${extension.toUpperCase()} image`;
            break;

        case "image/svg+xml":
            label = "SVG image";
            break;

        case "text/csv":
            label = "CSV document";
            break;

        case "application/vnd.ms-excel":
            label = "XLS document";
            break;

        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            label = "XLSX document";
            break;

        case "video/quicktime":
            label = "MOV video";
            break;

        case "video/mp4":
            label = "MP4 video";
            break;

        case "video/mpeg":
            label = "MPEG video";
            break;

        case "video/webm":
            label = "WEBM video";
            break;

        default:
            label = extension ? extension.toUpperCase() : "";
            break;
    }

    return label;
};

export const getServicePriceIntervals = () => {
    return [SERVICE_PRICE_INTERVAL_MONTH, SERVICE_PRICE_INTERVAL_YEAR];
};

export const getSurveyTypes = () => {
    return [SURVEY_STATUS_ACTIVE, SURVEY_STATUS_ARCHIVED];
};

export const getSurveyAllQuestionTypes = () => {
    return [
        {
            label: "Checkbox",
            value: SURVEY_QUESTION_TYPE_MULTI_SELECT,
        },
        {
            label: "Radio",
            value: SURVEY_QUESTION_TYPE_SINGLE_SELECT,
        },
        {
            label: "Text",
            value: SURVEY_QUESTION_TYPE_TEXT,
        },
    ];
};

export const getSurveyOptionsQuestionTypes = () => {
    return [
        SURVEY_QUESTION_TYPE_MULTI_SELECT,
        SURVEY_QUESTION_TYPE_SINGLE_SELECT,
    ];
};

export const getTicketPriorityList = () => {
    return [
        TICKET_LOW_PRIORITY,
        TICKET_NORMAL_PRIORITY,
        TICKET_URGENT_PRIORITY,
    ];
};

export const getTicketPriorityColor = (ticketPriority = "") => {
    let bgColor = "",
        textColor = "";

    switch (ticketPriority) {
        case TICKET_LOW_PRIORITY:
            bgColor = amber[500];
            textColor = "#fff";
            break;
        case TICKET_NORMAL_PRIORITY:
            bgColor = orange[500];
            textColor = "#fff";
            break;
        case TICKET_URGENT_PRIORITY:
            bgColor = red[500];
            textColor = "#fff";
            break;
        default:
            break;
    }
    return { bgColor, textColor };
};

export const getTicketStatusColor = (ticketStatus = "") => {
    let bgColor = "",
        textColor = "";

    switch (ticketStatus) {
        case TICKET_CLOSE_STATUS:
            bgColor = green[500];
            textColor = "#fff";
            break;
        case TICKET_OPEN_STATUS:
            bgColor = blue[500];
            textColor = "#fff";
            break;
        /* case TICKET_PENDING_STATUS:
            bgColor = "#FFA81E";
            textColor = "#fff";
            break; */
        default:
            break;
    }

    return { bgColor, textColor };
};

export const getTicketStatusList = () => {
    return [
        {
            value: TICKET_OPEN_STATUS,
            label: TICKET_OPEN_STATUS_TXT,
        },
        /* {
            value: TICKET_PENDING_STATUS,
            label: TICKET_PENDING_STATUS_TXT,
        }, */
        {
            value: TICKET_CLOSE_STATUS,
            label: TICKET_CLOSE_STATUS_TXT,
        },
    ];
};

export const getUserTypesList = () => {
    return [
        { label: USER_TYPE_ADMIN, value: USER_TYPE_ADMIN_ID },
        { label: USER_TYPE_AE, value: USER_TYPE_AE_ID },
        { label: USER_TYPE_CLIENT, value: USER_TYPE_CLIENT_ID },
    ];
};
