import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    archiveClientUser,
    deleteClientUser,
    getFilteredClientUsers,
    restoreClientUser,
    setCurrentClientUser,
    /* updateClientUserStatus, */
} from "../../redux/actions/clientUsers";
import { getCompany } from "../../redux/actions/companies";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    AUTH_USER_KEYS,
    APP_NAME,
    CLIENT_USER_ACTIVE_STATUS,
    /* CLIENT_USER_INACTIVE_STATUS, */
    CLIENT_USER_KEYS,
    COMPANY_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { getClientUserStatusList } from "../../utils/appHelpers";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import { checkCompanyClientUserValid } from "../../utils/validationHelpers";
import AddEditCompanyClientDialog from "./AddEditCompanyClientDialog";
import CompanyClientUsersTable from "./CompanyClientUsersTable";
import CompanyCUTabPanel, { a11yProps } from "./CompanyCUTabPanel";
const AddTicketDialog = lazy(() => import("../tickets/AddTicketDialog"));

const allHeadCells = [
    {
        id: CLIENT_USER_KEYS.NAME,
        label: "Client",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: CLIENT_USER_KEYS.EMAIL,
        label: "Email",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: CLIENT_USER_KEYS.PHONE_NO,
        label: "Phone No.",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: CLIENT_USER_KEYS.CREATED_AT,
        label: "Created",
        show: false,
        sortColumn: false,
        useColumn: true,
    },
];

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "ARCHIVE") {
            return {
                archived: true,
                deleted: false,
                inserted: false,
                restored: false,
            };
        } else if (action.type === "DELETE") {
            return {
                archived: false,
                deleted: true,
                inserted: false,
                restored: false,
            };
        } else if (action.type === "INSERT") {
            return {
                archived: false,
                deleted: false,
                inserted: true,
                restored: false,
            };
        } else if (action.type === "RESTORE") {
            return {
                archived: false,
                deleted: false,
                inserted: false,
                restored: true,
            };
        }
    }

    return {
        archived: false,
        deleted: false,
        inserted: false,
        restored: false,
    };
};

const filterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "SET_COMPANY_AND_FETCH") {
            return { ...state, companyId: action.companyId, fetchPage: true };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            return {
                ...state,
                sort: action.sort,
                order: action.order,
                fetchPage: true,
            };
        } else if (action.type === "SET_STATUS") {
            return { ...state, status: action.status };
        } else if (action.type === "SET_STATUS_AND_FETCH") {
            return {
                ...state,
                status: action.status,
                page: 0,
                fetchPage: true,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                page: 0,
                rowsPerPage: 10,
                sort: CLIENT_USER_KEYS.NAME,
                order: SORT_ORDER_ASC,
                fetchPage: false,
                status: CLIENT_USER_ACTIVE_STATUS,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchPage: false };
        }
    }

    return {
        companyId: "",
        page: 0,
        rowsPerPage: 10,
        sort: CLIENT_USER_KEYS.NAME,
        order: SORT_ORDER_ASC,
        fetchPage: false,
        status: CLIENT_USER_ACTIVE_STATUS,
    };
};

const statusList = getClientUserStatusList();

const genericPageName = "Company Client Users";

const CompanyClientUsers = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isMountedCompanyClientUsers = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const companyId = params?.companyId || "";

    const { clientUsers, clientUserCounts, currentClientUser } = useSelector(
        ({ clientUsersReducer }) => clientUsersReducer
    );

    const [isLoading, setIsLoading] = useState(false);
    const [pageName, setPageName] = useState(genericPageName);
    const [headCells, setHeadCells] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [companyIsValid, setCompanyIsValid] = useState(false);
    const [companyFetched, setCompanyFetched] = useState(false);
    const [company, setCompany] = useState(null);
    const [clientUsersFetched, setClientUsersFetched] = useState(true);
    const [openAddEditClientDialog, setOpenAddEditClientDialog] =
        useState(false);
    const [openAddTicketDialog, setOpenAddTicketDialog] = useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        archived: false,
        deleted: false,
        inserted: false,
        restored: false,
    });

    const [filterState, dispatchFilterAction] = useReducer(filterReducer, {
        companyId: "",
        page: 0,
        rowsPerPage: 10,
        sort: CLIENT_USER_KEYS.NAME,
        order: SORT_ORDER_ASC,
        fetchPage: false,
        status: CLIENT_USER_ACTIVE_STATUS,
    });

    const { fetchPage, status } = filterState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    const canAddEditClient = isAdminUser || isFmUser;
    const canArchiveClient = isAdminUser || isFmUser;
    const canCreateTicket = isAdminUser || isFmUser;
    const canDeleteClient = isAdminUser || isFmUser;
    const canRestoreClient = isAdminUser || isFmUser;
    const canUpdateClient = isAdminUser || isFmUser;
    const canViewOverview = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedCompanyClientUsers.current = false;
            dispatch(setCurrentClientUser(null));
        };
    }, [dispatch]);

    /* Set table columns */
    useEffect(() => {
        if (isAdminUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    if (
                        [
                            CLIENT_USER_KEYS.NAME,
                            CLIENT_USER_KEYS.EMAIL,
                            CLIENT_USER_KEYS.PHONE_NO,
                        ].includes(headCell.id)
                    ) {
                        return { ...headCell, show: true, sortColumn: true };
                    } else {
                        return { ...headCell, show: false };
                    }
                })
            );
        } else if (isFmUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    if (
                        [
                            CLIENT_USER_KEYS.NAME,
                            CLIENT_USER_KEYS.EMAIL,
                            CLIENT_USER_KEYS.PHONE_NO,
                        ].includes(headCell.id)
                    ) {
                        return { ...headCell, show: true, sortColumn: true };
                    } else {
                        return { ...headCell, show: false };
                    }
                })
            );
        }
    }, [isAdminUser, isFmUser]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (isLoading) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompanyFetched(true);

                                const fetchedCompanyValid =
                                    fetchedCompany &&
                                    fetchedCompany?.[COMPANY_KEYS.ID] &&
                                    fetchedCompany[COMPANY_KEYS.ID];
                                if (fetchedCompanyValid) {
                                    setCompany(fetchedCompany);
                                    setCompanyIsValid(true);
                                    setPageName(
                                        fetchedCompany?.[COMPANY_KEYS.NAME]
                                            ? `${
                                                  fetchedCompany[
                                                      COMPANY_KEYS.NAME
                                                  ]
                                              } - Client Users`
                                            : ""
                                    );
                                    dispatchFilterAction({
                                        type: "SET_COMPANY_AND_FETCH",
                                        companyId:
                                            fetchedCompany[COMPANY_KEYS.ID],
                                    });
                                } else {
                                    setCompany(null);
                                    setCompanyIsValid(false);
                                    setPageName("");
                                    setIsLoading(false);
                                }
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: companyId,
            };

            const promise = dispatch(fetchData({ companyData: companyData }));
            promise.catch((error) => {
                if (isActive) {
                    setCompanyFetched(true);
                    setCompany(null);
                    setCompanyIsValid(false);
                    setPageName("");
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, companyId, isLoading]);

    /* Fetch company's client users */
    useEffect(() => {
        if (fetchPage) {
            setClientUsersFetched(false);
        }
    }, [fetchPage]);

    useEffect(() => {
        let isActive = true;

        if (!clientUsersFetched && filterState) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredClientUsers(payload, () => {
                            if (isActive) {
                                setClientUsersFetched(true);
                                if (isLoading) setIsLoading(false);
                                dispatchFilterAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchAction();
                            }
                        })
                    );
                };
            };

            const clientUserData = {
                authcode: authToken,
                [CLIENT_USER_KEYS.COMPANY_ID]: filterState.companyId,
                page: filterState.page + 1,
                rows_per_page: filterState.rowsPerPage,
                [CLIENT_USER_KEYS.STATUS]: filterState.status,
                sort: filterState.sort,
                order: filterState.order,
            };

            const promise = dispatch(
                fetchData({
                    clientUserData: clientUserData,
                    authUserType: authUserType,
                    fromAction: actionState,
                    fetchStart: !isLoading,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setClientUsersFetched(true);
                    if (isLoading) setIsLoading(false);
                    dispatchFilterAction({ type: "RESET" });
                    dispatchAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        authUserType,
        actionState,
        clientUsersFetched,
        filterState,
        isLoading,
    ]);

    useEffect(() => {
        if (
            actionState.archived ||
            actionState.deleted ||
            actionState.inserted ||
            actionState.restored
        ) {
            /**
             * Fetch the list again if a client is
             * added, archived, deleted, or restored
             */
            dispatchFilterAction({ type: "FETCH_PAGE" });
        }
    }, [actionState]);

    const handleOpenAddClientDialog = () => {
        setOpenAddEditClientDialog(true);
    };

    const handleCloseAddEditClientDialog = () => {
        setOpenAddEditClientDialog(false);
        dispatch(setCurrentClientUser(null));
    };

    const handleCloseAddTicketDialog = () => {
        setOpenAddTicketDialog(false);
        dispatch(setCurrentClientUser(null));
    };

    const handleStatusChange = (event, newValue) =>
        dispatchFilterAction({
            type: "SET_STATUS_AND_FETCH",
            status: newValue,
        });

    const handlePageChange = (event, newPage) => {
        dispatchFilterAction({ type: "SET_PAGE", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchFilterAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        const isAsc =
            filterState.sort === property &&
            filterState.order === SORT_ORDER_ASC;

        dispatchFilterAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
            order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
        });
    };

    const handleClientUserEdit = (clientUser) => {
        dispatch(setCurrentClientUser(clientUser));
        setOpenAddEditClientDialog(true);
    };

    const handleClientUserArchive = React.useCallback(
        (clientUser) => {
            if (companyIsValid) {
                if (canArchiveClient) {
                    if (checkCompanyClientUserValid(clientUser, companyId)) {
                        showDialog({
                            variant: "confirm",
                            title: `Archive client: ${
                                clientUser[CLIENT_USER_KEYS.NAME]
                            }?`,
                            onYes: () => {
                                hideDialog();
                                const clientUserData = {
                                    authcode: authToken,
                                    [CLIENT_USER_KEYS.USER_ID]:
                                        clientUser[CLIENT_USER_KEYS.ID],
                                    [CLIENT_USER_KEYS.COMPANY_ID]: companyId,
                                };
                                dispatch(
                                    archiveClientUser(
                                        {
                                            clientUserData: clientUserData,
                                            authUserType: authUserType,
                                        },
                                        () => {
                                            if (
                                                _isMountedCompanyClientUsers.current
                                            )
                                                dispatchAction({
                                                    type: "ARCHIVE",
                                                });
                                        },
                                        () => {}
                                    )
                                );
                            },
                            onNo: hideDialog,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Invalid client",
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ACTION_AUTHORIZATION_ERROR,
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid company",
                });
            }
        },
        [
            dispatch,
            Swal,
            hideDialog,
            showDialog,
            authToken,
            authUserType,
            canArchiveClient,
            companyId,
            companyIsValid,
        ]
    );

    const handleClientUserRestore = React.useCallback(
        (clientUser) => {
            if (companyIsValid) {
                if (canRestoreClient) {
                    if (checkCompanyClientUserValid(clientUser, companyId)) {
                        showDialog({
                            variant: "confirm",
                            title: `Restore client: ${
                                clientUser[CLIENT_USER_KEYS.NAME]
                            }?`,
                            onYes: () => {
                                hideDialog();
                                const clientUserData = {
                                    authcode: authToken,
                                    [CLIENT_USER_KEYS.USER_ID]:
                                        clientUser[CLIENT_USER_KEYS.ID],
                                    [CLIENT_USER_KEYS.COMPANY_ID]: companyId,
                                };
                                dispatch(
                                    restoreClientUser(
                                        {
                                            clientUserData: clientUserData,
                                            authUserType: authUserType,
                                        },
                                        () => {
                                            if (
                                                _isMountedCompanyClientUsers.current
                                            )
                                                dispatchAction({
                                                    type: "RESTORE",
                                                });
                                        },
                                        () => {}
                                    )
                                );
                            },
                            onNo: hideDialog,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Invalid client",
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ACTION_AUTHORIZATION_ERROR,
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid company",
                });
            }
        },
        [
            dispatch,
            Swal,
            hideDialog,
            showDialog,
            authToken,
            authUserType,
            canRestoreClient,
            companyId,
            companyIsValid,
        ]
    );

    const handleClientUserDelete = React.useCallback(
        (clientUser) => {
            if (companyIsValid) {
                if (canDeleteClient) {
                    if (checkCompanyClientUserValid(clientUser, companyId)) {
                        showDialog({
                            variant: "confirm",
                            title: `Delete client: ${
                                clientUser[CLIENT_USER_KEYS.NAME]
                            }?`,
                            onYes: () => {
                                hideDialog();
                                const clientUserData = {
                                    authcode: authToken,
                                    [CLIENT_USER_KEYS.USER_ID]:
                                        clientUser[CLIENT_USER_KEYS.ID],
                                    [CLIENT_USER_KEYS.COMPANY_ID]: companyId,
                                };
                                dispatch(
                                    deleteClientUser(
                                        {
                                            clientUserData: clientUserData,
                                            authUserType: authUserType,
                                        },
                                        () => {
                                            if (
                                                _isMountedCompanyClientUsers.current
                                            )
                                                dispatchAction({
                                                    type: "DELETE",
                                                });
                                        },
                                        () => {}
                                    )
                                );
                            },
                            onNo: hideDialog,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Invalid client",
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ACTION_AUTHORIZATION_ERROR,
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid company",
                });
            }
        },
        [
            dispatch,
            Swal,
            hideDialog,
            showDialog,
            authToken,
            authUserType,
            canDeleteClient,
            companyId,
            companyIsValid,
        ]
    );

    /* const handleClientUserStatusUpdate = (clientUser) => {
        if (companyIsValid) {
            if (canUpdateClient) {
                if (checkCompanyClientUserValid(clientUser, companyId)) {
                    const clientUserStatus =
                        clientUser?.[CLIENT_USER_KEYS.STATUS] || "";
                    if (
                        [
                            CLIENT_USER_ACTIVE_STATUS,
                            CLIENT_USER_INACTIVE_STATUS,
                        ].includes(clientUserStatus)
                    ) {
                        const updatedStatus =
                            clientUserStatus === CLIENT_USER_INACTIVE_STATUS
                                ? CLIENT_USER_ACTIVE_STATUS
                                : clientUserStatus === CLIENT_USER_ACTIVE_STATUS
                                ? CLIENT_USER_INACTIVE_STATUS
                                : "";

                        const clientUserData = {
                            authcode: authToken,
                            [CLIENT_USER_KEYS.COMPANY_ID]: companyId,
                            [CLIENT_USER_KEYS.USER_ID]:
                                clientUser[CLIENT_USER_KEYS.ID],
                            [CLIENT_USER_KEYS.STATUS]: updatedStatus,
                        };

                        dispatch(
                            updateClientUserStatus(
                                {
                                    clientUserData: clientUserData,
                                    authUserType: authUserType,
                                },
                                null,
                                (messages) => {
                                    const msgArray = messages
                                        ? Object.values(messages)
                                        : [];
                                    if (
                                        _isMountedCompanyClientUsers.current &&
                                        msgArray.length > 0
                                    ) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Oops...",
                                            html: msgArray.join("<br>"),
                                        });
                                    }
                                }
                            )
                        );
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Invalid user",
                        });
                        return false;
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid client",
                    });
                    return false;
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
                return false;
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid company",
            });
            return false;
        }
    }; */

    const handleClientUserCreateTicket = (clientUser) => {
        dispatch(setCurrentClientUser(clientUser));
        setOpenAddTicketDialog(true);
    };

    const handleInsertClientUser = () => dispatchAction({ type: "INSERT" });

    const fetchedCompanyInvalid = companyFetched && !companyIsValid;
    const loadError = !isValidUserType || fetchedCompanyInvalid;

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    return (
        <Grid container spacing={3.5}>
            {pageName && (
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle type="v2" component="h1" title={pageName} />
                        <Breadcrumb
                            pageNames={{
                                genericName: genericPageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
            )}
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {fetchedCompanyInvalid && (
                        <Grid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                Invalid company!
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    {canAddEditClient && (
                        <Grid item xs={12}>
                            <HeadingStack sx={{ justifyContent: "end" }}>
                                <FmButton
                                    startIcon="add"
                                    type="button"
                                    label="Add Client"
                                    onClick={handleOpenAddClientDialog}
                                />
                            </HeadingStack>
                        </Grid>
                    )}
                    {status && (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                }}
                            >
                                <AppBar position="static" color="inherit">
                                    <Tabs
                                        variant="fullWidth"
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                        value={status}
                                        aria-label="company client users tabs"
                                        onChange={handleStatusChange}
                                    >
                                        {statusList.map((statusObj) => {
                                            const statusValue = statusObj.value;
                                            return (
                                                <Tab
                                                    key={`company-client-user-tab-${statusValue}`}
                                                    label={statusObj.label}
                                                    value={statusValue}
                                                    sx={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                    {...a11yProps(statusValue)}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                </AppBar>
                                {statusList.map((statusObj, statusIdx) => {
                                    const statusValue = statusObj.value;
                                    return (
                                        <CompanyCUTabPanel
                                            key={`company-client-user-tabpanel-${statusValue}`}
                                            value={status}
                                            index={statusIdx}
                                            status={statusValue}
                                        >
                                            {headCells.length > 0 ? (
                                                <CompanyClientUsersTable
                                                    canArchiveClient={
                                                        canArchiveClient
                                                    }
                                                    canAddEditClient={
                                                        canAddEditClient
                                                    }
                                                    canCreateTicket={
                                                        canCreateTicket
                                                    }
                                                    canDeleteClient={
                                                        canDeleteClient
                                                    }
                                                    canRestoreClient={
                                                        canRestoreClient
                                                    }
                                                    canUpdateClient={
                                                        canUpdateClient
                                                    }
                                                    canViewOverview={
                                                        canViewOverview
                                                    }
                                                    clientUsers={clientUsers}
                                                    clientUserCounts={
                                                        clientUserCounts
                                                    }
                                                    clientUsersFetched={
                                                        clientUsersFetched
                                                    }
                                                    columnsToShow={
                                                        columnsToShow
                                                    }
                                                    headCells={headCells}
                                                    order={filterState.order}
                                                    page={filterState.page}
                                                    rowsPerPage={
                                                        filterState.rowsPerPage
                                                    }
                                                    sort={filterState.sort}
                                                    handlePageChange={
                                                        handlePageChange
                                                    }
                                                    handleRowsPerPageChange={
                                                        handleRowsPerPageChange
                                                    }
                                                    onArchive={
                                                        handleClientUserArchive
                                                    }
                                                    onCreateTicket={
                                                        handleClientUserCreateTicket
                                                    }
                                                    onEdit={
                                                        handleClientUserEdit
                                                    }
                                                    onDelete={
                                                        handleClientUserDelete
                                                    }
                                                    onRestore={
                                                        handleClientUserRestore
                                                    }
                                                    onSort={handleSort}
                                                    /* onUpdateStatus={
                                                        handleClientUserStatusUpdate
                                                    } */
                                                />
                                            ) : (
                                                <Alert
                                                    variant="outlined"
                                                    severity="error"
                                                >
                                                    There was a problem in
                                                    rendering the table!
                                                </Alert>
                                            )}
                                        </CompanyCUTabPanel>
                                    );
                                })}
                            </Box>
                        </Grid>
                    )}
                    {canAddEditClient && openAddEditClientDialog && (
                        <AddEditCompanyClientDialog
                            currentClientUser={currentClientUser}
                            currentCompany={company}
                            open={openAddEditClientDialog}
                            setGlobalState={true}
                            onClose={handleCloseAddEditClientDialog}
                            onInsert={handleInsertClientUser}
                        />
                    )}
                    {canCreateTicket && openAddTicketDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddTicketDialog
                                currentClientUser={currentClientUser}
                                currentCompany={company}
                                open={openAddTicketDialog}
                                onClose={handleCloseAddTicketDialog}
                            />
                        </Suspense>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default CompanyClientUsers;
