import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import NoMessagesAlert from "../../shared/widgets/NoMessagesAlert";
import ConversationMessage from "./ConversationMessage";

const chatGroupedByDate = (messageKeys, array) =>
    Object.entries(
        array.reduce((result, { [messageKeys.SENT_DATE]: k, ...rest }) => {
            (result[k] = result[k] || []).push(rest);
            return result;
        }, {})
    ).map(([sent_date, messages]) => ({
        [messageKeys.SENT_DATE]: sent_date,
        [messageKeys.MESSAGES]: messages,
    }));

const ChatConversation = ({ chatBox, messages, messageKeys, onDownload }) => {
    const scrollbarRef = React.useRef();

    useEffect(() => {
        if (scrollbarRef) scrollbarRef.current?.scrollToBottom();
    }, [scrollbarRef]);

    const groupedMessages = useMemo(() => {
        if (messages && messages.length > 0) {
            return chatGroupedByDate(messageKeys, messages);
        } else {
            return [];
        }
    }, [messages, messageKeys]);

    if (!messageKeys) {
        return null;
    }

    let chatBoxSx = chatBox?.sx || {};

    return (
        <Box
            sx={{
                height: "64vh",
                p: (theme) => theme.spacing(2),
                overflow: "hidden",
                ...chatBoxSx,
            }}
        >
            <JumboScrollbar
                ref={scrollbarRef}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
                autoHeightMin={30}
                style={{ height: "100%" }}
            >
                {groupedMessages && groupedMessages.length > 0 ? (
                    groupedMessages?.map((message, index) => (
                        <ConversationMessage
                            key={`${message[messageKeys.ID]}-${index}`}
                            conversation={message}
                            messageKeys={messageKeys}
                            onDownload={onDownload}
                        />
                    ))
                ) : (
                    <NoMessagesAlert />
                )}
            </JumboScrollbar>
        </Box>
    );
};

ChatConversation.propTypes = {
    chatBox: PropTypes.object,
    messages: PropTypes.array,
    messageKeys: PropTypes.object,
    onDownload: PropTypes.func,
};

ChatConversation.defaultProps = {
    chatBox: null,
    messages: [],
    messageKeys: null,
    onDownload: () => {},
};

export default ChatConversation;
