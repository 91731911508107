import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    deleteService,
    getFilteredServices,
    setCurrentService,
} from "../../redux/actions/services";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SERVICE_KEYS,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import AddEditServiceDialog from "./AddEditServiceDialog";
import ServicesTable from "./ServicesTable";

const pageName = "Services";

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, deleted: true };
        }
    }

    return { inserted: false, deleted: false };
};

const SERVICES_LIMIT = 10;

const filterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "RESET_FILTER") {
            return { ...state, page: 0, fetchPage: false };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchPage: false };
        }
    }

    return {
        fetchPage: false,
        order: SORT_ORDER_DESC,
        page: 0,
        rowsPerPage: SERVICES_LIMIT,
        sort: SERVICE_KEYS.CREATED_AT,
    };
};

const ManageServices = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isServicesMounted = useRef(true);
    const dispatch = useDispatch();
    /* const navigate = useNavigate(); */
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { currentService, services, serviceCounts } = useSelector(
        ({ servicesReducer }) => servicesReducer
    );

    const [isLoading, setIsLoading] = useState(false);
    const [servicesFetched, setServicesFetched] = useState(true);
    const [openAddEditServiceDialog, setOpenAddEditServiceDialog] =
        useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
    });

    const [filterState, dispatchFilterAction] = useReducer(filterReducer, {
        fetchPage: false,
        order: SORT_ORDER_DESC,
        page: 0,
        rowsPerPage: SERVICES_LIMIT,
        sort: SERVICE_KEYS.CREATED_AT,
    });

    const { fetchPage } = filterState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    const canAddService = isAdminUser || isFmUser;
    const canDeleteService = isAdminUser || isFmUser;
    const canEditService = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isServicesMounted.current = false;
            dispatch(setCurrentService(null));
        };
    }, [dispatch]);

    /* Start loading & fetching page if user is valid */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    useEffect(() => {
        if (isLoading) {
            dispatchFilterAction({ type: "FETCH_PAGE" });
        }
    }, [isLoading]);

    /* Fetch services list */
    useEffect(() => {
        if (fetchPage) {
            setServicesFetched(false);
        }
    }, [fetchPage]);

    useEffect(() => {
        let isActive = true;

        if (!servicesFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredServices(payload, () => {
                            if (isActive) {
                                setServicesFetched(true);
                                dispatchFilterAction({ type: "STOP_FETCH" });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const serviceData = {
                authcode: authToken,
                order: filterState.order,
                page: filterState.page + 1,
                rows_per_page: filterState.rowsPerPage,
                sort: filterState.sort,
            };

            const promise = dispatch(
                fetchData({ serviceData: serviceData, fromAction: actionState })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setServicesFetched(true);
                    dispatchFilterAction({ type: "RESET_FILTER" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, actionState, filterState, servicesFetched]);

    /* Fetch the list again if a service is added or deleted */
    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            setServicesFetched(false);
        }
    }, [actionState]);

    const handlePageChange = (event, newPage) =>
        dispatchFilterAction({ type: "SET_PAGE", page: newPage });

    const handleRowsPerPageChange = (event) => {
        dispatchFilterAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleAddService = () => {
        if (canAddService) {
            setOpenAddEditServiceDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAddEditServiceDialog = () => {
        setOpenAddEditServiceDialog(false);
        dispatch(setCurrentService(null));
    };

    const handleServiceEdit = (service) => {
        if (canEditService) {
            dispatch(setCurrentService(service));
            setOpenAddEditServiceDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleServiceDelete = React.useCallback(
        (service) => {
            if (canDeleteService) {
                if (service && service[SERVICE_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete service: ${service[SERVICE_KEYS.NAME]}?`,
                        onYes: () => {
                            hideDialog();
                            const serviceData = {
                                authcode: authToken,
                                [SERVICE_KEYS.SERVICE_ID]:
                                    service[SERVICE_KEYS.ID],
                            };
                            dispatch(
                                deleteService(
                                    { serviceData: serviceData },
                                    () => {
                                        if (_isServicesMounted.current)
                                            dispatchAction({ type: "DELETE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid service",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, canDeleteService]
    );

    return (
        <React.Fragment>
            {isValidUserType && !isLoading && (
                <React.Fragment>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12}>
                            <HeadingStack>
                                <PageTitle
                                    component="h1"
                                    title={pageName}
                                    type="v2"
                                />
                                <Breadcrumb
                                    pageNames={{
                                        genericName: pageName,
                                        specificName: pageName,
                                    }}
                                />
                            </HeadingStack>
                        </Grid>
                        {isValidUserType &&
                            servicesFetched &&
                            canAddService && (
                                <Grid item xs={12}>
                                    <HeadingStack
                                        sx={{ justifyContent: "end" }}
                                    >
                                        <FmButton
                                            startIcon="add"
                                            type="button"
                                            label="Add Service"
                                            onClick={handleAddService}
                                        />
                                    </HeadingStack>
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <ServicesTable
                                canDelete={canDeleteService}
                                canEdit={canEditService}
                                page={filterState.page}
                                rowsPerPage={filterState.rowsPerPage}
                                services={services}
                                serviceCounts={serviceCounts}
                                servicesFetched={servicesFetched}
                                handlePageChange={handlePageChange}
                                handleRowsPerPageChange={
                                    handleRowsPerPageChange
                                }
                                onEdit={handleServiceEdit}
                                onDelete={handleServiceDelete}
                            />
                        </Grid>
                    </Grid>
                    {openAddEditServiceDialog && (
                        <AddEditServiceDialog
                            currentService={currentService}
                            open={openAddEditServiceDialog}
                            onClose={handleCloseAddEditServiceDialog}
                            onDispatchAction={dispatchAction}
                        />
                    )}
                </React.Fragment>
            )}
            {!isValidUserType && (
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default ManageServices;
