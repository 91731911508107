import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getCaseStudyLocal } from "../../redux/actions/caseStudies";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import HTMLTypography from "../../shared/widgets/HTMLTypography";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    CASE_STUDY_KEYS,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";

const genericPageName = "Case Study";

const INIT_CASE_STUDY_STATE = {
    caseStudy: null,
    caseStudyFetched: false,
    caseStudyId: "",
    caseStudyValid: false,
    fetchCaseStudy: false,
    firstLoad: true,
    inputIsValid: false,
};

const caseStudyLocalReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, caseStudyFetched: false, fetchCaseStudy: true };
        } else if (action.type === "RESET") {
            return {
                ...state,
                caseStudy: null,
                caseStudyFetched: false,
                caseStudyValid: false,
                fetchCaseStudy: false,
                firstLoad: false,
            };
        } else if (action.type === "SET_CASE_STUDY_ID") {
            const updatedCaseStudyId = action.caseStudyId;
            return {
                ...state,
                caseStudyId: updatedCaseStudyId,
                inputIsValid: !!updatedCaseStudyId,
            };
        } else if (action.type === "SET_DATA") {
            const updatedCaseStudy = action.caseStudy;
            let updatedCaseStudyValid = false;
            if (
                updatedCaseStudy &&
                updatedCaseStudy?.[CASE_STUDY_KEYS.ID] &&
                updatedCaseStudy[CASE_STUDY_KEYS.ID]
            ) {
                updatedCaseStudyValid = true;
            }

            return {
                ...state,
                caseStudy: updatedCaseStudy,
                caseStudyFetched: true,
                caseStudyValid: updatedCaseStudyValid,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchCaseStudy: false, firstLoad: false };
        }
    }

    return INIT_CASE_STUDY_STATE;
};

const CaseStudy = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isMountedCaseStudy = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const caseStudyIdParam = params.caseStudyId;

    const [isLoading, setIsLoading] = useState(false);

    const [caseStudyState, dispatchCaseStudyAction] = useReducer(
        caseStudyLocalReducer,
        INIT_CASE_STUDY_STATE
    );

    const {
        caseStudy,
        caseStudyFetched,
        caseStudyValid,
        fetchCaseStudy,
        inputIsValid,
    } = caseStudyState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    useEffect(() => {
        return () => {
            _isMountedCaseStudy.current = false;
        };
    }, []);

    /* Set case study ID */
    useEffect(() => {
        if (isValidUserType) {
            dispatchCaseStudyAction({
                type: "SET_CASE_STUDY_ID",
                caseStudyId: caseStudyIdParam || "",
            });
        }
    }, [caseStudyIdParam, isValidUserType]);

    /* Start loading if case study ID is valid */
    useEffect(() => {
        if (inputIsValid) {
            setIsLoading(true);
        }
    }, [inputIsValid]);

    /* Fetch case study */
    useEffect(() => {
        if (isLoading) {
            dispatchCaseStudyAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (caseStudyState.fetchCaseStudy) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCaseStudyLocal(payload, (fetchedCaseStudy) => {
                            if (isActive) {
                                dispatchCaseStudyAction({ type: "STOP_FETCH" });
                                dispatchCaseStudyAction({
                                    type: "SET_DATA",
                                    caseStudy: fetchedCaseStudy || null,
                                });
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const caseStudyData = {
                authcode: authToken,
                [CASE_STUDY_KEYS.CASE_STUDY_ID]: caseStudyState.caseStudyId,
            };

            const promise = dispatch(
                fetchData({ caseStudyData: caseStudyData })
            );
            promise.catch((error) => {
                if (isActive) {
                    dispatchCaseStudyAction({ type: "RESET" });
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, caseStudyState]);

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchCaseStudy && !caseStudyFetched;
    const loadError =
        !isValidUserType || !inputIsValid || fetchError || !caseStudyValid;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle
                        component="h1"
                        title={genericPageName}
                        type="v2"
                    />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: genericPageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !inputIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid case study!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && inputIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the case study!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType &&
                        inputIsValid &&
                        !fetchError &&
                        !caseStudyValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Fetched case study is not valid!
                                </Alert>
                            </Grid>
                        )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    {caseStudy[CASE_STUDY_KEYS.DESCRIPTION] && (
                        <Grid item xs={12}>
                            <HTMLTypography
                                content={caseStudy[CASE_STUDY_KEYS.DESCRIPTION]}
                            />
                        </Grid>
                    )}
                    {/* caseStudy[CASE_STUDY_KEYS.PROCEDURE] && (
                        <Grid item xs={12}>
                            <HTMLTypography
                                content={caseStudy[CASE_STUDY_KEYS.PROCEDURE]}
                            />
                        </Grid>
                    ) */}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default CaseStudy;
