import React from "react";
import Page from "@jumbo/shared/Page";
import Announcements from "../pages/announcements";

const announcementsRoutes = [
    {
        path: "/announcements",
        element: <Page component={Announcements} />,
    },
];

export default announcementsRoutes;
