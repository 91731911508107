import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const LockIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 14.712 17.982">
            <path
                d="M4.087,8.174h6.539V5.722A3.15,3.15,0,0,0,9.668,3.41a3.15,3.15,0,0,0-2.312-.958,3.15,3.15,0,0,0-2.312.958,3.15,3.15,0,0,0-.958,2.312V8.174ZM14.713,9.4v7.356a1.221,1.221,0,0,1-1.226,1.226H1.227a1.182,1.182,0,0,1-.868-.357A1.182,1.182,0,0,1,0,16.757V9.4a1.182,1.182,0,0,1,.357-.868,1.182,1.182,0,0,1,.868-.357h.409V5.722A5.507,5.507,0,0,1,3.321,1.686,5.506,5.506,0,0,1,7.356,0a5.508,5.508,0,0,1,4.036,1.686,5.5,5.5,0,0,1,1.686,4.036V8.174h.409A1.221,1.221,0,0,1,14.713,9.4Z"
                transform="translate(-0.001)"
            />
        </SvgIcon>
    );
};

export default LockIcon;
