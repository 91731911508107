import {
    ADD_SPECIAL,
    DELETE_SPECIAL,
    EDIT_SPECIAL,
    GET_SPECIAL_COUNT,
    GET_SPECIALS,
    SET_SPECIAL_DETAILS,
} from "../../utils/constants/actionTypes";
import { SPECIAL_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    specials: [],
    currentSpecial: null,
    specialCounts: { [SPECIAL_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SPECIALS: {
            return {
                ...state,
                specials: action.payload,
            };
        }
        case GET_SPECIAL_COUNT: {
            return {
                ...state,
                specialCounts: action.payload,
            };
        }
        case SET_SPECIAL_DETAILS: {
            return {
                ...state,
                currentSpecial: action.payload,
            };
        }
        case ADD_SPECIAL: {
            return {
                ...state,
                specials: [action.payload, ...state.specials],
            };
        }
        case EDIT_SPECIAL: {
            return {
                ...state,
                specials: state.specials.map((special) =>
                    special.id === action.payload.id ? action.payload : special
                ),
            };
        }
        case DELETE_SPECIAL: {
            return {
                ...state,
                specials: state.specials.filter(
                    (special) => special.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
