import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "../../shared/SVGImages/SettingsIcon";
import { SERVICE_KEYS } from "../../utils/constants/appData";

const ServicesList = ({
    canRequestService,
    services,
    onReadDescription,
    onRequestService,
}) => {
    const showActions = canRequestService;

    return (
        <Grid container spacing={4}>
            {services.map((service) => {
                const serviceId = service[SERVICE_KEYS.ID];
                return (
                    <Grid
                        item
                        key={`service-${serviceId}`}
                        md={3}
                        sm={6}
                        xs={12}
                        sx={{ p: 0 }}
                    >
                        <Card
                            sx={(theme) => ({
                                padding: theme.spacing(2, 2),
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 12px 15px #0000000D",
                                borderRadius: "10px",
                                opacity: 1,
                            })}
                        >
                            <CardHeader
                                action={
                                    <Stack
                                        direction="row"
                                        justifyContent="end"
                                        alignItems="center"
                                        spacing={0}
                                    >
                                        <Tooltip title="Read Full Description">
                                            <IconButton
                                                type="button"
                                                onClick={() =>
                                                    onReadDescription(service)
                                                }
                                                sx={{ color: "#888888" }}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                }
                                sx={{ p: 0 }}
                            />
                            <CardContent>
                                <Stack
                                    alignItems={"center"}
                                    sx={{
                                        p: (theme) => theme.spacing(0),
                                        mt: -2,
                                        mb: (theme) => theme.spacing(1),
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            mb: 2,
                                            p: 3,
                                            borderRadius: "50%",
                                            backgroundColor: "#F1F2F3",
                                        }}
                                    >
                                        <SettingsIcon
                                            sx={{ fontSize: "3rem" }}
                                        />
                                    </Box>
                                    <Typography
                                        noWrap
                                        variant={"h5"}
                                        align="center"
                                        /* sx={{ wordWrap: "break-word" }} */
                                    >
                                        {service[SERVICE_KEYS.NAME]}
                                    </Typography>
                                    <Typography
                                        color="text.disabled"
                                        align="center"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                service[
                                                    SERVICE_KEYS.DESCRIPTION
                                                ]
                                            ),
                                        }}
                                        sx={{
                                            height: "75px",
                                            overflow: "hidden",
                                        }}
                                    />
                                </Stack>
                            </CardContent>
                            {showActions && (
                                <CardActions>
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        flexWrap="wrap"
                                        sx={{ width: "100%" }}
                                    >
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                onRequestService(service);
                                            }}
                                        >
                                            Request
                                        </Button>
                                    </Stack>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

ServicesList.propTypes = {
    services: PropTypes.array,
    onReadDescription: PropTypes.func,
    onRequestService: PropTypes.func,
};

ServicesList.defaultProps = {
    services: [],
};

export default ServicesList;
