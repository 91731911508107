import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
/* import Autocomplete from "@mui/material/Autocomplete"; */
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
/* import FormControl from "@mui/material/FormControl"; */
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    getClientUserProfile,
    updateClientUserProfile,
} from "../../redux/actions/clientUsers";
import { getAllIndustries } from "../../redux/actions/industries";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FormattedPhoneNo from "../../shared/widgets/FormattedPhoneNo";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    CLIENT_USER_KEYS,
    INDUSTRY_ENTERPRISE,
    INDUSTRY_KEYS,
} from "../../utils/constants/appData";
import {
    checkCompanyNameValid,
    checkEmailValid,
    checkIndustryValid,
    checkNameValid,
    checkPasswordsMatch,
    checkPhoneNoValid,
    checkProfilePhotoValid,
    checkWebsiteValid,
} from "../../utils/validationHelpers";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";

const pageName = "Edit Profile";

const EditClientUserProfile = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isEditClientUserProfileMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [allIndustries, setAllIndustries] = useState([]);
    const [industriesList, setIndustriesList] = useState([]);
    const [filteredIndustriesList, setFilteredIndustriesList] = useState([]);
    const [userFetching, setUserFetching] = useState(false);
    const [userFetched, setUserFetched] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [company, setCompany] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [industry, setIndustry] = useState(null);
    const [industryError, setIndustryError] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneNoError, setPhoneNoError] = useState("");
    const [website, setWebsite] = useState("");
    const [websiteError, setWebsiteError] = useState("");
    const [photo, setPhoto] = useState(null);
    const [photoError, setPhotoError] = useState("");
    const [photoLink, setPhotoLink] = useState("");
    const [initials, setInitials] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [matchPasswords, setMatchPasswords] = useState(false);
    const [matchPasswordsError, setMatchPasswordsError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        let isActive = true;

        if (authUser) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllIndustries(payload, (fetchedIndustries) => {
                            if (isActive) {
                                setAllIndustries(fetchedIndustries);
                                setUserFetching(true);
                            }
                        })
                    );
                };
            };

            const industryData = { authcode: authToken };
            const promise = dispatch(
                fetchData({ industryData: industryData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setAllIndustries([]);
                }
            });
        } else {
            setAllIndustries([]);
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUser]);

    useEffect(() => {
        if (allIndustries) {
            setIndustriesList(
                allIndustries.map(
                    (tempIndustry) => tempIndustry[INDUSTRY_KEYS.NAME]
                )
            );
        }
    }, [allIndustries]);

    useEffect(() => {
        let isActive = true;

        if (industriesList && userFetching && !userFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getClientUserProfile(payload, (fetchedUser) => {
                            if (isActive) {
                                const fetchedUserName =
                                    fetchedUser[CLIENT_USER_KEYS.NAME];

                                setId(fetchedUser[CLIENT_USER_KEYS.ID]);
                                setName(fetchedUserName);
                                setEmail(fetchedUser[CLIENT_USER_KEYS.EMAIL]);
                                setCompany(
                                    fetchedUser[CLIENT_USER_KEYS.COMPANY]
                                );
                                setPhoneNo(
                                    fetchedUser[CLIENT_USER_KEYS.PHONE_NO]
                                );
                                setWebsite(
                                    fetchedUser[CLIENT_USER_KEYS.WEBSITE] || ""
                                );
                                if (industriesList) {
                                    const clientIndustry = industriesList.find(
                                        (tempIndustry) =>
                                            tempIndustry ===
                                            fetchedUser[
                                                CLIENT_USER_KEYS.INDUSTRY
                                            ]
                                    );
                                    setIndustry(
                                        clientIndustry ? clientIndustry : null
                                    );
                                }
                                setPhoto(null);
                                const fetchedPhoto =
                                    fetchedUser[CLIENT_USER_KEYS.PROFILE_PIC];
                                let fetchedPhotoLink = "";
                                if (fetchedPhoto) {
                                    fetchedPhotoLink = `${
                                        fetchedUser[
                                            CLIENT_USER_KEYS.PHOTO_BASE_URL
                                        ]
                                    }/${
                                        fetchedPhoto[
                                            CLIENT_USER_KEYS.PHOTO_FILE_PATH
                                        ]
                                    }`;
                                    setPhotoLink(fetchedPhotoLink);
                                }
                                setInitials(
                                    fetchedPhotoLink ? (
                                        <React.Fragment>&nbsp;</React.Fragment>
                                    ) : (
                                        getInitialsFromName(fetchedUserName)
                                    )
                                );
                                setUserFetching(false);
                                setUserFetched(true);
                            }
                        })
                    );
                };
            };

            const clientUserData = {
                authcode: authToken,
                [CLIENT_USER_KEYS.USER_ID]: authUser[AUTH_USER_KEYS.ID],
            };

            const promise = dispatch(
                fetchData({ clientUserData: clientUserData })
            );
            promise.catch((error) => {
                if (isActive) {
                    setUserFetching(false);
                    setUserFetched(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        authUser,
        industriesList,
        userFetching,
        userFetched,
    ]);

    useEffect(() => {
        if (industry && industriesList) {
            if (industry === INDUSTRY_ENTERPRISE) {
                setFilteredIndustriesList([...industriesList]);
            } else {
                setFilteredIndustriesList(
                    industriesList.filter(
                        (industryListItem) =>
                            industryListItem !== INDUSTRY_ENTERPRISE
                    )
                );
            }
        }
    }, [industry, industriesList]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case CLIENT_USER_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case CLIENT_USER_KEYS.EMAIL:
                        setEmailError(msg);
                        break;

                    case CLIENT_USER_KEYS.COMPANY:
                        setCompanyError(msg);
                        break;

                    case CLIENT_USER_KEYS.INDUSTRY:
                        setIndustryError(msg);
                        break;

                    case CLIENT_USER_KEYS.PHONE_NO:
                        setPhoneNoError(msg);
                        break;

                    case CLIENT_USER_KEYS.WEBSITE:
                        setWebsiteError(msg);
                        break;

                    case CLIENT_USER_KEYS.PROFILE_PIC:
                        setPhotoError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    useEffect(() => {
        if (matchPasswords) {
            setMatchPasswordsError("");
            /* if (password && confirmPassword) { */
            const validationResult = checkPasswordsMatch(
                password,
                confirmPassword
            );
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    setMatchPasswordsError(validationResult.msg);
                }
            } else {
                setMatchPasswordsError(VALIDATION_FAILED);
            }
            /* } */
            setMatchPasswords(false);
        }
    }, [
        password,
        passwordError,
        confirmPassword,
        confirmPasswordError,
        matchPasswords,
        matchPasswordsError,
    ]);

    const nameBlurHandler = (event) => {
        const validationResult = checkNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const emailBlurHandler = (event) => {
        const validationResult = checkEmailValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setEmailError(validationResult.msg);
            }
        } else {
            setEmailError(VALIDATION_FAILED);
        }
    };

    /* const companyBlurHandler = (event) => {
        const validationResult = checkCompanyNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setCompanyError(validationResult.msg);
            }
        } else {
            setCompanyError(VALIDATION_FAILED);
        }
    }; */

    /* const industryChangeHandler = (event, newValue) => {
        setIndustryError("");

        const industryValidationResult = checkIndustryValid(
            newValue,
            filteredIndustriesList
        );
        if (industryValidationResult.status) {
            if (industryValidationResult.status === "false") {
                setIndustry(null);
                setIndustryError(industryValidationResult.msg);
            } else {
                setIndustry(newValue);
            }
        } else {
            setIndustry(null);
            setIndustryError(VALIDATION_FAILED);
        }
    }; */

    const phoneNoChangeHandler = (event) => {
        const updatedPhoneNo = event.target.value;

        setPhoneNo(updatedPhoneNo);
        setPhoneNoError("");

        const validationResult = checkPhoneNoValid(updatedPhoneNo);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setPhoneNoError(validationResult.msg);
            }
        } else {
            setPhoneNoError(VALIDATION_FAILED);
        }
    };

    const websiteBlurHandler = (event) => {
        const enteredWebsite = event.target.value;
        if (enteredWebsite) {
            const validationResult = checkWebsiteValid(enteredWebsite);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    setWebsiteError(validationResult.msg);
                }
            } else {
                setWebsiteError(VALIDATION_FAILED);
            }
        }
    };

    const photoChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (files && files.length > 0) {
            const validationResult = checkProfilePhotoValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setPhotoError(validationResult.msg);
                }
            } else {
                isValid = false;
                setPhotoError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setPhoto(files[0]);
            setPhotoError("");
        } else {
            setPhoto(null);
        }
    };

    const passwordBlurHandler = (event) => {
        setPasswordError("");
        setMatchPasswords(true);
    };

    const confirmPasswordBlurHandler = (event) => {
        setConfirmPasswordError("");
        setMatchPasswords(true);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        const emailValidationResult = checkEmailValid(email);
        if (emailValidationResult.status) {
            if (emailValidationResult.status === "false") {
                formIsValid = false;
                setEmailError(emailValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setEmailError(VALIDATION_FAILED);
        }

        const companyValidationResult = checkCompanyNameValid(company);
        if (companyValidationResult.status) {
            if (companyValidationResult.status === "false") {
                formIsValid = false;
                setCompanyError(companyValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setCompanyError(VALIDATION_FAILED);
        }

        const industryValidationResult = checkIndustryValid(
            industry,
            filteredIndustriesList
        );
        if (industryValidationResult.status) {
            if (industryValidationResult.status === "false") {
                formIsValid = false;
                setIndustryError(industryValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setIndustryError(VALIDATION_FAILED);
        }

        const phoneNoValidationResult = checkPhoneNoValid(phoneNo);
        if (phoneNoValidationResult.status) {
            if (phoneNoValidationResult.status === "false") {
                formIsValid = false;
                setPhoneNoError(phoneNoValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPhoneNoError(VALIDATION_FAILED);
        }

        if (website) {
            const websiteValidationResult = checkWebsiteValid(website);
            if (websiteValidationResult.status) {
                if (websiteValidationResult.status === "false") {
                    formIsValid = false;
                    setWebsiteError(websiteValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setWebsiteError(VALIDATION_FAILED);
            }
        }

        if (photo) {
            const photoValidationResult = checkProfilePhotoValid([photo]);
            if (photoValidationResult.status) {
                if (photoValidationResult.status === "false") {
                    formIsValid = false;
                    setPhotoError(photoValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setPhotoError(VALIDATION_FAILED);
            }
        }

        const matchValidationResult = checkPasswordsMatch(
            password,
            confirmPassword
        );
        if (matchValidationResult.status) {
            if (matchValidationResult.status === "false") {
                formIsValid = false;
                setPasswordError(matchValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPasswordError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const clientUserData = new FormData();
            clientUserData.append("authcode", authToken);
            clientUserData.append([CLIENT_USER_KEYS.NAME], name);
            clientUserData.append([CLIENT_USER_KEYS.EMAIL], email);
            /* clientUserData.append([CLIENT_USER_KEYS.COMPANY], company);
            clientUserData.append([CLIENT_USER_KEYS.INDUSTRY], industry); */
            clientUserData.append([CLIENT_USER_KEYS.PHONE_NO], phoneNo);
            clientUserData.append([CLIENT_USER_KEYS.WEBSITE], website || "");
            if (photo)
                clientUserData.append([CLIENT_USER_KEYS.PROFILE_PIC], photo);
            if (password) {
                clientUserData.append([CLIENT_USER_KEYS.PASSWORD], password);
                clientUserData.append(
                    [CLIENT_USER_KEYS.CONFIRM_PASSWORD],
                    confirmPassword
                );
            }
            dispatch(
                updateClientUserProfile(
                    { clientUserData: clientUserData },
                    () => {
                        if (_isEditClientUserProfileMounted.current) {
                            setErrorMessages([]);
                            window.location.reload();
                        }
                    },
                    (messages) => {
                        if (_isEditClientUserProfileMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const userIsValid = !!id;

    return (
        <Grid
            container
            spacing={3.5}
            sx={(theme) => ({
                p: theme.spacing(5, 10),
                [theme.breakpoints.down("md")]: {
                    p: theme.spacing(0.5),
                },
            })}
        >
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle component="h1" title={pageName} type="v2" />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {!userFetching && userFetched && userIsValid && (
                <Grid item xs={12}>
                    <Card sx={{ px: 2.5, py: 5, borderRadius: "5px" }}>
                        <form onSubmit={handleFormSubmit}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {miscellaneousErrors &&
                                        miscellaneousErrors.length > 0 && (
                                            <Grid item xs={12}>
                                                {miscellaneousErrors.map(
                                                    (
                                                        miscellaneousError,
                                                        idx
                                                    ) => (
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            color="error"
                                                            gutterBottom
                                                            key={`misc-error-${idx}`}
                                                        >
                                                            {miscellaneousError}
                                                        </Typography>
                                                    )
                                                )}
                                            </Grid>
                                        )}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mb: (theme) => theme.spacing(1) }}
                                    >
                                        <Avatar
                                            src={photoLink}
                                            alt={name}
                                            sx={{
                                                width: 96,
                                                height: 96,
                                                bgcolor: "#487AA3",
                                            }}
                                        >
                                            {initials}
                                        </Avatar>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            variant="outlined"
                                            id="company"
                                            name="company"
                                            label="Company"
                                            margin="dense"
                                            value={company}
                                            /* onChange={(e) => {
                                                setCompany(e.target.value);
                                                setCompanyError("");
                                            }}
                                            onBlur={companyBlurHandler} */
                                            error={companyError !== ""}
                                            helperText={companyError}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            variant="outlined"
                                            id="industry"
                                            name="industry"
                                            label="Industry"
                                            margin="dense"
                                            value={industry}
                                            error={industryError !== ""}
                                            helperText={industryError}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            id="name"
                                            name="name"
                                            label="Name"
                                            value={name}
                                            margin="dense"
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                setNameError("");
                                            }}
                                            onBlur={nameBlurHandler}
                                            error={nameError !== ""}
                                            helperText={nameError}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={email}
                                            margin="dense"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailError("");
                                            }}
                                            onBlur={emailBlurHandler}
                                            error={emailError !== ""}
                                            helperText={emailError}
                                        />
                                    </Grid>
                                    {/* <Grid item md={6} xs={12}>
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            <Autocomplete
                                                disablePortal
                                                fullWidth
                                                filterSelectedOptions
                                                id="industry"
                                                options={filteredIndustriesList}
                                                value={industry}
                                                getOptionLabel={(option) => {
                                                    return option ? option : "";
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Select Industry"
                                                        error={
                                                            industryError !== ""
                                                        }
                                                    />
                                                )}
                                                onChange={industryChangeHandler}
                                            />
                                            {industryError && (
                                                <FormHelperText
                                                    error
                                                    id="industry-error"
                                                >
                                                    {industryError}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            id="phone_no"
                                            name="phone_no"
                                            label="Phone Number"
                                            margin="dense"
                                            value={phoneNo}
                                            InputProps={{
                                                inputComponent:
                                                    FormattedPhoneNo,
                                                inputProps: {
                                                    allowEmptyFormatting: false,
                                                    displayType: "input",
                                                },
                                            }}
                                            onChange={phoneNoChangeHandler}
                                            error={phoneNoError !== ""}
                                            helperText={phoneNoError}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="website"
                                            name="website"
                                            label="Website"
                                            margin="dense"
                                            value={website}
                                            onChange={(e) => {
                                                setWebsite(e.target.value);
                                                setWebsiteError("");
                                            }}
                                            onBlur={websiteBlurHandler}
                                            error={websiteError !== ""}
                                            helperText={websiteError}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Leave the photo blank if you do not
                                            want to change it
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <label
                                            htmlFor="photo"
                                            style={{
                                                display: "block",
                                                marginTop: 8,
                                            }}
                                        >
                                            <input
                                                id="photo"
                                                name="photo"
                                                type="file"
                                                onChange={photoChangeHandler}
                                                style={{ display: "none" }}
                                            />

                                            <AttachFileButton
                                                fullWidth
                                                type="button"
                                                variant="outlined"
                                                component="span"
                                                startIcon={<AttachFileIcon />}
                                                sx={(theme) => {
                                                    const photoErrorSx =
                                                        photoError
                                                            ? {
                                                                  color: theme
                                                                      .palette
                                                                      .error
                                                                      .main,
                                                                  border: `1px solid ${theme.palette.error.main}`,
                                                              }
                                                            : "";
                                                    return {
                                                        ...photoErrorSx,
                                                    };
                                                }}
                                            >
                                                {photo ? photo.name : "Photo"}
                                            </AttachFileButton>
                                        </label>
                                        {photoError && (
                                            <FormHelperText
                                                error
                                                id="photo-error"
                                            >
                                                {photoError}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Leave the password field blank if
                                            you do not want to change the
                                            password
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            variant="outlined"
                                            id="password"
                                            name="password"
                                            label="Password"
                                            value={password}
                                            margin="dense"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setPasswordError("");
                                            }}
                                            onBlur={passwordBlurHandler}
                                            error={
                                                passwordError !== "" ||
                                                matchPasswordsError !== ""
                                            }
                                            helperText={
                                                passwordError ||
                                                matchPasswordsError
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            variant="outlined"
                                            id="confirm_password"
                                            name="confirm_password"
                                            label="Confirm Password"
                                            value={confirmPassword}
                                            margin="dense"
                                            onChange={(e) => {
                                                setConfirmPassword(
                                                    e.target.value
                                                );
                                                setConfirmPasswordError("");
                                            }}
                                            onBlur={confirmPasswordBlurHandler}
                                            error={
                                                confirmPasswordError !== "" ||
                                                matchPasswordsError !== ""
                                            }
                                            helperText={
                                                confirmPasswordError ||
                                                matchPasswordsError
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Update Profile
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </form>
                    </Card>
                </Grid>
            )}
            {!userFetching && userFetched && !userIsValid && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        Client is not valid!
                    </Alert>
                </Grid>
            )}
            {!userFetching && !userFetched && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        Client not found!
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default EditClientUserProfile;
