import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import DndWrapper from "../../shared/widgets/DndWrapper";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { formatBytes } from "../../utils/appHelpers";
import {
    AUTH_USER_KEYS,
    FLYER_APPROVED_STATUS,
    FLYER_PENDING_STATUS,
    SPECIAL_KEYS,
    SPECIAL_FLYER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
    VALID_COMMON_FILE_MAX_SIZE_MB,
    VALID_COMMON_FILES_COUNT,
    VALID_SPECIAL_FILE_EXTENSIONS,
    VALID_SPECIAL_FILE_MIME_TYPES,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkFlyersValid } from "../../utils/validationHelpers";
import { saveSpecialFlyer } from "../../redux/actions/specials";

const AddEditFlyerDialog = ({
    currentSpecial,
    open,
    setGlobalState,
    onClose,
    onUpdate,
}) => {
    const _isAddEditFlyerDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: VALID_SPECIAL_FILE_MIME_TYPES.join(","),
        maxSize: VALID_COMMON_FILE_MAX_SIZE_MB * 1024 * 1024,
        maxFiles: VALID_COMMON_FILES_COUNT,
        onDrop: (acceptedFiles, fileRejections) => {
            setFlyersError("");
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "too-many-files") {
                        setFlyersError(
                            `Error: Maximum number of allowed files is ${VALID_COMMON_FILES_COUNT}`
                        );
                    }

                    if (err.code === "file-too-large") {
                        setFlyersError(
                            `Error: Maximum allowed file size is ${VALID_COMMON_FILE_MAX_SIZE_MB} MB`
                        );
                    }

                    if (err.code === "file-invalid-type") {
                        setFlyersError(
                            `Error: File type must be ${VALID_SPECIAL_FILE_EXTENSIONS.join(
                                ", "
                            )}`
                        );
                    }
                });
            });
        },
    });
    const Swal = useSwalWrapper();

    const [flyers, setFlyers] = useState([]);
    const [flyersError, setFlyersError] = useState("");
    const [deletedFlyers, setDeletedFlyers] = useState([]);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    useEffect(() => {
        return () => {
            _isAddEditFlyerDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setFlyers([]);
            setFlyersError("");
            setDeletedFlyers([]);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (currentSpecial) {
            setFlyers(currentSpecial[SPECIAL_KEYS.UPLOADED_FLYERS] || []);
            setDeletedFlyers([]);
        }
    }, [currentSpecial]);

    useEffect(() => {
        const acceptedFilesValidationResult = checkFlyersValid(acceptedFiles);
        const flyersValidationResult = checkFlyersValid(flyers);

        if (
            (acceptedFilesValidationResult.status &&
                acceptedFilesValidationResult.status === "true") ||
            (flyersValidationResult.status &&
                flyersValidationResult.status === "true")
        ) {
            setFlyersError("");
        }
    }, [acceptedFiles, flyers]);

    useEffect(() => {
        if (deletedFlyers && deletedFlyers.length > 0) {
            setFlyers((prevState) =>
                prevState.filter((tempFlyer) => {
                    return !deletedFlyers.includes(
                        tempFlyer[SPECIAL_FLYER_KEYS.ID]
                    );
                })
            );
        }
    }, [deletedFlyers]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case SPECIAL_KEYS.FLYERS:
                        setFlyersError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const handleDeleteFlyer = (deletedFlyer) => {
        if (deletedFlyer[SPECIAL_FLYER_KEYS.STATUS] === FLYER_APPROVED_STATUS) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Approved flyers cannot be deleted",
            });
        } else {
            setDeletedFlyers((prevState) => [
                ...prevState,
                deletedFlyer[SPECIAL_FLYER_KEYS.ID],
            ]);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        let validateNewFlyers = true;
        /* If no new flyers are uploaded: */
        if (acceptedFiles && acceptedFiles.length === 0) {
            /* If one or more existing flyers have been deleted: */
            if (deletedFlyers && deletedFlyers.length > 0) {
                /* then no need to validate */
                validateNewFlyers = false;
            }
        }

        if (validateNewFlyers) {
            const acceptedFilesValidationResult =
                checkFlyersValid(acceptedFiles);
            if (acceptedFilesValidationResult.status) {
                if (acceptedFilesValidationResult.status === "false") {
                    formIsValid = false;
                    setFlyersError(acceptedFilesValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setFlyersError(VALIDATION_FAILED);
            }
        }

        if (formIsValid) {
            const specialData = new FormData();
            specialData.append("authcode", authToken);
            specialData.append(
                [SPECIAL_KEYS.SPECIAL_ID],
                currentSpecial[SPECIAL_KEYS.ID]
            );
            specialData.append(
                [SPECIAL_KEYS.DELETED_FLYERS],
                deletedFlyers.length > 0 ? deletedFlyers.join(",") : ""
            );
            if (acceptedFiles.length > 0) {
                for (let i = 0; i < acceptedFiles.length; i++) {
                    specialData.append([SPECIAL_KEYS.FLYERS], acceptedFiles[i]);
                }
            } else {
                specialData.append([SPECIAL_KEYS.FLYERS], null);
            }

            /* for (const specialDataVal of specialData.values()) {
                console.log(specialDataVal);
            } */

            dispatch(
                saveSpecialFlyer(
                    {
                        specialData: specialData,
                        setGlobalState: setGlobalState,
                    },
                    (updatedSpecial) => {
                        if (_isAddEditFlyerDialogMounted.current) {
                            onUpdate(updatedSpecial);
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isAddEditFlyerDialogMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const loadError = !isValidUserType;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title="Upload Flyers"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {loadError && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!loadError && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <Div sx={{ flex: 1 }}>
                                    {flyers.length > 0 && (
                                        <React.Fragment>
                                            <Typography variant="p">
                                                Uploaded Flyers
                                            </Typography>
                                            <List
                                                disablePadding
                                                sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                {flyers.map((flyer) => {
                                                    const isFlyerApproved =
                                                        flyer[
                                                            SPECIAL_FLYER_KEYS
                                                                .STATUS
                                                        ] ===
                                                        FLYER_APPROVED_STATUS;
                                                    const isFlyerPending =
                                                        flyer[
                                                            SPECIAL_FLYER_KEYS
                                                                .STATUS
                                                        ] ===
                                                        FLYER_PENDING_STATUS;

                                                    return (
                                                        <ListItem
                                                            key={
                                                                flyer[
                                                                    SPECIAL_FLYER_KEYS
                                                                        .ID
                                                                ]
                                                            }
                                                            component={Link}
                                                            href={
                                                                flyer[
                                                                    SPECIAL_FLYER_KEYS
                                                                        .LINK
                                                                ]
                                                            }
                                                            target="_blank"
                                                            rel="noopener"
                                                            underline="hover"
                                                            secondaryAction={
                                                                isFlyerApproved ? (
                                                                    <Typography
                                                                        sx={(
                                                                            theme
                                                                        ) => ({
                                                                            fontWeight:
                                                                                "bold",
                                                                            color: theme
                                                                                .palette
                                                                                .success
                                                                                .main,
                                                                        })}
                                                                    >
                                                                        Approved
                                                                    </Typography>
                                                                ) : isFlyerPending ? (
                                                                    <TableActionIcon
                                                                        iconComponent="delete"
                                                                        label="delete flyer"
                                                                        tooltip="Delete Flyer"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handleDeleteFlyer(
                                                                                flyer
                                                                            );
                                                                        }}
                                                                    />
                                                                ) : null
                                                            }
                                                            sx={{
                                                                width: "auto",
                                                                mr: 1,
                                                                flex: "1 1 100%",
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={`${
                                                                    flyer[
                                                                        SPECIAL_FLYER_KEYS
                                                                            .NAME
                                                                    ]
                                                                } - ${formatBytes(
                                                                    flyer[
                                                                        SPECIAL_FLYER_KEYS
                                                                            .SIZE
                                                                    ]
                                                                )}`}
                                                            />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </React.Fragment>
                                    )}
                                    <DndWrapper>
                                        <div
                                            {...getRootProps({
                                                className:
                                                    "dropzoneCustomInputBtn",
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography variant={"body1"}>
                                                Drag 'n' drop some files here,
                                                or click to select files
                                            </Typography>
                                        </div>
                                    </DndWrapper>
                                    <Typography variant="p">
                                        Selected Files
                                    </Typography>
                                    {acceptedFiles.length > 0 ? (
                                        <List
                                            disablePadding
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {acceptedFiles.map((file) => (
                                                <ListItem
                                                    key={file.path}
                                                    sx={{
                                                        width: "auto",
                                                        mr: 1,
                                                        flex: "1 1 100%",
                                                    }}
                                                >
                                                    {`${
                                                        file.path
                                                    } - ${formatBytes(
                                                        file.size
                                                    )}`}
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        !flyersError && (
                                            <Alert severity="info">
                                                No files selected!
                                            </Alert>
                                        )
                                    )}
                                    {flyersError && (
                                        <Alert severity="error">
                                            {flyersError}
                                        </Alert>
                                    )}
                                </Div>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditFlyerDialog.propTypes = {
    currentSpecial: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
};

AddEditFlyerDialog.defaultProps = {
    currentSpecial: null,
    open: false,
    setGlobalState: false,
    // onClose: () => {},
    onUpdate: () => {},
};

export default AddEditFlyerDialog;
