import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import HeadingStack from "../../shared/widgets/HeadingStack";
import NameLink from "../../shared/widgets/NameLink";
import PageTitle from "../../shared/widgets/PageTitle";
import { COMPANY_KEYS, INDUSTRY_KEYS } from "../../utils/constants/appData";

const CompanyDetails = ({
    allIndustries,
    canAddEditCompany,
    company,
    genericPageName,
    onEdit,
}) => {
    const [companyIndustry, setCompanyIndustry] = useState("");

    useEffect(() => {
        if (allIndustries && company[COMPANY_KEYS.INDUSTRY]) {
            const companyIndustryObj = allIndustries.find(
                (tempIndustryObj) =>
                    tempIndustryObj[INDUSTRY_KEYS.NAME] ===
                    company[COMPANY_KEYS.INDUSTRY]
            );
            setCompanyIndustry(
                companyIndustryObj ? companyIndustryObj[INDUSTRY_KEYS.NAME] : ""
            );
        }
    }, [allIndustries, company]);

    const companyName = company[COMPANY_KEYS.NAME];
    const companyWebsite = company[COMPANY_KEYS.WEBSITE] || "";
    const companySheetLink = company?.[COMPANY_KEYS.SHEET_LINK] || "";

    const pageName = companyName || genericPageName;

    return (
        <React.Fragment>
            {pageName && (
                <HeadingStack sx={{ alignItems: "baseline" }}>
                    <PageTitle type="v2" component="h1" title={pageName} />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            )}
            <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "start", md: "space-between" }}
                alignItems={{ xs: "start", md: "center" }}
            >
                <Stack direction="column">
                    {companyIndustry && (
                        <Typography
                            variant="h5"
                            component="p"
                            sx={{ color: "#757575" }}
                        >
                            {companyIndustry}
                        </Typography>
                    )}
                    {companyWebsite && (
                        <NameLink
                            href={companyWebsite}
                            target="_blank"
                            variant="h5"
                            sx={{ fontWeight: "bold" }}
                        >
                            {companyWebsite}
                        </NameLink>
                    )}
                    {companySheetLink && (
                        <NameLink
                            href={companySheetLink}
                            target="_blank"
                            variant="h5"
                            sx={{ fontWeight: "bold" }}
                        >
                            View Sheet
                        </NameLink>
                    )}
                </Stack>
                {canAddEditCompany && (
                    <FmButton
                        label="Edit Project"
                        startIcon="edit"
                        onClick={onEdit}
                    />
                )}
            </Stack>
        </React.Fragment>
    );
};

CompanyDetails.propTypes = {
    canAddEditCompany: PropTypes.bool,
    allIndustries: PropTypes.array,
    company: PropTypes.object,
    genericPageName: PropTypes.string,
    onEdit: PropTypes.func,
};

CompanyDetails.defaultProps = {
    canAddEditCompany: false,
    company: null,
    genericPageName: "",
};

export default CompanyDetails;
