import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import SentMessageContent from "./SentMessageContent";
import ReceivedMessageContent from "./ReceivedMessageContent";

const ConversationMessage = ({ conversation, messageKeys, onDownload }) => {
    if (!conversation || !messageKeys) return null;

    return (
        <React.Fragment>
            <Div
                sx={{
                    position: "relative",
                    textAlign: "center",
                    mb: 2,

                    "&:after": {
                        display: "inline-block",
                        content: "''",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        height: "1px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        backgroundColor: "divider",
                    },
                }}
            >
                <Chip
                    label={conversation?.[messageKeys.SENT_DATE]}
                    variant="outlined"
                    sx={{
                        position: "relative",
                        zIndex: 1,
                        bgcolor: (theme) => theme.palette.background.paper,
                        borderColor: "divider",
                        borderRadius: 2,
                    }}
                />
            </Div>
            {conversation?.[messageKeys.MESSAGES]?.map((message, index) => {
                return (
                    <React.Fragment key={index}>
                        {message?.[messageKeys.SENT_BY_ME] === "yes" ? (
                            <SentMessageContent
                                message={message}
                                messageKeys={messageKeys}
                                onDownload={onDownload}
                            />
                        ) : (
                            <ReceivedMessageContent
                                message={message}
                                messageKeys={messageKeys}
                                onDownload={onDownload}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

ConversationMessage.propTypes = {
    conversation: PropTypes.object,
    messageKeys: PropTypes.object,
    onDownload: PropTypes.func,
};

ConversationMessage.defaultProps = {
    conversation: null,
    messageKeys: null,
    onDownload: () => {},
};

export default ConversationMessage;
