import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { getCompanyAllFmUsers } from "../../redux/actions/companies";
import { getClientUserLocalState } from "../../redux/actions/clientUsers";
import {
    getTicketChat,
    getTicketLocalState,
    sendTicketMessage,
    updateTicketPriority,
    updateTicketStatus,
} from "../../redux/actions/tickets";
import {
    getTicketPriorityList,
    getTicketStatusList,
} from "../../utils/appHelpers";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    CLIENT_USER_KEYS,
    COMPANY_KEYS,
    FM_USER_KEYS,
    TICKET_KEYS,
    TICKET_CHAT_MESSAGES_KEYS,
    TICKET_OPEN_STATUS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import {
    checkChatFilesValid,
    checkTicketPriorityValid,
    checkTicketStatusValid,
} from "../../utils/validationHelpers";
import AssignFmUsersDialog from "./AssignFmUsersDialog";
import ChatSection from "../chatSection/ChatSection";
import TicketDescriptionDialog from "./TicketDescriptionDialog";
import TicketHeader from "./TicketHeader";
import TicketSidebar from "./TicketSidebar";

const pageName = "Support Ticket";

const priorityList = getTicketPriorityList();
const statusList = getTicketStatusList();

const INIT_CHAT_STATE = {
    chatFetched: false,
    fetchingChat: false,
    firstLoad: true,
    messages: [],
    refresh: false,
    sendingMsg: false,
};

const chatReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, chatFetched: false, fetchingChat: true };
        } else if (action.type === "REFRESH") {
            return {
                ...state,
                chatFetched: false,
                fetchingChat: true,
                refresh: true,
            };
        } else if (action.type === "SEND_MESSAGE") {
            return {
                ...state,
                sendingMsg: true,
            };
        } else if (action.type === "SET_DATA") {
            return {
                ...state,
                chatFetched: true,
                messages: action.messages,
                refresh: false,
                sendingMsg: false,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchingChat: false, firstLoad: false };
        } else if (action.type === "STOP_SEND_MESSAGE") {
            return {
                ...state,
                sendingMsg: false,
            };
        }
    }

    return INIT_CHAT_STATE;
};

const INIT_CHAT_MSG_STATE = {
    message: "",
    messageErrors: [],
    messageFiles: [],
    messageFilesErrors: [],
    messageFilesErrorMsg: "",
};

const chatMsgReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "REMOVE_MESSAGE_FILE") {
            const idx = action.idx;
            const currentMsgFiles = state.messageFiles;
            const updatedMsgFiles = currentMsgFiles.filter(
                (temp, tempIdx) => idx !== tempIdx
            );

            return {
                ...state,
                messageFiles: updatedMsgFiles,
            };
        } else if (action.type === "REMOVE_MESSAGE_FILE_ERROR") {
            const idx = action.idx;
            const currentMsgFilesErrors = state.messageFilesErrors;
            const updatedMsgFilesErrors = currentMsgFilesErrors.filter(
                (temp, tempIdx) => idx !== tempIdx
            );
            const updatedMsgFilesErrorMsg =
                updatedMsgFilesErrors.length > 0
                    ? state.messageFilesErrorMsg
                    : "";

            return {
                ...state,
                messageFilesErrors: updatedMsgFilesErrors,
                messageFilesErrorMsg: updatedMsgFilesErrorMsg,
            };
        } else if (action.type === "RESET_MESSAGE") {
            let updatedMsgErrors = [];
            const errors = action?.errors || null;
            if (errors) {
                for (const fieldName in errors) {
                    updatedMsgErrors.push(errors[fieldName]);
                }
            }

            return {
                ...state,
                message: "",
                messageErrors: updatedMsgErrors,
                messageFiles: [],
                messageFilesErrors: [],
                messageFilesErrorMsg: "",
            };
        } else if (action.type === "SET_MESSAGE") {
            return { ...state, message: action.message, messageErrors: [] };
        } else if (action.type === "SET_MESSAGE_ERRORS") {
            return {
                ...state,
                messageErrors: action.messageErrors,
            };
        } else if (action.type === "SET_MESSAGE_FILES") {
            let updatedMsgFiles = state.messageFiles,
                updatedMsgFilesErrors = state.messageFilesErrors,
                updatedMsgFilesErrorMsg = state.messageFilesErrorMsg;

            const uploadedFiles = action.files;
            const filesValidationResult = checkChatFilesValid(uploadedFiles);
            if (filesValidationResult.status) {
                if (filesValidationResult.status === "false") {
                    updatedMsgFilesErrorMsg =
                        filesValidationResult.msg || updatedMsgFilesErrorMsg;

                    const results = filesValidationResult.results;
                    if (results && results.length > 0) {
                        for (let i = 0; i < results.length; i++) {
                            const result = results[i];
                            if (result.errors.length > 0) {
                                updatedMsgFilesErrors.push(result);
                            } else {
                                updatedMsgFiles.push(result.file);
                            }
                        }
                    }
                } else {
                    updatedMsgFiles = [...updatedMsgFiles, ...uploadedFiles];
                }
            } else {
                updatedMsgFilesErrorMsg = VALIDATION_FAILED;
            }

            return {
                ...state,
                messageFiles: updatedMsgFiles,
                messageFilesErrors: updatedMsgFilesErrors,
                messageFilesErrorMsg: updatedMsgFilesErrorMsg,
            };
        }
    }

    return INIT_CHAT_MSG_STATE;
};

const Ticket = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isMountedTicket = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const Swal = useSwalWrapper();
    const theme = useTheme();

    const supportTicketId = params.supportTicketId;

    const [isLoading, setIsLoading] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [statusValues, setStatusValues] = useState([]);
    const [ticketFetching, setTicketFetching] = useState(false);
    const [ticketFetched, setTicketFetched] = useState(false);
    const [ticketIsValid, setTicketIsValid] = useState(false);
    const [ticketClientId, setTicketClientId] = useState("");
    const [ticketCompanyId, setTicketCompanyId] = useState("");
    const [allFmUsers, setAllFmUsers] = useState([]);
    const [ticketFmUsers, setTicketFmUsers] = useState([]);
    const [ticketFmUsersValid, setTicketFmUsersValid] = useState(false);
    const [ticketClientUser, setTicketClientUser] = useState(null);
    const [ticketClientUserValid, setTicketClientUserValid] = useState(false);
    const [priorityError, setPriorityError] = useState("");
    const [statusError, setStatusError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const [openAssignFmUsersDialog, setOpenAssignFmUsersDialog] =
        useState(false);
    const [openTicketDescriptionDialog, setOpenTicketDescriptionDialog] =
        useState(false);

    const [chatState, dispatchChatAction] = useReducer(
        chatReducer,
        INIT_CHAT_STATE
    );

    const [chatMsgState, dispatchChatMsgAction] = useReducer(
        chatMsgReducer,
        INIT_CHAT_MSG_STATE
    );

    const { message, messageFiles } = chatMsgState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    const canAssignFmUsers = isAdminUser;
    const canFetchClient = isAdminUser || isFmUser;
    const canReadDescription = /* isAdminUser || isFmUser || isClientUser */ false;
    const canSendMessage = isAdminUser || isClientUser || isFmUser;
    const canUpdatePriority = isAdminUser || isFmUser;
    const canUpdateStatus = isAdminUser || isFmUser;
    const canViewCompany = isAdminUser || isFmUser;
    const canViewFmUsers = isAdminUser || isClientUser;
    const canViewFmUserDetails = isAdminUser;
    const canViewPriority = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedTicket.current = false;
        };
    }, []);

    /* Set values of ticket statuses in a state variable */
    useEffect(() => {
        if (statusList && statusList.length > 0) {
            setStatusValues(statusList.map((statusObj) => statusObj.value));
        }
    }, []);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch ticket's details */
    useEffect(() => {
        if (isLoading) {
            setTicketFetching(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (ticketFetching) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getTicketLocalState(payload, (fetchedTicket) => {
                            if (isActive) {
                                setTicketFetching(false);
                                setTicketFetched(true);
                                setCurrentTicket(fetchedTicket || null);
                            }
                        })
                    );
                };
            };

            const ticketData = {
                authcode: authToken,
                [TICKET_KEYS.TICKET_ID]: supportTicketId,
            };

            const promise = dispatch(fetchData({ ticketData: ticketData }));
            promise.catch((error) => {
                if (isActive) {
                    setTicketFetching(false);
                    setTicketFetched(false);
                    setCurrentTicket(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, supportTicketId, ticketFetching, ticketFetched]);

    /* Check if the fetched ticket is valid */
    useEffect(() => {
        if (
            currentTicket &&
            currentTicket?.[TICKET_KEYS.ID] &&
            currentTicket[TICKET_KEYS.ID]
        ) {
            setTicketIsValid(true);
        }
    }, [currentTicket]);

    /* Set company & client ID of ticket */
    useEffect(() => {
        if (ticketIsValid) {
            setTicketClientId(currentTicket[TICKET_KEYS.CLIENT_ID]);
            setTicketCompanyId(currentTicket[TICKET_KEYS.COMPANY_ID]);
        }
    }, [currentTicket, ticketIsValid]);

    /* Fetch ticket's chat */
    useEffect(() => {
        /**
         * NOTE:
         * Do not remove isLoading otherwise every time the ticket is updated,
         * like on assigning FM Users, updating status/priority etc., the
         * chat will be fetched again
         */
        if (isLoading && ticketIsValid) {
            dispatchChatAction({ type: "FETCH" });
        }
    }, [isLoading, ticketIsValid]);

    useEffect(() => {
        let isActive = true;

        if (chatState.fetchingChat) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getTicketChat(payload, (chatMsg) => {
                            if (isActive) {
                                dispatchChatAction({ type: "STOP_FETCH" });
                                dispatchChatAction({
                                    type: "SET_DATA",
                                    messages: chatMsg || [],
                                });
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const ticketData = {
                authcode: authToken,
                [TICKET_CHAT_MESSAGES_KEYS.TICKET_ID]:
                    currentTicket[TICKET_KEYS.ID],
            };
            const promise = dispatch(fetchData({ ticketData: ticketData }));
            promise.catch((error) => {
                if (isActive) {
                    dispatchChatAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, chatState, currentTicket]);

    /* Fetch all FM Users of the fetched ticket's company */
    useEffect(() => {
        let isActive = true;

        if (ticketCompanyId) {
            if (canAssignFmUsers || canViewFmUsers) {
                const fetchData = (payload) => {
                    return (dispatch, getState) => {
                        return dispatch(
                            getCompanyAllFmUsers(payload, (fetchedFmUsers) => {
                                if (isActive) {
                                    setAllFmUsers(fetchedFmUsers || []);
                                }
                            })
                        );
                    };
                };

                const companyData = {
                    authcode: authToken,
                    [COMPANY_KEYS.COMPANY_ID]: ticketCompanyId,
                };
                const promise = dispatch(
                    fetchData({
                        companyData: companyData,
                    })
                );
                promise.catch((error) => {
                    if (isActive) {
                        setAllFmUsers([]);
                    }
                });
            } else {
                setTicketFmUsersValid(true);
            }
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        canAssignFmUsers,
        canViewFmUsers,
        ticketCompanyId,
    ]);

    /* Check if the FM Users assigned to ticket are present in company's FM Users list */
    useEffect(() => {
        if (allFmUsers && allFmUsers.length > 0) {
            const ticketFmUsersIds = currentTicket[TICKET_KEYS.FM_USERS_IDS];
            const ticketFmUsersIdsArray = ticketFmUsersIds
                ? ticketFmUsersIds.split(",")
                : [];
            if (ticketFmUsersIdsArray.length > 0) {
                const ticketFmUsers = allFmUsers.filter((tempFmUser) =>
                    ticketFmUsersIdsArray.includes(tempFmUser[FM_USER_KEYS.ID])
                );
                if (ticketFmUsers.length === ticketFmUsersIdsArray.length) {
                    setTicketFmUsersValid(true);
                }
            }
        }
    }, [allFmUsers, currentTicket]);

    /* Set FM Users currently assigned to ticket */
    useEffect(() => {
        if (ticketFmUsersValid) {
            const ticketFmUsersIds = currentTicket[TICKET_KEYS.FM_USERS_IDS];
            const ticketFmUsersIdsArray = ticketFmUsersIds.split(",");
            const ticketFmUsers = allFmUsers.filter((tempFmUser) =>
                ticketFmUsersIdsArray.includes(tempFmUser[FM_USER_KEYS.ID])
            );
            setTicketFmUsers([...ticketFmUsers]);
        }
    }, [allFmUsers, currentTicket, ticketFmUsersValid]);

    /* Fetch ticket's client's details */
    useEffect(() => {
        let isActive = true;

        if (ticketClientId) {
            if (canFetchClient) {
                const fetchData = (payload) => {
                    return (dispatch, getState) => {
                        return dispatch(
                            getClientUserLocalState(
                                payload,
                                (fetchedClientUser) => {
                                    if (isActive) {
                                        setTicketClientUser(
                                            fetchedClientUser || null
                                        );
                                    }
                                }
                            )
                        );
                    };
                };

                const clientUserData = {
                    authcode: authToken,
                    [CLIENT_USER_KEYS.USER_ID]: ticketClientId,
                };

                const promise = dispatch(
                    fetchData({
                        clientUserData: clientUserData,
                        authUserType: authUserType,
                        fetchStart: true,
                    })
                );
                promise.catch((error) => {
                    if (isActive) {
                        setTicketClientUser(null);
                    }
                });
            } else {
                setTicketClientUserValid(true);
            }
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserType, canFetchClient, ticketClientId]);

    /* Check if client user is valid or not */
    useEffect(() => {
        if (
            ticketClientUser &&
            ticketClientUser?.[CLIENT_USER_KEYS.ID] &&
            ticketClientUser[CLIENT_USER_KEYS.ID]
        ) {
            setTicketClientUserValid(true);
        }
    }, [ticketClientUser]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case TICKET_KEYS.PRIORITY:
                        setPriorityError(msg);
                        break;

                    case TICKET_KEYS.STATUS:
                        setStatusError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const handleReadTicketDescription = (ticket) => {
        if (canReadDescription) {
            setOpenTicketDescriptionDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseTicketDescriptionDialog = () => {
        setOpenTicketDescriptionDialog(false);
    };

    const handleOpenAssignFmUsersDialog = () => {
        if (canAssignFmUsers) {
            setOpenAssignFmUsersDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAssignFmUsersDialog = () => {
        setOpenAssignFmUsersDialog(false);
    };

    const handleTicketPriorityChange = (event) => {
        if (canUpdatePriority) {
            const updatedTicketPriority = event.target.value;
            setPriorityError("");
            let formIsValid = true;
            const priorityValidationResult = checkTicketPriorityValid(
                updatedTicketPriority,
                priorityList
            );
            if (priorityValidationResult.status) {
                if (priorityValidationResult.status === "false") {
                    formIsValid = false;
                    setPriorityError(priorityValidationResult.msg);
                } else if (!authUserType) {
                    formIsValid = false;
                    setPriorityError(AUTHORIZATION_ERROR);
                } else if (!currentTicket || !currentTicket[TICKET_KEYS.ID]) {
                    formIsValid = false;
                    setPriorityError("Invalid ticket");
                }
            } else {
                formIsValid = false;
                setPriorityError(VALIDATION_FAILED);
            }

            if (formIsValid) {
                const ticketData = {
                    authcode: authToken,
                    [TICKET_KEYS.TICKET_ID]: currentTicket[TICKET_KEYS.ID],
                    [TICKET_KEYS.PRIORITY]: updatedTicketPriority,
                };

                dispatch(
                    updateTicketPriority(
                        { ticketData: ticketData, showAlert: true },
                        (updatedTicket) => {
                            if (_isMountedTicket.current) {
                                setCurrentTicket({ ...updatedTicket } || null);
                            }
                        },
                        (messages) => {
                            if (_isMountedTicket.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleTicketStatusChange = (event) => {
        if (canUpdateStatus) {
            const updatedTicketStatus = event.target.value;
            setStatusError("");
            let formIsValid = true;
            const statusValidationResult = checkTicketStatusValid(
                updatedTicketStatus,
                statusValues
            );
            if (statusValidationResult.status) {
                if (statusValidationResult.status === "false") {
                    formIsValid = false;
                    setStatusError(statusValidationResult.msg);
                } else if (!authUserType) {
                    formIsValid = false;
                    setStatusError(AUTHORIZATION_ERROR);
                } else if (!currentTicket || !currentTicket[TICKET_KEYS.ID]) {
                    formIsValid = false;
                    setStatusError("Invalid ticket");
                }
            } else {
                formIsValid = false;
                setStatusError(VALIDATION_FAILED);
            }

            if (formIsValid) {
                const ticketData = {
                    authcode: authToken,
                    [TICKET_KEYS.TICKET_ID]: currentTicket[TICKET_KEYS.ID],
                    [TICKET_KEYS.STATUS]: updatedTicketStatus,
                };

                dispatch(
                    updateTicketStatus(
                        { ticketData: ticketData, showAlert: true },
                        (updatedTicket) => {
                            if (_isMountedTicket.current) {
                                setCurrentTicket({ ...updatedTicket } || null);
                            }
                        },
                        (messages) => {
                            if (_isMountedTicket.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleTicketUpdate = (updatedTicket) => {
        setCurrentTicket({ ...updatedTicket });
    };

    const handleSetMessage = (msg) =>
        dispatchChatMsgAction({ type: "SET_MESSAGE", message: msg || "" });

    const handleSetMessageFile = (event) => {
        dispatchChatMsgAction({
            type: "SET_MESSAGE_FILES",
            files: event.target.files,
        });
    };

    const handleRemoveMessageFile = (fileIdx) => {
        dispatchChatMsgAction({
            type: "REMOVE_MESSAGE_FILE",
            idx: fileIdx,
        });
    };

    const handleRemoveMessageFileError = (fileIdx) => {
        dispatchChatMsgAction({
            type: "REMOVE_MESSAGE_FILE_ERROR",
            idx: fileIdx,
        });
    };

    /* const handleSendMessage = (event) => {
        const message = event.target.value.trim();
        if (authUserType && currentTicket) {
            const ticketStatus = currentTicket?.[TICKET_KEYS.STATUS];
            if (ticketStatus === TICKET_OPEN_STATUS) {
                if (event.key === "Enter" && message) {
                    const ticketData = {
                        authcode: authToken,
                        [TICKET_CHAT_MESSAGES_KEYS.TICKET_ID]:
                            currentTicket[TICKET_KEYS.ID],
                        [TICKET_CHAT_MESSAGES_KEYS.MESSAGE]: message,
                    };

                    dispatch(
                        sendTicketMessage(
                            { ticketData: ticketData },
                            () => {
                                if (_isMountedTicket.current) {
                                    dispatchChatMsgAction({ type: "RESET_MESSAGE" });
                                    dispatchChatAction({ type: "REFRESH" })
                                }
                            },
                            (messages) => {
                                if (_isMountedTicket.current) {
                                    dispatchChatMsgAction({ type: "RESET_MESSAGE", errors: messages || "" });
                                }
                            }
                        )
                    );
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ticket is not Open!",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    }; */

    const handleSendMessage = () => {
        if (authUserType && currentTicket) {
            const ticketStatus = currentTicket?.[TICKET_KEYS.STATUS];
            if (ticketStatus === TICKET_OPEN_STATUS) {
                const updatedMessage = message ? message.trim() : "";
                const updatedMessageFiles = messageFiles ? messageFiles : [];
                if (updatedMessage && updatedMessage.length > 0) {
                    dispatchChatAction({ type: "SEND_MESSAGE" });
                    const ticketData = new FormData();
                    ticketData.append("authcode", authToken);
                    ticketData.append(
                        [TICKET_CHAT_MESSAGES_KEYS.TICKET_ID],
                        currentTicket[TICKET_KEYS.ID]
                    );
                    ticketData.append(
                        [TICKET_CHAT_MESSAGES_KEYS.MESSAGE],
                        updatedMessage
                    );
                    if (updatedMessageFiles.length > 0) {
                        for (let i = 0; i < updatedMessageFiles.length; i++) {
                            ticketData.append(
                                [TICKET_CHAT_MESSAGES_KEYS.MESSAGE_FILES_ARRAY],
                                updatedMessageFiles[i]
                            );
                        }
                    } else {
                        ticketData.append(
                            [TICKET_CHAT_MESSAGES_KEYS.MESSAGE_FILES_ARRAY],
                            null
                        );
                    }

                    dispatch(
                        sendTicketMessage(
                            { ticketData: ticketData },
                            () => {
                                if (_isMountedTicket.current) {
                                    dispatchChatMsgAction({
                                        type: "RESET_MESSAGE",
                                    });
                                    dispatchChatAction({ type: "REFRESH" });
                                }
                            },
                            (messages) => {
                                if (_isMountedTicket.current) {
                                    dispatchChatMsgAction({
                                        type: "RESET_MESSAGE",
                                        errors: messages || "",
                                    });
                                    dispatchChatAction({
                                        type: "STOP_SEND_MESSAGE",
                                    });
                                }
                            }
                        )
                    );
                } else if (
                    updatedMessageFiles &&
                    updatedMessageFiles.length > 0
                ) {
                    dispatchChatMsgAction({
                        type: "SET_MESSAGE_ERRORS",
                        messageErrors: ["Message is required"],
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ticket is not Open!",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const ticketIsOpen = useMemo(() => {
        if (currentTicket && currentTicket?.[TICKET_KEYS.STATUS]) {
            return currentTicket[TICKET_KEYS.STATUS] === TICKET_OPEN_STATUS;
        } else {
            return false;
        }
    }, [currentTicket]);

    const ticketFetchedSuccess = !ticketFetching && ticketFetched;
    const ticketFetchError = !ticketFetching && !ticketFetched;
    const fetchedDataValid =
        ticketIsValid && ticketClientUserValid && ticketFmUsersValid;

    const loadError =
        !isValidUserType ||
        ticketFetchError ||
        !ticketIsValid ||
        !ticketClientUserValid ||
        !ticketFmUsersValid;

    return (
        <React.Fragment>
            {!isLoading && loadError && (
                <Grid container spacing={3.5}>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && ticketFetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the ticket data!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType &&
                        !ticketFetchError &&
                        !fetchedDataValid && (
                            <React.Fragment>
                                {!ticketIsValid && (
                                    <Grid item xs={12}>
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                        >
                                            Ticket data is not valid!
                                        </Alert>
                                    </Grid>
                                )}
                                {ticketIsValid && !ticketClientUserValid && (
                                    <Grid item xs={12}>
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                        >
                                            Ticket client user is not valid!
                                        </Alert>
                                    </Grid>
                                )}
                                {ticketIsValid && !ticketFmUsersValid && (
                                    <Grid item xs={12}>
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                        >
                                            One or more user(s) is not valid!
                                        </Alert>
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                </Grid>
            )}
            {!isLoading && !loadError && ticketFetchedSuccess && (
                <React.Fragment>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12}>
                            <TicketHeader
                                canAssignFmUsers={canAssignFmUsers}
                                canReadDescription={canReadDescription}
                                canUpdatePriority={canUpdatePriority}
                                canUpdateStatus={canUpdateStatus}
                                canViewPriority={canViewPriority}
                                currentTicket={currentTicket}
                                miscellaneousErrors={miscellaneousErrors}
                                priorityError={priorityError}
                                statusError={statusError}
                                ticketClientUser={ticketClientUser}
                                onAssignFmUsers={handleOpenAssignFmUsersDialog}
                                onPriorityChange={handleTicketPriorityChange}
                                onReadDescription={handleReadTicketDescription}
                                onStatusChange={handleTicketStatusChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack
                                direction={{
                                    md: "row",
                                    xs: "column-reverse",
                                }}
                                spacing={2}
                            >
                                <Box
                                    sx={{
                                        flex: "1 1 70%",
                                        [theme.breakpoints.down("md")]: {
                                            flex: "1 1 100%",
                                        },
                                    }}
                                >
                                    <ChatSection
                                        canSendMessage={
                                            canSendMessage && ticketIsOpen
                                        }
                                        chatBox={{
                                            sx: {
                                                height: "72vh",
                                                border: (theme) =>
                                                    `2px solid ${theme.palette.secondary.main}`,
                                                borderRadius: "8px",
                                            },
                                        }}
                                        chatFetched={chatState.chatFetched}
                                        errorMessages={
                                            chatMsgState.messageErrors
                                        }
                                        fetchingChat={chatState.fetchingChat}
                                        firstLoad={chatState.firstLoad}
                                        message={message}
                                        messages={chatState.messages}
                                        messageFiles={messageFiles}
                                        messageFilesErrors={
                                            chatMsgState.messageFilesErrors
                                        }
                                        messageFilesErrorMsg={
                                            chatMsgState.messageFilesErrorMsg
                                        }
                                        messageKeys={TICKET_CHAT_MESSAGES_KEYS}
                                        sendingMsg={chatState.sendingMsg}
                                        sendRestrictionMsg={
                                            ticketIsOpen
                                                ? ""
                                                : "The ticket is closed!"
                                        }
                                        onRemoveMessageFile={
                                            handleRemoveMessageFile
                                        }
                                        onRemoveMessageFileError={
                                            handleRemoveMessageFileError
                                        }
                                        onSendMessage={handleSendMessage}
                                        onSetMessage={handleSetMessage}
                                        onSetMessageFiles={handleSetMessageFile}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        flex: "1 1 30%",
                                        [theme.breakpoints.down("md")]: {
                                            flex: "1 1 100%",
                                        },
                                    }}
                                >
                                    <TicketSidebar
                                        canFetchClient={canFetchClient}
                                        canViewCompany={canViewCompany}
                                        canViewFmUsers={canViewFmUsers}
                                        canViewFmUserDetails={
                                            canViewFmUserDetails
                                        }
                                        currentTicket={currentTicket}
                                        ticketClientUser={ticketClientUser}
                                        ticketFmUsers={ticketFmUsers}
                                    />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                    <React.Fragment>
                        {canAssignFmUsers && openAssignFmUsersDialog && (
                            <AssignFmUsersDialog
                                currentTicket={currentTicket}
                                open={openAssignFmUsersDialog}
                                setLocalState={true}
                                onAssign={handleTicketUpdate}
                                onClose={handleCloseAssignFmUsersDialog}
                            />
                        )}
                        {canReadDescription && openTicketDescriptionDialog && (
                            <TicketDescriptionDialog
                                currentTicket={currentTicket}
                                open={openTicketDescriptionDialog}
                                onClose={handleCloseTicketDescriptionDialog}
                            />
                        )}
                    </React.Fragment>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Ticket;
