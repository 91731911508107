import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div";
import { deleteWorkOrderDocuments } from "../../redux/actions/assignedServices";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { formatBytes } from "../../utils/appHelpers";
import {
    ASSIGNED_SERVICE_KEYS,
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
    WORK_ORDER_DOCUMENT_KEYS,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkWODeletedDocumentsValid } from "../../utils/validationHelpers";

const ViewWorkOrderDocumentDialog = ({
    currentAssignedService,
    open,
    setGlobalState,
    setLocalState,
    onClose,
    onDispatchAction,
}) => {
    const _isViewWODocDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [assignedServiceValid, setAssignedServiceValid] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentsError, setDocumentsError] = useState("");
    const [deletedDocuments, setDeletedDocuments] = useState([]);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserId =
        authUser && authUser?.[AUTH_USER_KEYS.ID] && authUser[AUTH_USER_KEYS.ID]
            ? authUser[AUTH_USER_KEYS.ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canUserDeleteDocument = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isViewWODocDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAssignedServiceValid(false);
            setDocuments([]);
            setDocumentsError("");
            setDeletedDocuments([]);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (isValidUserType) {
            if (
                currentAssignedService &&
                currentAssignedService?.[ASSIGNED_SERVICE_KEYS.ID] &&
                currentAssignedService[ASSIGNED_SERVICE_KEYS.ID]
            ) {
                setAssignedServiceValid(true);
            }
        }
    }, [currentAssignedService, isValidUserType]);

    useEffect(() => {
        if (assignedServiceValid) {
            setDocuments(
                currentAssignedService[ASSIGNED_SERVICE_KEYS.DOCUMENTS]
            );
            setDeletedDocuments([]);
        }
    }, [assignedServiceValid, currentAssignedService]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case ASSIGNED_SERVICE_KEYS.DOCUMENTS:
                        setDocumentsError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const handleDeleteWorkOrder = (deletedDocument) => {
        setDocumentsError("");
        setDeletedDocuments((prevState) => [
            ...prevState,
            deletedDocument[WORK_ORDER_DOCUMENT_KEYS.ID],
        ]);
        setDocuments((prevState) =>
            prevState.filter((tempDocument) => {
                return (
                    tempDocument[WORK_ORDER_DOCUMENT_KEYS.ID] !==
                    deletedDocument[WORK_ORDER_DOCUMENT_KEYS.ID]
                );
            })
        );
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const deletedDocsValidationResult =
            checkWODeletedDocumentsValid(deletedDocuments);
        if (deletedDocsValidationResult.status) {
            if (deletedDocsValidationResult.status === "false") {
                formIsValid = false;
                setDocumentsError(deletedDocsValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDocumentsError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const assignedServiceData = {
                authcode: authToken,
                [ASSIGNED_SERVICE_KEYS.ASSIGNED_SERVICE_ID]:
                    currentAssignedService[ASSIGNED_SERVICE_KEYS.ID],
                [ASSIGNED_SERVICE_KEYS.DELETED_DOCUMENTS]:
                    deletedDocuments.join(","),
            };

            dispatch(
                deleteWorkOrderDocuments(
                    {
                        assignedServiceData: assignedServiceData,
                        setGlobalState: setGlobalState,
                    },
                    (updatedAssignedService) => {
                        if (_isViewWODocDialogMounted.current) {
                            if (setLocalState) {
                                onDispatchAction({
                                    type: "UPDATE",
                                    updatedService: updatedAssignedService,
                                });
                            }
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isViewWODocDialogMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const errorOnLoading = !isValidUserType || !assignedServiceValid;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title="View Work Order Documents"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {errorOnLoading && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !assignedServiceValid && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    Invalid service!
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!errorOnLoading && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            {currentAssignedService[
                                ASSIGNED_SERVICE_KEYS.WORK_ORDER
                            ] && (
                                <Grid item xs={12}>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="p"
                                        sx={{
                                            fontWeight: (theme) =>
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {
                                            currentAssignedService[
                                                ASSIGNED_SERVICE_KEYS.WORK_ORDER
                                            ]
                                        }
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Div sx={{ flex: 1 }}>
                                    {documents.length > 0 && (
                                        <List
                                            disablePadding
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {documents.map((document) => {
                                                const uploaderId =
                                                    document[
                                                        WORK_ORDER_DOCUMENT_KEYS
                                                            .UPLOADED_BY
                                                    ];
                                                const canDeleteDocument =
                                                    authUserId &&
                                                    uploaderId &&
                                                    (isAdminUser ||
                                                        (isFmUser &&
                                                            authUserId ===
                                                                uploaderId));

                                                return (
                                                    <ListItem
                                                        disableGutters
                                                        key={
                                                            document[
                                                                WORK_ORDER_DOCUMENT_KEYS
                                                                    .ID
                                                            ]
                                                        }
                                                        component={Link}
                                                        href={
                                                            document[
                                                                WORK_ORDER_DOCUMENT_KEYS
                                                                    .LINK
                                                            ]
                                                        }
                                                        target="_blank"
                                                        rel="noopener"
                                                        underline="hover"
                                                        secondaryAction={
                                                            canDeleteDocument && (
                                                                <TableActionIcon
                                                                    iconComponent="delete"
                                                                    label="delete document"
                                                                    tooltip="Delete Document"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        handleDeleteWorkOrder(
                                                                            document
                                                                        );
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                        sx={{
                                                            width: "auto",
                                                            mr: 1,
                                                            flex: "1 1 100%",
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={`${
                                                                document[
                                                                    WORK_ORDER_DOCUMENT_KEYS
                                                                        .ORIGINAL_FILE_NAME
                                                                ]
                                                            } - ${formatBytes(
                                                                document[
                                                                    WORK_ORDER_DOCUMENT_KEYS
                                                                        .SIZE
                                                                ]
                                                            )}`}
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    )}
                                    {documents.length === 0 && (
                                        <Alert severity="info">
                                            No documents found!
                                        </Alert>
                                    )}
                                    {documentsError && (
                                        <Typography
                                            component="p"
                                            variant="caption"
                                            color="error.main"
                                        >
                                            {documentsError}
                                        </Typography>
                                    )}
                                </Div>
                            </Grid>
                            {canUserDeleteDocument && (
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

ViewWorkOrderDocumentDialog.propTypes = {
    currentAssignedService: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    setLocalState: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

ViewWorkOrderDocumentDialog.defaultProps = {
    currentAssignedService: null,
    open: false,
    setGlobalState: false,
    setLocalState: false,
    onDispatchAction: () => {},
};

export default ViewWorkOrderDocumentDialog;
