import basicAuthAxios from "../../services/auth/basicAuth";
import basicFileAuthAxios from "../../services/auth/basicFileAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    ADD_CLIENT_USER,
    DELETE_CLIENT_USER,
    EDIT_CLIENT_USER,
    GET_CLIENT_USERS,
    GET_CLIENT_USER_COUNT,
    SET_CLIENT_USER_DETAILS,
} from "../../utils/constants/actionTypes";
import {
    ADD_CLIENT_USER_LINK,
    ARCHIVE_CLIENT_USER_LINK,
    DELETE_CLIENT_USER_LINK,
    FM_ADD_CLIENT_USER_LINK,
    FM_ARCHIVE_CLIENT_USER_LINK,
    FM_DELETE_CLIENT_USER_LINK,
    FM_GET_CLIENT_USER_LINK,
    FM_GET_CLIENT_USERS_LINK,
    /* FM_GET_ALL_CLIENT_USERS_LINK, */
    /* FM_GET_ALL_CLIENT_USERS_LINK_2, */
    FM_RESTORE_CLIENT_USER_LINK,
    FM_SEND_MSG_CLIENT_USER_LINK,
    FM_UPDATE_CLIENT_USER_LINK,
    /* FM_UPDATE_CLIENT_USER_STATUS_LINK, */
    GET_CLIENT_USER_PROFILE_LINK,
    GET_CLIENT_USER_SHEET_LINK,
    GET_CLIENT_USER_LINK,
    GET_CLIENT_USERS_LINK,
    /* GET_ALL_CLIENT_USERS_LINK, */
    /* GET_ALL_FM_CLIENT_USERS_LINK, */
    RESTORE_CLIENT_USER_LINK,
    SEND_MSG_CLIENT_USER_LINK,
    UPDATE_CLIENT_USER_LINK,
    UPDATE_CLIENT_USER_PROFILE_LINK,
    /* UPDATE_CLIENT_USER_STATUS_LINK, */
} from "../../utils/constants/apiLinks";
import {
    /* CLIENT_USER_ACTIVE_STATUS, */
    /* CLIENT_USER_INACTIVE_STATUS, */
    CLIENT_USER_KEYS,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addClientUser = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_ADD_CLIENT_USER_LINK;
                break;

            default:
                apiLink = ADD_CLIENT_USER_LINK;
                break;
        }
        /* console.log("addClientUser: " + apiLink); */
        const body = request.clientUserData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(apiLink, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.client) {
                            /* dispatch(
                                fetchSuccess("Client was added successfully.")
                            ); */
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: ADD_CLIENT_USER,
                                    payload: data.client,
                                });
                            }
                            if (callbackFun) callbackFun(data.client);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredClientUsers = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_GET_CLIENT_USERS_LINK;
                break;

            default:
                apiLink = GET_CLIENT_USERS_LINK;
                break;
        }
        /* console.log("getFilteredClientUsers: " + apiLink); */
        const body = request.clientUserData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.clients) {
                            const clients = data.clients;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [CLIENT_USER_KEYS.TOTAL]: count[
                                        CLIENT_USER_KEYS.TOTAL
                                    ]
                                        ? count[CLIENT_USER_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [CLIENT_USER_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.archived) {
                                    successMsg =
                                        "Selected client was archived successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected client was deleted successfully";
                                } else if (fromAction.inserted) {
                                    successMsg =
                                        "Client was added successfully";
                                } else if (fromAction.restored) {
                                    successMsg =
                                        "Selected client was restored successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: GET_CLIENT_USERS,
                                payload: clients,
                            });
                            dispatch({
                                type: GET_CLIENT_USER_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(clients);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_CLIENT_USERS, payload: [] });
                dispatch({
                    type: GET_CLIENT_USER_COUNT,
                    payload: {
                        [CLIENT_USER_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

export const getFilteredClientUsersLocal = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_GET_CLIENT_USERS_LINK;
                break;

            default:
                apiLink = GET_CLIENT_USERS_LINK;
                break;
        }
        /* console.log("getFilteredClientUsersLocal: " + apiLink); */
        const body = request.clientUserData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.clients) {
                            const clients = data.clients;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [CLIENT_USER_KEYS.TOTAL]: count[
                                        CLIENT_USER_KEYS.TOTAL
                                    ]
                                        ? count[CLIENT_USER_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [CLIENT_USER_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.archived) {
                                    successMsg =
                                        "Selected client was archived successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected client was deleted successfully";
                                } else if (fromAction.inserted) {
                                    successMsg =
                                        "Client was added successfully";
                                } else if (fromAction.restored) {
                                    successMsg =
                                        "Selected client was restored successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            if (callbackFun)
                                callbackFun({
                                    clients: clients,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                throw error;
            });
    };
};

/* export const getAllClientUsers = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_GET_ALL_CLIENT_USERS_LINK_2;
                break;

            default:
                apiLink = GET_ALL_CLIENT_USERS_LINK;
                break;
        }
        // console.log("getAllClientUsers: " + apiLink);
        const body = request.clientUserData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.clients) {
                            const clientUsers = data.clients;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(clientUsers);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
}; */

/* export const getAllFmClientUsers = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            default:
                apiLink = GET_ALL_FM_CLIENT_USERS_LINK;
                break;
        }
        // console.log("getAllFmClientUsers: " + apiLink);
        const body = request.clientUserData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.clients) {
                            const clientUsers = data.clients;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(clientUsers);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
}; */

export const getClientUserLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_GET_CLIENT_USER_LINK;
                break;

            default:
                apiLink = GET_CLIENT_USER_LINK;
                break;
        }
        const body = request.clientUserData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.client) {
                            const client = data.client;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(client);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getClientUserProfile = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.clientUserData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_CLIENT_USER_PROFILE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.user) {
                            const client = data.user;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(client);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const setCurrentClientUser = (clientUser) => {
    return (dispatch) => {
        dispatch({ type: SET_CLIENT_USER_DETAILS, payload: clientUser });
    };
};

export const updateClientUser = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_UPDATE_CLIENT_USER_LINK;
                break;

            default:
                apiLink = UPDATE_CLIENT_USER_LINK;
                break;
        }
        /* console.log("updateClientUser: " + apiLink); */
        const body = request.clientUserData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(apiLink, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.client) {
                            dispatch(
                                fetchSuccess(
                                    "Selected client was updated successfully."
                                )
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_CLIENT_USER,
                                    payload: data.client,
                                });
                            }
                            if (callbackFun) callbackFun(data.client);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

/* export const updateClientUserStatus = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_UPDATE_CLIENT_USER_STATUS_LINK;
                break;

            default:
                apiLink = UPDATE_CLIENT_USER_STATUS_LINK;
                break;
        }
        // console.log("updateClientUserStatus: " + apiLink);
        const body = request.clientUserData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.client) {
                            let successMsg = "Status was updated successfully.";
                            if (
                                body[CLIENT_USER_KEYS.STATUS] ===
                                CLIENT_USER_ACTIVE_STATUS
                            ) {
                                successMsg =
                                    "Client user has been activated successfully!";
                            } else if (
                                body[CLIENT_USER_KEYS.STATUS] ===
                                CLIENT_USER_INACTIVE_STATUS
                            ) {
                                successMsg =
                                    "Client user has been deactivated successfully!";
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: EDIT_CLIENT_USER,
                                payload: data.client,
                            });
                            if (callbackFun) callbackFun(data.client);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
}; */

export const updateClientUserProfile = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.clientUserData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(UPDATE_CLIENT_USER_PROFILE_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.user) {
                            dispatch(
                                fetchSuccess(
                                    "Profile was updated successfully."
                                )
                            );
                            if (callbackFun) callbackFun(data.user);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteClientUser = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_DELETE_CLIENT_USER_LINK;
                break;

            default:
                apiLink = DELETE_CLIENT_USER_LINK;
                break;
        }
        /* console.log("deleteClientUser: " + apiLink); */
        const body = request.clientUserData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected client was deleted successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_CLIENT_USER,
                            payload:
                                request.clientUserData[
                                    CLIENT_USER_KEYS.USER_ID
                                ],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

export const archiveClientUser = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_ARCHIVE_CLIENT_USER_LINK;
                break;

            default:
                apiLink = ARCHIVE_CLIENT_USER_LINK;
                break;
        }
        /* console.log("archiveClientUser: " + apiLink); */
        const body = request.clientUserData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected client was archived successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_CLIENT_USER,
                            payload:
                                request.clientUserData[
                                    CLIENT_USER_KEYS.USER_ID
                                ],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

export const restoreClientUser = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_RESTORE_CLIENT_USER_LINK;
                break;

            default:
                apiLink = RESTORE_CLIENT_USER_LINK;
                break;
        }
        /* console.log("restoreClientUser: " + apiLink); */
        const body = request.clientUserData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected client was restored successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_CLIENT_USER,
                            payload:
                                request.clientUserData[
                                    CLIENT_USER_KEYS.USER_ID
                                ],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

export const sendClientUserMsg = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const authUserType = request?.authUserType || "";
        let apiLink = "";
        switch (authUserType) {
            case USER_TYPE_AE_ID:
                apiLink = FM_SEND_MSG_CLIENT_USER_LINK;
                break;

            default:
                apiLink = SEND_MSG_CLIENT_USER_LINK;
                break;
        }
        /* console.log("sendClientUserMsg: " + apiLink); */
        const body = request.clientUserData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(apiLink, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        dispatch(fetchSuccess("Message sent successfully."));
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getClientUserSheet = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.clientUserData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_CLIENT_USER_SHEET_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.user) {
                            const client = data.user;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(client);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};
