import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import SurveyForm from "./SurveyForm";

const pageName = "Add Survey";

const AddSurvey = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isAddSurveyMounted = useRef(true);
    const navigate = useNavigate();
    const { authUser } = useJumboAuth();

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isAddSurveyMounted.current = false;
        };
    }, []);

    const handleSubmitSurvey = () => navigate("/surveys");

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle component="h1" title={pageName} type="v2" />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {isValidUserType && (
                <Grid item xs={12}>
                    <SurveyForm onAdd={handleSubmitSurvey} />
                </Grid>
            )}
            {!isValidUserType && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        {AUTHORIZATION_ERROR}
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default AddSurvey;
