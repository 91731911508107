import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const a11yProps = (userType) => {
    return {
        id: `sidebar-item-tab-${userType}`,
        "aria-controls": `sidebar-items-tabpanel-${userType}`,
    };
};

const SidebarItemsTabPanel = (props) => {
    const { children, value, userType, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== userType}
            id={`sidebar-items-tabpanel-${userType}`}
            aria-labelledby={`sidebar-items-tab-${userType}`}
            {...other}
        >
            {value === userType && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

SidebarItemsTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

export default SidebarItemsTabPanel;
