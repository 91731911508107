import {
    ADD_CASE_STUDY,
    DELETE_CASE_STUDY,
    EDIT_CASE_STUDY,
    GET_CASE_STUDY_COUNT,
    GET_CASE_STUDIES,
    SET_CASE_STUDY_DETAILS,
} from "../../utils/constants/actionTypes";
import { CASE_STUDY_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    caseStudies: [],
    currentCaseStudy: null,
    caseStudyCounts: { [CASE_STUDY_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CASE_STUDIES: {
            return {
                ...state,
                caseStudies: action.payload,
            };
        }
        case GET_CASE_STUDY_COUNT: {
            return {
                ...state,
                caseStudyCounts: action.payload,
            };
        }
        case SET_CASE_STUDY_DETAILS: {
            return {
                ...state,
                currentCaseStudy: action.payload,
            };
        }
        case ADD_CASE_STUDY: {
            return {
                ...state,
                caseStudies: [action.payload, ...state.caseStudies],
            };
        }
        case EDIT_CASE_STUDY: {
            return {
                ...state,
                caseStudies: state.caseStudies.map((caseStudy) =>
                    caseStudy.id === action.payload.id
                        ? action.payload
                        : caseStudy
                ),
            };
        }
        case DELETE_CASE_STUDY: {
            return {
                ...state,
                caseStudies: state.caseStudies.filter(
                    (caseStudy) => caseStudy.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
