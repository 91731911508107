import basicAuthAxios from "../../services/auth/basicAuth";
import basicFileAuthAxios from "../../services/auth/basicFileAuth";

import { fetchError, fetchStart, fetchSuccess } from "./common";

import {
    ADD_SPECIAL,
    DELETE_SPECIAL,
    EDIT_SPECIAL,
    GET_SPECIALS,
    GET_SPECIAL_COUNT,
    SET_SPECIAL_DETAILS,
} from "../../utils/constants/actionTypes";

import {
    ADD_SPECIAL_LINK,
    APPROVE_SPECIAL_FLYER_LINK,
    DELETE_SPECIAL_LINK,
    GET_SPECIAL_LINK,
    GET_SPECIAL_CHAT_LINK,
    GET_SPECIALS_LINK,
    GET_ALL_SPECIALS_LINK,
    SAVE_SPECIAL_FLYER_LINK,
    SEND_SPECIAL_MESSAGE_LINK,
    UPDATE_SPECIAL_LINK,
} from "../../utils/constants/apiLinks";

import { SPECIAL_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addSpecial = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(ADD_SPECIAL_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: ADD_SPECIAL,
                                    payload: data.specials,
                                });
                            } else {
                                dispatch(
                                    fetchSuccess(
                                        "Special was added successfully."
                                    )
                                );
                            }
                            if (callbackFun) callbackFun(data.specials);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredSpecials = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_SPECIALS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            const specials = data.specials;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [SPECIAL_KEYS.TOTAL]: count[
                                        SPECIAL_KEYS.TOTAL
                                    ]
                                        ? count[SPECIAL_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [SPECIAL_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Special was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected special was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: GET_SPECIALS,
                                payload: specials,
                            });
                            dispatch({
                                type: GET_SPECIAL_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(specials);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_SPECIALS, payload: [] });
                dispatch({
                    type: GET_SPECIAL_COUNT,
                    payload: {
                        [SPECIAL_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getFilteredSpecialsLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_SPECIALS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            const specials = data.specials;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [SPECIAL_KEYS.TOTAL]: count[
                                        SPECIAL_KEYS.TOTAL
                                    ]
                                        ? count[SPECIAL_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [SPECIAL_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Special was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected special was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            if (callbackFun)
                                callbackFun({
                                    specials: specials,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getAllSpecials = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_ALL_SPECIALS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            const specials = data.specials;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(specials);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getSpecial = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_SPECIAL_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            const special = data.specials;
                            dispatch(fetchSuccess());
                            dispatch({
                                type: SET_SPECIAL_DETAILS,
                                payload: special,
                            });
                            if (callbackFun) callbackFun(special);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was some issue in responding server.";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    /* dispatch({ type: SET_SPECIAL_DETAILS, payload: null }); */
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: SET_SPECIAL_DETAILS, payload: null });
                dispatch(fetchError(error.message));
                throw error;
            });
    };
};

export const getSpecialLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_SPECIAL_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            const special = data.specials;
                            if (callbackFun) callbackFun(special);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was some issue in responding server.";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch(fetchError(error.message));
                throw error;
            });
    };
};

export const setCurrentSpecial = (special) => {
    return (dispatch) => {
        dispatch({ type: SET_SPECIAL_DETAILS, payload: special });
    };
};

export const updateSpecial = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(UPDATE_SPECIAL_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            dispatch(
                                fetchSuccess(
                                    "Selected special was updated successfully."
                                )
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_SPECIAL,
                                    payload: data.specials,
                                });
                            }
                            if (callbackFun) callbackFun(data.specials);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const approveFlyer = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(APPROVE_SPECIAL_FLYER_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            const special = data.specials;
                            dispatch(
                                fetchSuccess(
                                    "Selected flyer was approved successfully."
                                )
                            );
                            dispatch({
                                type: EDIT_SPECIAL,
                                payload: special,
                            });
                            if (callbackFun) callbackFun(special);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteSpecial = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_SPECIAL_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected special was deleted successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_SPECIAL,
                            payload:
                                request.specialData[SPECIAL_KEYS.SPECIAL_ID],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};

export const saveSpecialFlyer = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.specialData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(SAVE_SPECIAL_FLYER_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.specials) {
                            dispatch(
                                fetchSuccess("Flyers were saved successfully.")
                            );
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_SPECIAL,
                                    payload: data.specials,
                                });
                            }
                            if (callbackFun) callbackFun(data.specials);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getSpecialChat = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.specialData;
        dispatch(fetchStart(false));
        return basicAuthAxios
            .post(GET_SPECIAL_CHAT_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.message) {
                            const chat = data.message;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(chat);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const sendSpecialMessage = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.specialData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        basicFileAuthAxios
            .post(SEND_SPECIAL_MESSAGE_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.message) {
                            if (data.message) {
                                dispatch(
                                    fetchSuccess(
                                        "Message was sent successfully."
                                    )
                                );
                                if (callbackFun) callbackFun(data.message);
                            } else {
                                isError = true;
                                errorMsg = "Unable to get response";
                            }
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = [];

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};
