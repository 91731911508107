import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { updateAccountAssetStatus } from "../../redux/actions/accountAssets";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    ACCOUNT_ASSET_KEYS,
    ACCOUNT_ASSET_REJECTED_STATUS_ID,
    AUTH_USER_KEYS,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkRejectionCommentsValid } from "../../utils/validationHelpers";

const RejectAccountAssetDialog = ({ open, onClose, onDispatchAction }) => {
    const _isMountedRejectAccountAssetDialog = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const { currentAccountAsset } = useSelector(
        ({ accountAssetsReducer }) => accountAssetsReducer
    );

    const [comments, setComments] = useState("");
    const [commentsError, setCommentsError] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;

    useEffect(() => {
        return () => {
            _isMountedRejectAccountAssetDialog.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setComments("");
            setCommentsError("");
            setErrorMessages([]);
            setMiscellaneousErrors([]);
        }
    }, [open]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case ACCOUNT_ASSET_KEYS.REJECT_REASON:
                        setCommentsError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const commentsBlurHandler = (event) => {
        const validationResult = checkRejectionCommentsValid(
            event.target.value
        );
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setCommentsError(validationResult.msg);
            }
        } else {
            setCommentsError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const commentsValidationResult = checkRejectionCommentsValid(comments);
        if (commentsValidationResult.status) {
            if (commentsValidationResult.status === "false") {
                formIsValid = false;
                setCommentsError(commentsValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setCommentsError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const accountAssetData = {
                authcode: authToken,
                [ACCOUNT_ASSET_KEYS.ASSET_ID]:
                    currentAccountAsset[ACCOUNT_ASSET_KEYS.ID],
                [ACCOUNT_ASSET_KEYS.STATUS]: ACCOUNT_ASSET_REJECTED_STATUS_ID,
                [ACCOUNT_ASSET_KEYS.REJECT_REASON]: comments,
            };
            dispatch(
                updateAccountAssetStatus(
                    { accountAssetData: accountAssetData },
                    () => {
                        if (_isMountedRejectAccountAssetDialog.current) {
                            onDispatchAction({ type: "REJECT" });
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isMountedRejectAccountAssetDialog.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose title="Comments" open={open} onClose={onClose} />
            <DialogContent>
                {!authUserType && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {authUserType && !isClientUser && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                {ACTION_AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {authUserType && isClientUser && !currentAccountAsset && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                Invalid Account Asset
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {authUserType && isClientUser && currentAccountAsset && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    id="comments"
                                    name="comments"
                                    label="Leave Comments"
                                    value={comments}
                                    margin="dense"
                                    onChange={(e) => {
                                        setComments(e.target.value);
                                        setCommentsError("");
                                    }}
                                    onBlur={commentsBlurHandler}
                                    error={commentsError !== ""}
                                    helperText={commentsError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

RejectAccountAssetDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

RejectAccountAssetDialog.defaultProps = {
    open: false,
    // onClose: () => {},
    // onDispatchAction: () => {},
};

export default RejectAccountAssetDialog;
