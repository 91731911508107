import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getSurveyQuestionTextResponses } from "../../redux/actions/surveys";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SURVEY_QUESTION_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";

const genericPageName = "Text Responses";

const TextQuestionResponses = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isTextQuestionResponsesMounted = useRef(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();

    const surveyId = params?.surveyId || "";
    const questionId = params?.questionId || "";

    const [isLoading, setIsLoading] = useState(false);
    const [inputIsValid, setInputIsValid] = useState(false);
    const [fetchResponses, setFetchResponses] = useState(false);
    const [responsesFetched, setResponsesFetched] = useState(false);
    const [responses, setResponses] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isTextQuestionResponsesMounted.current = false;
        };
    }, []);

    /* Check input is valid */
    useEffect(() => {
        if (isValidUserType) {
            setInputIsValid(!!questionId && !!surveyId);
        }
    }, [isValidUserType, questionId, surveyId]);

    /* Start loading */
    useEffect(() => {
        if (inputIsValid) {
            setIsLoading(true);
        }
    }, [inputIsValid]);

    /* Fetch survey */
    useEffect(() => {
        if (isLoading) {
            setFetchResponses(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchResponses) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getSurveyQuestionTextResponses(
                            payload,
                            (fetchedData) => {
                                if (isActive) {
                                    setFetchResponses(false);
                                    const fetchedResponses =
                                        fetchedData?.responses &&
                                        fetchedData.responses
                                            ? fetchedData.responses
                                            : [];
                                    if (fetchedResponses) {
                                        setResponsesFetched(true);
                                        setResponses(fetchedResponses);
                                    }
                                    setIsLoading(false);
                                }
                            }
                        )
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                [SURVEY_QUESTION_KEYS.SURVEY_ID]: surveyId,
                [SURVEY_QUESTION_KEYS.QUESTION_ID]: questionId,
            };

            const promise = dispatch(fetchData({ surveyData: surveyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchResponses(false);
                    setResponsesFetched(false);
                    setResponses([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchResponses, questionId, surveyId]);

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchResponses && !responsesFetched;
    const loadError = !isValidUserType || !inputIsValid || fetchError;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle
                        component="h1"
                        title="Question Responses"
                        type="v2"
                    />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: genericPageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !inputIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid input!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && inputIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the responses!
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <Grid item xs={12}>
                    {responses.length > 0 ? (
                        <Paper>
                            <List>
                                {responses.map((response, responseIdx) => {
                                    const textResponse =
                                        response[
                                            SURVEY_QUESTION_KEYS.TEXT_RESPONSE
                                        ];

                                    if (!textResponse) return null;

                                    return (
                                        <ListItem
                                            divider
                                            key={`response-${responseIdx}`}
                                        >
                                            <ListItemText
                                                primary={`${
                                                    responseIdx + 1
                                                }. ${textResponse}`}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Paper>
                    ) : (
                        <Alert severity="error" variant="outlined">
                            No responses found!
                        </Alert>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default TextQuestionResponses;
