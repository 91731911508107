import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import ArchiveIcon from "@mui/icons-material/Archive";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GridOnIcon from "@mui/icons-material/GridOn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoteIcon from "@mui/icons-material/Note";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import PeopleIcon from "@mui/icons-material/People";
import { styled } from "@mui/system";
import StyledActionsMenu from "../../shared/widgets/StyledActionsMenu";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    COMPANY_ACTIVE_STATUS,
    COMPANY_ARCHIVE_STATUS,
    COMPANY_KEYS,
} from "../../utils/constants/appData";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
        color: `${theme.palette.secondary.main}!important`,
    },
}));

const CompaniesActionsList = ({
    canAddEditCompany,
    canEditRenewalDate,
    canManageLocations,
    canManageNotes,
    canManageSidebar,
    canViewClientUsers,
    canViewGoogleSheet,
    company,
    type,
    onArchive,
    onDelete,
    onEdit,
    onEditRenewalDate,
    onManageLocations,
    onManageNotes,
    onManageSidebar,
    onRestore,
}) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const companyId = company[COMPANY_KEYS.ID];

    const status = company[COMPANY_KEYS.STATUS] || "";
    const isActive = status === COMPANY_ACTIVE_STATUS;
    const isArchived = status === COMPANY_ARCHIVE_STATUS;

    const actionsMenuOpen = Boolean(anchorEl);

    const handleActionsMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleActionsMenuClose = (action = "") => {
        setAnchorEl(null);
        switch (action) {
            case "viewClientUsers":
                navigate(`/company/${companyId}/clientUsers/`);
                break;

            case "viewServices":
                navigate(`/services/assigned/${companyId}/`);
                break;

            case "editCompany":
                onEdit(company);
                break;

            case "editRenewalDate":
                onEditRenewalDate(company);
                break;

            case "manageLocations":
                onManageLocations(company);
                break;

            case "manageNotes":
                onManageNotes(company);
                break;

            case "manageSidebar":
                onManageSidebar(company);
                break;

            case "viewGoogleSheet":
                break;

            case "archiveCompany":
                onArchive(company);
                break;

            case "restoreCompany":
                onRestore(company);
                break;

            case "deleteCompany":
                onDelete(company);
                break;

            default:
                break;
        }
    };

    if (type === "list") {
        return (
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="start"
            >
                {isActive && canViewClientUsers && (
                    <TableActionIcon
                        iconComponent="people"
                        label="view client users"
                        tooltip="View Client Users"
                        component={RouterLink}
                        to={`/company/${companyId}/clientUsers/`}
                    />
                )}
                {isActive && (
                    <TableActionIcon
                        iconComponent="miscellaneousServices"
                        label="view services"
                        tooltip="View Services"
                        component={RouterLink}
                        to={`/services/assigned/${companyId}/`}
                    />
                )}
                {isActive && canAddEditCompany && (
                    <TableActionIcon
                        iconComponent="edit"
                        label="edit company"
                        tooltip="Edit Company"
                        onClick={() => onEdit(company)}
                    />
                )}
                {isActive && canEditRenewalDate && (
                    <TableActionIcon
                        iconComponent="calendarMonth"
                        label="edit renewal date"
                        tooltip="Edit Renewal date"
                        onClick={() => onEditRenewalDate(company)}
                    />
                )}
                {isActive && canManageLocations && (
                    <TableActionIcon
                        iconComponent="locationOn"
                        label="manage locations"
                        tooltip="Manage Locations"
                        onClick={() => onManageLocations(company)}
                    />
                )}
                {isActive && canManageNotes && (
                    <TableActionIcon
                        iconComponent="note"
                        label="notes"
                        tooltip="Notes"
                        onClick={() => onManageNotes(company)}
                    />
                )}
                {isActive && canManageSidebar && (
                    <TableActionIcon
                        iconComponent="viewSidebar"
                        label="manage sidebar"
                        tooltip="Manage Sidebar"
                        onClick={() => onManageSidebar(company)}
                    />
                )}
                {isActive &&
                    canViewGoogleSheet &&
                    company?.[COMPANY_KEYS.SHEET_LINK] && (
                        <TableActionIcon
                            componentType="link"
                            iconComponent="gridOn"
                            label="view google sheet"
                            tooltip="View Google Sheet"
                            href={company[COMPANY_KEYS.SHEET_LINK]}
                            target="_blank"
                        />
                    )}
                {isActive && (
                    <TableActionIcon
                        iconComponent="archive"
                        label="archive company"
                        tooltip="Archive Company"
                        onClick={() => onArchive(company)}
                    />
                )}
                {isArchived && (
                    <TableActionIcon
                        iconComponent="unarchive"
                        label="restore company"
                        tooltip="Restore Company"
                        onClick={() => onRestore(company)}
                    />
                )}
                {isArchived && (
                    <TableActionIcon
                        iconComponent="delete"
                        label="delete company"
                        tooltip="Delete Company"
                        onClick={() => onDelete(company)}
                    />
                )}
            </Stack>
        );
    } else if (type === "menu") {
        return (
            <Box>
                <IconButton
                    id="company-actions-button"
                    aria-controls={
                        actionsMenuOpen ? "company-actions-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={actionsMenuOpen ? "true" : undefined}
                    variant="outlined"
                    onClick={handleActionsMenuClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <StyledActionsMenu
                    id="company-actions-menu"
                    MenuListProps={{
                        "aria-labelledby": "company-actions-button",
                    }}
                    anchorEl={anchorEl}
                    open={actionsMenuOpen}
                    onClose={handleActionsMenuClose}
                >
                    {isActive && canViewClientUsers && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("viewClientUsers")
                            }
                            disableRipple
                        >
                            <PeopleIcon />
                            View Client Users
                        </StyledMenuItem>
                    )}
                    {isActive && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("viewServices")
                            }
                            disableRipple
                        >
                            <MiscellaneousServicesIcon />
                            View Services
                        </StyledMenuItem>
                    )}
                    {isActive && canAddEditCompany && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("editCompany")
                            }
                            disableRipple
                        >
                            <EditIcon />
                            Edit Company
                        </StyledMenuItem>
                    )}
                    {isActive && canEditRenewalDate && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("editRenewalDate")
                            }
                            disableRipple
                        >
                            <CalendarMonthIcon />
                            Edit Renewal date
                        </StyledMenuItem>
                    )}
                    {isActive && canManageLocations && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("manageLocations")
                            }
                            disableRipple
                        >
                            <LocationOnIcon />
                            Manage Locations
                        </StyledMenuItem>
                    )}
                    {isActive && canManageNotes && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("manageNotes")
                            }
                            disableRipple
                        >
                            <NoteIcon />
                            Notes
                        </StyledMenuItem>
                    )}
                    {isActive && canManageSidebar && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("manageSidebar")
                            }
                            disableRipple
                        >
                            <ViewSidebarIcon />
                            Manage Sidebar
                        </StyledMenuItem>
                    )}
                    {isActive &&
                        canViewGoogleSheet &&
                        company?.[COMPANY_KEYS.SHEET_LINK] && (
                            <StyledMenuItem
                                component="a"
                                href={company[COMPANY_KEYS.SHEET_LINK]}
                                target="_blank"
                                onClick={() =>
                                    handleActionsMenuClose("viewGoogleSheet")
                                }
                                disableRipple
                            >
                                <GridOnIcon />
                                View Google Sheet
                            </StyledMenuItem>
                        )}
                    {isActive && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("archiveCompany")
                            }
                            disableRipple
                        >
                            <ArchiveIcon />
                            Archive Company
                        </StyledMenuItem>
                    )}
                    {isArchived && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("restoreCompany")
                            }
                            disableRipple
                        >
                            <UnarchiveIcon />
                            Restore Company
                        </StyledMenuItem>
                    )}
                    {isArchived && (
                        <StyledMenuItem
                            onClick={() =>
                                handleActionsMenuClose("deleteCompany")
                            }
                            disableRipple
                        >
                            <DeleteIcon />
                            Delete Company
                        </StyledMenuItem>
                    )}
                </StyledActionsMenu>
            </Box>
        );
    } else {
        return null;
    }
};

CompaniesActionsList.propTypes = {
    canAddEditCompany: PropTypes.bool,
    canEditRenewalDate: PropTypes.bool,
    canManageLocations: PropTypes.bool,
    canManageNotes: PropTypes.bool,
    canManageSidebar: PropTypes.bool,
    canViewClientUsers: PropTypes.bool,
    canViewGoogleSheet: PropTypes.bool,
    company: PropTypes.object,
    type: PropTypes.string,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onEditRenewalDate: PropTypes.func,
    onManageLocations: PropTypes.func,
    onManageNotes: PropTypes.func,
    onManageSidebar: PropTypes.func,
    onRestore: PropTypes.func,
};

CompaniesActionsList.defaultProps = {
    canAddEditCompany: false,
    canEditRenewalDate: false,
    canManageLocations: false,
    canManageNotes: false,
    canManageSidebar: false,
    canViewClientUsers: false,
    canViewGoogleSheet: false,
    type: "list",
    // onArchive: () => {},
    // onDelete: () => {},
    // onEdit: () => {},
    // onEditRenewalDate: () => {},
    // onManageLocations: () => {},
    // onManageNotes: () => {},
    onManageSidebar: () => {},
    // onRestore: () => {},
};

export default CompaniesActionsList;
