import React from "react";
import CustomNavItem from "./CustomNavItem";
import NavSection from "./NavSection";
import NavCollapsible from "./NavCollapsible";
import NavItem from "./NavItem";

const NAV_VARIANTS = {
    section: NavSection,
    collapsible: NavCollapsible,
    "nav-item": NavItem,
};

const NavIdentifier = ({ customMenuUsed, item, isNested, translate }) => {
    if (!item) return null;

    if (customMenuUsed) {
        return <CustomNavItem translate item={item} isNested={isNested} />;
    } else if (
        item.type &&
        ["section", "collapsible", "nav-item"].includes(item.type)
    ) {
        const NavComponent = NAV_VARIANTS[item.type];
        return <NavComponent translate item={item} isNested={isNested} />;
    }
};

NavIdentifier.defaultProps = {
    isNested: false,
};

export default NavIdentifier;
