import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    FOLDER_KEYS,
    INDUSTRY_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";
import { getAllCompanies, getCompany } from "../../redux/actions/companies";
import { getAllIndustries } from "../../redux/actions/industries";
import {
    checkAssignedCompanyValid,
    checkIndustryValid,
    checkNameValid,
} from "../../utils/validationHelpers";
import { addFolder, updateFolder } from "../../redux/actions/folders";

const AddEditFolderDialog = ({
    currentCompany,
    currentFolder,
    open,
    setGlobalState,
    onClose,
    onInsert,
    onUpdate,
}) => {
    const _isAddEditFolderDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authUser, authToken } = useJumboAuth();

    const [allCompanies, setAllCompanies] = useState([]);
    const [industriesList, setIndustriesList] = useState([]);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [company, setCompany] = useState(null);
    const [companyError, setCompanyError] = useState("");
    const [industry, setIndustry] = useState(null);
    const [industryError, setIndustryError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserCompanyId =
        authUser &&
        authUser?.[AUTH_USER_KEYS.COMPANY_ID] &&
        authUser[AUTH_USER_KEYS.COMPANY_ID]
            ? authUser[AUTH_USER_KEYS.COMPANY_ID]
            : "";

    const authUserIndustry =
        authUser &&
        authUser?.[AUTH_USER_KEYS.INDUSTRY] &&
        authUser[AUTH_USER_KEYS.INDUSTRY]
            ? authUser[AUTH_USER_KEYS.INDUSTRY]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    const canAssignCompany = isAdminUser || isFmUser;
    const cannotAssignCompany = isClientUser;
    const hasIndustryAssigned = isClientUser;

    useEffect(() => {
        return () => {
            _isAddEditFolderDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAllCompanies([]);
            setIndustriesList([]);
            setName("");
            setNameError("");
            setCompany(null);
            setCompanyError("");
            setIndustry(null);
            setIndustryError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    /* Fetch all companies */
    useEffect(() => {
        let isActive = true;

        if (canAssignCompany) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllCompanies(payload, (fetchedCompanies) => {
                            if (isActive) {
                                setAllCompanies(
                                    fetchedCompanies?.companies || []
                                );
                            }
                        })
                    );
                };
            };

            const companyData = { authcode: authToken };
            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setAllCompanies([]);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, canAssignCompany]);

    /* Fetch Company Details */
    useEffect(() => {
        let isActive = true;

        if (cannotAssignCompany) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                if (
                                    fetchedCompany &&
                                    fetchedCompany?.[COMPANY_KEYS.ID] &&
                                    fetchedCompany[COMPANY_KEYS.ID]
                                ) {
                                    setCompany(fetchedCompany);
                                } else {
                                    setCompany(null);
                                }
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: authUserCompanyId,
            };

            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setCompany(null);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserCompanyId, cannotAssignCompany]);

    /* Fetch all industries */
    useEffect(() => {
        let isActive = true;

        if (isValidUserType) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllIndustries(payload, (fetchedIndustries) => {
                            if (isActive) {
                                if (
                                    fetchedIndustries &&
                                    fetchedIndustries.length > 0
                                ) {
                                    setIndustriesList(
                                        fetchedIndustries.map(
                                            (industriesObj) =>
                                                industriesObj[
                                                    INDUSTRY_KEYS.NAME
                                                ]
                                        )
                                    );
                                } else {
                                    setIndustriesList([]);
                                }
                            }
                        })
                    );
                };
            };

            const promise = dispatch(
                fetchData({
                    industryData: { authcode: authToken },
                    fetchStart: true,
                })
            );

            promise.catch((error) => {
                if (isActive) {
                    setIndustriesList([]);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, isValidUserType]);

    useEffect(() => {
        if (currentFolder) {
            setName(currentFolder[FOLDER_KEYS.NAME]);
            setIndustry(currentFolder[FOLDER_KEYS.INDUSTRY]);
            if (
                allCompanies &&
                allCompanies.length > 0 &&
                currentFolder[FOLDER_KEYS.COMPANY_ID]
            ) {
                const companyObj = allCompanies.find(
                    (tempCompanyObj) =>
                        tempCompanyObj[COMPANY_KEYS.ID] ===
                        currentFolder[FOLDER_KEYS.COMPANY_ID]
                );
                setCompany(companyObj || null);
            }
        } else if (currentCompany) {
            if (
                allCompanies &&
                allCompanies.length > 0 &&
                currentCompany[COMPANY_KEYS.ID]
            ) {
                const companyObj = allCompanies.find(
                    (tempCompanyObj) =>
                        tempCompanyObj[COMPANY_KEYS.ID] ===
                        currentCompany[COMPANY_KEYS.ID]
                );
                setCompany(companyObj || null);
            }
        }
    }, [allCompanies, currentCompany, currentFolder]);

    /**
     * Set industry and folder name values
     * according to the selected company if
     * they are empty.
     */
    useEffect(() => {
        if (!currentFolder) {
            let industryAssigned = false;
            if (authUserIndustry && hasIndustryAssigned) {
                industryAssigned = true;
                setIndustry(authUserIndustry);
                setIndustryError("");
            }

            if (company && !companyError) {
                if (company[COMPANY_KEYS.INDUSTRY] && !industryAssigned) {
                    setIndustry(company[COMPANY_KEYS.INDUSTRY]);
                    setIndustryError("");
                }
                if (company[COMPANY_KEYS.NAME]) {
                    setName(company[COMPANY_KEYS.NAME]);
                    setNameError("");
                }
            }
        }
    }, [
        authUserIndustry,
        company,
        companyError,
        currentFolder,
        hasIndustryAssigned,
    ]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case FOLDER_KEYS.FOLDER_NAME:
                        setNameError(msg);
                        break;

                    case FOLDER_KEYS.COMPANY_ID:
                        setCompanyError(msg);
                        break;

                    case FOLDER_KEYS.INDUSTRY:
                        setIndustryError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const nameBlurHandler = (event) => {
        const validationResult = checkNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const companyChangeHandler = (event, newValue) => {
        setCompanyError("");

        const assignedCompanyValidationResult = checkAssignedCompanyValid(
            newValue,
            allCompanies
        );
        if (assignedCompanyValidationResult.status) {
            if (assignedCompanyValidationResult.status === "false") {
                setCompany(null);
                setCompanyError(assignedCompanyValidationResult.msg);
            } else {
                setCompany(newValue);
            }
        } else {
            setCompany(null);
            setCompanyError(VALIDATION_FAILED);
        }
    };

    const industryChangeHandler = (event, newValue) => {
        setIndustryError("");

        const industryValidationResult = checkIndustryValid(
            newValue,
            industriesList
        );
        if (industryValidationResult.status) {
            if (industryValidationResult.status === "false") {
                setIndustry(null);
                setIndustryError(industryValidationResult.msg);
            } else {
                setIndustry(newValue);
            }
        } else {
            setIndustry(null);
            setIndustryError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        if (canAssignCompany) {
            const companyValidationResult = checkAssignedCompanyValid(
                company,
                allCompanies
            );
            if (companyValidationResult.status) {
                if (companyValidationResult.status === "false") {
                    formIsValid = false;
                    setCompanyError(companyValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setCompanyError(VALIDATION_FAILED);
            }
        }

        const industryValidationResult = checkIndustryValid(
            industry,
            industriesList
        );
        if (industryValidationResult.status) {
            if (industryValidationResult.status === "false") {
                formIsValid = false;
                setIndustryError(industryValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setIndustryError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const folderData = {
                authcode: authToken,
                [FOLDER_KEYS.FOLDER_NAME]: name,
                [FOLDER_KEYS.INDUSTRY]: industry,
                [FOLDER_KEYS.COMPANY_ID]: company?.[COMPANY_KEYS.ID] || "",
            };

            if (currentFolder) {
                folderData[FOLDER_KEYS.FOLDER_ID] =
                    currentFolder[FOLDER_KEYS.ID];

                dispatch(
                    updateFolder(
                        {
                            folderData: folderData,
                            setGlobalState: setGlobalState,
                        },
                        (updatedFolder) => {
                            if (_isAddEditFolderDialogMounted.current) {
                                onUpdate(updatedFolder);
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditFolderDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                dispatch(
                    addFolder(
                        {
                            folderData: folderData,
                            setGlobalState: setGlobalState,
                        },
                        () => {
                            if (_isAddEditFolderDialogMounted.current) {
                                onInsert();
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditFolderDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentFolder ? "Edit Folder" : "Add Folder"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        {miscellaneousErrors &&
                            miscellaneousErrors.length > 0 && (
                                <Grid item xs={12}>
                                    {miscellaneousErrors.map(
                                        (miscellaneousError, idx) => (
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                color="error"
                                                gutterBottom
                                                key={`misc-error-${idx}`}
                                            >
                                                {miscellaneousError}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            )}

                        {canAssignCompany && (
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Autocomplete
                                        disabled={
                                            !!currentFolder || !!currentCompany
                                        }
                                        disablePortal
                                        fullWidth
                                        filterSelectedOptions
                                        id="company_id"
                                        options={allCompanies}
                                        value={company}
                                        getOptionLabel={(option) => {
                                            return option &&
                                                option[COMPANY_KEYS.ID]
                                                ? option[COMPANY_KEYS.NAME]
                                                : "";
                                        }}
                                        isOptionEqualToValue={(
                                            option,
                                            newValue
                                        ) => {
                                            return (
                                                option[COMPANY_KEYS.ID] ===
                                                newValue[COMPANY_KEYS.ID]
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Select Company"
                                                error={companyError !== ""}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li
                                                {...props}
                                                key={option[COMPANY_KEYS.ID]}
                                            >
                                                {option[COMPANY_KEYS.NAME]}
                                            </li>
                                        )}
                                        onChange={companyChangeHandler}
                                    />
                                    {companyError && (
                                        <FormHelperText
                                            error
                                            id="company_id-error"
                                        >
                                            {companyError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        )}
                        {!canAssignCompany && (
                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Typography
                                        component="p"
                                        sx={{
                                            fontWeight: (theme) =>
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        Company:
                                    </Typography>
                                    <Typography component="p">
                                        {authUser?.[AUTH_USER_KEYS.COMPANY] ||
                                            ""}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                id="name"
                                name="name"
                                label="Name"
                                value={name}
                                margin="dense"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameError("");
                                }}
                                onBlur={nameBlurHandler}
                                error={nameError !== ""}
                                helperText={nameError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    filterSelectedOptions
                                    id="industry"
                                    options={industriesList}
                                    value={industry}
                                    getOptionLabel={(option) => {
                                        return option ? option : "";
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                            label="Select Industry"
                                            error={industryError !== ""}
                                        />
                                    )}
                                    onChange={industryChangeHandler}
                                />
                                {industryError && (
                                    <FormHelperText error id="industry-error">
                                        {industryError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {currentFolder ? "Update Folder" : "Add Folder"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

AddEditFolderDialog.propTypes = {
    currentCompany: PropTypes.object,
    currentFolder: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    onClose: PropTypes.func,
    onInsert: PropTypes.func,
    onUpdate: PropTypes.func,
};

AddEditFolderDialog.defaultProps = {
    currentCompany: null,
    currentFolder: null,
    open: false,
    setGlobalState: false,
    // onClose: () => {},
    onInsert: () => {},
    onUpdate: () => {},
};

export default AddEditFolderDialog;
