import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    ANNOUNCEMENT_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    ACTION_AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import {
    deleteAnnouncement,
    getFilteredAnnouncements,
    setCurrentAnnouncement,
} from "../../redux/actions/announcements";
import AnnouncementsTable from "./AnnouncementsTable";
const AddEditAnnouncementDialog = lazy(() =>
    import("./AddEditAnnouncementDialog")
);
const ViewAnnouncementDialog = lazy(() => import("./ViewAnnouncementDialog"));

const pageName = "Announcements";

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, deleted: true };
        }
    }

    return { inserted: false, deleted: false };
};

const ANNOUNCEMENTS_LIMIT = 10;

const INIT_ANNOUNCEMENTS_STATE = {
    announcementsFetched: false,
    fetchAnnouncements: false,
    firstLoad: true,
    order: SORT_ORDER_DESC,
    page: 0,
    rowsPerPage: ANNOUNCEMENTS_LIMIT,
    sort: ANNOUNCEMENT_KEYS.TITLE,
};

const announcementsLocalReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                announcementsFetched: false,
                fetchAnnouncements: true,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                announcementsFetched: false,
                fetchAnnouncements: false,
                page: 0,
            };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                announcementsFetched: false,
                fetchAnnouncements: true,
                page: 0,
                rowsPerPage: action.rowsPerPage,
            };
        } else if (action.type === "SET_PAGE") {
            return {
                ...state,
                announcementsFetched: false,
                fetchAnnouncements: true,
                page: action.page,
            };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                announcementsFetched: false,
                fetchAnnouncements: true,
                sort: updatedSort,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                announcementsFetched: true,
                fetchAnnouncements: false,
                firstLoad: false,
            };
        }
    }

    return INIT_ANNOUNCEMENTS_STATE;
};

const Announcements = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isAnnouncementsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { announcements, announcementCounts } = useSelector(
        ({ announcementsReducer }) => announcementsReducer
    );

    const [isLoading, setIsLoading] = useState(false);
    const [openAddEditAnnouncementDialog, setOpenAddEditAnnouncementDialog] =
        useState(false);
    const [openViewAnnouncementDialog, setOpenViewAnnouncementDialog] =
        useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
    });

    const [announcementsState, dispatchAnnouncementsAction] = useReducer(
        announcementsLocalReducer,
        INIT_ANNOUNCEMENTS_STATE
    );

    const { announcementsFetched, fetchAnnouncements } = announcementsState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;

    const isValidUserType = isAdminUser;
    const canDeleteAnnouncement = isAdminUser;
    const canEditAnnouncement = isAdminUser;
    const canViewAnnouncement = isAdminUser;

    useEffect(() => {
        return () => {
            _isAnnouncementsMounted.current = false;
            dispatch(setCurrentAnnouncement(null));
        };
    }, [dispatch]);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch announcements */
    useEffect(() => {
        if (isLoading) {
            dispatchAnnouncementsAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (announcementsState.fetchAnnouncements) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredAnnouncements(payload, () => {
                            if (isActive) {
                                dispatchAnnouncementsAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const announcementData = {
                authcode: authToken,
                page: announcementsState.page + 1,
                rows_per_page: announcementsState.rowsPerPage,
                sort: announcementsState.sort,
                order: announcementsState.order,
            };

            const payload = {
                announcementData: announcementData,
                fromAction: actionState,
            };

            if (!announcementsState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchAnnouncementsAction({ type: "RESET" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, actionState, announcementsState, authToken]);

    /**
     * Fetch the list again if aN announcement is
     * added or deleted
     */
    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            dispatchAnnouncementsAction({ type: "FETCH" });
        }
    }, [actionState]);

    const handleOpenAddAnnouncementDialog = () => {
        setOpenAddEditAnnouncementDialog(true);
    };

    const handleCloseAddEditAnnouncementDialog = () => {
        setOpenAddEditAnnouncementDialog(false);
        dispatch(setCurrentAnnouncement(null));
    };

    const handleAnnouncementView = (announcement) => {
        if (canViewAnnouncement) {
            dispatch(setCurrentAnnouncement(announcement));
            setOpenViewAnnouncementDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseViewAnnouncementDialog = () => {
        setOpenViewAnnouncementDialog(false);
        dispatch(setCurrentAnnouncement(null));
    };

    const handlePageChange = (event, newPage) =>
        dispatchAnnouncementsAction({ type: "SET_PAGE", page: newPage });

    const handleRowsPerPageChange = (event) => {
        dispatchAnnouncementsAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchAnnouncementsAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleAnnouncementInsert = () => dispatchAction({ type: "INSERT" });

    const handleAnnouncementEdit = (announcement) => {
        if (canEditAnnouncement) {
            dispatch(setCurrentAnnouncement(announcement));
            setOpenAddEditAnnouncementDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleAnnouncementDelete = React.useCallback(
        (announcement) => {
            if (canDeleteAnnouncement) {
                if (announcement && announcement[ANNOUNCEMENT_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete announcement: ${
                            announcement[ANNOUNCEMENT_KEYS.TITLE]
                        }?`,
                        onYes: () => {
                            hideDialog();
                            const announcementData = {
                                authcode: authToken,
                                [ANNOUNCEMENT_KEYS.ID]:
                                    announcement[ANNOUNCEMENT_KEYS.ID],
                            };
                            dispatch(
                                deleteAnnouncement(
                                    { announcementData: announcementData },
                                    () => {
                                        if (_isAnnouncementsMounted.current)
                                            dispatchAction({ type: "DELETE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid announcement",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [
            dispatch,
            Swal,
            authToken,
            canDeleteAnnouncement,
            hideDialog,
            showDialog,
        ]
    );

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchAnnouncements && !announcementsFetched;
    const loadError = !isValidUserType || fetchError;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                {pageName && (
                    <Grid item xs={12}>
                        <HeadingStack>
                            <PageTitle
                                component="h1"
                                title={pageName}
                                type="v2"
                            />
                            <Breadcrumb
                                pageNames={{
                                    genericName: pageName,
                                    specificName: pageName,
                                }}
                            />
                        </HeadingStack>
                    </Grid>
                )}
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && fetchError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    There was a problem in fetching the
                                    announcements list!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <HeadingStack sx={{ justifyContent: "end" }}>
                                <FmButton
                                    startIcon="add"
                                    type="button"
                                    label="Add Announcement"
                                    onClick={handleOpenAddAnnouncementDialog}
                                />
                            </HeadingStack>
                        </Grid>
                        <Grid item xs={12}>
                            <AnnouncementsTable
                                canDeleteAnnouncement={canDeleteAnnouncement}
                                canEditAnnouncement={canEditAnnouncement}
                                canViewAnnouncement={canViewAnnouncement}
                                announcements={announcements}
                                announcementCounts={announcementCounts}
                                announcementsFetched={announcementsFetched}
                                order={announcementsState.order}
                                page={announcementsState.page}
                                rowsPerPage={announcementsState.rowsPerPage}
                                sort={announcementsState.sort}
                                handlePageChange={handlePageChange}
                                handleRowsPerPageChange={
                                    handleRowsPerPageChange
                                }
                                onEdit={handleAnnouncementEdit}
                                onDelete={handleAnnouncementDelete}
                                onSort={handleSort}
                                onView={handleAnnouncementView}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && (
                <React.Fragment>
                    {openAddEditAnnouncementDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddEditAnnouncementDialog
                                open={openAddEditAnnouncementDialog}
                                onClose={handleCloseAddEditAnnouncementDialog}
                                onInsert={handleAnnouncementInsert}
                            />
                        </Suspense>
                    )}
                    {openViewAnnouncementDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <ViewAnnouncementDialog
                                open={openViewAnnouncementDialog}
                                onClose={handleCloseViewAnnouncementDialog}
                            />
                        </Suspense>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Announcements;
