import axios from "axios";

const basicFileAuthAxios = axios.create({
    baseURL: "https://fmportal.firm-media.com/",
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

basicFileAuthAxios.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response && err.response.data.type === "token-invalid") {
            //todo logout the user
        }
        return Promise.reject(err);
    }
);

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default basicFileAuthAxios;
