import {
    DELETE_ASSIGNED_SERVICE,
    EDIT_ASSIGNED_SERVICE,
    GET_ASSIGNED_SERVICE_COUNT,
    GET_ASSIGNED_SERVICES,
    SET_ASSIGNED_SERVICE_DETAILS,
} from "../../utils/constants/actionTypes";
import { ASSIGNED_SERVICE_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    assignedServices: [],
    currentAssignedService: null,
    assignedServiceCounts: { [ASSIGNED_SERVICE_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ASSIGNED_SERVICES: {
            return {
                ...state,
                assignedServices: action.payload,
            };
        }
        case GET_ASSIGNED_SERVICE_COUNT: {
            return {
                ...state,
                assignedServiceCounts: action.payload,
            };
        }
        case SET_ASSIGNED_SERVICE_DETAILS: {
            return {
                ...state,
                currentAssignedService: action.payload,
            };
        }
        case EDIT_ASSIGNED_SERVICE: {
            return {
                ...state,
                assignedServices: state.assignedServices.map(
                    (assignedService) =>
                        assignedService.id === action.payload.id
                            ? action.payload
                            : assignedService
                ),
            };
        }
        case DELETE_ASSIGNED_SERVICE: {
            return {
                ...state,
                assignedServices: state.assignedServices.filter(
                    (assignedService) => assignedService.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
