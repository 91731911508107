import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getAllCompanies } from "../../redux/actions/companies";
import { getAllIndustries } from "../../redux/actions/industries";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import FormattedPhoneNo from "../../shared/widgets/FormattedPhoneNo";
import NameLink from "../../shared/widgets/NameLink";
import {
    checkClientPhoneNoValid,
    checkEmailValid,
    checkNameValid,
    checkProfilePhotoValid,
    checkRoleValid,
    checkWebsiteValid,
} from "../../utils/validationHelpers";
import {
    AUTH_USER_KEYS,
    CLIENT_USER_KEYS,
    COMPANY_KEYS,
    INDUSTRY_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import {
    addClientUser,
    updateClientUser,
} from "../../redux/actions/clientUsers";

const AddEditCompanyClientDialog = ({
    currentClientUser,
    currentCompany,
    open,
    setGlobalState,
    onClose,
    onInsert,
    onUpdate,
}) => {
    const _isAddEditCompClientDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allIndustries, setAllIndustries] = useState([]);
    const [company, setCompany] = useState(null);
    const [companyError, setCompanyError] = useState("");
    const [companyValid, setCompanyValid] = useState(false);
    const [industry, setIndustry] = useState(null);
    const [industryError, setIndustryError] = useState("");
    const [industryValid, setIndustryValid] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneNoError, setPhoneNoError] = useState("");
    const [role, setRole] = useState("");
    const [roleError, setRoleError] = useState("");
    const [photo, setPhoto] = useState(null);
    const [photoError, setPhotoError] = useState("");
    const [photoLink, setPhotoLink] = useState("");
    const [website, setWebsite] = useState("");
    const [websiteError, setWebsiteError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isAddEditCompClientDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setIsLoading(false);
            setAllCompanies([]);
            setAllIndustries([]);
            setName("");
            setNameError("");
            setEmail("");
            setEmailError("");
            setCompany(null);
            setCompanyError("");
            setCompanyValid(false);
            setIndustry(null);
            setIndustryError("");
            setIndustryValid(false);
            setPhoneNo("");
            setPhoneNoError("");
            setRole("");
            setRoleError("");
            setPhoto(null);
            setPhotoError("");
            setPhotoLink("");
            setWebsite("");
            setWebsiteError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch lists: companies, industries */
    useEffect(() => {
        let isActive = true;

        if (isLoading) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllCompanies(
                            {
                                companyData: payload.companyData,
                                fetchStart: true,
                            },
                            (fetchedCompanies) => {
                                if (isActive) {
                                    setAllCompanies(
                                        fetchedCompanies?.companies
                                            ? [...fetchedCompanies.companies]
                                            : []
                                    );
                                }
                            }
                        )
                    ).then(() => {
                        return dispatch(
                            getAllIndustries(
                                {
                                    industryData: payload.industryData,
                                    fetchStart: true,
                                },
                                (fetchedIndustries) => {
                                    if (isActive) {
                                        setAllIndustries([
                                            ...fetchedIndustries,
                                        ]);
                                        setIsLoading(false);
                                    }
                                }
                            )
                        );
                    });
                };
            };

            const payload = {
                companyData: { authcode: authToken },
                industryData: { authcode: authToken },
            };

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    setAllCompanies([]);
                    setAllIndustries([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, isLoading]);

    useEffect(() => {
        if (allCompanies && allCompanies.length > 0 && currentCompany) {
            const currentCompanyObj = allCompanies.find(
                (tempCompanyObj) =>
                    tempCompanyObj[COMPANY_KEYS.ID] ===
                    currentCompany[COMPANY_KEYS.ID]
            );
            if (currentCompanyObj) {
                setCompany(currentCompanyObj);
            }
        }
    }, [allCompanies, currentCompany]);

    useEffect(() => {
        if (company && company?.[COMPANY_KEYS.ID] && company[COMPANY_KEYS.ID]) {
            setCompanyValid(true);
        }
    }, [company]);

    useEffect(() => {
        if (companyValid && allIndustries && allIndustries.length > 0) {
            if (currentClientUser) {
                const clientIndustryObj = allIndustries.find(
                    (tempIndustryObj) =>
                        tempIndustryObj[INDUSTRY_KEYS.NAME] ===
                        currentClientUser[CLIENT_USER_KEYS.INDUSTRY]
                );
                if (clientIndustryObj) {
                    setIndustry(clientIndustryObj);
                }
            } else {
                const companyIndustryObj = allIndustries.find(
                    (tempIndustryObj) =>
                        tempIndustryObj[INDUSTRY_KEYS.NAME] ===
                        company[COMPANY_KEYS.INDUSTRY]
                );
                if (companyIndustryObj) {
                    setIndustry(companyIndustryObj);
                }
            }
        }
    }, [allIndustries, company, companyValid, currentClientUser]);

    useEffect(() => {
        if (
            industry &&
            industry?.[INDUSTRY_KEYS.NAME] &&
            industry[INDUSTRY_KEYS.NAME]
        ) {
            setIndustryValid(true);
        }
    }, [industry]);

    useEffect(() => {
        if (companyValid && currentClientUser && industryValid) {
            setName(currentClientUser[CLIENT_USER_KEYS.NAME]);
            setEmail(currentClientUser[CLIENT_USER_KEYS.EMAIL]);
            setPhoneNo(currentClientUser[CLIENT_USER_KEYS.PHONE_NO] || "");
            setRole(currentClientUser?.[CLIENT_USER_KEYS.ROLE] || "");
            setWebsite(currentClientUser[CLIENT_USER_KEYS.WEBSITE] || "");

            const companyId = company[COMPANY_KEYS.ID];
            const currentClientCompanyId =
                currentClientUser?.[CLIENT_USER_KEYS.COMPANY_ID] || "";
            const companyMatch =
                companyId &&
                currentClientCompanyId &&
                companyId === currentClientCompanyId;
            if (!companyMatch) {
                setCompanyValid(false);
            }

            /* const companyIndustry = company?.[COMPANY_KEYS.INDUSTRY] || "";
            const currentClientIndustry =
                currentClientUser?.[CLIENT_USER_KEYS.INDUSTRY] || "";
            const industryMatch =
                companyIndustry &&
                currentClientIndustry &&
                companyIndustry === currentClientIndustry;
            if (!industryMatch) {
                setIndustryValid(false);
            } */

            setPhoto(null);
            const fetchedPhoto =
                currentClientUser[CLIENT_USER_KEYS.PROFILE_PIC];
            if (fetchedPhoto) {
                setPhotoLink(
                    `${currentClientUser[CLIENT_USER_KEYS.PHOTO_BASE_URL]}/${
                        fetchedPhoto[CLIENT_USER_KEYS.PHOTO_FILE_PATH]
                    }`
                );
            }
        }
    }, [
        allIndustries,
        company,
        companyValid,
        currentClientUser,
        industry,
        industryValid,
    ]);

    useEffect(() => {
        if (companyValid) {
            setCompanyError("");
        } else {
            setCompanyError("Invalid company!");
        }
    }, [companyValid]);

    useEffect(() => {
        if (industryValid) {
            setIndustryError("");
        } else {
            setIndustryError("Invalid industry!");
        }
    }, [industryValid]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case CLIENT_USER_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case CLIENT_USER_KEYS.EMAIL:
                        setEmailError(msg);
                        break;

                    case CLIENT_USER_KEYS.COMPANY_ID:
                        setCompanyError(msg);
                        break;

                    case CLIENT_USER_KEYS.INDUSTRY:
                        setIndustryError(msg);
                        break;

                    case CLIENT_USER_KEYS.PHONE_NO:
                        setPhoneNoError(msg);
                        break;

                    case CLIENT_USER_KEYS.ROLE:
                        setRoleError(msg);
                        break;

                    case CLIENT_USER_KEYS.PROFILE_PIC:
                        setPhotoError(msg);
                        break;

                    case CLIENT_USER_KEYS.WEBSITE:
                        setWebsiteError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const nameBlurHandler = (event) => {
        const validationResult = checkNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const emailBlurHandler = (event) => {
        const validationResult = checkEmailValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setEmailError(validationResult.msg);
            }
        } else {
            setEmailError(VALIDATION_FAILED);
        }
    };

    const phoneNoChangeHandler = (event) => {
        const updatedPhoneNo = event.target.value;

        setPhoneNo(updatedPhoneNo);
        setPhoneNoError("");

        const validationResult = checkClientPhoneNoValid(updatedPhoneNo);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setPhoneNoError(validationResult.msg);
            }
        } else {
            setPhoneNoError(VALIDATION_FAILED);
        }
    };

    const roleBlurHandler = (event) => {
        const validationResult = checkRoleValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setRoleError(validationResult.msg);
            }
        } else {
            setRoleError(VALIDATION_FAILED);
        }
    };

    const websiteBlurHandler = (event) => {
        const enteredWebsite = event.target.value;
        if (enteredWebsite) {
            const validationResult = checkWebsiteValid(enteredWebsite);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    setWebsiteError(validationResult.msg);
                }
            } else {
                setWebsiteError(VALIDATION_FAILED);
            }
        }
    };

    const photoChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (files && files.length > 0) {
            const validationResult = checkProfilePhotoValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setPhotoError(validationResult.msg);
                }
            } else {
                isValid = false;
                setPhotoError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setPhoto(files[0]);
            setPhotoError("");
        } else {
            setPhoto(null);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        const emailValidationResult = checkEmailValid(email);
        if (emailValidationResult.status) {
            if (emailValidationResult.status === "false") {
                formIsValid = false;
                setEmailError(emailValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setEmailError(VALIDATION_FAILED);
        }

        const phoneNoValidationResult = checkClientPhoneNoValid(phoneNo);
        if (phoneNoValidationResult.status) {
            if (phoneNoValidationResult.status === "false") {
                formIsValid = false;
                setPhoneNoError(phoneNoValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPhoneNoError(VALIDATION_FAILED);
        }

        const roleValidationResult = checkRoleValid(role);
        if (roleValidationResult.status) {
            if (roleValidationResult.status === "false") {
                formIsValid = false;
                setRoleError(roleValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setRoleError(VALIDATION_FAILED);
        }

        if (website) {
            const websiteValidationResult = checkWebsiteValid(website);
            if (websiteValidationResult.status) {
                if (websiteValidationResult.status === "false") {
                    formIsValid = false;
                    setWebsiteError(websiteValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setWebsiteError(VALIDATION_FAILED);
            }
        }

        if (photo) {
            const photoValidationResult = checkProfilePhotoValid([photo]);
            if (photoValidationResult.status) {
                if (photoValidationResult.status === "false") {
                    formIsValid = false;
                    setPhotoError(photoValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setPhotoError(VALIDATION_FAILED);
            }
        }

        formIsValid = formIsValid && companyValid && industryValid;

        if (authUserType && formIsValid) {
            const clientUserData = new FormData();
            clientUserData.append("authcode", authToken);
            clientUserData.append([CLIENT_USER_KEYS.NAME], name);
            clientUserData.append([CLIENT_USER_KEYS.EMAIL], email);
            clientUserData.append([CLIENT_USER_KEYS.PHONE_NO], phoneNo);
            clientUserData.append([CLIENT_USER_KEYS.ROLE], role);
            clientUserData.append([CLIENT_USER_KEYS.WEBSITE], website || "");
            clientUserData.append(
                [CLIENT_USER_KEYS.COMPANY_ID],
                company?.[COMPANY_KEYS.ID] || ""
            );
            clientUserData.append(
                [CLIENT_USER_KEYS.INDUSTRY],
                industry?.[INDUSTRY_KEYS.NAME] || ""
            );

            if (currentClientUser) {
                clientUserData.append(
                    [CLIENT_USER_KEYS.USER_ID],
                    currentClientUser[CLIENT_USER_KEYS.ID]
                );

                if (photo)
                    clientUserData.append(
                        [CLIENT_USER_KEYS.PROFILE_PIC],
                        photo
                    );

                dispatch(
                    updateClientUser(
                        {
                            clientUserData: clientUserData,
                            authUserType: authUserType,
                            setGlobalState: setGlobalState,
                        },
                        (updatedClientUser) => {
                            if (_isAddEditCompClientDialogMounted.current) {
                                onUpdate(updatedClientUser);
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditCompClientDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                if (photo)
                    clientUserData.append(
                        [CLIENT_USER_KEYS.PROFILE_PIC],
                        photo
                    );

                dispatch(
                    addClientUser(
                        {
                            clientUserData: clientUserData,
                            authUserType: authUserType,
                            setGlobalState: setGlobalState,
                        },
                        () => {
                            if (_isAddEditCompClientDialogMounted.current) {
                                onInsert();
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditCompClientDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    const loadError = !isValidUserType || !companyValid || !industryValid;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentClientUser ? "Edit User" : "Add User"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {!isLoading && loadError && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            !(companyValid && industryValid) && (
                                <Grid container spacing={2}>
                                    {companyError && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                {companyError}
                                            </Alert>
                                        </Grid>
                                    )}
                                    {industryError && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                {industryError}
                                            </Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                    </Grid>
                )}
                {!isLoading && !loadError && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item md={6} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    variant="outlined"
                                    id="company"
                                    name="company"
                                    label="Company"
                                    margin="dense"
                                    value={company?.[COMPANY_KEYS.NAME] || ""}
                                    error={companyError !== ""}
                                    helperText={companyError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    variant="outlined"
                                    id="industry"
                                    name="industry"
                                    label="Industry"
                                    margin="dense"
                                    value={industry?.[INDUSTRY_KEYS.NAME] || ""}
                                    error={industryError !== ""}
                                    helperText={industryError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={name}
                                    margin="dense"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameError("");
                                    }}
                                    onBlur={nameBlurHandler}
                                    error={nameError !== ""}
                                    helperText={nameError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={email}
                                    margin="dense"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError("");
                                    }}
                                    onBlur={emailBlurHandler}
                                    error={emailError !== ""}
                                    helperText={emailError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="phone_no"
                                    name="phone_no"
                                    label="Phone Number"
                                    margin="dense"
                                    value={phoneNo}
                                    InputProps={{
                                        inputComponent: FormattedPhoneNo,
                                        inputProps: {
                                            allowEmptyFormatting: false,
                                            displayType: "input",
                                        },
                                    }}
                                    onChange={phoneNoChangeHandler}
                                    error={phoneNoError !== ""}
                                    helperText={phoneNoError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="role"
                                    name="role"
                                    label="Role"
                                    margin="dense"
                                    value={role}
                                    onChange={(e) => {
                                        setRole(e.target.value);
                                        setRoleError("");
                                    }}
                                    onBlur={roleBlurHandler}
                                    error={roleError !== ""}
                                    helperText={roleError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="website"
                                    name="website"
                                    label="Website"
                                    margin="dense"
                                    value={website}
                                    onChange={(e) => {
                                        setWebsite(e.target.value);
                                        setWebsiteError("");
                                    }}
                                    onBlur={websiteBlurHandler}
                                    error={websiteError !== ""}
                                    helperText={websiteError}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <label
                                    htmlFor="photo"
                                    style={{
                                        display: "block",
                                        marginTop: 8,
                                    }}
                                >
                                    <input
                                        id="photo"
                                        name="photo"
                                        type="file"
                                        onChange={photoChangeHandler}
                                        style={{ display: "none" }}
                                    />

                                    <AttachFileButton
                                        fullWidth
                                        type="button"
                                        variant="outlined"
                                        component="span"
                                        startIcon={<AttachFileIcon />}
                                        sx={(theme) => {
                                            const photoErrorSx = photoError
                                                ? {
                                                      color: theme.palette.error
                                                          .main,
                                                      border: `1px solid ${theme.palette.error.main}`,
                                                  }
                                                : "";
                                            return {
                                                ...photoErrorSx,
                                            };
                                        }}
                                    >
                                        {photo ? photo.name : "Photo"}
                                    </AttachFileButton>
                                </label>
                                {photoError && (
                                    <FormHelperText error id="photo-error">
                                        {photoError}
                                    </FormHelperText>
                                )}
                            </Grid>
                            {photoLink && (
                                <Grid item xs={12}>
                                    <NameLink
                                        noWrap
                                        align="center"
                                        href={photoLink}
                                        target="_blank"
                                        sx={(theme) => ({
                                            display: "block",
                                            wordWrap: "break-word",
                                            ...theme.typography.h5,
                                        })}
                                    >
                                        View Photo
                                    </NameLink>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {currentClientUser
                                        ? "Update User"
                                        : "Create User"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditCompanyClientDialog.propTypes = {
    currentClientUser: PropTypes.object,
    currentCompany: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    onClose: PropTypes.func,
    onInsert: PropTypes.func,
    onUpdate: PropTypes.func,
};

AddEditCompanyClientDialog.defaultProps = {
    currentClientUser: null,
    open: false,
    setGlobalState: false,
    // onClose: () => {},
    onInsert: () => {},
    onUpdate: () => {},
};

export default AddEditCompanyClientDialog;
