import { isValidEmail } from "@jumbo/utils";
import {
    CLIENT_USER_KEYS,
    COMPANY_KEYS,
    COUNTRY_KEYS,
    FM_USER_KEYS,
    FOLDER_KEYS,
    SURVEY_QUESTION_OPTION_KEYS,
    SURVEY_QUESTION_TYPE_SINGLE_SELECT,
    SERVICE_KEYS,
    STATE_KEYS,
    TIME_ZONE_KEYS,
    VALID_FILE_AUDIO_EXTENSIONS,
    VALID_FILE_AUDIO_MIME_TYPES,
    VALID_FILE_DOC_EXTENSIONS,
    VALID_FILE_DOC_MIME_TYPES,
    VALID_FILE_GRAPHICS_EXTENSIONS,
    VALID_FILE_GRAPHICS_MIME_TYPES,
    VALID_FILE_IMG_EXTENSIONS,
    VALID_FILE_IMG_MIME_TYPES,
    VALID_FILE_SHEET_EXTENSIONS,
    VALID_FILE_SHEET_MIME_TYPES,
    VALID_FILE_VIDEO_EXTENSIONS,
    VALID_FILE_VIDEO_MIME_TYPES,
    VALID_CHAT_FILES_MAX_COUNT,
    VALID_CHAT_FILE_MAX_SIZE_MB,
    VALID_COMMON_FILE_MAX_SIZE_MB,
    VALID_IMG_EXTENSIONS,
    VALID_IMG_MIME_TYPES,
    VALID_REPORT_EXTENSIONS,
    VALID_REPORT_MIME_TYPES,
    VALID_SPECIAL_FILE_EXTENSIONS,
    VALID_SPECIAL_FILE_MIME_TYPES,
    VALID_WO_FILE_EXTENSIONS,
    VALID_WO_FILE_MIME_TYPES,
} from "./constants/appData";
import {
    ACCOUNT_ASSET_NOT_VALID,
    ANSWER_REQUIRED,
    AMOUNT_NOT_VALID,
    CARD_NO_NOT_VALID,
    CLIENT_NOT_VALID,
    CLIENTS_NOT_VALID,
    COUNTRY_NOT_VALID,
    COMPANY_NOT_VALID,
    CVV_NOT_VALID,
    DATE_NOT_VALID,
    DELETED_DOCUMENTS_REQUIRED,
    DUPLICATE_OPTIONS_ERROR,
    DUPLICATE_QUESTIONS_ERROR,
    EMAIL_NOT_VALID,
    FM_CLIENTS_NOT_VALID,
    FM_USER_NOT_VALID,
    FM_USERS_NOT_VALID,
    FILE_EXTENSION_NOT_VALID,
    FILE_SIZE_NOT_VALID,
    FOLDER_NOT_VALID,
    ICON_NOT_VALID,
    INDUSTRY_NOT_VALID,
    INTERVAL_NOT_VALID,
    INTERVALS_LIST_NOT_VALID,
    INVALID_ASSIGNED_COMPANIES,
    INVALID_DATE_RANGE,
    MAX_FILES_ERROR,
    MEETING_NOT_IN_FUTURE,
    MONTH_NOT_VALID,
    OPTIONS_NOT_VALID,
    PASSWORD_MISMATCH,
    PASSWORD_RULES_MISMATCH,
    PAST_DATE_NOT_ALLOWED,
    PHONE_NO_NOT_VALID,
    PHONE_NO_EXT_NOT_VALID,
    RESPONSE_NOT_VALID,
    REQUIRED,
    SERVICE_NOT_VALID,
    SIDEBAR_IS_ACTIVE_INVALID,
    SIDEBAR_NEW_TAB_INVALID,
    SIDEBAR_URL_TYPE_INVALID,
    SINGLE_ANSWER_REQUIRED,
    STATE_NOT_VALID,
    STATUS_NOT_VALID,
    SURVEY_QUES_TYPE_NOT_VALID,
    TIME_NOT_VALID,
    TIME_ZONE_NOT_VALID,
    YEAR_NOT_VALID,
} from "./constants/errorMessages";
import {
    getCurrentDateTimeFormatted,
    getDateinDesiredFormat,
    isPastFromToday,
    isStartBeforeEnd,
} from "./dateHelpers";

export const checkAccountAssetTypeValid = (
    selectedDocumentType,
    documentTypes
) => {
    if (selectedDocumentType && documentTypes) {
        const foundDocumentType = documentTypes.find(
            (documentType) => selectedDocumentType === documentType
        );
        if (foundDocumentType) {
            return { status: "true" };
        } else {
            return { status: "false", msg: ACCOUNT_ASSET_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAccountAssetValid = (assets) => {
    if (!assets || assets.length !== 1 || !assets[0]) {
        return { status: "false", msg: REQUIRED };
    }

    const validExtensions = [
        ...VALID_FILE_AUDIO_EXTENSIONS,
        ...VALID_FILE_DOC_EXTENSIONS,
        ...VALID_FILE_GRAPHICS_EXTENSIONS,
        ...VALID_FILE_IMG_EXTENSIONS,
        ...VALID_FILE_SHEET_EXTENSIONS,
        ...VALID_FILE_VIDEO_EXTENSIONS,
    ];
    const validMimeTypes = [
        ...VALID_FILE_AUDIO_MIME_TYPES,
        ...VALID_FILE_DOC_MIME_TYPES,
        ...VALID_FILE_GRAPHICS_MIME_TYPES,
        ...VALID_FILE_IMG_MIME_TYPES,
        ...VALID_FILE_SHEET_MIME_TYPES,
        ...VALID_FILE_VIDEO_MIME_TYPES,
    ];

    return isFileValid(assets[0], 30, validExtensions, validMimeTypes);
};

export const checkAddressValid = (address) => {
    if (!address) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkAnnouncementDateRageValid = (startDate, endDate) => {
    const formattedStartDate = getDateinDesiredFormat(startDate, "YYYY-MM-DD");
    const startDateTime = `${formattedStartDate} 00:00:00`;

    const formattedEndDate = getDateinDesiredFormat(endDate, "YYYY-MM-DD");
    const endDateTime = `${formattedEndDate} 00:00:00`;

    if (isStartBeforeEnd(startDateTime, endDateTime)) {
        return { status: "true" };
    } else {
        return { status: "false", msg: INVALID_DATE_RANGE };
    }
};

export const checkAnnouncementDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkAnnouncementLinkValid = (link) => {
    if (!link) {
        return { status: "false", msg: REQUIRED };
    } else {
        return isValidUrl(link);
    }
};

export const checkAssignedClientValid = (assignedClient, clientsList) => {
    if (assignedClient && clientsList) {
        const foundClient = clientsList.find(
            (client) =>
                client[CLIENT_USER_KEYS.ID] ===
                assignedClient[CLIENT_USER_KEYS.ID]
        );
        if (foundClient) {
            return { status: "true" };
        } else {
            return { status: "false", msg: CLIENT_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedClientsValid = (assignedClients, clientsList) => {
    if (
        assignedClients &&
        assignedClients.length > 0 &&
        clientsList &&
        clientsList.length > 0
    ) {
        let allClientsValid = true;
        for (let i = 0; i < assignedClients.length; i++) {
            const assignedClient = assignedClients[i];
            const foundClient = clientsList.find(
                (client) =>
                    client[CLIENT_USER_KEYS.ID] ===
                    assignedClient[CLIENT_USER_KEYS.ID]
            );
            if (!foundClient) {
                allClientsValid = false;
                break;
            }
        }
        if (allClientsValid) {
            return { status: "true" };
        } else {
            return { status: "false", msg: CLIENTS_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedCompaniesValid = (
    assignedCompanies,
    allCompaniesIds
) => {
    if (
        allCompaniesIds &&
        allCompaniesIds.length > 0 &&
        assignedCompanies &&
        assignedCompanies.length > 0
    ) {
        let isValid = true;
        for (let i = 0; i < assignedCompanies.length; i++) {
            const assignedCompanyId = assignedCompanies[i][COMPANY_KEYS.ID];
            if (!allCompaniesIds.includes(assignedCompanyId)) {
                isValid = false;
                break;
            }
        }

        if (isValid) {
            return { status: "true" };
        } else {
            return { status: "false", msg: INVALID_ASSIGNED_COMPANIES };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedCompanyValid = (assignedCompany, companiesList) => {
    if (assignedCompany && companiesList && companiesList.length > 0) {
        const foundCompany = companiesList.find(
            (company) =>
                company[COMPANY_KEYS.ID] === assignedCompany[COMPANY_KEYS.ID]
        );
        if (foundCompany) {
            return { status: "true" };
        } else {
            return { status: "false", msg: COMPANY_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedCountryValid = (assignedCountry, countriesList) => {
    if (assignedCountry && countriesList && countriesList.length > 0) {
        const foundCountry = countriesList.find(
            (country) =>
                country[COUNTRY_KEYS.ID] === assignedCountry[COUNTRY_KEYS.ID]
        );
        if (foundCountry) {
            return { status: "true" };
        } else {
            return { status: "false", msg: COUNTRY_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedIconValid = (assignedIcon, iconsList) => {
    if (assignedIcon && iconsList) {
        const foundIcon = iconsList.find(
            (icon) => icon.label === assignedIcon.label
        );
        if (foundIcon) {
            return { status: "true" };
        } else {
            return { status: "false", msg: ICON_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedFmUserValid = (assignedFmUser, fmUsersList) => {
    if (assignedFmUser && fmUsersList) {
        const foundFmUser = fmUsersList.find(
            (fmUser) =>
                fmUser[FM_USER_KEYS.ID] === assignedFmUser[FM_USER_KEYS.ID]
        );
        if (foundFmUser) {
            return { status: "true" };
        } else {
            return { status: "false", msg: FM_USER_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedFmUsersValid = (assignedFmUsers, fmUsersList) => {
    if (
        assignedFmUsers &&
        assignedFmUsers.length > 0 &&
        fmUsersList &&
        fmUsersList.length > 0
    ) {
        let allFmUsersValid = true;
        for (let i = 0; i < assignedFmUsers.length; i++) {
            const assignedFmUser = assignedFmUsers[i];
            const foundFmUser = fmUsersList.find(
                (fmUser) =>
                    fmUser[FM_USER_KEYS.ID] === assignedFmUser[FM_USER_KEYS.ID]
            );
            if (!foundFmUser) {
                allFmUsersValid = false;
                break;
            }
        }
        if (allFmUsersValid) {
            return { status: "true" };
        } else {
            return { status: "false", msg: FM_USERS_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedFolderValid = (assignedFolder, foldersList) => {
    if (assignedFolder && foldersList && foldersList.length > 0) {
        const foundFolder = foldersList.find(
            (folder) =>
                folder[FOLDER_KEYS.ID] === assignedFolder[FOLDER_KEYS.ID]
        );
        if (foundFolder) {
            return { status: "true" };
        } else {
            return { status: "false", msg: FOLDER_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedServicePriceValid = (price) => {
    if (price) {
        if (isValidAmount(price)) {
            return { status: "true" };
        } else {
            return { status: "false", msg: AMOUNT_NOT_VALID };
        }
    } else {
        /* return { status: "false", msg: REQUIRED }; */
        return { status: "true" };
    }
};

export const checkAssignedServicePriceIntervalValid = (
    selectedInterval,
    intervalsList
) => {
    if (!intervalsList) {
        return { status: "false", msg: INTERVALS_LIST_NOT_VALID };
    }

    if (selectedInterval) {
        const foundInterval = intervalsList.find(
            (interval) => interval === selectedInterval
        );
        if (foundInterval) {
            return { status: "true" };
        } else {
            return { status: "false", msg: INTERVAL_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedServiceValid = (assignedService, servicesList) => {
    if (assignedService && servicesList) {
        const foundService = servicesList.find(
            (service) =>
                service[SERVICE_KEYS.ID] === assignedService[SERVICE_KEYS.ID]
        );
        if (foundService) {
            return { status: "true" };
        } else {
            return { status: "false", msg: SERVICE_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedSidebarUrlTypeValid = (assignedType, typesList) => {
    if (assignedType && typesList) {
        const foundType = typesList.find((type) => type === assignedType);
        if (foundType) {
            return { status: "true" };
        } else {
            return { status: "false", msg: SIDEBAR_URL_TYPE_INVALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedStateValid = (assignedState, statesList) => {
    if (assignedState && statesList && statesList.length > 0) {
        const foundState = statesList.find(
            (state) => state[STATE_KEYS.ID] === assignedState[STATE_KEYS.ID]
        );
        if (foundState) {
            return { status: "true" };
        } else {
            return { status: "false", msg: STATE_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkAssignedTimeZoneValid = (assignedTimeZone, timeZonesList) => {
    if (assignedTimeZone && timeZonesList) {
        const foundTimeZone = timeZonesList.find(
            (timeZone) =>
                timeZone[TIME_ZONE_KEYS.NAME] ===
                assignedTimeZone[TIME_ZONE_KEYS.NAME]
        );
        if (foundTimeZone) {
            return { status: "true" };
        } else {
            return { status: "false", msg: TIME_ZONE_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkBioDescriptionValid = (bioDescription) => {
    if (!bioDescription) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkBioLinkValid = (bioLink) => {
    if (!bioLink) {
        return { status: "false", msg: REQUIRED };
    } else {
        return isValidUrl(bioLink);
    }
};

export const checkButtonTextValid = (buttonText) => {
    if (!buttonText) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkCardDateValid = (mm, yy) => {
    const today = new Date(); // gets the current date

    let todayMM = today.getMonth() + 1; // extracts the month portion
    if (todayMM < 10) {
        // if today's month is less than 10
        todayMM = "0" + todayMM; // prefix it with a '0' to make it 2 digits
    }

    let todayYY = today.getFullYear() % 100; // extracts the year portion and changes it from yyyy to yy format
    todayYY = todayYY.toString();

    if (yy > todayYY || (yy === todayYY && mm >= todayMM)) {
        // all good because the yy from expiryDate is greater than the current yy
        // or if the yy from expiryDate is the same as the current yy but the mm
        // from expiryDate is greater than the current mm
        return true;
    } else {
        return false;
    }
};

export const checkCardMonthValid = (cardMonth) => {
    if (!cardMonth) {
        return { status: "false", msg: REQUIRED };
    } else if (!/^\d{2}$/.test(cardMonth)) {
        return { status: "false", msg: MONTH_NOT_VALID };
    } else if (
        !isPositiveInteger(cardMonth) ||
        parseInt(cardMonth) < 1 ||
        parseInt(cardMonth) > 12
    ) {
        return { status: "false", msg: MONTH_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkCardNumberValid = (cardNumber) => {
    if (!cardNumber) {
        return { status: "false", msg: REQUIRED };
    } else if (!isPositiveInteger(cardNumber)) {
        return { status: "false", msg: CARD_NO_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkCardYearValid = (cardYear) => {
    if (!cardYear) {
        return { status: "false", msg: REQUIRED };
    } else if (!/^\d{2}$/.test(cardYear)) {
        return { status: "false", msg: YEAR_NOT_VALID };
    } else if (!isPositiveInteger(cardYear) || parseInt(cardYear) < 1) {
        return { status: "false", msg: YEAR_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkCaseStudyDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkCaseStudyProcedureValid = (procedure) => {
    if (!procedure) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkChatFilesValid = (files) => {
    if (!files || files.length === 0) {
        return { status: "false", msg: REQUIRED };
    }

    if (files.length > VALID_CHAT_FILES_MAX_COUNT) {
        return {
            status: "false",
            msg: MAX_FILES_ERROR.replace(
                "{MAX_COUNT}",
                VALID_CHAT_FILES_MAX_COUNT
            ),
        };
    }

    const validExtensions = [
        ...VALID_FILE_AUDIO_EXTENSIONS,
        ...VALID_FILE_DOC_EXTENSIONS,
        ...VALID_FILE_GRAPHICS_EXTENSIONS,
        ...VALID_FILE_IMG_EXTENSIONS,
        ...VALID_FILE_SHEET_EXTENSIONS,
        ...VALID_FILE_VIDEO_EXTENSIONS,
    ];
    const validMimeTypes = [
        ...VALID_FILE_AUDIO_MIME_TYPES,
        ...VALID_FILE_DOC_MIME_TYPES,
        ...VALID_FILE_GRAPHICS_MIME_TYPES,
        ...VALID_FILE_IMG_MIME_TYPES,
        ...VALID_FILE_SHEET_MIME_TYPES,
        ...VALID_FILE_VIDEO_MIME_TYPES,
    ];

    return areUploadedFilesValid(
        files,
        VALID_CHAT_FILE_MAX_SIZE_MB,
        validExtensions,
        validMimeTypes
    );
};

export const checkCityValid = (city) => {
    if (!city) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkClientPhoneNoValid = (phoneNo) => {
    if (phoneNo) {
        if (!/^\d{10}$/.test(phoneNo)) {
            return { status: "false", msg: PHONE_NO_NOT_VALID };
        }
    }
    return { status: "true" };
};

export const checkCompanyClientUserValid = (
    clientUser = null,
    companyId = ""
) => {
    if (clientUser && companyId) {
        return (
            clientUser?.[CLIENT_USER_KEYS.ID] &&
            clientUser[CLIENT_USER_KEYS.ID] &&
            clientUser?.[CLIENT_USER_KEYS.COMPANY_ID] &&
            clientUser[CLIENT_USER_KEYS.COMPANY_ID] === companyId
        );
    } else {
        return false;
    }
};

export const checkCompanyNameValid = (companyName) => {
    if (!companyName) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkCompanyLogoValid = (logos) => {
    if (logos && logos.length === 1 && logos[0]) {
        return isFileValid(
            logos[0],
            15,
            VALID_IMG_EXTENSIONS,
            VALID_IMG_MIME_TYPES
        );
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkCvvValid = (cvv) => {
    if (!cvv) {
        return { status: "false", msg: REQUIRED };
    } else if (!/^\d{3}$/.test(cvv)) {
        return { status: "false", msg: CVV_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkDateValid = (date) => {
    if (!date) {
        return { status: "false", msg: REQUIRED };
    } else if (!date.isValid()) {
        return { status: "false", msg: DATE_NOT_VALID };
    } else if (isPastFromToday(date)) {
        return { status: "false", msg: PAST_DATE_NOT_ALLOWED };
    } else {
        return { status: "true" };
    }
};

export const checkCaseStudyDocumentValid = (documents) => {
    if (documents && documents.length === 1 && documents[0]) {
        return isFileValid(
            documents[0],
            15,
            VALID_IMG_EXTENSIONS,
            VALID_IMG_MIME_TYPES
        );
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkDocumentNameValid = (documentName) => {
    if (!documentName) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkEmailValid = (emailAddress) => {
    if (!emailAddress) {
        return { status: "false", msg: REQUIRED };
    } else if (!isValidEmail(emailAddress)) {
        return { status: "false", msg: EMAIL_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkEmailBodyValid = (body) => {
    if (!body) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkFlyersValid = (flyers) => {
    if (!flyers || flyers.length === 0) {
        return { status: "false", msg: REQUIRED };
    }

    return areFilesValid(
        flyers,
        VALID_COMMON_FILE_MAX_SIZE_MB,
        VALID_SPECIAL_FILE_EXTENSIONS,
        VALID_SPECIAL_FILE_MIME_TYPES
    );
};

export const checkFmUserAssignedClientsValid = (
    assignedClients,
    allClientIds
) => {
    if (assignedClients && assignedClients.length > 0) {
        if (allClientIds && allClientIds.length > 0) {
            let isValid = true;
            for (let i = 0; i < assignedClients.length; i++) {
                const assignedClientId =
                    assignedClients[i][CLIENT_USER_KEYS.ID];
                if (!allClientIds.includes(assignedClientId)) {
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                return { status: "true" };
            } else {
                return { status: "false", msg: FM_CLIENTS_NOT_VALID };
            }
        } else {
            return { status: "false", msg: REQUIRED };
        }
    } else {
        return { status: "true" };
    }
};

export const checkIndustryValid = (selectedIndustry, industriesList) => {
    if (selectedIndustry && industriesList) {
        const foundIndustry = industriesList.find(
            (industry) => industry === selectedIndustry
        );
        if (foundIndustry) {
            return { status: "true" };
        } else {
            return { status: "false", msg: INDUSTRY_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkLabelValid = (label) => {
    if (!label) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkMeetingDateValid = (date) => {
    if (!date) {
        return { status: "false", msg: REQUIRED };
    } else if (!date.isValid()) {
        return { status: "false", msg: DATE_NOT_VALID };
    } else {
        return { status: "true" };
    }
};
export const checkMeetingDateTimeValid = (date, time, timeZone) => {
    const currentDateTime = getCurrentDateTimeFormatted(
        "YYYY-MM-DD HH:mm:ss",
        timeZone
    );

    const formattedDate = getDateinDesiredFormat(date, "YYYY-MM-DD");
    /* const formattedTime = getDateinDesiredFormat(time, "HH:mm:ss"); */
    const formattedTime = time.standard;
    const meetingDateTime = `${formattedDate} ${formattedTime}`;

    if (isStartBeforeEnd(meetingDateTime, currentDateTime)) {
        return { status: "false", msg: MEETING_NOT_IN_FUTURE };
    } else {
        return { status: "true" };
    }
};

export const checkMeetingDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkMeetingLinkValid = (link) => {
    if (!link) {
        return { status: "false", msg: REQUIRED };
    } else {
        return isValidUrl(link);
    }
};

export const checkMeetingNameValid = (name) => {
    if (!name) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkMeetingTimeValid = (time) => {
    if (!time) {
        return { status: "false", msg: REQUIRED };
    } else if (!time.isValid()) {
        return { status: "false", msg: TIME_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkAssignedMeetingTimeValid = (assignedTime, timeList) => {
    if (assignedTime && timeList) {
        const foundTime = timeList.find(
            (time) => time.value === assignedTime.value
        );
        if (foundTime) {
            return { status: "true" };
        } else {
            return { status: "false", msg: TIME_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkMessageValid = (message) => {
    if (!message) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkNameValid = (name) => {
    if (!name) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkNoteValid = (note) => {
    if (note) {
        if (note.length > 255) {
            return {
                status: "false",
                msg: "Note cannot be more than 255 characters long",
            };
        } else {
            return { status: "true" };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkNewTimeResponsesValid = (selectedResponse, responsesList) => {
    if (selectedResponse && responsesList) {
        const foundResponse = responsesList.find(
            (response) => response === selectedResponse
        );
        if (foundResponse) {
            return { status: "true" };
        } else {
            return { status: "false", msg: RESPONSE_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkPasswordValid = (password) => {
    if (!password) {
        return { status: "false", msg: REQUIRED };
    } else if (password.length < 6) {
        return { status: "false", msg: PASSWORD_RULES_MISMATCH };
    } else {
        return { status: "true" };
    }
};

export const checkPasswordsMatch = (passwordOne, passwordTwo) => {
    if (passwordOne === passwordTwo) {
        return { status: "true" };
    } else {
        return { status: "false", msg: PASSWORD_MISMATCH };
    }
};

export const checkPhoneNoValid = (phoneNo) => {
    if (!phoneNo) {
        return { status: "false", msg: REQUIRED };
    } else if (!/^\d{10}$/.test(phoneNo)) {
        return { status: "false", msg: PHONE_NO_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkPhoneNoExtValid = (phoneNoExt) => {
    if (!phoneNoExt) {
        return { status: "false", msg: REQUIRED };
    } else if (!/^\d+$/.test(phoneNoExt)) {
        return { status: "false", msg: PHONE_NO_EXT_NOT_VALID };
    } else {
        return { status: "true" };
    }
};

export const checkProfilePhotoValid = (profilePhotos) => {
    if (profilePhotos && profilePhotos.length === 1 && profilePhotos[0]) {
        return isFileValid(
            profilePhotos[0],
            15,
            VALID_IMG_EXTENSIONS,
            VALID_IMG_MIME_TYPES
        );
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkPositionValid = (position) => {
    if (!position) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkProtocolValid = (protocol) => {
    if (!protocol) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkRejectionCommentsValid = (comments) => {
    if (!comments) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkReportDocumentValid = (documents) => {
    if (documents && documents.length === 1 && documents[0]) {
        return isFileValid(
            documents[0],
            25,
            VALID_REPORT_EXTENSIONS,
            VALID_REPORT_MIME_TYPES
        );
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkRoleValid = (role) => {
    if (!role) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkServiceNameValid = (name) => {
    if (!name) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkServiceDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkServicePriceValid = (price) => {
    if (price) {
        if (isValidAmount(price)) {
            return { status: "true" };
        } else {
            return { status: "false", msg: AMOUNT_NOT_VALID };
        }
    } else {
        return { status: "true" };
    }
};

export const checkServicePriceIntervalValid = (
    selectedInterval,
    intervalsList,
    required = true
) => {
    if (!intervalsList) {
        return { status: "false", msg: INTERVALS_LIST_NOT_VALID };
    }

    if (selectedInterval) {
        const foundInterval = intervalsList.find(
            (interval) => interval === selectedInterval
        );
        if (foundInterval) {
            return { status: "true" };
        } else {
            return { status: "false", msg: INTERVAL_NOT_VALID };
        }
    } else {
        if (required) {
            return { status: "false", msg: REQUIRED };
        } else {
            return { status: "true" };
        }
    }
};

export const checkServiceWorkOrderValid = (workOrder) => {
    /* if (!workOrder) {
        return { status: "false", msg: REQUIRED };
    } else { */
    return { status: "true" };
    /* } */
};

export const checkSheetLinkValid = (sheetLink) => {
    if (sheetLink) {
        return isValidUrl(sheetLink);
    } else {
        /* return { status: "false", msg: REQUIRED }; */
        return true;
    }
};

export const checkSidebarIsActiveValid = (value, choices) => {
    if (value && choices) {
        const foundChoice = choices.find((choice) => choice === value);
        if (foundChoice) {
            return { status: "true" };
        } else {
            return { status: "false", msg: SIDEBAR_IS_ACTIVE_INVALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkSidebarOpensInNewTabValid = (value, choices) => {
    if (value && choices) {
        const foundChoice = choices.find((choice) => choice === value);
        if (foundChoice) {
            return { status: "true" };
        } else {
            return { status: "false", msg: SIDEBAR_NEW_TAB_INVALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkSmtpHostValid = (smtpHost) => {
    if (!smtpHost) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSmtpPassValid = (smtpPass) => {
    if (!smtpPass) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSmtpPortValid = (smtpPort) => {
    if (!smtpPort) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSmtpReplyToValid = (smtpReplyTo) => {
    if (!smtpReplyTo) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSmtpUserValid = (smtpUser) => {
    if (!smtpUser) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSpecialDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSpecialDocumentsValid = (documents) => {
    if (!documents || documents.length === 0) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSpecialMonthValid = (month, monthsList) => {
    if (month && monthsList) {
        const foundMonth = monthsList.find(
            (tempMonth) => tempMonth.value === month
        );
        if (foundMonth) {
            return { status: "true" };
        } else {
            return { status: "false", msg: MONTH_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkTitleValid = (title) => {
    if (!title) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSidebarUrlValid = (url, isUrlExternal) => {
    if (!url) {
        return { status: "false", msg: REQUIRED };
    }

    if (isUrlExternal) {
        return isValidUrl(url);
    } else {
        if (isUrlAbsolute(url)) {
            return {
                status: "false",
                msg: "Only relative URLs are allowed for internal links",
            };
        } else {
            return { status: "true" };
        }
    }
};

export const checkStateValid = (state) => {
    if (!state) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSubjectValid = (subject) => {
    if (!subject) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSurveyNameValid = (name) => {
    if (!name) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSurveyDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkSurveyQuestionAnswersValid = (answers, questionType) => {
    if (answers && answers.length > 0) {
        if (
            questionType === SURVEY_QUESTION_TYPE_SINGLE_SELECT &&
            answers.length > 1
        ) {
            return { status: "false", msg: SINGLE_ANSWER_REQUIRED };
        } else {
            return { status: "true" };
        }
    } else {
        return { status: "false", msg: ANSWER_REQUIRED };
    }
};

export const checkSurveyQuestionOptionValid = (option, options) => {
    if (!option) {
        return { status: "false", msg: REQUIRED };
    } else if (options.includes(option)) {
        return { status: "false", msg: DUPLICATE_OPTIONS_ERROR };
    } else {
        return { status: "true" };
    }
};

export const checkSurveyQuestionOptionsValid = (options) => {
    if (options && options.length > 0) {
        let optionTextValid = true;
        for (let i = 0; i < options.length; i++) {
            if (!options[i][SURVEY_QUESTION_OPTION_KEYS.TITLE]) {
                optionTextValid = false;
                break;
            }
        }

        if (optionTextValid) {
            return { status: "true" };
        } else {
            return { status: "false", msg: OPTIONS_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkSurveyQuestionTitleValid = (title, titles, currentTitle) => {
    if (!title) {
        return { status: "false", msg: REQUIRED };
    } else {
        let titleIsDuplicate = false;
        if (currentTitle) {
            const currentTitleUpdated = title !== currentTitle;
            if (currentTitleUpdated) {
                if (titles.includes(title)) {
                    titleIsDuplicate = true;
                }
            }
        } else if (titles.includes(title)) {
            titleIsDuplicate = true;
        }

        if (titleIsDuplicate) {
            return { status: "false", msg: DUPLICATE_QUESTIONS_ERROR };
        } else {
            return { status: "true" };
        }
    }
};

export const checkSurveyQuestionTypeValid = (assignedType, typesList) => {
    if (assignedType && typesList) {
        const foundType = typesList.find((type) => type === assignedType);
        if (foundType) {
            return { status: "true" };
        } else {
            return { status: "false", msg: SURVEY_QUES_TYPE_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkSurveyQuestionsValid = (questions) => {
    if (questions && questions.length > 0) {
        return { status: "true" };
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkTicketDescriptionValid = (description) => {
    if (!description) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkTicketPriorityValid = (priority, priorityList) => {
    if (priority && priorityList) {
        const foundPriority = priorityList.find(
            (tempPriority) => tempPriority === priority
        );
        if (foundPriority) {
            return { status: "true" };
        } else {
            return { status: "false", msg: STATUS_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkTicketStatusValid = (status, statusList) => {
    if (status && statusList) {
        const foundStatus = statusList.find(
            (tempStatus) => tempStatus === status
        );
        if (foundStatus) {
            return { status: "true" };
        } else {
            return { status: "false", msg: STATUS_NOT_VALID };
        }
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkTicketSubjectValid = (subject) => {
    if (!subject) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkWebsiteValid = (website) => {
    if (website) {
        return isValidUrl(website);
    } else {
        return { status: "false", msg: REQUIRED };
    }
};

export const checkWorkOrderDocumentsValid = (documents) => {
    if (!documents || documents.length === 0) {
        return { status: "false", msg: REQUIRED };
    }

    return areFilesValid(
        documents,
        VALID_COMMON_FILE_MAX_SIZE_MB,
        VALID_WO_FILE_EXTENSIONS,
        VALID_WO_FILE_MIME_TYPES
    );
};

export const checkWODeletedDocumentsValid = (deletedDocuments) => {
    if (!deletedDocuments || deletedDocuments.length === 0) {
        return { status: "false", msg: DELETED_DOCUMENTS_REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const checkZipcodeValid = (zipcode) => {
    if (!zipcode) {
        return { status: "false", msg: REQUIRED };
    } else {
        return { status: "true" };
    }
};

export const isFloat = (value) => !isNaN(value) && !isNaN(parseFloat(value));

export const isInteger = (value) => !isNaN(value) && !isNaN(parseInt(value));

export const isPositiveInteger = (value) =>
    isInteger(value) && parseInt(value) > 0;

export const isValidAmount = (value) =>
    isFloat(value) &&
    parseFloat(value) >= 0 &&
    !Object.is(parseFloat(value), -0);

export const isValidUrl = (link) => {
    let url;
    try {
        url = new URL(link);
        return { status: "true" };
    } catch (err) {
        return {
            status: "false",
            msg:
                err.message.replace("URL constructor: ", "") +
                " Make sure you are using the HTTP protocol (http, https).",
        };
    }
};

export const isFileValid = (
    file = null,
    maxValidSizeMB = 0,
    validExtensions = [],
    validMimeTypes = []
) => {
    if (
        !file ||
        !maxValidSizeMB ||
        validExtensions.length === 0 ||
        validMimeTypes.length === 0
    ) {
        return { status: "false", msg: REQUIRED };
    }

    const fileName = file.name;
    const extension = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
    const mimeType = file.type;
    const size = file.size;

    if (!validExtensions.includes(extension)) {
        let errorMsg = FILE_EXTENSION_NOT_VALID;
        errorMsg = errorMsg.replace(
            "{extensionsList}",
            validExtensions.join(", ")
        );

        return { status: "false", msg: errorMsg };
    }

    /**
     * .psd, .sketch files sometimes have empty mime type
     */
    if (!(["psd", "sketch", "xd"].includes(extension) && mimeType === "")) {
        if (!validMimeTypes.includes(mimeType)) {
            let errorMsg = FILE_EXTENSION_NOT_VALID;
            errorMsg = errorMsg.replace(
                "{extensionsList}",
                validMimeTypes.join(", ")
            );

            return { status: "false", msg: errorMsg };
        }
    }

    if (size > maxValidSizeMB * 1024 * 1024) {
        let errorMsg = FILE_SIZE_NOT_VALID;
        errorMsg = errorMsg.replace("{size}", maxValidSizeMB);
        errorMsg = errorMsg.replace("{sizeUnits}", "MB");

        return { status: "false", msg: errorMsg };
    }

    return { status: "true" };
};

export const areFilesValid = (
    files = [],
    maxValidSizeMB = 0,
    validExtensions = [],
    validMimeTypes = []
) => {
    if (
        !files ||
        files.length === 0 ||
        !maxValidSizeMB ||
        validExtensions.length === 0 ||
        validMimeTypes.length === 0
    ) {
        return { status: "false", msg: REQUIRED };
    }

    let filesAreValid = true;
    let filesErrorMsg = "";
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const extension = fileName
            .substring(fileName.lastIndexOf(".") + 1)
            .toLowerCase();
        const mimeType = file.type;
        const size = file.size;

        if (!validExtensions.includes(extension)) {
            filesAreValid = false;

            filesErrorMsg = FILE_EXTENSION_NOT_VALID;
            filesErrorMsg = filesErrorMsg.replace(
                "{extensionsList}",
                validExtensions.join(", ")
            );

            break;
        }

        if (!validMimeTypes.includes(mimeType)) {
            filesAreValid = false;

            filesErrorMsg = FILE_EXTENSION_NOT_VALID;
            filesErrorMsg = filesErrorMsg.replace(
                "{extensionsList}",
                validMimeTypes.join(", ")
            );

            break;
        }

        if (size > maxValidSizeMB * 1024 * 1024) {
            filesAreValid = false;

            filesErrorMsg = FILE_SIZE_NOT_VALID;
            filesErrorMsg = filesErrorMsg.replace("{size}", maxValidSizeMB);
            filesErrorMsg = filesErrorMsg.replace("{sizeUnits}", "MB");

            break;
        }
    }

    if (filesAreValid) {
        return { status: "true" };
    } else {
        return { status: "false", msg: filesErrorMsg };
    }
};

export const areUploadedFilesValid = (
    files = [],
    maxValidSizeMB = 0,
    validExtensions = [],
    validMimeTypes = []
) => {
    if (
        !files ||
        files.length === 0 ||
        !maxValidSizeMB ||
        validExtensions.length === 0 ||
        validMimeTypes.length === 0
    ) {
        return { status: "false", msg: REQUIRED };
    }

    let filesAreValid = true;
    const results = [];
    for (let i = 0; i < files.length; i++) {
        const fileErrors = [];

        const file = files[i];
        const fileName = file.name;
        const extension = fileName
            .substring(fileName.lastIndexOf(".") + 1)
            .toLowerCase();
        const mimeType = file.type;
        const size = file.size;

        if (!validExtensions.includes(extension)) {
            filesAreValid = false;

            fileErrors.push(
                FILE_EXTENSION_NOT_VALID.replace(
                    "{extensionsList}",
                    validExtensions.join(", ")
                )
            );
        } else if (!validMimeTypes.includes(mimeType)) {
            filesAreValid = false;

            fileErrors.push(
                FILE_EXTENSION_NOT_VALID.replace(
                    "{extensionsList}",
                    validMimeTypes.join(", ")
                )
            );
        }

        if (size > maxValidSizeMB * 1024 * 1024) {
            filesAreValid = false;

            let tempSizeError = FILE_SIZE_NOT_VALID;
            tempSizeError = tempSizeError.replace("{size}", maxValidSizeMB);
            tempSizeError = tempSizeError.replace("{sizeUnits}", "MB");

            fileErrors.push(tempSizeError);
        }

        results.push({ file: file, errors: fileErrors });
    }

    if (filesAreValid) {
        return { status: "true" };
    } else {
        return {
            status: "false",
            msg: "One or more files are not valid!",
            results: results,
        };
    }
};

const isUrlAbsolute = (url) => {
    if (url.indexOf("//") === 0) {
        return true;
    } // URL is protocol-relative (= absolute)
    if (url.indexOf("://") === -1) {
        return false;
    } // URL has no protocol (= relative)
    if (url.indexOf(".") === -1) {
        return false;
    } // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
    if (url.indexOf("/") === -1) {
        return false;
    } // URL does not contain a single slash (= relative)
    if (url.indexOf(":") > url.indexOf("/")) {
        return false;
    } // The first colon comes after the first slash (= relative)
    if (url.indexOf("://") < url.indexOf(".")) {
        return true;
    } // Protocol is defined before first dot (= absolute)
    return false; // Anything else must be relative
};
