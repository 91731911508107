import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const Logout = () => {
    const navigate = useNavigate();
    const { setAuthToken } = useJumboAuth();

    useEffect(() => {
        setAuthToken(null);
        navigate("/login");
    }, [navigate, setAuthToken]);

    return null;
};

export default Logout;
