import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

const btnSx = {
    letterSpacing: 0,
    textTransform: "capitalize",
    textDecoration: "none",
    fontSize: (theme) => theme.typography.h6.fontSize,
    fontWeight: (theme) => theme.typography.fontWeightBold,
};

const TableColorCard = ({
    actions,
    cardColor,
    children,
    subtitle,
    sx,
    title,
}) => {
    const cardSx = sx?.cardSx || {};

    return (
        <Card
            sx={(theme) => ({
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
                border: `2px solid ${
                    cardColor || theme.palette.secondary.main
                }`,
                borderRadius: "10px",
                opacity: 1,
                ...cardSx,
            })}
        >
            <CardHeader
                title={title}
                subheader={subtitle}
                sx={(theme) => ({
                    padding: theme.spacing(3),
                    backgroundColor: cardColor || theme.palette.secondary.main,
                    "& .MuiCardHeader-content": {
                        textAlign: "center",
                    },
                    "& .MuiCardHeader-title": {
                        fontSize: "1.875rem",
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.background.paper,
                    },
                    "& .MuiCardHeader-subheader": {
                        fontSize: "1.5rem",
                        color: theme.palette.background.paper,
                    },
                })}
            />
            <CardContent sx={{ p: 0 }}>{children}</CardContent>
            {actions && actions.length > 0 && (
                <CardActions
                    sx={{
                        justifyContent: "center",
                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                >
                    {actions.map((action, idx) => {
                        const actionCommonSx =
                            action?.sx && action?.sx?.commonSx
                                ? action.sx.commonSx
                                : "";
                        const actionXlSx =
                            action?.sx && action?.sx?.xlSx
                                ? action.sx.xlSx
                                : "";
                        const actionLgSx =
                            action?.sx && action?.sx?.lgSx
                                ? action.sx.lgSx
                                : "";
                        const actionSmSx =
                            action?.sx && action?.sx?.smSx
                                ? action.sx.smSx
                                : "";

                        return (
                            <Button
                                key={action.id}
                                component={action.type}
                                type={action.buttonType}
                                variant={action.variant}
                                size={action?.size || "small"}
                                color={action?.color || "primary"}
                                href={
                                    action?.href && action.href
                                        ? action.href
                                        : ""
                                }
                                to={action?.to && action.to ? action.to : ""}
                                onClick={
                                    action?.onClick && action.onClick
                                        ? action.onClick
                                        : () => {}
                                }
                                sx={(theme) => ({
                                    ...btnSx,
                                    ...actionCommonSx,
                                    [theme.breakpoints.down("xl")]: {
                                        ...actionXlSx,
                                    },
                                    [theme.breakpoints.down("lg")]: {
                                        ...actionLgSx,
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        ...actionSmSx,
                                    },
                                })}
                            >
                                {action.label}
                            </Button>
                        );
                    })}
                </CardActions>
            )}
        </Card>
    );
};

TableColorCard.propTypes = {
    actions: PropTypes.array,
    cardColor: PropTypes.string,
    subtitle: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.string,
};

TableColorCard.defaultProps = {
    actions: [],
    cardColor: "",
    subtitle: "",
    sx: {},
    title: "",
};

export default TableColorCard;
