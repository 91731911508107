import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const EmptyValue = ({ align, content }) => {
    if (align) {
        return <Typography align={align}>{content}</Typography>;
    } else {
        return content;
    }
};

EmptyValue.propTypes = {
    align: PropTypes.string,
    content: PropTypes.node,
};

EmptyValue.defaultProps = {
    align: "",
    content: <React.Fragment>&#8211;</React.Fragment>,
};

export default EmptyValue;
