import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    getEmailSettingsLocal,
    saveEmailSettings,
} from "../../redux/actions/emailSettings";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    EMAIL_SETTINGS_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import {
    /* checkProtocolValid, */
    checkSmtpHostValid,
    checkSmtpPassValid,
    checkSmtpPortValid,
    checkSmtpReplyToValid,
    checkSmtpUserValid,
} from "../../utils/validationHelpers";
import TestEmailSettingsDialog from "./TestEmailSettingsDialog";

const pageName = "SMTP Email Settings";

const EmailSettings = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isEmailSettingsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [fetchingOnLoad, setFetchingOnLoad] = useState(false);
    const [fetchingOnLoadError, setFetchingOnLoadError] = useState("");
    const [smtpEnabled, setSmtpEnabled] = useState(false);
    const [smtpEnabledError, setSmtpEnabledError] = useState("");
    /* const [protocol, setProtocol] = useState("");
    const [protocolError, setProtocolError] = useState(""); */
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpHostError, setSmtpHostError] = useState("");
    const [smtpUser, setSmtpUser] = useState("");
    const [smtpUserError, setSmtpUserError] = useState("");
    const [smtpPass, setSmtpPass] = useState("");
    const [smtpPassError, setSmtpPassError] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [smtpPortError, setSmtpPortError] = useState("");
    const [smtpReplyTo, setSmtpReplyTo] = useState("");
    const [smtpReplyToError, setSmtpReplyToError] = useState("");
    const [smtpReplyToName, setSmtpReplyToName] = useState("");
    const [smtpReplyToNameError, setSmtpReplyToNameError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const [openTestEmailSettingsDialog, setOpenTestEmailSettingsDialog] =
        useState(false);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isValidUserType = isAdminUser;

    useEffect(() => {
        return () => {
            _isEmailSettingsMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isValidUserType) {
            setFetchingOnLoad(true);
        }
    }, [authUserType, isValidUserType]);

    useEffect(() => {
        let isActive = true;

        if (fetchingOnLoad) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getEmailSettingsLocal(payload, (fetchedData) => {
                            if (isActive) {
                                const settings = fetchedData?.settings;
                                if (settings) {
                                    setSmtpEnabled(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS.SMTP_ENABLED
                                        ] === "yes" || false
                                    );
                                    setSmtpHost(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS.SMTP_HOST
                                        ] || ""
                                    );
                                    setSmtpPass(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS.SMTP_PASS
                                        ] || ""
                                    );
                                    setSmtpPort(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS.SMTP_PORT
                                        ] || ""
                                    );
                                    setSmtpReplyTo(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO
                                        ] || ""
                                    );
                                    setSmtpReplyToName(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS
                                                .SMTP_REPLY_TO_NAME
                                        ] || ""
                                    );
                                    setSmtpUser(
                                        settings?.[
                                            EMAIL_SETTINGS_KEYS.SMTP_USER
                                        ] || ""
                                    );
                                }

                                setFetchingOnLoad(false);
                            }
                        })
                    );
                };
            };

            const emailSettingsData = {
                authcode: authToken,
            };

            const promise = dispatch(
                fetchData({
                    emailSettingsData: emailSettingsData,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setFetchingOnLoadError("There was a problem");
                    setFetchingOnLoad(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchingOnLoad]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    /* case EMAIL_SETTINGS_KEYS.PROTOCOL:
                        setProtocolError(msg);
                        break; */

                    case EMAIL_SETTINGS_KEYS.SMTP_ENABLED:
                        setSmtpEnabledError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.SMTP_HOST:
                        setSmtpHostError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.SMTP_PASS:
                        setSmtpPassError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.SMTP_PORT:
                        setSmtpPortError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO:
                        setSmtpReplyToError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO_NAME:
                        setSmtpReplyToNameError(msg);
                        break;

                    case EMAIL_SETTINGS_KEYS.SMTP_USER:
                        setSmtpUserError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const smtpEnabledChangeHandler = (event) => {
        setSmtpEnabled(event.target.checked);
    };

    /* const protocolBlurHandler = (event) => {
        const validationResult = checkProtocolValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setProtocolError(validationResult.msg);
            }
        } else {
            setProtocolError(VALIDATION_FAILED);
        }
    }; */

    const smtpHostBlurHandler = (event) => {
        const validationResult = checkSmtpHostValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSmtpHostError(validationResult.msg);
            }
        } else {
            setSmtpHostError(VALIDATION_FAILED);
        }
    };

    const smtpPassBlurHandler = (event) => {
        const validationResult = checkSmtpPassValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSmtpPassError(validationResult.msg);
            }
        } else {
            setSmtpPassError(VALIDATION_FAILED);
        }
    };

    const smtpPortBlurHandler = (event) => {
        const validationResult = checkSmtpPortValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSmtpPortError(validationResult.msg);
            }
        } else {
            setSmtpPortError(VALIDATION_FAILED);
        }
    };

    const smtpReplyToBlurHandler = (event) => {
        const validationResult = checkSmtpReplyToValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSmtpReplyToError(validationResult.msg);
            }
        } else {
            setSmtpReplyToError(VALIDATION_FAILED);
        }
    };

    const smtpReplyToNameBlurHandler = (event) => {
        const validationResult = checkSmtpReplyToValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSmtpReplyToNameError(validationResult.msg);
            }
        } else {
            setSmtpReplyToNameError(VALIDATION_FAILED);
        }
    };

    const smtpUserBlurHandler = (event) => {
        const validationResult = checkSmtpUserValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setSmtpUserError(validationResult.msg);
            }
        } else {
            setSmtpUserError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        /* const protocolValidationResult = checkProtocolValid(protocol);
        if (protocolValidationResult.status) {
            if (protocolValidationResult.status === "false") {
                formIsValid = false;
                setProtocolError(protocolValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setProtocolError(VALIDATION_FAILED);
        } */

        const smtpHostValidationResult = checkSmtpHostValid(smtpHost);
        if (smtpHostValidationResult.status) {
            if (smtpHostValidationResult.status === "false") {
                formIsValid = false;
                setSmtpHostError(smtpHostValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSmtpHostError(VALIDATION_FAILED);
        }

        const smtpPortValidationResult = checkSmtpPortValid(smtpPort);
        if (smtpPortValidationResult.status) {
            if (smtpPortValidationResult.status === "false") {
                formIsValid = false;
                setSmtpPortError(smtpPortValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSmtpPortError(VALIDATION_FAILED);
        }

        const smtpReplyToValidationResult = checkSmtpReplyToValid(smtpReplyTo);
        if (smtpReplyToValidationResult.status) {
            if (smtpReplyToValidationResult.status === "false") {
                formIsValid = false;
                setSmtpReplyToError(smtpReplyToValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSmtpReplyToError(VALIDATION_FAILED);
        }

        const smtpReplyToNameValidationResult =
            checkSmtpReplyToValid(smtpReplyToName);
        if (smtpReplyToNameValidationResult.status) {
            if (smtpReplyToNameValidationResult.status === "false") {
                formIsValid = false;
                setSmtpReplyToNameError(smtpReplyToNameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSmtpReplyToNameError(VALIDATION_FAILED);
        }

        const smtpPassValidationResult = checkSmtpPassValid(smtpPass);
        if (smtpPassValidationResult.status) {
            if (smtpPassValidationResult.status === "false") {
                formIsValid = false;
                setSmtpPassError(smtpPassValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSmtpPassError(VALIDATION_FAILED);
        }

        const smtpUserValidationResult = checkSmtpUserValid(smtpUser);
        if (smtpUserValidationResult.status) {
            if (smtpUserValidationResult.status === "false") {
                formIsValid = false;
                setSmtpUserError(smtpUserValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setSmtpUserError(VALIDATION_FAILED);
        }

        if (isValidUserType && formIsValid) {
            const emailSettingsData = {
                authcode: authToken,
                /* [EMAIL_SETTINGS_KEYS.PROTOCOL]: protocol, */
                [EMAIL_SETTINGS_KEYS.SMTP_ENABLED]: smtpEnabled ? "yes" : "no",
                [EMAIL_SETTINGS_KEYS.SMTP_HOST]: smtpHost,
                [EMAIL_SETTINGS_KEYS.SMTP_PASS]: smtpPass,
                [EMAIL_SETTINGS_KEYS.SMTP_PORT]: smtpPort,
                [EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO]: smtpReplyTo,
                [EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO_NAME]: smtpReplyToName,
                [EMAIL_SETTINGS_KEYS.SMTP_USER]: smtpUser,
            };

            dispatch(
                saveEmailSettings(
                    {
                        emailSettingsData: emailSettingsData,
                    },
                    (updatedData) => {
                        if (updatedData) {
                            const settings = updatedData?.settings;
                            if (settings) {
                                setSmtpEnabled(
                                    settings?.[
                                        EMAIL_SETTINGS_KEYS.SMTP_ENABLED
                                    ] === "yes" || false
                                );
                                setSmtpHost(
                                    settings?.[EMAIL_SETTINGS_KEYS.SMTP_HOST] ||
                                        ""
                                );
                                setSmtpPass(
                                    settings?.[EMAIL_SETTINGS_KEYS.SMTP_PASS] ||
                                        ""
                                );
                                setSmtpPort(
                                    settings?.[EMAIL_SETTINGS_KEYS.SMTP_PORT] ||
                                        ""
                                );
                                setSmtpReplyTo(
                                    settings?.[
                                        EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO
                                    ] || ""
                                );
                                setSmtpReplyToName(
                                    settings?.[
                                        EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO_NAME
                                    ] || ""
                                );
                                setSmtpUser(
                                    settings?.[EMAIL_SETTINGS_KEYS.SMTP_USER] ||
                                        ""
                                );
                            }
                        }
                    },
                    (messages) => {
                        if (_isEmailSettingsMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const handleOpenTestSettings = () => {
        setOpenTestEmailSettingsDialog(true);
    };

    const handleCloseTestSettings = () => {
        setOpenTestEmailSettingsDialog(false);
    };

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle type="v2" component="h1" title={pageName} />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {!fetchingOnLoad && !isValidUserType && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                )}
                {!fetchingOnLoad && isValidUserType && fetchingOnLoadError && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {fetchingOnLoadError}
                        </Alert>
                    </Grid>
                )}
                {!fetchingOnLoad && isValidUserType && !fetchingOnLoadError && (
                    <Grid item xs={12}>
                        <Card sx={{ px: 2.5, py: 5, borderRadius: "5px" }}>
                            <form onSubmit={handleFormSubmit}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        {miscellaneousErrors &&
                                            miscellaneousErrors.length > 0 && (
                                                <Grid item xs={12}>
                                                    {miscellaneousErrors.map(
                                                        (
                                                            miscellaneousError,
                                                            idx
                                                        ) => (
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                color="error"
                                                                gutterBottom
                                                                key={`misc-error-${idx}`}
                                                            >
                                                                {
                                                                    miscellaneousError
                                                                }
                                                            </Typography>
                                                        )
                                                    )}
                                                </Grid>
                                            )}
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                smtpEnabled
                                                            }
                                                            onChange={
                                                                smtpEnabledChangeHandler
                                                            }
                                                            inputProps={{
                                                                "aria-label":
                                                                    "SMTP Enabled",
                                                            }}
                                                        />
                                                    }
                                                    label="SMTP Enabled"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id={
                                                    EMAIL_SETTINGS_KEYS.SMTP_HOST
                                                }
                                                name={
                                                    EMAIL_SETTINGS_KEYS.SMTP_HOST
                                                }
                                                label="SMTP Host"
                                                value={smtpHost}
                                                margin="dense"
                                                onChange={(e) => {
                                                    setSmtpHost(e.target.value);
                                                    setSmtpHostError("");
                                                }}
                                                onBlur={smtpHostBlurHandler}
                                                error={smtpHostError !== ""}
                                                helperText={smtpHostError}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                type="password"
                                                variant="outlined"
                                                id={
                                                    EMAIL_SETTINGS_KEYS.SMTP_PASS
                                                }
                                                name={
                                                    EMAIL_SETTINGS_KEYS.SMTP_PASS
                                                }
                                                label="SMTP Password"
                                                value={smtpPass}
                                                margin="dense"
                                                onChange={(e) => {
                                                    setSmtpPass(e.target.value);
                                                    setSmtpPassError("");
                                                }}
                                                onBlur={smtpPassBlurHandler}
                                                error={smtpPassError !== ""}
                                                helperText={smtpPassError}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id={
                                                    EMAIL_SETTINGS_KEYS.SMTP_USER
                                                }
                                                name={
                                                    EMAIL_SETTINGS_KEYS.SMTP_USER
                                                }
                                                label="SMTP User"
                                                value={smtpUser}
                                                margin="dense"
                                                onChange={(e) => {
                                                    setSmtpUser(e.target.value);
                                                    setSmtpUserError("");
                                                }}
                                                onBlur={smtpUserBlurHandler}
                                                error={smtpUserError !== ""}
                                                helperText={smtpUserError}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id={
                                                    EMAIL_SETTINGS_KEYS.SMTP_PORT
                                                }
                                                name={
                                                    EMAIL_SETTINGS_KEYS.SMTP_PORT
                                                }
                                                label="SMTP Port"
                                                value={smtpPort}
                                                margin="dense"
                                                onChange={(e) => {
                                                    setSmtpPort(e.target.value);
                                                    setSmtpPortError("");
                                                }}
                                                onBlur={smtpPortBlurHandler}
                                                error={smtpPortError !== ""}
                                                helperText={smtpPortError}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id={
                                                    EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO
                                                }
                                                name={
                                                    EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO
                                                }
                                                label="Reply To"
                                                value={smtpReplyTo}
                                                margin="dense"
                                                onChange={(e) => {
                                                    setSmtpReplyTo(
                                                        e.target.value
                                                    );
                                                    setSmtpReplyToError("");
                                                }}
                                                onBlur={smtpReplyToBlurHandler}
                                                error={smtpReplyToError !== ""}
                                                helperText={smtpReplyToError}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id={
                                                    EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO_NAME
                                                }
                                                name={
                                                    EMAIL_SETTINGS_KEYS.SMTP_REPLY_TO_NAME
                                                }
                                                label="Reply To Name"
                                                value={smtpReplyToName}
                                                margin="dense"
                                                onChange={(e) => {
                                                    setSmtpReplyToName(
                                                        e.target.value
                                                    );
                                                    setSmtpReplyToNameError("");
                                                }}
                                                onBlur={
                                                    smtpReplyToNameBlurHandler
                                                }
                                                error={
                                                    smtpReplyToNameError !== ""
                                                }
                                                helperText={
                                                    smtpReplyToNameError
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display="flex"
                                                flexDirection={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                                alignItems={{
                                                    xs: "start",
                                                    md: "baseline",
                                                }}
                                                justifyContent={{
                                                    xs: "start",
                                                    md: "space-between",
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{
                                                        mb: { xs: 1, md: 0 },
                                                    }}
                                                >
                                                    Save Settings
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={
                                                        !(
                                                            smtpEnabled &&
                                                            !smtpHostError &&
                                                            !smtpPassError &&
                                                            !smtpPortError &&
                                                            !smtpReplyToError &&
                                                            !smtpReplyToNameError &&
                                                            !smtpUserError
                                                        )
                                                    }
                                                    onClick={
                                                        handleOpenTestSettings
                                                    }
                                                >
                                                    Test Settings
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </form>
                        </Card>
                    </Grid>
                )}
            </Grid>
            {!fetchingOnLoad && isValidUserType && !fetchingOnLoadError && (
                <React.Fragment>
                    {openTestEmailSettingsDialog && (
                        <TestEmailSettingsDialog
                            open={openTestEmailSettingsDialog}
                            onClose={handleCloseTestSettings}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default EmailSettings;
