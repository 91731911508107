import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    approveFlyer,
    getSpecialLocalState,
    getSpecialChat,
    sendSpecialMessage,
} from "../../redux/actions/specials";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    AUTH_USER_KEYS,
    APP_NAME,
    EMPTY_PARAM_VALUE,
    FLYER_APPROVED_STATUS,
    SPECIAL_KEYS,
    SPECIAL_CHAT_MESSAGES_KEYS,
    SPECIAL_FLYER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkChatFilesValid } from "../../utils/validationHelpers";
import AddEditFlyerDialog from "../specials/AddEditFlyerDialog";
import AddEditSpecialDialog from "../specials/AddEditSpecialDialog";
import ChatSection from "../chatSection/ChatSection";
import FlyerHeader from "./FlyerHeader";
import SpecialActions from "./SpecialActions";
import SpecialDescriptionDialog from "./SpecialDescriptionDialog";

const genericPageName = "Special";

const INIT_CHAT_STATE = {
    chatFetched: false,
    fetchingChat: false,
    firstLoad: true,
    messages: [],
    refresh: false,
    sendingMsg: false,
};

const chatReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, chatFetched: false, fetchingChat: true };
        } else if (action.type === "REFRESH") {
            return {
                ...state,
                chatFetched: false,
                fetchingChat: true,
                refresh: true,
            };
        } else if (action.type === "SEND_MESSAGE") {
            return {
                ...state,
                sendingMsg: true,
            };
        } else if (action.type === "SET_DATA") {
            return {
                ...state,
                chatFetched: true,
                messages: action.messages,
                refresh: false,
                sendingMsg: false,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchingChat: false, firstLoad: false };
        } else if (action.type === "STOP_SEND_MESSAGE") {
            return {
                ...state,
                sendingMsg: false,
            };
        }
    }

    return INIT_CHAT_STATE;
};

const INIT_CHAT_MSG_STATE = {
    message: "",
    messageErrors: [],
    messageFiles: [],
    messageFilesErrors: [],
    messageFilesErrorMsg: "",
};

const chatMsgReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "REMOVE_MESSAGE_FILE") {
            const idx = action.idx;
            const currentMsgFiles = state.messageFiles;
            const updatedMsgFiles = currentMsgFiles.filter(
                (temp, tempIdx) => idx !== tempIdx
            );

            return {
                ...state,
                messageFiles: updatedMsgFiles,
            };
        } else if (action.type === "REMOVE_MESSAGE_FILE_ERROR") {
            const idx = action.idx;
            const currentMsgFilesErrors = state.messageFilesErrors;
            const updatedMsgFilesErrors = currentMsgFilesErrors.filter(
                (temp, tempIdx) => idx !== tempIdx
            );
            const updatedMsgFilesErrorMsg =
                updatedMsgFilesErrors.length > 0
                    ? state.messageFilesErrorMsg
                    : "";

            return {
                ...state,
                messageFilesErrors: updatedMsgFilesErrors,
                messageFilesErrorMsg: updatedMsgFilesErrorMsg,
            };
        } else if (action.type === "RESET_MESSAGE") {
            let updatedMsgErrors = [];
            const errors = action?.errors || null;
            if (errors) {
                for (const fieldName in errors) {
                    updatedMsgErrors.push(errors[fieldName]);
                }
            }

            return {
                ...state,
                message: "",
                messageErrors: updatedMsgErrors,
                messageFiles: [],
                messageFilesErrors: [],
                messageFilesErrorMsg: "",
            };
        } else if (action.type === "SET_MESSAGE") {
            return { ...state, message: action.message, messageErrors: [] };
        } else if (action.type === "SET_MESSAGE_ERRORS") {
            return {
                ...state,
                messageErrors: action.messageErrors,
            };
        } else if (action.type === "SET_MESSAGE_FILES") {
            let updatedMsgFiles = state.messageFiles,
                updatedMsgFilesErrors = state.messageFilesErrors,
                updatedMsgFilesErrorMsg = state.messageFilesErrorMsg;

            const uploadedFiles = action.files;
            const filesValidationResult = checkChatFilesValid(uploadedFiles);
            if (filesValidationResult.status) {
                if (filesValidationResult.status === "false") {
                    updatedMsgFilesErrorMsg =
                        filesValidationResult.msg || updatedMsgFilesErrorMsg;

                    const results = filesValidationResult.results;
                    if (results && results.length > 0) {
                        for (let i = 0; i < results.length; i++) {
                            const result = results[i];
                            if (result.errors.length > 0) {
                                updatedMsgFilesErrors.push(result);
                            } else {
                                updatedMsgFiles.push(result.file);
                            }
                        }
                    }
                } else {
                    updatedMsgFiles = [...updatedMsgFiles, ...uploadedFiles];
                }
            } else {
                updatedMsgFilesErrorMsg = VALIDATION_FAILED;
            }

            return {
                ...state,
                messageFiles: updatedMsgFiles,
                messageFilesErrors: updatedMsgFilesErrors,
                messageFilesErrorMsg: updatedMsgFilesErrorMsg,
            };
        }
    }

    return INIT_CHAT_MSG_STATE;
};

const Special = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isMountedSpecial = useRef(true);
    const params = useParams();
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const Swal = useSwalWrapper();

    const specialIdParam = params?.specialId || "";

    /* Special states */
    const [specialId, setSpecialId] = useState("");
    const [specialIdValid, setSpecialIdValid] = useState(false);
    const [specialFetching, setSpecialFetching] = useState(false);
    const [specialFetched, setSpecialFetched] = useState(false);
    const [currentSpecial, setCurrentSpecial] = useState(null);
    const [specialIsValid, setSpecialIsValid] = useState(false);
    /* Modal states */
    const [openAddEditFlyerDialog, setOpenAddEditFlyerDialog] = useState(false);
    const [openAddEditSpecialDialog, setOpenAddEditSpecialDialog] =
        useState(false);
    const [openSpecialDescriptionDialog, setOpenSpecialDescriptionDialog] =
        useState(false);
    /* Miscellaneous states */
    const [isLoading, setIsLoading] = useState(false);
    const [pageName, setPageName] = useState(genericPageName);

    const [chatState, dispatchChatAction] = useReducer(
        chatReducer,
        INIT_CHAT_STATE
    );

    const [chatMsgState, dispatchChatMsgAction] = useReducer(
        chatMsgReducer,
        INIT_CHAT_MSG_STATE
    );

    const { message, messageFiles } = chatMsgState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    const canAddEditSpecials = isAdminUser || isClientUser || isFmUser;
    const canApprove = isClientUser;
    const canSendMessage = isAdminUser || isClientUser || isFmUser;
    const canUploadFlyers = isAdminUser || isClientUser || isFmUser;
    const canViewDescription = isAdminUser || isClientUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedSpecial.current = false;
        };
    }, []);

    /* Set query parameter */
    useEffect(() => {
        if (isValidUserType) {
            setSpecialId(specialIdParam || EMPTY_PARAM_VALUE);
        }
    }, [isValidUserType, specialIdParam]);

    /* Check special ID is valid or not */
    useEffect(() => {
        setSpecialIdValid(specialId && specialId !== EMPTY_PARAM_VALUE);
    }, [specialId]);

    /* Start loading */
    useEffect(() => {
        if (specialIdValid) {
            setIsLoading(true);
        }
    }, [specialIdValid]);

    /* Fetch special */
    useEffect(() => {
        if (isLoading) {
            setSpecialFetching(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (specialFetching) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getSpecialLocalState(payload, (fetchedSpecial) => {
                            if (isActive) {
                                setSpecialFetching(false);
                                setSpecialFetched(true);
                                setCurrentSpecial(fetchedSpecial);
                            }
                        })
                    );
                };
            };

            const specialData = {
                authcode: authToken,
                [SPECIAL_KEYS.SPECIAL_ID]: specialId,
            };

            const promise = dispatch(fetchData({ specialData: specialData }));
            promise.catch((error) => {
                if (isActive) {
                    setSpecialFetching(false);
                    setSpecialFetched(false);
                    setCurrentSpecial(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, specialFetching, specialFetched, specialId]);

    /* Check if fetched special is valid or not */
    useEffect(() => {
        if (
            currentSpecial &&
            currentSpecial?.[SPECIAL_KEYS.ID] &&
            currentSpecial[SPECIAL_KEYS.ID]
        ) {
            setSpecialIsValid(true);
            setPageName(currentSpecial[SPECIAL_KEYS.TITLE] || genericPageName);
        }
    }, [currentSpecial]);

    /* Fetch special's chat */
    useEffect(() => {
        if (specialIsValid) {
            dispatchChatAction({ type: "FETCH" });
        }
    }, [specialIsValid]);

    useEffect(() => {
        let isActive = true;

        if (chatState.fetchingChat) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getSpecialChat(payload, (chatMsg) => {
                            if (isActive) {
                                dispatchChatAction({ type: "STOP_FETCH" });
                                dispatchChatAction({
                                    type: "SET_DATA",
                                    messages: chatMsg || [],
                                });
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const specialData = {
                authcode: authToken,
                [SPECIAL_CHAT_MESSAGES_KEYS.SPECIAL_ID]:
                    currentSpecial[SPECIAL_KEYS.ID],
            };
            const promise = dispatch(fetchData({ specialData: specialData }));
            promise.catch((error) => {
                if (isActive) {
                    dispatchChatAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, chatState, currentSpecial]);

    /* Read description */
    const handleViewDescription = () => {
        setOpenSpecialDescriptionDialog(true);
    };

    const handleCloseSpecialDescriptionDialog = () => {
        setOpenSpecialDescriptionDialog(false);
    };

    /* Edit special */
    const handleEditSpecial = () => {
        if (canAddEditSpecials) {
            setOpenAddEditSpecialDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAddEditSpecialDialog = () => {
        setOpenAddEditSpecialDialog(false);
    };

    const handleUpdateSpecial = (updatedSpecial) => {
        setCurrentSpecial(updatedSpecial);
    };

    /* Upload flyers */
    const handleUploadFlyers = () => {
        if (canUploadFlyers) {
            setOpenAddEditFlyerDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAddEditFlyerDialog = () => {
        setOpenAddEditFlyerDialog(false);
    };

    /* Approve flyer */
    const handleApprove = (flyer) => {
        if (canApprove) {
            if (currentSpecial && currentSpecial[SPECIAL_KEYS.ID]) {
                if (flyer && flyer[SPECIAL_FLYER_KEYS.ID]) {
                    if (
                        flyer[SPECIAL_FLYER_KEYS.STATUS] ===
                        FLYER_APPROVED_STATUS
                    ) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "The flyer has already been approved",
                        });
                    } else {
                        const specialData = {
                            authcode: authToken,
                            [SPECIAL_KEYS.SPECIAL_ID]:
                                currentSpecial[SPECIAL_KEYS.ID],
                            [SPECIAL_FLYER_KEYS.FLYER_ID]:
                                flyer[SPECIAL_FLYER_KEYS.ID],
                            [SPECIAL_FLYER_KEYS.STATUS]: FLYER_APPROVED_STATUS,
                        };
                        dispatch(
                            approveFlyer(
                                { specialData: specialData },
                                (updatedSpecial) => {
                                    if (_isMountedSpecial.current) {
                                        setCurrentSpecial(updatedSpecial);
                                    }
                                },
                                () => {}
                            )
                        );
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid flyer",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid special",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleSetMessage = (msg) =>
        dispatchChatMsgAction({ type: "SET_MESSAGE", message: msg || "" });

    const handleSetMessageFile = (event) => {
        dispatchChatMsgAction({
            type: "SET_MESSAGE_FILES",
            files: event.target.files,
        });
    };

    const handleRemoveMessageFile = (fileIdx) => {
        dispatchChatMsgAction({
            type: "REMOVE_MESSAGE_FILE",
            idx: fileIdx,
        });
    };

    const handleRemoveMessageFileError = (fileIdx) => {
        dispatchChatMsgAction({
            type: "REMOVE_MESSAGE_FILE_ERROR",
            idx: fileIdx,
        });
    };

    /* const handleSendMessage = (event) => {
        const message = event.target.value.trim();
        if (canSendMessage && currentSpecial) {
            if (event.key === "Enter" && message) {
                const specialData = {
                    authcode: authToken,
                    [SPECIAL_CHAT_MESSAGES_KEYS.SPECIAL_ID]:
                        currentSpecial[SPECIAL_KEYS.ID],
                    [SPECIAL_CHAT_MESSAGES_KEYS.MESSAGE]: message,
                };

                dispatch(
                    sendSpecialMessage(
                        { specialData: specialData },
                        () => {
                            if (_isMountedSpecial.current) {
                                setMessage("");
                                handleRefreshChat();
                            }
                        },
                        (messages) => {
                            if (_isMountedSpecial.current) {
                                setMessage("");
                                setChatErrorMessages(messages);
                            }
                        }
                    )
                );
            }
        }
    }; */

    const handleSendMessage = (event) => {
        if (canSendMessage && currentSpecial) {
            const updatedMessage = message ? message.trim() : "";
            const updatedMessageFiles = messageFiles ? messageFiles : [];
            if (updatedMessage && updatedMessage.length > 0) {
                dispatchChatAction({ type: "SEND_MESSAGE" });
                const specialData = new FormData();
                specialData.append("authcode", authToken);
                specialData.append(
                    [SPECIAL_CHAT_MESSAGES_KEYS.SPECIAL_ID],
                    currentSpecial[SPECIAL_KEYS.ID]
                );
                specialData.append(
                    [SPECIAL_CHAT_MESSAGES_KEYS.MESSAGE],
                    updatedMessage
                );
                if (updatedMessageFiles.length > 0) {
                    for (let i = 0; i < updatedMessageFiles.length; i++) {
                        specialData.append(
                            [SPECIAL_CHAT_MESSAGES_KEYS.MESSAGE_FILES_ARRAY],
                            updatedMessageFiles[i]
                        );
                    }
                } else {
                    specialData.append(
                        [SPECIAL_CHAT_MESSAGES_KEYS.MESSAGE_FILES_ARRAY],
                        null
                    );
                }

                dispatch(
                    sendSpecialMessage(
                        { specialData: specialData },
                        () => {
                            if (_isMountedSpecial.current) {
                                dispatchChatMsgAction({
                                    type: "RESET_MESSAGE",
                                });
                                dispatchChatAction({ type: "REFRESH" });
                            }
                        },
                        (messages) => {
                            if (_isMountedSpecial.current) {
                                dispatchChatMsgAction({
                                    type: "RESET_MESSAGE",
                                    errors: messages || "",
                                });
                                dispatchChatAction({
                                    type: "STOP_SEND_MESSAGE",
                                });
                            }
                        }
                    )
                );
            } else if (updatedMessageFiles && updatedMessageFiles.length > 0) {
                dispatchChatMsgAction({
                    type: "SET_MESSAGE_ERRORS",
                    messageErrors: ["Message is required"],
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const fetchError = !specialFetching && !specialFetched;
    const loadError =
        !isValidUserType || !specialIdValid || fetchError || !specialIsValid;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle type="v2" component="h1" title={pageName} />
                        <Breadcrumb
                            pageNames={{
                                genericName: genericPageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {!isLoading && loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !specialIdValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Special is not valid!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && specialIdValid && fetchError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    There was a problem in fetching the special
                                    data!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            specialIdValid &&
                            !fetchError &&
                            !specialIsValid && (
                                <Grid item xs={12}>
                                    <Alert variant="outlined" severity="error">
                                        Special data is not valid!
                                    </Alert>
                                </Grid>
                            )}
                    </React.Fragment>
                )}
                {!isLoading && !loadError && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <SpecialActions
                                canAddEditSpecials={canAddEditSpecials}
                                canUploadFlyers={canUploadFlyers}
                                canViewDescription={canViewDescription}
                                onEdit={handleEditSpecial}
                                onUploadFlyers={handleUploadFlyers}
                                onViewDescription={handleViewDescription}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FlyerHeader
                                canApprove={canApprove}
                                currentSpecial={currentSpecial}
                                onApprove={handleApprove}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ChatSection
                                canSendMessage={canSendMessage}
                                chatBox={{
                                    sx: {
                                        border: (theme) =>
                                            `2px solid ${theme.palette.secondary.main}`,
                                        borderRadius: "8px",
                                    },
                                }}
                                chatFetched={chatState.chatFetched}
                                errorMessages={chatMsgState.messageErrors}
                                fetchingChat={chatState.fetchingChat}
                                firstLoad={chatState.firstLoad}
                                message={message}
                                messages={chatState.messages}
                                messageFiles={messageFiles}
                                messageFilesErrors={
                                    chatMsgState.messageFilesErrors
                                }
                                messageFilesErrorMsg={
                                    chatMsgState.messageFilesErrorMsg
                                }
                                messageKeys={SPECIAL_CHAT_MESSAGES_KEYS}
                                sendingMsg={chatState.sendingMsg}
                                showHeading={true}
                                onRemoveMessageFile={handleRemoveMessageFile}
                                onRemoveMessageFileError={
                                    handleRemoveMessageFileError
                                }
                                onSendMessage={handleSendMessage}
                                onSetMessage={handleSetMessage}
                                onSetMessageFiles={handleSetMessageFile}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {!isLoading && !loadError && (
                <React.Fragment>
                    {canAddEditSpecials && openAddEditSpecialDialog && (
                        <AddEditSpecialDialog
                            currentSpecial={currentSpecial}
                            open={openAddEditSpecialDialog}
                            onClose={handleCloseAddEditSpecialDialog}
                            onUpdate={handleUpdateSpecial}
                        />
                    )}
                    {canUploadFlyers && openAddEditFlyerDialog && (
                        <AddEditFlyerDialog
                            currentSpecial={currentSpecial}
                            open={openAddEditFlyerDialog}
                            onClose={handleCloseAddEditFlyerDialog}
                            onUpdate={handleUpdateSpecial}
                        />
                    )}
                    {canViewDescription && openSpecialDescriptionDialog && (
                        <SpecialDescriptionDialog
                            currentSpecial={currentSpecial}
                            open={openSpecialDescriptionDialog}
                            onClose={handleCloseSpecialDescriptionDialog}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Special;
