import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { FOLDER_KEYS } from "../../utils/constants/appData";

const FolderCard = ({
    canDeleteFolder,
    canDownloadFolder,
    canEditFolder,
    canRestoreFolder,
    canTrashFolder,
    canViewFolder,
    folder,
    onDelete,
    onDownload,
    onEdit,
    onRestore,
    onTrash,
}) => {
    const folderIcon = (
        <FolderIcon
            sx={{
                fontSize: "4rem",
                color: (theme) => theme.palette.secondary.main,
            }}
        />
    );

    const folderName = folder[FOLDER_KEYS.NAME];

    return (
        <Card
            sx={(theme) => ({
                padding: theme.spacing(1, 2),
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
                borderRadius: "10px",
                opacity: 1,
            })}
        >
            <CardContent sx={{ pb: (theme) => theme.spacing(0.5) }}>
                <Stack alignItems="center">
                    <Box>
                        {canViewFolder ? (
                            <IconButton
                                aria-label="view folder"
                                component={RouterLink}
                                to={`/accountAssets/folder/${
                                    folder[FOLDER_KEYS.ID]
                                }/${folder[FOLDER_KEYS.COMPANY_ID]}/`}
                            >
                                {folderIcon}
                            </IconButton>
                        ) : (
                            folderIcon
                        )}
                    </Box>
                    <Box
                        sx={{ p: 0, m: 0, width: "100%", textAlign: "center" }}
                    >
                        <Tooltip title={folderName}>
                            {canViewFolder ? (
                                <NameLink
                                    noWrap
                                    variant="h5"
                                    color="text.primary"
                                    aria-label="view folder"
                                    component={RouterLink}
                                    to={`/accountAssets/folder/${
                                        folder[FOLDER_KEYS.ID]
                                    }/${folder[FOLDER_KEYS.COMPANY_ID]}/`}
                                    sx={{
                                        fontWeight: (theme) =>
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {folderName}
                                </NameLink>
                            ) : (
                                <Typography
                                    noWrap
                                    variant="h5"
                                    color="text.primary"
                                    sx={{
                                        fontWeight: (theme) =>
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {folderName}
                                </Typography>
                            )}
                        </Tooltip>
                        <Typography
                            noWrap
                            variant={"h6"}
                            color="text.disabled"
                            sx={{ wordWrap: "break-word" }}
                        >
                            {folder[FOLDER_KEYS.INDUSTRY]}
                        </Typography>
                    </Box>
                </Stack>
            </CardContent>
            <CardActions sx={{ pt: (theme) => theme.spacing(0.5) }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    spacing={1}
                    sx={{ width: "100%" }}
                >
                    {canDownloadFolder && (
                        <TableActionIcon
                            iconComponent="fileDownloadOutlined"
                            label="download folder"
                            tooltip="Download Folder"
                            onClick={() => onDownload(folder)}
                        />
                    )}
                    {canEditFolder && (
                        <TableActionIcon
                            iconComponent="edit"
                            label="edit folder"
                            tooltip="Edit Folder"
                            iconSx={{ fontSize: "1.4rem" }}
                            onClick={() => onEdit(folder)}
                        />
                    )}
                    {canRestoreFolder && (
                        <TableActionIcon
                            iconComponent="restoreFromTrash"
                            label="restore folder"
                            tooltip="Restore Folder"
                            iconSx={{ fontSize: "1.45rem" }}
                            onClick={() => onRestore(folder)}
                        />
                    )}
                    {!canDeleteFolder && canTrashFolder && (
                        <TableActionIcon
                            iconComponent="deleteOutlined"
                            label="move folder to trash"
                            tooltip="Move Folder to Trash"
                            onClick={() => onTrash(folder)}
                        />
                    )}
                    {canDeleteFolder && !canTrashFolder && (
                        <TableActionIcon
                            iconComponent="deleteOutlined"
                            label="delete folder"
                            tooltip="Delete Folder"
                            onClick={() => onDelete(folder)}
                        />
                    )}
                </Stack>
            </CardActions>
        </Card>
    );
};

FolderCard.propTypes = {
    canDeleteFolder: PropTypes.bool,
    canDownloadFolder: PropTypes.bool,
    canEditFolder: PropTypes.bool,
    canRestoreFolder: PropTypes.bool,
    canTrashFolder: PropTypes.bool,
    canViewFolder: PropTypes.bool,
    folder: PropTypes.object,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    onEdit: PropTypes.func,
    onRestore: PropTypes.func,
    onTrash: PropTypes.func,
};

FolderCard.defaultProps = {
    canDeleteFolder: false,
    canDownloadFolder: false,
    canEditFolder: false,
    canRestoreFolder: false,
    canTrashFolder: false,
    canViewFolder: false,
    // folder: null,
    onDelete: () => {},
    onDownload: () => {},
    onEdit: () => {},
    onRestore: () => {},
    onTrash: () => {},
};

export default FolderCard;
