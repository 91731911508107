import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import { visuallyHidden } from "@mui/utils";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    FOLDER_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const FoldersList = ({
    canDeleteFolder,
    canDownloadFolder,
    canEditFolder,
    canRestoreFolder,
    canTrashFolder,
    canViewFolder,
    columnsToShow,
    folders,
    folderCounts,
    foldersFetched,
    headCells,
    order,
    page,
    rowsPerPage,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onDelete,
    onDownload,
    onEdit,
    onRestore,
    onSort,
    onTrash,
}) => {
    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const totalFolders = folderCounts[FOLDER_KEYS.TOTAL];
    const folderCountNonZero =
        folders &&
        folders.length > 0 &&
        folderCounts &&
        totalFolders &&
        !isNaN(totalFolders) &&
        parseInt(totalFolders) > 0;

    const folderIcon = (
        <FolderIcon
            sx={{
                color: (theme) => theme.palette.secondary.main,
            }}
        />
    );

    const numTotalCols = headCells.length;
    const numVisibleCols = columnsToShow.length;

    const showActions =
        canDownloadFolder ||
        canEditFolder ||
        canRestoreFolder ||
        canDeleteFolder ||
        canTrashFolder;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Folders Table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const sx = {};

                                if (headCell?.width) {
                                    const headCellWidth = Number(
                                        headCell.width.match(/\d+(\.\d+)?/)[0]
                                    );
                                    const updHeadCellWidth =
                                        (numTotalCols / numVisibleCols) *
                                        headCellWidth;
                                    sx.width = `${updHeadCellWidth.toFixed(
                                        2
                                    )}%`;
                                }

                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            {showActions && (
                                <TableCell
                                    key="actions"
                                    sx={{ width: "15%", textAlign: "center" }}
                                >
                                    Actions
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {folderCountNonZero ? (
                            folders.map((folder) => {
                                const folderId = folder[FOLDER_KEYS.ID];
                                const folderName = folder[FOLDER_KEYS.NAME];

                                return (
                                    <TableRow
                                        key={folderId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        {columnsToShow.map(
                                            (columnId, columnIdx) => {
                                                const foundHeadCell =
                                                    headCells.find(
                                                        (headCell) =>
                                                            headCell.id ===
                                                            columnId
                                                    );

                                                const sx = {};
                                                if (foundHeadCell?.pxWidth) {
                                                    sx.width =
                                                        foundHeadCell.pxWidth;
                                                }

                                                let cellContents = null;
                                                if (
                                                    columnId ===
                                                    FOLDER_KEYS.NAME
                                                ) {
                                                    cellContents = (
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={1.25}
                                                        >
                                                            {folderIcon}
                                                            {canViewFolder ? (
                                                                <NameLink
                                                                    component={
                                                                        RouterLink
                                                                    }
                                                                    to={`/accountAssets/folder/${folderId}/${
                                                                        folder[
                                                                            FOLDER_KEYS
                                                                                .COMPANY_ID
                                                                        ]
                                                                    }/`}
                                                                >
                                                                    {folderName}
                                                                </NameLink>
                                                            ) : (
                                                                <Typography>
                                                                    {folderName}
                                                                </Typography>
                                                            )}
                                                        </Stack>
                                                    );
                                                } else if (
                                                    columnId ===
                                                    FOLDER_KEYS.INDUSTRY
                                                ) {
                                                    cellContents =
                                                        folder[
                                                            FOLDER_KEYS.INDUSTRY
                                                        ];
                                                } else if (
                                                    columnId ===
                                                    FOLDER_KEYS.UPDATED_AT
                                                ) {
                                                    cellContents =
                                                        folder[
                                                            FOLDER_KEYS
                                                                .UPDATED_AT
                                                        ];
                                                }

                                                const tableCellKey = `cell-${columnId}-${columnIdx}`;
                                                if (columnIdx === 0) {
                                                    return (
                                                        <TableCell
                                                            key={tableCellKey}
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {cellContents}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell
                                                            key={tableCellKey}
                                                        >
                                                            {cellContents}
                                                        </TableCell>
                                                    );
                                                }
                                            }
                                        )}
                                        {showActions && (
                                            <TableCell>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    flexWrap="wrap"
                                                    sx={{ width: "100%" }}
                                                >
                                                    {canDownloadFolder && (
                                                        <TableActionIcon
                                                            iconComponent="fileDownloadOutlined"
                                                            label="download folder"
                                                            tooltip="Download Folder"
                                                            onClick={() =>
                                                                onDownload(
                                                                    folder
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {canEditFolder && (
                                                        <TableActionIcon
                                                            iconComponent="edit"
                                                            label="edit folder"
                                                            tooltip="Edit Folder"
                                                            iconSx={{
                                                                fontSize:
                                                                    "1.4rem",
                                                            }}
                                                            onClick={() =>
                                                                onEdit(folder)
                                                            }
                                                        />
                                                    )}
                                                    {canRestoreFolder && (
                                                        <TableActionIcon
                                                            iconComponent="restoreFromTrash"
                                                            label="restore folder"
                                                            tooltip="Restore Folder"
                                                            iconSx={{
                                                                fontSize:
                                                                    "1.45rem",
                                                            }}
                                                            onClick={() =>
                                                                onRestore(
                                                                    folder
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {!canDeleteFolder &&
                                                        canTrashFolder && (
                                                            <TableActionIcon
                                                                iconComponent="deleteOutlined"
                                                                label="move folder to trash"
                                                                tooltip="Move Folder to Trash"
                                                                onClick={() =>
                                                                    onTrash(
                                                                        folder
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    {canDeleteFolder &&
                                                        !canTrashFolder && (
                                                            <TableActionIcon
                                                                iconComponent="deleteOutlined"
                                                                label="delete folder"
                                                                tooltip="Delete Folder"
                                                                onClick={() =>
                                                                    onDelete(
                                                                        folder
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </Stack>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            foldersFetched ? "error" : "info"
                                        }
                                    >
                                        {foldersFetched
                                            ? "There are no records found."
                                            : "Loading folders..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    folderCountNonZero
                        ? parseInt(folderCounts[FOLDER_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={folderCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

FoldersList.propTypes = {
    canDeleteFolder: PropTypes.bool,
    canDownloadFolder: PropTypes.bool,
    canEditFolder: PropTypes.bool,
    canRestoreFolder: PropTypes.bool,
    canTrashFolder: PropTypes.bool,
    canViewFolder: PropTypes.bool,
    columnsToShow: PropTypes.array,
    folders: PropTypes.array,
    folderCounts: PropTypes.object,
    foldersFetched: PropTypes.bool,
    headCells: PropTypes.array,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    onEdit: PropTypes.func,
    onRestore: PropTypes.func,
    onSort: PropTypes.func,
    onTrash: PropTypes.func,
};

FoldersList.defaultProps = {
    canDeleteFolder: false,
    canDownloadFolder: false,
    canEditFolder: false,
    canRestoreFolder: false,
    canTrashFolder: false,
    canViewFolder: false,
    columnsToShow: [],
    folders: [],
    folderCounts: null,
    foldersFetched: false,
    headCells: [],
    page: 0,
    rowsPerPage: 0,
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    onDelete: () => {},
    onDownload: () => {},
    onEdit: () => {},
    onRestore: () => {},
    // onSort: () => {},
    onTrash: () => {},
};

export default FoldersList;
