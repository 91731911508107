import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import FmSearch from "../../shared/FmSearch";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { getAllIndustries } from "../../redux/actions/industries";
import {
    deleteFolder,
    getFilteredTrashedFoldersLocal,
    restoreFolder,
} from "../../redux/actions/folders";
import FmSingleAutocomplete from "../../shared/FmSingleAutocomplete";
import { useDebounce } from "../../utils/commonHelper";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    EMPTY_PARAM_VALUE,
    FOLDER_KEYS,
    INDUSTRY_KEYS,
    LAYOUT_LIST_VIEW,
    LAYOUT_GRID_VIEW,
    REFRESH_ACTION_DELETE,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import { allFoldersHeadCells } from "../../utils/constants/tableColumns";
import FoldersGridLoadMore from "../folders/FoldersGridLoadMore";
import FoldersListLoadMore from "../folders/FoldersListLoadMore";

const pageName = "Account Assets Trash";

const FOLDERS_LIMIT = 16;

const INIT_FOLDERS_STATE = {
    companyId: "",
    filtered: false,
    firstLoad: true,
    folders: [],
    foldersFetched: true,
    industry: null,
    inputIsValid: false,
    loadMore: false,
    order: SORT_ORDER_DESC,
    page: 0,
    refresh: false,
    refreshAction: "",
    rowsPerPage: FOLDERS_LIMIT,
    search: "",
    searching: false,
    sort: FOLDER_KEYS.NAME,
    sorted: false,
    totals: 0,
};

const foldersReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                foldersFetched: false,
            };
        } else if (action.type === "REFRESH") {
            return {
                ...state,
                foldersFetched: false,
                refresh: true,
                refreshAction: action?.refreshAction || "",
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                filtered: false,
                firstLoad: false,
                folders: [],
                foldersFetched: true,
                loadMore: false,
                page: 0,
                refresh: false,
                refreshAction: "",
                searching: false,
                sorted: false,
                totals: 0,
            };
        } else if (action.type === "SEARCH_AND_FETCH") {
            if (state.firstLoad) {
                return { ...state };
            } else {
                return {
                    ...state,
                    foldersFetched: false,
                    page: 0,
                    search: action.search,
                    searching: true,
                };
            }
        } else if (action.type === "SET_COMPANY_ID") {
            const updatedCompanyId = action.companyId;
            return {
                ...state,
                companyId: updatedCompanyId,
                inputIsValid: !!updatedCompanyId,
            };
        } else if (action.type === "SET_DATA") {
            const isFiltered = state.filtered;
            const isRefreshed = state.refresh;
            const isSearching = state.searching;
            const isSorted = state.sorted;
            const currentFolders = state.folders || [];

            const updatedFolders =
                isFiltered || isRefreshed || isSearching || isSorted
                    ? []
                    : [...currentFolders];
            let numUpdatedFolders = updatedFolders.length;

            let newPage =
                numUpdatedFolders > 0
                    ? Math.ceil(numUpdatedFolders / FOLDERS_LIMIT)
                    : 0;
            newPage = newPage > 0 ? newPage - 1 : newPage;

            const currentRowsPerPage = state.rowsPerPage;

            const fetchedTotals = action.totals;
            const numTotalFolders = fetchedTotals[FOLDER_KEYS.TOTAL];

            const fetchedFolders = action.folders;
            const numFetchedFolders = fetchedFolders.length;
            if (numFetchedFolders > 0) {
                let numAddedFolders = 0;

                for (let i = 0; i < numFetchedFolders; i++) {
                    const fetchedFolder = fetchedFolders[i];
                    if (
                        fetchedFolder &&
                        fetchedFolder?.[FOLDER_KEYS.ID] &&
                        fetchedFolder[FOLDER_KEYS.ID]
                    ) {
                        const foundFolder = updatedFolders.find(
                            (folderObj) =>
                                folderObj[FOLDER_KEYS.ID] ===
                                fetchedFolder[FOLDER_KEYS.ID]
                        );
                        if (!foundFolder) {
                            updatedFolders.push(fetchedFolder);
                            numAddedFolders += 1;
                        }
                    }
                }

                numUpdatedFolders = updatedFolders.length;

                newPage =
                    numUpdatedFolders > 0
                        ? Math.ceil(numUpdatedFolders / FOLDERS_LIMIT)
                        : 0;
                newPage = newPage > 0 ? newPage - 1 : newPage;

                if (numUpdatedFolders === numTotalFolders) {
                    return {
                        ...state,
                        filtered: false,
                        folders: updatedFolders,
                        loadMore: false,
                        page: newPage,
                        refresh: false,
                        refreshAction: "",
                        searching: false,
                        sorted: false,
                        totals: numTotalFolders,
                    };
                } else {
                    const maxLimit = isRefreshed
                        ? (newPage + 1) * currentRowsPerPage
                        : currentRowsPerPage;
                    if (numAddedFolders === maxLimit) {
                        return {
                            ...state,
                            filtered: false,
                            folders: updatedFolders,
                            loadMore: true,
                            page: newPage + 1,
                            refresh: false,
                            refreshAction: "",
                            searching: false,
                            sorted: false,
                            totals: numTotalFolders,
                        };
                    } else {
                        return {
                            ...state,
                            filtered: false,
                            folders: updatedFolders,
                            loadMore: false,
                            page: newPage,
                            refresh: false,
                            refreshAction: "",
                            searching: false,
                            sorted: false,
                            totals: numTotalFolders,
                        };
                    }
                }
            } else {
                return {
                    ...state,
                    filtered: false,
                    folders: updatedFolders,
                    loadMore: false,
                    page: newPage,
                    refresh: false,
                    refreshAction: "",
                    searching: false,
                    sorted: false,
                    totals: numTotalFolders,
                };
            }
        } else if (action.type === "SET_INDUSTRY_AND_FETCH") {
            return {
                ...state,
                filtered: true,
                foldersFetched: false,
                industry: action.industry,
                page: 0,
            };
        } else if (action.type === "SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                foldersFetched: false,
                page: 0,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                sort: updatedSort,
                sorted: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                firstLoad: false,
                foldersFetched: true,
            };
        }
    }
    return {
        filterList: false,
        industry: null,
        search: "",
        page: 0,
        rowsPerPage: FOLDERS_LIMIT,
        sort: FOLDER_KEYS.NAME,
        order: SORT_ORDER_DESC,
        searching: false,
    };
};

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "DELETE") {
            return { deleted: true, restored: false };
        } else if (action.type === "RESTORE") {
            return { deleted: false, restored: true };
        }
    }

    return { deleted: false, restored: false };
};

const TrashedFolders = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isTrashedFoldersMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const [params] = useSearchParams();
    const companyParam = params.get("company") || "";

    const [isLoading, setIsLoading] = useState(false);
    const [layoutType, setLayoutType] = useState(LAYOUT_GRID_VIEW);
    const [searchTerm, setSearchTerm] = useState("");
    const [fetchIndustries, setFetchIndustries] = useState(false);
    const [allIndustries, setAllIndustries] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [headCells, setHeadCells] = useState([]);

    const [foldersState, dispatchFoldersAction] = useReducer(
        foldersReducer,
        INIT_FOLDERS_STATE
    );

    const { folders, inputIsValid } = foldersState;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        deleted: false,
        restored: false,
    });

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;

    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canDeleteFolder = isAdminUser || isClientUser || isFmUser;
    const canRestoreFolder = isAdminUser || isClientUser || isFmUser;

    useEffect(() => {
        return () => {
            _isTrashedFoldersMounted.current = false;
        };
    }, []);

    /* Set the table columns to show */
    useEffect(() => {
        if (isValidUserType) {
            setHeadCells(
                allFoldersHeadCells.map((headCell) => {
                    return { ...headCell, show: true, sortColumn: true };
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isValidUserType]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Set query parameters */
    useEffect(() => {
        if (isAdminUser || isFmUser) {
            dispatchFoldersAction({
                type: "SET_COMPANY_ID",
                companyId: companyParam || EMPTY_PARAM_VALUE,
            });
        } else if (isClientUser) {
            dispatchFoldersAction({
                type: "SET_COMPANY_ID",
                companyId: EMPTY_PARAM_VALUE,
            });
        }
    }, [companyParam, isAdminUser, isClientUser, isFmUser]);

    /* Start loading */
    useEffect(() => {
        if (inputIsValid && isValidUserType) {
            setIsLoading(true);
        }
    }, [inputIsValid, isValidUserType]);

    /* Fetch industries */
    useEffect(() => {
        if (isLoading) {
            setFetchIndustries(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchIndustries) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllIndustries(payload, (fetchedIndustries) => {
                            if (isActive) {
                                setFetchIndustries(false);
                                if (
                                    fetchedIndustries &&
                                    fetchedIndustries.length > 0
                                ) {
                                    setAllIndustries(fetchedIndustries);
                                } else {
                                    setAllIndustries([]);
                                }
                                dispatchFoldersAction({ type: "FETCH" });
                            }
                        })
                    );
                };
            };

            const promise = dispatch(
                fetchData({ industryData: { authcode: authToken } })
            );

            promise.catch((error) => {
                if (isActive) {
                    setFetchIndustries(false);
                    setAllIndustries([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchIndustries]);

    useEffect(() => {
        let isActive = true;

        if (!foldersState.foldersFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredTrashedFoldersLocal(
                            payload,
                            (fetchedData) => {
                                if (isActive) {
                                    dispatchFoldersAction({
                                        type: "STOP_FETCH",
                                    });
                                    dispatchFoldersAction({
                                        type: "SET_DATA",
                                        folders: fetchedData?.folders || [],
                                        totals: fetchedData?.totals || null,
                                    });
                                    dispatchAction();
                                    setIsLoading(false);
                                }
                            }
                        )
                    );
                };
            };

            const filteredIndustry = foldersState?.industry || null;

            const folderData = {
                authcode: authToken,
                industry: filteredIndustry
                    ? filteredIndustry[INDUSTRY_KEYS.NAME]
                    : "",
                order: foldersState.order,
                search: foldersState.search,
                sort: foldersState.sort,
            };

            if (foldersState.companyId !== EMPTY_PARAM_VALUE)
                folderData[FOLDER_KEYS.COMPANY_ID] = foldersState.companyId;

            let currentPage = foldersState.page + 1;
            if (foldersState.refresh) {
                const numTotals = foldersState.folders.length;
                if (foldersState.refreshAction === REFRESH_ACTION_DELETE) {
                    currentPage = Math.ceil((numTotals - 1) / FOLDERS_LIMIT);
                }
                folderData.page = 1;
                folderData.rows_per_page =
                    currentPage * foldersState.rowsPerPage;
            } else {
                folderData.page = currentPage;
                folderData.rows_per_page = foldersState.rowsPerPage;
            }

            const payload = {
                folderData: folderData,
                fromAction: actionState,
            };

            if (!foldersState.firstLoad && !foldersState.searching) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    dispatchFoldersAction({ type: "RESET" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, actionState, foldersState]);

    /* Search folders */
    useEffect(() => {
        dispatchFoldersAction({
            type: "SEARCH_AND_FETCH",
            search: debouncedSearchTerm,
        });
    }, [dispatchFoldersAction, debouncedSearchTerm]);

    /* Fetch the folders list again if a folder is deleted or restored */
    useEffect(() => {
        if (actionState.deleted || actionState.restored) {
            dispatchFoldersAction({
                type: "REFRESH",
                refreshAction: REFRESH_ACTION_DELETE,
            });
        }
    }, [dispatchFoldersAction, actionState]);

    const toggleLayout = (updatedLayoutType) =>
        setLayoutType(updatedLayoutType);

    const searchTermChangeHandler = (event) =>
        setSearchTerm(event.target.value);

    const industryFilterChangeHandler = (value) => {
        dispatchFoldersAction({
            type: "SET_INDUSTRY_AND_FETCH",
            industry: value,
        });
    };

    const handleSort = (property) => {
        dispatchFoldersAction({
            type: "SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleLoadMore = () => dispatchFoldersAction({ type: "FETCH" });

    const handleRestoreFolder = React.useCallback(
        (folder) => {
            if (canRestoreFolder) {
                if (folder && folder[FOLDER_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Restore folder: ${folder[FOLDER_KEYS.NAME]}?`,
                        onYes: () => {
                            hideDialog();
                            const folderData = {
                                authcode: authToken,
                                [FOLDER_KEYS.COMPANY_ID]:
                                    folder[FOLDER_KEYS.COMPANY_ID],
                                [FOLDER_KEYS.FOLDER_ID]: folder[FOLDER_KEYS.ID],
                            };
                            dispatch(
                                restoreFolder(
                                    { folderData: folderData },
                                    () => {
                                        if (_isTrashedFoldersMounted.current)
                                            dispatchAction({ type: "RESTORE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid folder",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, authToken, canRestoreFolder, hideDialog, showDialog]
    );

    const handleDeleteFolder = React.useCallback(
        (folder) => {
            if (canDeleteFolder) {
                if (folder && folder[FOLDER_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete folder: ${folder[FOLDER_KEYS.NAME]}?`,
                        onYes: () => {
                            hideDialog();
                            const folderData = {
                                authcode: authToken,
                                [FOLDER_KEYS.COMPANY_ID]:
                                    folder[FOLDER_KEYS.COMPANY_ID],
                                [FOLDER_KEYS.FOLDER_ID]: folder[FOLDER_KEYS.ID],
                            };
                            dispatch(
                                deleteFolder(
                                    { folderData: folderData },
                                    () => {
                                        if (_isTrashedFoldersMounted.current)
                                            dispatchAction({ type: "DELETE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid folder",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, authToken, canDeleteFolder, hideDialog, showDialog]
    );

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const loadError = !isValidUserType || !inputIsValid;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle component="h1" title={pageName} type="v2" />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !inputIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                One or more parameters are not valid!
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    <Grid item md={5} xs={12}>
                        <FmSearch
                            ariaLabel="Search account assets"
                            id="folders_search"
                            placeholder="Search Account Assets"
                            searchTerm={searchTerm}
                            filterSearch={foldersState.searching}
                            onSearchChange={searchTermChangeHandler}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <FmSingleAutocomplete
                            id="industry-filter"
                            idKey={INDUSTRY_KEYS.NAME}
                            label="Filter by industry"
                            labelKey={INDUSTRY_KEYS.NAME}
                            options={allIndustries}
                            value={foldersState.industry}
                            onChange={industryFilterChangeHandler}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="end"
                        >
                            <IconButton
                                aria-label="grid view"
                                sx={(theme) => ({
                                    color:
                                        layoutType === LAYOUT_GRID_VIEW
                                            ? theme.palette.primary.main
                                            : theme.palette.primary.light,
                                })}
                                onClick={() => toggleLayout(LAYOUT_GRID_VIEW)}
                            >
                                <GridViewIcon />
                            </IconButton>
                            <IconButton
                                aria-label="list view"
                                sx={(theme) => ({
                                    color:
                                        layoutType === LAYOUT_LIST_VIEW
                                            ? theme.palette.primary.main
                                            : theme.palette.primary.light,
                                })}
                                onClick={() => toggleLayout(LAYOUT_LIST_VIEW)}
                            >
                                <FormatListBulletedIcon />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {layoutType === LAYOUT_GRID_VIEW && (
                            <FoldersGridLoadMore
                                canDeleteFolder={canDeleteFolder}
                                canRestoreFolder={canRestoreFolder}
                                folders={folders}
                                foldersFetched={foldersState.foldersFetched}
                                loadMore={foldersState.loadMore}
                                onDelete={handleDeleteFolder}
                                onLoadMore={handleLoadMore}
                                onRestore={handleRestoreFolder}
                            />
                        )}
                        {layoutType === LAYOUT_LIST_VIEW && (
                            <FoldersListLoadMore
                                canDeleteFolder={canDeleteFolder}
                                canRestoreFolder={canRestoreFolder}
                                columnsToShow={columnsToShow}
                                folders={folders}
                                foldersFetched={foldersState.foldersFetched}
                                headCells={headCells}
                                order={foldersState.order}
                                loadMore={foldersState.loadMore}
                                sort={foldersState.sort}
                                onDelete={handleDeleteFolder}
                                onLoadMore={handleLoadMore}
                                onRestore={handleRestoreFolder}
                                onSort={handleSort}
                            />
                        )}
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default TrashedFolders;
