import {
    ADD_COMPANY,
    DELETE_COMPANY,
    EDIT_COMPANY,
    GET_COMPANY_COUNT,
    GET_COMPANIES,
    SET_COMPANY_DETAILS,
} from "../../utils/constants/actionTypes";
import { COMPANY_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    companies: [],
    currentCompany: null,
    companyCounts: { [COMPANY_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANIES: {
            return {
                ...state,
                companies: action.payload,
            };
        }
        case GET_COMPANY_COUNT: {
            return {
                ...state,
                companyCounts: action.payload,
            };
        }
        case SET_COMPANY_DETAILS: {
            return {
                ...state,
                currentCompany: action.payload,
            };
        }
        case ADD_COMPANY: {
            return {
                ...state,
                companies: [action.payload, ...state.companies],
            };
        }
        case EDIT_COMPANY: {
            return {
                ...state,
                companies: state.companies.map((company) =>
                    company.id === action.payload.id ? action.payload : company
                ),
            };
        }
        case DELETE_COMPANY: {
            return {
                ...state,
                companies: state.companies.filter(
                    (company) => company.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
