import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const a11yProps = (name) => {
    return {
        id: `asset-type-tab-${name}`,
        "aria-controls": `asset-type-tabpanel-${name}`,
    };
};

const AssetTypeTabPanel = (props) => {
    const { children, value, name, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== name}
            id={`asset-type-tabpanel-${name}`}
            aria-labelledby={`asset-type-tab-${name}`}
            {...other}
        >
            {value === name && (
                <Box sx={{ p: 0, pt: (theme) => theme.spacing(3) }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

AssetTypeTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

export default AssetTypeTabPanel;
