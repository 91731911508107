import React from "react";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import AssignedServices from "./AssignedServices";

const AdminFmAssignedServices = () => {
    const params = useParams();
    const { authUser } = useJumboAuth();

    const companyParam = params?.companyId || "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    if (isValidUserType) {
        return <AssignedServices companyParam={companyParam} />;
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="error" variant="outlined">
                        {AUTHORIZATION_ERROR}
                    </Alert>
                </Grid>
            </Grid>
        );
    }
};

export default AdminFmAssignedServices;
