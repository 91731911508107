import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
} from "../../utils/constants/actionTypes";

export const fetchSuccess = (message) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_SUCCESS,
            payload: message || "",
        });
    };
};
export const fetchError = (error) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_ERROR,
            payload: error,
        });
    };
};

export const fetchStart = (showLoader = true) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_START,
            showLoader: showLoader,
        });
    };
};
