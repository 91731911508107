import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    CASE_STUDY_KEYS,
    PAGINATION_PAGES,
} from "../../utils/constants/appData";

const allHeadCells = [
    {
        id: CASE_STUDY_KEYS.BEFORE_URL,
        label: "Before",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
    {
        id: CASE_STUDY_KEYS.AFTER_URL,
        label: "After",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
    {
        id: CASE_STUDY_KEYS.DESCRIPTION,
        label: "Description",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "45%",
    },
    {
        id: CASE_STUDY_KEYS.CREATED_AT,
        label: "Created",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "10%",
    },
];

const CaseStudiesTable = ({
    caseStudies,
    caseStudyCounts,
    caseStudiesFetched,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    onDelete,
    onEdit,
}) => {
    let colspan = allHeadCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const caseStudyCountNonZero =
        caseStudies &&
        caseStudies.length > 0 &&
        caseStudyCounts &&
        caseStudyCounts[CASE_STUDY_KEYS.TOTAL] &&
        !isNaN(parseInt(caseStudyCounts[CASE_STUDY_KEYS.TOTAL])) &&
        parseInt(caseStudyCounts[CASE_STUDY_KEYS.TOTAL]) > 0;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Case Studies Table">
                    <TableHead>
                        <TableRow>
                            {allHeadCells.map((headCell) =>
                                headCell.show ? (
                                    <TableCell
                                        key={headCell.id}
                                        sx={{ width: headCell.width }}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ) : null
                            )}
                            <TableCell
                                key="actions"
                                sx={{ width: "10%", textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {caseStudyCountNonZero ? (
                            caseStudies.map((caseStudy) => {
                                const caseStudyId =
                                    caseStudy[CASE_STUDY_KEYS.ID];
                                const beforeLink =
                                    caseStudy[CASE_STUDY_KEYS.BEFORE_URL];
                                const afterLink =
                                    caseStudy[CASE_STUDY_KEYS.AFTER_URL];

                                return (
                                    <TableRow
                                        key={caseStudyId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Avatar
                                                src={beforeLink || ""}
                                                variant="square"
                                                alt={`${caseStudyId}-before`}
                                                sx={{
                                                    bgcolor: "#F4F7FC",
                                                    width: 100,
                                                    height: 120,
                                                }}
                                            >
                                                &nbsp;
                                            </Avatar>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Avatar
                                                src={afterLink || ""}
                                                variant="square"
                                                alt={`${caseStudyId}-after`}
                                                sx={{
                                                    bgcolor: "#F4F7FC",
                                                    width: 100,
                                                    height: 120,
                                                }}
                                            >
                                                &nbsp;
                                            </Avatar>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                dangerouslySetInnerHTML={{
                                                    __html: caseStudy[
                                                        CASE_STUDY_KEYS
                                                            .DESCRIPTION
                                                    ],
                                                }}
                                            ></Typography>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                caseStudy[
                                                    CASE_STUDY_KEYS.CREATED_AT
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={0.5}
                                            >
                                                <TableActionIcon
                                                    iconComponent="edit"
                                                    label="edit case study"
                                                    onClick={() =>
                                                        onEdit(caseStudy)
                                                    }
                                                />
                                                <TableActionIcon
                                                    iconComponent="delete"
                                                    label="delete case study"
                                                    onClick={() =>
                                                        onDelete(caseStudy)
                                                    }
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            caseStudiesFetched
                                                ? "error"
                                                : "info"
                                        }
                                    >
                                        {caseStudiesFetched
                                            ? "There are no records found."
                                            : "Loading case studies..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    caseStudyCountNonZero
                        ? parseInt(caseStudyCounts[CASE_STUDY_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={caseStudyCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

CaseStudiesTable.propTypes = {
    caseStudies: PropTypes.array,
    caseStudyCounts: PropTypes.object,
    caseStudiesFetched: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

CaseStudiesTable.defaultProps = {
    caseStudies: [],
    caseStudyCounts: null,
    caseStudiesFetched: false,
    page: 0,
    rowsPerPage: 0,
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onDelete: () => {},
    // onEdit: () => {},
};

export default CaseStudiesTable;
