import React, { Suspense } from "react";
import { IconButton, useMediaQuery } from "@mui/material";
/* import menus from "./menus"; */
import adminMenus from "./adminMenus";
import clientUserMenus from "./clientUserMenus";
import fmUserMenus from "./fmUserMenus";
/* import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar"; */
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import {
    SIDEBAR_STYLES,
    SIDEBAR_VARIANTS,
    SIDEBAR_VIEWS,
} from "@jumbo/utils/constants/layout";
import SVGLogo from "../../../../shared/SVGLogo";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
/* import MenuOpenIcon from "@mui/icons-material/MenuOpen"; */
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import VerticalNavbar from "../../../../shared/JumboCustomizer/components/VerticalNavbar/VerticalNavbar";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../../../utils/constants/appData";

const Sidebar = () => {
    const { authUser } = useJumboAuth();

    let customMenuUsed = false;
    let firmMediaMenus = [];

    if (authUser && authUser?.[AUTH_USER_KEYS.TYPE]) {
        if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_ADMIN_ID) {
            firmMediaMenus = adminMenus;
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_AE_ID) {
            firmMediaMenus = fmUserMenus;
        } else if (authUser[AUTH_USER_KEYS.TYPE] === USER_TYPE_CLIENT_ID) {
            firmMediaMenus = clientUserMenus;
        }
    }

    const authUserMenu = authUser?.[AUTH_USER_KEYS.MENU] || [];
    if (authUserMenu && authUserMenu.length > 0) {
        customMenuUsed = true;
        firmMediaMenus = authUserMenu;
    }

    return (
        <React.Fragment>
            <SidebarHeader />
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                alignContent: "center",
                                px: 3,
                            }}
                        >
                            <SidebarSkeleton />
                        </Div>
                    }
                >
                    <VerticalNavbar
                        translate
                        items={firmMediaMenus}
                        customMenuUsed={customMenuUsed}
                    />
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
    const { sidebarTheme } = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return (
            sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open
        );
    }, [sidebarOptions.view, sidebarOptions.open]);

    const isMiniAndOpen = React.useMemo(() => {
        return (
            sidebarOptions?.view === SIDEBAR_VIEWS.MINI && sidebarOptions?.open
        );
    }, [sidebarOptions.view, sidebarOptions.open]);

    const handleOpen = React.useCallback(() => {
        if (sidebarOptions?.view === SIDEBAR_VIEWS.MINI) {
            setSidebarOptions({
                variant: SIDEBAR_VARIANTS.PERSISTENT,
                open: true,
            });
        }
    }, [sidebarOptions?.view, setSidebarOptions]);

    const handleClose = React.useCallback(() => {
        if (sidebarOptions?.view === SIDEBAR_VIEWS.MINI) {
            setSidebarOptions({
                variant: SIDEBAR_VARIANTS.PERMANENT,
                open: false,
            });
        }
    }, [sidebarOptions?.view, setSidebarOptions]);

    const isResponsiveMode = useMediaQuery("(max-width:1200px)");

    return (
        <React.Fragment>
            {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
                <DrawerHeader>
                    {!isResponsiveMode && isMiniAndClosed && (
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleOpen}
                        >
                            <MenuIcon
                                sx={{
                                    color: "#FFFFFF",
                                }}
                            />
                        </IconButton>
                    )}
                    {!isResponsiveMode && isMiniAndOpen && (
                        <IconButton
                            aria-label="close drawer"
                            onClick={handleClose}
                        >
                            <CloseIcon
                                sx={{
                                    color: "#FFFFFF",
                                }}
                            />
                        </IconButton>
                    )}
                    {isResponsiveMode && !isMiniAndClosed && (
                        <SVGLogo
                            mode={sidebarTheme.type}
                            svgSx={{
                                height: "auto",
                                fontSize: "8rem",
                            }}
                        />
                    )}
                    {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close drawer"
                                sx={{ ml: 0, mr: -1.5 }}
                                onClick={() =>
                                    setSidebarOptions({ open: false })
                                }
                            >
                                <CloseIcon />
                            </IconButton>
                        </Zoom>
                    )}
                </DrawerHeader>
            )}
        </React.Fragment>
    );
};

export default Sidebar;
