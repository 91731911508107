import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getInitialsFromName } from "../../utils/appHelpers";
import { COMPANY_KEYS, FM_USER_KEYS } from "../../utils/constants/appData";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";

const ViewAssginedCompaniesDialog = ({
    allCompanies,
    currentFmUser,
    open,
    onClose,
}) => {
    const _isViewFmAssignedCompaniesMounted = useRef(true);

    const [assignedCompanies, setAssignedCompanies] = useState([]);
    const [assignedCompaniesSet, setAssignedCompaniesSet] = useState(false);

    useEffect(() => {
        return () => {
            _isViewFmAssignedCompaniesMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAssignedCompanies([]);
            setAssignedCompaniesSet(false);
        }
    }, [open]);

    useEffect(() => {
        if (allCompanies && allCompanies.length > 0 && currentFmUser) {
            const assignedCompaniesIds =
                currentFmUser[FM_USER_KEYS.ASSIGNED_COMPANIES];

            const tempAssignedCompanies = assignedCompaniesIds
                .split(",")
                .map((assignedCompanyId) => {
                    const assignedCompanyData = allCompanies.find(
                        (tempCompany) =>
                            tempCompany[COMPANY_KEYS.ID] === assignedCompanyId
                    );

                    return assignedCompanyData || null;
                });
            setAssignedCompanies(tempAssignedCompanies);
            setAssignedCompaniesSet(true);
        }
    }, [allCompanies, currentFmUser]);

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    currentFmUser
                        ? `View companies assigned to ${
                              currentFmUser[FM_USER_KEYS.NAME]
                          }`
                        : "Error"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {allCompanies &&
                    allCompanies.length > 0 &&
                    currentFmUser &&
                    !assignedCompaniesSet && (
                        <Alert severity="info">Loading list...</Alert>
                    )}
                {allCompanies &&
                    allCompanies.length &&
                    currentFmUser &&
                    assignedCompaniesSet && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <List>
                                    {assignedCompanies.map(
                                        (assignedCompany) => {
                                            const assignedCompanyId =
                                                assignedCompany[
                                                    COMPANY_KEYS.ID
                                                ];

                                            const assignedCompanyName =
                                                assignedCompany[
                                                    COMPANY_KEYS.NAME
                                                ];

                                            const fetchedPhoto =
                                                assignedCompany[
                                                    COMPANY_KEYS.LOGO_PIC
                                                ];
                                            let photoLink = "";
                                            if (fetchedPhoto) {
                                                photoLink = `${
                                                    assignedCompany[
                                                        COMPANY_KEYS
                                                            .LOGO_BASE_URL
                                                    ]
                                                }/${
                                                    fetchedPhoto[
                                                        COMPANY_KEYS
                                                            .LOGO_FILE_PATH
                                                    ]
                                                }`;
                                            }
                                            const assignedCompanyInitials =
                                                photoLink ? (
                                                    <React.Fragment>
                                                        &nbsp;
                                                    </React.Fragment>
                                                ) : (
                                                    getInitialsFromName(
                                                        assignedCompanyName
                                                    )
                                                );

                                            return assignedCompany ? (
                                                <ListItem
                                                    key={`assigned-client-${assignedCompanyId}`}
                                                >
                                                    <ListItemButton
                                                        component={Link}
                                                        to={`/company/${assignedCompanyId}/dashboard/`}
                                                        target="_blank"
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                src={photoLink}
                                                                sizes={"small"}
                                                                alt={
                                                                    assignedCompanyName
                                                                }
                                                                sx={{
                                                                    bgcolor:
                                                                        "#487AA3",
                                                                }}
                                                            >
                                                                {
                                                                    assignedCompanyInitials
                                                                }
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                assignedCompanyName
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.secondary"
                                                                >
                                                                    {
                                                                        assignedCompany[
                                                                            COMPANY_KEYS
                                                                                .EMAIL
                                                                        ]
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                        <ListItemIcon>
                                                            <ArrowForwardIcon />
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                </ListItem>
                                            ) : null;
                                        }
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    )}
                {!allCompanies && (
                    <Alert severity="error">Client Users not found!</Alert>
                )}
                {!currentFmUser && (
                    <Alert severity="error">User not found!</Alert>
                )}
            </DialogContent>
        </Dialog>
    );
};

ViewAssginedCompaniesDialog.propTypes = {
    allCompanies: PropTypes.array,
    currentFmUser: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

ViewAssginedCompaniesDialog.defaultProps = {
    allCompanies: [],
    currentFmUser: null,
    open: false,
    // onClose: () => {},
};

export default ViewAssginedCompaniesDialog;
