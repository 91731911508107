import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
/* import Zoom from "@mui/material/Zoom"; */
import { visuallyHidden } from "@mui/utils";
import EmptyValue from "../../shared/widgets/EmptyValue";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import TableColorCard from "../../shared/widgets/TableColorCard";
import { getTicketPriorityColor } from "../../utils/appHelpers";
import {
    FM_USER_KEYS,
    PAGINATION_PAGES,
    TICKET_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const TicketsTable = ({
    ariaLabel,
    canAssignFmUsers,
    /* canDeleteTicket, */
    canUpdatePriority,
    canUpdateStatus,
    canReadDescription,
    cardColor,
    columnsToShow,
    headCells,
    order,
    page,
    rowsPerPage,
    showColorCard,
    showEmptyRow,
    sort,
    tableActions,
    tickets,
    ticketCounts,
    ticketsFetched,
    handlePageChange,
    handleRowsPerPageChange,
    onAssignFmUsers,
    /* onDelete, */
    onEditPriority,
    onEditStatus,
    onReadDescription,
    onSort,
}) => {
    const navigate = useNavigate();

    let colspan = headCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const ticketsTotalCount =
        ticketCounts &&
        ticketCounts[TICKET_KEYS.TOTAL] &&
        !isNaN(parseInt(ticketCounts[TICKET_KEYS.TOTAL])) &&
        parseInt(ticketCounts[TICKET_KEYS.TOTAL]) > 0
            ? parseInt(ticketCounts[TICKET_KEYS.TOTAL])
            : 0;

    const fetchedTicketsCount =
        tickets && tickets.length > 0 ? tickets.length : 0;

    const ticketCountNonZero = fetchedTicketsCount > 0 && ticketsTotalCount > 0;

    const paddingSx = {};
    if (showEmptyRow) {
        paddingSx.border = "none";
        if (fetchedTicketsCount) {
            if (fetchedTicketsCount < rowsPerPage) {
                paddingSx.pt = (theme) => theme.spacing(3.1);
                paddingSx.pb = (theme) => theme.spacing(3.1);
            }
        } else {
            paddingSx.pt = (theme) => theme.spacing(3.3);
            paddingSx.pb = (theme) => theme.spacing(3.3);
        }
    }

    const showActions =
        canAssignFmUsers ||
        canUpdatePriority ||
        canUpdateStatus ||
        canReadDescription;

    const numTotalCols = headCells.length;
    const numVisibleCols = columnsToShow.length;

    const table = (
        <Table aria-label={ariaLabel}>
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => {
                        const sx = {};

                        if (headCell?.width) {
                            const headCellWidth = Number(
                                headCell.width.match(/\d+(\.\d+)?/)[0]
                            );
                            const updHeadCellWidth =
                                (numTotalCols / numVisibleCols) * headCellWidth;
                            sx.width = `${updHeadCellWidth.toFixed(2)}%`;
                        }

                        const sortDirection =
                            sort === headCell.id
                                ? order === SORT_ORDER_ASC
                                    ? SORT_ORDER_DESC
                                    : SORT_ORDER_ASC
                                : SORT_ORDER_ASC;

                        return headCell.show ? (
                            <TableCell key={headCell.id} sx={{ ...sx }}>
                                {headCell.sortColumn ? (
                                    <TableSortLabel
                                        active={sort === headCell.id}
                                        direction={sortDirection}
                                        onClick={() => onSort(headCell.id)}
                                    >
                                        {headCell.label}
                                        {sort === headCell.id ? (
                                            <Box
                                                component="span"
                                                sx={visuallyHidden}
                                            >
                                                {order === SORT_ORDER_DESC
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    headCell.label
                                )}
                            </TableCell>
                        ) : null;
                    })}
                    {showActions && (
                        <TableCell
                            key="actions"
                            sx={{ width: "15%", textAlign: "center" }}
                        >
                            Actions
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody
                sx={{
                    p: (theme) => theme.spacing(1),
                }}
            >
                {ticketCountNonZero ? (
                    tickets.map((ticket) => {
                        const ticketId = ticket[TICKET_KEYS.ID];

                        const ticketPriority =
                            ticket?.[TICKET_KEYS.PRIORITY] || "";
                        const ticketPriorityTxt = ticketPriority
                            ? ticketPriority
                            : "NA";
                        const ticketPriorityColorObj =
                            getTicketPriorityColor(ticketPriority);

                        const ticketFmUsers = ticket[TICKET_KEYS.FM_USERS];

                        return (
                            <TableRow
                                key={ticketId}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {columnsToShow.map((columnId, columnIdx) => {
                                    let cellContents = null;
                                    if (columnId === TICKET_KEYS.ID) {
                                        cellContents = (
                                            <NameLink
                                                component={RouterLink}
                                                to={`/supportTicket/${ticketId}/`}
                                            >
                                                {ticket[TICKET_KEYS.TICKETID]}
                                            </NameLink>
                                        );
                                    } else if (
                                        columnId === TICKET_KEYS.PRIORITY
                                    ) {
                                        cellContents =
                                            ticketPriorityColorObj ? (
                                                <Chip
                                                    label={ticketPriorityTxt}
                                                    sx={{
                                                        backgroundColor:
                                                            ticketPriorityColorObj.bgColor,
                                                        color: ticketPriorityColorObj.textColor,
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                />
                                            ) : (
                                                ticketPriorityTxt
                                            );
                                        /* cellContents = (
                                            <Tooltip
                                                TransitionComponent={Zoom}
                                                title={ticketPriorityTxt}
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            textTransform:
                                                                "capitalize",
                                                        },
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={(theme) => ({
                                                        display: "inline-block",
                                                        height: "8px",
                                                        width: "8px",
                                                        backgroundColor:
                                                            ticketPriorityColorObj
                                                                ? ticketPriorityColorObj.bgColor
                                                                : theme.palette
                                                                      .text
                                                                      .disabled,
                                                        borderRadius: "50%",
                                                    })}
                                                />
                                            </Tooltip>
                                        ); */
                                    } else if (
                                        columnId === TICKET_KEYS.FM_USERS
                                    ) {
                                        cellContents =
                                            ticketFmUsers.length > 0 ? (
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                >
                                                    {ticketFmUsers.map(
                                                        (
                                                            ticketFmUser,
                                                            ticketFmUserIdx
                                                        ) => {
                                                            const ticketFmUserId =
                                                                ticketFmUser[
                                                                    FM_USER_KEYS
                                                                        .ID
                                                                ];
                                                            const ticketFmUserName =
                                                                ticketFmUser[
                                                                    FM_USER_KEYS
                                                                        .NAME
                                                                ];
                                                            const ticketFmUserInitials =
                                                                ticketFmUser[
                                                                    FM_USER_KEYS
                                                                        .PHOTO_TEXT
                                                                ];
                                                            const ticketFmUserPhoto =
                                                                ticketFmUser[
                                                                    FM_USER_KEYS
                                                                        .PROFILE_PIC
                                                                ] || "";

                                                            return (
                                                                <Tooltip
                                                                    key={`assigned-ae-${ticketFmUserIdx}-${ticketFmUserId}`}
                                                                    title={
                                                                        ticketFmUserName
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        alt={
                                                                            ticketFmUserName
                                                                        }
                                                                        src={
                                                                            ticketFmUserPhoto
                                                                        }
                                                                        onClick={() =>
                                                                            canAssignFmUsers
                                                                                ? navigate(
                                                                                      `/fmUser/${ticketFmUserId}/`
                                                                                  )
                                                                                : false
                                                                        }
                                                                        sx={{
                                                                            cursor: canAssignFmUsers
                                                                                ? "pointer"
                                                                                : "default",
                                                                            ml: "-8px",
                                                                        }}
                                                                    >
                                                                        {ticketFmUserPhoto ||
                                                                            ticketFmUserInitials}
                                                                    </Avatar>
                                                                </Tooltip>
                                                            );
                                                        }
                                                    )}
                                                </Stack>
                                            ) : (
                                                <EmptyValue align="center" />
                                            );
                                    } else if (
                                        columnId === TICKET_KEYS.SUBJECT
                                    ) {
                                        if (
                                            columnsToShow.includes(
                                                TICKET_KEYS.ID
                                            )
                                        ) {
                                            cellContents = (
                                                <Typography>
                                                    {
                                                        ticket[
                                                            TICKET_KEYS.SUBJECT
                                                        ]
                                                    }
                                                </Typography>
                                            );
                                        } else {
                                            cellContents = (
                                                <Stack
                                                    direction="column"
                                                    flexWrap="nowrap"
                                                    alignItems="baseline"
                                                    spacing={1}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        sx={(theme) => ({
                                                            fontWeight:
                                                                theme.typography
                                                                    .fontWeightBold,
                                                            color: theme.palette
                                                                .secondary.main,
                                                        })}
                                                    >
                                                        {`#${
                                                            ticket[
                                                                TICKET_KEYS
                                                                    .TICKETID
                                                            ]
                                                        }`}
                                                    </Typography>
                                                    <NameLink
                                                        component={RouterLink}
                                                        to={`/supportTicket/${ticketId}/`}
                                                        variant="body1"
                                                    >
                                                        {
                                                            ticket[
                                                                TICKET_KEYS
                                                                    .SUBJECT
                                                            ]
                                                        }
                                                    </NameLink>
                                                </Stack>
                                            );
                                        }
                                    } else if (
                                        columnId === TICKET_KEYS.CREATED_AT
                                    ) {
                                        cellContents =
                                            ticket[TICKET_KEYS.CREATED_AT];
                                    } else if (
                                        columnId === TICKET_KEYS.UPDATED_AT
                                    ) {
                                        cellContents =
                                            ticket?.[TICKET_KEYS.UPDATED_AT] ||
                                            ticket[TICKET_KEYS.CREATED_AT];
                                    }

                                    const tableCellKey = `cell-${columnId}-${columnIdx}`;
                                    if (columnIdx === 0) {
                                        return (
                                            <TableCell
                                                key={tableCellKey}
                                                component="th"
                                                scope="row"
                                            >
                                                {cellContents}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={tableCellKey}>
                                                {cellContents}
                                            </TableCell>
                                        );
                                    }
                                })}
                                {showActions && (
                                    <TableCell>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {canReadDescription && (
                                                <TableActionIcon
                                                    iconComponent="description"
                                                    label="read description"
                                                    tooltip="Read Description"
                                                    onClick={() =>
                                                        onReadDescription(
                                                            ticket
                                                        )
                                                    }
                                                />
                                            )}
                                            {canAssignFmUsers && (
                                                <TableActionIcon
                                                    iconComponent="groupAdd"
                                                    label="assign users"
                                                    tooltip="Assign Users"
                                                    onClick={() =>
                                                        onAssignFmUsers(ticket)
                                                    }
                                                />
                                            )}
                                            {canUpdatePriority && (
                                                <TableActionIcon
                                                    iconComponent="priorityHigh"
                                                    label="update ticket priority"
                                                    tooltip="Update Ticket priority"
                                                    onClick={() => {
                                                        onEditPriority(ticket);
                                                    }}
                                                />
                                            )}
                                            {canUpdateStatus && (
                                                <TableActionIcon
                                                    iconComponent="sell"
                                                    label="update ticket status"
                                                    tooltip="Update Ticket Status"
                                                    onClick={() =>
                                                        onEditStatus(ticket)
                                                    }
                                                />
                                            )}
                                            {/* canDeleteTicket && (
                                                <TableActionIcon
                                                    iconComponent="delete"
                                                    label="delete ticket"
                                                    tooltip="Delete Ticket"
                                                    onClick={() =>
                                                        onDelete(ticket)
                                                    }
                                                />
                                            ) */}
                                        </Stack>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={colspan} sx={{ ...paddingSx }}>
                            <Alert severity={ticketsFetched ? "error" : "info"}>
                                {ticketsFetched
                                    ? "There are no records found."
                                    : "Loading tickets..."}
                            </Alert>
                        </TableCell>
                    </TableRow>
                )}
                {showEmptyRow &&
                    ticketCountNonZero &&
                    fetchedTicketsCount < rowsPerPage && (
                        <TableRow>
                            <TableCell
                                colSpan={colspan}
                                sx={{ ...paddingSx }}
                            ></TableCell>
                        </TableRow>
                    )}
            </TableBody>
        </Table>
    );

    return showColorCard ? (
        <TableColorCard
            actions={tableActions}
            cardColor={cardColor}
            title={
                ticketCounts?.[TICKET_KEYS.TOTAL]
                    ? `${ticketCounts[TICKET_KEYS.TOTAL]}`
                    : "NA"
            }
            subtitle={`Open ${
                ticketCounts?.[TICKET_KEYS.TOTAL] &&
                ticketCounts[TICKET_KEYS.TOTAL] === 1
                    ? "Ticket"
                    : "Tickets"
            }`}
            sx={{ cardSx: { height: "100%", width: "100%" } }}
        >
            <TableContainer>{table}</TableContainer>
        </TableColorCard>
    ) : (
        <React.Fragment>
            <TableContainer component={Paper}>{table}</TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    ticketCountNonZero
                        ? parseInt(ticketCounts[TICKET_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={ticketCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

TicketsTable.propTypes = {
    ariaLabel: PropTypes.string,
    canAssignFmUsers: PropTypes.bool,
    /* canDeleteTicket: PropTypes.bool, */
    canUpdatePriority: PropTypes.bool,
    canUpdateStatus: PropTypes.bool,
    canReadDescription: PropTypes.bool,
    cardColor: PropTypes.string,
    columnsToShow: PropTypes.array,
    headCells: PropTypes.array,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    showColorCard: PropTypes.bool,
    showEmptyRow: PropTypes.bool,
    sort: PropTypes.string,
    tableActions: PropTypes.array,
    tickets: PropTypes.array,
    ticketCounts: PropTypes.object,
    ticketsFetched: PropTypes.bool,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onAssignFmUsers: PropTypes.func,
    /* onDelete: PropTypes.func, */
    onEditPriority: PropTypes.func,
    onEditStatus: PropTypes.func,
    onReadDescription: PropTypes.func,
    onSort: PropTypes.func,
};

TicketsTable.defaultProps = {
    ariaLabel: "Tickets Table",
    canAssignFmUsers: false,
    /* canDeleteTicket: false, */
    canUpdatePriority: false,
    canUpdateStatus: false,
    canReadDescription: false,
    cardColor: "",
    columnsToShow: [],
    headCells: [],
    order: "",
    page: 0,
    rowsPerPage: 0,
    showColorCard: false,
    showEmptyRow: false,
    sort: "",
    tableActions: [],
    tickets: [],
    ticketCounts: null,
    ticketsFetched: false,
    handlePageChange: () => {},
    handleRowsPerPageChange: () => {},
    onAssignFmUsers: () => {},
    /* onDelete: () => {}, */
    onEditPriority: () => {},
    onEditStatus: () => {},
    onReadDescription: () => {},
    onSort: () => {},
};

export default TicketsTable;
