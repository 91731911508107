import {
    ADD_FOLDER,
    DELETE_FOLDER,
    EDIT_FOLDER,
    GET_FOLDER_COUNT,
    GET_FOLDERS,
    SET_FOLDER_DETAILS,
} from "../../utils/constants/actionTypes";
import { FOLDER_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    folders: [],
    currentFolder: null,
    folderCounts: { [FOLDER_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FOLDERS: {
            return {
                ...state,
                folders: action.payload,
            };
        }
        case GET_FOLDER_COUNT: {
            return {
                ...state,
                folderCounts: action.payload,
            };
        }
        case SET_FOLDER_DETAILS: {
            return {
                ...state,
                currentFolder: action.payload,
            };
        }
        case ADD_FOLDER: {
            return {
                ...state,
                folders: [action.payload, ...state.folders],
            };
        }
        case EDIT_FOLDER: {
            return {
                ...state,
                folders: state.folders.map((folder) =>
                    folder.id === action.payload.id ? action.payload : folder
                ),
            };
        }
        case DELETE_FOLDER: {
            return {
                ...state,
                folders: state.folders.filter(
                    (folder) => folder.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
