import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { capitalizeFLetter } from "@jumbo/utils";
import { addAccountAsset } from "../../redux/actions/accountAssets";
import { getAllAssetTypesLocalState } from "../../redux/actions/assetTypes";
import { getAllCompanies, getCompany } from "../../redux/actions/companies";
import { getAllFolders } from "../../redux/actions/folders";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import NameLink from "../../shared/widgets/NameLink";
import { getAssetFileNameFromLink } from "../../utils/accountAssetHelpers";
import {
    ACCOUNT_ASSET_KEYS,
    ACCOUNT_ASSET_TYPE_LOGO,
    ASSET_TYPE_KEYS,
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    FOLDER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import {
    checkAccountAssetTypeValid,
    checkAccountAssetValid,
    checkAssignedCompanyValid,
    checkAssignedFolderValid,
    checkDocumentNameValid,
} from "../../utils/validationHelpers";

const AddFileDialog = ({ open, onClose, onInsert }) => {
    const _isAddFileDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    /* Asset types states */
    const [fetchAssetTypes, setFetchAssetTypes] = useState(false);
    const [assetTypeNames, setAssetTypeNames] = useState([]);
    /* Asset states */
    const [allCompanies, setAllCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const [companyId, setCompanyId] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [folders, setFolders] = useState([]);
    const [folder, setFolder] = useState(null);
    const [folderId, setFolderId] = useState("");
    const [folderError, setFolderError] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [documentNameError, setDocumentNameError] = useState("");
    const [documentType, setDocumentType] = useState(ACCOUNT_ASSET_TYPE_LOGO);
    const [documentTypeError, setDocumentTypeError] = useState("");
    const [document, setDocument] = useState(null);
    const [documentError, setDocumentError] = useState("");
    const [documentLink, setDocumentLink] = useState("");
    /* Miscellaneous states */
    const [isLoading, setIsLoading] = useState(false);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserCompanyId =
        authUser &&
        authUser?.[AUTH_USER_KEYS.COMPANY_ID] &&
        authUser[AUTH_USER_KEYS.COMPANY_ID]
            ? authUser[AUTH_USER_KEYS.COMPANY_ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    const canAssignCompany = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isAddFileDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setFetchAssetTypes(false);
            setAssetTypeNames([]);
            setAllCompanies([]);
            setCompany(null);
            setCompanyId("");
            setCompanyError("");
            setFolders([]);
            setFolder(null);
            setFolderId("");
            setFolderError("");
            setDocumentName("");
            setDocumentNameError("");
            setDocumentType("");
            setDocumentTypeError("");
            setDocument(null);
            setDocumentError("");
            setDocumentLink("");
            setIsLoading(false);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    /* Start Loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch all companies if can assign company */
    useEffect(() => {
        let isActive = true;

        if (canAssignCompany) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllCompanies(payload, (fetchedCompanies) => {
                            if (isActive) {
                                setAllCompanies(
                                    fetchedCompanies?.companies || []
                                );
                                setFetchAssetTypes(true);
                            }
                        })
                    );
                };
            };

            const companyData = { authcode: authToken };
            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setAllCompanies([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, canAssignCompany]);

    /* Fetch company details if cannot assign company */
    useEffect(() => {
        let isActive = true;

        if (!canAssignCompany && !!authUserCompanyId) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompany(fetchedCompany || null);
                                setFetchAssetTypes(true);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: authUserCompanyId,
            };

            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setCompany(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, authUserCompanyId, canAssignCompany]);

    /* Fetch all asset types */
    useEffect(() => {
        let isActive = true;

        if (fetchAssetTypes) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllAssetTypesLocalState(
                            payload,
                            (fetchedAssetTypes) => {
                                if (isActive) {
                                    setFetchAssetTypes(false);
                                    if (
                                        fetchedAssetTypes &&
                                        fetchedAssetTypes.length > 0
                                    ) {
                                        setAssetTypeNames(
                                            fetchedAssetTypes.map(
                                                (fetchedAssetTypeObj) =>
                                                    fetchedAssetTypeObj[
                                                        ASSET_TYPE_KEYS.NAME
                                                    ]
                                            )
                                        );
                                    } else {
                                        setAssetTypeNames([]);
                                    }
                                    setIsLoading(false);
                                }
                            }
                        )
                    );
                };
            };

            const promise = dispatch(
                fetchData({
                    assetTypeData: {
                        authcode: authToken,
                    },
                    fetchStart: true,
                })
            );
            promise.catch((error) => {
                if (isActive) {
                    setFetchAssetTypes(false);
                    setAssetTypeNames([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchAssetTypes]);

    /* Set company ID from company */
    useEffect(() => {
        if (company) {
            setCompanyId(company[COMPANY_KEYS.ID]);
        } else {
            setCompanyId("");
        }
    }, [company]);

    /* Fetch all folders of company */
    useEffect(() => {
        let isActive = true;

        setFolder(null);
        setFolderError("");
        if (companyId) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllFolders(payload, (fetchedData) => {
                            if (isActive) {
                                setFolders(fetchedData?.folders || []);
                            }
                        })
                    );
                };
            };

            const folderData = {
                authcode: authToken,
                [FOLDER_KEYS.COMPANY_ID]: companyId,
            };
            const promise = dispatch(fetchData({ folderData: folderData }));
            promise.catch((error) => {
                if (isActive) {
                    setFolders([]);
                }
            });
        } else {
            setFolders([]);
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, companyId]);

    /* Set folder ID from folder */
    useEffect(() => {
        if (folder) {
            setFolderId(folder[FOLDER_KEYS.ID]);
        } else {
            setFolderId("");
        }
    }, [folder]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case ACCOUNT_ASSET_KEYS.COMPANY_ID:
                        setCompanyError(msg);
                        break;

                    case ACCOUNT_ASSET_KEYS.FOLDER_ID:
                        setFolderError(msg);
                        break;

                    case ACCOUNT_ASSET_KEYS.DOCUMENT_NAME:
                        setDocumentNameError(msg);
                        break;

                    case ACCOUNT_ASSET_KEYS.DOCUMENT_TYPE:
                        setDocumentNameError(msg);
                        break;

                    case ACCOUNT_ASSET_KEYS.DOCUMENT:
                        setDocumentError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const companyChangeHandler = (event, newValue) => {
        setCompanyError("");

        const assignedCompanyValidationResult = checkAssignedCompanyValid(
            newValue,
            allCompanies
        );
        if (assignedCompanyValidationResult.status) {
            if (assignedCompanyValidationResult.status === "false") {
                setCompany(null);
                setCompanyError(assignedCompanyValidationResult.msg);
            } else {
                setCompany(newValue);
            }
        } else {
            setCompany(null);
            setCompanyError(VALIDATION_FAILED);
        }
    };

    const folderChangeHandler = (event, newValue) => {
        setFolderError("");

        const assignedFolderValidationResult = checkAssignedFolderValid(
            newValue,
            folders
        );
        if (assignedFolderValidationResult.status) {
            if (assignedFolderValidationResult.status === "false") {
                setFolder(null);
                setFolderError(assignedFolderValidationResult.msg);
            } else {
                setFolder(newValue);
            }
        } else {
            setFolder(null);
            setFolderError(VALIDATION_FAILED);
        }
    };

    const documentNameBlurHandler = (event) => {
        const validationResult = checkDocumentNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDocumentNameError(validationResult.msg);
            }
        } else {
            setDocumentNameError(VALIDATION_FAILED);
        }
    };

    const documentTypeChangeHandler = (event) => {
        const selectedDocumentType = event.target.value;
        if (selectedDocumentType && selectedDocumentType.length > 0) {
            setDocumentType(selectedDocumentType);
        } else {
            setDocumentType("");
        }
        setDocumentTypeError("");

        const documentTypeValidationResult = checkAccountAssetTypeValid(
            selectedDocumentType,
            assetTypeNames
        );
        if (documentTypeValidationResult.status) {
            if (documentTypeValidationResult.status === "false") {
                setDocumentTypeError(documentTypeValidationResult.msg);
            }
        } else {
            setDocumentTypeError(VALIDATION_FAILED);
        }
    };

    const documentChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (!documentLink || (files && files.length > 0)) {
            const validationResult = checkAccountAssetValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setDocumentError(validationResult.msg);
                }
            } else {
                isValid = false;
                setDocumentError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setDocument(files[0]);
            setDocumentError("");
        } else {
            setDocument(null);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        if (canAssignCompany) {
            const companyValidationResult = checkAssignedCompanyValid(
                company,
                allCompanies
            );
            if (companyValidationResult.status) {
                if (companyValidationResult.status === "false") {
                    formIsValid = false;
                    setCompanyError(companyValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setCompanyError(VALIDATION_FAILED);
            }
        }

        const folderValidationResult = checkAssignedFolderValid(
            folder,
            folders
        );
        if (folderValidationResult.status) {
            if (folderValidationResult.status === "false") {
                formIsValid = false;
                setFolderError(folderValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setFolderError(VALIDATION_FAILED);
        }

        const documentNameValidationResult =
            checkDocumentNameValid(documentName);
        if (documentNameValidationResult.status) {
            if (documentNameValidationResult.status === "false") {
                formIsValid = false;
                setDocumentNameError(documentNameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDocumentNameError(VALIDATION_FAILED);
        }

        const documentTypeValidationResult = checkAccountAssetTypeValid(
            documentType,
            assetTypeNames
        );
        if (documentTypeValidationResult.status) {
            if (documentTypeValidationResult.status === "false") {
                formIsValid = false;
                setDocumentTypeError(documentTypeValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDocumentTypeError(VALIDATION_FAILED);
        }

        if (!documentLink || document) {
            const documentValidationResult = checkAccountAssetValid([document]);
            if (documentValidationResult.status) {
                if (documentValidationResult.status === "false") {
                    formIsValid = false;
                    setDocumentError(documentValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setDocumentError(VALIDATION_FAILED);
            }
        }

        if (formIsValid) {
            const accountAssetData = new FormData();
            accountAssetData.append("authcode", authToken);
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.COMPANY_ID],
                companyId || ""
            );
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.FOLDER_ID],
                folderId || ""
            );
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.DOCUMENT_NAME],
                documentName
            );
            accountAssetData.append(
                [ACCOUNT_ASSET_KEYS.DOCUMENT_TYPE],
                documentType
            );
            accountAssetData.append([ACCOUNT_ASSET_KEYS.DOCUMENT], document);

            dispatch(
                addAccountAsset(
                    { accountAssetData: accountAssetData },
                    () => {
                        if (_isAddFileDialogMounted.current) {
                            onInsert();
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isAddFileDialogMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const assetTypesValid = useMemo(() => {
        return assetTypeNames && assetTypeNames.length > 0;
    }, [assetTypeNames]);

    const loadError = !isValidUserType || !assetTypesValid;

    const companyFoldersNotFoundMsg =
        canAssignCompany && company && companyId && folders.length === 0
            ? `No folders found for ${company[COMPANY_KEYS.NAME]}!`
            : "";

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title="Upload Asset"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {!isLoading && loadError && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !assetTypesValid && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    There was a problem in fetching the asset
                                    types!
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!isLoading && !loadError && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            {canAssignCompany && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            filterSelectedOptions
                                            id="company_id"
                                            options={allCompanies}
                                            value={company}
                                            getOptionLabel={(option) => {
                                                return option &&
                                                    option[COMPANY_KEYS.ID]
                                                    ? option[COMPANY_KEYS.NAME]
                                                    : "";
                                            }}
                                            isOptionEqualToValue={(
                                                option,
                                                newValue
                                            ) => {
                                                return (
                                                    option[COMPANY_KEYS.ID] ===
                                                    newValue[COMPANY_KEYS.ID]
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Select Company"
                                                    error={companyError !== ""}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li
                                                    {...props}
                                                    key={
                                                        option[COMPANY_KEYS.ID]
                                                    }
                                                >
                                                    {option[COMPANY_KEYS.NAME]}
                                                </li>
                                            )}
                                            onChange={companyChangeHandler}
                                        />
                                        {companyError && (
                                            <FormHelperText
                                                error
                                                id="company_id-error"
                                            >
                                                {companyError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        filterSelectedOptions
                                        id="folder_id"
                                        options={folders}
                                        value={folder}
                                        getOptionLabel={(option) => {
                                            return option &&
                                                option[FOLDER_KEYS.ID]
                                                ? option[FOLDER_KEYS.NAME]
                                                : "";
                                        }}
                                        isOptionEqualToValue={(
                                            option,
                                            newValue
                                        ) => {
                                            return (
                                                option[FOLDER_KEYS.ID] ===
                                                newValue[FOLDER_KEYS.ID]
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Select Folder"
                                                error={
                                                    folderError !== "" ||
                                                    companyFoldersNotFoundMsg !==
                                                        ""
                                                }
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li
                                                {...props}
                                                key={option[FOLDER_KEYS.ID]}
                                            >
                                                {option[FOLDER_KEYS.NAME]}
                                            </li>
                                        )}
                                        onChange={folderChangeHandler}
                                    />
                                    {(folderError ||
                                        companyFoldersNotFoundMsg) && (
                                        <FormHelperText
                                            error
                                            id="folder_id-error"
                                        >
                                            {folderError ||
                                                companyFoldersNotFoundMsg}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="document_name"
                                    name="document_name"
                                    label="Document Name"
                                    value={documentName}
                                    margin="dense"
                                    onChange={(e) => {
                                        setDocumentName(e.target.value);
                                        setDocumentNameError("");
                                    }}
                                    onBlur={documentNameBlurHandler}
                                    error={documentNameError !== ""}
                                    helperText={documentNameError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="document-type-label"
                                        error={documentTypeError !== ""}
                                    >
                                        Document Type
                                    </InputLabel>
                                    <Select
                                        labelId="document-type-label"
                                        id="document-type"
                                        value={documentType}
                                        label="Document Type"
                                        input={
                                            <OutlinedInput
                                                fullWidth
                                                label="Document Type"
                                                error={documentTypeError !== ""}
                                            />
                                        }
                                        onChange={documentTypeChangeHandler}
                                    >
                                        {assetTypeNames.map((assetTypeName) => {
                                            const assetTypeNameParts =
                                                assetTypeName
                                                    .split("_")
                                                    .map((assetTypeNamePart) =>
                                                        capitalizeFLetter(
                                                            assetTypeNamePart
                                                        )
                                                    );
                                            return (
                                                <MenuItem
                                                    key={assetTypeName}
                                                    value={assetTypeName}
                                                >
                                                    {assetTypeNameParts.join(
                                                        " "
                                                    )}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {documentTypeError && (
                                        <FormHelperText
                                            error
                                            id="document-type-error"
                                        >
                                            {documentTypeError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <label
                                    htmlFor="document"
                                    style={{
                                        display: "block",
                                        marginTop: 8,
                                    }}
                                >
                                    <input
                                        id="document"
                                        name="document"
                                        type="file"
                                        onChange={documentChangeHandler}
                                        style={{ display: "none" }}
                                    />

                                    <AttachFileButton
                                        fullWidth
                                        type="button"
                                        variant="outlined"
                                        component="span"
                                        startIcon={<AttachFileIcon />}
                                        sx={(theme) => {
                                            const documentErrorSx =
                                                documentError
                                                    ? {
                                                          color: theme.palette
                                                              .error.main,
                                                          border: `1px solid ${theme.palette.error.main}`,
                                                      }
                                                    : "";
                                            return {
                                                ...documentErrorSx,
                                            };
                                        }}
                                    >
                                        {document
                                            ? document.name
                                            : "Choose Document"}
                                    </AttachFileButton>
                                </label>
                                {documentError && (
                                    <FormHelperText error id="document-error">
                                        {documentError}
                                    </FormHelperText>
                                )}
                            </Grid>
                            {documentLink && (
                                <Grid item xs={12}>
                                    <NameLink
                                        download={getAssetFileNameFromLink(
                                            documentLink
                                        )}
                                        noWrap
                                        align="center"
                                        href={documentLink}
                                        target="_blank"
                                        sx={(theme) => ({
                                            display: "block",
                                            wordWrap: "break-word",
                                            ...theme.typography.h5,
                                        })}
                                    >
                                        View Asset
                                    </NameLink>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddFileDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onInsert: PropTypes.func,
};

AddFileDialog.defaultProps = {
    open: false,
    // onClose: () => {},
    // onInsert: () => {},
};

export default AddFileDialog;
