import React from "react";
import Report from "../pages/report";
import Reports from "../pages/reports";
import Page from "@jumbo/shared/Page";

const reportsRoutes = [
    {
        path: "/reports",
        element: <Page component={Reports} />,
    },
    {
        path: "/report/:reportId/",
        element: <Page component={Report} />,
    },
];

export default reportsRoutes;
