export const cleanPhoneNo = (phoneNo = "") => {
    return phoneNo ? phoneNo.replace(/[-\s)(]/g, "") : "";
};

export const isHTML = (str = "") => {
    if (str) {
        /* const regex = new RegExp(/(<([^>]+)>)/i); */
        const regex = new RegExp(/(<([a-zA-Z]+)>)/i);
        return regex.test(str);
    }
    return false;
};
