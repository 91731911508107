import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div";
import { uploadWorkOrderDocument } from "../../redux/actions/assignedServices";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import DnDAcceptedFiles from "../../shared/widgets/DnDAcceptedFiles";
import DnDRejectedFiles from "../../shared/widgets/DnDRejectedFiles";
import DndWrapper from "../../shared/widgets/DndWrapper";
import {
    ASSIGNED_SERVICE_KEYS,
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    VALID_COMMON_FILE_MAX_SIZE_MB,
    VALID_WO_FILE_EXTENSIONS,
    VALID_WO_FILE_MIME_TYPES,
    VALID_COMMON_FILES_COUNT,
} from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkWorkOrderDocumentsValid } from "../../utils/validationHelpers";

const UploadWorkOrderDocumentDialog = ({
    currentAssignedService,
    open,
    setGlobalState,
    setLocalState,
    onClose,
    onDispatchAction,
}) => {
    const _isUploadWODocDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [documents, setDocuments] = useState([]);
    const [rejectedDocuments, setRejectedDocuments] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: VALID_WO_FILE_MIME_TYPES.join(","),
        maxSize: VALID_COMMON_FILE_MAX_SIZE_MB * 1024 * 1024,
        maxFiles: VALID_COMMON_FILES_COUNT,
        onDrop: (acceptedFiles, fileRejections) => {
            setDocuments((prevState) => {
                return acceptedFiles.reduce((accumulator, file) => {
                    return [...accumulator, file];
                }, prevState);
            });
            setRejectedDocuments(
                fileRejections.reduce((accumulator, file) => {
                    return [...accumulator, file];
                }, [])
            );
        },
    });

    const [assignedServiceValid, setAssignedServiceValid] = useState(false);
    const [documentsError, setDocumentsError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isUploadWODocDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAssignedServiceValid(false);
            setDocuments([]);
            setRejectedDocuments([]);
            setDocumentsError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (isValidUserType) {
            if (
                currentAssignedService &&
                currentAssignedService?.[ASSIGNED_SERVICE_KEYS.ID] &&
                currentAssignedService[ASSIGNED_SERVICE_KEYS.ID]
            ) {
                setAssignedServiceValid(true);
            }
        }
    }, [currentAssignedService, isValidUserType]);

    useEffect(() => {
        const documentsValidationResult =
            checkWorkOrderDocumentsValid(documents);

        if (
            documentsValidationResult.status &&
            documentsValidationResult.status === "true"
        ) {
            setDocumentsError("");
        }
    }, [documents]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case ASSIGNED_SERVICE_KEYS.DOCUMENTS_ATTACH:
                        setDocumentsError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const handleRemoveFile = (fileIdx) => {
        setDocuments((prevState) =>
            prevState.filter((temp, tempIdx) => fileIdx !== tempIdx)
        );
    };

    const handleRemoveRejectedFile = (rejectedFileIdx) => {
        setRejectedDocuments((prevState) =>
            prevState.filter((temp, tempIdx) => rejectedFileIdx !== tempIdx)
        );
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const acceptedFilesValidationResult =
            checkWorkOrderDocumentsValid(documents);
        if (acceptedFilesValidationResult.status) {
            if (acceptedFilesValidationResult.status === "false") {
                formIsValid = false;
                setDocumentsError(acceptedFilesValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDocumentsError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const assignedServiceData = new FormData();
            assignedServiceData.append("authcode", authToken);
            assignedServiceData.append(
                [ASSIGNED_SERVICE_KEYS.ASSIGNED_SERVICE_ID],
                currentAssignedService[ASSIGNED_SERVICE_KEYS.ID]
            );
            for (let i = 0; i < documents.length; i++) {
                assignedServiceData.append(
                    [ASSIGNED_SERVICE_KEYS.DOCUMENTS_ATTACH],
                    documents[i]
                );
            }

            dispatch(
                uploadWorkOrderDocument(
                    {
                        assignedServiceData: assignedServiceData,
                        setGlobalState: setGlobalState,
                    },
                    (updatedAssignedService) => {
                        if (_isUploadWODocDialogMounted.current) {
                            if (setLocalState) {
                                onDispatchAction({
                                    type: "UPDATE",
                                    updatedService: updatedAssignedService,
                                });
                            }
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isUploadWODocDialogMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const documentsHTML = useMemo(() => {
        if (documents && documents.length > 0) {
            return (
                <React.Fragment>
                    <Typography
                        component="p"
                        variant="h6"
                        sx={(theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                        })}
                    >
                        Accepted Files
                    </Typography>
                    <DnDAcceptedFiles
                        files={documents}
                        onRemove={handleRemoveFile}
                    />
                </React.Fragment>
            );
        } else {
            return <Alert severity="info">No files selected!</Alert>;
        }
    }, [documents]);

    const rejectedFilesHTML = useMemo(() => {
        if (rejectedDocuments && rejectedDocuments.length > 0) {
            return (
                <React.Fragment>
                    <Typography
                        component="p"
                        variant="h6"
                        sx={(theme) => ({
                            mt: theme.spacing(1),
                            fontWeight: theme.typography.fontWeightBold,
                        })}
                    >
                        Rejected Files
                    </Typography>
                    <DnDRejectedFiles
                        files={rejectedDocuments}
                        validFileExtensions={VALID_WO_FILE_EXTENSIONS}
                        onRemove={handleRemoveRejectedFile}
                    />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }, [rejectedDocuments]);

    const errorOnLoading = !isValidUserType || !assignedServiceValid;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title="Upload Work Order Documents"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {errorOnLoading && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !assignedServiceValid && (
                            <Grid item xs={12}>
                                <Alert severity="error" variant="outlined">
                                    Invalid service!
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!errorOnLoading && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <Div sx={{ flex: 1 }}>
                                    <DndWrapper>
                                        <div
                                            {...getRootProps({
                                                className:
                                                    "dropzoneCustomInputBtn",
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography variant={"body1"}>
                                                Drag 'n' drop some files here,
                                                or click to select files
                                            </Typography>
                                        </div>
                                    </DndWrapper>
                                    {documentsError && (
                                        <Typography
                                            component="p"
                                            variant="body1"
                                            sx={(theme) => ({
                                                mb: theme.spacing(1.5),
                                                color: theme.palette.error.main,
                                            })}
                                        >
                                            {documentsError}
                                        </Typography>
                                    )}
                                    {documentsHTML}
                                    {rejectedFilesHTML}
                                </Div>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

UploadWorkOrderDocumentDialog.propTypes = {
    currentAssignedService: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    setLocalState: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

UploadWorkOrderDocumentDialog.defaultProps = {
    currentAssignedService: null,
    open: false,
    setGlobalState: false,
    setLocalState: false,
    onDispatchAction: () => {},
};

export default UploadWorkOrderDocumentDialog;
