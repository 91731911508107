import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import {
    ANNOUNCEMENT_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const allHeadCells = [
    {
        id: ANNOUNCEMENT_KEYS.TITLE,
        label: "Title",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "30%",
    },
    {
        id: ANNOUNCEMENT_KEYS.INDUSTRY,
        label: "Industry",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "20%",
    },
    {
        id: ANNOUNCEMENT_KEYS.START_DATE,
        label: "From",
        show: true,
        sortColumn: true,
        useColumn: true,
    },
    {
        id: ANNOUNCEMENT_KEYS.END_DATE,
        label: "End",
        show: true,
        sortColumn: true,
        useColumn: true,
    },
];

const AnnouncementsTable = ({
    announcements,
    announcementCounts,
    announcementsFetched,
    canDeleteAnnouncement,
    canEditAnnouncement,
    canViewAnnouncement,
    order,
    page,
    rowsPerPage,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onEdit,
    onDelete,
    onSort,
    onView,
}) => {
    let colspan = allHeadCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const announcementCountNonZero =
        announcements &&
        announcements.length > 0 &&
        announcementCounts &&
        announcementCounts[ANNOUNCEMENT_KEYS.TOTAL] &&
        !isNaN(parseInt(announcementCounts[ANNOUNCEMENT_KEYS.TOTAL])) &&
        parseInt(announcementCounts[ANNOUNCEMENT_KEYS.TOTAL]) > 0;

    const numTotalCols = allHeadCells.length;
    const numVisibleCols = allHeadCells.length;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Announcements Table">
                    <TableHead>
                        <TableRow>
                            {allHeadCells.map((headCell) => {
                                const sx = {};

                                if (headCell?.width) {
                                    const headCellWidth = Number(
                                        headCell.width.match(/\d+(\.\d+)?/)[0]
                                    );
                                    const updHeadCellWidth =
                                        (numTotalCols / numVisibleCols) *
                                        headCellWidth;
                                    sx.width = `${updHeadCellWidth.toFixed(
                                        2
                                    )}%`;
                                }

                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id} sx={{ ...sx }}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            <TableCell
                                key="actions"
                                sx={{ textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {announcementCountNonZero ? (
                            announcements.map((announcement) => {
                                return (
                                    <TableRow
                                        key={announcement[ANNOUNCEMENT_KEYS.ID]}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {
                                                announcement[
                                                    ANNOUNCEMENT_KEYS.TITLE
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {announcement?.[
                                                ANNOUNCEMENT_KEYS.INDUSTRY
                                            ] || ""}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                announcement[
                                                    ANNOUNCEMENT_KEYS.START_DATE
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                announcement[
                                                    ANNOUNCEMENT_KEYS.END_DATE
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {canViewAnnouncement && (
                                                    <TableActionIcon
                                                        iconComponent="visibility"
                                                        label="view announcement"
                                                        tooltip="View Announcement"
                                                        onClick={() =>
                                                            onView(announcement)
                                                        }
                                                    />
                                                )}
                                                {canEditAnnouncement && (
                                                    <TableActionIcon
                                                        iconComponent="edit"
                                                        label="edit announcement"
                                                        tooltip="Edit Announcement"
                                                        onClick={() =>
                                                            onEdit(announcement)
                                                        }
                                                    />
                                                )}
                                                {canDeleteAnnouncement && (
                                                    <TableActionIcon
                                                        iconComponent="delete"
                                                        label="delete announcement"
                                                        tooltip="Delete Announcement"
                                                        onClick={() =>
                                                            onDelete(
                                                                announcement
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            announcementsFetched
                                                ? "error"
                                                : "info"
                                        }
                                    >
                                        {announcementsFetched
                                            ? "There are no records found."
                                            : "Loading announcements..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    announcementCountNonZero
                        ? parseInt(announcementCounts[ANNOUNCEMENT_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={announcementCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

AnnouncementsTable.propTypes = {
    announcements: PropTypes.array,
    announcementCounts: PropTypes.object,
    announcementsFetched: PropTypes.bool,
    canDeleteAnnouncement: PropTypes.bool,
    canEditAnnouncement: PropTypes.bool,
    canViewAnnouncement: PropTypes.bool,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSort: PropTypes.func,
    onView: PropTypes.func,
};

AnnouncementsTable.defaultProps = {
    announcements: [],
    announcementCounts: null,
    announcementsFetched: false,
    canDeleteAnnouncement: false,
    canEditAnnouncement: false,
    canViewAnnouncement: false,
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onEdit: () => {},
    // onDelete: () => {},
    // onSort: () => {},
    // onView: () => {},
};

export default AnnouncementsTable;
