import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import FmButton from "../../shared/widgets/FmButton";
import { getInitialsFromName } from "../../utils/appHelpers";
import { FM_USER_KEYS } from "../../utils/constants/appData";

const ClientFmUserCard = ({ clientFmUser }) => {
    const scrollbarRef = React.useRef();

    useEffect(() => {
        if (scrollbarRef) scrollbarRef.current?.scrollToBottom();
    }, [scrollbarRef]);

    const fmUserName = clientFmUser[FM_USER_KEYS.NAME];

    const fetchedPhoto = clientFmUser?.[FM_USER_KEYS.PROFILE_PIC];
    let photoLink = "";
    if (fetchedPhoto) {
        photoLink = `${clientFmUser[FM_USER_KEYS.PHOTO_BASE_URL]}/${
            fetchedPhoto[FM_USER_KEYS.PHOTO_FILE_PATH]
        }`;
    }
    const fmUserInitials = photoLink ? (
        <React.Fragment>&nbsp;</React.Fragment>
    ) : (
        getInitialsFromName(fmUserName)
    );

    return (
        <Card
            sx={(theme) => ({
                padding: theme.spacing(1, 1),
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
                borderRadius: "10px",
                opacity: 1,
            })}
        >
            <CardContent sx={{ pb: (theme) => theme.spacing(0.5) }}>
                <Stack alignItems="center">
                    <Box>
                        <Avatar
                            sx={{
                                width: 144,
                                height: 144,
                                mb: 2,
                                bgcolor: "#112434",
                                opacity: 1,
                                fontSize: "2.5rem",
                            }}
                            alt={fmUserName}
                            src={photoLink}
                        >
                            {fmUserInitials}
                        </Avatar>
                    </Box>
                    <Box sx={{ p: 0, m: 0, mb: 1, width: "100%" }}>
                        <Typography
                            noWrap
                            variant={"h4"}
                            color="text.primary"
                            align="center"
                            sx={{
                                fontWeight: (theme) =>
                                    theme.typography.fontWeightBold,
                                wordWrap: "break-word",
                            }}
                        >
                            {fmUserName}
                        </Typography>
                        <Typography
                            noWrap
                            variant="h6"
                            color="text.disabled"
                            align="center"
                            sx={{ wordWrap: "break-word" }}
                        >
                            {clientFmUser[FM_USER_KEYS.ROLE] || "NA"}
                        </Typography>
                    </Box>
                    <Box
                        sx={(theme) => ({
                            p: 0,
                            m: 0,
                            width: "100%",
                            height: "100px",
                            overflow: "hidden",
                        })}
                    >
                        <JumboScrollbar
                            ref={scrollbarRef}
                            autoHide
                            autoHideDuration={200}
                            autoHideTimeout={500}
                            autoHeightMin={30}
                            style={{ height: "100%" }}
                        >
                            <Typography
                                variant="h6"
                                color="text.primary"
                                align="center"
                                sx={{ wordWrap: "break-word" }}
                            >
                                {clientFmUser[FM_USER_KEYS.BIO_DESCRIPTION] ||
                                    ""}
                            </Typography>
                        </JumboScrollbar>
                    </Box>
                </Stack>
            </CardContent>
            <CardActions sx={{ pt: (theme) => theme.spacing(0.5) }}>
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    spacing={1}
                    sx={{ width: "100%" }}
                >
                    <FmButton
                        component={Link}
                        disabled={!clientFmUser[FM_USER_KEYS.BIO_LINK]}
                        href={clientFmUser[FM_USER_KEYS.BIO_LINK] || ""}
                        target="_blank"
                        type="button"
                        variant="outlined"
                        color="primary"
                        label="Get To Know Me"
                    />
                </Stack>
            </CardActions>
        </Card>
    );
};

ClientFmUserCard.propTypes = {
    clientFmUser: PropTypes.object,
};

ClientFmUserCard.defaultProps = {
    clientFmUser: null,
};

export default ClientFmUserCard;
