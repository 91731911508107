import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import HTMLTypography from "../../shared/widgets/HTMLTypography";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SURVEY_KEYS,
    SURVEY_QUESTION_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import { getSurveyLocal, getSurveyResult } from "../../redux/actions/surveys";
/* import QuestionResult from "./QuestionResult"; */

const QuestionResult = lazy(() => import("./QuestionResult"));

const genericPageName = "Survey Result";

const SurveyResult = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isSurveyResultMounted = useRef(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();

    const surveyId = params?.surveyId || "";

    const [isLoading, setIsLoading] = useState(false);
    const [surveyIdIsValid, setSurveyIdIsValid] = useState(false);
    const [surveyIsValid, setSurveyIsValid] = useState(false);
    const [fetchSurvey, setFetchSurvey] = useState(false);
    const [surveyFetched, setSurveyFetched] = useState(false);
    const [currentSurvey, setCurrentSurvey] = useState(null);
    const [fetchSurveyResult, setFetchSurveyResult] = useState(false);
    const [surveyResultFetched, setSurveyResultFetched] = useState(false);
    const [surveyResult, setSurveyResult] = useState(null);
    const [surveyResultValid, setSurveyResultValid] = useState(false);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isSurveyResultMounted.current = false;
        };
    }, []);

    /* Check survey ID is valid */
    useEffect(() => {
        if (isValidUserType) {
            setSurveyIdIsValid(!!surveyId);
        }
    }, [isValidUserType, surveyId]);

    /* Start loading */
    useEffect(() => {
        if (surveyIdIsValid) {
            setIsLoading(true);
        }
    }, [surveyIdIsValid]);

    /* Fetch survey */
    useEffect(() => {
        if (isLoading) {
            setFetchSurvey(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchSurvey) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getSurveyLocal(payload, (fetchedSurvey) => {
                            if (isActive) {
                                setFetchSurvey(false);
                                setSurveyFetched(true);
                                setCurrentSurvey(fetchedSurvey || null);
                            }
                        })
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.SURVEY_ID]: surveyId,
            };

            const promise = dispatch(fetchData({ surveyData: surveyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchSurvey(false);
                    setSurveyFetched(false);
                    setCurrentSurvey(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchSurvey, surveyId]);

    /* Check if fetched survey is valid or not */
    useEffect(() => {
        if (
            surveyFetched &&
            currentSurvey &&
            currentSurvey?.[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] === surveyId
        ) {
            setSurveyIsValid(true);
        } else {
            setSurveyIsValid(false);
        }
    }, [currentSurvey, surveyFetched, surveyId]);

    /* Fetch survey result */
    useEffect(() => {
        if (surveyIsValid) {
            setFetchSurveyResult(true);
        }
    }, [surveyIsValid]);

    useEffect(() => {
        let isActive = true;

        if (fetchSurveyResult) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getSurveyResult(payload, (fetchedSurveyResult) => {
                            if (isActive) {
                                setFetchSurveyResult(false);
                                setSurveyResultFetched(true);
                                setSurveyResult(
                                    fetchedSurveyResult?.[
                                        SURVEY_KEYS.QUESTIONS
                                    ] || null
                                );
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.SURVEY_ID]: surveyId,
            };

            const promise = dispatch(fetchData({ surveyData: surveyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchSurveyResult(false);
                    setSurveyResultFetched(false);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchSurveyResult, surveyId]);

    /* Check if survey results are valid */
    useEffect(() => {
        if (surveyResultFetched) {
            if (surveyResult && surveyResult.length > 0) {
                setSurveyResultValid(true);
            } else {
                setSurveyResultValid(false);
            }
        }
    }, [surveyResult, surveyResultFetched]);

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchSurvey && !surveyFetched;
    const fetchResultError = !fetchSurveyResult && !surveyResultFetched;

    const loadError =
        !isValidUserType ||
        !surveyIdIsValid ||
        fetchError ||
        !surveyIsValid ||
        fetchResultError ||
        !surveyResultValid;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle
                        component="h1"
                        title={
                            surveyIsValid
                                ? `${currentSurvey[SURVEY_KEYS.NAME]} Results`
                                : "Invalid Survey!"
                        }
                        type="v2"
                    />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: surveyIsValid
                                ? `${currentSurvey[SURVEY_KEYS.NAME]} Results`
                                : genericPageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !surveyIdIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid survey!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && surveyIdIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the survey!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType &&
                        surveyIdIsValid &&
                        !fetchError &&
                        !surveyIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Invalid survey details!
                                </Alert>
                            </Grid>
                        )}
                    {isValidUserType &&
                        surveyIdIsValid &&
                        !fetchError &&
                        surveyIsValid &&
                        fetchResultError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    There was a problem in fetching the survey
                                    results!
                                </Alert>
                            </Grid>
                        )}
                    {isValidUserType &&
                        surveyIdIsValid &&
                        !fetchError &&
                        surveyIsValid &&
                        !fetchResultError &&
                        !surveyResultValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Invalid survey results!
                                </Alert>
                            </Grid>
                        )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <HTMLTypography
                            component="span"
                            variant="h5"
                            content={currentSurvey[SURVEY_KEYS.DESCRIPTION]}
                        />
                    </Grid>
                    {surveyResultFetched && surveyResultValid && (
                        <React.Fragment>
                            {surveyResult.map((surveyResultObj) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={
                                        surveyResultObj[SURVEY_QUESTION_KEYS.ID]
                                    }
                                >
                                    <Suspense
                                        fallback={
                                            <Div
                                                sx={{
                                                    display: "flex",
                                                    minWidth: 0,
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                    height: 250,
                                                }}
                                            >
                                                <CircularProgress
                                                    sx={{
                                                        m: "-40px auto 0",
                                                    }}
                                                />
                                            </Div>
                                        }
                                    >
                                        <QuestionResult
                                            result={surveyResultObj}
                                            survey={currentSurvey}
                                        />
                                    </Suspense>
                                </Grid>
                            ))}
                        </React.Fragment>
                    )}
                    {surveyResultFetched && !surveyResultValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Results not found!
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default SurveyResult;
