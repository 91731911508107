import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import MultilineTypography from "../../shared/widgets/MultilineTypography";
import LinkButton from "../../shared/widgets/LinkButton";
import NameLink from "../../shared/widgets/NameLink";
import {
    MESSAGE_FILE_KEYS,
    VALID_FILE_IMG_MIME_TYPES,
} from "../../utils/constants/appData";
import { isHTML } from "../../utils/dataHelpers";
import { getAssetFileExtFromLink } from "../../utils/accountAssetHelpers";

const MSG_SX = {
    p: (theme) => theme.spacing(2),
    borderRadius: (theme) => theme.spacing(0.5),
    backgroundColor: (theme) => theme.palette.divider,
    color: (theme) => theme.palette.primary.main,
};

const SentMessageContent = ({ message, messageKeys, onDownload }) => {
    const msgTxt = message?.[messageKeys.MESSAGE] || "";
    if (!msgTxt) {
        return null;
    }

    const isMsgHTML = isHTML(msgTxt);

    let msgFilesHTML = null;
    const msgFiles = message?.[messageKeys.MESSAGE_FILES] || [];
    if (msgFiles && msgFiles.length > 0) {
        const msgFilesItems = msgFiles.map((msgFile, msgFileIdx) => {
            const msgFileName = msgFile[MESSAGE_FILE_KEYS.NAME];
            const msgFileOriginalName =
                msgFile[MESSAGE_FILE_KEYS.ORIGINAL_NAME];
            const msgFileLink = msgFile[MESSAGE_FILE_KEYS.LINK];
            const msgFileExt = getAssetFileExtFromLink(msgFileLink);
            const msgFileMimeType = msgFile[MESSAGE_FILE_KEYS.MIME_TYPE];

            const key = `msgfile-${msgFileIdx}-${msgFileName}`;

            if (VALID_FILE_IMG_MIME_TYPES.includes(msgFileMimeType)) {
                return (
                    <ListItem
                        key={key}
                        disableGutters
                        sx={{ justifyContent: "flex-end" }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                            }}
                            alt={msgFileOriginalName}
                            src={msgFileLink}
                        />
                    </ListItem>
                );
            } else {
                return (
                    <ListItem
                        key={key}
                        disableGutters
                        sx={{ justifyContent: "flex-end" }}
                    >
                        {["csv", "doc", "docx", "xlsx"].includes(msgFileExt) ? (
                            <LinkButton
                                component="button"
                                onClick={() => {
                                    onDownload(
                                        msgFileLink,
                                        msgFileOriginalName,
                                        msgFileMimeType
                                    );
                                }}
                                sx={{
                                    ...MSG_SX,
                                    wordWrap: "break-word",
                                    "&:hover": {
                                        ...MSG_SX,
                                        color: (theme) =>
                                            theme.palette.secondary.main,
                                    },
                                }}
                            >
                                {msgFileOriginalName}
                            </LinkButton>
                        ) : (
                            <NameLink
                                noWrap
                                download={msgFileOriginalName}
                                alt={msgFileOriginalName}
                                href={msgFileLink}
                                target="_blank"
                                sx={{
                                    ...MSG_SX,
                                    wordWrap: "break-word",
                                }}
                            >
                                {msgFileOriginalName}
                            </NameLink>
                        )}
                    </ListItem>
                );
            }
        });

        msgFilesHTML = <List disablePadding>{msgFilesItems}</List>;
    }

    return (
        <Div
            sx={{
                display: "flex",
                textAlign: "right",
                alignItems: "flex-start",
                flexDirection: "row-reverse",
                mb: 2,
                px: 3,
            }}
        >
            <div className="Message-root">
                <div className="Message-item">
                    <Typography
                        variant="body1"
                        fontSize="smaller"
                        mb={0.5}
                        sx={{
                            color: (theme) => theme.palette.secondary.main,
                        }}
                    >
                        {message?.[messageKeys.SENDER_NAME]}
                    </Typography>
                    <Stack direction="column" alignItems="flex-end">
                        {isMsgHTML ? (
                            <Div
                                dangerouslySetInnerHTML={{ __html: msgTxt }}
                                sx={{
                                    ...MSG_SX,
                                    p: (theme) => theme.spacing(0.5, 2),
                                    wordWrap: "break-word",
                                    "& *": {
                                        textAlign: "initial",
                                    },
                                }}
                            />
                        ) : (
                            <MultilineTypography
                                variant="body1"
                                sx={{
                                    ...MSG_SX,
                                    wordWrap: "break-word",
                                }}
                            >
                                {message?.[messageKeys.MESSAGE]}
                            </MultilineTypography>
                        )}
                        {msgFilesHTML}
                    </Stack>
                    <Typography
                        variant="body1"
                        fontSize="smaller"
                        mt={0.5}
                        sx={{
                            color: (theme) => theme.palette.secondary.main,
                        }}
                    >
                        {message?.[messageKeys.SENT_TIME]}
                    </Typography>
                </div>
            </div>
        </Div>
    );
};

export default SentMessageContent;
