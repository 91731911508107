import React from "react";
import FolderAssets from "../pages/accountAssets";
import Folders from "../pages/folders";
import PendingAssets from "../pages/accountAssets/PendingAssets";
import TrashedFolders from "../pages/trashedFolders";
import Page from "@jumbo/shared/Page";

const accountAssetsRoutes = [
    {
        path: "/accountAssets",
        element: <Page component={Folders} />,
    },
    {
        path: "/accountAssets/folder/:folderId/:companyId/",
        element: <Page component={FolderAssets} />,
    },
    {
        path: "/accountAssets/folder/:folderId/:companyId/:assetType/",
        element: <Page component={FolderAssets} />,
    },
    {
        path: "/accountAssets/pendingApprovals",
        element: <Page component={PendingAssets} />,
    },
    {
        path: "/accountAssets/pendingApprovals/:assetId/",
        element: <Page component={PendingAssets} />,
    },
    {
        path: "/accountAssets/trash",
        element: <Page component={TrashedFolders} />,
    },
];

export default accountAssetsRoutes;
