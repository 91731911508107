import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getAppUserTypeName, getInitialsFromName } from "app/utils/appHelpers";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "app/utils/constants/appData";

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { authUser, setAuthToken } = useJumboAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onSettings = () => {
        setAnchorEl(null);
        navigate("/settings");
    };

    const onEditProfile = () => {
        setAnchorEl(null);
        navigate("/editProfile");
    };

    const onLogout = () => {
        setAnchorEl(null);
        setAuthToken(null);
        navigate("/login");
    };

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;

    const authUserName =
        authUser &&
        authUser?.[AUTH_USER_KEYS.NAME] &&
        authUser[AUTH_USER_KEYS.NAME]
            ? authUser[AUTH_USER_KEYS.NAME]
            : "";

    const authUserInitials = getInitialsFromName(authUserName);

    const userTypeName =
        isAdminUser || isFmUser
            ? getAppUserTypeName(authUser?.[AUTH_USER_KEYS.TYPE] || "")
            : "";

    const fetchedPhoto = authUser?.[AUTH_USER_KEYS.PROFILE_PIC];
    let photoLink = "";
    if (fetchedPhoto) {
        photoLink = `${authUser[AUTH_USER_KEYS.PHOTO_BASE_URL]}/${
            fetchedPhoto[AUTH_USER_KEYS.PHOTO_FILE_PATH]
        }`;
    }

    return (
        <ThemeProvider theme={theme}>
            <span onClick={handleClick}>
                <Avatar
                    src={photoLink}
                    sizes={"small"}
                    alt={authUserName}
                    sx={{
                        bgcolor: "#112434",
                        boxShadow: 25,
                        cursor: "pointer",
                    }}
                >
                    {photoLink
                        ? "&nbsp"
                        : authUserInitials
                        ? authUserInitials
                        : "&nbsp;"}
                </Avatar>
            </span>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{
                    mt: 2,
                    mr: 1,
                }}
            >
                <Div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        p: (theme) => theme.spacing(2.5),
                    }}
                >
                    <Avatar
                        src={photoLink}
                        alt={authUserName}
                        sx={{
                            bgcolor: "#112434",
                            width: 60,
                            height: 60,
                            mb: 2,
                        }}
                    >
                        {photoLink
                            ? "&nbsp"
                            : authUserInitials
                            ? authUserInitials
                            : "&nbsp;"}
                    </Avatar>
                    <Typography variant={"h5"}>{authUserName}</Typography>
                    {userTypeName && (
                        <Typography variant={"body1"} color="text.primary">
                            {userTypeName}
                        </Typography>
                    )}
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {isAdminUser && (
                            <ListItemButton onClick={onSettings}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Settings"
                                    sx={{ my: 0 }}
                                />
                            </ListItemButton>
                        )}
                        <ListItemButton onClick={onEditProfile}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <EditOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Edit Profile"
                                sx={{ my: 0 }}
                            />
                        </ListItemButton>
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </Popover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
