import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
} from "../../utils/constants/actionTypes";

const INIT_STATE = {
    initialURL: "/",
    error: "",
    message: "",
    loading: false,
    showLoader: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_START: {
            return {
                ...state,
                error: "",
                message: "",
                loading: true,
                showLoader: action.showLoader,
            };
        }
        case FETCH_SUCCESS: {
            return {
                ...state,
                error: "",
                loading: false,
                showLoader: false,
                message: action.payload,
            };
        }
        case FETCH_ERROR: {
            return {
                ...state,
                loading: false,
                showLoader: false,
                message: "",
                error: action.payload,
            };
        }
        default:
            return state;
    }
};
