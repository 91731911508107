import React from "react";
import Meetings from "../pages/meetings";
import Page from "@jumbo/shared/Page";
import { MEETINGS_PAGE_LINK } from "../utils/constants/appData";

const meetingsRoutes = [
    {
        path: MEETINGS_PAGE_LINK,
        element: <Page component={Meetings} />,
    },
];

export default meetingsRoutes;
