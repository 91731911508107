export const APP_NAME = "Firm Media";

export const EMPTY_PARAM_VALUE = "empty_param_value";

export const LAYOUT_LIST_VIEW = "list";
export const LAYOUT_GRID_VIEW = "grid";

export const PAGINATION_PAGES = [5, 10, 20, 50, 100, 200];

export const REFRESH_ACTION_DELETE = "delete";
export const REFRESH_ACTION_INSERT = "insert";

export const SORT_ORDER_ASC = "asc";
export const SORT_ORDER_DESC = "desc";

export const VALID_IMG_EXTENSIONS = ["png", "jpeg", "jpg"];
export const VALID_IMG_MIME_TYPES = ["image/png", "image/jpeg"];

export const VALID_REPORT_EXTENSIONS = ["pdf"];
export const VALID_REPORT_MIME_TYPES = ["application/pdf"];

export const VALID_FILE_AUDIO_EXTENSIONS = ["mp3", "wav", "weba"];
export const VALID_FILE_AUDIO_MIME_TYPES = [
    "audio/mpeg",
    "audio/wav",
    "audio/webm",
];

export const VALID_FILE_DOC_EXTENSIONS = ["doc", "docx", "pdf"];
export const VALID_FILE_DOC_MIME_TYPES = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
];

export const VALID_FILE_GRAPHICS_EXTENSIONS = ["ai", "psd", "sketch", "xd"];
export const VALID_FILE_GRAPHICS_MIME_TYPES = [
    /* "application/pdf", */
    "application/postscript",
    "image/vnd.adobe.photoshop",
    "application/x-sketch",
    /* "application/zip", */
    "application/vnd.adobe.xd",
];

export const VALID_FILE_IMG_EXTENSIONS = ["gif", "jpeg", "jpg", "png", "svg"];
export const VALID_FILE_IMG_MIME_TYPES = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
];

export const VALID_FILE_SHEET_EXTENSIONS = ["csv", "xls", "xlsx"];
export const VALID_FILE_SHEET_MIME_TYPES = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const VALID_FILE_VIDEO_EXTENSIONS = ["mov", "mp4", "mpeg", "webm"];
export const VALID_FILE_VIDEO_MIME_TYPES = [
    "video/quicktime",
    "video/mp4",
    "video/mpeg",
    "video/webm",
];

export const VALID_CHAT_FILES_MAX_COUNT = 5;
export const VALID_CHAT_FILE_MAX_SIZE_MB = 12;

export const VALID_COMMON_FILE_MAX_SIZE_MB = 24;
export const VALID_COMMON_FILES_COUNT = 15;

export const VALID_SPECIAL_FILE_EXTENSIONS = ["png", "jpeg", "jpg", "pdf"];
export const VALID_SPECIAL_FILE_MIME_TYPES = [
    "image/png",
    "image/jpeg",
    "application/pdf",
];

export const VALID_TICKET_FILE_EXTENSIONS = [
    ...VALID_FILE_AUDIO_EXTENSIONS,
    ...VALID_FILE_DOC_EXTENSIONS,
    ...VALID_FILE_GRAPHICS_EXTENSIONS,
    ...VALID_FILE_IMG_EXTENSIONS,
    ...VALID_FILE_SHEET_EXTENSIONS,
    ...VALID_FILE_VIDEO_EXTENSIONS,
];
export const VALID_TICKET_FILE_MIME_TYPES = [
    ...VALID_FILE_AUDIO_MIME_TYPES,
    ...VALID_FILE_DOC_MIME_TYPES,
    ...VALID_FILE_GRAPHICS_MIME_TYPES,
    ...VALID_FILE_IMG_MIME_TYPES,
    ...VALID_FILE_SHEET_MIME_TYPES,
    ...VALID_FILE_VIDEO_MIME_TYPES,
];

export const VALID_WO_FILE_EXTENSIONS = [
    ...VALID_FILE_AUDIO_EXTENSIONS,
    ...VALID_FILE_DOC_EXTENSIONS,
    ...VALID_FILE_GRAPHICS_EXTENSIONS,
    ...VALID_FILE_IMG_EXTENSIONS,
    ...VALID_FILE_SHEET_EXTENSIONS,
    ...VALID_FILE_VIDEO_EXTENSIONS,
];
export const VALID_WO_FILE_MIME_TYPES = [
    ...VALID_FILE_AUDIO_MIME_TYPES,
    ...VALID_FILE_DOC_MIME_TYPES,
    ...VALID_FILE_GRAPHICS_MIME_TYPES,
    ...VALID_FILE_IMG_MIME_TYPES,
    ...VALID_FILE_SHEET_MIME_TYPES,
    ...VALID_FILE_VIDEO_MIME_TYPES,
];

/**
 * Sources:
 * https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-toolbar
 * https://stackoverflow.com/questions/13828383/what-toolbar-buttons-are-available-in-ckeditor-4
 * https://ckeditor.com/docs/ckeditor4/latest/features/toolbarconcepts.html#toolbar-groups-configuration
 * https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-toolbarGroups
 * https://ckeditor.com/docs/ckeditor4/latest/examples/react.html#/configuration
 */
export const CKEDITOR_TOOLBAR = [
    ["Cut", "Copy", "Paste", "PasteText"],
    ["Undo", "Redo"],
    ["Link", "Unlink"],
    /* "/", */
    ["Bold", "Italic"],
    ["RemoveFormat"],
    ["NumberedList", "BulletedList"],
    ["Format"],
    ["Source"],
];

/* Page Links */
export const CASE_STUDIES_PAGE_LINK = "/caseStudies";
export const MEETINGS_PAGE_LINK = "/meetings";
export const SERVICES_PAGE_LINK = "/services";
export const SPECIALS_PAGE_LINK = "/specials";
export const TICKETS_PAGE_LINK = "/supportTickets";

/* Auth User */
export const USER_TYPE_ADMIN_ID = "1";
export const USER_TYPE_AE_ID = "2";
export const USER_TYPE_CLIENT_ID = "3";

export const USER_TYPE_ADMIN = "Admin";
export const USER_TYPE_AE = "Account Executive";
export const USER_TYPE_CLIENT = "Client";

export const AUTH_USER_KEYS = {
    ID: "id",
    NAME: "name",
    EMAIL: "email",
    COMPANY: "company",
    COMPANY_ID: "company_id",
    INDUSTRY: "industry",
    ROLE: "role",
    TYPE: "user_type_id",
    PROFILE_PIC: "photo",
    PHOTO_FILE_PATH: "file_path",
    PHOTO_BASE_URL: "photo_baseURL",
    GLOBAL_COMPANY: "global_company",
    MENU: "menu",
};

/* Admin */
export const ADMIN_KEYS = {
    ID: "id",
    USER_ID: "user_id",
    NAME: "name",
    EMAIL: "email",
    COMPANY: "company",
    GLOBAL_COMPANY: "global_company",
    PHONE_NO: "phone",
    WEBSITE: "website",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "cpassword",
    PROFILE_PIC: "photo",
    PHOTO_FILE_PATH: "file_path",
    PHOTO_BASE_URL: "photo_baseURL",
};

/* FM User */
export const ACTIVE_FM_USER = "1";
export const INACTIVE_FM_USER = "0";

export const FM_USER_KEYS = {
    ID: "id",
    USER_ID: "user_id",
    NAME: "name",
    ROLE: "role",
    EMAIL: "email",
    PHONE_NO: "phone",
    BIO_DESCRIPTION: "bio_description",
    BIO_LINK: "bio_link",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "cpassword",
    TYPE: "user_type_id",
    STATUS: "status",
    PROFILE_PIC: "photo",
    PHOTO_FILE_PATH: "file_path",
    PHOTO_BASE_URL: "photo_baseURL",
    PHOTO_TEXT: "phototext",
    /* CLIENT_USERS: "assignedclient", */
    ASSIGNED_COMPANIES: "assigned_companies",
    TOTAL: "total",
    CREATED_AT: "created_at",
    TBL_COMPANY: "company",
};

/* Client User */
/* export const CLIENT_USER_INACTIVE_STATUS = "0";
export const CLIENT_USER_INACTIVE_STATUS_TXT = "Disabled"; */
export const CLIENT_USER_ACTIVE_STATUS = "1";
export const CLIENT_USER_ACTIVE_STATUS_TXT = "Active";
export const CLIENT_USER_ARCHIVE_STATUS = "2";
export const CLIENT_USER_ARCHIVE_STATUS_TXT = "Archived";

export const CLIENT_USER_KEYS = {
    ID: "id",
    USER_ID: "user_id",
    CLIENT_ID: "client_id",
    NAME: "name",
    EMAIL: "email",
    COMPANY: "company",
    COMPANY_ID: "company_id",
    INDUSTRY: "industry",
    PHONE_NO: "phone",
    WEBSITE: "website",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "cpassword",
    TYPE: "user_type_id",
    ROLE: "role",
    STATUS: "status",
    PROFILE_PIC: "photo",
    PHOTO_FILE_PATH: "file_path",
    PHOTO_BASE_URL: "photo_baseURL",
    SUBJECT: "subject",
    MESSAGE: "message",
    TOTAL: "total",
    CREATED_AT: "created_at",
    TBL_SUPPORT_TICKET: "support_ticket",
};

export const OFFICE_CONTACT_KEYS = {
    ID: "id",
    CONTACT_ID: "contact_id",
    OFFICE_CONTACT_ID: "office_contact_id",
    /* CLIENT_ID: "client_id", */
    COMPANY_ID: "company_id",
    CREATED_AT: "created_at",
    NAME: "name",
    EMAIL: "email",
    SUBJECT: "subject",
    MESSAGE: "message",
    PHONE_NO: "phone",
    POSITION: "position",
    PHOTO: "photo",
    PHOTO_URL: "photo_url",
    PHOTO_FILE_PATH: "file_path",
    PHOTO_BASE_URL: "photo_baseURL",
    STATUS: "status",
    TOTAL: "total",
};

/* Account Asset */
export const ACCOUNT_ASSET_APPROVED_STATUS_ID = "1";
export const ACCOUNT_ASSET_PENDING_STATUS_ID = "2";
export const ACCOUNT_ASSET_REJECTED_STATUS_ID = "3";

/* export const ACCOUNT_ASSET_TYPE_CASE_STUDY = "case_study"; */
export const ACCOUNT_ASSET_TYPE_DOCUMENT = "document";
export const ACCOUNT_ASSET_TYPE_LOGO = "logo";

export const ACCOUNT_ASSET_KEYS = {
    ID: "id",
    ASSET_ID: "asset_id",
    ACCOUNT_ASSET_ID: "account_asset_id",
    COMPANY: "company",
    COMPANY_ID: "company_id",
    COMPANY_NAME: "company_name",
    FOLDER: "folder",
    FOLDER_ID: "folder_id",
    FOLDER_NAME: "folder_name",
    USER_ID: "user_id",
    USER_NAME: "user_name",
    ORIGINAL_FILE_NAME: "original_file_name",
    DOCUMENT: "account_assets",
    DOCUMENT_NAME: "document_name",
    DOCUMENT_TYPE: "type",
    DOCUMENT_LINK: "url",
    EXTENSION: "file_extension",
    MIME_TYPE: "file_mime_type",
    STATUS: "status",
    REJECT_REASON: "comment",
    CREATED_AT: "created_at",
    TOTAL: "total",
};

/* Account Asset: Folder */
export const FOLDER_KEYS = {
    ID: "id",
    FOLDER_ID: "folder_id",
    /* CLIENT_ID: "client_id", */
    COMPANY_ID: "company_id",
    INDUSTRY: "industry",
    NAME: "name",
    FOLDER_NAME: "folder_name",
    ACCOUNT_ASSETS: "account_assets",
    ACCOUNT_ASSETS_TOTAL: "account_assets_total",
    TOTAL: "total",
    CREATED_BY: "created_by",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated_at",
};

/* Account Asset: Folder */
export const ASSET_CHAT_MESSAGES_KEYS = {
    ID: "id",
    MESSAGE_ID: "message_id",
    ACCOUNT_ASSET_ID: "asset_id",
    SENDER_ID: "sender_id",
    SENDER_NAME: "sender_name",
    RECEIVER_ID: "receiver_id",
    RECEIVER_NAME: "reciver_name",
    MESSAGE_TYPE: "message_type",
    MESSAGE: "message",
    MESSAGES: "messages",
    MESSAGE_FILES: "files",
    MESSAGE_FILES_ARRAY: "chat_files[]",
    DOCUMENT_LINK: "url",
    SENT_DATE: "send_date",
    SENT_TIME: "send_time",
    SENT_BY_ME: "sent_by_me",
};

/* Ticket */
export const TICKET_OPEN_STATUS = "1";
export const TICKET_CLOSE_STATUS = "2";
/* export const TICKET_PENDING_STATUS = "0"; */

export const TICKET_OPEN_STATUS_TXT = "open";
export const TICKET_CLOSE_STATUS_TXT = "closed";
/* export const TICKET_PENDING_STATUS_TXT = "pending"; */

export const TICKET_LOW_PRIORITY = "low";
export const TICKET_NORMAL_PRIORITY = "normal";
export const TICKET_URGENT_PRIORITY = "urgent";

export const TICKET_KEYS = {
    ID: "id",
    TICKET_ID: "ticket_id",
    TICKETID: "ticketid",
    COMPANY_ID: "company_id",
    COMPANY: "company",
    COMPANY_PHOTO: "companyphoto",
    COMPANY_INITIALS: "companyinitials",
    CLIENT_ID: "client_id",
    CLIENT_NAME: "client_name",
    FM_USERS: "assigned_fm",
    FM_USERS_IDS: "fm_ids",
    FM_USER_ID: "fmuser_id",
    /* FM_USER_NAME: "fmuser_name", */
    PRIORITY: "priority",
    STATUS: "status",
    STATUS_TXT: "status_txt",
    SUBJECT: "subject",
    DESCRIPTION: "description",
    DOCUMENTS: "ticket_attach[]",
    DOCUMENTS_LIST: "documents",
    BASE_URL: "document_baseURL",
    ORIGINAL_FILE_NAME: "original_file_name",
    FILE_PATH: "file_path",
    TOTAL: "total",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated_at",
};

export const TICKET_CHAT_MESSAGES_KEYS = {
    ID: "id",
    MESSAGE_ID: "message_id",
    TICKET_ID: "ticket_id",
    /* CLIENT_ID: "client_id", */
    /* USER_ID: "user_id", */
    SENDER_ID: "sender_id",
    SENDER_NAME: "sender_name",
    /* RECEIVER_ID: "receiver_id", */
    MESSAGE_TYPE: "message_type",
    MESSAGE: "message",
    MESSAGE_FILES: "files",
    MESSAGE_FILES_ARRAY: "chat_files[]",
    MESSAGES: "messages",
    SENT_DATE: "send_date",
    SENT_TIME: "send_time",
    SENT_BY_ME: "sent_by_me",
};

/* Meeting */
/* export const MEETING_CREATED_BY_ADMIN = "admin";
export const MEETING_CREATED_BY_CLIENT_USER = "client";
export const MEETING_CREATED_BY_FM_USER = "fmuser"; */

export const MEETING_TZ = "America/Los_Angeles";

export const MEETING_ALL_STATUS = "ALL";
export const MEETING_APPROVED_STATUS = "1";
export const MEETING_CANCELLED_STATUS = "2";
export const MEETING_PENDING_STATUS = "0";
export const MEETING_REJECTED_STATUS = "3";

export const MEETING_ALL_STATUS_TXT = "all";
export const MEETING_APPROVED_STATUS_TXT = "approved";
export const MEETING_CANCELLED_STATUS_TXT = "cancelled";
export const MEETING_PENDING_STATUS_TXT = "pending";
export const MEETING_REJECTED_STATUS_TXT = "rejected";

export const NEW_MEETING_TIME_EMPTY_STATUS = "";
export const NEW_MEETING_TIME_APPROVE_STATUS = "1";
export const NEW_MEETING_TIME_PENDING_STATUS = "0";
export const NEW_MEETING_TIME_REJECT_STATUS = "3";

export const NEW_MEETING_TIME_APPROVE_STATUS_TXT = "approved";
export const NEW_MEETING_TIME_PENDING_STATUS_TXT = "pending";
export const NEW_MEETING_TIME_REJECT_STATUS_TXT = "rejected";

export const TIME_REQUEST_RECEIVER_CLIENT = "client";
export const TIME_REQUEST_RECEIVER_FM = "FM";

export const MEETING_KEYS = {
    ID: "id",
    MEETING_ID: "meeting_id",
    CREATED_BY_ID: "createdbyID",
    CREATED_BY_NAME: "createdby",
    CREATED_BY_TYPE: "createdbyType",
    COMPANY_ID: "company_id",
    FM_USER_ID: "fmuser_id",
    FM_USER_NAME: "fmuser_name",
    CLIENT_ID: "client_id",
    CLIENT_NAME: "client_name",
    NAME: "title",
    DESCRIPTION: "description",
    MEETING_DATE: "meetdate",
    MEETING_TIME: "meettime",
    TIME_ZONE: "timezone",
    MEETING_LINK: "meeting_link",
    MEETING_DATE_TIME: "meeting_date_time",
    NEW_MEETING_DATE: "request_meetdate",
    NEW_MEETING_TIME: "request_meettime",
    NEW_MEETING_TIME_ZONE: "request_meettime_zone",
    CREATED_AT: "created_at",
    STATUS: "status",
    REQUEST_ID: "request_id",
    REQUEST_STATUS: "reschedule_status",
    REQUEST_RECEIVER_TYPE: "send_approval_to",
    TOTAL: "total",
};

/* Service */
export const SERVICE_PRICE_INTERVAL_MONTH = "month";
export const SERVICE_PRICE_INTERVAL_YEAR = "year";

export const SERVICE_KEYS = {
    ID: "id",
    SERVICE_ID: "service_id",
    NAME: "name",
    DESCRIPTION: "description",
    PRICE: "price",
    PRICE_INTERVAL: "intervals",
    CREATED_AT: "created_at",
    STATUS: "status",
    TOTAL: "total",
};

/* Service: Assigned Service */
export const ASSIGNED_SERVICE_APPROVED = "1";
export const ASSIGNED_SERVICE_DELETED = "3";
export const ASSIGNED_SERVICE_REJECTED = "2";
export const ASSIGNED_SERVICE_CANCELLED = "4";
export const ASSIGNED_SERVICE_COMPLETED = "5";

export const ASSIGNED_SERVICE_APPROVED_TXT = "ongoing";
export const ASSIGNED_SERVICE_DELETED_TXT = "deleted";
export const ASSIGNED_SERVICE_REJECTED_TXT = "rejected";
export const ASSIGNED_SERVICE_CANCELLED_TXT = "cancelled";
export const ASSIGNED_SERVICE_COMPLETED_TXT = "completed";

export const ASSIGNED_SERVICE_KEYS = {
    ID: "id",
    ASSIGNED_SERVICE_ID: "assign_service_id",
    SERVICE_ID: "service_id",
    COMPANY_ID: "company_id",
    COMPANY_NAME: "company",
    NAME: "name",
    DESCRIPTION: "description",
    PRICE: "price",
    PRICE_INTERVAL: "intervals",
    DELETED_DOCUMENTS: "delete_documents_ids",
    DOCUMENTS: "documents",
    DOCUMENTS_ATTACH: "document_attach[]",
    LAST_INVOICE_DATE: "last_invoice_date",
    RENEWAL_DATE: "renew_date",
    REQUESTED_BY: "requested_by",
    WORK_ORDER: "work_order",
    CREATED_AT: "created_at",
    STATUS: "status",
    TOTAL: "total",
};

/* Service: Client Service: Work Order Document */
export const WORK_ORDER_DOCUMENT_KEYS = {
    ID: "id",
    LINK: "link",
    NAME: "name",
    ORIGINAL_FILE_NAME: "original_name",
    SIZE: "size",
    SERVICE_ID: "service_id",
    SERVICE_REQUEST_ID: "service_request_id",
    TOTAL: "total",
    UPLOADED_BY: "uploaded_by",
};

/* Service: Client Service: Invoice */
export const INVOICE_STATUS_PAID = "paid";
export const INVOICE_STATUS_PENDING = "pending";

export const INVOICE_KEYS = {
    ID: "id",
    INVOICE_ID: "invoice_id",
    CLIENT_NAME: "client_name",
    BILL_DATE: "bill_date",
    STATUS: "status",
    TOTAL: "total",
};

/* Service: Client Service: Statement */
export const STATEMENT_KEYS = {
    ID: "id",
    STATEMENT_ID: "statement_id",
    CLIENT_ID: "client_id",
    CLIENT_NAME: "client_name",
    SERVICE_ID: "service_id",
    SERVICE_NAME: "service_name",
    BILLING_LOCATION: "billing_location",
    CREATED_AT: "created_at",
    PERIOD: "period",
    PERIOD_START: "period_start",
    PERIOD_END: "period_end",
    AMOUNT: "amount",
    TOTAL: "total",
};

/* Case Study */
export const CASE_STUDY_KEYS = {
    ID: "id",
    CASE_STUDY_ID: "casestudy_id",
    CLIENT_ID: "client_id",
    BEFORE: "before",
    BEFORE_FILE_PATH: "before_file_path",
    BEFORE_URL: "beforeurl",
    AFTER: "after",
    AFTER_FILE_PATH: "after_file_path",
    AFTER_URL: "afterurl",
    DESCRIPTION: "description",
    PROCEDURE: "procedure_text",
    CREATED_AT: "created_at",
    STATUS: "status",
    TOTAL: "total",
};

/* Alert */
export const ALERT_READ = "1";
export const ALERT_UNREAD = "0";

export const ALERT_KEYS = {
    ID: "id",
    ALERT_ID: "alert_id",
    USER_ID: "user_id",
    USER_NAME: "user_name",
    ENTITY_NAME: "entity_name",
    ENTITY_TYPE: "entity_type",
    TITLE: "title",
    MESSAGE: "alert_text",
    SENT_DATE: "created_at",
    SENT_TIME: "sent_time",
    READ: "read",
    STATUS: "status",
    TOTAL: "total",
};

/* Special */
export const SPECIAL_KEYS = {
    ID: "id",
    SPECIAL_ID: "special_id",
    COMPANY_ID: "company_id",
    COMPANY_NAME: "company_name",
    DESCRIPTION: "description",
    TITLE: "title",
    MONTH: "month",
    DELETED_DOCUMENTS: "deleted_documents",
    DOCUMENTS: "documents[]",
    UPLOADED_DOCUMENTS: "documents",
    DELETED_FLYERS: "deleted_flyers",
    FLYERS: "flyers[]",
    UPLOADED_FLYERS: "flyers",
    FLYER_ONE: "flyer_one",
    FLYER_ONE_URL: "flyer_one_url",
    FLYER_TWO: "flyer_two",
    FLYER_TWO_URL: "flyer_two_url",
    CREATED_AT: "created_at",
    STATUS: "status",
    TOTAL: "total",
};

export const SPECIAL_DOCUMENT_KEYS = {
    ID: "id",
    NAME: "name",
    LINK: "link",
    SIZE: "size",
};

/* Special: Flyer */
export const FLYER_PENDING_STATUS = "0";
export const FLYER_APPROVED_STATUS = "1";

export const FLYER_APPROVED_STATUS_TXT = "approved";
export const FLYER_PENDING_STATUS_TXT = "pending";

export const SPECIAL_FLYER_KEYS = {
    ID: "id",
    FLYER_ID: "flyer_id",
    USER_ID: "user_id",
    USER_NAME: "user_name",
    NAME: "name",
    LINK: "link",
    SIZE: "size",
    STATUS: "status",
    STATUS_TXT: "statustxt",
};

/* Special: Chat */
export const SPECIAL_CHAT_MESSAGES_KEYS = {
    ID: "id",
    MESSAGE_ID: "message_id",
    SPECIAL_ID: "special_id",
    CLIENT_ID: "client_id",
    SENDER_ID: "sender_id",
    SENDER_NAME: "sender_name",
    MESSAGE_TYPE: "message_type",
    MESSAGE: "message",
    MESSAGES: "messages",
    MESSAGE_FILES: "files",
    MESSAGE_FILES_ARRAY: "chat_files[]",
    SENT_DATE: "send_date",
    SENT_TIME: "send_time",
    SENT_BY_ME: "sent_by_me",
};

/* Message File */
export const MESSAGE_FILE_KEYS = {
    NAME: "name",
    ORIGINAL_NAME: "original_name",
    LINK: "link",
    MIME_TYPE: "mime_type",
};

/* Survey */
export const SURVEY_TYPE_NEW = "new";

export const SURVEY_STATUS_ACTIVE = "active";
export const SURVEY_STATUS_ARCHIVED = "archived";

export const SURVEY_ARCHIVED_NO = "no";
export const SURVEY_ARCHIVED_YES = "yes";

export const SURVEY_IS_NOT_ARCHIVED = "0";

export const SURVEY_IS_SUBMITTED = "1";
export const SURVEY_IS_NOT_SUBMITTED = "0";

export const SURVEY_KEYS = {
    ID: "id",
    SURVEY_ID: "survey_id",
    USER_ID: "user_id",
    ASSIGNED_COMPANIES: "companies_ids",
    NAME: "name",
    DESCRIPTION: "description",
    QUESTIONS: "questions",
    ANSWERS: "answers",
    CREATED_AT: "created_at",
    STATUS: "status",
    ARCHIVED: "archived",
    IS_ARCHIVED: "is_archived",
    IS_SUBMITTED: "is_submitted",
    TOTAL: "total",
};

/* Survey: Question */
export const SURVEY_QUESTION_TYPE_MULTI_SELECT = "multi_select";
export const SURVEY_QUESTION_TYPE_SINGLE_SELECT = "single_select";
export const SURVEY_QUESTION_TYPE_TEXT = "text";

export const SURVEY_QUESTION_KEYS = {
    ID: "id",
    QUESTION_ID: "question_id",
    SURVEY_ID: "survey_id",
    USER_ID: "user_id",
    OPTIONS: "options",
    TITLE: "title",
    TYPE: "type",
    TEXT_RESPONSE: "answer_text",
    FILL_ANSWER: "fill_answer",
    EMPTY_ANSWER: "empty_answer",
    CREATED_AT: "created_at",
    IS_DELETED: "is_deleted",
    STATUS: "status",
    TOTAL: "total",
};

export const SURVEY_QUESTION_OPTION_IS_ANSWER = 1;
export const SURVEY_QUESTION_OPTION_IS_NOT_ANSWER = 0;

export const SURVEY_QUESTION_IS_DELETED = "1";
export const SURVEY_QUESTION_IS_NOT_DELETED = "0";

export const SURVEY_QUESTION_OPTION_IS_DELETED = "1";
export const SURVEY_QUESTION_OPTION_IS_NOT_DELETED = "0";

/* Survey: Question: Option */
export const SURVEY_QUESTION_OPTION_KEYS = {
    ID: "id",
    OPTION_ID: "option_id",
    QUESTION_ID: "question_id",
    SURVEY_ID: "survey_id",
    USER_ID: "user_id",
    TITLE: "title",
    IS_ANSWER: "is_answer",
    IS_DELETED: "is_deleted",
    NUM_RESPONSES: "num_responses",
    CREATED_AT: "created_at",
    STATUS: "status",
    TOTAL: "total",
};

/* Survey: Answer */
export const SURVEY_ANSWER_KEYS = {
    QUESTION_ID: "question_id",
    ANSWER: "answer",
    ANSWER_REQUIRED: "answer_required",
};

/* Industry */
export const INDUSTRY_ALL = "All";
export const INDUSTRY_ENTERPRISE = "Enterprise";

export const INDUSTRY_KEYS = {
    ID: "id",
    NAME: "name",
    TOTAL: "total",
};

/* Asset Type */
export const ASSET_TYPE_KEYS = {
    ID: "id",
    NAME: "name",
    TOTAL: "total",
};

/* Announcement */
export const ANNOUNCEMENT_KEYS = {
    ID: "id",
    ANNOUNCEMENT_ID: "announcement_id",
    CREATED_AT: "created_at",
    TITLE: "title",
    DESCRIPTION: "description",
    BUTTON_TEXT: "button_text",
    BUTTON_LINK: "button_link",
    START_DATE: "start_date",
    END_DATE: "end_date",
    INDUSTRY: "type",
    STATUS: "status",
    TOTAL: "total",
};

/* Report */
export const REPORT_KEYS = {
    ID: "id",
    REPORT_ID: "report_id",
    COMPANY_ID: "company_id",
    NAME: "name",
    FILE: "report_file",
    FILE_DATA: "file_data",
    FILE_PATH: "file_path",
    FILE_BASE_URL: "file_baseURL",
    CREATED_AT: "created_at",
    TOTAL: "total",
};

/* Company */
export const COMPANY_ACTIVE_STATUS = "1";
export const COMPANY_ACTIVE_STATUS_TXT = "Active";
export const COMPANY_ARCHIVE_STATUS = "2";
export const COMPANY_ARCHIVE_STATUS_TXT = "Archived";

export const COMPANY_KEYS = {
    ID: "id",
    USER_ID: "user_id",
    COMPANY_ID: "company_id",
    NAME: "name",
    EMAIL: "email",
    INDUSTRY: "industry",
    PHONE_NO: "phone",
    PHONE_NO_EXT: "ext",
    WEBSITE: "website",
    STATUS: "status",
    LOGO_PIC: "photo",
    LOGO_FILE_PATH: "file_path",
    LOGO_BASE_URL: "photo_baseURL",
    SIDEBAR: "sidebar",
    ACTIVE: "active",
    RENEWAL_DATE: "renewal_date",
    ASSIGNED_AE: "assignedFM",
    SHEET_LINK: "leadsheet_link",
    TOTAL: "total",
    CREATED_AT: "created_at",
};

/* Note */
export const NOTE_KEYS = {
    ID: "id",
    NOTE_ID: "note_id",
    COMPANY_ID: "company_id",
    USER: "added_by",
    USER_ID: "id",
    USER_NAME: "name",
    USER_PHOTO: "photo",
    USER_INITIALS: "phototext",
    NOTE: "note",
    NOTES: "notes",
    TOTAL: "total",
    CREATED_AT: "created_at",
    UPDATED_AT: "updated_at",
};

/* Location */
export const LOCATION_KEYS = {
    ID: "id",
    LOCATION_ID: "location_id",
    COMPANY_ID: "company_id",
    NAME: "name",
    EMAIL: "email",
    ADDRESS_ONE: "address",
    CITY: "city",
    STATE: "state",
    ZIP_CODE: "zip",
    COUNTRY: "country",
    PHONE_NO: "phone",
    PHONE_NO_EXT: "ext",
    TOTAL: "total",
    CREATED_AT: "created_at",
};

/* Sidebar Item */
export const SIDEBAR_ITEM_ACTIVE = "1";
export const SIDEBAR_ITEM_NOT_ACTIVE = "0";

export const SIDEBAR_ITEM_URL_EXTERNAL = "1";
export const SIDEBAR_ITEM_URL_INTERNAL = "0";

export const SIDEBAR_ITEM_NEW_TAB_YES = "1";
export const SIDEBAR_ITEM_NEW_TAB_NO = "0";

export const SIDEBAR_ITEM_DEFAULT = "1";

export const SIDEBAR_ITEM_KEYS = {
    ID: "id",
    SIDEBAR_ITEM_ID: "menu_id",
    USER_TYPE_ID: "user_type_id",
    USER_ID: "user_id",
    COMPANY_ID: "company_id",
    CLIENT_ID: "client_id",
    LABEL: "label",
    ICON: "icon",
    URL: "url",
    IS_ACTIVE: "active",
    IS_URL_EXTERNAL: "external_url",
    IS_DEFAULT: "is_default",
    OPENS_IN_NEW_TAB: "newtab",
    ORDER: "order",
    POSITION: "position",
    TOTAL: "total",
    CREATED_AT: "created_at",
};

/* State */
export const STATE_KEYS = {
    ID: "id",
    CODE: "code",
    NAME: "name",
};

/* Country */
export const COUNTRY_KEYS = {
    ID: "id",
    CODE: "code",
    NAME: "name",
};

/* Time Zone */
export const TIME_ZONE_KEYS = {
    NAME: "name",
    ZONE_NAME: "zonename",
};

/* Email Settings */
export const EMAIL_SETTINGS_KEYS = {
    PROTOCOL: "protocol",
    SMTP_ENABLED: "smtp_active",
    SMTP_HOST: "smtp_host",
    SMTP_USER: "smtp_user",
    SMTP_PASS: "smtp_pass",
    SMTP_PORT: "smtp_port",
    SMTP_REPLY_TO: "smtp_replyto",
    SMTP_REPLY_TO_NAME: "smtp_replytoname",
    TEST_TO: "toemail",
    TEST_SUBJECT: "subject",
    TEST_BODY: "content",
};
