import React from "react";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/login/forgotPassword";
import ResetPassword from "../pages/auth/login/resetPassword";
import Page from "@jumbo/shared/Page";

const authRoutes = [
    {
        path: "/login",
        element: (
            <Page
                component={Login}
                layout={"full-page"}
                disableSmLogin={true}
            />
        ),
    },
    {
        path: "/forgotPassword",
        element: (
            <Page
                component={ForgotPassword}
                layout={"full-page"}
                disableSmLogin={true}
            />
        ),
    },
    {
        path: "/resetPassword/:code/",
        element: (
            <Page
                component={ResetPassword}
                layout={"full-page"}
                disableSmLogin={true}
            />
        ),
    },
];

export default authRoutes;
