import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    AUTH_USER_KEYS,
    SIDEBAR_ITEM_KEYS,
} from "../..//utils/constants/appData";

const topRightMenuLinks = ["/editProfile", "/settings"];

let firstTimePageLoad = true;

let storedBreadcrumb = localStorage.getItem("breadcrumb");

const storeBreadcrumb = (breadcrumb) =>
    localStorage.setItem("breadcrumb", JSON.stringify(breadcrumb));

const removeBreadcrumb = () => localStorage.removeItem("breadcrumb");

const dashboardBreadcrumb = {
    genericName: "Dashboard",
    specificName: "Dashboard",
    url: "/",
};
const initState = {
    breadcrumb: [dashboardBreadcrumb],
    breadcrumbSet: true,
    sidebar: [],
};
export const BreadcrumbContext = createContext(initState);

const breadcrumbReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "ADD_ITEM") {
            const item = action.item;
            if (!item) {
                return { ...state };
            }

            const currentBreadcrumb = state.breadcrumb;
            const currentSidebar = state.sidebar;

            const breadcrumbIndex = currentBreadcrumb
                .map((tempItem) => tempItem.url)
                .indexOf(item.url);
            if (breadcrumbIndex !== -1) {
                const updatedBreadcrumb = currentBreadcrumb.slice(
                    0,
                    breadcrumbIndex
                );
                updatedBreadcrumb.push(item);
                return { ...state, breadcrumb: updatedBreadcrumb };
            } else {
                const sidebarIndex = currentSidebar.indexOf(item.url);
                if (sidebarIndex !== -1) {
                    const updatedBreadcrumb = [];
                    updatedBreadcrumb.push(dashboardBreadcrumb);
                    updatedBreadcrumb.push(item);
                    return { ...state, breadcrumb: updatedBreadcrumb };
                } else {
                    const topRightMenuIdx = topRightMenuLinks.indexOf(item.url);
                    if (topRightMenuIdx !== -1) {
                        const updatedBreadcrumb = [];
                        updatedBreadcrumb.push(dashboardBreadcrumb);
                        updatedBreadcrumb.push(item);
                        return { ...state, breadcrumb: updatedBreadcrumb };
                    } else {
                        const updatedBreadcrumb = [...currentBreadcrumb];
                        updatedBreadcrumb.push(item);
                        return {
                            ...state,
                            breadcrumb: updatedBreadcrumb,
                        };
                    }
                }
            }
        } else if (action.type === "CLEAR_BREADCRUMB") {
            return {
                ...state,
                breadcrumb: [],
                breadcrumbSet: false,
                sidebar: [],
            };
        } else if (action.type === "INIT_BREADCRUMB") {
            return {
                ...state,
                breadcrumb: [...initState.breadcrumb],
                breadcrumbSet: true,
                sidebar: [],
            };
        } else if (action.type === "SET_BREADCRUMB") {
            const updatedBreadcrumb = action.breadcrumb;
            return { ...state, breadcrumb: [...updatedBreadcrumb] };
        } else if (action.type === "SET_SIDEBAR") {
            const updatedSidebar = action?.sidebar || [];
            return { ...state, sidebar: [...updatedSidebar] };
        }
    }
};

const BreadcrumbProvider = ({ children }) => {
    const { authUser, authToken } = useJumboAuth();
    const [breadcrumbOptions, setBreadcrumbOptions] = useReducer(
        breadcrumbReducer,
        initState
    );
    const { breadcrumb, breadcrumbSet } = breadcrumbOptions;

    const authUserMenu = useMemo(() => {
        if (authUser && authUser?.[AUTH_USER_KEYS.MENU]) {
            return authUser[AUTH_USER_KEYS.MENU].map(
                (item) => item[SIDEBAR_ITEM_KEYS.URL]
            );
        } else {
            return [];
        }
    }, [authUser]);

    useEffect(() => {
        if (authToken) {
            if (firstTimePageLoad) {
                if (storedBreadcrumb) {
                    setBreadcrumbOptions({
                        type: "SET_BREADCRUMB",
                        breadcrumb: JSON.parse(storedBreadcrumb),
                    });
                } else {
                    setBreadcrumbOptions({ type: "INIT_BREADCRUMB" });
                }
                firstTimePageLoad = false;
            } else {
                if (!storedBreadcrumb) {
                    setBreadcrumbOptions({ type: "INIT_BREADCRUMB" });
                }
            }
        } else {
            setBreadcrumbOptions({ type: "CLEAR_BREADCRUMB" });
            removeBreadcrumb();
            storedBreadcrumb = null;
        }
    }, [authToken]);

    useEffect(() => {
        if (breadcrumb && breadcrumb.length > 0) {
            storeBreadcrumb(breadcrumb);
        }
    }, [breadcrumb]);

    useEffect(() => {
        if (breadcrumbSet) {
            if (authUserMenu.length > 0) {
                setBreadcrumbOptions({
                    type: "SET_SIDEBAR",
                    sidebar: authUserMenu,
                });
            }
        }
    }, [breadcrumbSet, authUserMenu]);

    const addItem = useCallback((location) => {
        setBreadcrumbOptions({
            type: "ADD_ITEM",
            item: location,
        });
    }, []);

    const contextValue = useMemo(() => {
        return {
            ...breadcrumbOptions,
            addItem,
        };
    }, [breadcrumbOptions, addItem]);

    return (
        <BreadcrumbContext.Provider value={contextValue}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export const useBreadcrumb = () => useContext(BreadcrumbContext);

export default BreadcrumbProvider;
