import React from "react";
import Surveys from "../pages/surveys";
import Page from "@jumbo/shared/Page";

const surveysRoutes = [
    {
        path: "/surveys",
        element: <Page component={Surveys} />,
    },
];

export default surveysRoutes;
