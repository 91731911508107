import basicAuthAxios from "../../services/auth/basicAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    /* GET_ALERT_COUNT,
    GET_ALERTS, */
    SET_ALERT_DETAILS,
} from "../../utils/constants/actionTypes";
import {
    GET_ALERTS_LINK,
    MARK_ALERT_DONE_LINK,
    MARK_ALL_ALERTS_DONE_LINK,
} from "../../utils/constants/apiLinks";
import { ALERT_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const getFilteredAlerts = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.alertData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_ALERTS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.alerts) {
                            const alerts = data.alerts;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [ALERT_KEYS.TOTAL]: count[ALERT_KEYS.TOTAL]
                                        ? count[ALERT_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [ALERT_KEYS.TOTAL]: "0",
                                };
                            }

                            dispatch(fetchSuccess());
                            /* dispatch({
                                type: GET_ALERTS,
                                payload: alerts,
                            });
                            dispatch({
                                type: GET_ALERT_COUNT,
                                payload: totals,
                            }); */
                            if (callbackFun)
                                callbackFun({ alerts: alerts, totals: totals });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                /* dispatch({ type: GET_ALERTS, payload: [] });
                dispatch({
                    type: GET_ALERT_COUNT,
                    payload: {
                        [ALERT_KEYS.TOTAL]: "0",
                    },
                }); */

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const setCurrentAlert = (alert) => {
    return (dispatch) => {
        dispatch({ type: SET_ALERT_DETAILS, payload: alert });
    };
};

export const markAlertRead = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.alertData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(MARK_ALERT_DONE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* const data = responseData.data;
                        if (data.alert) { */
                        dispatch(
                            fetchSuccess(
                                "Selected alert was updated successfully."
                            )
                        );
                        if (callbackFun) callbackFun(/* data.alert */);
                        /* } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        } */
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const markAllAlertsRead = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.alertData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(MARK_ALL_ALERTS_DONE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        dispatch(
                            fetchSuccess("Alerts were updated successfully.")
                        );
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};
