import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import SVGLogo from "../../../../shared/SVGLogo";
import AuthUserHeader from "../../../../shared/widgets/AuthUserHeader";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import HoveredLink from "../../../../shared/widgets/HoveredLink";
/* import NotificationsDropdown from "../../../../shared/NotificationsDropdown"; */
import IconButtonOptions from "../../../../shared/JumboCustomizer/components/IconButtonOptions";
import { IconButton, Stack, alpha, useMediaQuery } from "@mui/material";
/* import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined"; */
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import {
    AUTH_USER_KEYS,
    TICKETS_PAGE_LINK,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "app/utils/constants/appData";

const headerMenuIconComponents = {
    home: HomeIcon,
    menu: MenuIcon,
    menuOpen: MenuOpenIcon,
    mailOutline: MailOutlineIcon,
    person: PersonIcon,
};

const FirmMediaHeader = () => {
    const { authUser } = useJumboAuth();
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
    const { headerTheme } = useJumboHeaderTheme();

    const isResponsiveMode = useMediaQuery("(max-width:1200px)");

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;

    let headerMenuItems = [];
    if (isAdminUser || isFmUser) {
        headerMenuItems = [
            {
                link: "/companies",
                label: "My Clients",
                icon: "home",
            },
            {
                link: TICKETS_PAGE_LINK,
                label: "My Tickets",
                icon: "mailOutline",
            },
            {
                link: "/editProfile",
                label: "My Profile",
                icon: "person",
            },
        ];
    } else if (isClientUser) {
        headerMenuItems = [
            {
                link: "/",
                label: "My Dashboard",
                icon: "home",
            },
            {
                link: TICKETS_PAGE_LINK,
                label: "My Tickets",
                icon: "mailOutline",
            },
            {
                link: "/editProfile",
                label: "My Profile",
                icon: "person",
            },
        ];
    }

    return (
        <React.Fragment>
            {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
                sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
                (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
                    !sidebarOptions.open)) && (
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{
                        ml:
                            sidebarOptions.style ===
                            SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                                ? -2
                                : 0,
                        mr: 3,
                    }}
                    onClick={() =>
                        setSidebarOptions({ open: !sidebarOptions.open })
                    }
                >
                    {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
            )}
            {!isResponsiveMode && (
                <SVGLogo
                    sx={{ mr: 3 }}
                    svgSx={{
                        height: "auto",
                        fontSize: "12rem",
                    }}
                    mode={headerTheme.type ?? "light"}
                />
            )}
            {headerMenuItems.length > 0 && (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={isResponsiveMode ? 0.5 : 3}
                    sx={{
                        ml: isResponsiveMode
                            ? "inherit"
                            : (theme) => theme.spacing(5),
                        mr: "auto",
                    }}
                >
                    {headerMenuItems.map((headerMenuItem, headerMenuIdx) => {
                        const HeaderMenuIconComponent =
                            headerMenuIconComponents[headerMenuItem.icon];

                        return isResponsiveMode ? (
                            <IconButtonOptions
                                key={`header-menu-item-${headerMenuIdx}`}
                                component={RouterLink}
                                to={headerMenuItem.link}
                            >
                                <HeaderMenuIconComponent
                                    sx={(theme) => {
                                        const btnMainColor =
                                            theme.palette?.miscellaneous?.button
                                                ?.primary || "#112434";
                                        return {
                                            fontSize: "1.25rem",
                                            color: btnMainColor,
                                            "&:hover": {
                                                borderRadius: "50%",
                                                backgroundColor: alpha(
                                                    btnMainColor,
                                                    0.04
                                                ),
                                            },
                                        };
                                    }}
                                />
                            </IconButtonOptions>
                        ) : (
                            <HoveredLink
                                key={`header-menu-item-${headerMenuIdx}`}
                                component={RouterLink}
                                to={headerMenuItem.link}
                                underline="none"
                            >
                                {headerMenuItem.label}
                            </HoveredLink>
                        );
                    })}
                    {/* <NotificationsDropdown /> */}
                </Stack>
            )}
            <Stack
                direction="row"
                alignItems="center"
                spacing={isResponsiveMode ? 0.5 : 3}
                sx={{ ml: "auto" }}
            >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <AuthUserDropdown />
                    {!isResponsiveMode && <AuthUserHeader />}
                </Stack>
            </Stack>
        </React.Fragment>
    );
};

export default FirmMediaHeader;
