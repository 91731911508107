// Auth
export const CHECK_PWD_RESET_CODE_LINK = "/checkresetpasswordcode";

// Admin
export const GET_ADMIN_PROFILE_LINK = "/getadminprofile";
export const UPDATE_ADMIN_PROFILE_LINK = "/updateadminprofile";

// FM User
export const ADD_FM_USER_LINK = "/user/create";
export const DELETE_FM_USER_LINK = "/user/delete";
/* export const GET_ALL_FM_USERS_LINK = "/user/unfmlist"; */
export const GET_FM_USER_LINK = "/user/fetch";
export const GET_FM_USER_PROFILE_LINK = "/fmclient/profile";
export const GET_FM_USERS_LINK = "/user/list";
export const GET_FM_USERS_DASHBOARD_LINK = "/user/dashbaord";
export const UPDATE_FM_USER_LINK = "/user/update";
export const UPDATE_FM_USER_PROFILE_LINK = "/fmclient/profileupdate";
export const UPDATE_FM_USER_STATUS_LINK = "/user/updatestatus";

// Client
export const GET_CLIENT_USER_SHEET_LINK = "/client/sheetLink";
export const GET_CLIENT_USER_PROFILE_LINK = "/client/profile";
export const UPDATE_CLIENT_USER_PROFILE_LINK = "/client/profileupdate";

// Admin - Client
export const ADD_CLIENT_USER_LINK = "/client/create";
export const ARCHIVE_CLIENT_USER_LINK = "/client/archive";
export const DELETE_CLIENT_USER_LINK = "/client/delete";
export const GET_CLIENT_USER_LINK = "/client/fetch";
export const GET_CLIENT_USERS_LINK = "/client/list";
/* export const GET_ALL_CLIENT_USERS_LINK = "/client/clientlist"; */
/* export const GET_ALL_FM_CLIENT_USERS_LINK = "/user/fmclients"; */
export const RESTORE_CLIENT_USER_LINK = "/client/restore";
export const SEND_MSG_CLIENT_USER_LINK = "/fmclient/SendMessage";
export const UPDATE_CLIENT_USER_LINK = "/client/update";
export const UPDATE_CLIENT_USER_STATUS_LINK = "/client/updatestatus";

// Fm User - Client
export const FM_ADD_CLIENT_USER_LINK = "/fmclient/create";
export const FM_ARCHIVE_CLIENT_USER_LINK = "/client/archive";
export const FM_DELETE_CLIENT_USER_LINK = "/fmclient/delete";
export const FM_GET_CLIENT_USER_LINK = "/fmclient/fetch";
export const FM_GET_CLIENT_USERS_LINK = "/fmclient/list";
/* export const FM_GET_ALL_CLIENT_USERS_LINK = "/fmclient/clientlist"; */
/* export const FM_GET_ALL_CLIENT_USERS_LINK_2 = "/fmclient/listwithoutfilter"; */
export const FM_RESTORE_CLIENT_USER_LINK = "/client/restore";
export const FM_SEND_MSG_CLIENT_USER_LINK = "/fmclient/SendMessage";
export const FM_UPDATE_CLIENT_USER_LINK = "/fmclient/update";
export const FM_UPDATE_CLIENT_USER_STATUS_LINK = "/fmclient/updatestatus";

// Client - Fm User
/* export const GET_ALL_CLIENT_FM_USERS_LINK = "/client/assolistnof"; */
export const GET_CLIENT_FM_USERS_LINK = "/client/assolist";

// Office Contact
export const ADD_OFFICE_CONTACT_LINK = "/officeContacts/create";
export const DELETE_OFFICE_CONTACT_LINK = "/officeContacts/delete";
export const GET_OFFICE_CONTACTS_LINK = "/officeContacts/list";
export const SEND_OFFICE_CONTACT_MSG_LINK = "/officeContacts/SendMessage";
export const UPDATE_OFFICE_CONTACT_LINK = "/officeContacts/update";

// Account Assets
export const ADD_ACCOUNT_ASSET_LINK = "/accountAsset/upload";
export const DELETE_ACCOUNT_ASSET_LINK = "/accountAsset/delete";
export const GET_ACCOUNT_ASSET_CHAT_LINK = "/accountAsset/GetSendMessages";
export const GET_FOLDER_ACCOUNT_ASSETS_LINK = "/accountAsset/listFolderAsset";
export const GET_PENDING_ASSETS_LINK = "/accountAsset/listpApprovalsnew";
export const RESEND_ACCOUNT_ASSET_APPROVAL_LINK = "/accountAsset/resend";
export const SEND_ACCOUNT_ASSET_MESSAGE_LINK = "/accountAsset/SendMessage";
export const UPDATE_ACCOUNT_ASSET_LINK = "/accountAsset/update";
export const UPDATE_ACCOUNT_ASSET_STATUS_LINK =
    "/accountAsset/updaterequeststatus";

// Folder
export const ADD_FOLDER_LINK = "/accountAsset/createFolder";
export const DELETE_FOLDER_LINK = "/accountAsset/deleteFolder";
export const DOWNLOAD_FOLDER_LINK = "/accountAsset/downloadFolder";
export const GET_ALL_FOLDERS_LINK = "/accountAsset/listAllFolder";
export const GET_FOLDERS_LINK = "/accountAsset/listFolder";
export const GET_TRASHED_FOLDERS_LINK = "/accountAsset/listTrashFolder";
export const RESTORE_FOLDER_LINK = "/accountAsset/restoreFolder";
export const TRASH_FOLDER_LINK = "/accountAsset/trashFolder";
export const UPDATE_FOLDER_LINK = "/accountAsset/updateFolder";

// Tickets
export const ADD_TICKET_LINK = "/ticket/create";
/* export const DELETE_TICKET_LINK = "/ticket/delete"; */
export const GET_TICKET_CHAT_LINK = "/ticket/GetSendMessages";
export const GET_TICKET_LINK = "/ticket/fetch";
export const GET_TICKETS_LINK = "/ticket/list";
export const SEND_TICKET_MESSAGE_LINK = "/ticket/SendMessage";
export const TICKET_ASSIGN_FM_USERS_LINK = "/ticket/assignFM";
export const UPDATE_TICKET_LINK = "/ticket/update";
export const UPDATE_TICKET_PRIORITY_LINK = "/ticket/changepriority";
export const UPDATE_TICKET_STATUS_LINK = "/ticket/updatestatus";

// Meetings
export const ADD_MEETING_LINK = "/meeting/create";
export const CANCEL_MEETING_LINK = "/meeting/cancel";
/* export const DELETE_MEETING_LINK = "/meeting/delete"; */
export const GET_MEETING_LINK = "/meeting/get";
export const GET_MEETINGS_LINK = "/meeting/list";
export const REQUEST_NEW_MEETING_TIME = "/meeting/sendrequest";
export const UPDATE_MEETING_LINK = "/meeting/update";
export const UPDATE_MEETING_STATUS_LINK = "/meeting/updatestatus";
export const UPDATE_TIME_REQUEST_STATUS_LINK = "/meeting/updaterequeststatus";

// Services
export const ADD_SERVICE_LINK = "/service/create";
export const CANCEL_SERVICE_LINK = "/service/cancel";
export const DELETE_SERVICE_LINK = "/service/delete";
export const GET_ALL_SERVICES_LINK = "/service/listall";
export const GET_SERVICE_LINK = "/service/fetch";
export const GET_SERVICES_LINK = "/service/list";
export const REQUEST_SERVICE_LINK = "/service/requestservice";
export const UPDATE_SERVICE_LINK = "/service/update";

// Assigned Services
export const ASSIGN_SERVICE_LINK = "/service/assignservice";
export const DELETE_ASSIGNED_SERVICE_LINK = "/service/deleteAssignService";
export const DELETE_WORK_ORDER_DOCUMENTS_LINK = "/service/deleteASWODocuments";
export const GET_ASSIGNED_SERVICES_LINK = "/service/listassignsedervice";
export const UPDATE_ASSIGNED_SERVICE_LINK = "/service/updateAssignedService";
export const UPDATE_SERVICE_RENEWAL_DATE_LINK =
    "/service/assignRenewDateUpdate";
export const UPLOAD_WORK_ORDER_DOCUMENT_LINK = "/service/uploadASWODocument";

// Invoices
export const GET_INVOICES_LINK = "/invoices/list";

// Case Study
export const ADD_CASE_STUDY_LINK = "/caseStudy/create";
export const DELETE_CASE_STUDY_LINK = "/caseStudy/delete";
export const GET_CASE_STUDIES_LINK = "/caseStudy/list";
export const GET_CASE_STUDY_LINK = "/caseStudy/fetch";
export const UPDATE_CASE_STUDY_LINK = "/caseStudy/update";

// Alerts
export const GET_ALERTS_LINK = "/alerts/list";
export const MARK_ALERT_DONE_LINK = "/alerts/update";
export const MARK_ALL_ALERTS_DONE_LINK = "/alerts/allread";

// Statement
export const GET_STATEMENTS_LINK = "/statements/list";

// Specials
export const ADD_SPECIAL_LINK = "/specials/create";
export const APPROVE_SPECIAL_FLYER_LINK = "/specials/flyersrequeststatus";
export const DELETE_SPECIAL_LINK = "/specials/delete";
export const GET_SPECIAL_LINK = "/specials/fetch";
export const GET_SPECIAL_CHAT_LINK = "/specials/GetSendMessages";
export const GET_SPECIALS_LINK = "/specials/list";
export const GET_ALL_SPECIALS_LINK = "/special/all";
export const SAVE_SPECIAL_FLYER_LINK = "/specials/uploadflyers";
export const SEND_SPECIAL_MESSAGE_LINK = "/specials/SendMessage";
export const UPDATE_SPECIAL_LINK = "/specials/update";

// Surveys
export const ADD_SURVEY_LINK = "/survey/create";
export const ARCHIVE_SURVEY_LINK = "/survey/archive";
export const DELETE_SURVEY_LINK = "/survey/delete";
export const GET_CLIENT_SURVEY_LINK = "/survey/singlefetch";
export const GET_SURVEY_LINK = "/survey/fetch";
export const GET_CLIENT_SURVEYS_LINK = "/survey/clientsurveylist";
export const GET_SURVEYS_LINK = "/survey/list";
export const GET_SURVEY_RESULT_LINK = "/survey/result";
export const GET_TEXT_RESPONSES_LINK = "/survey/questionresult";
export const SEND_SURVEY_LINK = "/survey/send";
export const SUBMIT_SURVEY_LINK = "/survey/submitsurvey";
export const UPDATE_SURVEY_LINK = "/survey/update";

// Industry
export const GET_ALL_INDUSTRIES_LINK = "/info/listIndustry";

// Asset Type
export const GET_ALL_ASSET_TYPES_LINK = "/info/listAssetsType";

// Announcement
export const ADD_ANNOUNCEMENT_LINK = "/annoucement/create";
export const DELETE_ANNOUNCEMENT_LINK = "/annoucement/delete";
export const GET_ANNOUNCEMENTS_LINK = "/annoucement/list";
export const HIDE_ANNOUNCEMENT_LINK = "/annoucement/hideAnnoucement";
export const SEND_ANNOUNCEMENT_MSG_LINK = "/annoucement/SendMessage";
export const UPDATE_ANNOUNCEMENT_LINK = "/annoucement/update";

// Report
export const ADD_REPORT_LINK = "/reports/create";
export const DELETE_REPORT_LINK = "/reports/delete";
export const GET_REPORT_LINK = "/reports/fetch";
export const GET_REPORTS_LINK = "/reports/list";
export const UPDATE_REPORT_LINK = "/reports/update";

// Company
export const ADD_COMPANY_LINK = "/company/create";
export const ADD_COMPANY_LOCATION_LINK = "/company/addofficelocation";
export const ARCHIVE_COMPANY_LINK = "/company/archive";
export const DELETE_COMPANY_LINK = "/company/delete";
export const DELETE_COMPANY_LOCATION_LINK = "/company/deleteofficelocation";
export const GET_ALL_COMPANIES_LINK = "/company/listall";
export const GET_COMPANIES_LINK = "/company/list";
export const GET_COMPANY_LINK = "/company/fetch";
export const GET_COMPANY_ALL_CLIENTS_LINK = "/company/listAllClients";
export const GET_COMPANY_ALL_FM_USERS_LINK = "/company/listAllFMs";
export const GET_COMPANY_LOCATIONS_LINK = "/company/listofficelocation";
export const GET_COMPANY_NOTES_LINK = "/company/fetchnotes";
export const GET_COMPANY_SIDEBAR_LINK = "/getcompanysidebarmenu";
export const GET_TEST_COMPANIES_LINK = "/company/listtest";
export const RESTORE_COMPANY_LINK = "/company/restore";
export const UPDATE_COMPANY_LINK = "/company/update";
export const UPDATE_COMPANY_LOCATION_LINK = "/company/editofficelocation";
export const UPDATE_COMPANY_RENEWAL_DATE_LINK = "/company/updaterenewaldate";
export const UPDATE_COMPANY_SIDEBAR_LINK = "/customizesidebarmenu";

// Company Note
export const ADD_COMPANY_NOTE_LINK = "/company/addnotes";
export const DELETE_COMPANY_NOTE_LINK = "/company/deletenotes";
export const UPDATE_COMPANY_NOTE_LINK = "/company/editnotes";

// Sidebar Settings
export const ADD_SIDEBAR_SETTING_ITEM_LINK = "/addmenuitem";
export const DELETE_SIDEBAR_SETTING_ITEM_LINK = "/deletemenuitem";
export const GET_SIDEBAR_SETTINGS_LINK = "/getsidebarmenu";
export const UPDATE_SIDEBAR_SETTING_ITEM_LINK = "/editmenuitem";
export const UPDATE_SIDEBAR_SETTING_ITEMS_ORDER_LINK = "/reordersidebarmenu";

// Countries
export const GET_COUNTRIES_LINK = "/getcountries";

// States
export const GET_STATES_LINK = "/getstates";

// Time zones
export const GET_TIME_ZONES_LINK = "/meeting/getTimeZones";

// Email Settings
export const GET_EMAIL_SETTINGS_LINK = "/getsmtpsettings";
export const SAVE_EMAIL_SETTINGS_LINK = "/updatesmtpsettings";
export const TEST_EMAIL_SETTINGS_LINK = "/testsmtpemail";
