import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { PAGINATION_PAGES, REPORT_KEYS } from "../../utils/constants/appData";

const allHeadCells = [
    {
        id: REPORT_KEYS.NAME,
        label: "Report",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: REPORT_KEYS.CREATED_AT,
        label: "Uploaded On",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
];

const CompanyReportsTable = ({
    canAddEditReport,
    canDeleteReport,
    reports,
    reportCounts,
    reportsFetched,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    onEdit,
    onDelete,
}) => {
    let colspan = allHeadCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const reportCountNonZero =
        reports &&
        reports.length > 0 &&
        reportCounts &&
        reportCounts[REPORT_KEYS.TOTAL] &&
        !isNaN(parseInt(reportCounts[REPORT_KEYS.TOTAL])) &&
        parseInt(reportCounts[REPORT_KEYS.TOTAL]) > 0;

    const showActions = canAddEditReport || canDeleteReport;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Reports Table">
                    <TableHead>
                        <TableRow>
                            {allHeadCells.map((headCell) => {
                                const sx = {};
                                if (headCell?.width) sx.width = headCell.width;

                                return headCell.show ? (
                                    <TableCell key={headCell.id} sx={{ ...sx }}>
                                        {headCell.label}
                                    </TableCell>
                                ) : null;
                            })}
                            {showActions && (
                                <TableCell
                                    key="actions"
                                    sx={{ width: "15%", textAlign: "center" }}
                                >
                                    Actions
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {reportCountNonZero ? (
                            reports.map((report) => {
                                const reportId = report[REPORT_KEYS.ID];
                                return (
                                    <TableRow
                                        key={reportId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Link
                                                noWrap
                                                color="text.primary"
                                                component={RouterLink}
                                                to={`/report/${reportId}/`}
                                            >
                                                {report[REPORT_KEYS.NAME]}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {report[REPORT_KEYS.CREATED_AT]}
                                        </TableCell>
                                        {showActions && (
                                            <TableCell>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    {canAddEditReport && (
                                                        <TableActionIcon
                                                            iconComponent="edit"
                                                            label="edit report"
                                                            tooltip="Edit Report"
                                                            onClick={() =>
                                                                onEdit(report)
                                                            }
                                                        />
                                                    )}
                                                    {canDeleteReport && (
                                                        <TableActionIcon
                                                            iconComponent="delete"
                                                            label="delete report"
                                                            tooltip="Delete Report"
                                                            onClick={() =>
                                                                onDelete(report)
                                                            }
                                                        />
                                                    )}
                                                </Stack>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            reportsFetched ? "error" : "info"
                                        }
                                    >
                                        {reportsFetched
                                            ? "There are no records found."
                                            : "Loading reports..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    reportCountNonZero
                        ? parseInt(reportCounts[REPORT_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={reportCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

CompanyReportsTable.propTypes = {
    canAddEditReport: PropTypes.bool,
    canDeleteReport: PropTypes.bool,
    reports: PropTypes.array,
    reportCounts: PropTypes.object,
    reportsFetched: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

CompanyReportsTable.defaultProps = {
    canAddEditReport: false,
    canDeleteReport: false,
    reports: [],
    reportCounts: null,
    reportsFetched: false,
    page: 0,
    rowsPerPage: 0,
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onEdit: () => {},
    // onDelete: () => {},
};

export default CompanyReportsTable;
