import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getReportLocalState } from "../../redux/actions/reports";
import {
    AUTH_USER_KEYS,
    APP_NAME,
    REPORT_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";

const Report = () => {
    document.title = `${APP_NAME} - Report`;

    const _isMountedReport = useRef(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();

    const reportId = params?.reportId || "";

    const [reportIdValid, setReportIdValid] = useState(false);
    const [reportFetching, setReportFetching] = useState(false);
    const [reportFetched, setReportFetched] = useState(false);
    const [reportIsValid, setReportIsValid] = useState(false);
    const [report, setReport] = useState(null);
    const [reportLink, setReportLink] = useState("");

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedReport.current = false;
        };
    }, []);

    useEffect(() => {
        if (reportId) {
            setReportIdValid(true);
        }
    }, [reportId]);

    useEffect(() => {
        if (isValidUserType && reportIdValid) {
            setReportFetching(true);
        }
    }, [isValidUserType, reportIdValid]);

    useEffect(() => {
        let isActive = true;

        if (reportFetching && !reportFetched) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getReportLocalState(payload, (fetchedReport) => {
                            if (isActive) {
                                setReportFetching(false);
                                setReportFetched(true);
                                setReport(fetchedReport || null);
                            }
                        })
                    );
                };
            };

            const reportData = {
                authcode: authToken,
                [REPORT_KEYS.REPORT_ID]: reportId,
            };

            const promise = dispatch(
                fetchData({
                    reportData: reportData,
                })
            );
            promise.catch((error) => {
                if (isActive) {
                    setReportFetching(false);
                    setReportFetched(false);
                    setReport(null);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        authUserType,
        reportFetching,
        reportFetched,
        reportId,
    ]);

    useEffect(() => {
        if (!reportFetching && reportFetched) {
            if (report && report?.[REPORT_KEYS.ID] && report[REPORT_KEYS.ID]) {
                setReportIsValid(true);
            }
        }
    }, [report, reportFetching, reportFetched]);

    useEffect(() => {
        if (reportIsValid) {
            const fetchedFile = report[REPORT_KEYS.FILE_DATA];
            if (fetchedFile) {
                setReportLink(
                    `${report[REPORT_KEYS.FILE_BASE_URL]}/${
                        fetchedFile[REPORT_KEYS.FILE_PATH]
                    }`
                );
            }
        } else {
            setReportLink("");
        }
    }, [report, reportIsValid]);

    return (
        <Grid container spacing={3.5} sx={{ height: "100%" }}>
            {!reportFetching && !isValidUserType && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        {AUTHORIZATION_ERROR}
                    </Alert>
                </Grid>
            )}
            {!reportFetching && !reportFetched && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        Unable to fetch the report!
                    </Alert>
                </Grid>
            )}
            {!reportFetching && reportFetched && !reportIsValid && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        Invalid report!
                    </Alert>
                </Grid>
            )}
            {!reportFetching && reportFetched && reportIsValid && (
                <Grid item xs={12}>
                    <object
                        data={reportLink}
                        type="application/pdf"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Typography component="p">
                            Unable to load the report. Please click on{" "}
                            <Link
                                noWrap
                                target="_blank"
                                color="text.primary"
                                href={reportLink}
                            >
                                this link
                            </Link>{" "}
                            to view the PDF.
                        </Typography>
                    </object>
                </Grid>
            )}
        </Grid>
    );
};

export default Report;
