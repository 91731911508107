import basicAuthAxios from "../../services/auth/basicAuth";
import basicFileAuthAxios from "../../services/auth/basicFileAuth";

import { fetchError, fetchStart, fetchSuccess } from "./common";

import {
    ADD_TICKET,
    /* DELETE_TICKET, */
    EDIT_TICKET,
    GET_TICKET_COUNT,
    GET_TICKETS,
    SET_TICKET_DETAILS,
} from "../../utils/constants/actionTypes";

import {
    ADD_TICKET_LINK,
    /* DELETE_TICKET_LINK, */
    GET_TICKET_CHAT_LINK,
    GET_TICKET_LINK,
    GET_TICKETS_LINK,
    SEND_TICKET_MESSAGE_LINK,
    TICKET_ASSIGN_FM_USERS_LINK,
    UPDATE_TICKET_PRIORITY_LINK,
    UPDATE_TICKET_STATUS_LINK,
} from "../../utils/constants/apiLinks";

import { TICKET_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addTicket = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart());
        basicFileAuthAxios
            .post(ADD_TICKET_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.ticket) {
                            if (
                                request?.fetchOnInsert &&
                                request.fetchOnInsert
                            ) {
                                /* do nothing */
                            } else {
                                dispatch(
                                    fetchSuccess(
                                        "Ticket was added successfully."
                                    )
                                );
                            }
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: ADD_TICKET,
                                    payload: data.ticket,
                                });
                            }
                            if (callbackFun) callbackFun(data.ticket);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredTickets = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_TICKETS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.tickets) {
                            const tickets = data.tickets;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [TICKET_KEYS.TOTAL]: count[
                                        TICKET_KEYS.TOTAL
                                    ]
                                        ? count[TICKET_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [TICKET_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Ticket was added successfully";
                                } else if (fromAction.updated) {
                                    successMsg =
                                        "Selected ticket was updated successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected ticket was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({ type: GET_TICKETS, payload: tickets });
                            dispatch({
                                type: GET_TICKET_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(tickets);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_TICKETS, payload: [] });
                dispatch({
                    type: GET_TICKET_COUNT,
                    payload: {
                        [TICKET_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getFilteredTicketsLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_TICKETS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.tickets) {
                            const tickets = data.tickets;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [TICKET_KEYS.TOTAL]: count[
                                        TICKET_KEYS.TOTAL
                                    ]
                                        ? count[TICKET_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [TICKET_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Ticket was added successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            if (callbackFun)
                                callbackFun({
                                    tickets: tickets,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getTicketLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_TICKET_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.ticket) {
                            const ticket = data.ticket;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(ticket);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was some issue in responding server.";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch(fetchError(error.message));
                throw error;
            });
    };
};

export const getTicketChat = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart(false));
        return basicAuthAxios
            .post(GET_TICKET_CHAT_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.message) {
                            const chat = data.message;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun(chat);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;
                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const sendTicketMessage = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.ticketData;
        const config = {};
        if (body.get("authcode")) {
            config["headers"] = {
                Authorization: "Bearer " + body.get("authcode"),
            };
        }
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        basicFileAuthAxios
            .post(SEND_TICKET_MESSAGE_LINK, body, config)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.message) {
                            if (data.message) {
                                dispatch(
                                    fetchSuccess(
                                        "Message was sent successfully."
                                    )
                                );
                                if (callbackFun) callbackFun(data.message);
                            } else {
                                isError = true;
                                errorMsg = "Unable to get response";
                            }
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = [];

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const setCurrentTicket = (ticket) => {
    return (dispatch) => {
        dispatch({ type: SET_TICKET_DETAILS, payload: ticket });
    };
};

export const updateTicketPriority = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_TICKET_PRIORITY_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.ticket) {
                            const ticket = data.ticket;
                            if (request?.showAlert && request.showAlert) {
                                dispatch(
                                    fetchSuccess(
                                        "Priority updated successfully."
                                    )
                                );
                            }
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_TICKET,
                                    payload: ticket,
                                });
                            }
                            if (callbackFun) callbackFun(ticket);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateTicketStatus = (
    request = {},
    callbackFun,
    errorCallbackFun
) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_TICKET_STATUS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.ticket) {
                            if (request?.showAlert && request.showAlert) {
                                dispatch(
                                    fetchSuccess("Status updated successfully.")
                                );
                            }
                            if (callbackFun) callbackFun(data.ticket);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

/* export const deleteTicket = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_TICKET_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        // dispatch(
                        //     fetchSuccess(
                        //         "Selected ticket was deleted successfully."
                        //     )
                        // );
                        dispatch({
                            type: DELETE_TICKET,
                            payload: request.ticketData[TICKET_KEYS.TICKET_ID],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
}; */

export const assignFmUsers = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.ticketData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(TICKET_ASSIGN_FM_USERS_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.ticket) {
                            const ticket = data.ticket;
                            if (
                                request?.setGlobalState &&
                                request.setGlobalState
                            ) {
                                dispatch({
                                    type: EDIT_TICKET,
                                    payload: ticket,
                                });
                            }
                            if (
                                request?.fetchOnUpdate &&
                                request.fetchOnUpdate
                            ) {
                                /* Do nothing */
                            } else {
                                dispatch(
                                    fetchSuccess(
                                        "Ticket was updated successfully."
                                    )
                                );
                            }
                            if (callbackFun) callbackFun(ticket);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};
