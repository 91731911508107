import React from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";

const IconButtonOptions = ({ children, elevation, badge, ...restProps }) => {
    return (
        <IconButton
            sx={{
                width: 40,
                height: 40,
                boxShadow: elevation,
                padding: 1.25,
            }}
            {...restProps}
        >
            {badge ? (
                <Badge
                    badgeContent={badge.badgeContent ? badge.badgeContent : 0}
                    color={badge.color ? badge.color : "primary"}
                >
                    {children}
                </Badge>
            ) : (
                children
            )}
        </IconButton>
    );
};

export default IconButtonOptions;
