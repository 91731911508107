import {
    ADD_FM_USER,
    DELETE_FM_USER,
    EDIT_FM_USER,
    GET_FM_USER_COUNT,
    GET_FM_USERS,
    SET_FM_USER_DETAILS,
} from "../../utils/constants/actionTypes";
import { FM_USER_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    fmUsers: [],
    currentFmUser: null,
    fmUserCounts: { [FM_USER_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FM_USERS: {
            return {
                ...state,
                fmUsers: action.payload,
            };
        }
        case GET_FM_USER_COUNT: {
            return {
                ...state,
                fmUserCounts: action.payload,
            };
        }
        case SET_FM_USER_DETAILS: {
            return {
                ...state,
                currentFmUser: action.payload,
            };
        }
        case ADD_FM_USER: {
            return {
                ...state,
                fmUsers: [action.payload, ...state.fmUsers],
            };
        }
        case EDIT_FM_USER: {
            return {
                ...state,
                fmUsers: state.fmUsers.map((fmUser) =>
                    fmUser.id === action.payload.id ? action.payload : fmUser
                ),
            };
        }
        case DELETE_FM_USER: {
            return {
                ...state,
                fmUsers: state.fmUsers.filter(
                    (fmUser) => fmUser.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
