import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { updateCompanyRenewalDate } from "../../redux/actions/companies";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    getDateinDesiredFormat,
    getMomentDateObj,
} from "../../utils/dateHelpers";
import { checkDateValid } from "../../utils/validationHelpers";
import {
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";

const EditRenewalDateDialog = ({ currentCompany, open, onClose }) => {
    const _isEditRenewalDateMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [currentCompanyValid, setCurrentCompanyValid] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [renewalDate, setRenewalDate] = useState(null);
    const [renewalDateError, setRenewalDateError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isEditRenewalDateMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setCurrentCompanyValid(false);
            setCompanyId("");
            setRenewalDate(null);
            setRenewalDateError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (isValidUserType) {
            setCurrentCompanyValid(
                currentCompany &&
                    currentCompany?.[COMPANY_KEYS.ID] &&
                    currentCompany[COMPANY_KEYS.ID]
                    ? true
                    : false
            );
        }
    }, [currentCompany, isValidUserType]);

    useEffect(() => {
        if (currentCompanyValid) {
            setCompanyId(currentCompany[COMPANY_KEYS.ID]);

            const renewalDate = currentCompany[COMPANY_KEYS.RENEWAL_DATE];
            const dateTime = `${renewalDate} 00:00:00`;
            const dateTimeObj = getMomentDateObj(dateTime);
            setRenewalDate(dateTimeObj);
        }
    }, [currentCompany, currentCompanyValid]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case COMPANY_KEYS.RENEWAL_DATE:
                        setRenewalDateError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const dateChangeHandler = (newValue) => {
        setRenewalDate(newValue);
        setRenewalDateError("");

        const validationResult = checkDateValid(newValue);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setRenewalDateError(validationResult.msg);
            } else {
                setRenewalDateError("");
            }
        } else {
            setRenewalDateError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const dateValidationResult = checkDateValid(renewalDate);
        if (dateValidationResult.status) {
            if (dateValidationResult.status === "false") {
                formIsValid = false;
                setRenewalDateError(dateValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setRenewalDateError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: companyId,
                [COMPANY_KEYS.RENEWAL_DATE]: getDateinDesiredFormat(
                    renewalDate,
                    "MM/DD/YYYY"
                ),
            };

            dispatch(
                updateCompanyRenewalDate(
                    { companyData: companyData },
                    () => {
                        if (_isEditRenewalDateMounted.current) onClose();
                    },
                    (messages) => {
                        if (_isEditRenewalDateMounted.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const loadError = !isValidUserType || !currentCompanyValid;

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    currentCompanyValid
                        ? `Edit Renewal Date of ${
                              currentCompany[COMPANY_KEYS.NAME]
                          }`
                        : "Invalid company!"
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {loadError && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {ACTION_AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !currentCompanyValid && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    Company is not valid!
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!loadError && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <DatePicker
                                        label="Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={renewalDate}
                                        onChange={dateChangeHandler}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                id="renewal_date"
                                                name="renewal_date"
                                                margin="dense"
                                                error={renewalDateError !== ""}
                                                helperText={renewalDateError}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

EditRenewalDateDialog.propTypes = {
    currentCompany: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

EditRenewalDateDialog.defaultProps = {
    currentCompany: null,
    open: false,
    // onClose: () => {},
};

export default EditRenewalDateDialog;
