import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
/* import FmSearch from "../../shared/FmSearch"; */
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    ALERT_KEYS,
    ALERT_READ,
    AUTH_USER_KEYS,
    REFRESH_ACTION_DELETE,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
/* import { useDebounce } from "../../utils/commonHelper"; */
import {
    getFilteredAlerts,
    markAllAlertsRead,
    markAlertRead,
} from "../../redux/actions/alerts";
import AlertsList from "./AlertsList";

const pageName = "Alerts";

const ALERTS_LIMIT = 24;

const INIT_ALERT_STATE = {
    alerts: [],
    alertsFetched: false,
    fetchAlerts: false,
    firstLoad: true,
    loadMore: false,
    page: 0,
    refresh: false,
    refreshAction: "",
    rowsPerPage: ALERTS_LIMIT,
    search: "",
    searching: false,
    totals: 0,
};

const alertsReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                alertsFetched: false,
                fetchAlerts: true,
            };
        } else if (action.type === "REFRESH") {
            return {
                ...state,
                alertsFetched: false,
                fetchAlerts: true,
                refresh: true,
                refreshAction: action?.refreshAction || "",
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                alerts: [],
                alertsFetched: false,
                fetchAlerts: false,
                firstLoad: false,
                loadMore: false,
                page: 0,
                refresh: false,
                refreshAction: "",
                searching: false,
                totals: 0,
            };
        } else if (action.type === "SEARCH_AND_FETCH") {
            if (state.firstLoad) {
                return { ...state };
            } else {
                return {
                    ...state,
                    alertsFetched: false,
                    fetchAlerts: true,
                    page: 0,
                    search: action.search,
                    searching: true,
                };
            }
        } else if (action.type === "SET_DATA") {
            const isRefreshed = state.refresh;
            const isSearching = state.searching;
            const currentAlerts = state.alerts || [];

            const updatedAlerts =
                isRefreshed || isSearching ? [] : [...currentAlerts];
            let numUpdatedAlerts = updatedAlerts.length;

            let newPage =
                numUpdatedAlerts > 0
                    ? Math.ceil(numUpdatedAlerts / ALERTS_LIMIT)
                    : 0;
            newPage = newPage > 0 ? newPage - 1 : newPage;

            const currentRowsPerPage = state.rowsPerPage;

            const fetchedTotals = action.totals;
            const numTotalAlerts = fetchedTotals[ALERT_KEYS.TOTAL];

            const fetchedAlerts = action.alerts;
            const numFetchedAlerts = fetchedAlerts.length;
            if (numFetchedAlerts > 0) {
                let numAddedAlerts = 0;

                for (let i = 0; i < numFetchedAlerts; i++) {
                    const fetchedAlert = fetchedAlerts[i];
                    if (
                        fetchedAlert &&
                        fetchedAlert?.[ALERT_KEYS.ID] &&
                        fetchedAlert[ALERT_KEYS.ID]
                    ) {
                        const foundAlert = updatedAlerts.find(
                            (alertObj) =>
                                alertObj[ALERT_KEYS.ID] ===
                                fetchedAlert[ALERT_KEYS.ID]
                        );
                        if (!foundAlert) {
                            updatedAlerts.push(fetchedAlert);
                            numAddedAlerts += 1;
                        }
                    }
                }

                numUpdatedAlerts = updatedAlerts.length;

                newPage =
                    numUpdatedAlerts > 0
                        ? Math.ceil(numUpdatedAlerts / ALERTS_LIMIT)
                        : 0;
                newPage = newPage > 0 ? newPage - 1 : newPage;

                if (numUpdatedAlerts === numTotalAlerts) {
                    return {
                        ...state,
                        alerts: updatedAlerts,
                        alertsFetched: true,
                        loadMore: false,
                        page: newPage,
                        refresh: false,
                        refreshAction: "",
                        searching: false,
                        totals: numTotalAlerts,
                    };
                } else {
                    const maxLimit = isRefreshed
                        ? (newPage + 1) * currentRowsPerPage
                        : currentRowsPerPage;
                    if (numAddedAlerts === maxLimit) {
                        return {
                            ...state,
                            alerts: updatedAlerts,
                            alertsFetched: true,
                            loadMore: true,
                            page: newPage + 1,
                            refresh: false,
                            refreshAction: "",
                            searching: false,
                            totals: numTotalAlerts,
                        };
                    } else {
                        return {
                            ...state,
                            alerts: updatedAlerts,
                            alertsFetched: true,
                            loadMore: false,
                            page: newPage,
                            refresh: false,
                            refreshAction: "",
                            searching: false,
                            totals: numTotalAlerts,
                        };
                    }
                }
            } else {
                return {
                    ...state,
                    alerts: updatedAlerts,
                    alertsFetched: true,
                    loadMore: false,
                    page: newPage,
                    refresh: false,
                    refreshAction: "",
                    searching: false,
                    totals: numTotalAlerts,
                };
            }
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchAlerts: false,
                firstLoad: false,
            };
        }
    }
    return INIT_ALERT_STATE;
};

const Alerts = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isMountedAlerts = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const [isLoading, setIsLoading] = useState(false);
    /* const [searchTerm, setSearchTerm] = useState(""); */

    const [alertsState, dispatchAlertsAction] = useReducer(
        alertsReducer,
        INIT_ALERT_STATE
    );

    const { alerts, alertsFetched, fetchAlerts, loadMore } = alertsState;

    /* const debouncedSearchTerm = useDebounce(searchTerm, 500); */

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    useEffect(() => {
        return () => {
            _isMountedAlerts.current = false;
        };
    }, []);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch alerts */
    useEffect(() => {
        if (isLoading) {
            dispatchAlertsAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (alertsState.fetchAlerts) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredAlerts(payload, (fetchedData) => {
                            if (isActive) {
                                dispatchAlertsAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchAlertsAction({
                                    type: "SET_DATA",
                                    alerts: fetchedData?.alerts || [],
                                    totals: fetchedData?.totals || null,
                                });
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const alertData = {
                authcode: authToken,
                /* search: alertsState.search, */
                sort: ALERT_KEYS.ID,
                order: SORT_ORDER_DESC,
            };

            let currentPage = alertsState.page + 1;
            if (alertsState.refresh) {
                const numTotals = alertsState.alerts.length;
                if (alertsState.refreshAction === REFRESH_ACTION_DELETE) {
                    currentPage = Math.ceil((numTotals - 1) / ALERTS_LIMIT);
                }
                alertData.page = 1;
                alertData.rows_per_page = currentPage * alertsState.rowsPerPage;
            } else {
                alertData.page = currentPage;
                alertData.rows_per_page = alertsState.rowsPerPage;
            }

            const payload = { alertData: alertData };
            if (!alertsState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchAlertsAction({ type: "RESET" });
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, dispatchAlertsAction, authToken, alertsState]);

    /* useEffect(() => {
        dispatchAlertsAction({
            type: "SEARCH_AND_FETCH",
            search: debouncedSearchTerm,
        });
    }, [debouncedSearchTerm, dispatchAlertsAction]); */

    const handleMarkRead = (alert) => {
        if (alert && alert[ALERT_KEYS.ID]) {
            const alertData = {
                authcode: authToken,
                [ALERT_KEYS.ALERT_ID]: alert[ALERT_KEYS.ID],
                [ALERT_KEYS.STATUS]: ALERT_READ,
            };
            dispatch(
                markAlertRead(
                    { alertData: alertData },
                    () => {
                        if (_isMountedAlerts.current) {
                            dispatchAlertsAction({
                                type: "REFRESH",
                                refreshAction: REFRESH_ACTION_DELETE,
                            });
                        }
                    },
                    () => {}
                )
            );
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid alert",
            });
        }
    };

    const handleLoadMore = () => dispatchAlertsAction({ type: "FETCH" });

    /* const searchTermChangeHandler = (event) =>
        setSearchTerm(event.target.value); */

    const handleMarkAllRead = React.useCallback(() => {
        showDialog({
            variant: "confirm",
            title: "Mark ALL alerts as read?",
            onYes: () => {
                hideDialog();
                const alertData = {
                    authcode: authToken,
                };
                dispatch(
                    markAllAlertsRead(
                        { alertData: alertData },
                        () => {
                            if (_isMountedAlerts.current) {
                                dispatchAlertsAction({
                                    type: "REFRESH",
                                    refreshAction: REFRESH_ACTION_DELETE,
                                });
                            }
                        },
                        () => {}
                    )
                );
            },
            onNo: hideDialog,
        });
    }, [dispatch, hideDialog, showDialog, authToken]);

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const alertsFetchError = !fetchAlerts && !alertsFetched;
    const loadError = !isValidUserType || alertsFetchError;
    const alertsNonZero = alerts && alerts.length > 0;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && alertsFetchError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    There was a problem in fetching the alerts!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        {alertsNonZero && (
                            <React.Fragment>
                                <Grid item xs={12} sx={{ textAlign: "right" }}>
                                    <Button
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        startIcon={<DoneAllIcon />}
                                        onClick={handleMarkAllRead}
                                    >
                                        Mark ALL as read
                                    </Button>
                                </Grid>
                                {/* <Grid item md={9} xs={12}></Grid>
                                <Grid item md={3} xs={12}>
                                    <FmSearch
                                        ariaLabel="Search alerts"
                                        id="alerts_search"
                                        placeholder="Search Alerts"
                                        searchTerm={searchTerm}
                                        filterSearch={fetchAlerts}
                                        onSearchChange={searchTermChangeHandler}
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <AlertsList
                                        alerts={alerts}
                                        onMarkRead={handleMarkRead}
                                    />
                                </Grid>
                                {loadMore && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ textAlign: "center" }}
                                    >
                                        <LoadingButton
                                            loading={fetchAlerts}
                                            type="button"
                                            variant="outlined"
                                            size="small"
                                            loadingPosition="start"
                                            className="btnHomeLoadMore"
                                            startIcon={<RefreshIcon />}
                                            onClick={handleLoadMore}
                                        >
                                            Load More Alerts
                                        </LoadingButton>
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                        {!alertsNonZero && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    No records found!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Alerts;
