import React from "react";
import Alerts from "../pages/alerts";
import Page from "@jumbo/shared/Page";

const alertsRoutes = [
    {
        path: "/alerts",
        element: <Page component={Alerts} />,
    },
];

export default alertsRoutes;
