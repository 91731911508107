export const ACCOUNT_ASSET_NOT_VALID = "Account Asset is not valid";
export const ACTION_AUTHORIZATION_ERROR =
    "You are not authorized to perform this action!";
export const AMOUNT_NOT_VALID = "Amount is not valid!";
export const ANSWER_REQUIRED = "At least one answer is required!";
export const API_COMMON_ERROR =
    "There was a problem in processing the request!";
export const ASSET_TYPE_INVALID = "Document Type is not valid!";
export const AUTHORIZATION_ERROR = "You are not authorized to view this page!";
export const CARD_NO_NOT_VALID = "Card Number is not valid!";
export const CLIENT_NOT_VALID = "Client is not valid!";
export const CLIENTS_NOT_VALID = "One or more client(s) are not valid!";
export const COMPANY_NOT_VALID = "Company is not valid!";
export const COUNTRY_NOT_VALID = "Country is not valid!";
export const CVV_NOT_VALID = "CVV is not valid!";
export const DATE_NOT_VALID = "Date is not valid!";
export const DELETED_DOCUMENTS_REQUIRED =
    "Please select at least one document for deletion!";
export const DUPLICATE_OPTIONS_ERROR = "Duplicate options are not allowed!";
export const DUPLICATE_QUESTIONS_ERROR = "Duplicate questions are not allowed!";
export const ICON_NOT_VALID = "Icon is not valid!";
export const FM_USER_NOT_VALID = "User is not valid!";
export const FM_USERS_NOT_VALID = "One or more user(s) are not valid!";
export const FILE_EXTENSION_NOT_VALID =
    "Only {extensionsList} files are allowed!";
export const FILE_SIZE_NOT_VALID =
    "File size cannot exceed {size} {sizeUnits}!";
export const FOLDER_NOT_VALID = "Folder is not valid!";
export const EMAIL_NOT_VALID = "Email is not valid!";
export const EVENT_NOT_IN_FUTURE = "Event date must be a future date!";
export const FM_CLIENTS_NOT_VALID = "One or more client(s) are not valid!";
export const INDUSTRY_NOT_VALID = "Industry is not valid!";
export const INTERVAL_NOT_VALID = "Interval is not valid!";
export const INTERVALS_LIST_NOT_VALID = "Intervals List is not valid!";
export const INVALID_ASSIGNED_COMPANIES =
    "One or more company(ies) are not valid!";
export const INVALID_DATE_RANGE =
    "End date must be a date after the start date!";
export const MAX_FILES_ERROR = "Maximum {MAX_COUNT} files can be uploaded!";
export const MEETING_NOT_IN_FUTURE = "Meeting date must be a future date!";
export const MONTH_NOT_VALID = "Month is not valid!";
export const OPTIONS_NOT_VALID = "One or more options is not valid!";
export const PASSWORD_MISMATCH = "Password did not match!";
export const PASSWORD_RULES_MISMATCH =
    "Password must be atleast 6 characters long!";
export const PAST_DATE_NOT_ALLOWED = "Past dates are not allowed!";
export const PHONE_NO_NOT_VALID = "Phone No is not valid!";
export const PHONE_NO_EXT_NOT_VALID = "Extension is not valid!";
export const RESPONSE_NOT_VALID = "Response is not valid!";
export const REQUIRED = "This field is required!";
export const SERVICE_NOT_VALID = "Service is not valid!";
export const SIDEBAR_IS_ACTIVE_INVALID = "Please select a valid option!";
export const SIDEBAR_NEW_TAB_INVALID = "Please select a valid option!";
export const SIDEBAR_URL_TYPE_INVALID = "Please select a valid option!";
export const SINGLE_ANSWER_REQUIRED =
    "Only one option can be selected as answer!";
export const STATE_NOT_VALID = "State is not valid!";
export const STATUS_NOT_VALID = "Status is not valid!";
export const SURVEY_ANSWER_REQUIRED = "Answer is required!";
export const SURVEY_QUES_TYPE_NOT_VALID = "Question Type is not valid!";
export const TIME_NOT_VALID = "Time is not valid!";
export const TIME_ZONE_NOT_VALID = "Time Zone is not valid!";
export const VALIDATION_FAILED = "Unable to validate the value!";
export const YEAR_NOT_VALID = "Year is not valid!";
