import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { CKEditor } from "ckeditor4-react";
import { NumericFormat } from "react-number-format";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import { getServicePriceIntervals } from "../../utils/appHelpers";
import {
    checkServiceDescriptionValid,
    checkServiceNameValid,
    checkServicePriceValid,
    checkServicePriceIntervalValid,
} from "../../utils/validationHelpers";
import {
    AUTH_USER_KEYS,
    CKEDITOR_TOOLBAR,
    SERVICE_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { ASSET_MUSEO_SANS } from "../../utils/constants/paths";
import { addService, updateService } from "../../redux/actions/services";

const PRICE_INTERVALS = getServicePriceIntervals();

const PriceFormat = React.forwardRef(function PriceFormat(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
            thousandSeparator={false}
        />
    );
});

PriceFormat.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const AddEditServiceDialog = ({
    currentService,
    open,
    onClose,
    onDispatchAction,
}) => {
    const _isMountedAddEditService = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [serviceIsValid, setServiceIsValid] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [initDescription, setInitDescription] = useState("__UNSET__");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [price, setPrice] = useState("");
    const [priceError, setPriceError] = useState("");
    const [priceInterval, setPriceInterval] = useState(null);
    const [priceIntervalError, setPriceIntervalError] = useState("");
    const [validatePrice, setValidatePrice] = useState(false);
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isMountedAddEditService.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setServiceIsValid(false);
            setName("");
            setNameError("");
            setInitDescription("__UNSET__");
            setDescription("");
            setDescriptionError("");
            setPrice("");
            setPriceError("");
            setPriceInterval(null);
            setPriceIntervalError("");
            setValidatePrice(false);
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (isValidUserType) {
            if (currentService) {
                if (
                    currentService?.[SERVICE_KEYS.ID] &&
                    currentService[SERVICE_KEYS.ID]
                ) {
                    setServiceIsValid(true);
                }
            } else {
                setServiceIsValid(true);
            }
        }
    }, [currentService, isValidUserType]);

    useEffect(() => {
        if (serviceIsValid) {
            if (currentService) {
                setName(currentService[SERVICE_KEYS.NAME]);
                setDescription(currentService[SERVICE_KEYS.DESCRIPTION]);
                setInitDescription(currentService[SERVICE_KEYS.DESCRIPTION]);
                setPrice(currentService[SERVICE_KEYS.PRICE]);
                setPriceInterval(currentService[SERVICE_KEYS.PRICE_INTERVAL]);
            } else {
                setInitDescription("");
            }
        }
    }, [currentService, serviceIsValid]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case SERVICE_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case SERVICE_KEYS.DESCRIPTION:
                        setDescriptionError(msg);
                        break;

                    case SERVICE_KEYS.PRICE:
                        setPriceError(msg);
                        break;

                    case SERVICE_KEYS.PRICE_INTERVAL:
                        setPriceIntervalError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    useEffect(() => {
        if (validatePrice) {
            const priceValidationResult = checkServicePriceValid(price);
            if (priceValidationResult.status) {
                if (priceValidationResult.status === "false") {
                    setPriceError(priceValidationResult.msg);
                } else {
                    setPriceError("");
                }
            } else {
                setPriceError(VALIDATION_FAILED);
            }

            const priceIntervalRequired = !!price;
            const priceIntervalValidationResult =
                checkServicePriceIntervalValid(
                    priceInterval,
                    PRICE_INTERVALS,
                    priceIntervalRequired
                );
            if (priceIntervalValidationResult.status) {
                if (priceIntervalValidationResult.status === "false") {
                    setPriceIntervalError(priceIntervalValidationResult.msg);
                } else {
                    setPriceIntervalError("");
                }
            } else {
                setPriceIntervalError(VALIDATION_FAILED);
            }

            setValidatePrice(false);
        }
    }, [price, priceInterval, validatePrice]);

    const nameBlurHandler = (event) => {
        const validationResult = checkServiceNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const descriptionChangeHandler = (event) => {
        setDescription(event.editor.getData());
        setDescriptionError("");
    };

    const descriptionBlurHandler = (event) => {
        const validationResult = checkServiceDescriptionValid(
            event.editor.getData()
        );
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setDescriptionError(validationResult.msg);
            }
        } else {
            setDescriptionError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkServiceNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        const descriptionValidationResult =
            checkServiceDescriptionValid(description);
        if (descriptionValidationResult.status) {
            if (descriptionValidationResult.status === "false") {
                formIsValid = false;
                setDescriptionError(descriptionValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setDescriptionError(VALIDATION_FAILED);
        }

        const priceValidationResult = checkServicePriceValid(price);
        if (priceValidationResult.status) {
            if (priceValidationResult.status === "false") {
                formIsValid = false;
                setPriceError(priceValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPriceError(VALIDATION_FAILED);
        }

        const priceIntervalRequired = !!price;
        const priceIntervalValidationResult = checkServicePriceIntervalValid(
            priceInterval,
            PRICE_INTERVALS,
            priceIntervalRequired
        );
        if (priceIntervalValidationResult.status) {
            if (priceIntervalValidationResult.status === "false") {
                formIsValid = false;
                setPriceIntervalError(priceIntervalValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPriceIntervalError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const serviceData = {
                authcode: authToken,
                [SERVICE_KEYS.NAME]: name,
                [SERVICE_KEYS.DESCRIPTION]: description,
                [SERVICE_KEYS.PRICE]: price,
                [SERVICE_KEYS.PRICE_INTERVAL]: priceInterval
                    ? priceInterval
                    : "",
            };

            if (currentService) {
                serviceData[SERVICE_KEYS.SERVICE_ID] =
                    currentService[SERVICE_KEYS.ID];
                dispatch(
                    updateService(
                        { serviceData: serviceData },
                        () => {
                            if (_isMountedAddEditService.current) onClose();
                        },
                        (messages) => {
                            if (_isMountedAddEditService.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                dispatch(
                    addService(
                        { serviceData: serviceData },
                        () => {
                            if (_isMountedAddEditService.current) {
                                onDispatchAction({ type: "INSERT" });
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isMountedAddEditService.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    const errorOnLoading = !isValidUserType || !serviceIsValid;

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentService ? "Edit Service" : "Add Service"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {errorOnLoading && (
                    <Grid container spacing={2}>
                        {isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {ACTION_AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !serviceIsValid && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    Service is not valid!
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!errorOnLoading && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={name}
                                    margin="dense"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameError("");
                                    }}
                                    onBlur={nameBlurHandler}
                                    error={nameError !== ""}
                                    helperText={nameError}
                                />
                            </Grid>
                            {initDescription !== "__UNSET__" && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <CKEditor
                                            name="description"
                                            initData={initDescription}
                                            config={{
                                                contentsCss: [
                                                    `${ASSET_MUSEO_SANS}/styles.css`,
                                                    `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                                                ],
                                                toolbar: CKEDITOR_TOOLBAR,
                                                removePlugins: "image",
                                            }}
                                            onBlur={descriptionBlurHandler}
                                            onChange={descriptionChangeHandler}
                                        />
                                        {descriptionError && (
                                            <FormHelperText
                                                error
                                                id="description-error"
                                            >
                                                {descriptionError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    id="price"
                                    name="price"
                                    label="Price"
                                    value={price}
                                    margin="dense"
                                    onChange={(e) => {
                                        const updatedPrice = e.target.value;
                                        setPrice(
                                            updatedPrice ? updatedPrice : ""
                                        );
                                    }}
                                    onBlur={() => setValidatePrice(true)}
                                    error={priceError !== ""}
                                    helperText={priceError}
                                    InputProps={{
                                        inputComponent: PriceFormat,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        filterSelectedOptions
                                        id="price_interval"
                                        options={PRICE_INTERVALS}
                                        value={priceInterval}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Select Price Interval"
                                                error={
                                                    priceIntervalError !== ""
                                                }
                                            />
                                        )}
                                        onChange={(event, newValue) => {
                                            setPriceInterval(
                                                newValue ? newValue : null
                                            );
                                            setValidatePrice(true);
                                        }}
                                    />
                                    {priceIntervalError && (
                                        <FormHelperText
                                            error
                                            id="price_interval-error"
                                        >
                                            {priceIntervalError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {currentService
                                        ? "Update Service"
                                        : "Add Service"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

AddEditServiceDialog.propTypes = {
    currentService: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDispatchAction: PropTypes.func,
};

AddEditServiceDialog.defaultProps = {
    currentService: null,
    open: false,
    // onClose: () => {},
    onDispatchAction: () => {},
};

export default AddEditServiceDialog;
