import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import FormattedPhoneNoTxt from "../../shared/widgets/FormattedPhoneNoTxt";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    ACTIVE_FM_USER,
    FM_USER_KEYS,
    PAGINATION_PAGES,
} from "../../utils/constants/appData";
import { cleanPhoneNo } from "../../utils/dataHelpers";

const allHeadCells = [
    {
        id: FM_USER_KEYS.ID,
        label: "User",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: FM_USER_KEYS.EMAIL,
        label: "Contact",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: FM_USER_KEYS.ROLE,
        label: "Role",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: FM_USER_KEYS.CREATED_AT,
        label: "Created",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: FM_USER_KEYS.TBL_COMPANY,
        label: "Company",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
];

const FMUsersTable = ({
    fmUsers,
    fmUserCounts,
    fmUsersFetched,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    onEdit,
    onDelete,
    onUpdateStatus,
    /* viewClientsHandler, */
    onViewAssignedCompanies,
}) => {
    let colspan = allHeadCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const fmUserCountNonZero =
        fmUsers &&
        fmUsers.length > 0 &&
        fmUserCounts &&
        fmUserCounts[FM_USER_KEYS.TOTAL] &&
        !isNaN(parseInt(fmUserCounts[FM_USER_KEYS.TOTAL])) &&
        parseInt(fmUserCounts[FM_USER_KEYS.TOTAL]) > 0;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Users Table">
                    <TableHead>
                        <TableRow>
                            {allHeadCells.map((headCell) =>
                                headCell.show ? (
                                    <TableCell key={headCell.id}>
                                        {headCell.label}
                                    </TableCell>
                                ) : null
                            )}
                            <TableCell
                                key="actions"
                                sx={{ textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {fmUserCountNonZero ? (
                            fmUsers.map((fmUser) => {
                                const fmUserId = fmUser[FM_USER_KEYS.ID];
                                const fmUserName = fmUser[FM_USER_KEYS.NAME];
                                const fmUserEmail = fmUser[FM_USER_KEYS.EMAIL];
                                const fmUserStatus =
                                    fmUser[FM_USER_KEYS.STATUS];
                                const isActiveFmUser =
                                    fmUserStatus === ACTIVE_FM_USER;
                                const fetchedPhoto =
                                    fmUser[FM_USER_KEYS.PROFILE_PIC];
                                let photoLink = "";
                                if (fetchedPhoto) {
                                    photoLink = `${
                                        fmUser[FM_USER_KEYS.PHOTO_BASE_URL]
                                    }/${
                                        fetchedPhoto[
                                            FM_USER_KEYS.PHOTO_FILE_PATH
                                        ]
                                    }`;
                                }
                                const fmUserInitials = photoLink ? (
                                    <React.Fragment>&nbsp;</React.Fragment>
                                ) : (
                                    getInitialsFromName(fmUserName)
                                );
                                return (
                                    <TableRow
                                        key={fmUser[FM_USER_KEYS.ID]}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1.25}
                                            >
                                                <Avatar
                                                    src={photoLink}
                                                    sizes={"small"}
                                                    alt={fmUserName}
                                                    sx={{
                                                        bgcolor: "#487AA3",
                                                    }}
                                                >
                                                    {fmUserInitials}
                                                </Avatar>
                                                <NameLink
                                                    component={RouterLink}
                                                    to={`/fmUser/${fmUserId}/`}
                                                >
                                                    {fmUserName}
                                                </NameLink>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {fmUserEmail ? (
                                                <NameLink
                                                    href={`mailto:${fmUserEmail}`}
                                                    variant="body1"
                                                >
                                                    {fmUserEmail}
                                                </NameLink>
                                            ) : (
                                                ""
                                            )}
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette.primary
                                                            .light,
                                                }}
                                            >
                                                <FormattedPhoneNoTxt
                                                    value={cleanPhoneNo(
                                                        fmUser[
                                                            FM_USER_KEYS
                                                                .PHONE_NO
                                                        ]
                                                    )}
                                                />
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {fmUser?.[FM_USER_KEYS.ROLE] || ""}
                                        </TableCell>
                                        <TableCell>
                                            {fmUser[FM_USER_KEYS.CREATED_AT]}
                                        </TableCell>
                                        <TableCell>
                                            {fmUser[
                                                FM_USER_KEYS.ASSIGNED_COMPANIES
                                            ] ? (
                                                <Button
                                                    disableElevation
                                                    type="button"
                                                    variant="text"
                                                    onClick={() =>
                                                        onViewAssignedCompanies(
                                                            fmUser
                                                        )
                                                    }
                                                >
                                                    View Companies
                                                </Button>
                                            ) : (
                                                "No companies assigned"
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {isActiveFmUser && (
                                                    <TableActionIcon
                                                        iconComponent="edit"
                                                        label="edit user"
                                                        tooltip="Edit User"
                                                        onClick={() =>
                                                            onEdit(fmUser)
                                                        }
                                                    />
                                                )}
                                                <TableActionIcon
                                                    iconComponent={
                                                        isActiveFmUser
                                                            ? "visibility"
                                                            : "visibilityOff"
                                                    }
                                                    label={
                                                        isActiveFmUser
                                                            ? "deactivate user"
                                                            : "activate user"
                                                    }
                                                    tooltip={
                                                        isActiveFmUser
                                                            ? "Deactivate User"
                                                            : "Activate User"
                                                    }
                                                    onClick={() =>
                                                        onUpdateStatus(fmUser)
                                                    }
                                                />
                                                <TableActionIcon
                                                    iconComponent="delete"
                                                    label="delete user"
                                                    tooltip="Delete User"
                                                    onClick={() =>
                                                        onDelete(fmUser)
                                                    }
                                                />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            fmUsersFetched ? "error" : "info"
                                        }
                                    >
                                        {fmUsersFetched
                                            ? "There are no records found."
                                            : "Loading users..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    fmUserCountNonZero
                        ? parseInt(fmUserCounts[FM_USER_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={fmUserCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

FMUsersTable.propTypes = {
    fmUsers: PropTypes.array,
    fmUserCounts: PropTypes.object,
    fmUsersFetched: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onUpdateStatus: PropTypes.func,
    /* viewClientsHandler: PropTypes.func, */
    onViewAssignedCompanies: PropTypes.func,
};

FMUsersTable.defaultProps = {
    fmUsers: [],
    fmUserCounts: null,
    fmUsersFetched: false,
    page: 0,
    rowsPerPage: 0,
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onEdit: () => {},
    // onDelete: () => {},
    // onUpdateStatus: () => {},
    // viewClientsHandler: () => {},
    // onViewAssignedCompanies: () => {},
};

export default FMUsersTable;
