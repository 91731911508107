import React, {
    lazy,
    Suspense,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
    /* deleteTicket, */
    getFilteredTickets,
    setCurrentTicket,
} from "../../redux/actions/tickets";
import FmSearch from "../../shared/FmSearch";
import FmSingleAutocomplete from "../../shared/FmSingleAutocomplete";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    getTicketPriorityList,
    getTicketStatusColor,
    getTicketStatusList,
} from "../../utils/appHelpers";
import { useDebounce } from "../../utils/commonHelper";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    EMPTY_PARAM_VALUE,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    TICKET_KEYS,
    TICKET_OPEN_STATUS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import { allTicketsHeadCells } from "../../utils/constants/tableColumns";
import AssignFmUsersDialog from "../ticket/AssignFmUsersDialog";
import EditTicketPriorityDialog from "./EditTicketPriorityDialog";
import EditTicketStatusDialog from "./EditTicketStatusDialog";
import TicketDescriptionDialog from "../ticket/TicketDescriptionDialog";
import TicketsTable from "./TicketsTable";
import TicketsTabPanel, { a11yProps } from "./TicketsTabPanel";
const AddTicketDialog = lazy(() => import("./AddTicketDialog"));

const priorityList = getTicketPriorityList();
const statusList = getTicketStatusList();

const pageName = "Tickets";

const TICKETS_LIMIT = 10;

const INIT_TICKETS_STATE = {
    clientId: "",
    companyId: "",
    fetchTickets: false,
    filtered: false,
    firstLoad: true,
    inputIsValid: false,
    order: SORT_ORDER_DESC,
    page: 0,
    priority: null,
    rowsPerPage: TICKETS_LIMIT,
    search: "",
    searching: false,
    sort: TICKET_KEYS.CREATED_AT,
    status: "",
    ticketsFetched: false,
};

const ticketsLocalReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, fetchTickets: true, ticketsFetched: false };
        } else if (action.type === "RESET") {
            return {
                ...state,
                fetchTickets: false,
                filtered: false,
                firstLoad: false,
                page: 0,
                searching: false,
                ticketsFetched: false,
            };
        } else if (action.type === "SEARCH_AND_FETCH") {
            if (state.firstLoad) {
                return { ...state };
            } else {
                return {
                    ...state,
                    fetchTickets: true,
                    page: 0,
                    search: action.search,
                    searching: true,
                    ticketsFetched: false,
                };
            }
        } else if (action.type === "SET_CLIENT_ID") {
            const currentCompanyId = state.companyId;
            const currentStatus = state.status;
            const updatedClientId = action.clientId;

            return {
                ...state,
                clientId: updatedClientId,
                inputIsValid:
                    !!currentCompanyId && !!currentStatus && !!updatedClientId,
            };
        } else if (action.type === "SET_COMPANY_ID") {
            const currentClientId = state.clientId;
            const currentStatus = state.status;
            const updatedCompanyId = action.companyId;

            return {
                ...state,
                companyId: updatedCompanyId,
                inputIsValid:
                    !!currentClientId && !!currentStatus && !!updatedCompanyId,
            };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                fetchTickets: true,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                ticketsFetched: false,
            };
        } else if (action.type === "SET_PAGE") {
            return {
                ...state,
                fetchTickets: true,
                page: action.page,
                ticketsFetched: false,
            };
        } else if (action.type === "SET_PRIORITY_AND_FETCH") {
            return {
                ...state,
                fetchTickets: true,
                filtered: true,
                page: 0,
                priority: action.priority || null,
                ticketsFetched: false,
            };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                fetchTickets: true,
                sort: updatedSort,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                ticketsFetched: false,
            };
        } else if (action.type === "SET_STATUS") {
            const currentClientId = state.clientId;
            const currentCompanyId = state.companyId;
            const updatedStatus = action.status;

            return {
                ...state,
                inputIsValid:
                    !!currentClientId && !!currentCompanyId && !!updatedStatus,
                status: updatedStatus,
            };
        } else if (action.type === "SET_STATUS_AND_FETCH") {
            const currentClientId = state.clientId;
            const currentCompanyId = state.companyId;
            const updatedStatus = action.status;

            const updatedInputIsValid =
                !!currentClientId && !!currentCompanyId && !!updatedStatus;
            if (updatedInputIsValid) {
                return {
                    ...state,
                    fetchTickets: true,
                    inputIsValid: updatedInputIsValid,
                    status: updatedStatus,
                    page: 0,
                    ticketsFetched: false,
                };
            } else {
                return {
                    ...state,
                    inputIsValid: updatedInputIsValid,
                    status: updatedStatus,
                };
            }
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchTickets: false,
                filtered: false,
                firstLoad: false,
                searching: false,
                ticketsFetched: true,
            };
        }
    }

    return INIT_TICKETS_STATE;
};

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, updated: false, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, updated: false, deleted: true };
        } else if (action.type === "UPDATE") {
            return { inserted: false, updated: true, deleted: false };
        }
    }

    return { inserted: false, deleted: false, updated: false };
};

const Tickets = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isTicketsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const Swal = useSwalWrapper();

    const { currentTicket, tickets, ticketCounts } = useSelector(
        ({ ticketsReducer }) => ticketsReducer
    );

    const [params] = useSearchParams();
    const clientParam = params.get("client") || "";
    const companyParam = params.get("company") || "";
    const statusParam = params.get("status") || "";

    const [isLoading, setIsLoading] = useState(false);
    const [headCells, setHeadCells] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [openAddTicketDialog, setOpenAddTicketDialog] = useState(false);
    const [openAssignFmUsersDialog, setOpenAssignFmUsersDialog] =
        useState(false);
    const [openEditTicketPriorityDialog, setOpenEditTicketPriorityDialog] =
        useState(false);
    const [openEditTicketStatusDialog, setOpenEditTicketStatusDialog] =
        useState(false);
    const [openTicketDescriptionDialog, setOpenTicketDescriptionDialog] =
        useState(false);

    const [ticketsState, dispatchTicketsAction] = useReducer(
        ticketsLocalReducer,
        INIT_TICKETS_STATE
    );

    const { fetchTickets, inputIsValid, status, ticketsFetched } = ticketsState;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
        updated: false,
    });

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isAdminUser || isFmUser || isClientUser;

    const canAssignFmUsers = isAdminUser;
    /* const canDeleteTicket = isAdminUser || isFmUser; */
    const canUpdatePriority = isAdminUser || isFmUser;
    const canUpdateStatus = isAdminUser || isFmUser;
    const canReadDescription = /* isAdminUser || isFmUser || isClientUser */ false;

    useEffect(() => {
        return () => {
            _isTicketsMounted.current = false;
            dispatch(setCurrentTicket(null));
        };
    }, [dispatch]);

    /* Set the table columns to show */
    useEffect(() => {
        if (isAdminUser) {
            setHeadCells(
                allTicketsHeadCells.map((headCell) => {
                    if (
                        [TICKET_KEYS.ID, TICKET_KEYS.CREATED_AT].includes(
                            headCell.id
                        )
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        let columnLabel = headCell.label;
                        if (headCell.id === TICKET_KEYS.SUBJECT) {
                            columnLabel = "Ticket";
                        }

                        return {
                            ...headCell,
                            label: columnLabel,
                            show: true,
                            sortColumn: true,
                        };
                    }
                })
            );
        } else if (isFmUser) {
            setHeadCells(
                allTicketsHeadCells.map((headCell) => {
                    if (
                        [
                            TICKET_KEYS.ID,
                            TICKET_KEYS.FM_USERS,
                            TICKET_KEYS.CREATED_AT,
                        ].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        let columnLabel = headCell.label;
                        if (headCell.id === TICKET_KEYS.SUBJECT) {
                            columnLabel = "Ticket";
                        }

                        return {
                            ...headCell,
                            label: columnLabel,
                            show: true,
                            sortColumn: true,
                        };
                    }
                })
            );
        } else if (isClientUser) {
            setHeadCells(
                allTicketsHeadCells.map((headCell) => {
                    if (
                        [
                            TICKET_KEYS.ID,
                            TICKET_KEYS.PRIORITY,
                            TICKET_KEYS.CREATED_AT,
                        ].includes(headCell.id)
                    ) {
                        return { ...headCell, show: false };
                    } else {
                        let columnLabel = headCell.label;
                        if (headCell.id === TICKET_KEYS.SUBJECT) {
                            columnLabel = "Ticket";
                        }

                        return {
                            ...headCell,
                            label: columnLabel,
                            show: true,
                            sortColumn: true,
                        };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isClientUser, isFmUser]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Set url parameters as filters for fetching tickets */
    useEffect(() => {
        if (isValidUserType) {
            if (isAdminUser || isFmUser) {
                dispatchTicketsAction({
                    type: "SET_CLIENT_ID",
                    clientId: clientParam || EMPTY_PARAM_VALUE,
                });
            } else {
                dispatchTicketsAction({
                    type: "SET_CLIENT_ID",
                    clientId: EMPTY_PARAM_VALUE,
                });
            }
        }
    }, [clientParam, isAdminUser, isFmUser, isValidUserType]);

    useEffect(() => {
        if (isValidUserType) {
            if (isAdminUser || isFmUser) {
                dispatchTicketsAction({
                    type: "SET_COMPANY_ID",
                    companyId: companyParam || EMPTY_PARAM_VALUE,
                });
            } else {
                dispatchTicketsAction({
                    type: "SET_COMPANY_ID",
                    companyId: EMPTY_PARAM_VALUE,
                });
            }
        }
    }, [companyParam, isAdminUser, isFmUser, isValidUserType]);

    useEffect(() => {
        if (isValidUserType) {
            const statusObj = statusList.find(
                (statusItem) =>
                    statusItem.label.toLowerCase() === statusParam.toLowerCase()
            );

            dispatchTicketsAction({
                type: "SET_STATUS",
                status: statusObj?.value || TICKET_OPEN_STATUS,
            });
        }
    }, [isValidUserType, statusParam]);

    /* Start loading the page content if input is okay */
    useEffect(() => {
        if (inputIsValid) {
            setIsLoading(true);
        }
    }, [inputIsValid]);

    /* Fetch filtered tickets list */
    useEffect(() => {
        if (isLoading) {
            dispatchTicketsAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (ticketsState.fetchTickets) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredTickets(payload, () => {
                            if (isActive) {
                                dispatchTicketsAction({ type: "STOP_FETCH" });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const ticketData = {
                authcode: authToken,
                page: ticketsState.page + 1,
                rows_per_page: ticketsState.rowsPerPage,
                sort: ticketsState.sort,
                order: ticketsState.order,
                search: ticketsState.search,
                [TICKET_KEYS.PRIORITY]: ticketsState.priority || "",
                [TICKET_KEYS.STATUS]: ticketsState.status,
            };

            if (ticketsState.clientId !== EMPTY_PARAM_VALUE) {
                ticketData[TICKET_KEYS.CLIENT_ID] = ticketsState.clientId;
            }

            if (ticketsState.companyId !== EMPTY_PARAM_VALUE) {
                ticketData[TICKET_KEYS.COMPANY_ID] = ticketsState.companyId;
            }

            const payload = { ticketData: ticketData, fromAction: actionState };
            if (!ticketsState.firstLoad && !ticketsState.searching) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    dispatchTicketsAction({ type: "RESET" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, actionState, ticketsState]);

    /* Search tickets */
    useEffect(() => {
        dispatchTicketsAction({
            type: "SEARCH_AND_FETCH",
            search: debouncedSearchTerm,
        });
    }, [debouncedSearchTerm, dispatchTicketsAction]);

    /**
     * Fetch the tickets list again if a ticket is
     * inserted or deleted or updated
     */
    useEffect(() => {
        if (
            actionState.inserted ||
            actionState.deleted ||
            actionState.updated
        ) {
            dispatchTicketsAction({ type: "FETCH" });
        }
    }, [actionState]);

    const searchTermChangeHandler = (event) =>
        setSearchTerm(event.target.value);

    const priorityFilterChangeHandler = (value) => {
        dispatchTicketsAction({
            type: "SET_PRIORITY_AND_FETCH",
            priority: value,
        });
    };

    const handleSort = (property) => {
        dispatchTicketsAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleStatusChange = (event, newValue) =>
        dispatchTicketsAction({
            type: "SET_STATUS_AND_FETCH",
            status: newValue,
        });

    const handleOpenAddTicketDialog = () => {
        setOpenAddTicketDialog(true);
    };

    const handleCloseAddTicketDialog = () => {
        setOpenAddTicketDialog(false);
        dispatch(setCurrentTicket(null));
    };

    const handleOpenAssignFmUsersDialog = (ticket) => {
        if (canAssignFmUsers) {
            dispatch(setCurrentTicket(ticket));
            setOpenAssignFmUsersDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAssignFmUsersDialog = () => {
        setOpenAssignFmUsersDialog(false);
        dispatch(setCurrentTicket(null));
    };

    const handlePageChange = (event, newPage) =>
        dispatchTicketsAction({ type: "SET_PAGE", page: newPage });

    const handleRowsPerPageChange = (event) => {
        dispatchTicketsAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleReadTicketDescription = (ticket) => {
        if (canReadDescription) {
            dispatch(setCurrentTicket(ticket));
            setOpenTicketDescriptionDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseTicketDescriptionDialog = () => {
        setOpenTicketDescriptionDialog(false);
        dispatch(setCurrentTicket(null));
    };

    const handleEditTicketPriority = (ticket) => {
        if (canUpdateStatus) {
            dispatch(setCurrentTicket(ticket));
            setOpenEditTicketPriorityDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseEditTicketPriorityDialog = () => {
        setOpenEditTicketPriorityDialog(false);
        dispatch(setCurrentTicket(null));
    };

    const handleEditTicketStatus = (ticket) => {
        if (canUpdateStatus) {
            dispatch(setCurrentTicket(ticket));
            setOpenEditTicketStatusDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseEditTicketStatusDialog = () => {
        setOpenEditTicketStatusDialog(false);
        dispatch(setCurrentTicket(null));
    };

    const handleTicketUpdate = () => {
        dispatchAction({ type: "UPDATE" });
    };

    /* const handleTicketDelete = React.useCallback(
        (ticket) => {
            if (canDeleteTicket) {
                if (ticket && ticket[TICKET_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete ticket: ${ticket[TICKET_KEYS.SUBJECT]}?`,
                        onYes: () => {
                            hideDialog();
                            const ticketData = {
                                authcode: authToken,
                                [TICKET_KEYS.TICKET_ID]: ticket[TICKET_KEYS.ID],
                            };
                            dispatch(
                                deleteTicket(
                                    { ticketData: ticketData },
                                    () => {
                                        if (_isTicketsMounted.current)
                                            dispatchAction({ type: "DELETE" });
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid ticket",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, canDeleteTicket]
    ); */

    const statusColorObj = useMemo(() => {
        if (status) {
            return getTicketStatusColor(status);
        } else {
            return null;
        }
    }, [status]);
    const statusColor = statusColorObj ? statusColorObj.bgColor : "";

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchTickets && !ticketsFetched;
    const loadError = !isValidUserType || !inputIsValid || fetchError;

    return (
        <Grid container spacing={3.5}>
            {pageName && (
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
            )}
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !inputIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                One or more input is not valid!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && inputIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the tickets!
                            </Alert>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <HeadingStack sx={{ justifyContent: "end" }}>
                            <FmButton
                                startIcon="add"
                                type="button"
                                label="Add Ticket"
                                onClick={handleOpenAddTicketDialog}
                            />
                        </HeadingStack>
                    </Grid>
                    <Grid item md={canUpdatePriority ? 6 : 12} xs={12}>
                        <FmSearch
                            ariaLabel="Search tickets"
                            id="tickets_search"
                            placeholder="Search Tickets"
                            searchTerm={searchTerm}
                            filterSearch={ticketsState.searching}
                            onSearchChange={searchTermChangeHandler}
                        />
                    </Grid>
                    {canUpdatePriority && (
                        <Grid item md={6} xs={12}>
                            <FmSingleAutocomplete
                                id="priority-filter"
                                label="Filter by priority"
                                options={priorityList}
                                value={ticketsState.priority}
                                onChange={priorityFilterChangeHandler}
                            />
                        </Grid>
                    )}
                    {status && (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                }}
                            >
                                <AppBar position="static" color="inherit">
                                    <Tabs
                                        variant="fullWidth"
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                        value={status}
                                        aria-label="tickets tabs"
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: statusColor,
                                            },
                                        }}
                                        onChange={handleStatusChange}
                                    >
                                        {statusList.map((statusObj) => {
                                            const statusValue = statusObj.value;
                                            return (
                                                <Tab
                                                    key={`ticket-tab-${statusValue}`}
                                                    label={`Status: ${statusObj.label}`}
                                                    value={statusValue}
                                                    sx={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                    {...a11yProps(statusValue)}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                </AppBar>
                                {statusList.map((statusObj, statusIdx) => {
                                    const statusValue = statusObj.value;
                                    return (
                                        <TicketsTabPanel
                                            key={`ticket-tabpanel-${statusValue}`}
                                            value={status}
                                            index={statusIdx}
                                            status={statusValue}
                                        >
                                            {headCells.length > 0 ? (
                                                <TicketsTable
                                                    canAssignFmUsers={
                                                        canAssignFmUsers
                                                    }
                                                    /* canDeleteTicket={
                                                            canDeleteTicket
                                                        } */
                                                    canUpdatePriority={
                                                        canUpdatePriority
                                                    }
                                                    canUpdateStatus={
                                                        canUpdateStatus
                                                    }
                                                    canReadDescription={
                                                        canReadDescription
                                                    }
                                                    columnsToShow={
                                                        columnsToShow
                                                    }
                                                    headCells={headCells}
                                                    order={ticketsState.order}
                                                    page={ticketsState.page}
                                                    rowsPerPage={
                                                        ticketsState.rowsPerPage
                                                    }
                                                    sort={ticketsState.sort}
                                                    tickets={tickets}
                                                    ticketCounts={ticketCounts}
                                                    ticketsFetched={
                                                        ticketsFetched
                                                    }
                                                    handlePageChange={
                                                        handlePageChange
                                                    }
                                                    handleRowsPerPageChange={
                                                        handleRowsPerPageChange
                                                    }
                                                    onAssignFmUsers={
                                                        handleOpenAssignFmUsersDialog
                                                    }
                                                    onEditPriority={
                                                        handleEditTicketPriority
                                                    }
                                                    onEditStatus={
                                                        handleEditTicketStatus
                                                    }
                                                    /* onDelete={
                                                            handleTicketDelete
                                                        } */
                                                    onReadDescription={
                                                        handleReadTicketDescription
                                                    }
                                                    onSort={handleSort}
                                                />
                                            ) : (
                                                <Alert
                                                    variant="outlined"
                                                    severity="error"
                                                >
                                                    There was a problem in
                                                    rendering the table!
                                                </Alert>
                                            )}
                                        </TicketsTabPanel>
                                    );
                                })}
                            </Box>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    {openAddTicketDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddTicketDialog
                                fetchOnInsert={true}
                                open={openAddTicketDialog}
                                onClose={handleCloseAddTicketDialog}
                                onDispatchAction={dispatchAction}
                            />
                        </Suspense>
                    )}
                    {canReadDescription && openTicketDescriptionDialog && (
                        <TicketDescriptionDialog
                            currentTicket={currentTicket}
                            open={openTicketDescriptionDialog}
                            onClose={handleCloseTicketDescriptionDialog}
                        />
                    )}
                    {canAssignFmUsers && openAssignFmUsersDialog && (
                        <AssignFmUsersDialog
                            currentTicket={currentTicket}
                            open={openAssignFmUsersDialog}
                            setGlobalState={true}
                            onClose={handleCloseAssignFmUsersDialog}
                        />
                    )}
                    {canUpdatePriority && openEditTicketPriorityDialog && (
                        <EditTicketPriorityDialog
                            currentTicket={currentTicket}
                            open={openEditTicketPriorityDialog}
                            setGlobalState={true}
                            showAlert={true}
                            onClose={handleCloseEditTicketPriorityDialog}
                        />
                    )}
                    {canUpdateStatus && openEditTicketStatusDialog && (
                        <EditTicketStatusDialog
                            currentTicket={currentTicket}
                            open={openEditTicketStatusDialog}
                            onClose={handleCloseEditTicketStatusDialog}
                            onStatusUpdate={handleTicketUpdate}
                        />
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default Tickets;
