import React from "react";
import { PatternFormat } from "react-number-format";
import PropTypes from "prop-types";

const FormattedPhoneNoTxt = ({ extension, ...restProps }) => {
    let format = "(###) ###-####";
    if (extension && extension.length > 0) {
        const extensionFormat = extension
            .split("")
            .map(() => "#")
            .join("");
        format = `${format}. Ext (${extensionFormat})`;
    }

    return (
        <PatternFormat
            {...restProps}
            allowEmptyFormatting={false}
            displayType="text"
            format={format}
            mask="_"
            valueIsNumericString
        />
    );
};

FormattedPhoneNoTxt.propTypes = {
    extension: PropTypes.string,
    onChange: PropTypes.func,
};

FormattedPhoneNoTxt.defaultProps = {
    extension: "",
    onChange: () => {},
};

export default FormattedPhoneNoTxt;
