import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { getAllCompanies, getCompany } from "../../redux/actions/companies";
import {
    deleteSpecial,
    getFilteredSpecials,
    setCurrentSpecial,
} from "../../redux/actions/specials";
import CompaniesFilter from "../../shared/CompaniesFilter";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    EMPTY_PARAM_VALUE,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    SPECIAL_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import AddEditFlyerDialog from "./AddEditFlyerDialog";
import AddEditSpecialDialog from "./AddEditSpecialDialog";
import SpecialDescriptionDialog from "../special/SpecialDescriptionDialog";
import SpecialsTable from "./SpecialsTable";

const pageName = "Specials";

const allHeadCells = [
    {
        id: SPECIAL_KEYS.TITLE,
        label: "Title",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "40%",
    },
    {
        id: SPECIAL_KEYS.COMPANY_NAME,
        label: "Company",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "30%",
    },
    {
        id: SPECIAL_KEYS.CREATED_AT,
        label: "Created",
        show: true,
        sortColumn: false,
        useColumn: true,
        width: "15%",
    },
];

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, deleted: true };
        }
    }

    return { inserted: false, deleted: false };
};

const SPECIALS_LIMIT = 10;

const INIT_SPECIALS_STATE = {
    companies: [],
    companiesError: "",
    companyId: "",
    fetchSpecials: false,
    filtering: false,
    firstLoad: true,
    inputIsValid: false,
    order: SORT_ORDER_DESC,
    page: 0,
    rowsPerPage: SPECIALS_LIMIT,
    sort: SPECIAL_KEYS.CREATED_AT,
    sorting: false,
    specialsFetched: true,
};

const specialsLocalReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return { ...state, fetchSpecials: true, specialsFetched: false };
        } else if (action.type === "FILTER_BY_COMPANIES") {
            return {
                ...state,
                companiesError: "",
                fetchSpecials: true,
                filtering: true,
                page: 0,
                specialsFetched: false,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                companiesError: "",
                fetchSpecials: false,
                filtering: false,
                page: 0,
                rowsPerPage: SPECIALS_LIMIT,
                sorting: false,
                specialsFetched: true,
            };
        } else if (action.type === "SET_COMPANIES") {
            return { ...state, companies: action.companies };
        } else if (action.type === "SET_COMPANIES_AND_FETCH") {
            return {
                ...state,
                companies: action.companies,
                companiesError: "",
                fetchSpecials: true,
                page: 0,
                specialsFetched: false,
            };
        } else if (action.type === "SET_COMPANY_ERROR") {
            return {
                ...state,
                companies: [],
                companiesError: action.companiesError,
                fetchSpecials: false,
            };
        } else if (action.type === "SET_COMPANY_ID") {
            const updatedCompanyId = action.companyId;
            return {
                ...state,
                companyId: updatedCompanyId,
                inputIsValid: !!updatedCompanyId,
            };
        } else if (action.type === "SET_LIMIT_AND_FETCH") {
            return {
                ...state,
                fetchSpecials: true,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                specialsFetched: false,
            };
        } else if (action.type === "SET_PAGE_AND_FETCH") {
            return {
                ...state,
                fetchSpecials: true,
                page: action.page,
                specialsFetched: false,
            };
        } else if (action.type === "SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                fetchSpecials: true,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                page: 0,
                sort: updatedSort,
                sorting: true,
                specialsFetched: false,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchSpecials: false,
                filtering: false,
                firstLoad: false,
                sorting: false,
                specialsFetched: true,
            };
        }
    }

    return INIT_SPECIALS_STATE;
};

const Specials = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isSpecialsMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { specials, specialCounts, currentSpecial } = useSelector(
        ({ specialsReducer }) => specialsReducer
    );

    const [params] = useSearchParams();
    const companyIdParam = params.get("company") || "";

    const [isLoading, setIsLoading] = useState(false);
    const [fetchAllCompanies, setFetchAllCompanies] = useState(false);
    const [allCompaniesFetched, setAllCompaniesFetched] = useState(false);
    const [allCompanies, setAllCompanies] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [headCells, setHeadCells] = useState([]);
    const [openAddEditFlyerDialog, setOpenAddEditFlyerDialog] = useState(false);
    const [openAddEditSpecialDialog, setOpenAddEditSpecialDialog] =
        useState(false);
    const [openSpecialDescriptionDialog, setOpenSpecialDescriptionDialog] =
        useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
    });

    const [specialsState, dispatchSpecialsAction] = useReducer(
        specialsLocalReducer,
        INIT_SPECIALS_STATE
    );

    const { companiesError, companyId, inputIsValid } = specialsState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canAddEditSpecials = isAdminUser || isClientUser || isFmUser;
    const canDeleteSpecials = isAdminUser || isClientUser || isFmUser;
    const canFetchAllCompanies = isAdminUser || isFmUser;
    const canFetchCompany = isAdminUser || isFmUser;
    const canUploadFlyers = isAdminUser || isClientUser || isFmUser;
    const canViewDescription = isAdminUser || isClientUser || isFmUser;
    const canViewSpecials = isAdminUser || isClientUser || isFmUser;

    useEffect(() => {
        return () => {
            _isSpecialsMounted.current = false;
            dispatch(setCurrentSpecial(null));
        };
    }, [dispatch]);

    /* Setup table columns */
    useEffect(() => {
        if (isAdminUser || isFmUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    return { ...headCell, show: true, sortColumn: true };
                })
            );
        } else if (isClientUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    if ([SPECIAL_KEYS.COMPANY_NAME].includes(headCell.id)) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true, sortColumn: true };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isClientUser, isFmUser]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Set the company ID query parameter */
    useEffect(() => {
        if (isAdminUser || isFmUser) {
            dispatchSpecialsAction({
                type: "SET_COMPANY_ID",
                companyId: companyIdParam || EMPTY_PARAM_VALUE,
            });
        } else if (isClientUser) {
            dispatchSpecialsAction({
                type: "SET_COMPANY_ID",
                companyId: EMPTY_PARAM_VALUE,
            });
        }
    }, [companyIdParam, isAdminUser, isClientUser, isFmUser]);

    /* Start loading if company ID is valid */
    useEffect(() => {
        if (inputIsValid) {
            setIsLoading(true);
        }
    }, [inputIsValid]);

    /* Fetch all companies */
    useEffect(() => {
        if (isLoading) {
            setFetchAllCompanies(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchAllCompanies) {
            if (canFetchAllCompanies) {
                const fetchData = (payload) => {
                    return (dispatch, getState) => {
                        return dispatch(
                            getAllCompanies(payload, (fetchedData) => {
                                if (isActive) {
                                    setFetchAllCompanies(false);
                                    const fetchedCompanies =
                                        fetchedData?.companies || [];
                                    setAllCompanies([...fetchedCompanies]);
                                    setAllCompaniesFetched(true);
                                }
                            })
                        );
                    };
                };

                const companyData = { authcode: authToken };
                const promise = dispatch(
                    fetchData({ companyData: companyData })
                );
                promise.catch((error) => {
                    if (isActive) {
                        setFetchAllCompanies(false);
                        setAllCompanies([]);
                        setAllCompaniesFetched(false);
                        setIsLoading(false);
                    }
                });
            } else {
                setFetchAllCompanies(false);
                setAllCompaniesFetched(true);
            }
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, canFetchAllCompanies, fetchAllCompanies]);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (allCompaniesFetched) {
            if (canFetchCompany && companyId !== EMPTY_PARAM_VALUE) {
                const fetchData = (payload) => {
                    return (dispatch, getState) => {
                        return dispatch(
                            getCompany(payload, (fetchedCompany) => {
                                if (isActive) {
                                    dispatchSpecialsAction({
                                        type: "SET_COMPANIES_AND_FETCH",
                                        companies: fetchedCompany
                                            ? [fetchedCompany]
                                            : [],
                                    });
                                }
                            })
                        );
                    };
                };

                const companyData = {
                    authcode: authToken,
                    [COMPANY_KEYS.COMPANY_ID]: companyId,
                };

                const promise = dispatch(
                    fetchData({ companyData: companyData })
                );
                promise.catch((error) => {
                    console.log({ error });
                    if (isActive) {
                        dispatchSpecialsAction({
                            type: "SET_COMPANY_ERROR",
                            companiesError: `There was a problem in fetching the company details. ${
                                error?.message || ""
                            }`,
                        });
                        setIsLoading(false);
                    }
                });
            } else {
                dispatchSpecialsAction({ type: "FETCH" });
            }
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, canFetchCompany, companyId, allCompaniesFetched]);

    /* Fetch filtered specials list */
    useEffect(() => {
        let isActive = true;

        if (specialsState.fetchSpecials) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredSpecials(payload, () => {
                            if (isActive) {
                                dispatchSpecialsAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const specialData = {
                authcode: authToken,
                page: specialsState.page + 1,
                rows_per_page: specialsState.rowsPerPage,
                sort: specialsState.sort,
                order: specialsState.order,
            };

            const filterCompanies = specialsState?.companies
                ? specialsState.companies
                : [];
            if (filterCompanies && filterCompanies.length > 0) {
                specialData[SPECIAL_KEYS.COMPANY_ID] = filterCompanies
                    .map((filterCompany) => filterCompany[COMPANY_KEYS.ID])
                    .join(",");
            }

            const payload = {
                specialData: specialData,
                fromAction: actionState,
            };

            if (!specialsState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    dispatchSpecialsAction({ type: "RESET" });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, actionState, authToken, specialsState]);

    /* Fetch the specials list again if a special is added or deleted */
    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            dispatchSpecialsAction({ type: "FETCH" });
        }
    }, [actionState]);

    const companyChangeHandler = (event, values, reason) => {
        if (["selectOption", "removeOption"].includes(reason)) {
            if (values && values.length > 0) {
                dispatchSpecialsAction({
                    type: "SET_COMPANIES",
                    companies: values,
                });
            } else {
                dispatchSpecialsAction({
                    type: "SET_COMPANIES",
                    companies: [],
                });
            }
        } else if (reason === "clear") {
            dispatchSpecialsAction({
                type: "SET_COMPANIES",
                companies: [],
            });
        }
    };

    const handleFilterByCompanies = () =>
        dispatchSpecialsAction({ type: "FILTER_BY_COMPANIES" });

    const handlePageChange = (event, newPage) => {
        dispatchSpecialsAction({ type: "SET_PAGE_AND_FETCH", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchSpecialsAction({
            type: "SET_LIMIT_AND_FETCH",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchSpecialsAction({
            type: "SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleOpenAddEditSpecialDialog = () => {
        if (canAddEditSpecials) {
            dispatch(setCurrentSpecial(null));
            setOpenAddEditSpecialDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleCloseAddEditSpecialDialog = () => {
        setOpenAddEditSpecialDialog(false);
        dispatch(setCurrentSpecial(null));
    };

    const handleInsertSpecial = () => dispatchAction({ type: "INSERT" });

    const handleCloseSpecialDescriptionDialog = () => {
        setOpenSpecialDescriptionDialog(false);
        dispatch(setCurrentSpecial(null));
    };

    const handleCloseAddEditFlyerDialog = () => {
        setOpenAddEditFlyerDialog(false);
        dispatch(setCurrentSpecial(null));
    };

    const handleEditSpecial = (special) => {
        if (canAddEditSpecials) {
            dispatch(setCurrentSpecial(special));
            setOpenAddEditSpecialDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handlespecialDelete = React.useCallback(
        (special) => {
            if (canDeleteSpecials) {
                if (special && special[SPECIAL_KEYS.ID]) {
                    showDialog({
                        variant: "confirm",
                        title: `Delete special: ${
                            special[SPECIAL_KEYS.TITLE]
                        }?`,
                        onYes: () => {
                            hideDialog();
                            const specialData = {
                                authcode: authToken,
                                [SPECIAL_KEYS.SPECIAL_ID]:
                                    special[SPECIAL_KEYS.ID],
                            };
                            dispatch(
                                deleteSpecial(
                                    { specialData: specialData },
                                    () => {
                                        if (_isSpecialsMounted.current) {
                                            dispatchAction({ type: "DELETE" });
                                        }
                                    },
                                    () => {}
                                )
                            );
                        },
                        onNo: hideDialog,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Invalid special",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: ACTION_AUTHORIZATION_ERROR,
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, canDeleteSpecials]
    );

    const handleViewDescription = (special) => {
        if (canViewDescription) {
            dispatch(setCurrentSpecial(special));
            setOpenSpecialDescriptionDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleUploadFlyers = (special) => {
        if (canUploadFlyers) {
            dispatch(setCurrentSpecial(special));
            setOpenAddEditFlyerDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const allCompaniesFetchSuccess = !fetchAllCompanies && allCompaniesFetched;

    const loadError =
        !isValidUserType ||
        !inputIsValid ||
        !allCompaniesFetchSuccess ||
        !!companiesError;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !inputIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    One or more parameters are not valid!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            inputIsValid &&
                            !allCompaniesFetchSuccess && (
                                <Grid item xs={12}>
                                    <Alert variant="outlined" severity="error">
                                        There was a problem in fetching the
                                        companies list!
                                    </Alert>
                                </Grid>
                            )}
                        {isValidUserType && !!companiesError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {companiesError}
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        {canAddEditSpecials && (
                            <Grid item xs={12}>
                                <HeadingStack sx={{ justifyContent: "end" }}>
                                    <FmButton
                                        startIcon="add"
                                        type="button"
                                        label="Add Special"
                                        onClick={handleOpenAddEditSpecialDialog}
                                    />
                                </HeadingStack>
                            </Grid>
                        )}
                        {canFetchAllCompanies && (
                            <Grid item xs={12}>
                                <CompaniesFilter
                                    disabled={companyId !== EMPTY_PARAM_VALUE}
                                    disableFilterButton={
                                        specialsState.filtering
                                    }
                                    id="company_id"
                                    label="Select Companies"
                                    options={allCompanies}
                                    value={specialsState.companies}
                                    onChange={companyChangeHandler}
                                    onFilterByCompanies={
                                        handleFilterByCompanies
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SpecialsTable
                                canAddEditSpecials={canAddEditSpecials}
                                canDeleteSpecials={canDeleteSpecials}
                                canUploadFlyers={canUploadFlyers}
                                canViewDescription={canViewDescription}
                                canViewSpecials={canViewSpecials}
                                columnsToShow={columnsToShow}
                                headCells={headCells}
                                order={specialsState.order}
                                page={specialsState.page}
                                rowsPerPage={specialsState.rowsPerPage}
                                sort={specialsState.sort}
                                specials={specials}
                                specialCounts={specialCounts}
                                specialsFetched={specialsState.specialsFetched}
                                handlePageChange={handlePageChange}
                                handleRowsPerPageChange={
                                    handleRowsPerPageChange
                                }
                                onEdit={handleEditSpecial}
                                onDelete={handlespecialDelete}
                                onSort={handleSort}
                                onUploadFlyers={handleUploadFlyers}
                                onViewDescription={handleViewDescription}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && (
                <React.Fragment>
                    {canAddEditSpecials && openAddEditSpecialDialog && (
                        <AddEditSpecialDialog
                            currentSpecial={currentSpecial}
                            open={openAddEditSpecialDialog}
                            setGlobalState={true}
                            onClose={handleCloseAddEditSpecialDialog}
                            onInsert={handleInsertSpecial}
                        />
                    )}
                    {canViewDescription && openSpecialDescriptionDialog && (
                        <SpecialDescriptionDialog
                            currentSpecial={currentSpecial}
                            open={openSpecialDescriptionDialog}
                            onClose={handleCloseSpecialDescriptionDialog}
                        />
                    )}
                    {canUploadFlyers && openAddEditFlyerDialog && (
                        <AddEditFlyerDialog
                            currentSpecial={currentSpecial}
                            open={openAddEditFlyerDialog}
                            setGlobalState={true}
                            onClose={handleCloseAddEditFlyerDialog}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Specials;
