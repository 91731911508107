import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";

const CustomAlert = ({
    children,
    colorType,
    customSx,
    severity,
    ...restProps
}) => {
    let sx = {};
    if (customSx) {
        sx = { ...sx, ...customSx };
    }
    if (colorType) {
        sx.borderColor = (theme) => theme.palette[severity][colorType];
        sx["& .MuiAlert-icon"] = {
            color: (theme) => theme.palette[severity][colorType],
        };
    } else if (severity) {
        sx.borderColor = (theme) => theme.palette[severity].main;
        sx["& .MuiAlert-icon"] = {
            color: (theme) => theme.palette[severity].main,
        };
    }

    return (
        <Alert severity={severity} {...restProps} sx={sx}>
            {children}
        </Alert>
    );
};

CustomAlert.propTypes = {
    colorType: PropTypes.string,
    customSx: PropTypes.object,
    severity: PropTypes.string,
};

CustomAlert.defaultProps = {
    colorType: "",
    customSx: null,
    severity: "",
};

export default CustomAlert;
