import React from "react";
import FmUser from "../pages/fmUser/FmUser";
import FMUsers from "../pages/fmUsers";
import Page from "@jumbo/shared/Page";

const fmUsersRoutes = [
    {
        path: "/fmUsers",
        element: <Page component={FMUsers} />,
    },
    {
        path: "/fmUser/:fmUserId/",
        element: <Page component={FmUser} />,
    },
];

export default fmUsersRoutes;
