export const sidebarTheme = {
    type: "dark",
    palette: {
        primary: {
            main: "#4B4B4B",
            light: "#818E94",
            dark: "#2D2F39",
            contrastText: "#FFF",
        },
        text: {
            primary: "#FFF",
            secondary: "#487AA3",
            disabled: "#A2B2C3",
        },
        nav: {
            action: {
                active: "#CA3E27",
                hover: "#222222",
            },
            background: {
                active: "transparent",
                hover: "#F5F5F5",
            },
            tick: {
                active: "#CA3E27",
                hover: "transparent",
            },
        },
        divider: "#DEE2E6",
        background: {
            paper: "#112434",
            default: "#112434",
        },
        action: {
            active: "#475259",
            hover: "#F5F7FA",
        },
    },
};
