import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FmSearch from "../../shared/FmSearch";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import { useDebounce } from "../../utils/commonHelper";
import { getCompanyStatusList } from "../../utils/appHelpers";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    COMPANY_ACTIVE_STATUS,
    COMPANY_KEYS,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import {
    archiveCompany,
    deleteCompany,
    getFilteredCompanies,
    restoreCompany,
    setCurrentCompany,
} from "../../redux/actions/companies";
import { getAllIndustries } from "../../redux/actions/industries";
import AddEditCompanyDialog from "./AddEditCompanyDialog";
import CompaniesTable from "./CompaniesTable";
import CompaniesTabPanel, { a11yProps } from "./CompaniesTabPanel";
import EditRenewalDateDialog from "./EditRenewalDateDialog";
const AddTicketDialog = lazy(() => import("../tickets/AddTicketDialog"));
const ManageSidebarDialog = lazy(() => import("./ManageSidebarDialog"));
const LocationsDialog = lazy(() => import("./LocationsDialog"));
const NotesDialog = lazy(() => import("./NotesDialog"));

const statusList = getCompanyStatusList();

const pageName = "Companies";

const allHeadCells = [
    {
        id: COMPANY_KEYS.NAME,
        label: "Company",
        show: false,
        sortColumn: true,
        useColumn: true,
        width: "19%",
    },
    {
        id: "actions",
        label: "Actions",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "11%",
    },
    {
        id: COMPANY_KEYS.EMAIL,
        label: "Email",
        show: false,
        sortColumn: true,
        useColumn: true,
        width: "14%",
    },
    {
        id: COMPANY_KEYS.PHONE_NO,
        label: "Phone",
        show: false,
        sortColumn: true,
        useColumn: true,
        width: "10%",
    },
    {
        id: COMPANY_KEYS.WEBSITE,
        label: "Website",
        show: false,
        sortColumn: true,
        useColumn: true,
        width: "14%",
    },
    {
        id: COMPANY_KEYS.INDUSTRY,
        label: "Industry",
        show: false,
        sortColumn: true,
        useColumn: true,
        width: "8%",
    },
    {
        id: COMPANY_KEYS.RENEWAL_DATE,
        label: "Renewal Date",
        show: false,
        sortColumn: true,
        useColumn: true,
        width: "12%",
    },
    {
        id: COMPANY_KEYS.ASSIGNED_AE,
        label: "Assigned AE",
        show: false,
        sortColumn: false,
        useColumn: true,
        width: "13%",
        textAlign: "center",
    },
];

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "ARCHIVE") {
            return {
                archived: true,
                inserted: false,
                deleted: false,
                restored: false,
            };
        } else if (action.type === "INSERT") {
            return {
                archived: false,
                inserted: true,
                deleted: false,
                restored: false,
            };
        } else if (action.type === "DELETE") {
            return {
                archived: false,
                inserted: false,
                deleted: true,
                restored: false,
            };
        } else if (action.type === "RESTORE") {
            return {
                archived: false,
                inserted: false,
                deleted: false,
                restored: true,
            };
        }
    }

    return {
        archived: false,
        inserted: false,
        deleted: false,
        restored: false,
    };
};

const filterReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH_PAGE") {
            return { ...state, fetchPage: true };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                page: 0,
                rowsPerPage: action.rowsPerPage,
                fetchPage: true,
            };
        } else if (action.type === "SET_PAGE") {
            return { ...state, page: action.page, fetchPage: true };
        } else if (action.type === "SET_SEARCH_AND_FETCH") {
            return {
                ...state,
                page: 0,
                search: action.search,
                filterFromSearch: true,
                fetchPage: true,
            };
        } else if (action.type === "SET_SORT_AND_FETCH") {
            return {
                ...state,
                sort: action.sort,
                order: action.order,
                fetchPage: true,
            };
        } else if (action.type === "SET_STATUS") {
            return { ...state, status: action.status };
        } else if (action.type === "SET_STATUS_AND_FETCH") {
            return {
                ...state,
                status: action.status,
                page: 0,
                fetchPage: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return { ...state, fetchPage: false, filterFromSearch: false };
        }
    }

    return {
        page: 0,
        rowsPerPage: 10,
        sort: COMPANY_KEYS.NAME,
        order: SORT_ORDER_ASC,
        fetchPage: false,
        search: "",
        filterFromSearch: false,
        status: COMPANY_ACTIVE_STATUS,
    };
};

const Companies = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isCompaniesMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { companies, companyCounts, currentCompany } = useSelector(
        ({ companiesReducer }) => companiesReducer
    );

    const [allIndustries, setAllIndustries] = useState([]);
    const [headCells, setHeadCells] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [companiesFetched, setCompaniesFetched] = useState(true);
    const [openAddEditCompanyDialog, setOpenAddEditCompanyDialog] =
        useState(false);
    const [openAddTicketDialog, setOpenAddTicketDialog] = useState(false);
    const [openEditRenewalDateDialog, setOpenEditRenewalDateDialog] =
        useState(false);
    const [openLocationsDialog, setOpenLocationsDialog] = useState(false);
    const [openManageSidebarDialog, setOpenManageSidebarDialog] =
        useState(false);
    const [openNotesDialog, setOpenNotesDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        archived: false,
        inserted: false,
        deleted: false,
        restored: false,
    });

    const [filterActionState, dispatchFilterAction] = useReducer(
        filterReducer,
        {
            page: 0,
            rowsPerPage: 10,
            sort: COMPANY_KEYS.NAME,
            order: SORT_ORDER_ASC,
            fetchPage: false,
            search: "",
            filterFromSearch: false,
            status: COMPANY_ACTIVE_STATUS,
        }
    );

    const { fetchPage, status } = filterActionState;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    const canAddEditCompany = isAdminUser || isFmUser;
    const canAddTicket = isAdminUser || isFmUser;
    const canEditRenewalDate = isAdminUser || isFmUser;
    const canManageLocations = isAdminUser || isFmUser;
    const canManageNotes = isAdminUser || isFmUser;
    const canManageSidebar = isAdminUser;
    const canViewClientUsers = isAdminUser || isFmUser;
    const canViewDashboard = isAdminUser || isFmUser;
    const canViewGoogleSheet = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isCompaniesMounted.current = false;
            dispatch(setCurrentCompany(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    useEffect(() => {
        if (isLoading) {
            dispatchFilterAction({ type: "FETCH_PAGE" });
        }
    }, [isLoading]);

    /* Set table columns */
    useEffect(() => {
        /* Can manipulate this state to hide columns based on user type */
        if (isAdminUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    return { ...headCell, show: true };
                })
            );
        } else if (isFmUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    if ([COMPANY_KEYS.ASSIGNED_AE].includes(headCell.id)) {
                        return { ...headCell, show: false };
                    } else {
                        return { ...headCell, show: true };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isFmUser]);

    /* Set table columns that will be visible */
    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Fetch industries list if user can add/edit companies */
    useEffect(() => {
        let isActive = true;

        if (canAddEditCompany) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getAllIndustries(payload, (fetchedIndustries) => {
                            if (isActive) {
                                setAllIndustries(fetchedIndustries);
                            }
                        })
                    );
                };
            };

            const industryData = { authcode: authToken };
            const promise = dispatch(fetchData({ industryData: industryData }));
            promise.catch((error) => {
                if (isActive) {
                    setAllIndustries([]);
                }
            });
        } else {
            setAllIndustries([]);
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, canAddEditCompany]);

    /* Set companies filter */
    useEffect(() => {
        if (fetchPage) {
            setCompaniesFetched(false);
        }
    }, [fetchPage]);

    /* Fetch companies list */
    useEffect(() => {
        let isActive = true;

        if (!companiesFetched && filterActionState) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredCompanies(payload, () => {
                            if (isActive) {
                                setCompaniesFetched(true);
                                if (isLoading) setIsLoading(false);
                                dispatchFilterAction({ type: "STOP_FETCH" });
                                dispatchAction();
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                page: filterActionState.page + 1,
                rows_per_page: filterActionState.rowsPerPage,
                sort: filterActionState.sort,
                order: filterActionState.order,
                search: filterActionState?.search || "",
                [COMPANY_KEYS.STATUS]: filterActionState.status,
            };

            const promise = dispatch(
                fetchData({
                    companyData: companyData,
                    fromAction: actionState,
                    fetchStart:
                        !isLoading || !filterActionState.filterFromSearch,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setCompaniesFetched(true);
                    if (isLoading) setIsLoading(false);
                    dispatchFilterAction();
                    dispatchAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [
        dispatch,
        authToken,
        actionState,
        companiesFetched,
        filterActionState,
        isLoading,
    ]);

    /* Fetch companies list again if search term entered */
    useEffect(() => {
        if (debouncedSearchTerm || !isLoading) {
            dispatchFilterAction({
                type: "SET_SEARCH_AND_FETCH",
                search: debouncedSearchTerm,
            });
        }
    }, [debouncedSearchTerm, isLoading, dispatchFilterAction]);

    /* Fetch companies list again if a company is added, archived, deleted, or restored */
    useEffect(() => {
        if (
            actionState.archived ||
            actionState.deleted ||
            actionState.inserted ||
            actionState.restored
        ) {
            dispatchFilterAction({ type: "FETCH_PAGE" });
        }
    }, [actionState]);

    const handleOpenAddCompanyDialog = () => {
        setOpenAddEditCompanyDialog(true);
    };

    const handleCompanyInsert = () => dispatchAction({ type: "INSERT" });

    const handleCloseAddEditCompanyDialog = () => {
        setOpenAddEditCompanyDialog(false);
        dispatch(setCurrentCompany(null));
    };

    const handlePageChange = (event, newPage) => {
        dispatchFilterAction({ type: "SET_PAGE", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchFilterAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        const isAsc =
            filterActionState.sort === property &&
            filterActionState.order === SORT_ORDER_ASC;

        dispatchFilterAction({
            type: "SET_SORT_AND_FETCH",
            sort: property,
            order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
        });
    };

    const handleSearch = (event) => setSearchTerm(event.target.value);

    const handleAddTicket = (company) => {
        dispatch(setCurrentCompany(company));
        setOpenAddTicketDialog(true);
    };

    const handleCloseAddTicketDialog = () => {
        setOpenAddTicketDialog(false);
        dispatch(setCurrentCompany(null));
    };

    const handleStatusChange = (event, newValue) =>
        dispatchFilterAction({
            type: "SET_STATUS_AND_FETCH",
            status: newValue,
        });

    const handleCompanyEdit = (company) => {
        dispatch(setCurrentCompany(company));
        setOpenAddEditCompanyDialog(true);
    };

    const handleEditRenewalDate = (company) => {
        dispatch(setCurrentCompany(company));
        setOpenEditRenewalDateDialog(true);
    };

    const handleCloseEditRenewalDateDialog = () => {
        setOpenEditRenewalDateDialog(false);
        dispatch(setCurrentCompany(null));
    };

    const handleManageSidebar = (company) => {
        dispatch(setCurrentCompany(company));
        setOpenManageSidebarDialog(true);
    };

    const handleCloseManageSidebar = () => {
        setOpenManageSidebarDialog(false);
        dispatch(setCurrentCompany(null));
    };

    const handleManageNotes = (company) => {
        dispatch(setCurrentCompany(company));
        setOpenNotesDialog(true);
    };

    const handleCloseNotesDialog = () => {
        setOpenNotesDialog(false);
        dispatch(setCurrentCompany(null));
    };

    const handleManageLocations = (company) => {
        dispatch(setCurrentCompany(company));
        setOpenLocationsDialog(true);
    };

    const handleCloseLocationsDialog = () => {
        setOpenLocationsDialog(false);
        dispatch(setCurrentCompany(null));
    };

    const handleCompanyArchive = React.useCallback(
        (company) => {
            if (isValidUserType && company && company[COMPANY_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Archive company: ${company[COMPANY_KEYS.NAME]}?`,
                    onYes: () => {
                        hideDialog();
                        const companyData = {
                            authcode: authToken,
                            [COMPANY_KEYS.COMPANY_ID]: company[COMPANY_KEYS.ID],
                        };
                        dispatch(
                            archiveCompany(
                                { companyData: companyData },
                                () => {
                                    if (_isCompaniesMounted.current)
                                        dispatchAction({ type: "ARCHIVE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid company",
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, isValidUserType]
    );

    const handleCompanyRestore = React.useCallback(
        (company) => {
            if (isValidUserType && company && company[COMPANY_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Restore company: ${company[COMPANY_KEYS.NAME]}?`,
                    onYes: () => {
                        hideDialog();
                        const companyData = {
                            authcode: authToken,
                            [COMPANY_KEYS.COMPANY_ID]: company[COMPANY_KEYS.ID],
                        };
                        dispatch(
                            restoreCompany(
                                { companyData: companyData },
                                () => {
                                    if (_isCompaniesMounted.current)
                                        dispatchAction({ type: "RESTORE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid company",
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, isValidUserType]
    );

    const handleCompanyDelete = React.useCallback(
        (company) => {
            if (isValidUserType && company && company[COMPANY_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Delete company: ${company[COMPANY_KEYS.NAME]}?`,
                    onYes: () => {
                        hideDialog();
                        const companyData = {
                            authcode: authToken,
                            [COMPANY_KEYS.COMPANY_ID]: company[COMPANY_KEYS.ID],
                        };
                        dispatch(
                            deleteCompany(
                                { companyData: companyData },
                                () => {
                                    if (_isCompaniesMounted.current)
                                        dispatchAction({ type: "DELETE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid company",
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, isValidUserType]
    );

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                {pageName && (
                    <Grid item xs={12}>
                        <HeadingStack>
                            <PageTitle
                                type="v2"
                                component="h1"
                                title={pageName}
                            />
                            <Breadcrumb
                                pageNames={{
                                    genericName: pageName,
                                    specificName: pageName,
                                }}
                            />
                        </HeadingStack>
                    </Grid>
                )}
                {!isValidUserType && (
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <HeadingStack sx={{ justifyContent: "end" }}>
                        <FmButton
                            label="Add Company"
                            startIcon="add"
                            onClick={handleOpenAddCompanyDialog}
                        />
                    </HeadingStack>
                </Grid>
                <Grid item xs={12}>
                    <FmSearch
                        ariaLabel="Search companies"
                        id="companies_search"
                        placeholder="Search Companies"
                        searchTerm={searchTerm}
                        filterSearch={filterActionState.filterFromSearch}
                        onSearchChange={handleSearch}
                    />
                </Grid>
                {status && (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                            }}
                        >
                            <AppBar position="static" color="inherit">
                                <Tabs
                                    variant="fullWidth"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    value={status}
                                    aria-label="companies tabs"
                                    onChange={handleStatusChange}
                                >
                                    {statusList.map((statusObj) => {
                                        const statusValue = statusObj.value;
                                        return (
                                            <Tab
                                                key={`company-tab-${statusValue}`}
                                                label={statusObj.label}
                                                value={statusValue}
                                                sx={{
                                                    textTransform: "capitalize",
                                                }}
                                                {...a11yProps(statusValue)}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </AppBar>
                            {statusList.map((statusObj, statusIdx) => {
                                const statusValue = statusObj.value;
                                return (
                                    <CompaniesTabPanel
                                        key={`company-tabpanel-${statusValue}`}
                                        value={status}
                                        index={statusIdx}
                                        status={statusValue}
                                    >
                                        {headCells.length > 0 ? (
                                            <CompaniesTable
                                                canAddEditCompany={
                                                    canAddEditCompany
                                                }
                                                canAddTicket={canAddTicket}
                                                canEditRenewalDate={
                                                    canEditRenewalDate
                                                }
                                                canManageLocations={
                                                    canManageLocations
                                                }
                                                canManageNotes={canManageNotes}
                                                canManageSidebar={
                                                    canManageSidebar
                                                }
                                                canViewClientUsers={
                                                    canViewClientUsers
                                                }
                                                canViewDashboard={
                                                    canViewDashboard
                                                }
                                                canViewGoogleSheet={
                                                    canViewGoogleSheet
                                                }
                                                companies={companies}
                                                companyCounts={companyCounts}
                                                companiesFetched={
                                                    companiesFetched
                                                }
                                                columnsToShow={columnsToShow}
                                                headCells={headCells}
                                                order={filterActionState.order}
                                                page={filterActionState.page}
                                                rowsPerPage={
                                                    filterActionState.rowsPerPage
                                                }
                                                sort={filterActionState.sort}
                                                handlePageChange={
                                                    handlePageChange
                                                }
                                                handleRowsPerPageChange={
                                                    handleRowsPerPageChange
                                                }
                                                onAddTicket={handleAddTicket}
                                                onArchive={handleCompanyArchive}
                                                onDelete={handleCompanyDelete}
                                                onEdit={handleCompanyEdit}
                                                onEditRenewalDate={
                                                    handleEditRenewalDate
                                                }
                                                onManageLocations={
                                                    handleManageLocations
                                                }
                                                onManageNotes={
                                                    handleManageNotes
                                                }
                                                onManageSidebar={
                                                    handleManageSidebar
                                                }
                                                onRestore={handleCompanyRestore}
                                                onSort={handleSort}
                                            />
                                        ) : (
                                            <Alert
                                                variant="outlined"
                                                severity="error"
                                            >
                                                There was a problem in rendering
                                                the table!
                                            </Alert>
                                        )}
                                    </CompaniesTabPanel>
                                );
                            })}
                        </Box>
                    </Grid>
                )}
            </Grid>
            {companiesFetched && (
                <React.Fragment>
                    {openAddEditCompanyDialog && (
                        <AddEditCompanyDialog
                            allIndustries={allIndustries}
                            currentCompany={currentCompany}
                            open={openAddEditCompanyDialog}
                            setGlobalState={true}
                            onClose={handleCloseAddEditCompanyDialog}
                            onInsert={handleCompanyInsert}
                        />
                    )}
                    {openManageSidebarDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <ManageSidebarDialog
                                currentCompany={currentCompany}
                                open={openManageSidebarDialog}
                                onClose={handleCloseManageSidebar}
                            />
                        </Suspense>
                    )}
                    {openAddTicketDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <AddTicketDialog
                                currentCompany={currentCompany}
                                open={openAddTicketDialog}
                                onClose={handleCloseAddTicketDialog}
                            />
                        </Suspense>
                    )}
                    {openEditRenewalDateDialog && (
                        <EditRenewalDateDialog
                            currentCompany={currentCompany}
                            open={openEditRenewalDateDialog}
                            onClose={handleCloseEditRenewalDateDialog}
                        />
                    )}
                    {openNotesDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <NotesDialog
                                currentCompany={currentCompany}
                                open={openNotesDialog}
                                onClose={handleCloseNotesDialog}
                            />
                        </Suspense>
                    )}
                    {openLocationsDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <LocationsDialog
                                currentCompany={currentCompany}
                                open={openLocationsDialog}
                                onClose={handleCloseLocationsDialog}
                            />
                        </Suspense>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Companies;
