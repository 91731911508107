import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const a11yProps = (status) => {
    return {
        id: `company-client-users-tab-${status}`,
        "aria-controls": `company-client-users-tabpanel-${status}`,
    };
};

const CompanyCUTabPanel = (props) => {
    const { children, value, status, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== status}
            id={`company-client-users-tabpanel-${status}`}
            aria-labelledby={`company-client-users-tab-${status}`}
            {...other}
        >
            {value === status && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

CompanyCUTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

export default CompanyCUTabPanel;
