import {
    ADD_SURVEY,
    DELETE_SURVEY,
    EDIT_SURVEY,
    GET_SURVEY_COUNT,
    GET_SURVEYS,
    SET_SURVEY_DETAILS,
} from "../../utils/constants/actionTypes";
import { SURVEY_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    surveys: [],
    currentSurvey: null,
    surveyCounts: { [SURVEY_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SURVEYS: {
            return {
                ...state,
                surveys: action.payload,
            };
        }
        case GET_SURVEY_COUNT: {
            return {
                ...state,
                surveyCounts: action.payload,
            };
        }
        case SET_SURVEY_DETAILS: {
            return {
                ...state,
                currentSurvey: action.payload,
            };
        }
        case ADD_SURVEY: {
            return {
                ...state,
                surveys: [action.payload, ...state.surveys],
            };
        }
        case EDIT_SURVEY: {
            return {
                ...state,
                surveys: state.surveys.map((survey) =>
                    survey.id === action.payload.id ? action.payload : survey
                ),
            };
        }
        case DELETE_SURVEY: {
            return {
                ...state,
                surveys: state.surveys.filter(
                    (survey) => survey.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
