import {
    ADD_SERVICE,
    DELETE_SERVICE,
    EDIT_SERVICE,
    GET_SERVICE_COUNT,
    GET_SERVICES,
    SET_SERVICE_DETAILS,
} from "../../utils/constants/actionTypes";
import { SERVICE_KEYS } from "../../utils/constants/appData";

const INIT_STATE = {
    services: [],
    currentService: null,
    serviceCounts: { [SERVICE_KEYS.TOTAL]: "0" },
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SERVICES: {
            return {
                ...state,
                services: action.payload,
            };
        }
        case GET_SERVICE_COUNT: {
            return {
                ...state,
                serviceCounts: action.payload,
            };
        }
        case SET_SERVICE_DETAILS: {
            return {
                ...state,
                currentService: action.payload,
            };
        }
        case ADD_SERVICE: {
            return {
                ...state,
                services: [action.payload, ...state.services],
            };
        }
        case EDIT_SERVICE: {
            return {
                ...state,
                services: state.services.map((service) =>
                    service.id === action.payload.id ? action.payload : service
                ),
            };
        }
        case DELETE_SERVICE: {
            return {
                ...state,
                services: state.services.filter(
                    (service) => service.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
};

export default reducerFunc;
