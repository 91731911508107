import AlarmIcon from "@mui/icons-material/Alarm";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import EventIcon from "@mui/icons-material/Event";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FolderIcon from "@mui/icons-material/Folder";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import ForumIcon from "@mui/icons-material/Forum";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MenuIcon from "@mui/icons-material/Menu";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import NoteIcon from "@mui/icons-material/Note";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PollIcon from "@mui/icons-material/Poll";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import QuizIcon from "@mui/icons-material/Quiz";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SendIcon from "@mui/icons-material/Send";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import UpdateIcon from "@mui/icons-material/Update";
import UploadIcon from "@mui/icons-material/Upload";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import WebStoriesIcon from "@mui/icons-material/WebStories";

const muiIconsList = [
    { label: "AlarmIcon", icon: AlarmIcon },
    { label: "AlarmOnIcon", icon: AlarmOnIcon },
    { label: "AnnouncementIcon", icon: AnnouncementIcon },
    { label: "AppRegistrationIcon", icon: AppRegistrationIcon },
    { label: "ArchiveIcon", icon: ArchiveIcon },
    { label: "AssignmentTurnedInIcon", icon: AssignmentTurnedInIcon },
    { label: "BarChartIcon", icon: BarChartIcon },
    { label: "BusinessIcon", icon: BusinessIcon },
    { label: "CalendarMonthIcon", icon: CalendarMonthIcon },
    { label: "CancelIcon", icon: CancelIcon },
    { label: "CloseIcon", icon: CloseIcon },
    { label: "ConfirmationNumberIcon", icon: ConfirmationNumberIcon },
    { label: "DashboardIcon", icon: DashboardIcon },
    { label: "DeleteOutlineOutlinedIcon", icon: DeleteOutlineOutlinedIcon },
    { label: "DescriptionIcon", icon: DescriptionIcon },
    { label: "DoneIcon", icon: DoneIcon },
    { label: "EventIcon", icon: EventIcon },
    { label: "FileDownloadOutlinedIcon", icon: FileDownloadOutlinedIcon },
    { label: "FilePresentIcon", icon: FilePresentIcon },
    { label: "ForumIcon", icon: ForumIcon },
    { label: "FolderIcon", icon: FolderIcon },
    { label: "FolderDeleteIcon", icon: FolderDeleteIcon },
    { label: "GroupAddIcon", icon: GroupAddIcon },
    { label: "GroupsIcon", icon: GroupsIcon },
    { label: "ImportContactsIcon", icon: ImportContactsIcon },
    { label: "InfoIcon", icon: InfoIcon },
    { label: "LocationOnIcon", icon: LocationOnIcon },
    { label: "LockOpenIcon", icon: LockOpenIcon },
    { label: "LogoutIcon", icon: LogoutIcon },
    { label: "ManageAccountsIcon", icon: ManageAccountsIcon },
    { label: "MenuBookIcon", icon: MenuBookIcon },
    { label: "MenuIcon", icon: MenuIcon },
    { label: "MiscellaneousServicesIcon", icon: MiscellaneousServicesIcon },
    { label: "NoteIcon", icon: NoteIcon },
    { label: "NotificationsActiveIcon", icon: NotificationsActiveIcon },
    { label: "PendingActionsIcon", icon: PendingActionsIcon },
    { label: "PeopleIcon", icon: PeopleIcon },
    { label: "PersonIcon", icon: PersonIcon },
    { label: "PollIcon", icon: PollIcon },
    { label: "PriorityHighIcon", icon: PriorityHighIcon },
    { label: "QuizIcon", icon: QuizIcon },
    { label: "ReceiptIcon", icon: ReceiptIcon },
    { label: "RestoreFromTrashIcon", icon: RestoreFromTrashIcon },
    { label: "SaveAltIcon", icon: SaveAltIcon },
    { label: "SendIcon", icon: SendIcon },
    { label: "SsidChartIcon", icon: SsidChartIcon },
    { label: "ThumbDownIcon", icon: ThumbDownIcon },
    { label: "ThumbUpIcon", icon: ThumbUpIcon },
    { label: "UnarchiveIcon", icon: UnarchiveIcon },
    { label: "UpdateIcon", icon: UpdateIcon },
    { label: "UploadIcon", icon: UploadIcon },
    { label: "ViewKanbanIcon", icon: ViewKanbanIcon },
    { label: "ViewSidebarIcon", icon: ViewSidebarIcon },
    { label: "WebStoriesIcon", icon: WebStoriesIcon },
];

export default muiIconsList;
