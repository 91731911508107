import {
    getMeetingStatusList,
    getMeetingTimeRequestReceiverTypes,
    getMeetingTimeRequestStatusList,
} from "./appHelpers";
import {
    AUTH_USER_KEYS,
    MEETING_KEYS,
    MEETING_APPROVED_STATUS,
    MEETING_PENDING_STATUS,
    MEETING_REJECTED_STATUS,
    NEW_MEETING_TIME_EMPTY_STATUS,
    TIME_REQUEST_RECEIVER_CLIENT,
    TIME_REQUEST_RECEIVER_FM,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "./constants/appData";

const meetingStatusList = getMeetingStatusList();
const meetingTimeRequestReceiverTypes = getMeetingTimeRequestReceiverTypes();

const meetingTimeRequestStatusList = getMeetingTimeRequestStatusList();
const timeRequestStatusListValues = meetingTimeRequestStatusList.map(
    (tempStatusObj) => tempStatusObj.value
);

export const isMeetingActionAllowed = (
    action = "",
    authUser = {},
    meeting = {}
) => {
    if (!action || !authUser || !meeting) return false;

    /* Check if action is valid */
    if (
        ![
            "approve",
            "approveTimeRequest",
            "cancel",
            "edit",
            "handleNewTimeRequest",
            "reject",
            "rejectTimeRequest",
            "requestNewTime",
            "viewSentTimeRequest",
        ].includes(action)
    )
        return false;

    /* Check if authUser is valid */
    const authUserId =
        authUser && authUser?.[AUTH_USER_KEYS.ID] && authUser[AUTH_USER_KEYS.ID]
            ? authUser[AUTH_USER_KEYS.ID]
            : "";

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    if (!authUserId || !authUserType) return false;

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    if (!isValidUserType) return false;

    /* Check if meeting is valid */
    const meetingId =
        meeting && meeting?.[MEETING_KEYS.ID] && meeting[MEETING_KEYS.ID]
            ? meeting[MEETING_KEYS.ID]
            : "";

    if (!meetingId) return false;

    const clientId = meeting?.[MEETING_KEYS.CLIENT_ID] || "";
    const createdById = meeting?.[MEETING_KEYS.CREATED_BY_ID] || "";
    const createdByType = meeting?.[MEETING_KEYS.CREATED_BY_TYPE] || "";
    const fmUserId = meeting?.[MEETING_KEYS.FM_USER_ID] || "";

    const userIdsValid =
        clientId &&
        createdById &&
        [USER_TYPE_ADMIN_ID, USER_TYPE_AE_ID, USER_TYPE_CLIENT_ID].includes(
            createdByType
        ) &&
        fmUserId;
    if (!userIdsValid) return false;

    const status = meeting?.[MEETING_KEYS.STATUS] || "";

    const meetingStatusValues = meetingStatusList.map(
        (statusObj) => statusObj.value
    );
    if (!meetingStatusValues.includes(status)) return false;

    /* Check if time request is valid */
    const timeRequestId = meeting[MEETING_KEYS.REQUEST_ID];
    const timeRequestStatus = meeting[MEETING_KEYS.REQUEST_STATUS];

    let newTimeRequested = false;
    let newTimeNotRequested = false;

    if (timeRequestId) {
        if (timeRequestStatusListValues.includes(timeRequestStatus)) {
            newTimeRequested = true;
        } else {
            return false;
        }
    } else {
        if (timeRequestStatus === NEW_MEETING_TIME_EMPTY_STATUS) {
            newTimeNotRequested = true;
        } else {
            return false;
        }
    }

    const timeRequestReceiverType = meeting[MEETING_KEYS.REQUEST_RECEIVER_TYPE];
    if (!meetingTimeRequestReceiverTypes.includes(timeRequestReceiverType)) {
        return false;
    }

    /* Check if allowed to do the action */
    const authUserCreatedMeeting =
        createdById === authUserId && createdByType === authUserType;

    const adminCreatedMeeting = createdByType === USER_TYPE_ADMIN_ID;
    const clientCreatedMeeting = createdByType === USER_TYPE_CLIENT_ID;
    const fmCreatedMeeting = createdByType === USER_TYPE_AE_ID;

    let isAllowed = false;

    if (action === "approve") {
        if (isAdminUser || isFmUser) {
            isAllowed =
                clientCreatedMeeting &&
                timeRequestReceiverType === TIME_REQUEST_RECEIVER_FM &&
                status === MEETING_PENDING_STATUS &&
                newTimeNotRequested;
        } else if (isClientUser) {
            isAllowed =
                (adminCreatedMeeting || fmCreatedMeeting) &&
                timeRequestReceiverType === TIME_REQUEST_RECEIVER_CLIENT &&
                status === MEETING_PENDING_STATUS &&
                newTimeNotRequested;
        }
    } else if (action === "cancel") {
        isAllowed = [MEETING_PENDING_STATUS, MEETING_APPROVED_STATUS].includes(
            status
        );
    } else if (action === "edit") {
        isAllowed = authUserCreatedMeeting && MEETING_PENDING_STATUS === status;
    } else if (action === "handleNewTimeRequest") {
        if (isAdminUser || isFmUser) {
            isAllowed =
                (adminCreatedMeeting || fmCreatedMeeting) &&
                timeRequestReceiverType === TIME_REQUEST_RECEIVER_FM &&
                status === MEETING_PENDING_STATUS &&
                newTimeRequested;
        } else if (isClientUser) {
            isAllowed =
                clientCreatedMeeting &&
                timeRequestReceiverType === TIME_REQUEST_RECEIVER_CLIENT &&
                status === MEETING_PENDING_STATUS &&
                newTimeRequested;
        }
    } else if (action === "reject") {
        if (isAdminUser || isFmUser) {
            isAllowed =
                clientCreatedMeeting &&
                timeRequestReceiverType === TIME_REQUEST_RECEIVER_FM &&
                status === MEETING_PENDING_STATUS &&
                newTimeNotRequested;
        } else if (isClientUser) {
            isAllowed =
                (adminCreatedMeeting || fmCreatedMeeting) &&
                timeRequestReceiverType === TIME_REQUEST_RECEIVER_CLIENT &&
                status === MEETING_PENDING_STATUS &&
                newTimeNotRequested;
        }
    } else if (action === "requestNewTime") {
        if (isAdminUser || isFmUser) {
            isAllowed =
                clientCreatedMeeting &&
                status === MEETING_PENDING_STATUS &&
                newTimeNotRequested;
        } else if (isClientUser) {
            isAllowed =
                (adminCreatedMeeting || fmCreatedMeeting) &&
                status === MEETING_PENDING_STATUS &&
                newTimeNotRequested;
        }
    } else if (action === "viewSentTimeRequest") {
        if (isAdminUser || isFmUser) {
            isAllowed =
                clientCreatedMeeting &&
                [
                    MEETING_APPROVED_STATUS,
                    MEETING_PENDING_STATUS,
                    MEETING_REJECTED_STATUS,
                ].includes(status) &&
                newTimeRequested;
        } else if (isClientUser) {
            isAllowed =
                (adminCreatedMeeting || fmCreatedMeeting) &&
                [
                    MEETING_APPROVED_STATUS,
                    MEETING_PENDING_STATUS,
                    MEETING_REJECTED_STATUS,
                ].includes(status) &&
                newTimeRequested;
        }
    }

    return isAllowed;
};
