import React from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TableActionIcon from "../TableActionIcon";
import { formatBytes } from "../../../utils/appHelpers";

const ChatAcceptedFiles = ({ files, onRemove }) => {
    if (files && files.length > 0) {
        return (
            <List disablePadding>
                {files.map((file, fileIdx) => {
                    const fileName = file.name;
                    return (
                        <React.Fragment key={`${fileIdx}-${fileName}`}>
                            <ListItem
                                secondaryAction={
                                    <TableActionIcon
                                        edge="end"
                                        iconComponent="delete"
                                        label="remove file"
                                        tooltip="Remove File"
                                        onClick={() => onRemove(fileIdx)}
                                    />
                                }
                            >
                                {`${fileName} - ${formatBytes(file.size)}`}
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                })}
            </List>
        );
    } else {
        return null;
    }
};

ChatAcceptedFiles.propTypes = {
    files: PropTypes.array,
    onRemove: PropTypes.func,
};

ChatAcceptedFiles.defaultProps = {
    files: [],
    onRemove: () => {},
};

export default ChatAcceptedFiles;
