import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getSurvey } from "../../redux/actions/surveys";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import HTMLTypography from "../../shared/widgets/HTMLTypography";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SURVEY_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import TakeSurveyForm from "../takeSurvey/TakeSurveyForm";

const genericPageName = "Survey";

const Survey = () => {
    document.title = `${APP_NAME} - ${genericPageName}`;

    const _isSurveyMounted = useRef(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();

    const surveyId = params?.surveyId || "";

    const [isLoading, setIsLoading] = useState(false);
    const [surveyIdIsValid, setSurveyIdIsValid] = useState(false);
    const [surveyIsValid, setSurveyIsValid] = useState(false);
    const [fetchSurvey, setFetchSurvey] = useState(false);
    const [surveyFetched, setSurveyFetched] = useState(false);
    const [currentSurvey, setCurrentSurvey] = useState(null);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isSurveyMounted.current = false;
        };
    }, []);

    /* Check survey ID is valid */
    useEffect(() => {
        if (isValidUserType) {
            setSurveyIdIsValid(!!surveyId);
        }
    }, [isValidUserType, surveyId]);

    /* Start loading */
    useEffect(() => {
        if (surveyIdIsValid) {
            setIsLoading(true);
        }
    }, [surveyIdIsValid]);

    /* Fetch survey */
    useEffect(() => {
        if (isLoading) {
            setFetchSurvey(true);
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (fetchSurvey) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getSurvey(payload, (fetchedSurvey) => {
                            if (isActive) {
                                setFetchSurvey(false);
                                setSurveyFetched(true);
                                setCurrentSurvey(fetchedSurvey || null);
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const surveyData = {
                authcode: authToken,
                [SURVEY_KEYS.SURVEY_ID]: surveyId,
            };

            const promise = dispatch(fetchData({ surveyData: surveyData }));
            promise.catch((error) => {
                if (isActive) {
                    setFetchSurvey(false);
                    setSurveyFetched(false);
                    setCurrentSurvey(null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, fetchSurvey, surveyId]);

    /* Check if fetched survey is valid or not */
    useEffect(() => {
        if (
            surveyFetched &&
            currentSurvey &&
            currentSurvey?.[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] &&
            currentSurvey[SURVEY_KEYS.ID] === surveyId
        ) {
            setSurveyIsValid(true);
        } else {
            setSurveyIsValid(false);
        }
    }, [currentSurvey, surveyFetched, surveyId]);

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const fetchError = !fetchSurvey && !surveyFetched;
    const loadError =
        !isValidUserType || !surveyIdIsValid || fetchError || !surveyIsValid;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle
                        component="h1"
                        title={
                            surveyIsValid
                                ? currentSurvey[SURVEY_KEYS.NAME]
                                : "Invalid Survey!"
                        }
                        type="v2"
                    />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName: surveyIsValid
                                ? currentSurvey[SURVEY_KEYS.NAME]
                                : genericPageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {loadError && (
                <React.Fragment>
                    {!isValidUserType && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && !surveyIdIsValid && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                Invalid survey!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType && surveyIdIsValid && fetchError && (
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="error">
                                There was a problem in fetching the survey!
                            </Alert>
                        </Grid>
                    )}
                    {isValidUserType &&
                        surveyIdIsValid &&
                        !fetchError &&
                        !surveyIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    Invalid survey details!
                                </Alert>
                            </Grid>
                        )}
                </React.Fragment>
            )}
            {!loadError && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <HeadingStack sx={{ justifyContent: "end" }}>
                            <FmButton
                                startIcon="edit"
                                type="button"
                                component={RouterLink}
                                label="Edit Survey"
                                to={`/survey/${
                                    currentSurvey[SURVEY_KEYS.ID]
                                }/edit`}
                            />
                        </HeadingStack>
                    </Grid>
                    <Grid item xs={12}>
                        <HTMLTypography
                            component="span"
                            variant="h5"
                            content={currentSurvey[SURVEY_KEYS.DESCRIPTION]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TakeSurveyForm currentSurvey={currentSurvey} />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default Survey;
