import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
    getCompany,
    getCompanyAllFmUsers,
} from "../../redux/actions/companies";
import { assignFmUsers } from "../../redux/actions/tickets";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import {
    AUTH_USER_KEYS,
    COMPANY_KEYS,
    FM_USER_KEYS,
    TICKET_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkAssignedFmUsersValid } from "../../utils/validationHelpers";

const AssignFmUsersDialog = ({
    currentTicket,
    open,
    setGlobalState,
    setLocalState,
    onAssign,
    onClose,
}) => {
    const _isMountedAssignFmUsersDialog = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [ticketIsValid, setTicketIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [companyFetched, setCompanyFetched] = useState(false);
    const [company, setCompany] = useState(null);
    const [companyIsValid, setCompanyIsValid] = useState(false);
    const [allFmUsers, setAllFmUsers] = useState([]);
    const [ticketFmUsersValid, setTicketFmUsersValid] = useState(false);
    const [ticketFmUsers, setTicketFmUsers] = useState([]);
    const [ticketFmUsersIds, setTicketFmUsersIds] = useState([]);
    const [ticketFmUsersError, setTicketFmUsersError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isValidUserType = isAdminUser;

    useEffect(() => {
        return () => {
            _isMountedAssignFmUsersDialog.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setTicketIsValid(false);
            setIsLoading(false);
            setCompanyFetched(false);
            setCompany(null);
            setCompanyIsValid(false);
            setAllFmUsers([]);
            setTicketFmUsersValid(false);
            setTicketFmUsers([]);
            setTicketFmUsersIds([]);
            setTicketFmUsersError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    /* Check if ticket is valid */
    useEffect(() => {
        if (isValidUserType) {
            if (
                currentTicket &&
                currentTicket?.[TICKET_KEYS.ID] &&
                currentTicket[TICKET_KEYS.ID]
            ) {
                setTicketIsValid(true);
            }
        }
    }, [currentTicket, isValidUserType]);

    /* Start loading */
    useEffect(() => {
        if (ticketIsValid) {
            setIsLoading(true);
        }
    }, [ticketIsValid]);

    /* Fetch company details */
    useEffect(() => {
        let isActive = true;

        if (ticketIsValid && isLoading) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompany(payload, (fetchedCompany) => {
                            if (isActive) {
                                setCompany(fetchedCompany || null);
                                setCompanyFetched(true);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]:
                    currentTicket[TICKET_KEYS.COMPANY_ID],
            };

            const promise = dispatch(
                fetchData({ companyData: companyData, fetchStart: true })
            );
            promise.catch((error) => {
                if (isActive) {
                    setCompany(null);
                    setCompanyFetched(false);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, currentTicket, isLoading, ticketIsValid]);

    /* Check if company is valid */
    useEffect(() => {
        if (companyFetched) {
            if (
                company &&
                company?.[COMPANY_KEYS.ID] &&
                company[COMPANY_KEYS.ID]
            ) {
                setCompanyIsValid(true);
            }
        }
    }, [company, companyFetched]);

    /* Fetch all FM Users of the company */
    useEffect(() => {
        let isActive = true;

        if (companyIsValid) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getCompanyAllFmUsers(payload, (fetchedFmUsers) => {
                            if (isActive) {
                                setAllFmUsers(fetchedFmUsers || []);
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const companyData = {
                authcode: authToken,
                [COMPANY_KEYS.COMPANY_ID]: company[COMPANY_KEYS.ID],
            };
            const promise = dispatch(
                fetchData({
                    companyData: companyData,
                })
            );
            promise.catch((error) => {
                if (isActive) {
                    setAllFmUsers([]);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, authToken, companyIsValid, company]);

    /* Check if the FM Users assigned to ticket are present in company's FM Users list */
    useEffect(() => {
        if (allFmUsers && allFmUsers.length > 0) {
            const ticketFmUsersIds = currentTicket[TICKET_KEYS.FM_USERS_IDS];
            const ticketFmUsersIdsArray = ticketFmUsersIds
                ? ticketFmUsersIds.split(",")
                : [];
            if (ticketFmUsersIdsArray.length > 0) {
                const ticketFmUsers = allFmUsers.filter((tempFmUser) =>
                    ticketFmUsersIdsArray.includes(tempFmUser[FM_USER_KEYS.ID])
                );
                if (ticketFmUsers.length === ticketFmUsersIdsArray.length) {
                    setTicketFmUsersValid(true);
                }
            }
        }
    }, [allFmUsers, currentTicket]);

    /* Set FM Users currently assigned to ticket */
    useEffect(() => {
        if (ticketFmUsersValid) {
            const ticketFmUsersIds = currentTicket[TICKET_KEYS.FM_USERS_IDS];
            const ticketFmUsersIdsArray = ticketFmUsersIds.split(",");
            const ticketFmUsers = allFmUsers.filter((tempFmUser) =>
                ticketFmUsersIdsArray.includes(tempFmUser[FM_USER_KEYS.ID])
            );
            setTicketFmUsers([...ticketFmUsers]);
        }
    }, [allFmUsers, currentTicket, ticketFmUsersValid]);

    /* Set Ids of the Fm Users */
    useEffect(() => {
        if (ticketFmUsers) {
            setTicketFmUsersIds(
                ticketFmUsers.map((fmUser) => fmUser[FM_USER_KEYS.ID])
            );
        } else {
            setTicketFmUsersIds("");
        }
    }, [ticketFmUsers]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case TICKET_KEYS.FM_USERS_IDS:
                        setTicketFmUsersError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const fmUsersChangeHandler = (event, newValue) => {
        setTicketFmUsersError("");

        const fmUserValidationResult = checkAssignedFmUsersValid(
            newValue,
            allFmUsers
        );
        if (fmUserValidationResult.status) {
            if (fmUserValidationResult.status === "false") {
                setTicketFmUsers([]);
                setTicketFmUsersError(fmUserValidationResult.msg);
            } else {
                setTicketFmUsers(newValue);
            }
        } else {
            setTicketFmUsers([]);
            setTicketFmUsersError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const fmUserValidationResult = checkAssignedFmUsersValid(
            ticketFmUsers,
            allFmUsers
        );
        if (fmUserValidationResult.status) {
            if (fmUserValidationResult.status === "false") {
                formIsValid = false;
                setTicketFmUsersError(fmUserValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setTicketFmUsersError(VALIDATION_FAILED);
        }

        if (formIsValid) {
            const ticketData = {
                authcode: authToken,
                [TICKET_KEYS.TICKET_ID]: currentTicket[TICKET_KEYS.ID],
                [TICKET_KEYS.FM_USERS_IDS]: ticketFmUsersIds.join(","),
            };

            dispatch(
                assignFmUsers(
                    {
                        ticketData: ticketData,
                        setGlobalState: setGlobalState,
                    },
                    (updatedTicket) => {
                        if (_isMountedAssignFmUsersDialog.current) {
                            if (setLocalState) onAssign(updatedTicket);
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isMountedAssignFmUsersDialog.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    const companyFmNotFoundMsg =
        companyIsValid && allFmUsers.length === 0
            ? `No users found for ${company[COMPANY_KEYS.NAME]}!`
            : "";

    const errorOnLoading =
        !isValidUserType ||
        !ticketIsValid ||
        !companyIsValid ||
        !ticketFmUsersValid;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitleClose
                title={
                    isLoading
                        ? "Loading..."
                        : errorOnLoading
                        ? "There is a problem"
                        : `Assign users to ${
                              currentTicket[TICKET_KEYS.SUBJECT]
                          }`
                }
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {!isLoading && errorOnLoading && (
                    <Grid container spacing={2}>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {ACTION_AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !ticketIsValid && (
                            <Grid item xs={12}>
                                <Alert severity="error">Invalid ticket!</Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            ticketIsValid &&
                            !companyIsValid && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        Invalid ticket company!
                                    </Alert>
                                </Grid>
                            )}
                        {isValidUserType &&
                            ticketIsValid &&
                            companyIsValid &&
                            !ticketFmUsersValid && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        One more user(s), currently assigned to
                                        the ticket, are not valid!
                                    </Alert>
                                </Grid>
                            )}
                    </Grid>
                )}
                {!isLoading && !errorOnLoading && (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <form onSubmit={handleFormSubmit}>
                                <Grid container spacing={2}>
                                    {miscellaneousErrors &&
                                        miscellaneousErrors.length > 0 && (
                                            <Grid item xs={12}>
                                                {miscellaneousErrors.map(
                                                    (
                                                        miscellaneousError,
                                                        idx
                                                    ) => (
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            color="error"
                                                            gutterBottom
                                                            key={`misc-error-${idx}`}
                                                        >
                                                            {miscellaneousError}
                                                        </Typography>
                                                    )
                                                )}
                                            </Grid>
                                        )}
                                    <Grid item xs={12}>
                                        <FormControl fullWidth sx={{ mt: 1 }}>
                                            <Autocomplete
                                                multiple
                                                disablePortal
                                                fullWidth
                                                filterSelectedOptions
                                                id="fm_users"
                                                options={allFmUsers}
                                                value={ticketFmUsers}
                                                getOptionLabel={(option) => {
                                                    return option &&
                                                        option[FM_USER_KEYS.ID]
                                                        ? option[
                                                              FM_USER_KEYS.NAME
                                                          ]
                                                        : "";
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    newValue
                                                ) => {
                                                    return (
                                                        option[
                                                            FM_USER_KEYS.ID
                                                        ] ===
                                                        newValue[
                                                            FM_USER_KEYS.ID
                                                        ]
                                                    );
                                                }}
                                                onChange={fmUsersChangeHandler}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Select user(s)"
                                                        error={
                                                            ticketFmUsersError !==
                                                                "" ||
                                                            companyFmNotFoundMsg !==
                                                                ""
                                                        }
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <li
                                                        {...props}
                                                        key={
                                                            option[
                                                                FM_USER_KEYS.ID
                                                            ]
                                                        }
                                                    >
                                                        {
                                                            option[
                                                                FM_USER_KEYS
                                                                    .NAME
                                                            ]
                                                        }
                                                    </li>
                                                )}
                                            />
                                            {(ticketFmUsersError ||
                                                companyFmNotFoundMsg) && (
                                                <FormHelperText
                                                    error
                                                    id="fm_users-error"
                                                >
                                                    {ticketFmUsersError ||
                                                        companyFmNotFoundMsg}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            sx={(theme) => ({
                                                p: theme.spacing(2, 3),
                                                width: "25%",
                                                [theme.breakpoints.down("md")]:
                                                    {
                                                        p: theme.spacing(
                                                            1.5,
                                                            2.5
                                                        ),
                                                        width: "100%",
                                                    },
                                            })}
                                        >
                                            Update Ticket
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

AssignFmUsersDialog.propTypes = {
    currentTicket: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    setLocalState: PropTypes.bool,
    onAssign: PropTypes.func,
    onClose: PropTypes.func,
};

AssignFmUsersDialog.defaultProps = {
    currentTicket: null,
    open: false,
    setGlobalState: false,
    setLocalState: false,
    onAssign: () => {},
    // onClose: () => {},
};

export default AssignFmUsersDialog;
