import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FmSearch from "../../shared/FmSearch";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    SERVICE_KEYS,
    SORT_ORDER_ASC,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import { useDebounce } from "../../utils/commonHelper";
import {
    getFilteredServicesLocalState,
    requestService,
} from "../../redux/actions/services";
import ServicesList from "./ServicesList";
import ServiceDescriptionDialog from "./ServiceDescriptionDialog";

const pageName = "Additional Services";

const SERVICES_LIMIT = 16;

const INIT_SERVICES_STATE = {
    fetchServices: false,
    filtering: false,
    firstLoad: true,
    loadMore: false,
    order: SORT_ORDER_ASC,
    page: 0,
    rowsPerPage: SERVICES_LIMIT,
    search: "",
    searching: false,
    services: [],
    servicesFetched: false,
    sort: SERVICE_KEYS.NAME,
    totals: 0,
};

const servicesReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                fetchServices: true,
                servicesFetched: false,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                fetchServices: false,
                filtering: false,
                firstLoad: false,
                loadMore: false,
                page: 0,
                searching: false,
                services: [],
                servicesFetched: false,
                totals: 0,
            };
        } else if (action.type === "SEARCH_AND_FETCH") {
            if (state.firstLoad) {
                return { ...state };
            } else {
                return {
                    ...state,
                    fetchServices: true,
                    page: 0,
                    search: action.search,
                    searching: true,
                    servicesFetched: false,
                };
            }
        } else if (action.type === "SET_DATA") {
            const isFiltered = state.filtering;
            const isSearching = state.searching;
            const currentServices = state.services || [];

            const updatedServices =
                isFiltered || isSearching ? [] : [...currentServices];
            let numUpdatedServices = updatedServices.length;

            let newPage =
                numUpdatedServices > 0
                    ? Math.ceil(numUpdatedServices / SERVICES_LIMIT)
                    : 0;
            newPage = newPage > 0 ? newPage - 1 : newPage;

            const currentRowsPerPage = state.rowsPerPage;

            const fetchedTotals = action.totals;
            const numTotalServices = fetchedTotals[SERVICE_KEYS.TOTAL];

            const fetchedServices = action.services;
            const numFetchedServices = fetchedServices.length;
            if (numFetchedServices > 0) {
                let numAddedServices = 0;

                for (let i = 0; i < numFetchedServices; i++) {
                    const fetchedService = fetchedServices[i];
                    if (
                        fetchedService &&
                        fetchedService?.[SERVICE_KEYS.ID] &&
                        fetchedService[SERVICE_KEYS.ID]
                    ) {
                        const foundService = updatedServices.find(
                            (serviceObj) =>
                                serviceObj[SERVICE_KEYS.ID] ===
                                fetchedService[SERVICE_KEYS.ID]
                        );
                        if (!foundService) {
                            updatedServices.push(fetchedService);
                            numAddedServices += 1;
                        }
                    }
                }

                numUpdatedServices = updatedServices.length;

                newPage =
                    numUpdatedServices > 0
                        ? Math.ceil(numUpdatedServices / SERVICES_LIMIT)
                        : 0;
                newPage = newPage > 0 ? newPage - 1 : newPage;

                if (numUpdatedServices === numTotalServices) {
                    return {
                        ...state,
                        filtering: false,
                        loadMore: false,
                        page: newPage,
                        searching: false,
                        services: updatedServices,
                        servicesFetched: true,
                        totals: numTotalServices,
                    };
                } else {
                    const maxLimit = currentRowsPerPage;
                    if (numAddedServices === maxLimit) {
                        return {
                            ...state,
                            filtering: false,
                            loadMore: true,
                            page: newPage + 1,
                            searching: false,
                            services: updatedServices,
                            servicesFetched: true,
                            totals: numTotalServices,
                        };
                    } else {
                        return {
                            ...state,
                            filtering: false,
                            loadMore: false,
                            page: newPage,
                            searching: false,
                            services: updatedServices,
                            servicesFetched: true,
                            totals: numTotalServices,
                        };
                    }
                }
            } else {
                return {
                    ...state,
                    filtering: false,
                    loadMore: false,
                    page: newPage,
                    searching: false,
                    services: updatedServices,
                    servicesFetched: true,
                    totals: numTotalServices,
                };
            }
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchServices: false,
                firstLoad: false,
            };
        }
    }
    return INIT_SERVICES_STATE;
};

const ClientServices = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isMountedClientServices = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const Swal = useSwalWrapper();

    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentService, setCurrentService] = useState(null);
    const [openServiceDescriptionDialog, setOpenServiceDescriptionDialog] =
        useState(false);

    const [servicesState, dispatchServicesAction] = useReducer(
        servicesReducer,
        INIT_SERVICES_STATE
    );

    const { fetchServices, services, servicesFetched } = servicesState;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isValidUserType = isClientUser;

    const canRequestService = isClientUser;

    useEffect(() => {
        return () => {
            _isMountedClientServices.current = false;
        };
    }, []);

    /* Start loading */
    useEffect(() => {
        if (isValidUserType) {
            setIsLoading(true);
        }
    }, [isValidUserType]);

    /* Fetch services */
    useEffect(() => {
        if (isLoading) {
            dispatchServicesAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (servicesState.fetchServices) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredServicesLocalState(
                            payload,
                            (fetchedData) => {
                                if (isActive) {
                                    dispatchServicesAction({
                                        type: "STOP_FETCH",
                                    });
                                    dispatchServicesAction({
                                        type: "SET_DATA",
                                        services: fetchedData?.services || [],
                                        totals: fetchedData?.totals || null,
                                    });
                                    setIsLoading(false);
                                }
                            }
                        )
                    );
                };
            };

            const serviceData = {
                authcode: authToken,
                order: servicesState.order,
                page: servicesState.page + 1,
                search: servicesState.search,
                rows_per_page: servicesState.rowsPerPage,
                sort: servicesState.sort,
            };

            const payload = { serviceData: serviceData };
            if (!servicesState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchServicesAction({ type: "RESET" });
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, dispatchServicesAction, authToken, servicesState]);

    /* Search services */
    useEffect(() => {
        dispatchServicesAction({
            type: "SEARCH_AND_FETCH",
            search: debouncedSearchTerm,
        });
    }, [debouncedSearchTerm, dispatchServicesAction]);

    const handleOpenDescriptionDialog = (service) => {
        setCurrentService(service);
        setOpenServiceDescriptionDialog(true);
    };

    const handleCloseDescriptionDialog = (service) => {
        setOpenServiceDescriptionDialog(false);
        setCurrentService(null);
    };

    const handleRequestService = (service) => {
        if (canRequestService) {
            if (
                service &&
                service?.[SERVICE_KEYS.ID] &&
                service[SERVICE_KEYS.ID]
            ) {
                const serviceData = {
                    authcode: authToken,
                    [SERVICE_KEYS.SERVICE_ID]: service[SERVICE_KEYS.ID],
                };
                dispatch(
                    requestService(
                        { serviceData: serviceData },
                        () => {},
                        () => {}
                    )
                );
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid service",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleLoadMore = () => dispatchServicesAction({ type: "FETCH" });

    const searchTermChangeHandler = (event) =>
        setSearchTerm(event.target.value);

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const servicesFetchError = !fetchServices && !servicesFetched;
    const servicesNonZero = services && services.length > 0;
    const loadError =
        !isValidUserType || servicesFetchError || !servicesNonZero;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && servicesFetchError && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    There was a problem in fetching the
                                    services!
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType &&
                            !servicesFetchError &&
                            !servicesNonZero && (
                                <Grid item xs={12}>
                                    <Alert variant="outlined" severity="error">
                                        No records found!
                                    </Alert>
                                </Grid>
                            )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Box
                                sx={(theme) => ({
                                    width: "33%",
                                    m: 0,
                                    ml: "auto",
                                    p: 0,
                                    [theme.breakpoints.down("sm")]: {
                                        width: "100%",
                                    },
                                })}
                            >
                                <FmSearch
                                    ariaLabel="Search services"
                                    id="services_search"
                                    placeholder="Search Services"
                                    searchTerm={searchTerm}
                                    filterSearch={servicesState.filtering}
                                    onSearchChange={searchTermChangeHandler}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ServicesList
                                canRequestService={canRequestService}
                                services={services}
                                onReadDescription={handleOpenDescriptionDialog}
                                onRequestService={handleRequestService}
                            />
                        </Grid>
                        {servicesState.loadMore && (
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <LoadingButton
                                    loading={fetchServices}
                                    type="button"
                                    variant="outlined"
                                    size="small"
                                    loadingPosition="start"
                                    className="btnHomeLoadMore"
                                    startIcon={<RefreshIcon />}
                                    onClick={handleLoadMore}
                                >
                                    Load More Services
                                </LoadingButton>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && openServiceDescriptionDialog && (
                <ServiceDescriptionDialog
                    currentService={currentService}
                    open={openServiceDescriptionDialog}
                    serviceKeys={SERVICE_KEYS}
                    onClose={handleCloseDescriptionDialog}
                />
            )}
        </React.Fragment>
    );
};

export default ClientServices;
