import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FmButton from "../../shared/widgets/FmButton";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    CASE_STUDY_KEYS,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import {
    deleteCaseStudy,
    getFilteredCaseStudies,
    setCurrentCaseStudy,
} from "../../redux/actions/caseStudies";
import AddEditCaseStudyDialog from "./AddEditCaseStudyDialog";
import CaseStudiesTable from "./CaseStudiesTable";

const pageName = "Case Studies";

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "INSERT") {
            return { inserted: true, deleted: false };
        } else if (action.type === "DELETE") {
            return { inserted: false, deleted: true };
        }
    }

    return { inserted: false, deleted: false };
};

const CaseStudies = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isCaseStudiesMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const Swal = useSwalWrapper();

    const { caseStudies, caseStudyCounts, currentCaseStudy } = useSelector(
        ({ caseStudiesReducer }) => caseStudiesReducer
    );

    const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
    const [fetchingOnLoad, setFetchingOnLoad] = useState(true);
    const [caseStudiesFetched, setCaseStudiesFetched] = useState(true);
    const [fetchCriteria, setFetchCriteria] = useState(null);
    const [caseStudiesFilterChanged, setCaseStudiesFilterChanged] =
        useState(true);
    const [openAddEditCaseStudyDialog, setOpenAddEditCaseStudyDialog] =
        useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        inserted: false,
        deleted: false,
    });

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    useEffect(() => {
        return () => {
            _isCaseStudiesMounted.current = false;
            dispatch(setCurrentCaseStudy(null));
        };
    }, [dispatch]);

    useEffect(() => {
        setFetchCriteria({
            page: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
        });

        if (!caseStudiesFilterChanged) {
            setCaseStudiesFetched(false);
        } else {
            setCaseStudiesFilterChanged(false);
        }
    }, [caseStudiesFilterChanged, pagination]);

    useEffect(() => {
        let isActive = true;

        if (!caseStudiesFetched && fetchCriteria) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredCaseStudies(payload, () => {
                            if (isActive) {
                                setCaseStudiesFetched(true);
                                setFetchingOnLoad(false);
                                dispatchAction();
                            }
                        })
                    );
                };
            };

            const caseStudyData = {
                authcode: authToken,
                page: fetchCriteria.page + 1,
                rows_per_page: fetchCriteria.rowsPerPage,
            };

            const promise = dispatch(
                fetchData({
                    caseStudyData: caseStudyData,
                    fromAction: actionState,
                })
            );
            promise.catch((error) => {
                /* Setting to 'true' means API has been executed, not necessarily successfully */
                if (isActive) {
                    setCaseStudiesFetched(true);
                    setFetchingOnLoad(false);
                    dispatchAction();
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, actionState, authToken, caseStudiesFetched, fetchCriteria]);

    useEffect(() => {
        if (actionState.inserted || actionState.deleted) {
            /**
             * Fetch the list again if a case study is
             * added or deleted
             */
            setCaseStudiesFetched(false);
        }
    }, [actionState]);

    const handlePageChange = (caseStudy, newPage) => {
        setPagination((prevState) => {
            return { ...prevState, page: newPage };
        });
    };

    const handleRowsPerPageChange = (caseStudy) => {
        setPagination({
            page: 0,
            rowsPerPage: parseInt(caseStudy.target.value, 10),
        });
    };

    const handleAddCaseStudy = () => {
        dispatch(setCurrentCaseStudy(null));
        setOpenAddEditCaseStudyDialog(true);
    };

    const handleCloseAddEditCaseStudyDialog = () => {
        setOpenAddEditCaseStudyDialog(false);
        dispatch(setCurrentCaseStudy(null));
    };

    const handleEditCaseStudy = (caseStudy) => {
        dispatch(setCurrentCaseStudy(caseStudy));
        setOpenAddEditCaseStudyDialog(true);
    };

    const handleDeleteCaseStudy = React.useCallback(
        (caseStudy) => {
            if (caseStudy && caseStudy[CASE_STUDY_KEYS.ID]) {
                showDialog({
                    variant: "confirm",
                    title: `Delete case study?`,
                    onYes: () => {
                        hideDialog();
                        const caseStudyData = {
                            authcode: authToken,
                            [CASE_STUDY_KEYS.CASE_STUDY_ID]:
                                caseStudy[CASE_STUDY_KEYS.ID],
                        };
                        dispatch(
                            deleteCaseStudy(
                                {
                                    caseStudyData: caseStudyData,
                                    authUserType: authUserType,
                                },
                                () => {
                                    if (_isCaseStudiesMounted.current)
                                        dispatchAction({ type: "DELETE" });
                                },
                                () => {}
                            )
                        );
                    },
                    onNo: hideDialog,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid case study",
                });
            }
        },
        [dispatch, Swal, hideDialog, showDialog, authToken, authUserType]
    );

    return (
        <React.Fragment>
            {!fetchingOnLoad && (
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <HeadingStack>
                            <PageTitle
                                component="h1"
                                title={pageName}
                                type="v2"
                            />
                            <Breadcrumb
                                pageNames={{
                                    genericName: pageName,
                                    specificName: pageName,
                                }}
                            />
                        </HeadingStack>
                    </Grid>
                    <Grid item xs={12}>
                        <HeadingStack sx={{ justifyContent: "end" }}>
                            <FmButton
                                startIcon="add"
                                type="button"
                                label="Add Case Study"
                                onClick={handleAddCaseStudy}
                            />
                        </HeadingStack>
                    </Grid>
                    <Grid item xs={12}>
                        <CaseStudiesTable
                            caseStudies={caseStudies}
                            caseStudyCounts={caseStudyCounts}
                            caseStudiesFetched={caseStudiesFetched}
                            page={pagination.page}
                            rowsPerPage={pagination.rowsPerPage}
                            handlePageChange={handlePageChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            onDelete={handleDeleteCaseStudy}
                            onEdit={handleEditCaseStudy}
                        />
                    </Grid>
                </Grid>
            )}
            {!fetchingOnLoad && openAddEditCaseStudyDialog && (
                <AddEditCaseStudyDialog
                    currentCaseStudy={currentCaseStudy}
                    fetchOnInsert={true}
                    open={openAddEditCaseStudyDialog}
                    onClose={handleCloseAddEditCaseStudyDialog}
                    onDispatchAction={dispatchAction}
                />
            )}
            {!authUserType && (
                <Grid container spacing={3.5}>
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                            {AUTHORIZATION_ERROR}
                        </Alert>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default CaseStudies;
