import React from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import TableActionIcon from "../TableActionIcon";
import { formatBytes } from "../../../utils/appHelpers";

const ChatRejectedFiles = ({ errors, onRemove }) => {
    const { theme: jumboTheme } = useJumboTheme();

    if (errors && errors.length > 0) {
        return (
            <List disablePadding>
                {errors.map(({ file, errors: fileErrors }, fileIdx) => {
                    const fileName = file.name;
                    const fileKey = `${fileIdx}-${fileName}`;

                    return (
                        <React.Fragment key={fileKey}>
                            <ListItem
                                secondaryAction={
                                    <TableActionIcon
                                        edge="end"
                                        iconComponent="close"
                                        label="close error message"
                                        tooltip="Close Error Message"
                                        onClick={() => onRemove(fileIdx)}
                                    />
                                }
                            >
                                <ListItemText
                                    disableTypography
                                    primary={`${fileName} - ${formatBytes(
                                        file.size
                                    )}`}
                                    secondary={
                                        <ul>
                                            {fileErrors.map(
                                                (error, errorIdx) => {
                                                    return (
                                                        <li
                                                            key={`${errorIdx}-${fileKey}`}
                                                            style={{
                                                                color: jumboTheme
                                                                    .palette
                                                                    .error.main,
                                                            }}
                                                        >
                                                            {error}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    }
                                />
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                })}
            </List>
        );
    } else {
        return null;
    }
};

ChatRejectedFiles.propTypes = {
    errors: PropTypes.array,
    onRemove: PropTypes.func,
};

ChatRejectedFiles.defaultProps = {
    errors: [],
};

export default ChatRejectedFiles;
