import React, {
    lazy,
    Suspense,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import FmButton from "../../shared/widgets/FmButton";
import FmCircularProgress from "../../shared/widgets/FmCircularProgress";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    ACCOUNT_ASSET_APPROVED_STATUS_ID,
    ACCOUNT_ASSET_KEYS,
    APP_NAME,
    AUTH_USER_KEYS,
    EMPTY_PARAM_VALUE,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
    USER_TYPE_CLIENT_ID,
} from "../../utils/constants/appData";
import {
    downloadAssetBlob,
    getFilteredPendingAssets,
    resendAssetApprovalRequest,
    setCurrentAccountAsset,
    updateAccountAssetStatus,
} from "../../redux/actions/accountAssets";
import { downloadAccountAsset } from "../../utils/accountAssetHelpers";
import {
    ACTION_AUTHORIZATION_ERROR,
    AUTHORIZATION_ERROR,
} from "../../utils/constants/errorMessages";
import AddFileDialog from "./AddFileDialog";
import PendingAssetsTable from "./PendingAssetsTable";
import RejectAccountAssetDialog from "./RejectAccountAssetDialog";
const ChatDialog = lazy(() => import("./ChatDialog"));

const pageName = "Pending Approvals";

const allHeadCells = [
    {
        id: ACCOUNT_ASSET_KEYS.COMPANY_NAME,
        label: "Company",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ACCOUNT_ASSET_KEYS.FOLDER_NAME,
        label: "Folder",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ACCOUNT_ASSET_KEYS.DOCUMENT_NAME,
        label: "File",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
    {
        id: ACCOUNT_ASSET_KEYS.CREATED_AT,
        label: "Uploaded",
        show: true,
        sortColumn: false,
        useColumn: true,
    },
];

const actionReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "APPROVE") {
            return {
                approved: true,
                deleted: false,
                inserted: false,
                rejected: false,
            };
        } else if (action.type === "DELETE") {
            return {
                approved: false,
                deleted: true,
                inserted: false,
                rejected: false,
            };
        } else if (action.type === "INSERT") {
            return {
                approved: false,
                deleted: false,
                inserted: true,
                rejected: false,
            };
        } else if (action.type === "REJECT") {
            return {
                approved: false,
                deleted: false,
                inserted: false,
                rejected: true,
            };
        }
    }

    return {
        approved: false,
        deleted: false,
        inserted: false,
        rejected: false,
    };
};

const INIT_PENDING_ASSETS_STATE = {
    assetId: "",
    fetchPendingAssets: false,
    firstLoad: true,
    inputIsValid: false,
    order: SORT_ORDER_ASC,
    page: 0,
    pendingAssetsFetched: false,
    rowsPerPage: 10,
    sort: ACCOUNT_ASSET_KEYS.CREATED_AT,
    sorting: false,
};

const pendingAssetsReducer = (state, action) => {
    if (action?.type && action.type) {
        if (action.type === "FETCH") {
            return {
                ...state,
                fetchPendingAssets: true,
                pendingAssetsFetched: false,
            };
        } else if (action.type === "RESET") {
            return {
                ...state,
                fetchPendingAssets: false,
                firstLoad: false,
                page: 0,
                pendingAssetsFetched: false,
                sorting: false,
            };
        } else if (action.type === "SET_ASSET_ID") {
            const updatedAssetId = action.assetId;
            return {
                ...state,
                assetId: updatedAssetId,
                inputIsValid: !!updatedAssetId,
            };
        } else if (action.type === "SET_LIMIT") {
            return {
                ...state,
                fetchPendingAssets: true,
                page: 0,
                pendingAssetsFetched: false,
                rowsPerPage: action.rowsPerPage,
            };
        } else if (action.type === "SET_PAGE") {
            return {
                ...state,
                fetchPendingAssets: true,
                page: action.page,
                pendingAssetsFetched: false,
            };
        } else if (action.type === "SORT_AND_FETCH") {
            const updatedSort = action.sort;
            const isAsc =
                state.sort === updatedSort && state.order === SORT_ORDER_ASC;
            return {
                ...state,
                fetchPendingAssets: true,
                page: 0,
                pendingAssetsFetched: false,
                order: isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC,
                sort: updatedSort,
                sorting: true,
            };
        } else if (action.type === "STOP_FETCH") {
            return {
                ...state,
                fetchPendingAssets: false,
                firstLoad: false,
                pendingAssetsFetched: true,
                sorting: false,
            };
        }
    }
    return INIT_PENDING_ASSETS_STATE;
};

const PendingAssets = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isPendingAssetsMounted = useRef(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { authToken, authUser } = useJumboAuth();
    const Swal = useSwalWrapper();

    const assetIdParam = params?.assetId || "";

    const { accountAssets, accountAssetCounts } = useSelector(
        ({ accountAssetsReducer }) => accountAssetsReducer
    );

    const [isLoading, setIsLoading] = useState(false);
    const [headCells, setHeadCells] = useState([]);
    const [columnsToShow, setColumnsToShow] = useState([]);
    const [openAddFileDialog, setOpenAddFileDialog] = useState(false);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);

    const [actionState, dispatchAction] = useReducer(actionReducer, {
        approved: false,
        deleted: false,
        inserted: false,
        rejected: false,
    });

    const [pendingAssetsState, dispatchPendingAssetsAction] = useReducer(
        pendingAssetsReducer,
        INIT_PENDING_ASSETS_STATE
    );

    const { inputIsValid } = pendingAssetsState;

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isClientUser = authUserType === USER_TYPE_CLIENT_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;
    const isValidUserType = isAdminUser || isClientUser || isFmUser;

    const canApproveRequest = isClientUser;
    const canRejectRequest = isClientUser;
    const canResendRequest = isAdminUser || isFmUser;

    useEffect(() => {
        return () => {
            _isPendingAssetsMounted.current = false;
            dispatch(setCurrentAccountAsset(null));
        };
    }, [dispatch]);

    /* Set table columns */
    useEffect(() => {
        if (isClientUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    if (
                        [
                            ACCOUNT_ASSET_KEYS.DOCUMENT_NAME,
                            ACCOUNT_ASSET_KEYS.FOLDER_NAME,
                            ACCOUNT_ASSET_KEYS.CREATED_AT,
                        ].includes(headCell.id)
                    ) {
                        return { ...headCell, show: true, sortColumn: true };
                    } else {
                        return { ...headCell, show: false };
                    }
                })
            );
        } else if (isAdminUser || isFmUser) {
            setHeadCells(
                allHeadCells.map((headCell) => {
                    if (
                        [
                            ACCOUNT_ASSET_KEYS.COMPANY_NAME,
                            ACCOUNT_ASSET_KEYS.DOCUMENT_NAME,
                            ACCOUNT_ASSET_KEYS.FOLDER_NAME,
                            ACCOUNT_ASSET_KEYS.CREATED_AT,
                        ].includes(headCell.id)
                    ) {
                        return { ...headCell, show: true, sortColumn: true };
                    } else {
                        return { ...headCell, show: false };
                    }
                })
            );
        } else {
            setHeadCells([]);
        }
    }, [isAdminUser, isClientUser, isFmUser]);

    useEffect(() => {
        if (headCells && headCells.length > 0) {
            let visibleColumnIds = [];
            for (let i = 0; i < headCells.length; i++) {
                if (headCells[i].show) {
                    visibleColumnIds.push(headCells[i].id);
                }
            }
            setColumnsToShow([...visibleColumnIds]);
        } else {
            setColumnsToShow([]);
        }
    }, [headCells]);

    /* Set query params */
    useEffect(() => {
        if (isValidUserType) {
            dispatchPendingAssetsAction({
                type: "SET_ASSET_ID",
                assetId: assetIdParam || EMPTY_PARAM_VALUE,
            });
        }
    }, [assetIdParam, isValidUserType]);

    /* Start loading if query param valid */
    useEffect(() => {
        if (inputIsValid) {
            setIsLoading(true);
        }
    }, [inputIsValid]);

    /* Start fetching pending assets */
    useEffect(() => {
        if (isLoading) {
            dispatchPendingAssetsAction({ type: "FETCH" });
        }
    }, [isLoading]);

    useEffect(() => {
        let isActive = true;

        if (pendingAssetsState.fetchPendingAssets) {
            const fetchData = (payload) => {
                return (dispatch, getState) => {
                    return dispatch(
                        getFilteredPendingAssets(payload, () => {
                            if (isActive) {
                                dispatchPendingAssetsAction({
                                    type: "STOP_FETCH",
                                });
                                dispatchAction();
                                setIsLoading(false);
                            }
                        })
                    );
                };
            };

            const accountAssetsData = {
                authcode: authToken,
                page: pendingAssetsState.page + 1,
                rows_per_page: pendingAssetsState.rowsPerPage,
                sort: pendingAssetsState.sort,
                order: pendingAssetsState.order,
            };

            if (pendingAssetsState.assetId !== EMPTY_PARAM_VALUE)
                accountAssetsData[ACCOUNT_ASSET_KEYS.ASSET_ID] =
                    pendingAssetsState.assetId;

            const payload = {
                accountAssetsData: accountAssetsData,
                fromAction: actionState,
            };

            if (!pendingAssetsState.firstLoad) {
                payload.fetchStart = true;
            }

            const promise = dispatch(fetchData(payload));
            promise.catch((error) => {
                if (isActive) {
                    dispatchPendingAssetsAction({
                        type: "RESET",
                    });
                    dispatchAction();
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dispatch, actionState, authToken, pendingAssetsState]);

    /**
     * Fetch the list again if an asset is
     * approved, deleted, inserted, or rejected
     */
    useEffect(() => {
        if (
            actionState.approved ||
            actionState.deleted ||
            actionState.inserted ||
            actionState.rejected
        ) {
            dispatchPendingAssetsAction({ type: "FETCH" });
        }
    }, [actionState]);

    const handlePageChange = (event, newPage) => {
        dispatchPendingAssetsAction({ type: "SET_PAGE", page: newPage });
    };

    const handleRowsPerPageChange = (event) => {
        dispatchPendingAssetsAction({
            type: "SET_LIMIT",
            rowsPerPage: parseInt(event.target.value, 10),
        });
    };

    const handleSort = (property) => {
        dispatchPendingAssetsAction({
            type: "SORT_AND_FETCH",
            sort: property,
        });
    };

    const handleOpenAddFileDialog = () => {
        setOpenAddFileDialog(true);
    };

    const handleCloseAddFileDialog = () => {
        setOpenAddFileDialog(false);
    };

    const handleAddFile = () => {
        dispatchAction({ type: "INSERT" });
    };

    const handleCloseChatDialog = () => {
        setOpenChatDialog(false);
        dispatch(setCurrentAccountAsset(null));
    };

    const handleViewChat = (accountAsset) => {
        dispatch(setCurrentAccountAsset(accountAsset));
        setOpenChatDialog(true);
    };

    const handleResendApprovalRequest = (accountAsset) => {
        if (canResendRequest) {
            if (
                accountAsset &&
                accountAsset[ACCOUNT_ASSET_KEYS.ID] &&
                accountAsset[ACCOUNT_ASSET_KEYS.COMPANY_ID]
            ) {
                const accountAssetData = {
                    authcode: authToken,
                    [ACCOUNT_ASSET_KEYS.ASSET_ID]:
                        accountAsset[ACCOUNT_ASSET_KEYS.ID],
                    [ACCOUNT_ASSET_KEYS.COMPANY_ID]:
                        accountAsset[ACCOUNT_ASSET_KEYS.COMPANY_ID],
                };
                dispatch(
                    resendAssetApprovalRequest(
                        { accountAssetData: accountAssetData },
                        () => {},
                        () => {}
                    )
                );
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid request",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleApprove = (accountAsset) => {
        if (canApproveRequest) {
            if (accountAsset && accountAsset[ACCOUNT_ASSET_KEYS.ID]) {
                const accountAssetData = {
                    authcode: authToken,
                    [ACCOUNT_ASSET_KEYS.ASSET_ID]:
                        accountAsset[ACCOUNT_ASSET_KEYS.ID],
                    [ACCOUNT_ASSET_KEYS.STATUS]:
                        ACCOUNT_ASSET_APPROVED_STATUS_ID,
                };
                dispatch(
                    updateAccountAssetStatus(
                        { accountAssetData: accountAssetData },
                        () => {
                            if (_isPendingAssetsMounted.current) {
                                dispatchAction({ type: "APPROVE" });
                            }
                        },
                        () => {}
                    )
                );
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid request",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    const handleDownloadAsset = React.useCallback(
        (url, filename, fileMimeType) => {
            if (url && filename && fileMimeType) {
                dispatch(
                    downloadAssetBlob(
                        { url: url },
                        (fileData) => {
                            if (_isPendingAssetsMounted.current) {
                                downloadAccountAsset(
                                    fileData,
                                    filename,
                                    fileMimeType
                                );
                            }
                        },
                        () => {}
                    )
                );
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Invalid file",
                });
            }
        },
        [dispatch, Swal]
    );

    const handleCloseRejectDialog = () => {
        setOpenRejectDialog(false);
        dispatch(setCurrentAccountAsset(null));
    };

    const handleReject = (accountAsset) => {
        if (canRejectRequest) {
            dispatch(setCurrentAccountAsset(accountAsset));
            setOpenRejectDialog(true);
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: ACTION_AUTHORIZATION_ERROR,
            });
        }
    };

    if (isLoading) {
        return <FmCircularProgress showBackDrop />;
    }

    const loadError = !isValidUserType || !inputIsValid;

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <HeadingStack>
                        <PageTitle component="h1" title={pageName} type="v2" />
                        <Breadcrumb
                            pageNames={{
                                genericName: pageName,
                                specificName: pageName,
                            }}
                        />
                    </HeadingStack>
                </Grid>
                {loadError && (
                    <React.Fragment>
                        {!isValidUserType && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    {AUTHORIZATION_ERROR}
                                </Alert>
                            </Grid>
                        )}
                        {isValidUserType && !inputIsValid && (
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="error">
                                    One or more parameters are not valid!
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {!loadError && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <HeadingStack sx={{ justifyContent: "end" }}>
                                <FmButton
                                    label="Upload Asset"
                                    size="medium"
                                    startIcon="add"
                                    onClick={handleOpenAddFileDialog}
                                />
                            </HeadingStack>
                        </Grid>
                        <Grid item xs={12}>
                            {headCells.length > 0 ? (
                                <PendingAssetsTable
                                    accountAssets={accountAssets}
                                    accountAssetCounts={accountAssetCounts}
                                    accountAssetsFetched={
                                        pendingAssetsState.pendingAssetsFetched
                                    }
                                    canApproveRequest={canApproveRequest}
                                    canRejectRequest={canRejectRequest}
                                    canResendRequest={canResendRequest}
                                    columnsToShow={columnsToShow}
                                    headCells={headCells}
                                    fetchAccountAssets={
                                        pendingAssetsState.fetchPendingAssets
                                    }
                                    order={pendingAssetsState.order}
                                    page={pendingAssetsState.page}
                                    sort={pendingAssetsState.sort}
                                    rowsPerPage={pendingAssetsState.rowsPerPage}
                                    handlePageChange={handlePageChange}
                                    handleRowsPerPageChange={
                                        handleRowsPerPageChange
                                    }
                                    onApprove={handleApprove}
                                    onDownload={handleDownloadAsset}
                                    onReject={handleReject}
                                    onResend={handleResendApprovalRequest}
                                    onSort={handleSort}
                                    onViewChat={handleViewChat}
                                />
                            ) : (
                                <Alert variant="outlined" severity="error">
                                    There was a problem in rendering the table!
                                </Alert>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {!loadError && (
                <React.Fragment>
                    {openAddFileDialog && (
                        <AddFileDialog
                            open={openAddFileDialog}
                            onClose={handleCloseAddFileDialog}
                            onInsert={handleAddFile}
                        />
                    )}
                    {openChatDialog && (
                        <Suspense fallback={<FmCircularProgress />}>
                            <ChatDialog
                                open={openChatDialog}
                                onClose={handleCloseChatDialog}
                            />
                        </Suspense>
                    )}
                    {openRejectDialog && (
                        <RejectAccountAssetDialog
                            open={openRejectDialog}
                            onClose={handleCloseRejectDialog}
                            onDispatchAction={dispatchAction}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PendingAssets;
