import React from "react";
import Special from "../pages/special";
import Specials from "../pages/specials";
import Page from "@jumbo/shared/Page";
import { SPECIALS_PAGE_LINK } from "../utils/constants/appData";

const speicalsRoutes = [
    {
        path: SPECIALS_PAGE_LINK,
        element: <Page component={Specials} />,
    },
    {
        path: "/special/:specialId/",
        element: <Page component={Special} />,
    },
];

export default speicalsRoutes;
