import React from "react";
import List from "@mui/material/List";
/* import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout"; */
import NavIdentifier from "./NavIdentifier";
import PropTypes from "prop-types";

const VerticalNavbar = ({ customMenuUsed, items }) => {
    /* const { sidebarOptions } = useJumboLayoutSidebar(); */

    /* const isMiniAndClosed = React.useMemo(() => {
        return (
            sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open
        );
    }, [sidebarOptions.view, sidebarOptions.open]); */

    return (
        <List
            disablePadding
            sx={{
                mr: 0,
                pb: 2,
            }}
        >
            {items.map((item, index) => (
                <NavIdentifier
                    translate
                    customMenuUsed={customMenuUsed}
                    item={item}
                    key={index}
                />
            ))}
        </List>
    );
};

VerticalNavbar.defaultProps = {
    customMenuUsed: PropTypes.bool,
    items: PropTypes.array,
    translate: false,
};

export default VerticalNavbar;
