import React, { useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import {
    APP_NAME,
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
} from "../../utils/constants/appData";
import { AUTHORIZATION_ERROR } from "../../utils/constants/errorMessages";
import settingsList from "./settingsList";

const pageName = "Settings";

const Settings = () => {
    document.title = `${APP_NAME} - ${pageName}`;

    const _isSettingsMounted = useRef(true);
    const { authUser } = useJumboAuth();

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isValidUserType = isAdminUser;

    useEffect(() => {
        return () => {
            _isSettingsMounted.current = false;
        };
    }, []);

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle type="v2" component="h1" title={pageName} />
                    <Breadcrumb
                        pageNames={{
                            genericName: pageName,
                            specificName: pageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            {isValidUserType && (
                <Grid item xs={12}>
                    <List>
                        {settingsList.map((settingsItem, idx) => {
                            return (
                                <React.Fragment key={`settings-item-${idx}`}>
                                    <ListItemButton
                                        component={RouterLink}
                                        to={settingsItem.uri}
                                        sx={(theme) => {
                                            return {
                                                p: theme.spacing(2),
                                                backgroundColor:
                                                    theme.palette.background
                                                        .paper,
                                                "&:hover": {
                                                    backgroundColor:
                                                        theme.palette.background
                                                            .paper,
                                                    "& .MuiListItemText-root .MuiListItemText-primary, .MuiListItemIcon-root":
                                                        {
                                                            color: theme.palette
                                                                .secondary.main,
                                                        },
                                                    "& .MuiListItemText-root .MuiListItemText-primary":
                                                        {
                                                            textDecoration:
                                                                "underline",
                                                        },
                                                },
                                            };
                                        }}
                                    >
                                        <ListItemIcon>
                                            {settingsItem.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={settingsItem.label}
                                            sx={(theme) => {
                                                return {
                                                    my: 0,
                                                    "&.MuiListItemText-root .MuiListItemText-primary":
                                                        {
                                                            fontSize:
                                                                theme.typography
                                                                    .h4
                                                                    .fontSize,
                                                            "&:hover": {
                                                                color: theme
                                                                    .palette
                                                                    .secondary
                                                                    .main,
                                                            },
                                                        },
                                                };
                                            }}
                                        />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                </Grid>
            )}
            {!isValidUserType && (
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="error">
                        {AUTHORIZATION_ERROR}
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default Settings;
