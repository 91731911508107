import basicAuthAxios from "../../services/auth/basicAuth";
import { fetchError, fetchStart, fetchSuccess } from "./common";
import {
    ADD_SERVICE,
    DELETE_SERVICE,
    EDIT_SERVICE,
    GET_SERVICES,
    GET_SERVICE_COUNT,
    SET_SERVICE_DETAILS,
} from "../../utils/constants/actionTypes";
import {
    ADD_SERVICE_LINK,
    DELETE_SERVICE_LINK,
    GET_ALL_SERVICES_LINK,
    GET_SERVICE_LINK,
    GET_SERVICES_LINK,
    REQUEST_SERVICE_LINK,
    UPDATE_SERVICE_LINK,
} from "../../utils/constants/apiLinks";
import { SERVICE_KEYS } from "../../utils/constants/appData";
import { API_COMMON_ERROR } from "../../utils/constants/errorMessages";

export const addService = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(ADD_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.service) {
                            /* dispatch(
                                fetchSuccess("Service was added successfully.")
                            ); */
                            dispatch({
                                type: ADD_SERVICE,
                                payload: data.service,
                            });
                            if (callbackFun) callbackFun(data.service);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const getFilteredServices = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_SERVICES_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.services) {
                            const services = data.services;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [SERVICE_KEYS.TOTAL]: count[
                                        SERVICE_KEYS.TOTAL
                                    ]
                                        ? count[SERVICE_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [SERVICE_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Service was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected service was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));
                            dispatch({
                                type: GET_SERVICES,
                                payload: services,
                            });
                            dispatch({
                                type: GET_SERVICE_COUNT,
                                payload: totals,
                            });
                            if (callbackFun) callbackFun(services);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                dispatch({ type: GET_SERVICES, payload: [] });
                dispatch({
                    type: GET_SERVICE_COUNT,
                    payload: {
                        [SERVICE_KEYS.TOTAL]: "0",
                    },
                });

                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));

                if (callbackFun) callbackFun([]);
                throw error;
            });
    };
};

export const getFilteredServicesLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart(request?.fetchStart ? request.fetchStart : false));
        return basicAuthAxios
            .post(GET_SERVICES_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.services) {
                            const services = data.services;

                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [SERVICE_KEYS.TOTAL]: count[
                                        SERVICE_KEYS.TOTAL
                                    ]
                                        ? count[SERVICE_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [SERVICE_KEYS.TOTAL]: "0",
                                };
                            }

                            let successMsg = "";
                            if (request.fromAction) {
                                const fromAction = request.fromAction;
                                if (fromAction.inserted) {
                                    successMsg =
                                        "Service was added successfully";
                                } else if (fromAction.deleted) {
                                    successMsg =
                                        "Selected service was deleted successfully";
                                }
                            }
                            dispatch(fetchSuccess(successMsg));

                            if (callbackFun)
                                callbackFun({
                                    services: services,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getAllServicesLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_ALL_SERVICES_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.services) {
                            const services = data.services;
                            const count = data.count ? data.count : null;
                            let totals = null;
                            if (count) {
                                totals = {
                                    [SERVICE_KEYS.TOTAL]: count[
                                        SERVICE_KEYS.TOTAL
                                    ]
                                        ? count[SERVICE_KEYS.TOTAL]
                                        : "0",
                                };
                            } else {
                                totals = {
                                    [SERVICE_KEYS.TOTAL]: "0",
                                };
                            }
                            dispatch(fetchSuccess());
                            if (callbackFun)
                                callbackFun({
                                    services: services,
                                    totals: totals,
                                });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const getServiceLocalState = (request = {}, callbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        return basicAuthAxios
            .post(GET_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.service) {
                            const service = data.service;
                            dispatch(fetchSuccess());
                            if (callbackFun) callbackFun({ service: service });
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = responseData.msg
                            ? responseData.msg
                            : API_COMMON_ERROR;
                    }
                } else {
                    isError = true;
                    errorMsg = API_COMMON_ERROR;
                }

                if (isError) {
                    throw new Error(errorMsg);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }
                dispatch(fetchError(errorMsg));
                throw error;
            });
    };
};

export const setCurrentService = (service) => {
    return (dispatch) => {
        dispatch({ type: SET_SERVICE_DETAILS, payload: service });
    };
};

export const requestService = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(REQUEST_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        dispatch(
                            fetchSuccess("Service request sent successfully.")
                        );
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors = responseData.errors
                            ? responseData.errors
                            : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const updateService = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(UPDATE_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;
                let apiErrors = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        const data = responseData.data;
                        if (data.service) {
                            dispatch(
                                fetchSuccess(
                                    "Selected service was updated successfully."
                                )
                            );
                            dispatch({
                                type: EDIT_SERVICE,
                                payload: data.service,
                            });
                            if (callbackFun) callbackFun(data.service);
                        } else {
                            isError = true;
                            errorMsg = "Unable to get data";
                        }
                    } else {
                        isError = true;
                        errorMsg = "There was a problem.";
                        apiErrors =
                            responseData.data && responseData.data.errors
                                ? responseData.data.errors
                                : null;
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (apiErrors && errorCallbackFun)
                        errorCallbackFun(apiErrors);
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                let apiErrors = null;

                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }

                    if (errResponseData.errors) {
                        apiErrors = errResponseData.errors;
                    }
                }

                dispatch(fetchError(errorMsg));
                if (apiErrors && errorCallbackFun) errorCallbackFun(apiErrors);
            });
    };
};

export const deleteService = (request = {}, callbackFun, errorCallbackFun) => {
    return (dispatch) => {
        const body = request.serviceData;
        dispatch(fetchStart());
        basicAuthAxios
            .post(DELETE_SERVICE_LINK, body)
            .then((response) => {
                let isError = false;
                let errorMsg = null;

                if (response.status === 200) {
                    const responseData = response.data;
                    if (responseData.status && responseData.status === "true") {
                        /* dispatch(
                            fetchSuccess(
                                "Selected service was deleted successfully."
                            )
                        ); */
                        dispatch({
                            type: DELETE_SERVICE,
                            payload:
                                request.serviceData[SERVICE_KEYS.SERVICE_ID],
                        });
                        if (callbackFun) callbackFun();
                    } else {
                        isError = true;
                        errorMsg = responseData.message
                            ? responseData.message
                            : "There was a problem";
                    }
                } else {
                    isError = true;
                    errorMsg =
                        "There was something issue in responding server.";
                }

                if (isError) {
                    dispatch(fetchError(errorMsg));
                    if (errorCallbackFun) errorCallbackFun();
                }
            })
            .catch((error) => {
                let errorMsg = error.message || API_COMMON_ERROR;
                if (error.response && error.response.data) {
                    const errResponseData = error.response.data;

                    if (errResponseData.msg) {
                        errorMsg = errResponseData.msg;
                    } else if (errResponseData.messages) {
                        const errorMessages = errResponseData.messages;
                        if (errorMessages && errorMessages.msg) {
                            errorMsg = errorMessages.msg;
                        }
                    }
                }

                dispatch(fetchError(errorMsg));
                if (errorCallbackFun) errorCallbackFun();
            });
    };
};
