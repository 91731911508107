import React from "react";
import { Stack, ThemeProvider, Typography } from "@mui/material";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { getAppUserTypeName } from "app/utils/appHelpers";
import {
    AUTH_USER_KEYS,
    USER_TYPE_ADMIN_ID,
    USER_TYPE_AE_ID,
} from "app/utils/constants/appData";

const AuthUserHeader = () => {
    const { theme } = useJumboTheme();
    const { authUser } = useJumboAuth();

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    const isAdminUser = authUserType === USER_TYPE_ADMIN_ID;
    const isFmUser = authUserType === USER_TYPE_AE_ID;

    const authUserName = authUser ? `${authUser[AUTH_USER_KEYS.NAME]}` : "";

    const userTypeName =
        isAdminUser || isFmUser
            ? getAppUserTypeName(authUser?.[AUTH_USER_KEYS.TYPE] || "")
            : "";

    return (
        <ThemeProvider theme={theme}>
            <Stack>
                <Typography
                    component="p"
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: "15px",
                        lineHeight: "18px",
                    }}
                >
                    {authUserName}
                </Typography>
                {userTypeName && (
                    <Typography
                        component="p"
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: "12px",
                            lineHeight: "14px",
                        }}
                    >
                        {userTypeName}
                    </Typography>
                )}
            </Stack>
        </ThemeProvider>
    );
};

export default AuthUserHeader;
