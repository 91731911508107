import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { capitalizeFLetter } from "@jumbo/utils";
import { updateTicketStatus } from "../../redux/actions/tickets";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import { getTicketStatusList } from "../../utils/appHelpers";
import { AUTH_USER_KEYS, TICKET_KEYS } from "../../utils/constants/appData";
import {
    AUTHORIZATION_ERROR,
    VALIDATION_FAILED,
} from "../../utils/constants/errorMessages";
import { checkTicketStatusValid } from "../../utils/validationHelpers";

const statusList = getTicketStatusList();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditTicketStatusDialog = ({
    currentTicket,
    open,
    onClose,
    onStatusUpdate,
}) => {
    const _isMountedEditTicketStatus = useRef(true);
    const dispatch = useDispatch();
    const { authToken, authUser } = useJumboAuth();

    const [statusValues, setStatusValues] = useState([]);
    const [status, setStatus] = useState("");
    const [statusError, setStatusError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const authUserType =
        authUser &&
        authUser?.[AUTH_USER_KEYS.TYPE] &&
        authUser[AUTH_USER_KEYS.TYPE]
            ? authUser[AUTH_USER_KEYS.TYPE]
            : "";

    useEffect(() => {
        return () => {
            _isMountedEditTicketStatus.current = false;
        };
    }, []);

    useEffect(() => {
        if (statusList && statusList.length > 0) {
            setStatusValues(statusList.map((statusObj) => statusObj.value));
        }
    }, []);

    useEffect(() => {
        if (!open) {
            setStatusValues([]);
            setStatus("");
            setStatusError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (currentTicket) {
            setStatus(currentTicket[TICKET_KEYS.STATUS]);
        }
    }, [currentTicket]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case TICKET_KEYS.STATUS:
                        setStatusError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const statusChangeHandler = (event) => {
        setStatusError("");
        const statusValidationResult = checkTicketStatusValid(
            event.target.value,
            statusValues
        );
        if (statusValidationResult.status) {
            if (statusValidationResult.status === "false") {
                setStatus("");
                setStatusError(statusValidationResult.msg);
            } else {
                setStatus(event.target.value);
            }
        } else {
            setStatus("");
            setStatusError(VALIDATION_FAILED);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        if (!currentTicket || !currentTicket[TICKET_KEYS.ID] || !authUserType) {
            formIsValid = false;
            setStatusError("Invalid ticket");
        } else {
            const statusValidationResult = checkTicketStatusValid(
                status,
                statusValues
            );
            if (statusValidationResult.status) {
                if (statusValidationResult.status === "false") {
                    formIsValid = false;
                    setStatusError(statusValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setStatusError(VALIDATION_FAILED);
            }
        }

        if (formIsValid) {
            const ticketData = {
                authcode: authToken,
                [TICKET_KEYS.TICKET_ID]: currentTicket[TICKET_KEYS.ID],
                [TICKET_KEYS.STATUS]: status,
            };

            dispatch(
                updateTicketStatus(
                    { ticketData: ticketData },
                    () => {
                        if (_isMountedEditTicketStatus.current) {
                            onStatusUpdate();
                            onClose();
                        }
                    },
                    (messages) => {
                        if (_isMountedEditTicketStatus.current)
                            setErrorMessages(messages);
                    }
                )
            );
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitleClose
                title="Edit Ticket Status"
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                {authUserType && currentTicket && (
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            {miscellaneousErrors &&
                                miscellaneousErrors.length > 0 && (
                                    <Grid item xs={12}>
                                        {miscellaneousErrors.map(
                                            (miscellaneousError, idx) => (
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    color="error"
                                                    gutterBottom
                                                    key={`misc-error-${idx}`}
                                                >
                                                    {miscellaneousError}
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel
                                        id="ticket-status-select-label"
                                        error={statusError !== ""}
                                    >
                                        Status
                                    </InputLabel>
                                    <Select
                                        labelId="ticket-status-select-label"
                                        id="ticket-status-select"
                                        value={status}
                                        label="Status"
                                        margin="dense"
                                        onChange={statusChangeHandler}
                                        input={
                                            <OutlinedInput
                                                fullWidth
                                                label="Status"
                                                error={statusError !== ""}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {statusList.map((statusObj) => (
                                            <MenuItem
                                                key={`${statusObj.value}-status`}
                                                value={statusObj.value}
                                            >
                                                {capitalizeFLetter(
                                                    statusObj.label
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {statusError && (
                                        <FormHelperText
                                            error
                                            id="ticket-status-select-error"
                                        >
                                            {statusError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update Status
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {!authUserType && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                {AUTHORIZATION_ERROR}
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                {!currentTicket && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="error">Invalid ticket</Alert>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

EditTicketStatusDialog.propTypes = {
    currentTicket: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onStatusUpdate: PropTypes.func,
};

EditTicketStatusDialog.defaultProps = {
    currentTicket: null,
    open: false,
    // onClose: () => {},
    onStatusUpdate: () => {},
};

export default EditTicketStatusDialog;
