import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import AttachFileButton from "../../shared/widgets/AttachFileButton";
import DialogTitleClose from "../../shared/widgets/DialogTitleClose";
import FormattedPhoneNo from "../../shared/widgets/FormattedPhoneNo";
import NameLink from "../../shared/widgets/NameLink";
import {
    /* checkBioDescriptionValid, */
    checkBioLinkValid,
    checkAssignedCompaniesValid,
    checkEmailValid,
    checkNameValid,
    checkPhoneNoValid,
    checkProfilePhotoValid,
    checkRoleValid,
} from "../../utils/validationHelpers";
import { COMPANY_KEYS, FM_USER_KEYS } from "../../utils/constants/appData";
import { VALIDATION_FAILED } from "../../utils/constants/errorMessages";
import { addFMUser, updateFmUser } from "../../redux/actions/fmUsers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddEditUserDialog = ({
    allCompanies,
    currentFmUser,
    open,
    setGlobalState,
    onClose,
    onInsert,
    onUpdate,
}) => {
    const _isAddEditUserDialogMounted = useRef(true);
    const dispatch = useDispatch();
    const { authToken } = useJumboAuth();

    const [allCompaniesIds, setAllCompaniesIds] = useState([]);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [role, setRole] = useState("");
    const [roleError, setRoleError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneNoError, setPhoneNoError] = useState("");
    const [bioLink, setBioLink] = useState("");
    const [bioLinkError, setBioLinkError] = useState("");
    const [bioDescription, setBioDescription] = useState("");
    const [bioDescriptionError, setBioDescriptionError] = useState("");
    const [photo, setPhoto] = useState(null);
    const [photoError, setPhotoError] = useState("");
    const [photoLink, setPhotoLink] = useState("");
    const [assignedCompanies, setAssignedCompanies] = useState([]);
    const [assignedCompaniesError, setAssignedCompaniesError] = useState("");
    const [miscellaneousErrors, setMiscellaneousErrors] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        return () => {
            _isAddEditUserDialogMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            setAllCompaniesIds([]);
            setName("");
            setNameError("");
            setRole("");
            setRoleError("");
            setEmail("");
            setEmailError("");
            setPhoneNo("");
            setPhoneNoError("");
            setBioLink("");
            setBioLinkError("");
            setBioDescription("");
            setBioDescriptionError("");
            setPhoto(null);
            setPhotoError("");
            setPhotoLink("");
            setAssignedCompanies([]);
            setAssignedCompaniesError("");
            setMiscellaneousErrors([]);
            setErrorMessages([]);
        }
    }, [open]);

    useEffect(() => {
        if (allCompanies && allCompanies.length > 0) {
            setAllCompaniesIds(
                allCompanies.map(
                    (allCompanies) => allCompanies[COMPANY_KEYS.ID]
                )
            );
        }
    }, [allCompanies]);

    useEffect(() => {
        if (currentFmUser) {
            setName(currentFmUser[FM_USER_KEYS.NAME]);
            setRole(currentFmUser[FM_USER_KEYS.ROLE]);
            setEmail(currentFmUser[FM_USER_KEYS.EMAIL]);
            setPhoneNo(currentFmUser[FM_USER_KEYS.PHONE_NO]);
            setBioLink(currentFmUser[FM_USER_KEYS.BIO_LINK] || "");
            setBioDescription(
                currentFmUser[FM_USER_KEYS.BIO_DESCRIPTION] || ""
            );

            setPhoto(null);
            const fetchedPhoto = currentFmUser[FM_USER_KEYS.PROFILE_PIC];
            if (fetchedPhoto) {
                setPhotoLink(
                    `${currentFmUser[FM_USER_KEYS.PHOTO_BASE_URL]}/${
                        fetchedPhoto[FM_USER_KEYS.PHOTO_FILE_PATH]
                    }`
                );
            }

            const currentCompaniesIds =
                currentFmUser[FM_USER_KEYS.ASSIGNED_COMPANIES];
            if (
                allCompanies &&
                allCompanies.length > 0 &&
                currentCompaniesIds
            ) {
                const currentCompanies = currentCompaniesIds
                    .split(",")
                    .map((currentCompanyId) => {
                        const foundCompany = allCompanies.find(
                            (company) =>
                                company[COMPANY_KEYS.ID] === currentCompanyId
                        );

                        return foundCompany || null;
                    });

                setAssignedCompanies(
                    currentCompanies.filter(
                        (currentCompany) =>
                            currentCompany && currentCompany?.[COMPANY_KEYS.ID]
                    )
                );
            } else {
                setAssignedCompanies([]);
            }
        }
    }, [allCompanies, currentFmUser]);

    useEffect(() => {
        if (errorMessages) {
            setMiscellaneousErrors([]);
            for (const fieldName in errorMessages) {
                const msg = errorMessages[fieldName];
                switch (fieldName) {
                    case FM_USER_KEYS.NAME:
                        setNameError(msg);
                        break;

                    case FM_USER_KEYS.ROLE:
                        setRoleError(msg);
                        break;

                    case FM_USER_KEYS.EMAIL:
                        setEmailError(msg);
                        break;

                    case FM_USER_KEYS.PHONE_NO:
                        setPhoneNoError(msg);
                        break;

                    case FM_USER_KEYS.BIO_LINK:
                        setBioLinkError(msg);
                        break;

                    case FM_USER_KEYS.BIO_DESCRIPTION:
                        setBioDescriptionError(msg);
                        break;

                    case FM_USER_KEYS.ASSIGNED_COMPANIES:
                        setAssignedCompaniesError(msg);
                        break;

                    case FM_USER_KEYS.PROFILE_PIC:
                        setPhotoError(msg);
                        break;

                    default:
                        setMiscellaneousErrors((prevState) => [
                            ...prevState,
                            msg,
                        ]);
                        break;
                }
            }
        }
    }, [errorMessages]);

    const nameBlurHandler = (event) => {
        const validationResult = checkNameValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setNameError(validationResult.msg);
            }
        } else {
            setNameError(VALIDATION_FAILED);
        }
    };

    const roleBlurHandler = (event) => {
        const validationResult = checkRoleValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setRoleError(validationResult.msg);
            }
        } else {
            setRoleError(VALIDATION_FAILED);
        }
    };

    const emailBlurHandler = (event) => {
        const validationResult = checkEmailValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setEmailError(validationResult.msg);
            }
        } else {
            setEmailError(VALIDATION_FAILED);
        }
    };

    const phoneNoChangeHandler = (event) => {
        const updatedPhoneNo = event.target.value;

        setPhoneNo(updatedPhoneNo);
        setPhoneNoError("");

        const validationResult = checkPhoneNoValid(updatedPhoneNo);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setPhoneNoError(validationResult.msg);
            }
        } else {
            setPhoneNoError(VALIDATION_FAILED);
        }
    };

    const bioLinkBlurHandler = (event) => {
        const enteredBioLink = event.target.value;
        if (enteredBioLink) {
            const validationResult = checkBioLinkValid(event.target.value);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    setBioLinkError(validationResult.msg);
                }
            } else {
                setBioLinkError(VALIDATION_FAILED);
            }
        }
    };

    /* const bioDescriptionBlurHandler = (event) => {
        const validationResult = checkBioDescriptionValid(event.target.value);
        if (validationResult.status) {
            if (validationResult.status === "false") {
                setBioDescriptionError(validationResult.msg);
            }
        } else {
            setBioDescriptionError(VALIDATION_FAILED);
        }
    }; */

    const companiesChangeHandler = (event) => {
        const {
            target: { value },
        } = event;
        if (value && value.length > 0) {
            setAssignedCompanies(value);
        } else {
            setAssignedCompanies([]);
        }

        const assignedCompaniesValidationResult = checkAssignedCompaniesValid(
            value,
            allCompaniesIds
        );
        if (assignedCompaniesValidationResult.status) {
            if (assignedCompaniesValidationResult.status === "false") {
                setAssignedCompaniesError(
                    assignedCompaniesValidationResult.msg
                );
            } else {
                setAssignedCompaniesError("");
            }
        } else {
            setAssignedCompaniesError(VALIDATION_FAILED);
        }
    };

    const photoChangeHandler = (event) => {
        let isValid = true;

        const files = event.target.files;
        if (files && files.length > 0) {
            const validationResult = checkProfilePhotoValid(files);
            if (validationResult.status) {
                if (validationResult.status === "false") {
                    isValid = false;
                    setPhotoError(validationResult.msg);
                }
            } else {
                isValid = false;
                setPhotoError(VALIDATION_FAILED);
            }
        }

        if (isValid) {
            setPhoto(files[0]);
            setPhotoError("");
        } else {
            setPhoto(null);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setMiscellaneousErrors([]);

        let formIsValid = true;

        const nameValidationResult = checkNameValid(name);
        if (nameValidationResult.status) {
            if (nameValidationResult.status === "false") {
                formIsValid = false;
                setNameError(nameValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setNameError(VALIDATION_FAILED);
        }

        const roleValidationResult = checkRoleValid(role);
        if (roleValidationResult.status) {
            if (roleValidationResult.status === "false") {
                formIsValid = false;
                setRoleError(roleValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setRoleError(VALIDATION_FAILED);
        }

        const emailValidationResult = checkEmailValid(email);
        if (emailValidationResult.status) {
            if (emailValidationResult.status === "false") {
                formIsValid = false;
                setEmailError(emailValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setEmailError(VALIDATION_FAILED);
        }

        const phoneNoValidationResult = checkPhoneNoValid(phoneNo);
        if (phoneNoValidationResult.status) {
            if (phoneNoValidationResult.status === "false") {
                formIsValid = false;
                setPhoneNoError(phoneNoValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setPhoneNoError(VALIDATION_FAILED);
        }

        if (bioLink) {
            const bioLinkValidationResult = checkBioLinkValid(bioLink);
            if (bioLinkValidationResult.status) {
                if (bioLinkValidationResult.status === "false") {
                    formIsValid = false;
                    setBioLinkError(bioLinkValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setBioLinkError(VALIDATION_FAILED);
            }
        }

        /* const bioDescriptionValidationResult =
            checkBioDescriptionValid(bioDescription);
        if (bioDescriptionValidationResult.status) {
            if (bioDescriptionValidationResult.status === "false") {
                formIsValid = false;
                setBioDescriptionError(bioDescriptionValidationResult.msg);
            }
        } else {
            formIsValid = false;
            setBioDescriptionError(VALIDATION_FAILED);
        } */

        const assignedCompaniesValidationResult = checkAssignedCompaniesValid(
            assignedCompanies,
            allCompaniesIds
        );
        if (assignedCompaniesValidationResult.status) {
            if (assignedCompaniesValidationResult.status === "false") {
                formIsValid = false;
                setAssignedCompaniesError(
                    assignedCompaniesValidationResult.msg
                );
            }
        } else {
            formIsValid = false;
            setAssignedCompaniesError(VALIDATION_FAILED);
        }

        if (photo) {
            const photoValidationResult = checkProfilePhotoValid([photo]);
            if (photoValidationResult.status) {
                if (photoValidationResult.status === "false") {
                    formIsValid = false;
                    setPhotoError(photoValidationResult.msg);
                }
            } else {
                formIsValid = false;
                setPhotoError(VALIDATION_FAILED);
            }
        }

        if (formIsValid) {
            const fmUserData = new FormData();
            fmUserData.append("authcode", authToken);
            fmUserData.append([FM_USER_KEYS.NAME], name);
            fmUserData.append([FM_USER_KEYS.ROLE], role);
            fmUserData.append([FM_USER_KEYS.EMAIL], email);
            fmUserData.append([FM_USER_KEYS.PHONE_NO], phoneNo);
            fmUserData.append([FM_USER_KEYS.BIO_LINK], bioLink);
            fmUserData.append([FM_USER_KEYS.BIO_DESCRIPTION], bioDescription);

            const assignedCompaniesIds = assignedCompanies.map(
                (assignedCompany) => assignedCompany[COMPANY_KEYS.ID]
            );
            fmUserData.append(
                [FM_USER_KEYS.ASSIGNED_COMPANIES],
                assignedCompaniesIds.join(",")
            );

            if (currentFmUser) {
                fmUserData.append(
                    [FM_USER_KEYS.USER_ID],
                    currentFmUser[FM_USER_KEYS.ID]
                );

                if (photo) fmUserData.append([FM_USER_KEYS.PROFILE_PIC], photo);

                dispatch(
                    updateFmUser(
                        {
                            fmUserData: fmUserData,
                            setGlobalState: setGlobalState,
                        },
                        (updatedFmUser) => {
                            if (_isAddEditUserDialogMounted.current) {
                                onUpdate(updatedFmUser);
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditUserDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            } else {
                if (photo) fmUserData.append([FM_USER_KEYS.PROFILE_PIC], photo);

                dispatch(
                    addFMUser(
                        { fmUserData: fmUserData },
                        () => {
                            if (_isAddEditUserDialogMounted.current) {
                                onInsert();
                                onClose();
                            }
                        },
                        (messages) => {
                            if (_isAddEditUserDialogMounted.current)
                                setErrorMessages(messages);
                        }
                    )
                );
            }
        }
    };

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitleClose
                title={currentFmUser ? "Edit User" : "Add User"}
                open={open}
                onClose={onClose}
            />
            <DialogContent>
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        {miscellaneousErrors &&
                            miscellaneousErrors.length > 0 && (
                                <Grid item xs={12}>
                                    {miscellaneousErrors.map(
                                        (miscellaneousError, idx) => (
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                color="error"
                                                gutterBottom
                                                key={`misc-error-${idx}`}
                                            >
                                                {miscellaneousError}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            )}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                id="name"
                                name="name"
                                label="Name"
                                value={name}
                                margin="dense"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameError("");
                                }}
                                onBlur={nameBlurHandler}
                                error={nameError !== ""}
                                helperText={nameError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                id="role"
                                name="role"
                                label="Role"
                                value={role}
                                margin="dense"
                                onChange={(e) => {
                                    setRole(e.target.value);
                                    setRoleError("");
                                }}
                                onBlur={roleBlurHandler}
                                error={roleError !== ""}
                                helperText={roleError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="email"
                                name="email"
                                label="Email"
                                value={email}
                                margin="dense"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError("");
                                }}
                                onBlur={emailBlurHandler}
                                error={emailError !== ""}
                                helperText={emailError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="phone_no"
                                name="phone_no"
                                label="Phone Number"
                                margin="dense"
                                value={phoneNo}
                                InputProps={{
                                    inputComponent: FormattedPhoneNo,
                                    inputProps: {
                                        allowEmptyFormatting: false,
                                        displayType: "input",
                                    },
                                }}
                                onChange={phoneNoChangeHandler}
                                error={phoneNoError !== ""}
                                helperText={phoneNoError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel
                                    id="assign_company_checkbox"
                                    error={assignedCompaniesError !== ""}
                                >
                                    Assign Companies
                                </InputLabel>
                                <Select
                                    labelId="assign_company_checkbox"
                                    id="assign_company"
                                    multiple
                                    value={assignedCompanies ?? ""}
                                    onChange={companiesChangeHandler}
                                    input={
                                        <OutlinedInput
                                            fullWidth
                                            label="Assign Companies"
                                            error={
                                                assignedCompaniesError !== ""
                                            }
                                        />
                                    }
                                    renderValue={(selected) =>
                                        selected
                                            .map(
                                                (selectedVal) =>
                                                    selectedVal[
                                                        COMPANY_KEYS.NAME
                                                    ]
                                            )
                                            .join(", ")
                                    }
                                    MenuProps={MenuProps}
                                >
                                    {allCompanies.map((tempCompanyObj) => {
                                        const found = assignedCompanies.find(
                                            (assignedCompany) =>
                                                assignedCompany[
                                                    COMPANY_KEYS.ID
                                                ] ===
                                                tempCompanyObj[COMPANY_KEYS.ID]
                                        );
                                        return (
                                            <MenuItem
                                                key={
                                                    tempCompanyObj[
                                                        COMPANY_KEYS.ID
                                                    ]
                                                }
                                                value={tempCompanyObj}
                                            >
                                                <Checkbox checked={!!found} />
                                                <ListItemText
                                                    primary={
                                                        tempCompanyObj[
                                                            COMPANY_KEYS.NAME
                                                        ]
                                                    }
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {assignedCompaniesError && (
                                    <FormHelperText
                                        error
                                        id="assign_company-error"
                                    >
                                        {assignedCompaniesError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="bio_link"
                                name="bio_link"
                                label="Bio Link"
                                margin="dense"
                                value={bioLink}
                                onChange={(e) => {
                                    setBioLink(e.target.value);
                                    setBioLinkError("");
                                }}
                                onBlur={bioLinkBlurHandler}
                                error={bioLinkError !== ""}
                                helperText={bioLinkError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                id="bio_description"
                                name="bio_description"
                                label="Bio Description"
                                margin="dense"
                                value={bioDescription}
                                onChange={(e) => {
                                    setBioDescription(e.target.value);
                                    setBioDescriptionError("");
                                }}
                                /* onBlur={bioDescriptionBlurHandler} */
                                error={bioDescriptionError !== ""}
                                helperText={bioDescriptionError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label
                                htmlFor="photo"
                                style={{ display: "block", marginTop: 8 }}
                            >
                                <input
                                    id="photo"
                                    name="photo"
                                    type="file"
                                    onChange={photoChangeHandler}
                                    style={{ display: "none" }}
                                />

                                <AttachFileButton
                                    fullWidth
                                    type="button"
                                    variant="outlined"
                                    component="span"
                                    startIcon={<AttachFileIcon />}
                                    sx={(theme) => {
                                        const photoErrorSx = photoError
                                            ? {
                                                  color: theme.palette.error
                                                      .main,
                                                  border: `1px solid ${theme.palette.error.main}`,
                                              }
                                            : "";
                                        return {
                                            ...photoErrorSx,
                                        };
                                    }}
                                >
                                    {photo ? photo.name : "Photo"}
                                </AttachFileButton>
                            </label>
                            {photoError && (
                                <FormHelperText error id="photo-error">
                                    {photoError}
                                </FormHelperText>
                            )}
                        </Grid>
                        {photoLink && (
                            <Grid item xs={12}>
                                <NameLink
                                    noWrap
                                    align="center"
                                    href={photoLink}
                                    target="_blank"
                                    sx={(theme) => ({
                                        display: "block",
                                        wordWrap: "break-word",
                                        ...theme.typography.h5,
                                    })}
                                >
                                    View Photo
                                </NameLink>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {currentFmUser ? "Update User" : "Create User"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

AddEditUserDialog.propTypes = {
    allCompanies: PropTypes.array,
    currentFmUser: PropTypes.object,
    open: PropTypes.bool,
    setGlobalState: PropTypes.bool,
    onClose: PropTypes.func,
    onInsert: PropTypes.func,
    onUpdate: PropTypes.func,
};

AddEditUserDialog.defaultProps = {
    allCompanies: [],
    currentFmUser: null,
    open: false,
    setGlobalState: false,
    // onClose: () => {},
    onInsert: () => {},
    onUpdate: () => {},
};

export default AddEditUserDialog;
