import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const AttachFileButton = styled(Button)(({ theme }) => {
    const outlinedRootStyles =
        theme.components.MuiOutlinedInput.styleOverrides.root;

    return {
        ...outlinedRootStyles,
        ...theme.typography.h6,
        justifyContent: "start",
        height: "1.4375em",
        padding: theme.spacing(3.35, 2),
        border: "1px solid #9FA1A3",
        textTransform: "none",
        letterSpacing: "inherit",
        "&:hover": {
            border: `1px solid ${outlinedRootStyles.color}`,
            background: outlinedRootStyles.background,
            color: outlinedRootStyles.color,
        },
        "&:active, &:focus-visible": {
            border: `1px solid ${outlinedRootStyles.color}`,
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3.35, 2),
        },
    };
});

export default AttachFileButton;
