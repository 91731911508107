import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { capitalizeFLetter } from "@jumbo/utils";
import Breadcrumb from "../../shared/widgets/Breadcrumb";
import HeadingStack from "../../shared/widgets/HeadingStack";
import PageTitle from "../../shared/widgets/PageTitle";
import FmButton from "../../shared/widgets/FmButton";
import { TICKET_KEYS } from "../../utils/constants/appData";
import {
    getTicketPriorityColor,
    getTicketPriorityList,
    getTicketStatusColor,
    getTicketStatusList,
} from "../../utils/appHelpers";

const priorityList = getTicketPriorityList();
const statusList = getTicketStatusList();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const OUTLINE_INPUT_SX = {
    height: "48px",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #CA3E27",
        borderRadius: "34px",
    },
};

const CHIP_SX = {
    mt: (theme) => theme.spacing(1),
};

const genericPageName = "Ticket";

const TicketHeader = ({
    canAssignFmUsers,
    canReadDescription,
    canUpdatePriority,
    canUpdateStatus,
    canViewPriority,
    currentTicket,
    miscellaneousErrors,
    priorityError,
    statusError,
    onAssignFmUsers,
    onPriorityChange,
    onReadDescription,
    onStatusChange,
}) => {
    const currentTicketPriority = currentTicket[TICKET_KEYS.PRIORITY];

    const currentTicketPriorityColorObj = useMemo(() => {
        if (currentTicketPriority) {
            return getTicketPriorityColor(currentTicketPriority);
        } else {
            return null;
        }
    }, [currentTicketPriority]);
    const currentTicketPriorityBgColor = currentTicketPriorityColorObj
        ? currentTicketPriorityColorObj.bgColor
        : "";
    const currentTicketPriorityTextColor = currentTicketPriorityColorObj
        ? currentTicketPriorityColorObj.textColor
        : "";

    const priorityChipSx = {
        backgroundColor: currentTicketPriorityBgColor,
        color: currentTicketPriorityTextColor,
        textTransform: "uppercase",
    };

    const currentTicketStatus = currentTicket[TICKET_KEYS.STATUS];

    const currentTicketStatusColorObj = useMemo(() => {
        if (currentTicketStatus) {
            return getTicketStatusColor(currentTicketStatus);
        } else {
            return null;
        }
    }, [currentTicketStatus]);
    const currentTicketStatusBgColor = currentTicketStatusColorObj
        ? currentTicketStatusColorObj.bgColor
        : "";
    const currentTicketStatusTextColor = currentTicketStatusColorObj
        ? currentTicketStatusColorObj.textColor
        : "";

    const currentTicketStatusValue = useMemo(() => {
        if (currentTicketStatus) {
            const foundStatusObj = statusList.find(
                (tempStatusObj) => tempStatusObj.value === currentTicketStatus
            );
            return foundStatusObj && foundStatusObj?.label
                ? foundStatusObj.label
                : "";
        } else {
            return "";
        }
    }, [currentTicketStatus]);

    const statusChipSx = {
        mr: (theme) => theme.spacing(1),
        backgroundColor: currentTicketStatusBgColor,
        color: currentTicketStatusTextColor,
        textTransform: "uppercase",
    };

    const showActions =
        canAssignFmUsers ||
        canReadDescription ||
        canUpdatePriority ||
        canUpdateStatus;

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12}>
                <HeadingStack>
                    <PageTitle
                        component="h1"
                        title={
                            currentTicket[TICKET_KEYS.SUBJECT] ||
                            genericPageName
                        }
                        type="v2"
                    />
                    <Breadcrumb
                        pageNames={{
                            genericName: genericPageName,
                            specificName:
                                currentTicket[TICKET_KEYS.SUBJECT] ||
                                genericPageName,
                        }}
                    />
                </HeadingStack>
            </Grid>
            <Grid item xs={12}>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "start", md: "center" }}
                    justifyContent={{ xs: "start", md: "space-between" }}
                    flexWrap="wrap"
                    spacing={{ xs: 2, md: 1 }}
                >
                    <Stack direction="row" alignItems="center" flexWrap="wrap">
                        <Chip
                            label={`Ticket #${
                                currentTicket[TICKET_KEYS.TICKETID]
                            }`}
                            sx={{ ...CHIP_SX, mr: (theme) => theme.spacing(1) }}
                        />
                        <Chip
                            label={`Status : ${currentTicketStatusValue}`}
                            sx={{ ...CHIP_SX, ...statusChipSx }}
                        />
                        {canViewPriority && (
                            <Chip
                                label={`Priority : ${currentTicketPriority}`}
                                sx={{ ...CHIP_SX, ...priorityChipSx }}
                            />
                        )}
                    </Stack>
                    {showActions && (
                        <Stack
                            direction={{ xs: "column", md: "row-reverse" }}
                            alignItems={{ xs: "start", md: "center" }}
                            flexWrap="wrap"
                            spacing={{ xs: 1, md: 1.5 }}
                        >
                            {canUpdatePriority && (
                                <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel id="ticket-priority-select-label">
                                        Priority
                                    </InputLabel>
                                    <Select
                                        disabled={!canUpdatePriority}
                                        labelId="ticket-priority-select-label"
                                        id="ticket-priority-select"
                                        value={currentTicketPriority}
                                        label="Priority"
                                        margin="dense"
                                        onChange={onPriorityChange}
                                        input={
                                            <OutlinedInput
                                                fullWidth
                                                label="Priority"
                                                error={priorityError !== ""}
                                                sx={{ ...OUTLINE_INPUT_SX }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {priorityList.map((priorityValue) => (
                                            <MenuItem
                                                key={`${priorityValue}-priority`}
                                                value={priorityValue}
                                            >
                                                {capitalizeFLetter(
                                                    priorityValue
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {priorityError && (
                                        <FormHelperText
                                            error
                                            id="ticket-priority-select-error"
                                        >
                                            {priorityError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                            {canUpdateStatus && (
                                <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel id="ticket-status-select-label">
                                        Status
                                    </InputLabel>
                                    <Select
                                        disabled={!canUpdateStatus}
                                        labelId="ticket-status-select-label"
                                        id="ticket-status-select"
                                        value={currentTicketStatus}
                                        label="Status"
                                        margin="dense"
                                        onChange={onStatusChange}
                                        input={
                                            <OutlinedInput
                                                fullWidth
                                                label="Status"
                                                error={statusError !== ""}
                                                sx={{ ...OUTLINE_INPUT_SX }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {statusList.map((statusObj) => (
                                            <MenuItem
                                                key={`${statusObj.value}-status`}
                                                value={statusObj.value}
                                            >
                                                {capitalizeFLetter(
                                                    statusObj.label
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {statusError && (
                                        <FormHelperText
                                            error
                                            id="ticket-status-select-error"
                                        >
                                            {statusError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                            {canReadDescription && (
                                <FmButton
                                    label="Read Description"
                                    size="large"
                                    startIcon="description"
                                    onClick={onReadDescription}
                                />
                            )}
                            {canAssignFmUsers && (
                                <FmButton
                                    label="Assign Users"
                                    size="large"
                                    startIcon="groupAdd"
                                    onClick={onAssignFmUsers}
                                />
                            )}
                        </Stack>
                    )}
                </Stack>
            </Grid>
            {miscellaneousErrors && miscellaneousErrors.length > 0 && (
                <Grid item xs={12}>
                    {miscellaneousErrors.map((miscellaneousError, idx) => (
                        <Typography
                            variant="caption"
                            display="block"
                            color="error"
                            gutterBottom
                            key={`misc-error-${idx}`}
                        >
                            {miscellaneousError}
                        </Typography>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

TicketHeader.propTypes = {
    canAssignFmUsers: PropTypes.bool,
    canReadDescription: PropTypes.bool,
    canUpdatePriority: PropTypes.bool,
    canUpdateStatus: PropTypes.bool,
    canViewPriority: PropTypes.bool,
    currentTicket: PropTypes.object,
    miscellaneousErrors: PropTypes.array,
    priorityError: PropTypes.string,
    statusError: PropTypes.string,
    onAssignFmUsers: PropTypes.func,
    onPriorityChange: PropTypes.func,
    onReadDescription: PropTypes.func,
    onStatusChange: PropTypes.func,
};

TicketHeader.defaultProps = {
    canAssignFmUsers: false,
    canReadDescription: false,
    canUpdatePriority: false,
    canUpdateStatus: false,
    canViewPriority: false,
    currentTicket: null,
    miscellaneousErrors: [],
    priorityError: "",
    statusError: "",
    onAssignFmUsers: () => {},
    onPriorityChange: () => {},
    onReadDescription: () => {},
    onStatusChange: () => {},
};

export default TicketHeader;
