import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const PageTitle = ({ component, title, type, sx }) => {
    const componentName = [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "body1",
    ].includes(component)
        ? component
        : "h1";

    return (
        <Typography
            component={component || "h1"}
            variant="p"
            sx={(theme) => {
                if (type === "v2") {
                    return {
                        mb: 0,
                        fontSize: (theme) =>
                            theme.typography[componentName].fontSize,
                        textTransform: "capitalize",
                        letterSpacing: "-0.02rem",
                    };
                } else {
                    return {
                        mb: 2,
                        fontSize: theme.typography.h5.fontSize,
                        textTransform: "uppercase",
                        ...sx,
                    };
                }
            }}
        >
            {title}
        </Typography>
    );
};

PageTitle.propTypes = {
    component: PropTypes.node,
    title: PropTypes.string,
    type: PropTypes.string,
    sx: PropTypes.object,
};

PageTitle.defaultProps = {
    component: null,
    title: "",
    type: "v1",
    sx: {},
};

export default PageTitle;
