import React from "react";
import EditProfile from "../pages/editProfile";
import Page from "@jumbo/shared/Page";

const profileRoutes = [
    {
        path: "/editProfile",
        element: <Page component={EditProfile} />,
    },
];

export default profileRoutes;
