import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import NameLink from "../../shared/widgets/NameLink";
import TableActionIcon from "../../shared/widgets/TableActionIcon";
import { getInitialsFromName } from "../../utils/appHelpers";
import {
    COMPANY_KEYS,
    PAGINATION_PAGES,
    SORT_ORDER_ASC,
    SORT_ORDER_DESC,
} from "../../utils/constants/appData";

const allHeadCells = [
    {
        id: COMPANY_KEYS.NAME,
        label: "Company",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "50%",
    },
    {
        id: COMPANY_KEYS.INDUSTRY,
        label: "Industry",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "15%",
    },
    {
        id: COMPANY_KEYS.EMAIL,
        label: "Email",
        show: true,
        sortColumn: true,
        useColumn: true,
        width: "25%",
    },
];

const ReportsCompaniesTable = ({
    companies,
    companyCounts,
    companiesFetched,
    order,
    page,
    rowsPerPage,
    sort,
    handlePageChange,
    handleRowsPerPageChange,
    onSort,
}) => {
    let colspan = allHeadCells.filter((headCell) => headCell.show).length;
    colspan = colspan > 0 ? colspan + 1 : 1;

    const companyCountNonZero =
        companies &&
        companies.length > 0 &&
        companyCounts &&
        companyCounts[COMPANY_KEYS.TOTAL] &&
        !isNaN(parseInt(companyCounts[COMPANY_KEYS.TOTAL])) &&
        parseInt(companyCounts[COMPANY_KEYS.TOTAL]) > 0;

    const numTotalCols = allHeadCells.length;
    const numVisibleCols = allHeadCells.length;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="Companies Table">
                    <TableHead>
                        <TableRow>
                            {allHeadCells.map((headCell) => {
                                const sx = {};

                                if (headCell?.width) {
                                    const headCellWidth = Number(
                                        headCell.width.match(/\d+(\.\d+)?/)[0]
                                    );
                                    const updHeadCellWidth =
                                        (numTotalCols / numVisibleCols) *
                                        headCellWidth;
                                    sx.width = `${updHeadCellWidth.toFixed(
                                        2
                                    )}%`;
                                }

                                const sortDirection =
                                    sort === headCell.id
                                        ? order === SORT_ORDER_ASC
                                            ? SORT_ORDER_DESC
                                            : SORT_ORDER_ASC
                                        : SORT_ORDER_ASC;

                                return headCell.show ? (
                                    <TableCell key={headCell.id} sx={{ ...sx }}>
                                        {headCell.sortColumn ? (
                                            <TableSortLabel
                                                active={sort === headCell.id}
                                                direction={sortDirection}
                                                onClick={() =>
                                                    onSort(headCell.id)
                                                }
                                            >
                                                {headCell.label}
                                                {sort === headCell.id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order ===
                                                        SORT_ORDER_DESC
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ) : null;
                            })}
                            <TableCell
                                key="actions"
                                sx={{ width: "10%", textAlign: "center" }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            p: 1,
                        }}
                    >
                        {companyCountNonZero ? (
                            companies.map((company) => {
                                const companyId = company[COMPANY_KEYS.ID];
                                const companyName = company[COMPANY_KEYS.NAME];
                                const fetchedPhoto =
                                    company[COMPANY_KEYS.LOGO_PIC];
                                let photoLink = "";
                                if (fetchedPhoto) {
                                    photoLink = `${
                                        company[COMPANY_KEYS.LOGO_BASE_URL]
                                    }/${
                                        fetchedPhoto[
                                            COMPANY_KEYS.LOGO_FILE_PATH
                                        ]
                                    }`;
                                }
                                const companyInitials = photoLink ? (
                                    <React.Fragment>&nbsp;</React.Fragment>
                                ) : (
                                    getInitialsFromName(companyName)
                                );

                                return (
                                    <TableRow
                                        key={companyId}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1.25}
                                            >
                                                <Avatar
                                                    src={photoLink}
                                                    sizes={"small"}
                                                    alt={companyName}
                                                    sx={{
                                                        bgcolor: "#487AA3",
                                                    }}
                                                >
                                                    {companyInitials}
                                                </Avatar>
                                                <Typography>
                                                    <NameLink
                                                        component={RouterLink}
                                                        to={`/company/${companyId}/dashboard/`}
                                                    >
                                                        {companyName}
                                                    </NameLink>
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {company[COMPANY_KEYS.INDUSTRY]}
                                        </TableCell>
                                        <TableCell>
                                            {company[COMPANY_KEYS.EMAIL]}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <TableActionIcon
                                                iconComponent="viewKanban"
                                                label="view reports"
                                                tooltip="View Reports"
                                                component={RouterLink}
                                                to={`/reports/${companyId}/`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colspan}>
                                    <Alert
                                        severity={
                                            companiesFetched ? "error" : "info"
                                        }
                                    >
                                        {companiesFetched
                                            ? "There are no records found."
                                            : "Loading companies..."}
                                    </Alert>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={PAGINATION_PAGES}
                component="div"
                count={
                    companyCountNonZero
                        ? parseInt(companyCounts[COMPANY_KEYS.TOTAL])
                        : 0
                }
                labelRowsPerPage=""
                rowsPerPage={rowsPerPage}
                page={companyCountNonZero ? page : 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
};

ReportsCompaniesTable.propTypes = {
    companies: PropTypes.array,
    companyCounts: PropTypes.object,
    companiesFetched: PropTypes.bool,
    order: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    sort: PropTypes.string,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
    onSort: PropTypes.func,
};

ReportsCompaniesTable.defaultProps = {
    companies: [],
    companyCounts: null,
    companiesFetched: false,
    order: "",
    page: 0,
    rowsPerPage: 0,
    sort: "",
    // handlePageChange: () => {},
    // handleRowsPerPageChange: () => {},
    // onSort: () => {},
};

export default ReportsCompaniesTable;
