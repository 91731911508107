import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { COMPANY_KEYS } from "../../utils/constants/appData";

const CompaniesFilter = ({
    disabled,
    disableFilterButton,
    id,
    label,
    options,
    value,
    onChange,
    onFilterByCompanies,
}) => {
    return (
        <Stack direction="row" alignItems="center">
            <Autocomplete
                multiple
                disabled={disabled}
                disablePortal
                fullWidth
                filterSelectedOptions
                id={id}
                options={options}
                value={value}
                getOptionLabel={(option) => {
                    return option && option[COMPANY_KEYS.ID]
                        ? option[COMPANY_KEYS.NAME]
                        : "";
                }}
                isOptionEqualToValue={(option, newValue) => {
                    return (
                        option[COMPANY_KEYS.ID] === newValue[COMPANY_KEYS.ID]
                    );
                }}
                onChange={onChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label={label}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option[COMPANY_KEYS.ID]}>
                        {option[COMPANY_KEYS.NAME]}
                    </li>
                )}
                sx={(theme) => ({
                    "& .MuiAutocomplete-inputRoot": {
                        backgroundColor: theme.palette.background.paper,
                    },
                })}
            />
            <Tooltip title="Filter by Company">
                <Button
                    disabled={disableFilterButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={onFilterByCompanies}
                    sx={{
                        ml: 1,
                        borderRadius: "5%",
                        "&.Mui-disabled": { color: "inherit" },
                    }}
                >
                    <FilterListIcon />
                </Button>
            </Tooltip>
        </Stack>
    );
};

CompaniesFilter.propTypes = {
    disabled: PropTypes.bool,
    disableFilterButton: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onFilterByCompanies: PropTypes.func,
};

CompaniesFilter.defaultProps = {
    disabled: false,
};

export default CompaniesFilter;
