import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const LinkButton = styled(Button)(({ theme }) => ({
    border: "none",
    borderRadius: 0,
    padding: theme.spacing(0),
    textAlign: "left",
    textTransform: "none",
    fontSize: theme.typography?.miscellaneous?.button?.medium,
    fontWeight: 500,
    color: theme.palette.miscellaneous.button.main,
    letterSpacing: "0px",
    textDecoration: "underline",
    "&:hover": {
        border: "none",
        borderRadius: 0,
        padding: theme.spacing(0),
        backgroundColor: "transparent",
        color: theme.palette.secondary.main,
        textDecoration: "underline",
    },
}));

export default LinkButton;
