import React from "react";
import LeadSheets from "../pages/leadSheets";
import Page from "@jumbo/shared/Page";

const leadSheetsRoutes = [
    {
        path: "/leadSheets",
        element: <Page component={LeadSheets} />,
    },
];

export default leadSheetsRoutes;
