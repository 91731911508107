export const headerTheme = {
    type: "light",
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: "#818E94",
                    background: "#FFFFFF",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: "#487AA3",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "#487AA3",
                    background: "#FFFFFF",
                    "&:hover": {
                        color: "#487AA3",
                        background: "#FFFFFF",
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#4B4B4B",
            light: "#818E94",
            dark: "#2D2F39",
            contrastText: "#FFF",
        },
        secondary: {
            main: "#E44A77",
            light: "#FF7EA6",
            dark: "#DF295E",
            contrastText: "#FFF",
        },
        error: {
            main: "#E73145",
            light: "#FF6A70",
            dark: "#AD001E",
            contrastText: "#FFF",
        },
        warning: {
            main: "#F39711",
            light: "#FFC84C",
            dark: "#BB6900",
            contrastText: "#FFF",
        },
        info: {
            main: "#2EB5C9",
            light: "#6FE7FC",
            dark: "#008598",
            contrastText: "#FFF",
        },
        success: {
            main: "#3BD2A2",
            light: "#78FFD3",
            dark: "#00A073",
            contrastText: "#FFF",
        },
        text: {
            primary: "#475259",
            secondary: "#8595A6",
            disabled: "#A2B2C3",
        },
        divider: "#DEE2E6",
        background: {
            paper: "#FFFFFF",
            default: "#F5F7FA",
        },
        action: {
            active: "#475259",
            hover: "#F5F7FA",
        },
    },
};
